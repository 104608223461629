/** API REDUCER */

import { baseURL } from '../config/baseURL';
import API from 'helpers/api';

export const FETCH_API_INVENTORY_TABLE = '@api/FETCH_API_INVENTORY_TABLE';

export const LOADING_TABLE_REQUEST = '@api/LOADING_TABLE_REQUEST';
export const FINISH_TABLE_REQUEST = '@api/FINISH_TABLE_REQUEST';

const getAPIData = async (api, options) => {
  const getData = await fetch(api, options);
  return getData.status === 200 && (await getData.json());
};

export const getToken = () => API.getFirebaseToken();

export const fetchInventoryTable = query => async dispatch => {
  dispatch({ type: LOADING_TABLE_REQUEST });
  const token = await getToken();

  const options = {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/csv'
    }
  };

  let stringifiedQueries = Object.keys(query)
    .map(param => `${param}=${query[param]}&`)
    .join('');
  stringifiedQueries = stringifiedQueries.substring(0, stringifiedQueries.length - 1);
  let inventory = null;
  // ROLE - INVENTORY - VIEWTABLE
  const inventoryAPI = `${baseURL}/analytics/sites/${query.site}/inventory?${stringifiedQueries}`;
  const inventoryJSON = await getAPIData(inventoryAPI, options);

  if (inventoryJSON) inventory = inventoryJSON.data;

  await dispatch({
    type: FETCH_API_INVENTORY_TABLE,
    inventory: inventory
  });
  return dispatch({ type: FINISH_TABLE_REQUEST });
};

const initialState = {
  table: {
    _loading: false,
    sites: null,
    sitesIssues: null,
    inventory: null,
    issues: null,
    issuesQuestions: null,
    auditors: null
  }
};

export const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_API_INVENTORY_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          _loading: true,
          inventory: action.inventory
        }
      };
    case LOADING_TABLE_REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          _loading: true
        }
      };
    case FINISH_TABLE_REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          _loading: false
        }
      };
    default:
      return state;
  }
};
