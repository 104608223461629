import { createAsyncAction, createAction } from 'typesafe-actions';

import * as types from './actionType';
import { Option } from 'types/app';

export const fetchFilterSites = createAsyncAction(
  types.FETCH_FILTER_SITES_REQUEST,
  types.FETCH_FILTER_SITES_SUCCESS,
  types.FETCH_FILTER_SITES_FAILURE,
  types.FETCH_FILTER_SITES_CANCEL
)<undefined, { data: any }, { error: any }, undefined>();

export const selectSiteOption = createAction(types.SELECT_SITE_OPTION)<Option>();
export const clearSiteOption = createAction(types.CLEAR_SITE_OPTION)<undefined>();
