export const SET_FILTER_QUERY = 'promotions/SET_FILTER_QUERY';
export const CLEAR_FILTER_QUERY = 'promotions/CLEAR_FILTER_QUERY';
export const SET_SORT = 'promotions/SET_PROMOTIONS_SORT';
export const SET_LOADING = 'promotions/SET_LOADING';
export const SET_PAGE_INDEX = 'promotions/SET_PAGE_INDEX ';
export const SET_TOTAL_promotions = 'promotions/SET_TOTAL_promotions';
export const SET_SELECTED_TAB = 'promotions/SET_SELECTED_TAB';

export const FETCH_PROMOTIONS_REQUEST = 'promotions/FETCH_PROMOTIONS_REQUEST';
export const FETCH_PROMOTIONS_SUCCESS = 'promotions/FETCH_PROMOTIONS_SUCCESS';
export const FETCH_PROMOTIONS_FAILURE = 'promotions/FETCH_PROMOTIONS_FAILURE';

export const FETCH_PAGINATE_PROMOTIONS_REQUEST = 'promotions/FETCH_PAGINATE_PROMOTIONS_REQUEST';
export const FETCH_PAGINATE_PROMOTIONS_SUCCESS = 'promotions/FETCH_PAGINATE_PROMOTIONS_SUCCESS';
export const FETCH_PAGINATE_PROMOTIONS_FAILURE = 'promotions/FETCH_PAGINATE_PROMOTIONS_FAILURE';

export const FETCH_PROMOTIONS_DETAIL_REQUEST = 'promotions/FETCH_PROMOTIONS_DETAIL_REQUEST';
export const FETCH_PROMOTIONS_DETAIL_SUCCESS = 'promotions/FETCH_PROMOTIONS_DETAIL_SUCCESS';
export const FETCH_PROMOTIONS_DETAIL_FAILURE = 'promotions/FETCH_PROMOTIONS_DETAIL_FAILURE';

export const CREATE_PROMOTIONS_REQUEST = 'promotions/CREATE_PROMOTIONS_REQUEST';
export const CREATE_PROMOTIONS_SUCCESS = 'promotions/CREATE_PROMOTIONS_SUCCESS';
export const CREATE_PROMOTIONS_FAILURE = 'promotions/CREATE_PROMOTIONS_FAILURE';

export const UPDATE_PROMOTIONS_REQUEST = 'promotions/UPDATE_PROMOTIONS_REQUEST';
export const UPDATE_PROMOTIONS_SUCCESS = 'promotions/UPDATE_PROMOTIONS_SUCCESS';
export const UPDATE_PROMOTIONS_FAILURE = 'promotions/UPDATE_PROMOTIONS_FAILURE';
