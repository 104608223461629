import update from 'immutability-helper';
import { ActionType, createReducer, Reducer } from 'typesafe-actions';

import * as types from './siteDetails.actionTypes';
import * as actions from './siteDetails.action';
import { PopulatedSite } from 'nimbly-common';
import { Site } from '@nimbly-technologies/nimbly-common';

export type SiteDetailsAction = ActionType<typeof actions>;
interface SiteDetailsModal {
	removeMember: boolean;
}
export interface SiteDetailsState {
	isLoading: boolean;
	value: null | Site | PopulatedSite;
	initialValue: null | Site | PopulatedSite;
	isEmpty: boolean;
	hasChanges: boolean;
	isLoadingDepartments: boolean;
	errorDepartments: string | null;
	selectedRemovedMember: string;
	selectedRemovedQuestionnaireSchedules: number[];
	modal: SiteDetailsModal;
}

const initialState: SiteDetailsState = {
	isLoading: true,
	value: null,
	initialValue: null,
	isEmpty: true,
	hasChanges: false,
	isLoadingDepartments: true,
	errorDepartments: null,
	selectedRemovedMember: '',
	selectedRemovedQuestionnaireSchedules: [],
	modal: {
		removeMember: false,
	},
};

const siteDetailsReducer: Reducer<SiteDetailsState, SiteDetailsAction> = createReducer<
	SiteDetailsState,
	SiteDetailsAction
>(initialState)
	.handleType(types.SET_SITE_LOADING, (state, action) =>
		update(state, {
			isLoading: { $set: action.payload.bool },
		}),
	)
	.handleType(types.FETCH_SITE_DEPARTMENTS_REQUEST, (state) =>
		update(state, {
			$merge: {
				isLoadingDepartments: true,
				errorDepartments: null,
			},
		}),
	)
	.handleType(types.FETCH_SITE_DEPARTMENTS_SUCCESS, (state) =>
		update(state, {
			isLoadingDepartments: { $set: false },
		}),
	)
	.handleType(types.FETCH_SITE_DEPARTMENTS_FAILURE, (state, { payload }) =>
		update(state, {
			errorDepartments: { $set: payload.error },
		}),
	)
	.handleType(types.SHOW_REMOVE_MEMBER_MODAL, (state) =>
		update(state, {
			modal: {
				removeMember: { $set: true },
			},
		}),
	)
	.handleType(types.DISMISS_REMOVE_MEMBER_MODAL, (state) =>
		update(state, {
			modal: {
				removeMember: { $set: false },
			},
		}),
	)
	.handleType(types.SET_REMOVE_TEAM_MEMBER, (state, action) =>
		update(state, {
			selectedRemovedMember: { $set: action.payload.userKey },
		}),
	)
	.handleType(types.SET_QUESTIONNAIRE_SCHEDULE_MEMBER, (state, { payload }) =>
		update(state, {
			selectedRemovedQuestionnaireSchedules: { $set: payload.indexes },
		}),
	)
	.handleType(types.SET_SITE, (state, action) =>
		update(state, {
			isEmpty: { $set: !!action.payload.site },
			value: { $set: action.payload.site },
			initialValue: { $set: action.payload.site },
			isLoading: { $set: false },
		}),
	)
	.handleType(types.SET_SITE_VALUE, (state, action) =>
		update(state, {
			value: { $set: action.payload.site },
		}),
	)
	.handleType(types.SET_TEAM, (state, action) =>
		update(state, {
			value: {
				team: { $set: action.payload.team },
			},
		}),
	)
	.handleType(types.SET_SITE_OWNER, (state, action) =>
		update(state, {
			value: {
				owner: { $set: action.payload.userKey },
			},
		}),
	)
	.handleType(types.SET_SITE_NAME, (state, action) =>
		update(state, {
			value: {
				name: { $set: action.payload.text },
			},
		}),
	)

	.handleType(types.SET_SITE_SUBTITLE, (state, action) =>
		update(state, {
			value: {
				subtitle: { $set: action.payload.text },
			},
		}),
	)
	.handleType(types.SET_SITE_ADDRESS, (state, action) =>
		update(state, {
			value: {
				address: { $set: action.payload.text },
			},
		}),
	)
	.handleType(types.SET_SITE_COUNTRY, (state, action) =>
		update(state, {
			value: {
				country: { $set: action.payload.text },
			},
		}),
	)
	.handleType(types.SET_SITE_PROVINCE, (state, action) =>
		update(state, {
			value: {
				province: { $set: action.payload.text },
			},
		}),
	)
	.handleType(types.SET_SITE_CITY, (state, action) =>
		update(state, {
			value: {
				city: { $set: action.payload.text },
			},
		}),
	)
	.handleType(types.SET_SITE_LOCATION_NAME, (state, action) =>
		update(state, {
			value: {
				locationName: { $set: action.payload.text },
			},
		}),
	)
	.handleType(types.SET_SITE_RADIUS, (state, action) =>
		update(state, {
			value: {
				radius: { $set: action.payload.radius },
			},
		}),
	)
	.handleType(types.SET_SITE_SUPERVISORS, (state, action) =>
		update(state, {
			value: {
				supervisors: { $set: action.payload.supervisors },
			},
		}),
	)
	.handleType(types.SET_SITE_TIMEZONE, (state, action) =>
		update(state, {
			value: {
				$merge: {
					timezone: action.payload.timezone,
					utcOffset: action.payload.offset,
				},
			},
		}),
	)
	.handleType(types.ADD_SITE_AUDITOR, (state, action) =>
		update(state, {
			value: {
				team: (team: string[]) => update(team || [], { $push: [action.payload.userKey] }),
			},
		}),
	)
	.handleType(types.REMOVE_TEAM_MEMBER, (state, action) =>
		update(state, {
			value: {
				team: {
					$apply: (team: string[]) => team.slice().filter((m) => m !== action.payload.userKey),
				},
			},
		}),
	)
	.handleType(types.SET_COORDINATE, (state, action) =>
		update(state, {
			value: {
				coordinates: { $set: action.payload.coordinate },
			},
		}),
	)
	.handleType(types.SET_SITE_ISSUE_DEFAULT_ASSIGNED_USER, (state, action) =>
		update(state, {
			value: {
				issueDefaultAssignedUser: { $set: action.payload.uid },
			},
		}),
	)
	.handleType(types.UPDATE_INITIAL_VALUE, (state) =>
		update(state, {
			initialValue: { $set: state.value },
		}),
	)
	.handleType(types.SET_HAS_CHANGES, (state, action) =>
		update(state, {
			hasChanges: { $set: action.payload.hasChanges },
		}),
	)
	.handleType(types.SET_CHILDREN, (state, action) =>
		update(state, {
			value: {
				children: { $set: action.payload.children },
			},
		}),
	)
	.handleType(types.SET_ASSIGNED_AUDITOR, (state, action) =>
		update(state, {
			value: {
				assignedAuditor: { $set: action.payload.assignedAuditor },
			},
		}),
	);

export default siteDetailsReducer;
