import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './integration.action';

import { IntegrationState } from './type';

export type IntegrationAction = ActionType<typeof actions>;

const initialState: IntegrationState = {
  token: '',
  errorMessage: '',
  isLoading: false
};

export const integrationReducer = createReducer<IntegrationState, IntegrationAction>(initialState)
  .handleAction(actions.createIntegrationToken.request, state => ({
    ...state,
    errorMessage: '',
    token: '',
    isLoading: true
  }))
  .handleAction(actions.createIntegrationToken.success, (state, action) => ({
    ...state,
    errorMessage: '',
    token: action.payload.token,
    isLoading: false
  }))
  .handleAction(actions.createIntegrationToken.failure, (state, action) => ({
    ...state,
    errorMessage: '',
    token: '',
    isLoading: false
  }))
  .handleAction(actions.retrieveIntegrationToken.request, state => ({
    ...state,
    errorMessage: '',
    token: '',
    isLoading: true
  }))
  .handleAction(actions.retrieveIntegrationToken.success, (state, action) => ({
    ...state,
    errorMessage: '',
    token: action.payload.token,
    isLoading: false
  }))
  .handleAction(actions.retrieveIntegrationToken.failure, (state, action) => ({
    ...state,
    errorMessage: '',
    token: '',
    isLoading: false
  }));

export default integrationReducer;
