import { createAsyncAction, deprecated } from 'typesafe-actions';
import * as types from './repositoryFolderList.actionTypes';
import { RepositoryFolderList } from './type';
import { SortDirection } from 'reducers/skuManagement/type';

const { createStandardAction } = deprecated;

export const setRepositoryFolderListSortBy = createStandardAction(types.SET_REPOSITORY_FOLDER_LIST_SORT_BY)<string>();
export const setRepositoryFolderListSortType = createStandardAction(types.SET_REPOSITORY_FOLDER_LIST_SORT_TYPE)<
	SortDirection
>();

export const fetchRepositoryFolderList = createAsyncAction(
	types.FETCH_REPOSITORY_FOLDER_LIST_REQUEST,
	types.FETCH_REPOSITORY_FOLDER_LIST_SUCCESS,
	types.FETCH_REPOSITORY_FOLDER_LIST_FAILED,
)<undefined, { data: RepositoryFolderList[] }, { error: string }>();
