import React, { lazy, Suspense } from 'react';

const SetPassword = lazy(() => import('../components/landing/SetPassword/SetPassword'));

const SetPasswordPage = () => {
	return (
		<div className="altpage-root">
			<Suspense fallback={<div />}>
				<SetPassword />
			</Suspense>
			<div className="altpage-footer">
				<span>©2018 All Rights Reserved.</span>
			</div>
		</div>
	);
};
export default SetPasswordPage;
