import { NotificationSettings } from '@nimbly-technologies/nimbly-common';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
	CreateSettingsNotificationUnsubscribeResponse,
	FetchSettingsNotificationUnsubscribeResponse,
	UnsubscribeNotificationSettings,
	createSettingsNotification,
	createSettingsNotificationUnsubscribe,
	fetchSettingsNotification,
	fetchSettingsNotificationUnsubscribe,
	updateSettingsNotification,
	updateSettingsNotificationUnsubscribe,
} from 'services/settings/settingsNotification/settingsNotification.service';
import { RootState } from 'store/rootReducers';
import { IAPIResponse } from 'types/api';
import Monitoring from 'utils/monitoring/Monitoring';
import * as types from './settingsNotification.actionTypes';
import * as actions from './settingsNotification.actions';
import { generateChangedSettings } from './utils/generateChangedSettings';

export function* updateSettingsNotificationSaga(
	action: ReturnType<typeof actions.updateSettingsNotificationAsync.request>,
): Generator {
	try {
		const res: IAPIResponse<NotificationSettings> = (yield call(
			updateSettingsNotification,
			action.payload.data,
		) as unknown) as IAPIResponse<NotificationSettings>;

		yield put(actions.updateSettingsNotificationAsync.success(res));
		yield put(
			actions.fetchSettingsNotificationAsync.request({
				organizationID: action.payload.data.organizationID,
			}),
		);

		action.payload.onSuccess?.();
	} catch (e) {
		Monitoring.logError(e as Error);
		yield put(actions.updateSettingsNotificationAsync.failure({ error: e as string }));
	}
}

export function* getSettingsNotificationSaga(
	action: ReturnType<typeof actions.fetchSettingsNotificationAsync.request>,
): Generator {
	try {
		const res: IAPIResponse<NotificationSettings> = (yield call(fetchSettingsNotification, {
			organizationID: action.payload.organizationID,
		}) as unknown) as IAPIResponse<NotificationSettings>;

		yield put(actions.fetchSettingsNotificationAsync.success(res));
	} catch (e) {
		Monitoring.logError(e as Error);
		yield put(actions.fetchSettingsNotificationAsync.failure({ error: e as string }));
	}
}

export function* createSettingsNotificationSaga(
	action: ReturnType<typeof actions.createSettingsNotificationAsync.request>,
): Generator {
	try {
		const res: IAPIResponse<NotificationSettings> = (yield call(
			createSettingsNotification,
			action.payload.data,
		) as unknown) as IAPIResponse<NotificationSettings>;

		yield put(actions.createSettingsNotificationAsync.success(res));
		yield put(
			actions.fetchSettingsNotificationAsync.request({
				organizationID: action.payload.data.organizationID,
			}),
		);
	} catch (e) {
		Monitoring.logError(e as Error);
		yield put(actions.createSettingsNotificationAsync.failure({ error: e as string }));
	}
}

export function* getSettingsNotificationUnsubscribeSaga(
	action: ReturnType<typeof actions.fetchSettingsNotificationUnsubscribeAsync.request>,
): Generator {
	try {
		const res: FetchSettingsNotificationUnsubscribeResponse = (yield call(fetchSettingsNotificationUnsubscribe, {
			organizationID: action.payload.organizationID,
		}) as unknown) as FetchSettingsNotificationUnsubscribeResponse;

		yield put(actions.fetchSettingsNotificationUnsubscribeAsync.success(res));
	} catch (e) {
		Monitoring.logError(e as Error);
		yield put(actions.fetchSettingsNotificationUnsubscribeAsync.failure({ error: e as string }));
	}
}

const unsubscribeSettingsSelector = (state: RootState) => state.settingsNotification.unsubscribeQuery.data;
type UnsubscribeSettingsQuerData = RootState['settingsNotification']['unsubscribeQuery']['data'];

export function* updateSettingsNotificationUnsubscribeSaga(
	action: ReturnType<typeof actions.updateSettingsNotificationUnsubscribeAsync.request>,
): Generator {
	try {
		const unsubscribeSettings = (yield select(unsubscribeSettingsSelector)) as UnsubscribeSettingsQuerData;
		const changedSettings: UnsubscribeNotificationSettings[] = generateChangedSettings(
			unsubscribeSettings,
			action.payload.data.settings,
		);

		const payload = {
			organizationID: action.payload.data.organizationID,
			settings: changedSettings,
		};

		const apiCall = unsubscribeSettings ? updateSettingsNotificationUnsubscribe : createSettingsNotificationUnsubscribe;

		const res = (yield call(apiCall, payload)) as CreateSettingsNotificationUnsubscribeResponse;

		yield put(actions.updateSettingsNotificationUnsubscribeAsync.success(res));
		yield put(
			actions.fetchSettingsNotificationUnsubscribeAsync.request({
				organizationID: action.payload.data.organizationID,
			}),
		);

		action.payload.onSuccess?.();
	} catch (e) {
		Monitoring.logError(e as Error);
		yield put(actions.updateSettingsNotificationUnsubscribeAsync.failure({ error: e as string }));
	}
}

export default function* settingsNotificationSaga() {
	yield takeLatest(types.CREATE_SETTINGS_NOTIFICATION_REQUEST, createSettingsNotificationSaga);
	yield takeLatest(types.FETCH_SETTINGS_NOTIFICATION_REQUEST, getSettingsNotificationSaga);
	yield takeLatest(types.UPDATE_SETTINGS_NOTIFICATION_REQUEST, updateSettingsNotificationSaga);

	yield takeLatest(types.FETCH_SETTINGS_NOTIFICATION_UNSUBSCRIBE_REQUEST, getSettingsNotificationUnsubscribeSaga);
	yield takeLatest(types.UPDATE_SETTINGS_NOTIFICATION_UNSUBSCRIBE_REQUEST, updateSettingsNotificationUnsubscribeSaga);
}
