export const SET_SHOW_MODAL = 'userManagement/SET_SHOW_MODAL';
export const SET_SUCCESS = 'userManagement/SET_SUCCESS';

export const CREATE_USER_REQUEST = 'userManagement/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'userManagement/CREATE_USER_LOADING';
export const CREATE_USER_FAILURE = 'userManagement/CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'userManagement/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'userManagement/UPDATE_USER_LOADING';
export const UPDATE_USER_FAILURE = 'userManagement/UPDATE_USER_FAILURE';

export const GET_USER_LIST_REQUEST = 'userManagement/GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'userManagement/GET_USER_LIST_LOADING';
export const GET_USER_LIST_FAILURE = 'userManagement/GET_USER_LIST_FAILURE';

export const SET_USER_LIST_FILTER_QUERY = 'userManagement/SET_USER_LIST_FILTER_QUERY';
export const RESET_USER_LIST_FILTER_QUERY = 'userManagement/RESET_USER_LIST_FILTER_QUERY';
export const SET_USER_LIST_PAGE_INDEX = 'userManagement/SET_USER_LIST_PAGE_INDEX';
export const SET_USER_LIST_SEARCH_QUERY = 'userManagement/SET_USER_LIST_SEARCH_QUERY';
export const SET_USER_LIST_SORT = 'userManagement/SET_USER_LIST_SORT';
export const RESET_USER_FILTER = 'userManagement/CLEAR_USER_FILTER';
