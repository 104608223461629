import { apiURL } from 'config/baseURL';
import { getToken } from './index';
import { AllOrganizationsResponse } from './organizations.types';
import Monitoring from '../../../utils/monitoring/Monitoring';

export const getAllOrganizations = async (): Promise<AllOrganizationsResponse> => {
  const token = await getToken();
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    }
  };
  const url = `${apiURL}/organizations/all`;

  try {
    const response = await fetch(url, options);

    if (response.status !== 200) {
      throw new Error(`Failed to fetch: ${url}`);
    }

    const result = (await response.json()) as AllOrganizationsResponse;

    if (result.message === 'FAILED') {
      throw new Error(`Error occurred to fetch: ${url}`);
    }

    return { data: result.data, message: 'SUCCESS', error: null };
  } catch (e) {
    Monitoring.logEvent('getAllOrganizations', e);
    return { data: null, message: 'FAILED', error: e };
  }
};
