import { SitesIssueListResponse, DashboardSitesPayload } from 'reducers/dashboard/fetch-types/sites-fetch-types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type SitesIssueList = SitesIssueListResponse['data'];

export interface IssueListState {
  readonly issueList_loading: boolean;
  readonly issueList_data: SitesIssueList;
  readonly issueList_error: string;
  readonly issueList_dl_loading: boolean;
  readonly issueList_dl_url: string;
  readonly issueList_dl_error: string;
}

type DownloadPayload = {
  dl: 1;
};

export const FETCH_ISSUE_LIST_REQUEST = '@@dashboard/sites/FETCH_ISSUE_LIST_REQUEST';
export const FETCH_ISSUE_LIST_SUCCESS = '@@dashboard/sites/FETCH_ISSUE_LIST_SUCCESS';
export const FETCH_ISSUE_LIST_FAILURE = '@@dashboard/sites/FETCH_ISSUE_LIST_FAILURE';

export type FetchSiteIssueStatListPayload = DashboardSitesPayload;
export type FetchSiteIssueStatListResponse = SitesIssueListResponse | ErrorResponse;

export const DOWNLOAD_ISSUE_LIST_REQUEST = '@@dashboard/sites/DOWNLOAD_ISSUE_LIST_REQUEST';
export const DOWNLOAD_ISSUE_LIST_SUCCESS = '@@dashboard/sites/DOWNLOAD_ISSUE_LIST_SUCCESS';
export const DOWNLOAD_ISSUE_LIST_FAILURE = '@@dashboard/sites/DOWNLOAD_ISSUE_LIST_FAILURE';

export type DownloadSiteIssueStatListPayload = DashboardSitesPayload & DownloadPayload;
