import { toast } from 'react-toastify';
import { action, ActionType } from 'typesafe-actions';
import { ThunkResult } from 'types/app';

import { Role, Resource } from 'nimbly-common';
import { IRoleLabel, RoleLabel, RestructuredResource } from '../components/permissions/typings';

import { sortUserRoles } from '../components/permissions/utils/sortUserRoles';
import { createTemplateResource } from '../components/permissions/utils/createTemplateResource';
import { updateResourcePermissions } from '../components/permissions/utils/updateResourcePermissions';
import { revertData } from '../components/permissions/utils/revertData';
import { apiURL } from '../config/baseURL';
import { getToken } from './api';
import i18n from 'i18n';

const SET_PERMISSION_RESOURCE = '@settings/SET_PERMISSION_RESOURCE';
const UPDATE_PERMISSION_RESOURCE = '@settings/UPDATE_PERMISSION_RESOURCE';
const SET_RANKED_ROLES = '@settings/SET_RANKED_ROLES';
const IS_FETCHING = '@settings/IS_FETCHING';
const IS_BUSY = '@settings/IS_BUSY';

// Action creators
const setPermissionResource = (resource: RestructuredResource) => action(SET_PERMISSION_RESOURCE, { resource });

const updatePermissionResource = (resource: RestructuredResource) => action(UPDATE_PERMISSION_RESOURCE, { resource });

const setRankedRoles = (userRoles: RoleLabel[]) => action(SET_RANKED_ROLES, { userRoles });

const setIsFetching = (bool: boolean) => action(IS_FETCHING, { bool });

const setIsBusy = (bool: boolean) => action(IS_BUSY, { bool });

export const settingsActions = {
  setPermissionResource,
  updatePermissionResource,
  setRankedRoles,
  setIsFetching,
  setIsBusy
};

// Thunks
const fetchResource = (): ThunkResult => async dispatch => {
  dispatch(setIsFetching(true));
  const token = await getToken();

  if (!token) {
    toast.error(i18n.t('addOn.settings.permissions.failAuthenticate'));
    dispatch(setIsFetching(false));
    return Promise.resolve();
  }

  // initiate fetch resource here
  try {
    const url = `${apiURL}/user-roles/all`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        authorization: token
      }
    });
    const result = await response.json();

    let userPermissions: Role[] = [];
    let userRoles: RoleLabel[] = [];
    let rankedUserRoles: RoleLabel[] = [];

    if (response.status === 200 && result.data) {
      userPermissions = result.data;

      // collect roles available from resource data
      userPermissions.forEach(({ role, label, origin, level }: IRoleLabel) => {
        userRoles.push({ value: role, label, level, origin: origin || null });
      });
      rankedUserRoles = sortUserRoles(userRoles);
      dispatch(setRankedRoles(rankedUserRoles));
    }

    const templateResource = createTemplateResource(rankedUserRoles);
    // update the template data of user role with data from database
    const updatedResource: RestructuredResource = updateResourcePermissions(templateResource, userPermissions);

    dispatch(setPermissionResource(updatedResource));
    dispatch(setIsFetching(false));
  } catch (err) {
    toast.error(JSON.stringify(err));
    dispatch(setIsFetching(false));
  }
  return Promise.resolve();
};

const saveResource = (resource: RestructuredResource): ThunkResult => async (dispatch, getState) => {
  dispatch(setIsBusy(true));
  const state = getState();
  const userRoles = state.settings.userRoles;

  const parsedResource: Resource = revertData(resource, userRoles);

  const token = await getToken();

  if (!token) {
    toast.error(i18n.t('addOn.settings.permissions.failAuthenticate'));
    dispatch(setIsBusy(false));
    return Promise.resolve();
  }

  try {
    const url = `${apiURL}/user-roles/all`;
    const options = {
      method: 'PUT',
      headers: {
        authorization: token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ roles: parsedResource })
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (response.status === 200 && result.data) {
      toast.success(i18n.t('addOn.settings.permissions.success'));
    } else {
      throw new Error(i18n.t('addOn.settings.permissions.fail'));
    }
  } catch (err) {
    toast.error(err);
    // refetch resource on failed save
    dispatch(fetchResource());
  }
  dispatch(setIsBusy(false));
  return Promise.resolve();
};

export const settingsThunks = { fetchResource, saveResource };

export type SettingsThunks = ActionType<typeof settingsThunks>;
export type SettingsActions = ActionType<typeof settingsActions>;

export type SettingsState = {
  readonly isFetching: boolean;
  readonly isBusy: boolean;
  readonly permissions: RestructuredResource;
  readonly userRoles: RoleLabel[];
  readonly userRolesMap: { [role: string]: RoleLabel };
};

export const initialState: SettingsState = {
  isFetching: false,
  isBusy: false,
  permissions: {},
  userRoles: [],
  userRolesMap: {}
};

export function settingsReducer(state = initialState, action: SettingsActions): SettingsState {
  switch (action.type) {
    case SET_PERMISSION_RESOURCE:
      return {
        ...state,
        permissions: action.payload.resource
      };
    case UPDATE_PERMISSION_RESOURCE:
      return {
        ...state,
        permissions: action.payload.resource
      };
    case SET_RANKED_ROLES:
      return {
        ...state,
        userRoles: action.payload.userRoles,
        userRolesMap: action.payload.userRoles.reduce((map: { [role: string]: RoleLabel }, obj) => {
          map[obj.value] = obj;
          return map;
        }, {})
      };
    case IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload.bool
      };
    case IS_BUSY:
      return {
        ...state,
        isBusy: action.payload.bool
      };
    default:
      return state;
  }
}
