import {
	BaseOptionObject,
	BaseSelectOptionType,
	mapOptionsDataToObject,
} from 'components/global/Select/utils/optionsMapper';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersDataVisibility } from 'reducers/users/users.action';
import { RootState } from 'store/rootReducers';

export const useUserDataVisibilityOptions = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchUsersDataVisibility.request());
	}, [dispatch]);

	const users = useSelector((state: RootState) => state.users.usersDataVisibility) || {};

	const userDataVisibilityMap = useMemo<BaseOptionObject>(
		() =>
			mapOptionsDataToObject({
				data: Object.values(users),
				key: 'userID',
				value: 'userID',
				label: 'displayName',
			}),
		[users],
	);
	const userDataVisibilityOptions: BaseSelectOptionType[] = useMemo(
		() => Object.values(userDataVisibilityMap).sort((a, b) => a.label.localeCompare(b.label)),
		[userDataVisibilityMap],
	);

	return {
		userDataVisibilityMap,
		userDataVisibilityOptions,
	};
};
