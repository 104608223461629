import { RankByFlagsResponse, DashboardSitesPayload } from 'reducers/dashboard/fetch-types/sites-fetch-types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type RankByFlags = RankByFlagsResponse['data'];

export interface RankByFlagsState {
  readonly rankByFlags_loading: boolean;
  readonly rankByFlags_data: RankByFlags;
  readonly rankByFlags_error: string;
  readonly rankByFlags_dl_loading: boolean;
  readonly rankByFlags_dl_url: string;
  readonly rankByFlags_dl_error: string;
}

type DownloadPayload = {
  dl: 1;
};

export const FETCH_RANK_BY_FLAGS_REQUEST = '@@dashboard/sites/FETCH_RANK_BY_FLAGS_REQUEST';
export const FETCH_RANK_BY_FLAGS_SUCCESS = '@@dashboard/sites/FETCH_RANK_BY_FLAGS_SUCCESS';
export const FETCH_RANK_BY_FLAGS_FAILURE = '@@dashboard/sites/FETCH_RANK_BY_FLAGS_FAILURE';

export type FetchRankByFlagsPayload = DashboardSitesPayload;
export type FetchRankByFlagsResponse = RankByFlagsResponse | ErrorResponse;

export const DOWNLOAD_RANK_BY_FLAGS_REQUEST = '@@dashboard/sites/DOWNLOAD_RANK_BY_FLAGS_REQUEST';
export const DOWNLOAD_RANK_BY_FLAGS_SUCCESS = '@@dashboard/sites/DOWNLOAD_RANK_BY_FLAGS_SUCCESS';
export const DOWNLOAD_RANK_BY_FLAGS_FAILURE = '@@dashboard/sites/DOWNLOAD_RANK_BY_FLAGS_FAILURE';

export type DownloadRankByFlagsPayload = DashboardSitesPayload & DownloadPayload;
