import React from 'react';

import './LoadingSpinner.css';
import classNames from 'classnames';

export interface LoadingSpinnerProps {
	xs?: boolean;
	small?: boolean;
	medium?: boolean;
	className?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
	const render = () => {
		return <div className={classNames(getClassSize(), props.className || '')} />;
	};

	const getClassSize = () => {
		const { xs, small, medium } = props;

		if (xs) {
			return 'LoadingSpinner-root-xs';
		}

		if (small) {
			return 'LoadingSpinner-root-small';
		}

		if (medium) {
			return 'LoadingSpinner-root-medium';
		}

		return 'LoadingSpinner-root';
	};

	return render();
};

export default LoadingSpinner;
