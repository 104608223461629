// Reducer for misc styling
const SET_SCROLLBAR_WIDTH = 'styling/SET_SCROLLBAR_WIDTH';

export const setScrollbarWidth = width => {
  return { type: SET_SCROLLBAR_WIDTH, width };
};

const initialState = { scrollbarWidth: 0 };

export function stylingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SCROLLBAR_WIDTH:
      return {
        ...state,
        scrollbarWidth: action.width
      };
    default:
      return state;
  }
}
