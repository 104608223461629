import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';
import {
  FetchIssuesCategoriesChartPayload,
  IssuesCategoriesChart
} from 'reducers/dashboard/overview/issuesCategoriesChart/types';

import issues_stock from '../../../assets/dummy/json/issuesCategoriesChart/issues-stock-number.json';
import issues_equipment from '../../../assets/dummy/json/issuesCategoriesChart/issues-equipment-number.json';
import issues_staff from '../../../assets/dummy/json/issuesCategoriesChart/issues-staff-number.json';
import issues_cleanliness from '../../../assets/dummy/json/issuesCategoriesChart/issues-cleanliness-number.json';
import issues_procurement from '../../../assets/dummy/json/issuesCategoriesChart/issues-procurement-number.json';

const reduceFunc = (curr: number, acc: number) => curr + acc;
export const getDummyIssuesCategoriesChart = (payload: FetchIssuesCategoriesChartPayload) => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let selectedStockIssues = [];
  let selectedEquipmentIssues = [];
  let selectedStaffIssues = [];
  let selectedCleanlinessIssues = [];
  let selectedProcurementIssues = [];

  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    selectedStockIssues = issues_stock.slice(-diffInDay);
    selectedEquipmentIssues = issues_equipment.slice(-diffInDay);
    selectedStaffIssues = issues_staff.slice(-diffInDay);
    selectedCleanlinessIssues = issues_cleanliness.slice(-diffInDay);
    selectedProcurementIssues = issues_procurement.slice(-diffInDay);
  } else {
    selectedStockIssues = issues_stock.slice(0, diffInDay);
    selectedEquipmentIssues = issues_equipment.slice(0, diffInDay);
    selectedStaffIssues = issues_staff.slice(0, diffInDay);
    selectedCleanlinessIssues = issues_cleanliness.slice(0, diffInDay);
    selectedProcurementIssues = issues_procurement.slice(0, diffInDay);
  }

  const categoriesName = ['Stock', 'Kitchen Equipment', 'Staff Performance', 'Cleanliness', 'Procurement'];
  const allIssuesCategoriesValues = [
    selectedStockIssues,
    selectedEquipmentIssues,
    selectedStaffIssues,
    selectedCleanlinessIssues,
    selectedProcurementIssues
  ];

  const dummyIssuesCategoriesChart: IssuesCategoriesChart = categoriesName.map((categoryName, index) => {
    return {
      category: categoryName,
      total: allIssuesCategoriesValues[index].reduce(reduceFunc),
      //UNUSED
      percentage: 0
    };
  });

  return dummyIssuesCategoriesChart;
};
