import * as actions from './userEnrollment.action';
import { ActionType } from 'typesafe-actions';
import * as types from './userEnrollment.actionTypes';

export type UserEnrollmentAction = ActionType<typeof actions>;

export interface UserEnrollmentState {
	enrollmentStatus: string;
	isAuthenticating: boolean;
}

interface UserEnrollmentReducerState {
	enrollmentStatus: string;
	authenticatingStatus: boolean;
}

const initialState: UserEnrollmentReducerState = {
	enrollmentStatus: '',
	authenticatingStatus: false,
};

export function userEnrollmentReducer(state = initialState, action: UserEnrollmentAction) {
	switch (action.type) {
		case types.SET_ENROLLMENT_STATUS:
			return {
				...state,
				enrollmentStatus: action.payload.value,
			};
		case types.SET_AUTHENTICATING_STATUS:
			return {
				...state,
				authenticatingStatus: action.payload.value,
			};
		default:
			return state;
	}
}
