import {
	createSKU as CreateSkuRequest,
	FindBySKUIDResponse,
	FindSKUPerCategory,
	SKU,
	UOM,
	updateSKU as UpdateSkuRequest,
} from '@nimbly-technologies/nimbly-common';
import { apiURL } from '../../config/baseURL';
import API from '../../helpers/api';
import { SkuApiError } from './singleSku.errors';
import { BatchUploadSkuError } from './batchSku.errors';

export const fetchSkuUoms = async (): Promise<UOM[]> => {
	const url = `${apiURL}/miscellaneous/uoms`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export const fetchSkuCategories = async (): Promise<string[]> => {
	const url = `${apiURL}/skus/sku-settings/categories`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export const createSkuCategory = async (category: string): Promise<string> => {
	const url = `${apiURL}/skus/sku-settings/categories`;

	const token = await API.getFirebaseToken();
	const result = await API.post(url, token, { category });

	const response = await result.json();
	if (result.status === 200) {
		return response.data as string;
	} else {
		throw new Error();
	}
};

export const fetchSkusCompact = async (searchParams: URLSearchParams): Promise<FindSKUPerCategory> => {
	const url = `${apiURL}/skus/compact?${searchParams}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export type sku = string;

export const createSku = async (sku: CreateSkuRequest): Promise<sku> => {
	const url = `${apiURL}/skus`;
	const token = await API.getFirebaseToken();
	const response = await API.post(url, token, sku);

	const error = await SkuApiError.getError(response);
	if (error) throw error;

	const json = await response.json();
	return json.data;
};

export const getSku = async (id: string): Promise<FindBySKUIDResponse> => {
	const url = `${apiURL}/skus/${id}`;
	return (await getData(url)) as FindBySKUIDResponse;
};

const getData = async (url: string): Promise<any> => {
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);

	if (response.status !== 200) throw new Error('Failed to retrieve data');

	const json = await response.json();

	return json.data;
};

export const deleteSku = async (id: string): Promise<void> => {
	const url = `${apiURL}/skus/${id}`;
	const token = await API.getFirebaseToken();
	const response = await API.delete(url, token);

	if (response.status !== 200) throw new Error('Failed to delete data');
};

export const updateSku = async (id: string, changedSkuValues: UpdateSkuRequest): Promise<SKU> => {
	const url = `${apiURL}/skus/${id}`;
	const token = await API.getFirebaseToken();
	const response = await API.patch(url, token, changedSkuValues);

	const error = await SkuApiError.getError(response);
	if (error) throw error;

	const json = await response.json();

	return json.data;
};

export const getBatchCreateTemplateData = async (): Promise<string> => {
	const url = `${apiURL}/skus/bulk-upload`;
	return (await getData(url)) as string;
};

export const uploadBatchCreateTemplateData = async (file: File): Promise<void> => {
	const url = `${apiURL}/skus/bulk-upload`;
	const token = await API.getFirebaseToken();
	const body = new FormData();
	body.append('file', file);
	const response = await API.postFile(url, token, body);

	const error = await BatchUploadSkuError.getError(response);
	if (error) throw error;

	await response.json();

	return;
};
