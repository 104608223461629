import API from 'helpers/api';
import { apiURL } from 'config/baseURL';
import { toast } from 'react-toastify';
import {
	HeatmapIssueFlagsI,
	IrrRatioI,
	RcrRatioI,
	VocRationI,
} from 'reducers/dashboardRevamp/executive/executive.types';
import { store } from 'store/configStore';
import { queryStringify } from 'utils/router';
import moment from 'moment';

export const fetchDepartmentRatio = async (): Promise<string | undefined> => {
	const { filters } = store.getState().executiveDashboard;

	const query = queryStringify({
		metric: 'issue-report-ratio',
		groupBy: 'department',
		startDate: moment(filters?.startDate)?.format('YYYY-MM-DD') || '',
		endDate: moment(filters?.endDate)?.format('YYYY-MM-DD') || '',
	});
	const apiRoute = `detail?${query}`;

	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const url = `${apiURL}/statistics/dashboard/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData?.data as string;
	} catch (error) {
		toast.error(error);
	}
};

export const fetchIrrRatio = async (): Promise<IrrRatioI | undefined> => {
	const { filters } = store.getState().executiveDashboard;

	const query = queryStringify({
		metric: 'irr',
		groupBy: 'issue',
		startDate: moment(filters?.startDate)?.format('YYYY-MM-DD') || '',
		endDate: moment(filters?.endDate)?.format('YYYY-MM-DD') || '',
	});
	const apiRoute = `issue/detail?${query}`;

	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const url = `${apiURL}/statistics/dashboard/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData?.data as IrrRatioI;
	} catch (error) {
		toast.error(error);
	}
};

export const fetchRcrRatio = async (): Promise<RcrRatioI | undefined> => {
	const { filters } = store.getState().executiveDashboard;

	const query = queryStringify({
		metric: 'rcr',
		groupBy: 'report',
		startDate: moment(filters?.startDate)?.format('YYYY-MM-DD') || '',
		endDate: moment(filters?.endDate)?.format('YYYY-MM-DD') || '',
	});
	const apiRoute = `report/detail?${query}`;

	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const url = `${apiURL}/statistics/dashboard/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData?.data as RcrRatioI;
	} catch (error) {
		toast.error(error);
	}
};

export const fetchVocRatio = async (): Promise<VocRationI | undefined> => {
	const { filters } = store.getState().executiveDashboard;

	const query = queryStringify({
		metric: 'issue-voc',
		groupBy: 'issue',
		startDate: moment(filters?.startDate)?.format('YYYY-MM-DD') || '',
		endDate: moment(filters?.endDate)?.format('YYYY-MM-DD') || '',
	});
	const apiRoute = `issue/detail?${query}`;

	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const url = `${apiURL}/statistics/dashboard/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData?.data as VocRationI;
	} catch (error) {
		toast.error(error);
	}
};
