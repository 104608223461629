import { ActionType, createReducer } from 'typesafe-actions';
import { RepositoryFolderListState } from './type';
import * as actions from './repositoryFolderList.action';
import { SortDirection } from 'utils/sort';

export type RepositoryFolderListAction = ActionType<typeof actions>;

export const initialState: RepositoryFolderListState = {
	isLoading: false,
	error: '',
	page: 1,
	limit: 20,
	totalDocs: 0,
	totalPages: 0,
	sortBy: '',
	sortType: SortDirection.ASC,
	data: [],
};

export const repositoryFolderListReducer = createReducer<RepositoryFolderListState, RepositoryFolderListAction>(
	initialState,
)
	.handleAction(actions.setRepositoryFolderListSortBy, (state, action) => ({
		...state,
		sortBy: action.payload,
	}))
	.handleAction(actions.setRepositoryFolderListSortType, (state, action) => ({
		...state,
		sortType: action.payload,
	}))
	.handleAction(actions.fetchRepositoryFolderList.request, (state, action) => ({
		...state,
		error: initialState.error,
		isLoading: true,
	}))
	.handleAction(actions.fetchRepositoryFolderList.success, (state, action) => ({
		...state,
		data: action.payload.data,
		error: initialState.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchRepositoryFolderList.failure, (state, action) => {
		return {
			...state,
			error: action.payload.error,
			isLoading: false,
		};
	});
