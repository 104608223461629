import {
  FetchManyUsersChartPayload,
  FetchManyUsersChartResponse
} from 'reducers/dashboard/auditors/manyUsersChart/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyAuditorsDailyChart } from 'utils/dashboardDummy/auditors/dummyAuditorsDailyChart';

export const fetchManyUsersChart = async (
  payload: FetchManyUsersChartPayload
): Promise<FetchManyUsersChartResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyManyUsersChart = getDummyAuditorsDailyChart(payload);
    return {
      message: 'SUCCESS',
      data: dummyManyUsersChart
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/users/daily?${serializedQueries}`;
    const res = await fetch(url, options);

    const auditorsListResult: FetchManyUsersChartResponse = await res.json();
    return auditorsListResult;
  } catch {
    const errorResult: FetchManyUsersChartResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
