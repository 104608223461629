import { action, createAsyncAction } from 'typesafe-actions';
import * as types from '../stockAuditScheduler/stockAuditScheduler.actionTypes';
import { Single_SKUScheduleList } from '@nimbly-technologies/nimbly-common';
import { StockAuditSchedulerFilters } from './type';

export const setSchedulePageIndex = (pageIndex: number) => action(types.SET_SCHEDULE_PAGE_INDEX, { pageIndex });

export const setScheduleSearchQuery = (searchQuery: string) => action(types.SET_SCHEDULE_SEARCH_QUERY, { searchQuery });

export const setScheduleSort = (sortBy: string, isAscending: boolean) =>
	action(types.SET_SCHEDULE_SORT, { sortBy, isAscending });

export const setScheduleFilters = (filters: StockAuditSchedulerFilters) =>
	action(types.SET_SCHEDULE_FILTER_QUERY, { filters });

export const setScheduleMobileFiltersVisible = (isVisible: boolean) =>
	action(types.SET_SCHEDULE_MOBILE_FILTERS_VISIBLE, { isVisible });

export const fetchSchedulesAsync = createAsyncAction(
	types.FETCH_SCHEDULES_REQUEST,
	types.FETCH_SCHEDULES_SUCCESS,
	types.FETCH_SCHEDULES_FAILED,
)<
	{ withDisabled?: boolean },
	{ schedules: Single_SKUScheduleList[] | []; totalSchedules: number; lastUpdatedAt: number },
	{ error: string }
>();
