import { DashboardIssuesPayload, FlagsChartResponse } from 'reducers/dashboard/fetch-types/issues-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesFlagsChart = FlagsChartResponse['data'];

export interface IssuesFlagsChartState {
  readonly issuesFlagsChart_loading: boolean;
  readonly issuesFlagsChart_data: IssuesFlagsChart;
  readonly issuesFlagsChart_error: string;
}

export const FETCH_ISSUES_FLAGS_CHART_REQUEST = '@@dashboard/issues/FETCH_FLAGS_CHART_REQUEST';
export const FETCH_ISSUES_FLAGS_CHART_SUCCESS = '@@dashboard/issues/FETCH_FLAGS_CHART_SUCCESS';
export const FETCH_ISSUES_FLAGS_CHART_FAILURE = '@@dashboard/issues/FETCH_FLAGS_CHART_FAILURE';

export type FetchFlagsChartPayload = DashboardIssuesPayload;
export type FetchFlagsChartResponse = FlagsChartResponse | ErrorResponse;
