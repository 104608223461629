import { LMSFileDetail, CourseDetailResponse } from '@nimbly-technologies/nimbly-common';
import { action, createAction, createAsyncAction } from 'typesafe-actions';
import { ILmsCourseStore } from './lmsCourse';
import * as types from './lmsCourse.actionTypes';

export const setLmsSelectedFile = (file: LMSFileDetail | null) => action(types.SET_LMS_SELECTED_FILE, file);

export const setLmsCourseById = (file: LMSFileDetail | null) => action(types.SET_LMS_COURSE_BY_ID, file);

export const setLmsCourseLessonSequence = (lessonSequence: ILmsCourseStore['lessonSequence']) =>
	action(types.SET_LMS_COURSE_LESSON_SEQUENCE, lessonSequence);

export const setLmsCourseCompletedModalVisible = (visible: boolean) =>
	action(types.SET_LMS_COURSE_IS_COMPLETED_MODAL_VISIBLE, visible);

export const setLmsCourseReTaken = (visible: boolean) => action(types.SET_LMS_COURSE_RETAKEN, visible);

export const setLmsMobileTopicsModal = (isOpen: boolean) => action(types.SET_LMS_MOBILE_TOPICS_MODAL, isOpen);

export const setLmsCourseChaptersLearnerActivityMap = (
	chaptersLearnerActivityMap: ILmsCourseStore['chaptersLearnerActivityMap'],
) => action(types.SET_LMS_COURSE_CHAPTERS_LEARNER_ACTIVITY_MAP, chaptersLearnerActivityMap);

export const setLmsCourseFilesWithoutChapterLearnerActivityMap = (
	filesWithoutChapterLearnerActivityMap: ILmsCourseStore['filesWithoutChapterLearnerActivityMap'],
) => action(types.SET_LMS_COURSE_FILES_WITHOUT_CHAPTER_LEARNER_ACTIVITY_MAP, filesWithoutChapterLearnerActivityMap);

export const setLmsCourseFileLearnerActivityFlatMap = (
	fileLearnerActivityFlatMap: ILmsCourseStore['fileLearnerActivityFlatMap'],
) => action(types.SET_LMS_COURSE_FILE_LEARNER_ACTIVITY_FLAT_MAP, fileLearnerActivityFlatMap);

export const setLmsCourseQuizLearnerActivityMap = (quizLearnerActivityMap: ILmsCourseStore['quizLearnerActivityMap']) =>
	action(types.SET_LMS_COURSE_QUIZ_LEARNER_ACTIVITY_MAP, quizLearnerActivityMap);

export const fetchLmsCourseByIdAsync = createAsyncAction(
	types.FETCH_LMS_COURSE_BYID_REQUEST,
	types.FETCH_LMS_COURSE_BYID_SUCCESS,
	types.FETCH_LMS_COURSE_BYID_FAILURE,
)<string, CourseDetailResponse, string>();

export const setLmsCourseAutoSelectLastLesson = (autoSelectLastLesson: boolean) =>
	action(types.SET_LMS_COURSE_AUTOSELECT_LAST_LESSON, autoSelectLastLesson);
