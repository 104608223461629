export const CREATE_DEPARTMENT_REQUEST = '@departmentEditor/CREATE_DEPARTMENT_REQUEST';
export const CREATE_DEPARTMENT_SUCCESS = '@departmentEditor/CREATE_DEPARTMENT_LOADING';
export const CREATE_DEPARTMENT_FAILURE = '@departmentEditor/CREATE_DEPARTMENT_FAILURE';

export const UPDATE_DEPARTMENT_REQUEST = '@departmentEditor/UPDATE_DEPARTMENT_REQUEST';
export const UPDATE_DEPARTMENT_SUCCESS = '@departmentEditor/UPDATE_DEPARTMENT_LOADING';
export const UPDATE_DEPARTMENT_FAILURE = '@departmentEditor/UPDATE_DEPARTMENT_FAILURE';

export const SET_SHOW_MODAL = '@departmentEditor/SET_SHOW_MODAL';
export const SET_SUCCESS = '@departmentEditor/SET_SUCCESS';
