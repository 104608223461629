import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';
import {
  FetchIssuesResolvedChartPayload,
  IssuesResolvedChart
} from 'reducers/dashboard/overview/issuesResolvedChart/types';

import org_issuesDue from '../../../assets/dummy/json/issuesOverviewStat/org-issuesDue.json';
import org_issuesResolved from '../../../assets/dummy/json/issuesOverviewStat/org-issuesResolved.json';

const reduceFunc = (curr: number, acc: number) => curr + acc;
export const getDummyIssuesResolvedChart = (payload: FetchIssuesResolvedChartPayload): IssuesResolvedChart => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let selectedDue = [];
  let selectedResolved = [];

  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    selectedDue = org_issuesDue.slice(-diffInDay);
    selectedResolved = org_issuesResolved.slice(-diffInDay);
  } else {
    selectedDue = org_issuesDue.slice(0, diffInDay);
    selectedResolved = org_issuesResolved.slice(0, diffInDay);
  }

  const totalDue = selectedDue.reduce(reduceFunc);

  const dummyIssuesResolvedChart_DAILY = selectedResolved.map((issuesResolved, index) => {
    const date = index < 10 ? `2020-03-0${index}` : `2020-03-${index}`;
    return {
      date,
      percentage: issuesResolved / totalDue,
      issuesCompleted: org_issuesResolved[index],
      totalIssuesDue: totalDue
    };
  });
  const issuesCompleted_reduced = dummyIssuesResolvedChart_DAILY.reduce((curr, acc) => ({
    issuesCompleted: curr.issuesCompleted + acc.issuesCompleted,
    totalIssuesDue: curr.issuesCompleted,
    date: '',
    percentage: 0
  }));
  const overview_issuesCompleted = issuesCompleted_reduced.issuesCompleted;
  const overview_issuesDue = issuesCompleted_reduced.totalIssuesDue;
  const randomizedPrevVal = [0.2, 0.35, 0.4, 0.75, 1];
  const overview_prevIssuesCompleted = overview_issuesCompleted * randomizedPrevVal[overview_issuesCompleted % 5];
  const overview_prevIssuesDue = overview_issuesDue * randomizedPrevVal[overview_issuesDue % 5];
  const overview_isGrowthValid = !!overview_prevIssuesCompleted && !!overview_issuesCompleted;
  const overview_growthRate = (overview_issuesCompleted - overview_prevIssuesCompleted) / overview_prevIssuesCompleted;

  return {
    daily: dummyIssuesResolvedChart_DAILY,
    overview: {
      issuesCompleted: overview_issuesCompleted,
      issuesDue: overview_issuesDue,
      prevIssuesCompleted: overview_prevIssuesCompleted,
      prevIssuesDue: overview_prevIssuesDue,
      isGrowthValid: overview_isGrowthValid,
      growthRate: overview_growthRate
    }
  };
};
