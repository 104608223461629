import {
  DashboardOverviewPayload,
  IssuesCategoriesChartResponse
} from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesCategoriesChart = IssuesCategoriesChartResponse['data'];

export interface IssuesCategoriesChartState {
  readonly issuesCategoriesChart_loading: boolean;
  readonly issuesCategoriesChart_data: IssuesCategoriesChart;
  readonly issuesCategoriesChart_error: string;
}

export const FETCH_ISSUES_CATEGORIES_CHART_REQUEST = '@@dashboard/overview/FETCH_ISSUES_CATEGORIES_CHART_REQUEST';
export const FETCH_ISSUES_CATEGORIES_CHART_SUCCESS = '@@dashboard/overview/FETCH_ISSUES_CATEGORIES_CHART_SUCCESS';
export const FETCH_ISSUES_CATEGORIES_CHART_FAILURE = '@@dashboard/overview/FETCH_ISSUES_CATEGORIES_CHART_FAILURE';

export type FetchIssuesCategoriesChartPayload = DashboardOverviewPayload;
export type FetchIssuesCategoriesChartResponse = IssuesCategoriesChartResponse | ErrorResponse;
