import { PaginationResult, MyCourseAndSyllabusResponse } from '@nimbly-technologies/nimbly-common';
import { apiURL } from '../../../config/baseURL';
import API from '../../../helpers/api';

export const fetchMyCoursesAndSyllabus = async (
	searchParams: string,
): Promise<PaginationResult<MyCourseAndSyllabusResponse>> => {
	const url = `${apiURL}/lms/courses-syllabuses?${searchParams}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};
