import { getFirebase } from 'react-redux-firebase';
import { apiURL } from 'config/baseURL';
import { Role, Activity } from 'nimbly-common';

export * from './activities';
export * from './organizations';
export * from './statistics';
export * from './users';

export type FetchOptions = {
  method: string;
  headers: {
    'Content-Type': string;
    authorization: string;
  };
  body?: string;
};

export const getToken = async () => {
  const firebase = getFirebase();
  const { currentUser } = firebase.auth!();
  let token = '';
  if (currentUser) {
    token = await currentUser.getIdToken(true);
  }
  return token;
};

export const requestDeleteRole = async (role: string) => {
  const token = await getToken();
  const options: FetchOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    }
  };
  const url = `${apiURL}/user-roles/${role}`;
  const response = await fetch(url, options);
  return response;
};

export const requestCreateRoles = async (payload: Partial<Role>[]) => {
  const token = await getToken();
  const options = {
    method: 'PUT',
    headers: {
      authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ roles: payload })
  };
  const url = `${apiURL}/user-roles/metadata/all`;
  const response = await fetch(url, options);
  return response;
};

export const requestUpdateRole = async (targetUsers: string[], currentRole: string, newRole: string) => {
  const token = await getToken();
  const payload = {
    users: targetUsers.map(userID => ({
      userID,
      role: newRole
    }))
  };

  const options: FetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    },
    body: JSON.stringify(payload)
  };
  const url = `${apiURL}/user-roles/assign-user-remove/${currentRole}`;
  const response = await fetch(url, options);
  return response;
};

export const requestExistingUsersWithRole = async (role: string) => {
  const token = await getToken();
  const options: FetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    }
  };
  const url = `${apiURL}/user-roles/${role}`;
  const response = await fetch(url, options);
  return response;
};

export const createNewActivity = async (activity: Activity) => {
  const token = await getToken();
  const options: FetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    },
    body: JSON.stringify(activity)
  };
  const url = `${apiURL}/activities`;
  const response = await fetch(url, options);
  return response;
};

export const sendIssueReadStatus = async (issueID: string) => {
  const token = await getToken();
  const payload = { issueID };
  const options: FetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    },
    body: JSON.stringify(payload)
  };
  const url = `${apiURL}/issues/issues/readStatus`;
  const response = await fetch(url, options);
  return response;
};
