import {
  CREATE_SitePayload,
  UPDATE_SitePayload,
  UPSERT_SiteResponse,
  ErrorResponse,
  BLOCK_SitePayload,
  BLOCK_SiteResponse,
  FETCH_SiteScheduleCountResponse
} from '../../fetch-types/manage-sites-fetch.types';

export type UpsertedSiteID = UPSERT_SiteResponse['data'];
export type BlockedSiteID = BLOCK_SiteResponse['data'];
export type CheckedSiteSchedules = FETCH_SiteScheduleCountResponse['data'];

export interface SitesState {
  readonly upsertedSite_loading: boolean;
  readonly upsertedSite_siteID: UpsertedSiteID;
  readonly upsertedSite_error: string;
  readonly blockSite_loading: boolean;
  readonly blockSite_siteID: BlockedSiteID;
  readonly blockSite_error: string;
  readonly checkSiteSchedule_loading: boolean;
  readonly checkSiteSchedule_count: number;
  readonly checkSiteSchedule_error: string;
}

export const CREATE_SITE_REQUEST = '@@admin/manage/sites/CREATE_SITE_REQUEST';
export const CREATE_SITE_SUCCESS = '@@admin/manage/sites/CREATE_SITE_SUCCESS';
export const CREATE_SITE_FAILURE = '@@admin/manage/sites/CREATE_SITE_FAILURE';

export type CreateSitePayload = CREATE_SitePayload;

export const UPDATE_SITE_REQUEST = '@@admin/manage/sites/UPDATE_SITE_REQUEST';
export const UPDATE_SITE_SUCCESS = '@@admin/manage/sites/UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_FAILURE = '@@admin/manage/sites/UPDATE_SITE_FAILURE';

export type UpdateSitePayload = UPDATE_SitePayload;

export type UpsertSiteResponse = UPSERT_SiteResponse | ErrorResponse;

export const CHECK_SITE_SCHEDULE_COUNT_REQUEST = '@@admin/manage/sites/CHECK_SITE_SCHEDULE_COUNT_REQUEST';
export const CHECK_SITE_SCHEDULE_COUNT_SUCCESS = '@@admin/manage/sites/CHECK_SITE_SCHEDULE_COUNT_SUCCESS';
export const CHECK_SITE_SCHEDULE_COUNT_FAILURE = '@@admin/manage/sites/CHECK_SITE_SCHEDULE_COUNT_FAILURE';

export type CheckSiteScheduleCountPayload = string;
export type CheckSiteScheduleCountResponse = FETCH_SiteScheduleCountResponse | ErrorResponse;

export const TOGGLE_BLOCK_SITE_REQUEST = '@@admin/manage/sites/TOGGLE_BLOCK_SITE_REQUEST';
export const TOGGLE_BLOCK_SITE_SUCCESS = '@@admin/manage/sites/TOGGLE_BLOCK_SITE_SUCCESS';
export const TOGGLE_BLOCK_SITE_FAILURE = '@@admin/manage/sites/TOGGLE_BLOCK_SITE_FAILURE';

export type ToggleBlockSitePayload = BLOCK_SitePayload;
export type ToggleBlockSiteResponse = BLOCK_SiteResponse | ErrorResponse;

export const CLEAR_SITE_STATE = '@@admin/manage/sites/CLEAR_SITE_STATE';
export const CLEAR_TOGGLE_BLOCK_SITE_STATE = '@@admin/manage/sites/CLEAR_BLOCK_SITE_STATE';
