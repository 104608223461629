import { ActionType, createReducer } from 'typesafe-actions';
import { SyllabusesState, SyllabusesFetchState } from './type.d';
import update from 'immutability-helper';
import * as actions from './syllabuses.action';
import * as types from './syllabuses.actionTypes';

export type SyllabusesAction = ActionType<typeof actions>;

export const initialState: SyllabusesState = {
	error: '',
	fetchState: SyllabusesFetchState.SUCCESS,
	syllabuses: [],

	totalPages: 0,
	totalDocs: 0,
	page: 1,
	limit: 15,

	sortField: 'createdDate',
	sortDirection: 'desc',
	search: '',
	filters: {
		userIDs: [],
		startDate: '',
		endDate: '',
	},
	confirmationModalConfig: {
		isVisible: false,
		title: '',
		content: '',
		confirmButtonText: '',
		cancelButtonText: '',
		onCancel: () => {},
		onConfirm: () => {},
	},
	isConfirmationModalOpen: false,
};

export const syllabusesReducer = createReducer<SyllabusesState, SyllabusesAction>(initialState)
	.handleType(types.SET_SYLLABUSES_PAGE_INDEX, (state, action) =>
		update(state, {
			fetchState: { $set: SyllabusesFetchState.LOADING },
			page: { $set: action.payload.pageIndex },
		}),
	)
	.handleType(types.SET_SYLLABUSES_SEARCH_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: SyllabusesFetchState.LOADING },
			page: { $set: initialState.page },
			search: { $set: action.payload.searchQuery },
		}),
	)
	.handleType(types.SET_SYLLABUSES_SORT, (state, action) =>
		update(state, {
			fetchState: { $set: SyllabusesFetchState.LOADING },
			page: { $set: initialState.page },
			sortField: { $set: action.payload.sortField },
			sortDirection: { $set: action.payload.sortDirection },
		}),
	)
	.handleType(types.SET_SYLLABUSES_FILTER_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: SyllabusesFetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: { ...state.filters, ...action.payload.filters } },
		}),
	)
	.handleType(types.RESET_SYLLABUSES_FILTER_QUERY, (state) =>
		update(state, {
			fetchState: { $set: SyllabusesFetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_SYLLABUSES_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: SyllabusesFetchState.LOADING },
			syllabuses: { $set: initialState.syllabuses },
			page: { $set: initialState.page },
			sortField: { $set: initialState.sortField },
			sortDirection: { $set: initialState.sortDirection },
			search: { $set: initialState.search },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_SYLLABUSES_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: SyllabusesFetchState.SUCCESS },
			syllabuses: { $set: action.payload.syllabuses },
			totalDocs: { $set: action.payload.totalDocs || 0 },
			page: { $set: action.payload.page || 0 },
			totalPages: { $set: action.payload.totalPages || 0 },
		}),
	)
	.handleType(types.FETCH_SYLLABUSES_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: SyllabusesFetchState.ERROR },
			syllabuses: { $set: initialState.syllabuses },
		}),
	)
	.handleType(types.TOGGLE_CONFIRMATION_MODAL, (state, action) =>
		update(state, {
			isConfirmationModalOpen: { $set: action.payload.isOpen },
		}),
	)
	.handleType(types.SET_CONFIRMATION_MODAL_CONFIG, (state, action) =>
		update(state, {
			confirmationModalConfig: {
				$set: {
					...state.confirmationModalConfig,
					...action.payload.modalConfig,
				},
			},
		}),
	);
