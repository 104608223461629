import { enums, IssueResolutionRate } from '@nimbly-technologies/nimbly-common';

import queryString from 'query-string';
import { getToken } from 'reducers/api';
import { cloudV2ApiURL } from 'config/baseURL';
import { FetchIssuesResolutionRateQuery } from 'reducers/dashboard/issues/issuesResolutionRate/types';

export const fetchIssuesResolutionRate = async (query: FetchIssuesResolutionRateQuery) => {
	try {
		const payload: FetchIssuesResolutionRateQuery = {
			periodType: query.periodType,
			startDate: query.startDate,
			endDate: query.endDate,
		};

		if (query?.departments?.length) payload.departments = query.departments;
		if (query?.sites?.length) payload.sites = query.sites;
		if (query?.users?.length) payload.users = query.users;
		if (query?.questionnaires?.length) payload.questionnaires = query.questionnaires;

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: await getToken(),
			},
			body: JSON.stringify(payload),
		};
		const url = `${cloudV2ApiURL}/statistics/issues/resolution-rate`;
		const response = await fetch(url, options);
		if (response?.status === 200) {
			const responseJson = await response.json();
			return responseJson.data as IssueResolutionRate;
		}
		throw new Error(`Failed to get response, status ${response?.status}`);
	} catch (error) {
		throw new Error(error.message);
	}
};
