import React from 'react';

import './LoadingDots.css';

const LoadingDots = () => {
	return (
		<div className="LoadingDots">
			<div className="bounce1" />
			<div className="bounce2" />
			<div className="bounce3" />
		</div>
	);
};

export default LoadingDots;
