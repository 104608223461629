import { FetchAuditorsListPayload } from 'reducers/dashboard/auditors/auditorsList/types';
import { AuditorsAuditorsList } from '../../../reducers/dashboard/auditors/auditorsList/types';

import user1ReportsSubmitted from '../../../assets/dummy/json/auditor1-number.json';
import user2ReportsSubmitted from '../../../assets/dummy/json/auditor2-number.json';
import user3ReportsSubmitted from '../../../assets/dummy/json/auditor3-number.json';
import user4ReportsSubmitted from '../../../assets/dummy/json/auditor4-number.json';
import user5ReportsSubmitted from '../../../assets/dummy/json/auditor5-number.json';
import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

const usersReportSubmitted = [
  user1ReportsSubmitted,
  user2ReportsSubmitted,
  user3ReportsSubmitted,
  user4ReportsSubmitted,
  user5ReportsSubmitted
];

const usersName = ['Herby Herado', 'Restu Utomo', 'Samuel Sandro', 'Krisnata Pandji', 'Komang Mahendra'];

const dummyAuditorsRankByDate: AuditorsAuditorsList[] = new Array(31).fill('with_date').map((_, dateIndex) => {
  const allUserCompletionThisDate: AuditorsAuditorsList = usersReportSubmitted.map((reportsSubmitted, userIndex) => {
    const completedNum = reportsSubmitted[dateIndex];
    const dummySchedule = completedNum % 2 ? completedNum + (completedNum % 3) : completedNum;
    return {
      completedNum,
      adhocNum: completedNum % 3,
      scheduledNum: dummySchedule,
      missedNum: dummySchedule - completedNum,
      isRateValid: !!completedNum,
      completionRate: !!completedNum ? completedNum / dummySchedule : 0,
      isGrowthValid: true,
      completionGrowth: Math.floor(Math.random() * 1000) / 1000,
      madeUpNum: (dummySchedule - completedNum) % 3,
      pendingNum: dummySchedule % 3,
      supervisors: [
        {
          uid: 'user_0',
          userName: 'Yow Zi Shing'
        }
      ],
      userID: `user_${userIndex + 1}`,
      userName: usersName[userIndex]
    };
  });

  return allUserCompletionThisDate;
});

export const getDummyAuditorsList = (payload: FetchAuditorsListPayload) => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let selected_dummyAuditorsOnRange: AuditorsAuditorsList[] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    selected_dummyAuditorsOnRange = dummyAuditorsRankByDate.slice(-diffInDay);
  } else {
    selected_dummyAuditorsOnRange = dummyAuditorsRankByDate.slice(0, diffInDay);
  }

  return selected_dummyAuditorsOnRange.reduce((curr, acc) => {
    return curr.map((_, index) => {
      const completed_total = curr[index].completedNum + acc[index].completedNum;
      const scheduled_total = curr[index].scheduledNum + acc[index].scheduledNum;
      const isRateValid = !!completed_total;
      const completionRate = isRateValid ? completed_total / scheduled_total : 0;

      return {
        completedNum: completed_total,
        adhocNum: curr[index].adhocNum + acc[index].adhocNum,
        scheduledNum: scheduled_total,
        missedNum: curr[index].missedNum + acc[index].missedNum,
        isRateValid,
        completionRate,
        isGrowthValid: true,
        completionGrowth: (curr[index].completionGrowth + acc[index].completionGrowth) / 2,
        madeUpNum: curr[index].madeUpNum + acc[index].madeUpNum,
        pendingNum: curr[index].pendingNum + acc[index].pendingNum,
        supervisors: curr[index].supervisors,
        userID: curr[index].userID,
        userName: curr[index].userName
      };
    });
  });
};
