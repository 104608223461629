import { createAsyncAction, action, createAction } from 'typesafe-actions';
import { CompetitorProduct, ProductCategory, CompanyTargetMarket, ProductPrice, Product } from 'nimbly-common';
import * as types from './product.actionTypes';

type ProductUpdatePayload = {
  category: string[];
  companyID: string;
  description: string;
  photos: string | File[];
  name: string;
  price: ProductPrice;
  stores: string[];
  targetMarket: string[];
  variant: string;
  organizationID: string;
  option: string | null;
  code: string;
  image: string;
};

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });
export const setSort = (sortType: string, sortOrder: string) => action(types.SET_SORT, { sortType, sortOrder });
export const setFilterQuery = (text: string) => action(types.SET_FILTER_QUERY, { text });
export const setPageIndex = (value: number) => action(types.SET_PAGE_INDEX, { value });

export const fetchProduct = createAsyncAction(
  types.FETCH_PRODUCTS_REQUEST,
  types.FETCH_PRODUCTS_SUCCESS,
  types.FETCH_PRODUCTS_FAILURE
)<undefined, CompetitorProduct[], { error: string }>();

export const fetchPaginationProductAsync = createAsyncAction(
  types.FETCH_PRODUCTS_PAGINATION_REQUEST,
  types.FETCH_PRODUCTS_PAGINATION_SUCCESS,
  types.FETCH_PRODUCTS_PAGINATION_FAILURE
)<undefined, { data: CompetitorProduct[]; total: number }, { error: string }>();

export const createProduct = createAsyncAction(
  types.CREATE_PRODUCTS_REQUEST,
  types.CREATE_PRODUCTS_SUCCESS,
  types.CREATE_PRODUCTS_FAILURE
)<types.POST_ProductsPayload, string, { error: string }>();

export const createProductBulk = createAsyncAction(
  types.CREATE_PRODUCTS_BULK_REQUEST,
  types.CREATE_PRODUCTS_BULK_SUCCESS,
  types.CREATE_PRODUCTS_BULK_FAILURE
)<types.POST_ProductsBulkPayload, string, string[]>();

export const fetchProductDetail = createAsyncAction(
  types.FETCH_PRODUCT_DETAIL_REQUEST,
  types.FETCH_PRODUCT_DETAIL_SUCCESS,
  types.FETCH_PRODUCT_DETAIL_FAILURE
)<string, Product, { error: string }>();

export const updateProduct = createAsyncAction(
  types.UPDATE_PRODUCT_REQUEST,
  types.UPDATE_PRODUCT_SUCCESS,
  types.UPDATE_PRODUCT_FAILURE
)<types.PUT_ProductsPayload, string, { error: string }>();

export const deleteProduct = createAsyncAction(
  types.DELETE_PRODUCT_REQUEST,
  types.DELETE_PRODUCT_SUCCESS,
  types.DELETE_PRODUCT_FAILURE
)<any, any, { error: string }>();

export const fetchCategory = createAsyncAction(
  types.FETCH_PRODUCT_CATEGORY_REQUEST,
  types.FETCH_PRODUCT_CATEGORY_SUCCESS,
  types.FETCH_PRODUCT_CATEGORY_FAILURE
)<undefined, { data: ProductCategory[] }, { error: string }>();

export const fetchTargetMarket = createAsyncAction(
  types.FETCH_PRODUCT_TARGET_MARKET_REQUEST,
  types.FETCH_PRODUCT_TARGET_MARKET_SUCCESS,
  types.FETCH_PRODUCT_TARGET_MARKET_FAILURE
)<undefined, { data: CompanyTargetMarket[] }, { error: string }>();

export const flushProductState = createAction(types.FLUSH_PRODUCT_STATE)<undefined>();
