import { CompetitorProduct } from 'nimbly-common';
import { CompetitorPromotion, Company, CompetitorCompanySummary } from 'nimbly-common';

export const SET_LOADING = '@company/SET_LOADING';
export const SET_SORT = '@company/SET_SORT';
// export const UPDATE_COMPANY_DETAIL = '@company/UPDATE_COMPANY_DETAIL';

export const FETCH_COMPANIES_REQUEST = '@company/FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = '@company/FETCH_COMPANIES_LOADING';
export const FETCH_COMPANIES_FAILURE = '@company/FETCH_COMPANIES_FAILURE';

export type FETCH_CompaniesResponse = {
  message: string;
  error: string;
  data: CompetitorCompanySummary[];
};

export const CREATE_COMPANIES_REQUEST = '@company/CREATE_COMPANIES_REQUEST';
export const CREATE_COMPANIES_SUCCESS = '@company/CREATE_COMPANIES_SUCCESS';
export const CREATE_COMPANIES_FAILURE = '@company/CREATE_COMPANIES_FAILURE';

export type POST_CompaniesPayload = {
  company: Partial<Company>;
  logoFile: (string | File)[];
};
export type POST_CompaniesResponse = {
  message: string;
  error: string;
  data: CompetitorCompanySummary;
};

export const CREATE_COMPANIES_BULK_REQUEST = '@company/CREATE_COMPANIES_BULK_REQUEST';
export const CREATE_COMPANIES_BULK_SUCCESS = '@company/CREATE_COMPANIES_BULK_SUCCESS';
export const CREATE_COMPANIES_BULK_FAILURE = '@company/CREATE_COMPANIES_BULK_FAILURE';

export type POST_BulkCompaniesPayload = Partial<Company>[];

export const FETCH_COMPANY_DETAIL_REQUEST = '@company/FETCH_COMPANY_DETAIL_REQUEST';
export const FETCH_COMPANY_DETAIL_SUCCESS = '@company/FETCH_COMPANY_DETAIL_SUCCESS';
export const FETCH_COMPANY_DETAIL_FAILURE = '@company/FETCH_COMPANY_DETAIL_FAILURE';

export type FETCH_CompanyDetailResponse = {
  message: string;
  error: string;
  data: Company;
};

export const UPDATE_COMPANY_REQUEST = '@company/UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = '@company/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = '@company/UPDATE_COMPANY_FAILURE';

export type PUT_CompaniesPayload = {
  company: Partial<Company>;
  logoFile: (string | File)[];
};
export type PUT_CompaniesResponse = {
  message: string;
  error: string;
  data: Company;
};

export const DELETE_COMPANY_REQUEST = '@company/DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = '@company/DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = '@company/DELETE_COMPANY_FAILURE';

export const FETCH_COMPANY_PRODUCTS_REQUEST = '@company/FETCH_COMPANY_PRODUCTS_REQUEST';
export const FETCH_COMPANY_PRODUCTS_SUCCESS = '@company/FETCH_COMPANY_PRODUCTS_SUCCESS';
export const FETCH_COMPANY_PRODUCTS_FAILURE = '@company/FETCH_COMPANY_PRODUCTS_FAILURE';

export const FETCH_COMPANY_PROMOTIONS_REQUEST = '@company/FETCH_COMPANY_PROMOTIONS_REQUEST';
export const FETCH_COMPANY_PROMOTIONS_SUCCESS = '@company/FETCH_COMPANY_PROMOTIONS_SUCCESS';
export const FETCH_COMPANY_PROMOTIONS_FAILURE = '@company/FETCH_COMPANY_PROMOTIONS_FAILURE';

export type FETCH_CompanyProductsPayload = {
  companyCode: string;
  companyName: string;
};
export type FETCH_CompanyProductsResponse = {
  message: string;
  error: string;
  data: CompetitorProduct[];
};
export type FETCH_CompanyPromotionsPayload = {
  companyCode: string;
  companyName: string;
};
export type FETCH_CompanyPromotionsResponse = {
  message: string;
  error: string;
  data: CompetitorPromotion[];
};
