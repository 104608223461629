export function getStartEpochTimeInMillisecondsFromUTC(dateString: string) {
	// Step 1: Parse the date string into a Date object
	const dateObj = new Date(dateString);

	// Step 2: Convert the Date object to the UTC time
	const utcDateObj = new Date(Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate()));

	// Step 3: Get the epoch time in milliseconds
	const epochTimeInMilliseconds = utcDateObj.getTime();

	return epochTimeInMilliseconds;
}

export function getEndOfDayEpochTimeInMillisecondsFromUTC(dateString: string) {
	// Step 1: Parse the date string into a Date object
	const dateObj = new Date(dateString);

	// Step 2: Set the time to the end of the day in local time
	dateObj.setHours(23, 59, 59, 999);

	// Step 3: Convert the local time to UTC time
	const utcDateObj = new Date(
		Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate(), 23, 59, 59, 999),
	);

	// Step 4: Get the epoch time in milliseconds
	const epochTimeInMilliseconds = utcDateObj.getTime();

	return epochTimeInMilliseconds;
}
