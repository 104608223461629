import * as Common from '@nimbly-technologies/nimbly-common';
import { ScheduleActivePeriodUnit } from '@nimbly-technologies/nimbly-common/lib/enumerators/periodUnit';
import { DataValue } from 'types/myTypes';
import { action, createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './siteSchedule.actionTypes';
import {
	QuestionnaireScheduleError,
	QuestionnaireScheduleProps,
	SelectedSchedule,
	SiteScheduleState,
	UpdatedSchedule,
	ExtendedSiteScheduleIndex,
} from './siteSchedule.reducer';
export const setLoadingState = (bool: boolean) => action(types.SET_LOADING_STATE, { bool });

// Single Site Schedule actions
export const setInitialSchedules = (
	allSchedules: DataValue<Common.SiteScheduleEvent>[],
	singleAuditSchedules: DataValue<Common.SiteScheduleEvent>[],
	teamAuditSchedule: null | DataValue<Common.SiteScheduleEvent>,
) => action(types.SET_INITIAL_SCHEDULES, { allSchedules, singleAuditSchedules, teamAuditSchedule });

export const setHasChanges = (hasChanges: boolean) => action(types.SET_HAS_CHANGES, { hasChanges });

export const setSingleAuditScheduleList = (schedules: DataValue<Common.SiteScheduleEvent>[]) =>
	action(types.SET_SINGLE_AUDIT_SCHEDULE_LIST, { list: schedules });

export const setSelectedQuestionnaireSchedule = (
	questionnaireSchedule: DataValue<Common.SiteScheduleEvent>,
	index: number,
) => action(types.SET_SELECTED_QUESTIONNAIRE_SCHEDULE, { schedule: questionnaireSchedule, index });

export const removeSelectedQuestionnaireSchedule = () => action(types.REMOVE_SELECTED_QUESTIONNAIRE_SCHEDULE);

export const addQuestionnaireSchedule = (questionnaireSchedule: DataValue<Common.SiteScheduleEvent>) =>
	action(types.ADD_QUESTIONNAIRE_SCHEDULE, { schedule: questionnaireSchedule });

// START TEAM AUDIT ACTIONS
export const setTeamAuditSchedule = (schedule: DataValue<Common.SiteScheduleEvent>) =>
	action(types.SET_TEAM_AUDIT_SCHEDULE, { schedule });

export const addTeamAuditQuestionnaire = (questionnaireIndexKey: string) =>
	action(types.ADD_TEAM_AUDIT_QUESTIONNAIRE, { questionnaireIndexKey });
// END TEAM AUDIT ACTIONS

/**
 * Remove one questionnaire from single audit schedule list
 */
export const spliceSelectedSchedule = (index: number) => action(types.SPLICE_SELECTED_SCHEDULE, { index });
export const setQuestionnaireScheduleType = (index: number, type: 'custom' | 'recurring') =>
	action(types.SET_QUESTIONNAIRE_SCHEDULE_TYPE, { index, type });

export const setQuestionnaireScheduleFrequency = (index: number, frequency: 'daily' | 'weekly' | 'monthly') =>
	action(types.SET_QUESTIONNAIRE_SCHEDULE_FREQUENCY, { index, frequency });

export const toggleQuestionnaireScheduleDate = (index: number, date: string) =>
	action(types.TOGGLE_QUESTIONNAIRE_SCHEDULE_DATE, { index, date });

/**
 * @param index - index number of questionnaire schedule in redux array
 * @param day - isoWeekday 1 - 7
 */
export const addQuestionnaireScheduleWeekday = (index: number, day: number) =>
	action(types.ADD_QUESTIONNAIRE_SCHEDULE_WEEKDAY, { index, day });

/**
 * @param index - index number of questionnaire schedule in redux array
 * @param dayIndex - index of removing day in `daysOfWeek` array
 */
export const removeQuestionnaireScheduleWeekday = (index: number, dayIndex: number) =>
	action(types.REMOVE_QUESTIONNAIRE_SCHEDULE_WEEKDAY, { index, dayIndex });
/**
 * @param index - index number of questionnaire schedule in redux array
 * @param date - isoWeekday 1 - 7
 */
export const addQuestionnaireScheduleMonthDate = (index: number, date: number) =>
	action(types.ADD_QUESTIONNAIRE_SCHEDULE_MONTH_DATE, { index, date });

/**
 * @param index - index number of questionnaire schedule in redux array
 * @param dateIndex - index of removing day in `datesOfMonth` array
 */
export const removeQuestionnaireScheduleMonthDate = (index: number, dateIndex: number) =>
	action(types.REMOVE_QUESTIONNAIRE_SCHEDULE_MONTH_DATE, { index, dateIndex });

/**
 * @param index - index number of questionnaire schedule in redux array
 */
export const addQuestionnaireScheduleEmailTarget = (index: number) =>
	action(types.ADD_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET, { index });

/**
 * @param index - index number of questionnaire schedule in redux array
 * @param emailIndex - index number of email schedule in `emailTargets`
 * @param text - email target
 */
export const updateQuestionnaireScheduleEmailTarget = (index: number, emailIndex: number, text: string) =>
	action(types.UPDATE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET, { index, emailIndex, text });

/**
 * @param index - index number of questionnaire schedule in redux array
 * @param emailIndex - index of removing day in `emailTargets` array
 */
export const removeQuestionnaireScheduleEmailTarget = (index: number, emailIndex: number) =>
	action(types.REMOVE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET, { index, emailIndex });

/**
 * @param index - index number of questionnaire schedule in redux array
 * @param value - number of signatures required
 */
export const setQuestionnaireScheduleSignatures = (index: number, value: number) =>
	action(types.SET_QUESTIONNAIRE_SCHEDULE_SIGNATURES, { index, value });

export const setQuestionnaireDeadlineTime = (index: number, name: 'startTime' | 'endTime', value: number) =>
	action(types.SET_QUESTIONNAIRE_DEADLINE_TIME, { index, name, value });

export const setQuestionnaireDeadlineStatus = (index: number, bool: boolean) =>
	action(types.SET_QUESTIONNAIRE_DEADLINE_STATUS, { index, bool });

export const setQuestionnaireDeadlineAuditor = (index: number, userKey: string, team: string[]) =>
	action(types.SET_QUESTIONNAIRE_DEADLINE_AUDITOR, { index, userKey, team });

export const setQuestionnaireScheduleErrors = (errorMessages: { [index: number]: QuestionnaireScheduleError }) =>
	action(types.SET_QUESTIONNAIRE_SCHEDULES_ERRORS, { errorMessages });

export const setQuestionnaireDeadlineReminderTime = (index: number, value: number | null) =>
	action(types.SET_QUESTIONNAIRE_DEADLINE_REMINDER_TIME, { index, value });

export const setQuestionnaireScheduleSelfieSignature = (index: number) =>
	action(types.SET_QUESTIONNAIRE_SELFIE_SIGNATURE, { index });

export const setQuestionnaireScheduleSelfieTitle = (index: number, value: string, titleIndex: number) =>
	action(types.SET_QUESTIONNAIRE_SELFIE_SIGNATURE_TITLE, { index, value, titleIndex });

export const setQuestionnaireScheduleStartDate = (index: number, date: string) =>
	action(types.SET_QUESTIONNAIRE_SCHEDULE_START_DATE, { index, date });

export const setQuestionnaireScheduleEndDate = (index: number, date: string) =>
	action(types.SET_QUESTIONNAIRE_SCHEDULE_END_DATE, { index, date });

/**
 * @param index - index number on questionnaire schedule to be updated
 * @param property - property name of questionnaire schedule errors to be updated
 * @param value - value of questionnaire schedule errors to be updated
 */
export const updateScheduleValidation = <K extends keyof QuestionnaireScheduleError>(
	index: number,
	property: K,
	value: QuestionnaireScheduleError[K],
) => action(types.UPDATE_QUESTIONNAIRE_SCHEDULE_VALIDATION, { index, property, value });

/**
 * @param index - index number on questionnaire schedule errors to be removed
 */
export const removeScheduleValidation = (index: number) =>
	action(types.REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION, { index });

/**
 * @param index - index number on questionnaire schedule to be updated
 * @param property - property name of questionnaire schedule errors to be removed
 */
export const removeScheduleValidationProperty = (index: number, property: QuestionnaireScheduleProps) =>
	action(types.REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION_PROPERTY, { index, property });

export const clearSiteScheduleState = () => action(types.CLEAR_SITE_SCHEDULE_STATE);

// After schedule improvement
export const reset = () => action(types.RESET);

export const setActiveDepartment = (payload: { departmentID: string }) => action(types.SET_ACTIVE_DEPARTMENT, payload);

export const selectSchedule = (value: SelectedSchedule) => action(types.SELECT_SCHEDULE, { ...value });
export const removeSelectedSchedule = () => action(types.REMOVE_SELECTED_SCHEDULE);
export const updateSelectedScheduleState = createAction(types.UPDATE_SELECTED_SCHEDULE_STATE)<SelectedSchedule>();
export const pushNewSchedule = createAction(types.PUSH_NEW_SCHEDULE)<{
	schedule: ExtendedSiteScheduleIndex;
	select?: boolean;
}>();

export const setSelectedScheduleType = (payload: { type: Common.enums.ScheduleTypes; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_TYPE, payload);

export const setSelectedScheduleRepeatType = (payload: { type: Common.enums.ScheduleTypes; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_REPEAT_TYPE, payload);

export const setSelectedScheduleDaysOfWeek = (payload: { daysOfWeek: number[]; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_DAYS_OF_WEEK, payload);

export const setSelectedScheduleDatesOfMonth = (payload: { datesOfMonth: number[]; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_DATES_OF_MONTH, payload);

export const setSelectedScheduleDatesCustom = (payload: {
	datesCustom: { [date: string]: boolean };
	indexOrigin: number;
}) => action(types.SET_SELECTED_SCHEDULE_DATES_CUSTOM, payload);

export const setSelectedScheduleHasDeadline = (payload: { bool: boolean; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_HAS_DEADLINE, payload);

export const setSelectedScheduleAssignedAuditor = (payload: { uid: string; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_ASSIGNED_AUDITOR, payload);

export const setSelectedScheduleIssueOwner = (payload: { uid: string; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_ISSUE_OWNER, payload);

export const setSelectedScheduleStartTime = (payload: { time: number | null; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_START_TIME, payload);

export const setSelectedScheduleEndTime = (payload: { time: number | null; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_END_TIME, payload);

export const setSelectedScheduleReminderTime = (payload: { time: number | null; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_REMINDER_TIME, payload);

export const setSelectedScheduleStartDate = (payload: { indexOrigin: number; date: string }) =>
	action(types.SET_SELECTED_SCHEDULE_START_DATE, payload);

export const setSelectedScheduleEndDate = (payload: { indexOrigin: number; date: string }) =>
	action(types.SET_SELECTED_SCHEDULE_END_DATE, payload);

export const setSelectedScheduleSignatures = (payload: { indexOrigin: number; count: number }) =>
	action(types.SET_SELECTED_SCHEDULE_SIGNATURES, payload);

export const setSelectedScheduleSelfieSignature = (payload: { indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE, payload);

export const setSelectedScheduleSelfieSignatureTitle = (payload: {
	indexOrigin: number;
	title: string;
	indexSignature: number;
}) => action(types.SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE_TITLE, payload);

export const setSelectedScheduleSynchronizeCalendar = (payload: { indexOrigin: number }) =>
	action(types.TOGGLE_SELECTED_SCHEDULE_SYNCHRONIZE_CALENDAR, payload);

export const setSelectedScheduleEmailTarget = (payload: { indexOrigin: number; email: string; indexEmail: number }) =>
	action(types.SET_SELECTED_SCHEDULE_EMAIL_TARGET, payload);

export const addSelectedScheduleEmailTarget = (payload: { indexOrigin: number }) =>
	action(types.ADD_SELECTED_SCHEDULE_EMAIL_TARGET, payload);

export const removeSelectedScheduleEmailTarget = (payload: { indexOrigin: number; indexEmail: number }) =>
	action(types.REMOVE_SELECTED_SCHEDULE_EMAIL_TARGET, payload);

export const setFirstScheduleStartDate = (payload: { date: Date | null; indexOrigin: number }) =>
	action(types.SET_FIRST_SCHEDULE_START_DATE, payload);

export const setFirstScheduleEndDate = (payload: { date: Date | null; indexOrigin: number }) =>
	action(types.SET_FIRST_SCHEDULE_END_DATE, payload);

export const setRepeatingType = (payload: { type: Common.enums.RepeatingTypes; indexOrigin: number }) =>
	action(types.SET_REPEATING_TYPE, payload);

export const setOccurenceNumber = (payload: { value: number | null; indexOrigin: number }) =>
	action(types.SET_OCCURENCE_NUMBER, payload);

export const setRepeatingEndDate = (payload: { date: Date; indexOrigin: number }) =>
	action(types.SET_REPEATING_END_DATE, payload);

export const setSelectedScheduleEmail = (payload: { email: string; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_EMAIL, payload);

export const setUpdateAllEndTime = (payload: { date: Date | null; indexOrigin: number }) =>
	action(types.SET_UPDATE_ALL_END_TIME, payload);

export const setScheduleStartAt = (payload: { date: Date | null; indexOrigin: number }) =>
	action(types.SET_SCHEDULE_START_AT, payload);

export const setSelectedScheduleAllowAdhoc = (payload: { bool: boolean; indexOrigin: number }) =>
	action(types.SET_ALLOW_ADHOC_REPORT, payload);

export const setSelectedScheduleRestrictTime = (payload: { bool: boolean; indexOrigin: number }) =>
	action(types.SET_ALLOW_RESTRICT_TIME, payload);

export const setSelectedScheduleCheckIn = (payload: { bool: boolean; indexOrigin: number }) =>
	action(types.SET_ALLOW_ENFORCE_CHECKIN, payload);

export const setSelectedScheduleCheckOut = (payload: { bool: boolean; indexOrigin: number }) =>
	action(types.SET_ALLOW_ENFORCE_CHECKOUT, payload);

export const setSelectedCustomAdhocOnly = (payload: { bool: boolean; indexOrigin: number }) =>
	action(types.SET_ALLOW_CUSTOM_ADHOC_ONLY, payload);

export const setCustomV2Add = (payload: { indexOrigin: number }) => action(types.SET_CUSTOMV2_ADD_ELEMENT, payload);

export const setCustomV2Delete = (payload: { customIndex: number; indexOrigin: number }) =>
	action(types.SET_CUSTOMV2_DELETE_ELEMENT, payload);

export const setCustomV2StartDate = (payload: { date: Date | null; customIndex: number; indexOrigin: number }) =>
	action(types.SET_CUSTOMV2_START_DATE, payload);

export const setCustomV2EndDate = (payload: { date: Date | null; customIndex: number; indexOrigin: number }) =>
	action(types.SET_CUSTOMV2_END_DATE, payload);

export const setCustomV2AddDate = (payload: { startDate: Date | null; endDate: Date | null; indexOrigin: number }) =>
	action(types.SET_CUSTOMV2_ADD_DATE, payload);

export const setFilterStatus = (payload: { status: SiteScheduleState['filter']['status'] }) =>
	action(types.SET_FILTER_STATUS, payload);

export const setSelectedScheduleReminderPeriod = (payload: {
	type: Common.enums.ReminderPeriod;
	indexOrigin: number;
}) => action(types.SET_SELECTED_SCHEDULE_REMINDER_PERIOD, payload);

export const setDepartment = (payload: { dept: string; indexOrigin: number }) =>
	action(types.SET_SELECTED_SCHEDULE_DEPARTMENT, payload);

export const setSupervisor = (payload: { userID: string; indexOrigin: number }) =>
	action(types.SET_SCHEDULE_SUPERVISOR, payload);
/**
 * add new single audit schedule
 */
export const addNewSchedule = createAsyncAction(
	types.ADD_NEW_SCHEDULE_REQUEST,
	types.ADD_NEW_SCHEDULE_SUCCESS,
	types.ADD_NEW_SCHEDULE_FAILURE,
)<
	{
		questionnaireIndexID: string;
		departmentID: string;
		siteID: string;
	},
	ExtendedSiteScheduleIndex,
	{ error: string }
>();

// Saga actions
export const fetchSiteSchedules = createAsyncAction(
	types.FETCH_SITE_SCHEDULES_REQUEST,
	types.FETCH_SITE_SCHEDULES_SUCCESS,
	types.FETCH_SITE_SCHEDULES_FAILURE,
)<
	{ siteID: string; status: SiteScheduleState['filter']['status'] },
	{ schedules: ExtendedSiteScheduleIndex[] },
	{ error: string }
>();

export const uploadSiteSchedules = createAsyncAction(
	types.UPLOAD_SITE_SCHEDULES_REQUEST,
	types.UPLOAD_SITE_SCHEDULES_SUCCESS,
	types.UPLOAD_SITE_SCHEDULES_FAILURE,
)<{ schedules: ExtendedSiteScheduleIndex[]; siteID: string }, any, { errors: string[] }>();

export const uploadTeamSiteSchedules = createAsyncAction(
	types.UPLOAD_TEAM_SITE_SCHEDULES_REQUEST,
	types.UPLOAD_TEAM_SITE_SCHEDULES_SUCCESS,
	types.UPLOAD_TEAM_SITE_SCHEDULES_FAILURE,
)<{ siteID: string; schedules: ExtendedSiteScheduleIndex[] }, undefined, { errors: string }>();

export const syncSiteSchedulesCalendar = createAsyncAction(
	types.SYNC_SITE_SCHEDULES_CALENDAR_REQUEST,
	types.SYNC_SITE_SCHEDULES_CALENDAR_SUCCESS,
	types.SYNC_SITE_SCHEDULES_CALENDAR_FAILURE,
)<{ updatedSchedules: UpdatedSchedule[]; siteID: string }, any, { error: string }>();

export const importSchedules = createAsyncAction(
	types.IMPORT_SCHEDULES_REQUEST,
	types.IMPORT_SCHEDULES_SUCCESS,
	types.IMPORT_SCHEDULES_FAILURE,
)<
	{ schedules: ExtendedSiteScheduleIndex[]; activeDepartment: string; siteID: string },
	{ schedules: ExtendedSiteScheduleIndex[] },
	{ error: string }
>();

export const toggleAllowActiveSchedulePeriod = (payload: { indexOrigin: number }) =>
	action(types.TOGGLE_ALLOW_ACTIVE_SCHEDULE_PERIOD, payload);
export const setActiveSchedulePeriodUnit = (payload: { unit: ScheduleActivePeriodUnit; indexOrigin: number }) =>
	action(types.SET_ACTIVE_SCHEDULE_PERIOD_UNIT, payload);
export const setActiveSchedulePeriodLength = (payload: { length: number; indexOrigin: number }) =>
	action(types.SET_ACTIVE_SCHEDULE_PERIOD_LENGTH, payload);
