export const SET_LOADING = '@journeys/SET_LOADING';

export const FETCH_JOURNEYS_REQUEST = '@journeys/FETCH_JOURNEYS_REQUEST';
export const FETCH_JOURNEYS_SUCCESS = '@journeys/FETCH_JOURNEYS_LOADING';
export const FETCH_JOURNEYS_FAILURE = '@journeys/FETCH_JOURNEYS_FAILURE';

export const CREATE_JOURNEYS_REQUEST = '@journeys/CREATE_JOURNEYS_REQUEST';
export const CREATE_JOURNEYS_SUCCESS = '@journeys/CREATE_JOURNEYS_SUCCESS';
export const CREATE_JOURNEYS_FAILURE = '@journeys/CREATE_JOURNEYS_FAILURE';

export const CREATE_JOURNEYS_BULK_REQUEST = '@journeys/CREATE_JOURNEYS_BULK_REQUEST';
export const CREATE_JOURNEYS_BULK_SUCCESS = '@journeys/CREATE_JOURNEYS_BULK_SUCCESS';
export const CREATE_JOURNEYS_BULK_FAILURE = '@journeys/CREATE_JOURNEYS_BULK_FAILURE';

export const FETCH_JOURNEY_DETAIL_REQUEST = '@journeys/FETCH_JOURNEY_DETAIL_REQUEST';
export const FETCH_JOURNEY_DETAIL_SUCCESS = '@journeys/FETCH_JOURNEY_DETAIL_SUCCESS';
export const FETCH_JOURNEY_DETAIL_FAILURE = '@journeys/FETCH_JOURNEY_DETAIL_FAILURE';

export const UPDATE_JOURNEY_REQUEST = '@journeys/UPDATE_JOURNEY_REQUEST';
export const UPDATE_JOURNEY_SUCCESS = '@journeys/UPDATE_JOURNEY_SUCCESS';
export const UPDATE_JOURNEY_FAILURE = '@journeys/UPDATE_JOURNEY_FAILURE';

export const DELETE_JOURNEY_REQUEST = '@journeys/DELETE_JOURNEY_REQUEST';
export const DELETE_JOURNEY_SUCCESS = '@journeys/DELETE_JOURNEY_SUCCESS';
export const DELETE_JOURNEY_FAILURE = '@journeys/DELETE_JOURNEY_FAILURE';

export const FETCH_REPORT_JOURNEYS_REQUEST = '@journeys/FETCH_REPORT_JOURNEYS_REQUEST';
export const FETCH_REPORT_JOURNEYS_SUCCESS = '@journeys/FETCH_REPORT_JOURNEYS_LOADING';
export const FETCH_REPORT_JOURNEYS_FAILURE = '@journeys/FETCH_REPORT_JOURNEYS_FAILURE';
