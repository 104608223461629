export enum UsersCompactFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface UsersCompactState {
	error: string;
	fetchState: UsersCompactFetchState;
	usersCompact: any[];
}

export enum UsersCompactProjection {
	USER_ID = 'userID',
	NAME = 'name',
	EMAIL = 'email',
}
