import { StockAuditFetchState, StockAuditSchedulerAction, StockAuditSchedulerState } from './type.d';
import { createReducer } from 'typesafe-actions';
import * as actionTypes from './stockAuditScheduler.actionTypes';
import update from 'immutability-helper';

export const initialState: StockAuditSchedulerState = {
	fetchState: StockAuditFetchState.LOADING,

	schedules: [],
	lastUpdatedAt: 0,

	searchQuery: '',
	pageIndex: 1,
	limit: 15,
	totalSchedules: 0,

	isMobileFiltersVisible: false,
	filters: {
		types: [],
		sites: [],
		status: [],
		frequencies: [],
	},

	sortBy: 'updatedAt',
	isAscending: false,

	error: '',
};

export const schedulerReducer = createReducer<StockAuditSchedulerState, StockAuditSchedulerAction>(initialState)
	.handleType(actionTypes.FETCH_SCHEDULES_REQUEST, (state) =>
		update(state, {
			fetchState: { $set: StockAuditFetchState.LOADING },
			pageIndex: { $set: initialState.pageIndex },
			searchQuery: { $set: initialState.searchQuery },
			sortBy: { $set: initialState.sortBy },
			isAscending: { $set: initialState.isAscending },
			error: { $set: initialState.error },
		}),
	)
	.handleType(actionTypes.FETCH_SCHEDULES_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: StockAuditFetchState.SUCCESS },
			lastUpdatedAt: { $set: action.payload.lastUpdatedAt },
			schedules: { $set: action.payload.schedules },
			totalSchedules: { $set: action.payload.totalSchedules },
		}),
	)
	.handleType(actionTypes.FETCH_SCHEDULES_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: StockAuditFetchState.ERROR },
			schedules: { $set: [] },
		}),
	)
	.handleType(actionTypes.SET_SCHEDULE_PAGE_INDEX, (state, action) =>
		update(state, {
			fetchState: { $set: StockAuditFetchState.LOADING },
			pageIndex: { $set: action.payload.pageIndex },
		}),
	)
	.handleType(actionTypes.SET_SCHEDULE_SEARCH_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: StockAuditFetchState.LOADING },
			pageIndex: { $set: initialState.pageIndex },
			searchQuery: { $set: action.payload.searchQuery },
		}),
	)
	.handleType(actionTypes.SET_SCHEDULE_SORT, (state, action) =>
		update(state, {
			fetchState: { $set: StockAuditFetchState.LOADING },
			pageIndex: { $set: 1 },
			sortBy: { $set: action.payload.sortBy },
			isAscending: { $set: action.payload.isAscending },
		}),
	)
	.handleType(actionTypes.SET_SCHEDULE_FILTER_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: StockAuditFetchState.LOADING },
			pageIndex: { $set: initialState.pageIndex },
			filters: { $set: action.payload.filters },
		}),
	)
	.handleType(actionTypes.SET_SCHEDULE_MOBILE_FILTERS_VISIBLE, (state, action) =>
		update(state, {
			isMobileFiltersVisible: { $set: action.payload.isVisible },
		}),
	);
