import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import * as actions from './usersCompact.action';
import * as types from './usersCompact.actionTypes';
import { UsersCompactFetchState, UsersCompactState } from './type.d';

export type UsersCompactAction = ActionType<typeof actions>;

export const initialState: UsersCompactState = {
	error: '',
	fetchState: UsersCompactFetchState.SUCCESS,
	usersCompact: [],
};

export const usersCompactReducer = createReducer<UsersCompactState, UsersCompactAction>(initialState)
	.handleType(types.FETCH_USERS_COMPACT_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: UsersCompactFetchState.LOADING },
			usersCompact: { $set: initialState.usersCompact },
		}),
	)
	.handleType(types.FETCH_USERS_COMPACT_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: UsersCompactFetchState.SUCCESS },
			usersCompact: { $set: action.payload.usersCompact },
		}),
	)
	.handleType(types.FETCH_USERS_COMPACT_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: UsersCompactFetchState.ERROR },
			usersCompact: { $set: initialState.usersCompact },
		}),
	);
