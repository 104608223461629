import { all, call, put, takeEvery } from 'typed-redux-saga';
import * as actions from '../../reducers/site/siteHierarchy/siteHierarchy.action';
import { apiURL } from '../../config/baseURL';
import { getToken } from 'reducers/api';

export function* fetchSiteHierarchySaga({ payload }: ReturnType<typeof actions.fetchSiteHierarchy.request>) {
	try {
		const authToken = yield* call(getToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const query: Record<string, string> = {
			withDepartmentAccess: String(payload.withDepartmentAccess || false),
		};
		const queryStr = new URLSearchParams(query);

		const fetchSiteHierarchyUrl = `${apiURL}/sites/hierarchy?${queryStr}`;
		const response = yield* call(fetch, fetchSiteHierarchyUrl, options);
		const responseJson = yield response.json();

		if (response?.status !== 200) {
			yield put(actions.fetchSiteHierarchy.failure({ error: responseJson.message }));
			return;
		}

		const siteHierarchy = responseJson.data;
		yield put(actions.fetchSiteHierarchy.success({ siteHierarchy }));
	} catch (e) {
		yield put(actions.fetchSiteHierarchy.failure({ error: 'Failed to fetch site hierarchy' }));
		return null;
	}
}

export default function* siteHierarchySaga() {
	yield all([takeEvery(actions.fetchSiteHierarchy.request.toString(), fetchSiteHierarchySaga)]);
}
