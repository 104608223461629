import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as Common from 'nimbly-common';
import cloneDeep from 'lodash/cloneDeep';

// reducers
import * as actions from 'reducers/superadmin/activitylog/action';
import * as types from 'reducers/superadmin/activitylog/actionType';
import {
  fetchAllOrganizationAsync,
  fetchOrganizationUsersAsync,
  fetchOrganizationActivitiesAsync
} from 'reducers/superadmin/activitylog/action';

// utils
import * as APIService from 'constants/api/v1';
import { toQueryString } from 'utils/string';

// types
import { Option } from 'types/app';
import { RootState } from 'store/rootReducers';
import {
  AllOrganizationsResponse,
  OrganizationUsersResponse,
  OrganizationActivityResponse
} from 'constants/api/v1/types';

export function* fetchAllOrganizationSaga(): Generator {
  try {
    const { data, error } = (yield call(APIService.getAllOrganizations) as unknown) as AllOrganizationsResponse;

    if (error) {
      throw new Error(error);
    }

    // convert the data result to dropdown options
    const options: Option[] = (data! || []).map(organization => ({
      label: organization.name,
      value: organization.organizationID
    }));

    yield put(fetchAllOrganizationAsync.success({ options }));
  } catch (e) {
    yield put(fetchAllOrganizationAsync.failure({ error: e }));
  }
}

export function* fetchOrganizationUserSaga(action: ReturnType<typeof fetchOrganizationUsersAsync.request>): Generator {
  const { organizationID } = action.payload;
  try {
    const { data, error } = (yield call(
      APIService.getUsersByOrganization,
      organizationID
    ) as unknown) as OrganizationUsersResponse;

    if (error) {
      throw new Error(error);
    }

    const options: Option[] = (data! || []).map(user => ({
      label: user.displayName,
      value: user.userID
    }));

    yield put(fetchOrganizationUsersAsync.success({ options, organizationID }));
  } catch (e) {
    yield put(fetchOrganizationUsersAsync.failure({ error: e }));
  }
}

export function* fetchOrganizationActivitiesSaga(
  action: ReturnType<typeof fetchOrganizationActivitiesAsync.request>
): Generator {
  const state = (yield select((state: RootState) => state) as unknown) as RootState;
  const activityLog = state.activityLog;
  const prevTotalPages = activityLog.totalPages;
  const prevTotalDocs = activityLog.totalDocs;
  const prevPaginationEntries = activityLog.activities.data;
  const pageLimit = activityLog.limit;

  const { organizationID } = action.payload;

  const query = toQueryString({
    userID: action.payload.userID,
    startDate: action.payload.startDate,
    endDate: action.payload.endDate,
    activityType: action.payload.activityType,
    page: action.payload.page,
    limit: action.payload.limit,
    // set query to sort by its timestamp descending
    sortFields: 'timestamp',
    sortDirections: 'desc'
  });

  try {
    const { data, error } = (yield call(
      APIService.getActivityByOrganization,
      organizationID,
      query
    ) as unknown) as OrganizationActivityResponse;

    if (error) {
      throw new Error(error);
    }

    const entryDocs = data.docs;
    const totalDocs = data.totalDocs;
    const totalPages = Math.max(Math.ceil(totalDocs / pageLimit), 1);

    let paginationEntries: Common.PopulatedActivity[][] = [];

    // create new paginationEntries when different total pages, totalDocs or empty entries are detected
    if (prevTotalDocs !== totalDocs || prevTotalPages !== totalPages || prevPaginationEntries.length === 0) {
      paginationEntries = new Array(totalPages).fill([]);
      // clone and prepare entries to be inserted based on its index
    } else if (prevTotalPages === totalPages || prevTotalDocs === totalDocs) {
      paginationEntries = cloneDeep(prevPaginationEntries);
    } else {
      paginationEntries = new Array(totalPages).fill([]);
    }

    // fill paginationEntries based on its page index
    const startIndex = ((action.payload.page - 1) * action.payload.limit) / pageLimit;
    const endIndex = startIndex + Math.ceil(entryDocs.length / pageLimit);

    // split entryDocs into `pageLimit` equal parts array e.g. [[1, 2, 3], [4, 5, 6], [7, 8]]
    const pagedDocs: Common.PopulatedActivity[][] = new Array(Math.ceil(entryDocs.length / pageLimit))
      .fill([])
      .map(_ => entryDocs.splice(0, pageLimit));

    // insert pagedDocs into paginationEntries
    for (let i = startIndex, j = 0; i < endIndex; i++, j++) {
      const pageIndex = i;

      // only replace when docs is available
      if (pagedDocs[j].length > 0) {
        paginationEntries[pageIndex] = pagedDocs[j];
      }
    }

    yield put(actions.setTotalDocs(totalDocs));
    yield put(actions.setTotalPages(totalPages));
    yield put(fetchOrganizationActivitiesAsync.success({ data: paginationEntries }));
  } catch (e) {
    yield put(fetchOrganizationActivitiesAsync.failure({ error: e }));
  }
}

export default function* activityLogSaga() {
  yield takeLatest(types.FETCH_ALL_ORGANIZATIONS_REQUEST, fetchAllOrganizationSaga);
  yield takeLatest(types.FETCH_ORGANIZATION_USERS_REQUEST, fetchOrganizationUserSaga);
  yield takeLatest(types.FETCH_ORGANIZATION_ACTIVITIES_REQUEST, fetchOrganizationActivitiesSaga);
}
