import { ActionType, createReducer } from 'typesafe-actions';
import { QuizAssociationOptionsState } from './type.d';
import update from 'immutability-helper';
import * as actions from './quizAssociationOptions.action';
import * as types from './quizAssociationOptions.actionTypes';

export type QuizAssociationOptionsAction = ActionType<typeof actions>;

export const initialState: QuizAssociationOptionsState = {
	error: '',
	isLoading: false,
	quizAssociationOptions: {
		courses: [],
		lessons: [],
		topics: [],
	},
};

export const quizAssociationOptionsReducer = createReducer<QuizAssociationOptionsState, QuizAssociationOptionsAction>(
	initialState,
)
	.handleType(types.FETCH_QUIZ_ASSOCIATION_OPTIONS_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			isLoading: { $set: true },
			quizAssociationOptions: { $set: initialState.quizAssociationOptions },
		}),
	)
	.handleType(types.FETCH_QUIZ_ASSOCIATION_OPTIONS_SUCCESS, (state, action) =>
		update(state, {
			isLoading: { $set: false },
			quizAssociationOptions: { $set: action.payload.quizAssociationOptions },
		}),
	)
	.handleType(types.FETCH_QUIZ_ASSOCIATION_OPTIONS_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			isLoading: { $set: false },
			quizAssociationOptions: { $set: initialState.quizAssociationOptions },
		}),
	);
