import { IBulkDownloadReportRequest } from 'nimbly-common';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getToken } from '../../reducers/api';

import { apiURL } from '../../config/baseURL';
import * as types from './downloadManager.actionTypes';
import * as actions from './downloadManager.action';
import { toQueryString } from 'utils/string';
import { RootState } from 'store/rootReducers';

const selectorAuth = (state: RootState) => state.firebase.auth;

export function* fetchMissedReportDownloadList() {
  const auth = yield select(selectorAuth);
  try {
    const authToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const apiUrl = `${apiURL}/reports/downloads`;
    const request = () => fetch(apiUrl, options);
    const response = yield call(request);
    const responseData = yield response.json();

    if (response && response.status === 200) {
      const mappingData: IBulkDownloadReportRequest[] = [];
      responseData.data.forEach((downloadable: any) => {
        if (downloadable.requestorID !== auth?.uid) {
          // skip
          return;
        }
        const newData: IBulkDownloadReportRequest = {
          id: downloadable._id,
          dJobID: downloadable.dJobId,
          requestedTimestamp: downloadable.requestedTimestamp,
          status: downloadable.status,
          department: downloadable.department,
          site: downloadable.site,
          requestorID: downloadable.requestorID,
          startDate: downloadable.startDate,
          endDate: downloadable.endDate,
          downloadURL: downloadable.downloadURL,
          reportsCount: downloadable.reportsCount,
          downloadURLPDF: downloadable.downloadURLPDF
        } as IBulkDownloadReportRequest;
        mappingData.push(newData);
      });
      yield put(actions.fetchMissedReportDownloadList.success({ data: mappingData }));
      return mappingData;
    } else {
      yield put(actions.fetchMissedReportDownloadList.success({ data: [] }));
      yield put(actions.fetchMissedReportDownloadList.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchMissedReportDownloadList.success({ data: [] }));
    yield put(
      actions.fetchMissedReportDownloadList.failure({ error: 'Failed to retrieve list of downloadable missed reports' })
    );
    return null;
  }
}

export function* countMissedReport(action: ReturnType<typeof actions.countMissedReport.request>) {
  try {
    const authToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const params = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      department: action.payload.department,
      user: action.payload.user,
      site: action.payload.site,
      type: 'count'
    };

    const query = toQueryString(params);

    const apiUrl = `${apiURL}/app-engine/reports/sub/missed-report?${query}`;
    const request = () => fetch(apiUrl, options);
    const response = yield call(request);
    const responseData = yield response.json();

    if (response && response.status === 200) {
      const numberOfReports: number = responseData.data.count as number;
      const downloadableExists: boolean = responseData.data.isExist as boolean;
      yield put(
        actions.countMissedReport.success({
          numberOfReports: numberOfReports,
          isDownloadableExists: downloadableExists
        })
      );
      return responseData.data;
    } else {
      const responseMessage: string = responseData.message as string;
      yield put(actions.countMissedReport.success({ numberOfReports: 0, isDownloadableExists: false }));
      yield put(actions.countMissedReport.failure({ error: responseMessage }));
      return null;
    }
  } catch (e) {
    yield put(actions.countMissedReport.success({ numberOfReports: 0, isDownloadableExists: false }));
    yield put(actions.countMissedReport.failure({ error: 'Failed to retrieve list of downloadable missed reports' }));
    return null;
  }
}

export function* generateMissedReport(action: ReturnType<typeof actions.generateMissedReport.request>) {
  try {
    const authToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const apiUrl =
      `${apiURL}/app-engine/reports/sub/missed-report?startDate` +
      `=${action.payload.startDate}` +
      `&endDate=${action.payload.endDate}` +
      `&department=${action.payload.department}` +
      (() => {
        if (action.payload.user) {
          return `&user=${action.payload.user}`;
        } else {
          return '';
        }
      })() +
      (() => {
        if (action.payload.site) {
          return `&site=${action.payload.site}`;
        } else {
          return '';
        }
      })() +
      `&type=file` +
      `&reportFormat=${action.payload.reportFormat}`;

    const request = () => fetch(apiUrl, options);
    const response = yield call(request);
    const responseData = yield response.json();

    if (response && response.status === 200) {
      yield put(actions.generateMissedReport.success({ downloadableUrl: '' }));
      return responseData.data;
    } else {
      const responseMessage: string = responseData.message as string;
      yield put(actions.generateMissedReport.success({ downloadableUrl: '' }));
      yield put(actions.generateMissedReport.failure({ error: responseMessage }));
      return null;
    }
  } catch (e) {
    yield put(actions.generateMissedReport.success({ downloadableUrl: '' }));
    yield put(actions.generateMissedReport.failure({ error: 'Failed to generate report' }));
    return null;
  }
}

export default function* downloadManagerSaga() {
  yield takeLatest(types.FETCH_MISSED_REPORTS_LIST_REQUEST, fetchMissedReportDownloadList);
  yield takeLatest(types.COUNT_MISSED_REPORTS_REQUEST, countMissedReport);
  yield takeLatest(types.GENERATE_MISSED_REPORTS_REQUEST, generateMissedReport);
}
