export enum BatchUploadSkuErrorEnum {
	DATA_ROWS_ERROR = 'data_rows_error',
	EMPTY_ROWS_FOUND_ERROR = 'empty_rows_found_error',
	INVALID_FILE_FORMAT_ERROR = 'invalid_file_format_error',
	EXCEEDED_FILE_SIZE_ERROR = 'exceeded_file_size_error',
	EXCEEDED_ROW_COUNT_ERROR = 'exceeded_row_count_error',
}

export interface BatchUploadRowErrorData {
	row: string;
	column: string;
	message: string;
}

const errorMap: { [key: string]: (response: Response, json: any) => boolean } = {
	[BatchUploadSkuErrorEnum.INVALID_FILE_FORMAT_ERROR]: (response: Response, json: any) => {
		return response.status === 400 && json.data === 'Please upload file in .xlsx format';
	},
	[BatchUploadSkuErrorEnum.EXCEEDED_FILE_SIZE_ERROR]: (response: Response, json: any) => {
		return response.status === 413 && json.data === 'File size Exceeds 10MB limit';
	},
	[BatchUploadSkuErrorEnum.EMPTY_ROWS_FOUND_ERROR]: (response: Response, json: any) => {
		return response.status === 400 && json.data[0]['message'] === 'Please check your file, no rows found';
	},
	[BatchUploadSkuErrorEnum.EXCEEDED_ROW_COUNT_ERROR]: (response: Response, json: any) => {
		return (
			response.status === 400 && json.data === 'Please reduce the number of rows in selected file to 1000 or below'
		);
	},
};

export class BatchUploadSkuError extends Error {
	public static async getError(response: Response): Promise<BatchUploadSkuError | Error | null> {
		const json = await response.clone().json();

		if (response.status >= 200 && response.status < 400) {
			return null;
		}

		const errorKey = Object.keys(errorMap).find((errorEnum: string) => {
			return errorMap[errorEnum](response, json);
		});

		if (errorKey) return new BatchUploadSkuError(json.data, (errorKey as unknown) as BatchUploadSkuErrorEnum);

		if (response.status === 400 && json.data[0]['message']) {
			const dataRowErrors = json.data as BatchUploadRowErrorData[];

			return new BatchUploadSkuError(json.data, BatchUploadSkuErrorEnum.DATA_ROWS_ERROR, dataRowErrors);
		}

		return new Error(json?.message);
	}

	private _errorEnum: BatchUploadSkuErrorEnum;
	private _rowErrors?: BatchUploadRowErrorData[];

	public constructor(message: string, errorEnum: BatchUploadSkuErrorEnum, rowErrors?: BatchUploadRowErrorData[]) {
		super(message);
		this._rowErrors = rowErrors;
		this._errorEnum = errorEnum;
	}

	public get errorEnum() {
		return this._errorEnum;
	}

	public get rowErrors() {
		return this._rowErrors;
	}
}
