import * as actions from './user.action';
import * as types from './user.actionTypes';
import { UserAuth } from 'nimbly-common';
import { ActionType } from 'typesafe-actions';

export type UserAction = ActionType<typeof actions>;

export interface userState {
	user: UserAuth;
}

const initialState: any = {
	user: null,
	isSandboxAccount: false,
};

export const userReducer = (state = initialState, action: UserAction) => {
	switch (action.type) {
		case types.SET_AUTHENTICATED_PROFILE:
			return {
				...state,
				user: action,
			};
		case types.SET_SANDBOX_ACCOUNT:
			return {
				...state,
				isSandboxAccount: action.payload.isSandboxAccount,
			};
		default:
			return state;
	}
};
