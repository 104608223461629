import React, { useState, useEffect, createContext, useContext } from 'react';
import { auth } from 'store/configStore';
import { User } from '@firebase/auth-types';
import moment from 'moment';

import { clearSessionStorageCache } from 'helpers/sessionStorageHelper';
import Monitoring from '../utils/monitoring/Monitoring';

export const IDTOKEN = 'idtoken';
const IDTOKEN_TIMESTAMP = 'idtoken:timestamp';

const getSetToken = async (user: User) => {
  try {
    const token = await user.getIdToken(true);

    sessionStorage.setItem(IDTOKEN, token);
    sessionStorage.setItem(IDTOKEN_TIMESTAMP, new Date().toISOString());

    return token;
  } catch (error) {
    Monitoring.logEvent('getSetToken', error);
    return null;
  }
};

/**
 * getIdToken get user id token then set token to localstorage
 */
export const getIdToken = async () => {
  const user = auth().currentUser;
  if (!user) {
    return null;
  }

  const tokenLocal = sessionStorage.getItem(IDTOKEN);
  const tokenTimestamp = sessionStorage.getItem(IDTOKEN_TIMESTAMP);

  if (!tokenLocal || !tokenTimestamp) {
    return await getSetToken(user);
  }

  const diff = moment().diff(tokenTimestamp, 'minutes');

  // Refresh localstorage token if more than 45 mins
  if (Math.abs(diff) > 45) {
    return await getSetToken(user);
  }

  return tokenLocal;
};

const _signin = async ({ email, password }: { email: string; password: string }) =>
  auth()
    .signInWithEmailAndPassword(email, password)
    .then(res => res.user);

const _signout = async () => auth().signOut();

const _signup = async ({ email, password }: { email: string; password: string }) =>
  auth()
    .createUserWithEmailAndPassword(email, password)
    .then(res => res.user);

const _sendPasswordResetEmail = async (email: string) => auth().sendPasswordResetEmail(email);

const _confirmPasswordReset = async ({ code, password }: { code: string; password: string }) =>
  auth().confirmPasswordReset(code, password);

const state = {
  user: null,
  isLoaded: false,

  signin: _signin,
  signout: _signout,
  signup: _signup,

  sendPasswordResetEmail: _sendPasswordResetEmail,
  confirmPasswordReset: _confirmPasswordReset
} as ReturnType<typeof useProvider>;
const authContext = createContext(state);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: React.ReactNode | React.ReactNode[] }) {
  const auth = useProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useFirebaseAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
export function useProvider() {
  const [user, setUser] = useState<null | User>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);

        getSetToken(user);
      } else {
        setUser(null);

        clearSessionStorageCache();
      }

      setIsLoaded(true);
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
      clearSessionStorageCache();
    };
  }, []);

  // Return the user object and auth methods
  return {
    user,
    isLoaded,
    signin: _signin,
    signup: _signup,
    signout: _signout,
    sendPasswordResetEmail: _sendPasswordResetEmail,
    confirmPasswordReset: _confirmPasswordReset
  };
}
