import { FetchAllOrgResponse } from 'reducers/superadmin/clientLogin/masterPassword/types';
import { getToken } from 'reducers/api';
import { apiURL } from 'config/baseURL';

export const fetchAllOrg = async (): Promise<FetchAllOrgResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const url = `${apiURL}/organizations/all`;
    const res = await fetch(url, options);

    const auditorsListResult: FetchAllOrgResponse = await res.json();

    return auditorsListResult;
  } catch {
    const errorResult: FetchAllOrgResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
