export const SET_SITE_LOADING = '@siteDetails/SET_SITE_LOADING';
export const SET_SITE = '@siteDetails/SET_SITE';
export const SET_SITE_VALUE = '@siteDetails/SET_SITE_VALUE';
export const SET_SITE_OWNER = '@siteDetails/SET_SITE_OWNER';

export const SET_TEAM = '@siteDetails/SET_TEAM';
export const SET_SITE_NAME = '@siteDetails/SET_SITE_NAME';
export const SET_SITE_SUBTITLE = '@siteDetails/SET_SITE_SUBTITLE';
export const SET_SITE_ADDRESS = '@siteDetails/SET_SITE_ADDRESS';
export const SET_SITE_COUNTRY = '@siteDetails/SET_SITE_COUNTRY';
export const SET_SITE_PROVINCE = '@siteDetails/SET_SITE_PROVINCE';
export const SET_SITE_CITY = '@siteDetails/SET_SITE_CITY';
export const SET_SITE_LOCATION_NAME = '@siteDetails/SET_SITE_LOCATION_NAME';
export const SET_SITE_RADIUS = '@siteDetails/SET_SITE_RADIUS';
export const SET_SITE_SUPERVISORS = '@siteDetails/SUPERVISORS';
export const SET_SITE_TIMEZONE = '@siteDetails/SET_SITE_TIMEZONE';
export const SET_SITE_ISSUE_DEFAULT_ASSIGNED_USER = '@siteDetails/SET_ISSUE_DEFAULT_ASSIGNED_USER';
export const SET_HAS_CHANGES = '@siteDetails/SET_HAS_CHANGES';
export const SET_CHILDREN = '@siteDetails/SET_CHILDREN';
export const SET_ASSIGNED_AUDITOR = '@siteDetails/SET_ASSIGNED_AUDITOR';

export const ADD_SITE_AUDITOR = '@siteDetails/ADD_SITE_AUDITOR';
export const REMOVE_TEAM_MEMBER = '@siteDetails/REMOVE_TEAM_MEMBER';
export const SET_REMOVE_TEAM_MEMBER = '@siteDetails/SET_REMOVE_TEAM_MEMBER';
export const SET_QUESTIONNAIRE_SCHEDULE_MEMBER = '@siteDetails/SET_QUESTIONNAIRE_SCHEDULE_MEMBER';
export const SET_COORDINATE = '@siteDetails/SET_COORDINATE';

export const UPDATE_INITIAL_VALUE = '@siteDetails/SET_INITIAL_VALUE';

export const SHOW_REMOVE_MEMBER_MODAL = '@siteDetails/SHOW_REMOVE_MEMBER_MODAL';
export const DISMISS_REMOVE_MEMBER_MODAL = '@siteDetails/DISMISS_REMOVE_MEMBER_MODAL';

export const FETCH_SITE_DEPARTMENTS_REQUEST = '@siteDetails/FETCH_SITE_DEPARTMENTS_REQUEST';
export const FETCH_SITE_DEPARTMENTS_SUCCESS = '@siteDetails/FETCH_SITE_DEPARTMENTS_SUCCESS';
export const FETCH_SITE_DEPARTMENTS_FAILURE = '@siteDetails/FETCH_SITE_DEPARTMENTS_FAILURE';
