import API from 'helpers/api';
import { apiURL, baseApiURLAlt } from 'config/baseURL';
import {
	IDashboardIndicatorStore,
	IDashboardCompletionStore,
	IDashboardPassFailRatioStore,
	IDashboardLessonProgressStore,
	IDashboardLessonStatusStore,
	IDashboardSummaryStore,
	IDashboardQuizInSightStore,
	EntityStatusStore,
	OverallQuizInsights,
	adminOverallInsight,
	instructorOverallInsight,
	learnerOverallInsight,
	IDashboardtimeSpent,
	LmsDashboardTrends,
	LmsDashboardTopBottomEntities,
	LmsQuizAssociation,
	LmsEntityDetail,
	LmsEntityDetailResponse,
	LmsEntityEnrollmentStats,
	LmsEntityStatusWiseSplit,
} from 'reducers/lms/lmsDashboard/lmsDashboard.type';
import { getJwtToken } from 'services/gallery/gallery.service';
import { store } from 'store/configStore';
import queryString from 'query-string';
import moment from 'moment';
import { getStartEpochTimeInMillisecondsFromUTC, getEndOfDayEpochTimeInMillisecondsFromUTC } from 'utils/getUTCEpoch';
import { DownloadDashboardFileType } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import { downloadURL } from 'utils/download';
import { filterObjectWithEmptyValues } from 'pages/dashboardRevamp/utils/filterObjectWithEmptyValues';
import { getRequestOptions } from 'services/dashboardRevamp/issueInsights/issueInsights.service';

const buildDashboardQueryParams = () => {
	const dashboardFilters = store.getState().lmsDashboard.dashboardFilters;

	let filteredData = filterObjectWithEmptyValues(dashboardFilters);
	if (filteredData.startDate) {
		let utcStartDate = getStartEpochTimeInMillisecondsFromUTC(moment(filteredData.startDate).format('YYYY-MM-DD'));
		filteredData = {
			...filteredData,
			startDate: utcStartDate,
		};
	}
	if (filteredData.endDate) {
		let utcEndDate = getEndOfDayEpochTimeInMillisecondsFromUTC(moment(filteredData.endDate).format('YYYY-MM-DD'));
		filteredData = {
			...filteredData,
			endDate: utcEndDate,
		};
	}
	if (filteredData.publishedStartDate) {
		let utcStartDate = getStartEpochTimeInMillisecondsFromUTC(
			moment(filteredData.publishedStartDate).format('YYYY-MM-DD'),
		);
		filteredData = {
			...filteredData,
			publishedStartDate: utcStartDate,
		};
	}
	if (filteredData.publishedEndDate) {
		let utcEndDate = getEndOfDayEpochTimeInMillisecondsFromUTC(
			moment(filteredData.publishedEndDate).format('YYYY-MM-DD'),
		);
		filteredData = {
			...filteredData,
			publishedEndDate: utcEndDate,
		};
	}

	return filteredData;
};

export const fetchLmsDashboardIndicator = async (): Promise<IDashboardIndicatorStore | undefined> => {
	const url = `${apiURL}/lms/learner-activities/dashboard-indicator`;
	const res = await API.getJSON(url);
	return res?.data as IDashboardIndicatorStore;
};

export const fetchLmsDashboardCompletionStats = async (): Promise<IDashboardCompletionStore | undefined> => {
	try {
		const url = `${apiURL}/lms/dashboard/completion`;
		const res = await API.getJSON(url);
		return res?.data as IDashboardCompletionStore;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardPassFailRatio = async (): Promise<IDashboardPassFailRatioStore[] | undefined> => {
	try {
		const { startDate, endDate } = store.getState().lmsDashboard.lessonStatus;
		let query = '';
		if (startDate) {
			let utcStartDate = getStartEpochTimeInMillisecondsFromUTC(moment(startDate).format('YYYY-MM-DD'));

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = getEndOfDayEpochTimeInMillisecondsFromUTC(moment(endDate).format('YYYY-MM-DD'));
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}
		const url = `${apiURL}/lms/dashboard/pass-fail-ratio?${query}`;
		const res = await API.getJSON(url);
		return res?.data as IDashboardPassFailRatioStore[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardLessonProgress = async (): Promise<IDashboardLessonProgressStore[] | undefined> => {
	try {
		const { startDate, endDate } = store.getState().lmsDashboard.lessonStatus;
		let query = '';
		if (startDate) {
			let utcStartDate = getStartEpochTimeInMillisecondsFromUTC(moment(startDate).format('YYYY-MM-DD'));

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = getEndOfDayEpochTimeInMillisecondsFromUTC(moment(endDate).format('YYYY-MM-DD'));
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}

		let url = `${apiURL}/lms/dashboard/lesson-progress?${query}`;

		const res = await API.getJSON(url);
		return res?.data as IDashboardLessonProgressStore[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardLessonStatus = async (): Promise<IDashboardLessonStatusStore | undefined> => {
	try {
		const { startDate, endDate } = store.getState().lmsDashboard.lessonStatus;
		let query = '';
		if (startDate) {
			query = `${query}&${queryString.stringify({
				startDate,
			})}`;
		}
		if (endDate) {
			query = `${query}&${queryString.stringify({
				endDate,
			})}`;
		}
		let url = `${apiURL}/lms/dashboard/status?${query}`;

		const res = await API.getJSON(url);

		return res?.data as IDashboardLessonStatusStore;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardEntityStatus = async (entity?: string): Promise<EntityStatusStore> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		let url = `${apiURL}/lms/dashboard/status-v2`;

		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();

		return responseData?.data as EntityStatusStore;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardSummary = async (status?: string): Promise<IDashboardSummaryStore | undefined> => {
	try {
		const { limit, page, sortDirections, sortFields, type } = store.getState().lmsDashboard.summary;
		let query = buildDashboardQueryParams();
		query = {
			...query,
			limit,
			page,
		};

		if (sortDirections) {
			query = {
				...query,
				sortDirections,
			};
		}
		if (sortFields) {
			query = {
				...query,
				sortFields,
			};
		}
		if (status) {
			query = {
				...query,
				status,
			};
		}
		if (type) {
			query = {
				...query,
				type,
			};
		}
		const url = `${apiURL}/lms/dashboard/summary`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();

		return responseData?.data?.data as IDashboardSummaryStore;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const downloadBulkSummaryReport = async (): Promise<any> => {
	try {
		const {
			limit,
			page,
			startDate,
			endDate,
			sortDirections,
			sortFields,
			status,
			type,
		} = store.getState().lmsDashboard.summary;
		let query = queryString.stringify({
			limit,
			page,
		});

		if (sortDirections) {
			query = `${query}&${queryString.stringify({
				sortDirections,
			})}`;
		}
		if (sortFields) {
			query = `${query}&${queryString.stringify({
				sortFields,
			})}`;
		}
		if (status) {
			query = `${query}&${queryString.stringify({
				status,
			})}`;
		}
		if (type) {
			query = `${query}&${queryString.stringify({
				type,
			})}`;
		}
		if (startDate) {
			query = `${query}&${queryString.stringify({
				startDate,
			})}`;
		}
		if (endDate) {
			query = `${query}&${queryString.stringify({
				endDate,
			})}`;
		}

		const url = `${baseApiURLAlt}/api-lms/dashboard/summary-download?${query}`;

		const token = await getJwtToken();

		const response = (await fetch(url, {
			method: 'GET',
			headers: {
				authorization: token,
				deviceTImezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			},
		})) as any;
		if (response.status !== 200) {
			const json = await response.json();
			throw new Error(json.message);
		}

		const responseBlob = await response.blob();
		return responseBlob;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardQuizInsight = async (): Promise<IDashboardQuizInSightStore[] | undefined> => {
	try {
		const { startDate, endDate } = store.getState().lmsDashboard.lessonStatus;
		let query = '';
		if (startDate) {
			let utcStartDate = getStartEpochTimeInMillisecondsFromUTC(moment(startDate).format('YYYY-MM-DD'));

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = getEndOfDayEpochTimeInMillisecondsFromUTC(moment(endDate).format('YYYY-MM-DD'));
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}
		const url = `${apiURL}/lms/dashboard/quiz-insight?${query}`;
		const res = await API.getJSON(url);
		return res?.data as IDashboardQuizInSightStore[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardQuizInsightV2 = async (entity?: string): Promise<IDashboardQuizInSightStore[]> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		const url = `${apiURL}/lms/dashboard/quiz-insight-v2`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();

		return responseData?.data as IDashboardQuizInSightStore[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardOverallQuizInsight = async (): Promise<OverallQuizInsights> => {
	try {
		const query = buildDashboardQueryParams();

		const url = `${apiURL}/lms/dashboard/overall-quiz-insight`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();
		return responseData?.data as OverallQuizInsights;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardOverallInsight = async (
	role: string,
): Promise<adminOverallInsight | instructorOverallInsight | learnerOverallInsight> => {
	try {
		let query = '';
		if (role) {
			query = `${query}&${queryString.stringify({
				role,
			})}`;
		}
		const url = `${apiURL}/lms/dashboard/overview?${query}`;
		const res = await API.getJSON(url);
		return res?.data as adminOverallInsight | instructorOverallInsight | learnerOverallInsight;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardTimeSpent = async (entity?: string, role?: string): Promise<IDashboardtimeSpent[]> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		if (role) {
			query = {
				...query,
				role,
			};
		}
		const url = `${apiURL}/lms/dashboard/quiz-insight-v2`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();
		return responseData?.data as IDashboardtimeSpent[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardTrends = async ({
	role,
	period,
	entity,
}: {
	role: string;
	period: string;
	entity: string;
}): Promise<LmsDashboardTrends[]> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		if (role) {
			query = {
				...query,
				role,
			};
		}
		if (period) {
			query = {
				...query,
				period,
			};
		}
		const url = `${apiURL}/lms/dashboard/trends`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();
		return responseData?.data?.result as LmsDashboardTrends[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardTopBottomEntites = async ({
	role,
	entity,
	sortDirection,
	status = '',
}: {
	role: string;
	entity: string;
	sortDirection: string;
	status?: string;
}): Promise<LmsDashboardTopBottomEntities[]> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		if (role) {
			query = {
				...query,
				role,
			};
		}
		if (sortDirection) {
			query = {
				...query,
				sortDirection,
			};
		}
		if (status) {
			query = {
				...query,
				status,
			};
		}
		const url = `${apiURL}/lms/dashboard/top-bottom-entities`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();
		return responseData?.data as LmsDashboardTopBottomEntities[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsEnrollmentMetrics = async ({
	role,
	entity,
}: {
	role: string;
	entity: string;
}): Promise<LmsDashboardTopBottomEntities[]> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		if (role) {
			query = {
				...query,
				role,
			};
		}
		const url = `${apiURL}/lms/dashboard/enrollment-metrics`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();
		return responseData?.data as LmsDashboardTopBottomEntities[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsDashboardQuizAssociation = async (): Promise<LmsQuizAssociation[]> => {
	try {
		// TOD: Implement Pagination
		let query = queryString.stringify({
			limit: 5,
			page: 1,
		});
		const url = `${apiURL}/lms/dashboard/quiz-association`;
		const res = await API.getJSON(url);
		return res?.data as LmsQuizAssociation[];
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsEntityDetails = async (entity: string): Promise<LmsEntityDetailResponse> => {
	try {
		let query = buildDashboardQueryParams();
		const { page, limit } = store.getState().lmsDashboard.entityDetail;
		if (page) {
			query = {
				...query,
				page,
			};
		}
		if (limit) {
			query = {
				...query,
				limit,
			};
		}
		if (entity) {
			query = {
				...query,
				entity,
			};
		}

		const url = `${apiURL}/lms/dashboard/entity-details`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();
		return responseData?.data as LmsEntityDetailResponse;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsEnrollmentStats = async (entity: string): Promise<LmsEntityEnrollmentStats> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		const url = `${apiURL}/lms/dashboard/enrollment-stats`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();

		return responseData.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsEntityStatusWiseSplit = async (entity: string): Promise<LmsEntityStatusWiseSplit> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		const url = `${apiURL}/lms/dashboard/status-split`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();

		return responseData.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsViewDetails = async ({
	role,
	entity,
	limit = 10,
}: {
	role: string;
	entity: string;
	limit?: number;
}): Promise<Record<string, any>[]> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		if (role) {
			query = {
				...query,
				role,
			};
		}
		if (limit) {
			query = {
				...query,
				limit,
			};
		}
		const url = `${apiURL}/lms/dashboard/view-details`;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const responseData = await res.json();
		return responseData?.data as Record<string, any>[];
	} catch (error) {
		console.error('Error in fetchViewDetailsData', error);
		return [];
	}
};

export const fetchLmsDownloadReport = async ({
	role,
	entity,
	fieldName = [],
	fileType,
	otherFilters,
}: {
	role: string;
	entity: string;
	fieldName?: string[];
	fileType: DownloadDashboardFileType;
	otherFilters?: Record<string, any>;
}): Promise<void> => {
	try {
		let query = buildDashboardQueryParams();
		if (entity) {
			query = {
				...query,
				entity,
			};
		}
		if (role) {
			query = {
				...query,
				role,
			};
		}
		if (fieldName) {
			query = {
				...query,
				fieldName,
			};
		}
		if (otherFilters) {
			query = {
				...query,
				...otherFilters,
			};
		}
		if (fileType) {
			query = {
				...query,
				fileType,
			};
		}
		const url = `${apiURL}/lms/dashboard/download`;
		const options = await getRequestOptions(query);
		const response = await fetch(url, options);
		const { data, message } = await response.json();

		if (data && message === 'SUCCESS') {
			await downloadURL(data, `nimbly-lms-dashboard-${moment().format('DD-MM-YY')}.${fileType}`);
		}
	} catch (error) {
		console.error('Error in fetchViewDetailsData', error);
	}
};
