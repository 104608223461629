export const SET_LOADING = '@questionnaires/SET_LOADING';
export const SET_PAGE = 'questionnaire/SET_PAGE';
export const SET_TAB = 'questionnaire/SET_TAB';
export const CLEAR_FILTER_QUERY = 'questionnaire/CLEAR_FILTER_QUERY';
export const DISMISS_BULK_MODAL = 'questionnaire/DISMISS_BULK_MODAL';
export const DISMISS_HISTORY_MODAL = 'questionnaire/DISMISS_HISTORY_MODAL';
export const DISMISS_LABEL_FLAG_MODAL = 'questionnaire/DISMISS_LABEL_FLAG_MODAL';
export const DISMISS_MODAL = 'questionnaire/DISMISS_MODAL';
export const SET_FILTER_QUERY = 'questionnaire/SET_FILTER_QUERY';
export const SET_QUESTIONNAIRES = 'questionnaire/SET_QUESTIONNAIRES';
export const SET_SCROLL = 'questionnaire/SET_QUESTIONNAIRE_SCROLL';
export const SET_SORT = 'questionnaire/SET_QUESTIONNAIRE_SORT';
export const SHOW_BULK_MODAL = 'questionnaire/SHOW_BULK_MODAL';
export const SHOW_HISTORY_MODAL = 'questionnaire/SHOW_HISTORY_MODAL';
export const SHOW_LABEL_FLAG_MODAL = 'questionnaire/SHOW_LABEL_FLAG_MODAL';
export const SHOW_MODAL = 'questionnaire/SHOW_MODAL';
export const REMOVE_PAGINATION_QUESTIONNAIRE_BY_ID = 'questionnaire/ REMOVE_PAGINATION_QUESTIONNAIRE_BY_ID';

export const CLONE_QUESTIONNAIRE_REQUEST = 'questionnaire/CLONE_QUESTIONNAIRE_REQUEST';
export const CLONE_QUESTIONNAIRE_SUCCESS = 'questionnaire/CLONE_QUESTIONNAIRE_SUCCESS';
export const CLONE_QUESTIONNAIRE_FAILURE = 'questionnaire/CLONE_QUESTIONNAIRE_FAILURE';

export const UPDATE_QUESTIONNAIRE_DEPARTMENT_REQUEST = 'questionnaire/UPDATE_QUESTIONNAIRE_DEPARTMENT_REQUEST';
export const UPDATE_QUESTIONNAIRE_DEPARTMENT_SUCCESS = 'questionnaire/UPDATE_QUESTIONNAIRE_DEPARTMENT_SUCCESS';
export const UPDATE_QUESTIONNAIRE_DEPARTMENT_FAILURE = 'questionnaire/UPDATE_QUESTIONNAIRE_DEPARTMENT_FAILURE';

export const FETCH_QUESTIONNAIRE_DEPARTMENTS_REQUEST = 'questionnaire/FETCH_QUESTIONNAIRE_DEPARTMENTS_REQUEST';
export const FETCH_QUESTIONNAIRE_DEPARTMENTS_SUCCESS = 'questionnaire/FETCH_QUESTIONNAIRE_DEPARTMENTS_SUCCESS';
export const FETCH_QUESTIONNAIRE_DEPARTMENTS_FAILURE = 'questionnaire/FETCH_QUESTIONNAIRE_DEPARTMENTS_FAILURE';

export const FETCH_QUESTIONNAIRES_INDEX_REQUEST = 'questionnaire/FETCH_QUESTIONNAIRES_INDEX_REQUEST';
export const FETCH_QUESTIONNAIRES_INDEX_SUCCESS = 'questionnaire/FETCH_QUESTIONNAIRES_INDEX_SUCCESS';
export const FETCH_QUESTIONNAIRES_INDEX_FAILURE = 'questionnaire/FETCH_QUESTIONNAIRES_INDEX_FAILURE';

export const GET_POPULATED_QUESTIONNAIRES_REQUEST = 'questionnaire/GET_POPULATED_QUESTIONNAIRES_REQUEST';
export const GET_POPULATED_QUESTIONNAIRES_SUCCESS = 'questionnaire/GET_POPULATED_QUESTIONNAIRES_SUCCESS';
export const GET_POPULATED_QUESTIONNAIRES_FAILURE = 'questionnaire/GET_POPULATED_QUESTIONNAIRES_FAILURE';

export const FETCH_QUESTIONNAIRES_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRES_REQUEST';
export const FETCH_QUESTIONNAIRES_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRES_LOADING';
export const FETCH_QUESTIONNAIRES_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRES_FAILURE';

export const FETCH_PAGINATE_QUESTIONNAIRES_REQUEST = 'questionnaire/FETCH_PAGINATE_QUESTIONNAIRES_REQUEST';
export const FETCH_PAGINATE_QUESTIONNAIRES_SUCCESS = 'questionnaire/FETCH_PAGINATE_QUESTIONNAIRES_SUCCESS';
export const FETCH_PAGINATE_QUESTIONNAIRES_FAILURE = 'questionnaire/FETCH_PAGINATE_QUESTIONNAIRES_FAILURE';

export const FETCH_QUESTIONNAIRES_TEMPLATE_REQUEST = 'questionnaire/FETCH_QUESTIONNAIRES_TEMPLATE_REQUEST';
export const FETCH_QUESTIONNAIRES_TEMPLATE_SUCCESS = 'questionnaire/FETCH_QUESTIONNAIRES_TEMPLATE_SUCCESS';
export const FETCH_QUESTIONNAIRES_TEMPLATE_FAILURE = 'questionnaire/FETCH_QUESTIONNAIRES_TEMPLATE_FAILURE';

export const DOWNLOAD_QUESTIONNAIRES_TEMPLATE_REQUEST = 'questionnaire/DOWNLOAD_QUESTIONNAIRES_TEMPLATE_REQUEST';
export const DOWNLOAD_QUESTIONNAIRES_TEMPLATE_SUCCESS = 'questionnaire/DOWNLOAD_QUESTIONNAIRES_TEMPLATE_SUCCESS';
export const DOWNLOAD_QUESTIONNAIRES_TEMPLATE_FAILURE = 'questionnaire/DOWNLOAD_QUESTIONNAIRES_TEMPLATE_FAILURE';

export const FETCH_QUESTIONNAIRES_BY_PROPS_REQUEST = 'questionnaire/FETCH_QUESTIONNAIRES_BY_PROPS_REQUEST';
export const FETCH_QUESTIONNAIRES_BY_PROPS_SUCCESS = 'questionnaire/FETCH_QUESTIONNAIRES_BY_PROPS_SUCCESS';
export const FETCH_QUESTIONNAIRES_BY_PROPS_FAILURE = 'questionnaire/FETCH_QUESTIONNAIRES_BY_PROPSFAILURE';