import { Questionnaire } from 'nimbly-common';
import { UpsertQuestionnairePayload } from 'reducers/admin/manage/questionnaires/types';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './questionnaire.actionTypes';
import { QuestionnaireByPropsState, QuestionnaireData, QuestionnaireOrdered, Questionnaires, TabOptions } from './type';


// Reducer for questionnaire management
export const setPage = (index: number) => action(types.SET_PAGE, { index });

export const setTab = (value: TabOptions) => action(types.SET_TAB, { value });

export const setQuestionnairesFilterQuery = (text: string) => action(types.SET_FILTER_QUERY, { text });

export const clearQuestionnairesFilterQuery = () => action(types.CLEAR_FILTER_QUERY);

export const setSortQuestionnaire = (newType: string, newOrder: 'asc' | 'desc' | null = null) =>
  action(types.SET_SORT, { newType, newOrder });

export const setQuestionnaireScrollPosition = (newScroll: any) => action(types.SET_SCROLL, { newScroll });

export const showQuestionnaireBulkModal = () => action(types.SHOW_BULK_MODAL);

export const dismissQuestionnaireBulkModal = () => action(types.DISMISS_BULK_MODAL);

export const showQuestionnaireModal = (mode: any, questionnaire: Questionnaire) =>
  action(types.SHOW_MODAL, { mode, questionnaire });

export const dismissQuestionnaireModal = () => action(types.DISMISS_MODAL);

export const showHistoryModal = () => action(types.SHOW_HISTORY_MODAL);

export const removePaginationQuestionnaireByID = (questionnaireID: string) =>
  action(types.REMOVE_PAGINATION_QUESTIONNAIRE_BY_ID, { questionnaireID });

export const dismissHistoryModal = () => action(types.DISMISS_HISTORY_MODAL);

export const showLabelFlagModal = (
  questionId: string,
  categoryId: string,
  questionType: string,
  conditionalQuestionId: number | null,
  scope: any
) => action(types.SHOW_LABEL_FLAG_MODAL, { questionId, categoryId, questionType, conditionalQuestionId, scope });

export const dismissLabelFlagModal = () => action(types.DISMISS_LABEL_FLAG_MODAL);

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const fetchPaginateQuestionnairesAsync = createAsyncAction(
  types.FETCH_PAGINATE_QUESTIONNAIRES_REQUEST,
  types.FETCH_PAGINATE_QUESTIONNAIRES_SUCCESS,
  types.FETCH_PAGINATE_QUESTIONNAIRES_FAILURE
)<
  { sortFields: string; sortDirections: 'asc' | 'desc' },
  { ordered: QuestionnaireOrdered | []; totalItem: number | 0 },
  { error: string }
>();

export const cloneQuestionnaireAsync = createAsyncAction(
  types.CLONE_QUESTIONNAIRE_REQUEST,
  types.CLONE_QUESTIONNAIRE_SUCCESS,
  types.CLONE_QUESTIONNAIRE_FAILURE
)<
  {
    oldQuestionnaireKey: string;
    newQuestionnaireData: UpsertQuestionnairePayload;
  },
  { newQuestionnaireId: string },
  { message: string }
>();

export const getPopulatedQuestionnairesAsync = createAsyncAction(
  types.GET_POPULATED_QUESTIONNAIRES_REQUEST,
  types.GET_POPULATED_QUESTIONNAIRES_SUCCESS,
  types.GET_POPULATED_QUESTIONNAIRES_FAILURE
)<undefined, { data: QuestionnaireData | null; ordered: QuestionnaireOrdered | null; index: any }, { error: string }>();

export const updateQuestionnaireDepartment = createAsyncAction(
  types.UPDATE_QUESTIONNAIRE_DEPARTMENT_REQUEST,
  types.UPDATE_QUESTIONNAIRE_DEPARTMENT_SUCCESS,
  types.UPDATE_QUESTIONNAIRE_DEPARTMENT_FAILURE
)<{ questionnaireKey: string; data: string[] }, undefined, { error: string }>();

export const fetchQuestionnaireDepartment = createAsyncAction(
  types.FETCH_QUESTIONNAIRE_DEPARTMENTS_REQUEST,
  types.FETCH_QUESTIONNAIRE_DEPARTMENTS_SUCCESS,
  types.FETCH_QUESTIONNAIRE_DEPARTMENTS_FAILURE
)<{ questionnaireKey: string }, { data: string[] }, { error: string }>();

export const fetchQuestionnaires = createAsyncAction(
  types.FETCH_QUESTIONNAIRES_REQUEST,
  types.FETCH_QUESTIONNAIRES_SUCCESS,
  types.FETCH_QUESTIONNAIRES_FAILURE
)<undefined, { data: Questionnaires | null }, { error: string }>();

export const fetchQuestionnairesTemplate = createAsyncAction(
  types.FETCH_QUESTIONNAIRES_TEMPLATE_REQUEST,
  types.FETCH_QUESTIONNAIRES_TEMPLATE_SUCCESS,
  types.FETCH_QUESTIONNAIRES_TEMPLATE_FAILURE
)<undefined, { data: null }, { error: string }>();

export const downloadQuestionnaireTemplate = createAsyncAction(
  types.DOWNLOAD_QUESTIONNAIRES_TEMPLATE_REQUEST,
  types.DOWNLOAD_QUESTIONNAIRES_TEMPLATE_SUCCESS,
  types.DOWNLOAD_QUESTIONNAIRES_TEMPLATE_FAILURE
)<undefined, { data: null }, { error: string }>();

export const getQuestionnairesByPropsAsync = createAsyncAction(
  types.FETCH_QUESTIONNAIRES_BY_PROPS_REQUEST,
  types.FETCH_QUESTIONNAIRES_BY_PROPS_SUCCESS,
  types.FETCH_QUESTIONNAIRES_BY_PROPS_FAILURE
)<undefined, { data: QuestionnaireByPropsState['data'] } , { error: string }>()