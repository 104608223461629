export const neutral = {
	100: '#FFFFFF',
	200: '#FAFAFA',
	300: '#EFEEED',
	400: '#EDEDED',
	500: '#E2E2E2',
	600: '#C4C4C4',
	700: '#A0A4A8',
	800: '#535353',
	900: '#25282B',
	1000: '#000000',
	1100: '#8f8f8f',
};

export const purple = {
	100: '#F8F7FF',
	200: '#EAE9F9',
	300: '#867FF8',
	400: '#7E78EA',
	500: '#574FCF',
};

export const green = {
	100: '#E6FAED',
	200: '#D4EFDE',
	300: '#55DA6A',
	400: '#3BD070',
	500: '#54CF68',
	600: '#56C768',
	700: '#0ABA26',
	800: '#00AB4E',
};

export const yellow = {
	100: '#FFF2D8',
	200: '#FFEBC1',
	300: '#FFCD66',
	400: '#F6C869',
	500: '#F6BB42',
	600: '#FFC81F',
};

export const red = {
	100: '#FFEFF0',
	200: '#FFD6DA',
	300: '#FF8A96',
	400: '#FF5D6E',
	500: '#ED5565',
	600: '#FC4C53',
	700: '#EB5757',
	800: '#FF354A',
	900: '#F6001A',
};

export const blue = {
	100: '#E9F2FE',
	200: '#CFE3FF',
	300: '#6EA2EA',
	400: '#5078AF',
	500: '#0F3E7C',
	600: '#25282b',
};

export const white = {
	100: '#FFFFFF',
	200: '#F2F5F6',
};
