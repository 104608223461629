import { FetchRankByFlagsPayload, FetchRankByFlagsResponse } from 'reducers/dashboard/sites/rankByFlags/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyRankByFlags } from 'utils/dashboardDummy/sites/dummyRankByFlags';

export const fetchRankByFlags = async (payload: FetchRankByFlagsPayload): Promise<FetchRankByFlagsResponse> => {
  const { isDummy, ...query } = payload;

  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyRankByFlags = getDummyRankByFlags(payload);
    return {
      message: 'SUCCESS',
      data: dummyRankByFlags
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/sites/flag-rank?${serializedQueries}`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const rankByFlagListResult: FetchRankByFlagsResponse = await res.json();
    return rankByFlagListResult;
  } catch {
    // ERROR
    const errorResult: FetchRankByFlagsResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
