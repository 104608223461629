import { getToken } from 'reducers/api';
import { apiURL } from '../../../../config/baseURL';
import { UpdateAuditorRequest } from 'nimbly-common';
import { UpsertUserResponse } from 'reducers/admin/manage/users/types';
import Monitoring from '../../../../utils/monitoring/Monitoring';

export const updateUser = async (req: UpdateAuditorRequest): Promise<UpsertUserResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      // might need to change this to PUT later
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify(req)
    };
    const url = `${apiURL}/users/update`;

    const res = await fetch(url, options);
    const updateUserResult: UpsertUserResponse = await res.json();
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error(updateUserResult.message);
    }
    return updateUserResult;
  } catch (error) {
    Monitoring.logEvent('updateUser', error);
    const rawErrorMessage = error.message;
    let errorMessage: string;
    if (rawErrorMessage.includes('$')) {
      const markedMessage: string = rawErrorMessage.replace(/[^A-Za-z0-9 ]/gi, '$');

      const splittedMarkedMessage = markedMessage.split('$');
      const errorProps = splittedMarkedMessage[2] || 'Some field(s)';
      const tailMessage = splittedMarkedMessage[splittedMarkedMessage.length - 1];
      errorMessage = errorProps + tailMessage;
    } else {
      errorMessage = rawErrorMessage;
    }
    const errorResult: UpsertUserResponse = {
      message: 'FAILED',
      error: errorMessage
    };
    return errorResult;
  }
};
