import { SiteGroupListResponse } from '@nimbly-technologies/nimbly-common';
import { UpdateSiteGroupParams } from 'services/sites/siteGroups.services';
import { CreateSiteGroupPayload, QuerySiteGroupsParams } from 'services/sites/siteGroups.servicesTypes';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './siteGroups.actionTypes';

interface QuerySiteGroupsAsyncParams {
	status?: SiteGroupListResponse['status'];
	search?: string;
}

export const querySiteGroupsAsync = createAsyncAction(
	types.SITE_GROUPS_QUERY_REQUEST,
	types.SITE_GROUPS_QUERY_SUCCESS,
	types.SITE_GROUPS_QUERY_FAILURE,
)<
	QuerySiteGroupsAsyncParams,
	SiteGroupListResponse[],
	{
		error: string;
	}
>();

export const createSiteGroupAsync = createAsyncAction(
	types.SITE_GROUPS_CREATE_REQUEST,
	types.SITE_GROUPS_CREATE_SUCCESS,
	types.SITE_GROUPS_CREATE_FAILURE,
)<
	CreateSiteGroupPayload,
	SiteGroupListResponse,
	{
		error: string;
	}
>();

export const updateSiteGroupAsync = createAsyncAction(
	types.SITE_GROUPS_UPDATE_REQUEST,
	types.SITE_GROUPS_UPDATE_SUCCESS,
	types.SITE_GROUPS_UPDATE_FAILURE,
)<
	{
		siteGroupId: UpdateSiteGroupParams['0'];
		siteGroup: UpdateSiteGroupParams['1'];
	},
	null,
	{
		error: string;
	}
>();

export const querySiteGroupByIdAsync = createAsyncAction(
	types.SITE_GROUPS_QUERY_BY_ID_REQUEST,
	types.SITE_GROUPS_QUERY_BY_ID_SUCCESS,
	types.SITE_GROUPS_QUERY_BY_ID_FAILURE,
)<
	string,
	SiteGroupListResponse,
	{
		error: string;
	}
>();

export const toggleSiteGroupActiveStatusAsync = createAsyncAction(
	types.SITE_GROUPS_TOGGLE_ACTIVE_STATUS_REQUEST,
	types.SITE_GROUPS_TOGGLE_ACTIVE_STATUS_SUCCESS,
	types.SITE_GROUPS_TOGGLE_ACTIVE_STATUS_FAILURE,
)<
	{
		siteGroupId: string;
		onSuccess?: () => void;
	},
	null,
	{
		error: string;
	}
>();

export const setSiteGroupsFilterQuery = createAction(types.SET_SITE_GROUPS_FILTER_QUERY)<QuerySiteGroupsAsyncParams>();

export const siteGroupsActions = {
	queryAllAsync: querySiteGroupsAsync,
	createAsync: createSiteGroupAsync,
	updateAsync: updateSiteGroupAsync,
	queryByIdAsync: querySiteGroupByIdAsync,
	toggleActiveStatusAsync: toggleSiteGroupActiveStatusAsync,
	setFilterQuery: setSiteGroupsFilterQuery,
};

export type SiteGroupAction = ActionType<typeof siteGroupsActions>;
