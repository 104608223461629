import { ActionType, createReducer } from 'typesafe-actions';
import { SiteScheduleActivityState, SiteScheduleActivitySortField } from './type.d';
import * as actions from './siteScheduleActivity.action';
import * as types from './siteScheduleActivity.actionTypes';
import { SortDirection } from 'utils/sort';

export type SiteScheduleActivityAction = ActionType<typeof actions>;

export const initialState: SiteScheduleActivityState = {
	error: '',
	page: 1,
	isLoading: false,
	limit: 10,
	totalPages: 0,
	totalDocs: 0,
	siteID: '',

	siteScheduleActivity: [],
	sortFields: SiteScheduleActivitySortField.timestamp,
	sortDirections: SortDirection.DESC,
	filters: {
		userIDs: [],
		questionnaireIndexIDs: [],
		operations: [],
		sources: [],
		startDate: null,
		endDate: null,
	},
};

export const siteScheduleActivityReducer = createReducer<SiteScheduleActivityState, SiteScheduleActivityAction>(
	initialState,
)
	.handleType(types.SET_SITE_SCHEDULE_ACTIVITY_PAGE_INDEX, (state, action) => ({
		...state,
		isLoading: true,
		page: action.payload.page,
	}))
	.handleType(types.SET_SITE_SCHEDULE_ACTIVITY_SORT, (state, action) => ({
		...state,
		isLoading: true,
		page: initialState.page,
		sortFields: action.payload.sortBy,
		sortDirections: action.payload.sortDirection,
	}))
	.handleType(types.SET_SITE_SCHEDULE_ACTIVITY_FILTERS, (state, action) => ({
		...state,
		isLoading: true,
		filters: { ...state.filters, ...action.payload },
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
	}))
	.handleType(types.RESET_SITE_SCHEDULE_ACTIVITY_FILTERS, (state) => ({
		...state,
		isLoading: true,
		filters: initialState.filters,
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
	}))
	.handleType(types.FETCH_SITE_SCHEDULE_ACTIVITY_REQUEST, (state, action) => ({
		...state,
		error: initialState.error,
		isLoading: true,
		siteID: action.payload.siteID,
	}))
	.handleType(types.FETCH_SITE_SCHEDULE_ACTIVITY_SUCCESS, (state, action) => ({
		...state,
		isLoading: false,
		...action.payload,
	}))
	.handleType(types.FETCH_SITE_SCHEDULE_ACTIVITY_FAILED, (state, action) => ({
		...state,
		isLoading: false,
		error: action.payload.error,
	}));
