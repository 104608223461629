import { createAsyncAction } from 'typesafe-actions';
import {
  AuditorsDailyChart,
  FETCH_AUDITORS_DAILY_CHART_REQUEST,
  FETCH_AUDITORS_DAILY_CHART_SUCCESS,
  FETCH_AUDITORS_DAILY_CHART_FAILURE,
  FetchAuditorsDailyChartPayload
} from './types';

export const fetchAuditorsDailyChartAsync = createAsyncAction(
  FETCH_AUDITORS_DAILY_CHART_REQUEST,
  FETCH_AUDITORS_DAILY_CHART_SUCCESS,
  FETCH_AUDITORS_DAILY_CHART_FAILURE
)<FetchAuditorsDailyChartPayload, AuditorsDailyChart, string>();
