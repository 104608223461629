import { FetchRankByFlagsPayload, RankByFlags } from 'reducers/dashboard/sites/rankByFlags/types';
import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

import site1_greenFlags from '../../../assets/dummy/json/site1-green.json';
import site1_yellowFlags from '../../../assets/dummy/json/site1-yellow.json';
import site1_redFlags from '../../../assets/dummy/json/site1-red.json';

import site2_greenFlags from '../../../assets/dummy/json/site2-green.json';
import site2_yellowFlags from '../../../assets/dummy/json/site2-yellow.json';
import site2_redFlags from '../../../assets/dummy/json/site2-red.json';

import site3_greenFlags from '../../../assets/dummy/json/site3-green.json';
import site3_yellowFlags from '../../../assets/dummy/json/site3-yellow.json';
import site3_redFlags from '../../../assets/dummy/json/site3-red.json';

import site4_greenFlags from '../../../assets/dummy/json/site4-green.json';
import site4_yellowFlags from '../../../assets/dummy/json/site4-yellow.json';
import site4_redFlags from '../../../assets/dummy/json/site4-red.json';

import site5_greenFlags from '../../../assets/dummy/json/site5-green.json';
import site5_yellowFlags from '../../../assets/dummy/json/site5-yellow.json';
import site5_redFlags from '../../../assets/dummy/json/site5-red.json';

const sites_greenFlags = [site1_greenFlags, site2_greenFlags, site3_greenFlags, site4_greenFlags, site5_greenFlags];
const sites_yellowFlags = [
  site1_yellowFlags,
  site2_yellowFlags,
  site3_yellowFlags,
  site4_yellowFlags,
  site5_yellowFlags
];
const sites_redFlags = [site1_redFlags, site2_redFlags, site3_redFlags, site4_redFlags, site5_redFlags];

const sitesName = ['PIK', 'Kota Kasablanka', 'Pondok Indah Mall', 'Mall of Indonesia', 'Pacific Place'];
const sitesCity = ['Jakarta Selatan', 'Jakarta Pusat', 'Jakarta Selatan', 'Jakarta Utara', 'Jakarta Utara'];
const sitesOwners = ['Herby Herado', 'Restu Utomo', 'Samuel Sandro', 'Arief Gusti', 'Nikita Wong'];
const sitesTeam = ['Samuel Sandro', 'Krisnata Panji', 'Komang Mahendra', 'Tiffany Eunike', 'Suci Fransiska'];

const dummyRankByFlags_SCHEDULED_31D: RankByFlags['scheduled'][] = new Array(31)
  .fill('with_date')
  .map((_, dateIndex) => {
    const rankByFlags: RankByFlags['scheduled'] = sitesName.map((siteName, siteIndex) => {
      return {
        siteName,
        city: sitesCity[siteIndex],
        siteID: `site_${siteIndex + 1}`,
        totalGreen: sites_greenFlags[siteIndex][dateIndex],
        totalYellow: sites_yellowFlags[siteIndex][dateIndex],
        totalRed: sites_redFlags[siteIndex][dateIndex],
        totalFlags:
          sites_greenFlags[siteIndex][dateIndex] +
          sites_yellowFlags[siteIndex][dateIndex] +
          sites_redFlags[siteIndex][dateIndex],
        owners: [
          {
            userName: sitesOwners[siteIndex],
            departmentID: '',
            departmentName: '',
            userID: ''
          }
        ],
        team: [
          {
            userName: sitesTeam[siteIndex],
            departmentID: '',
            departmentName: '',
            userID: ''
          }
        ]
      };
    });
    return rankByFlags;
  });

export const getDummyRankByFlags = (payload: FetchRankByFlagsPayload) => {
  const selectedSites = payload.sites;
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let dummyRankByFlags_SCHEDULED: RankByFlags['scheduled'][] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    dummyRankByFlags_SCHEDULED = dummyRankByFlags_SCHEDULED_31D.slice(-diffInDay);
  } else {
    dummyRankByFlags_SCHEDULED = dummyRankByFlags_SCHEDULED_31D.slice(0, diffInDay);
  }

  const reduced_dummyRankByFlags_SCHEDULED = dummyRankByFlags_SCHEDULED.reduce((curr, acc) => {
    return curr.map((_, index) => {
      return {
        siteName: curr[index].siteName,
        siteID: curr[index].siteID,
        city: curr[index].city,
        owners: curr[index].owners,
        team: curr[index].team,
        totalGreen: curr[index].totalGreen + acc[index].totalGreen,
        totalYellow: curr[index].totalYellow + acc[index].totalYellow,
        totalRed: curr[index].totalRed + acc[index].totalRed,
        totalFlags: curr[index].totalFlags + acc[index].totalFlags
      };
    });
  });

  const dummyRankByFlags: RankByFlags = {
    adhoc: [],
    scheduled: reduced_dummyRankByFlags_SCHEDULED
  };

  if (selectedSites !== '_all_') {
    dummyRankByFlags.scheduled = reduced_dummyRankByFlags_SCHEDULED.filter(
      siteRank => siteRank.siteName === selectedSites
    );
  }
  return dummyRankByFlags;
};
