import {
  FETCH_AllOrgResponse,
  CREATE_MasterPasswordPayload,
  CREATE_MasterPasswordResponse,
  ErrorResponse
} from 'reducers/superadmin/fetch-types/clientLogin-fetch.types';

export type AllOrganization = FETCH_AllOrgResponse['data'];

export interface MasterPasswordState {
  readonly createMasterPassword: {
    loading: boolean;
    status: 'SUCCESS' | 'FAILED';
    error: string;
  };
  readonly fetchAllOrg: {
    loading: boolean;
    data: AllOrganization;
    error: string;
  };
}

export const FETCH_ALL_ORG_REQUEST = '@@superadmin/clientLogin/createMasterPassword/FETCH_ALL_ORG_REQUEST';
export const FETCH_ALL_ORG_SUCCESS = '@@superadmin/clientLogin/createMasterPassword/FETCH_ALL_ORG_SUCCESS';
export const FETCH_ALL_ORG_FAILURE = '@@superadmin/clientLogin/createMasterPassword/FETCH_ALL_ORG_FAILURE';

export type FetchAllOrgResponse = FETCH_AllOrgResponse | ErrorResponse;

export const CREATE_MASTER_PASSWORD_REQUEST =
  '@@superadmin/clientLogin/createMasterPassword/CREATE_MASTER_PASSWORD_REQUEST';
export const CREATE_MASTER_PASSWORD_SUCCESS =
  '@@superadmin/clientLogin/createMasterPassword/CREATE_MASTER_PASSWORD_SUCCESS';
export const CREATE_MASTER_PASSWORD_FAILURE =
  '@@superadmin/clientLogin/createMasterPassword/CREATE_MASTER_PASSWORD_FAILURE';

export type CreateMasterPasswordPayload = CREATE_MasterPasswordPayload;
export type CreateMasterPasswordResponse = CREATE_MasterPasswordResponse | ErrorResponse;
