export const GET_DEPT_GROUP_REQUEST = '@departmentGroup/GET_DEPT_GROUP_REQUEST';
export const GET_DEPT_GROUP_SUCCESS = '@departmentGroup/GET_DEPT_GROUP_SUCCESS';
export const GET_DEPT_GROUP_FAILURE = '@departmentGroup/GET_DEPT_GROUP_FAILURE';

export const CLEAR_DEPT_GROUP_FILTER = '@departmentGroup/CLEAR_DEPT_GROUP_FILTER';
export const SET_DEPT_GROUP_FILTER = '@departmentGroup/SET_DEPT_GROUP_FILTER';

export const GET_DEPT_GROUP_BY_ID_REQUEST = '@departmentGroup/GET_DEPT_GROUP_BY_ID_REQUEST';
export const GET_DEPT_GROUP_BY_ID_SUCCESS = '@departmentGroup/GET_DEPT_GROUP_BY_ID_SUCCESS';
export const GET_DEPT_GROUP_BY_ID_FAILURE = '@departmentGroup/GET_DEPT_GROUP_BY_ID_FAILURE';

export const DELETE_DEPT_GROUP_REQUEST = '@departmentGroup/DELETE_DEPT_GROUP_REQUEST';
export const DELETE_DEPT_GROUP_SUCCESS = '@departmentGroup/DELETE_DEPT_GROUP_SUCCESS';
export const DELETE_DEPT_GROUP_FAILURE = '@departmentGroup/DELETE_DEPT_GROUP_FAILURE';

export const CREATE_DEPARTMENT_GROUP_REQUEST = '@departmentGroupEditor/CREATE_DEPARTMENT_GROUP_REQUEST';
export const CREATE_DEPARTMENT_GROUP_SUCCESS = '@departmentGroupEditor/CREATE_DEPARTMENT_GROUP_SUCCESS';
export const CREATE_DEPARTMENT_GROUP_FAILURE = '@departmentGroupEditor/CREATE_DEPARTMENT_GROUP_FAILURE';

export const UPDATE_DEPARTMENT_GROUP_REQUEST = '@departmentGroupEditor/UPDATE_DEPARTMENT_GROUP_REQUEST';
export const UPDATE_DEPARTMENT_GROUP_SUCCESS = '@departmentGroupEditor/UPDATE_DEPARTMENT_GROUP_SUCCESS';
export const UPDATE_DEPARTMENT_GROUP_FAILURE = '@departmentGroupEditor/UPDATE_DEPARTMENT_GROUP_FAILURE';

export const SET_DEPT_GROUP_TAB = '@departmentGroup/SET_DEPT_GROUP_TAB';

export const SET_EDITING_DETAIL = '@departmentGroup/SET_EDITING_DETAIL';
