import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from './actionType';
import * as actions from './action';

import { fetchFlagCards, fetchFlagCardsV1 } from './helper/fetchFlagCards';

export function* fetchFlagCardsSaga(action: ReturnType<typeof actions.fetchCardFlagAsync.request>): Generator {
  try {
    const fetchRes: types.FetchFlagsOverviewResponse = (yield call(fetchFlagCards, action.payload)) as any;

    const { data } = fetchRes;

    if (data) {
      yield put(actions.fetchCardFlagAsync.success(fetchRes.data));
    } else {
      yield put(actions.fetchCardFlagAsync.failure('FETCH FLAG CARDS FAILED'));
    }
  } catch (e) {
    yield put(actions.fetchCardFlagAsync.failure('FETCH FLAG CARDS FAILED'));
  }
}

export function* fetchFlagCardsV1Saga(action: ReturnType<typeof actions.fetchCardFlagV1Async.request>): Generator {
  try {
    const fetchRes: types.FetchFlagsOverviewResponse = (yield call(fetchFlagCardsV1, action.payload)) as any;

    const { data } = fetchRes;

    if (data) {
      yield put(actions.fetchCardFlagV1Async.success(fetchRes.data));
    } else {
      yield put(actions.fetchCardFlagV1Async.failure('FETCH FLAG CARDS V1 FAILED'));
    }
  } catch (e) {
    yield put(actions.fetchCardFlagV1Async.failure('FETCH FLAG CARDS V1 FAILED'));
  }
}

export function* cardFlagSaga() {
  yield takeLatest(types.FETCH_CARD_FLAG_REQUEST, fetchFlagCardsSaga);
  yield takeLatest(types.FETCH_CARD_FLAGV1_REQUEST, fetchFlagCardsV1Saga);
}
