import { AttachmentGalleryResponse } from '@nimbly-technologies/nimbly-common';
import { AttachmentDetails } from 'components/gallery/component/AttachmentThumbnailCard';
import { createAsyncAction, deprecated } from 'typesafe-actions';
import * as types from './gallery.actionTypes';
import {
	FetchAttachmentsSuccessPayload,
	GalleryFilters,
	GalleryGroupByType,
	GalleryProgressModalConfig,
	GalleryTabEnum,
	SearchAttachmentResult,
} from './type.d';

const { createStandardAction } = deprecated;

/**
 * @activeTab selected active tab
 */
export const setActiveGalleryTab = createStandardAction(types.SET_ACTIVE_GALLERY_TAB)<GalleryTabEnum>();
/**
 * @searchQuery search query from user input
 */
export const setGallerySearchQuery = createStandardAction(types.SET_GALLERY_SEARCH_QUERY)<string>();
/**
 * @filters object of gallery filters
 */
export const setGalleryFilters = createStandardAction(types.SET_GALLERY_FILTERS)<Partial<GalleryFilters>>();
export const resetGalleryFilters = createStandardAction(types.RESET_GALLERY_FILTERS)<undefined>();
/**
 * @groupBy group type
 */
export const setGalleryGroupBy = createStandardAction(types.SET_GALLERY_GROUP_BY)<GalleryGroupByType>();
/**
 * @pageIndex page index for pagination
 */
export const setGalleryPageIndex = createStandardAction(types.SET_GALLERY_PAGE_INDEX)<number>();
/**
 * @gridSize size for grid size
 */
export const setGalleryGridSize = createStandardAction(types.SET_GALLERY_GRID_SIZE)<number>();

/**
 * @selectedAttachment selected attachment details
 */
export const setSelectedGalleryAttachment = createStandardAction(
	types.SET_SELECTED_GALLERY_ATTACHMENT,
)<AttachmentGalleryResponse | null>();
/**
 * @selectedAttachmentIds array of selected attachment IDs
 */
export const setSelectedGalleryAttachmentIds = createStandardAction(types.SET_SELECTED_GALLERY_ATTACHMENT_IDS)<
	Set<string>
>();
/**
 * @totalSelectedAttachments total of selected attachments
 */
export const setTotalSelectedGalleryAttachments = createStandardAction(types.SET_TOTAL_SELECTED_GALLERY_ATTACHMENTS)<
	number
>();
/**
 * @totalSelectedAttachments total size of selected attachments
 */
export const setTotalSelectedAttachmentsSize = createStandardAction(types.SET_TOTAL_SELECTED_GALLERY_ATTACHMENTS_SIZE)<
	number
>();

/**
 * @selectedAlbum selected album details
 */
export const setSelectedGalleryAlbum = createStandardAction(types.SET_SELECTED_GALLERY_ALBUM)<
	AttachmentDetails | undefined
>();
/**
 * @selectedAlbumIds array of selected album IDs
 */
export const setSelectedGalleryAlbumIds = createStandardAction(types.SET_SELECTED_GALLERY_ALBUM_IDS)<Set<string>>();

/**
 * @selectedDates selected dates
 */
export const setSelectedGalleryDates = createStandardAction(types.SET_SELECTED_GALLERY_DATES)<Set<string>>();

export const toggleGalleryFilter = createStandardAction(types.TOGGLE_GALLERY_FILTER)<boolean>();
export const toggleGallerySelectMode = createStandardAction(types.TOGGLE_GALLERY_SELECT_MODE)<boolean>();

export const toggleGalleryDownloadErrorModal = createStandardAction(types.TOGGLE_DOWNLOAD_ERROR_MODAL)<boolean>();
export const toggleGalleryDownloadInitializationModal = createStandardAction(
	types.TOGGLE_DOWNLOAD_INITIALIZATION_MODAL,
)<boolean>();
export const setGalleryDownloadProgressModalConfig = createStandardAction(types.SET_DOWNLOAD_PROGRESS_MODAL_CONFIG)<
	GalleryProgressModalConfig
>();

export const fetchAttachments = createAsyncAction(
	types.FETCH_ATTACHMENTS_REQUEST,
	types.FETCH_ATTACHMENTS_SUCCESS,
	types.FETCH_ATTACHMENTS_FAILED,
)<{ loadMore?: boolean }, FetchAttachmentsSuccessPayload, { error: string }>();

export const searchAttachments = createAsyncAction(
	types.SEARCH_ATTACHMENTS_REQUEST,
	types.SEARCH_ATTACHMENTS_SUCCESS,
	types.SEARCH_ATTACHMENTS_FAILED,
)<undefined, SearchAttachmentResult, { error: string }>();
