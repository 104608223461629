import * as Common from 'nimbly-common';
import moment from 'moment';

export const getDummyCustomIdxRange = (startDate?: string | null, endDate?: string | null) => {
  if (!startDate || !endDate) {
    return 0;
  }

  const endMoment = moment(endDate);
  const diffInDay = endMoment.diff(startDate, 'days');

  return diffInDay + 1;
};

export const getDummyIdxRange = (periodType: Common.enums.PeriodTypes) => {
  if (periodType === Common.enums.PeriodTypes.MTD) {
    const endMoment = moment();
    const startOfMonth = moment().startOf('month');
    const diffInDay = endMoment.diff(startOfMonth, 'days');

    return diffInDay + 1;
  } else {
    switch (periodType) {
      case Common.enums.PeriodTypes.CURRENT:
        return 7;
      case Common.enums.PeriodTypes.PREVIOUS:
        return 7;
    }
  }
  return 0;
};
