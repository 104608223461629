export const SCROLL_LIST_LOG = 'errorlog/SCROLL_LIST_LOG';
export const SET_ORGANIZATION = 'errorlog/ORGANIZATION';

export const setScrollList = () => {
  return { type: SCROLL_LIST_LOG };
};

export const setCreatedByOrganization = org => {
  return { type: SET_ORGANIZATION, org };
};

const initialState = {
  queryLimit: 50,
  organization: ''
};

export function errorlogReducer(state = initialState, action) {
  switch (action.type) {
    case SCROLL_LIST_LOG:
      return {
        ...state,
        queryLimit: state.queryLimit + 25
      };
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.org,
        queryLimit: 50
      };
    default:
      return state;
  }
}
