import { ActionType, createReducer } from 'typesafe-actions';
import { ILmsLearnerActivityStore, LearnerActivityExtended } from './lmsLearnerActivity';
import * as actions from './lmsLearnerActivity.action';
import * as types from './lmsLearnerActivity.actionTypes';

const initialState: ILmsLearnerActivityStore = {
	fetchById: {
		data: null,
		isLoading: false,
		errorMessage: '',
	},
	update: {
		isLoading: false,
		errorMessage: '',
	},
};

export type LmsUserActivityAction = ActionType<typeof actions>;
export const lmsLearnerActivityReducer = createReducer<ILmsLearnerActivityStore, LmsUserActivityAction>(initialState)
	.handleAction(actions.fetchLmsLearnerActivityByIdAsync.request, (state) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsLearnerActivityByIdAsync.success, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: false,
			data: action.payload as LearnerActivityExtended,
			errorMessage: '',
		},
	}))
	.handleAction(actions.fetchLmsLearnerActivityByIdAsync.failure, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: false,
			data: null,
			errorMessage: action.payload,
		},
	}))
	.handleAction(actions.updateLmsLearnerActivityAsync.request, (state) => ({
		...state,
		update: {
			...state.update,
			isLoading: true,
		},
	}))
	.handleAction(actions.updateLmsLearnerActivityAsync.success, (state, action) => ({
		...state,
		update: {
			...state.update,
			isLoading: false,
			data: action.payload,
			errorMessage: '',
		},
		fetchById: {
			...state.fetchById,
			data: action.payload as LearnerActivityExtended,
		},
	}))
	.handleAction(actions.updateLmsLearnerActivityAsync.failure, (state, action) => ({
		...state,
		update: {
			...state.update,
			isLoading: false,
			errorMessage: action.payload,
		},
	}))
	.handleAction(types.CLEAR_LMS_LEARNER_ACTIVITY_BY_ID as any, (state) => ({
		...state,
		fetchById: {
			...state.fetchById,
			data: null,
		},
	}));
