import React, { useEffect, useState } from 'react';
import { RouteProps, Route as RouteBase } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Common from 'nimbly-common';

import NoAccessPage from './components/NoAccess';
import LoadingAccessPage from './components/LoadingAccess';
import { Resource } from '../permissions/utils/updateResourcePermissions';
import { RootState } from '../../store/rootReducers';
import { UserAccessState, updateAccessPermission } from 'reducers/useraccess';
import * as actions from 'reducers/featureAccess/featureAccess.action';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { setAccessPath } from 'reducers/routeAccess/action';

function Route_BASE({
	component: Component,
	withValidation,
	access,
	feature,
	lmsRoleAccessType,
	...otherProps
}: RouteWithValidationProps) {
	const { path, setAccess, pathAccessState } = otherProps;
	/**
	 * `null` => should render loading page
	 * `true` => should render `Component` from props
	 * `false` => should render noAccess page
	 */
	const [isAccessible, setIsAccessible] = useState<boolean | null>(null);

	const { t } = useTranslation();

	useEffect(() => {
		setAccess({
			path: (path as string) || '',
			feature: feature || '',
			resource: access?.resource || '',
			lmsRoleAccessType: lmsRoleAccessType || '',
		});
	}, [setAccess, path, feature, access, lmsRoleAccessType]);

	useEffect(() => {
		if (!pathAccessState || !pathAccessState.isLoaded) {
			// loading
			setIsAccessible(null);
			return;
		}

		if (pathAccessState.error) {
			// show loading page?
			setIsAccessible(null);
			toast.error(t('error.common.fetchAccess'));
			return;
		}
		setIsAccessible(pathAccessState.isAccessible);
	}, [pathAccessState, t]);

	return (
		<RouteBase
			{...otherProps}
			render={(routeProps) => {
				if (!Component) {
					return;
				}

				// bypass all role and feature access validation
				if (withValidation === false) {
					return <Component {...routeProps} />;
				}

				if (isAccessible === null) {
					return <LoadingAccessPage />;
				}

				if (isAccessible) {
					return <Component {...routeProps} />;
				} else {
					return <NoAccessPage />;
				}
			}}
		/>
	);
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
	userAccess: state.userAccess,
	featureAccess: state.featureAccess.features,
	isFeatureAccessLoaded: state.featureAccess.isLoaded,
	pathAccessState: state.routeAccess[ownProps.path as string],
});

const mapDispatchToProps = (dispatch: any) => ({
	updateAccessPermission: (userAccess: Partial<UserAccessState>) => dispatch(updateAccessPermission(userAccess)),
	showAccessModal: () => dispatch(actions.showFeatureModal()),
	setAccess: (payload: {
		path: string;
		feature: Common.enums.FeaturesType;
		resource: string;
		lmsRoleAccessType: string;
	}) => dispatch(setAccessPath.request(payload)),
});

type OwnProps = RouteProps & {
	withValidation: boolean;
	access: Resource;
	feature: Common.enums.FeaturesType;
	lmsRoleAccessType?: string;
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RouteWithValidationProps = OwnProps & StateProps & DispatchProps;

const Route = connect(mapStateToProps, mapDispatchToProps)(Route_BASE) as any;

export default Route;
