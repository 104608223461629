import {
	BulkUpdateCourseRequest,
	Course,
	CoursePaginateResponse,
	CourseStatus,
} from '@nimbly-technologies/nimbly-common';
import { LMSAccessType } from '@nimbly-technologies/nimbly-common/lib/enumerators';

import { SortDirection } from 'reducers/lms/userManagement/userList/type';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './courses.actionTypes';
import { CourseBulkErrorResponse, CourseFilters, CoursesSortFields } from './type.d';

export const setCoursesPageIndex = (page: number) => action(types.SET_COURSES_PAGE_INDEX, { page });

export const setCoursesSearchQuery = (searchQuery: string) => action(types.SET_COURSES_SEARCH_QUERY, { searchQuery });

export const setCoursesSort = (sortField: CoursesSortFields, sortDirection: SortDirection) =>
	action(types.SET_COURSES_SORT, { sortField, sortDirection });

export const setCoursesFilters = (filters: Partial<CourseFilters>) =>
	action(types.SET_COURSES_FILTER_QUERY, { filters });

export const resetCoursesFilters = () => action(types.RESET_COURSES_FILTER_QUERY);

export const fetchCourses = createAsyncAction(
	types.FETCH_COURSES_REQUEST,
	types.FETCH_COURSES_SUCCESS,
	types.FETCH_COURSES_FAILED,
)<
	{ status?: CourseStatus; accessType?: LMSAccessType },
	{
		courses: CoursePaginateResponse[];
		totalDocs?: number;
		page?: number;
		totalPages?: number;
	},
	{
		error: string;
	}
>();

export const editCourses = createAsyncAction(
	types.EDIT_COURSES_REQUEST,
	types.EDIT_COURSES_SUCCESS,
	types.EDIT_COURSES_FAILED,
)<
	{
		editedCourses: BulkUpdateCourseRequest[];
		successMessage?: string;
	},
	undefined,
	{
		error: CourseBulkErrorResponse[];
	}
>();

export const editCourse = createAsyncAction(
	types.EDIT_COURSE_REQUEST,
	types.EDIT_COURSE_SUCCESS,
	types.EDIT_COURSE_FAILED,
)<
	{
		courseID: string;
		editedCourse: Partial<Course>;
		successMessage?: string;
	},
	undefined,
	{
		error: string;
	}
>();
