import { createAsyncAction, action } from 'typesafe-actions';

import * as types from './promotion.actionTypes';

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });
export const setSort = (sortType: string, sortOrder: string) => action(types.SET_SORT, { sortType, sortOrder });

export const fetchPromotion = createAsyncAction(
  types.FETCH_PROMOTIONS_REQUEST,
  types.FETCH_PROMOTIONS_SUCCESS,
  types.FETCH_PROMOTIONS_FAILURE
)<undefined, { data: any | null }, { error: string }>();

export const createPromotion = createAsyncAction(
  types.CREATE_PROMOTION_REQUEST,
  types.CREATE_PROMOTION_SUCCESS,
  types.CREATE_PROMOTION_FAILURE
)<any, any, { error: string }>();

export const createPromotionBulk = createAsyncAction(
  types.CREATE_PROMOTIONS_BULK_REQUEST,
  types.CREATE_PROMOTIONS_BULK_SUCCESS,
  types.CREATE_PROMOTIONS_BULK_FAILURE
)<any, any, { error: string }>();

export const fetchPromotionDetail = createAsyncAction(
  types.FETCH_PROMOTION_DETAIL_REQUEST,
  types.FETCH_PROMOTION_DETAIL_SUCCESS,
  types.FETCH_PROMOTION_DETAIL_FAILURE
)<any, { data: any | null }, { error: string }>();

export const updatePromotion = createAsyncAction(
  types.UPDATE_PROMOTION_REQUEST,
  types.UPDATE_PROMOTION_SUCCESS,
  types.UPDATE_PROMOTION_FAILURE
)<any, any, { error: string }>();

export const deletePromotion = createAsyncAction(
  types.DELETE_PROMOTION_REQUEST,
  types.DELETE_PROMOTION_SUCCESS,
  types.DELETE_PROMOTION_FAILURE
)<any, any, { error: string }>();
