import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './promotions.action';
import * as types from './promotions.actionType';
import { promotionState } from './type';

export type PromotionAction = ActionType<typeof actions>;

const initialState: promotionState = {
  promotionList: null,
  promotion: null,
  isLoading: false,
  error: null,
  promotionListRaw: null,
  filterQuery: '',
  sortBy: 'title',
  title: 'asc',
  sales: 'asc',
  message: 'asc',
  target: 'asc',
  start: 'asc',
  end: 'asc',
  cost: 'asc',
  selectedTab: 'active',
  pageIndex: 0,
  total: 0,
  paginationPromotions: null,
  isLoadingCreate: false,
  isLoadingUpdate: false
};

export const promotionReducer = createReducer<promotionState, PromotionAction>(initialState)
  .handleAction(actions.fetchPaginatePromotions.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchPaginatePromotions.success, (state, action) => ({
    ...state,
    total: action.payload.total,
    paginationPromotions: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchPaginatePromotions.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.fetchPromotions.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchPromotions.success, (state, action) => ({
    ...state,
    promotionListRaw: action.payload.data.raw,
    isLoading: false
  }))
  .handleAction(actions.fetchPromotions.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.createPromotion.request, state => ({
    ...state,
    error: null,
    isLoadingCreate: true
  }))
  .handleAction(actions.createPromotion.success, (state, action) => ({
    ...state,
    isLoadingCreate: false
  }))
  .handleAction(actions.createPromotion.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoadingCreate: false
  }))
  .handleAction(actions.updatePromotion.request, state => ({
    ...state,
    error: null,
    isLoadingUpdate: true
  }))
  .handleAction(actions.updatePromotion.success, (state, action) => ({
    ...state,
    isLoadingUpdate: false
  }))
  .handleAction(actions.updatePromotion.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoadingUpdate: false
  }))
  .handleAction(actions.fetchPromotionDetail.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchPromotionDetail.success, (state, action) => ({
    ...state,
    promotion: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchPromotionDetail.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(types.SET_SORT as any, (state, action) => {
    if (state.sortBy !== action.payload.sortType) {
      return {
        ...state,
        sortBy: action.payload.sortType,
        title: 'asc',
        sales: 'asc',
        message: 'asc',
        target: 'asc',
        start: 'asc',
        end: 'asc',
        cost: 'asc'
      };
    }
    return {
      ...state,
      [action.payload.sortType]: action.payload.sortOrder
    };
  })
  .handleAction(types.SET_PAGE_INDEX as any, (state, action) => {
    return {
      ...state,
      pageIndex: action.payload.value
    };
  })
  .handleAction(types.SET_SELECTED_TAB as any, (state, action) => ({
    ...state,
    selectedTab: action.payload.tab
  }));

export default promotionReducer;
