import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from 'reducers/siteUser/siteUser.action';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';

function* fetchSiteUserCompact({ payload }: ReturnType<typeof actions.siteUserAction.request>) {
	const authToken = yield API.getFirebaseToken();

	const body = {
		withDisabled: payload?.withDisabled || false,
		userIDs: payload?.userIDs || [],
		siteGroupIDs: payload?.siteGroupIDs || [],
	};
	const URL = `${apiURL}/sites/site-user`;
	const response = yield call(API.post, URL, authToken, body);
	const { data, message } = yield response.json();
	if (response && response.status === 200) {
		yield put(actions.siteUserAction.success({ data, message }));
	} else {
		yield put(actions.siteUserAction.failure({ message }));
	}
}

export default function* siteUserCompactSaga() {
	yield takeLatest(actions.siteUserAction.request, fetchSiteUserCompact);
}
