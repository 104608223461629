import { createAsyncAction } from 'typesafe-actions';

import {
  OverviewCompletionCards,
  FETCH_CARD_COMPLETION_REQUEST,
  FETCH_CARD_COMPLETION_SUCCESS,
  FETCH_CARD_COMPLETION_FAILURE,
  FetchCompletionCardsPayload
} from './actionType';

export const fetchCardCompletionAsync = createAsyncAction(
  FETCH_CARD_COMPLETION_REQUEST,
  FETCH_CARD_COMPLETION_SUCCESS,
  FETCH_CARD_COMPLETION_FAILURE
)<FetchCompletionCardsPayload, OverviewCompletionCards, string>();
