import { CourseStatus } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/course/updateCourse/updateCourse.action';
import * as types from 'reducers/lms/course/updateCourse/updateCourse.actionTypes';
import { getSuccessToastMessage } from 'reducers/lms/coursesSyllabuses/utils/getSuccessToastMessage';
import { call, put, takeLatest } from 'redux-saga/effects';

function* updateCourse(action: ReturnType<typeof actions.updateCourse.request>) {
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const body = action.payload.data;

		const options = {
			method: 'PUT',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};
		const URL = `${apiURL}/lms/courses/${body.courseID}`;
		const request = () => fetch(URL, options);
		// @ts-ignore
		const response = yield call(request);
		const responseJson = yield response.json();

		if (response && response.status === 200) {
			yield put(actions.updateCourse.success());
			getSuccessToastMessage(action.payload.data.status);
		} else {
			const errorMessage = responseJson.data;
			toast.error(errorMessage);
			yield put(actions.updateCourse.failure({ error: errorMessage }));
		}
	} catch (e) {
		toast.error('Failed to Update Course');
		yield put(actions.updateCourse.failure({ error: 'Failed to Update Course' }));
	}
}

export default function* updateCourseSaga() {
	yield takeLatest(types.UPDATE_LMS_COURSE_REQUEST, updateCourse);
}
