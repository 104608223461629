import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesFlagsOverview,
  FETCH_ISSUES_FLAGS_OVERVIEW_REQUEST,
  FETCH_ISSUES_FLAGS_OVERVIEW_SUCCESS,
  FETCH_ISSUES_FLAGS_OVERVIEW_FAILURE,
  FetchFlagsOverviewPayload
} from './types';

export const fetchIssuesFlagsOverviewAsync = createAsyncAction(
  FETCH_ISSUES_FLAGS_OVERVIEW_REQUEST,
  FETCH_ISSUES_FLAGS_OVERVIEW_SUCCESS,
  FETCH_ISSUES_FLAGS_OVERVIEW_FAILURE
)<FetchFlagsOverviewPayload, IssuesFlagsOverview, string>();
