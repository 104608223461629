export const FETCH_SETTINGS_NOTIFICATION_REQUEST = '@settings/notification/GET_REQUEST';
export const FETCH_SETTINGS_NOTIFICATION_SUCCESS = '@settings/notification/GET_SUCCESS';
export const FETCH_SETTINGS_NOTIFICATION_FAILURE = '@settings/notification/GET_FAILURE';

export const UPDATE_SETTINGS_NOTIFICATION_REQUEST = '@settings/notification/UPDATE_REQUEST';
export const UPDATE_SETTINGS_NOTIFICATION_SUCCESS = '@settings/notification/UPDATE_SUCCESS';
export const UPDATE_SETTINGS_NOTIFICATION_FAILURE = '@settings/notification/UPDATE_FAILURE';

export const CREATE_SETTINGS_NOTIFICATION_REQUEST = '@settings/notification/CREATE_REQUEST';
export const CREATE_SETTINGS_NOTIFICATION_SUCCESS = '@settings/notification/CREATE_SUCCESS';
export const CREATE_SETTINGS_NOTIFICATION_FAILURE = '@settings/notification/CREATE_FAILURE';

export const FETCH_SETTINGS_NOTIFICATION_UNSUBSCRIBE_REQUEST = '@settings/notification/UNSUBSCRIBE_REQUEST';
export const FETCH_SETTINGS_NOTIFICATION_UNSUBSCRIBE_SUCCESS = '@settings/notification/UNSUBSCRIBE_SUCCESS';
export const FETCH_SETTINGS_NOTIFICATION_UNSUBSCRIBE_FAILURE = '@settings/notification/UNSUBSCRIBE_FAILURE';

export const UPDATE_SETTINGS_NOTIFICATION_UNSUBSCRIBE_REQUEST = '@settings/notification/UNSUBSCRIBE_UPDATE_REQUEST';
export const UPDATE_SETTINGS_NOTIFICATION_UNSUBSCRIBE_SUCCESS = '@settings/notification/UNSUBSCRIBE_UPDATE_SUCCESS';
export const UPDATE_SETTINGS_NOTIFICATION_UNSUBSCRIBE_FAILURE = '@settings/notification/UNSUBSCRIBE_UPDATE_FAILURE';

export const RESET_SETTINGS_NOTIFICATION = '@settings/notification/RESET';
