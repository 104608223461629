import {
	BaseOptionObject,
	BaseSelectOptionType,
	mapOptionsDataToObject,
} from 'components/global/Select/utils/optionsMapper';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from 'reducers/users/users.action';
import { RootState } from 'store/rootReducers';

export const useUserDataOptions = (disableFetch?: boolean) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (!disableFetch) {
			dispatch(fetchUsers.request());
		}
	}, [dispatch]);

	const users = useSelector((state: RootState) => state.users.users) || {};

	const userMap = useMemo<BaseOptionObject>(
		() =>
			mapOptionsDataToObject({
				data: Object.values(users),
				key: 'userID',
				value: 'userID',
				label: 'displayName',
			}),
		[users],
	);
	const userOptions: BaseSelectOptionType[] = useMemo(
		() => Object.values(userMap).sort((a, b) => a.label.localeCompare(b.label)),
		[userMap],
	);

	return {
		userMap,
		userOptions,
	};
};
