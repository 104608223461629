import { appengineURL, apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { Site } from 'nimbly-common';
import { OrganizationSiteOverview } from 'reducers/sites/type';

export default class SiteAPI {
	public static async updateSite(site: Site) {
		const api = new SiteAPI();

		const token = await API.getFirebaseToken();

		const res = await API.put(`${api.appEngineSiteURL}/${site.siteID}`, token, site);

		const contentType = res.headers.get('Content-Type');

		if (!contentType?.includes('application/json')) {
			return null;
		}

		const json = await res.json();
	}

	public static async getInternalSites(organizationID: string, query: Partial<Site> = {}): Promise<Site[]> {
		const api = new SiteAPI();

		const token = await API.getFirebaseToken();

		const payload: Partial<Site> = { organizationID };

		if (query.name) payload.name = query.name;
		const res = await API.post(`${api.siteURL}/internal`, token, payload);

		const contentType = res.headers.get('Content-Type');
		const json = await res.json();

		if (!contentType?.includes('application/json')) {
			return [];
		}

		if (res.status !== 200) throw new Error(json.data || 'Failed to retrieve data');

		return json.data;
	}

	public static async getOverviewSites(): Promise<OrganizationSiteOverview[]> {
		const api = new SiteAPI();

		const token = await API.getFirebaseToken();

		const res = await API.get(`${api.siteURL}/internal/overview`, token);

		const contentType = res.headers.get('Content-Type');
		const json = await res.json();

		if (!contentType?.includes('application/json')) {
			return [];
		}

		if (res.status !== 200) throw new Error(json.data || 'Failed to retrieve data');

		return json.data;
	}

	public static async internalCreateSite(organizationID: string, createdSite: Partial<Site>): Promise<Site[]> {
		const api = new SiteAPI();

		const token = await API.getFirebaseToken();

		const payload = { organizationID, ...createdSite };
		const res = await API.put(`${api.siteURL}/internal/create`, token, payload);

		const contentType = res.headers.get('Content-Type');
		const json = await res.json();

		if (!contentType?.includes('application/json')) {
			return [];
		}

		if (res.status !== 200) throw new Error(json.data || 'Failed to retrieve data');

		return json.data;
	}

	public static async internalUpdateSite(
		organizationID: string,
		siteID: string,
		updatedSite: Partial<Site>,
	): Promise<Site[]> {
		const api = new SiteAPI();

		const token = await API.getFirebaseToken();

		const payload = { organizationID, siteID, ...updatedSite };
		const res = await API.put(`${api.siteURL}/internal/update`, token, payload);

		const contentType = res.headers.get('Content-Type');
		const json = await res.json();

		if (!contentType?.includes('application/json')) {
			return [];
		}

		if (res.status !== 200) throw new Error(json.data || 'Failed to retrieve data');

		return json.data;
	}

	private siteURL: string;
	private appEngineSiteURL: string;

	private constructor() {
		this.appEngineSiteURL = `${appengineURL}/sites`;
		this.siteURL = `${apiURL}/sites`;
	}
}
