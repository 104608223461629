export const SET_SHOW_MODAL = 'EnrollCourseManagement/SET_SHOW_MODAL';
export const SET_SUCCESS = 'EnrollCourseManagement/SET_SUCCESS';

export const GET_COURSE_LIST_REQUEST = 'EnrollCourseManagement/GET_COURSE_LIST_REQUEST';
export const GET_COURSE_LIST_SUCCESS = 'EnrollCourseManagement/GET_COURSE_LIST_LOADING';
export const GET_COURSE_LIST_FAILURE = 'EnrollCourseManagement/GET_COURSE_LIST_FAILURE';

export const SET_COURSE_LIST_FILTER_QUERY = 'EnrollCourseManagement/SET_COURSE_LIST_FILTER_QUERY';
export const SET_COURSE_LIST_PAGE_INDEX = 'EnrollCourseManagement/SET_COURSE_LIST_PAGE_INDEX';
export const SET_COURSE_LIST_SEARCH_QUERY = 'EnrollCourseManagement/SET_COURSE_LIST_SEARCH_QUERY';
export const SET_COURSE_LIST_SORT = 'EnrollCourseManagement/SET_COURSE_LIST_SORT';

export const UPDATE_USERGROUP_ENROLLCOURSE_REQUEST = 'EnrollCourseManagement/UPDATE_USERGROUP_ENROLLCOURSE_REQUEST';
export const UPDATE_USERGROUP_ENROLLCOURSE_SUCCESS = 'EnrollCourseManagement/UPDATE_USERGROUP_ENROLLCOURSE_LOADING';
export const UPDATE_USERGROUP_ENROLLCOURSE_FAILURE = 'EnrollCourseManagement/UPDATE_USERGROUP_ENROLLCOURSE_FAILURE';

export const RESET_COURSE_FILTER = 'EnrollCourseManagement/RESET_COURSE_FILTER';
