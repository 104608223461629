export const SET_LMS_COURSE_QUIZ_SELECTED_ID = '@lms/course/quiz/SET_SELECTED_QUIZ_ID';
export const SET_LMS_COURSE_QUIZ_CURRENT_QUESTION_INDEX = '@lms/course/quiz/SET_CURRENT_QUESTION_INDEX';
export const SET_LMS_COURSE_QUIZ_STEP = '@lms/course/quiz/SET_STEP';
export const SET_LMS_COURSE_QUIZ_TIMEOUT_MODAL_VISIBLE = '@lms/course/quiz/SET_TIMEOUT_MODAL_VISIBLE';
export const SET_LMS_COURSE_QUIZ_SUMMARY_QUESTIONS_MOBILE_VISIBLE =
	'@lms/course/quiz/SET_SUMMARY_QUESTIONS_MOBILE_VISIBLE';

export const QUERY_LMS_COURSE_QUIZ_BY_ID_REQUEST = '@lms/course/quiz/QUERY_BY_ID_REQUEST';
export const QUERY_LMS_COURSE_QUIZ_BY_ID_SUCCESS = '@lms/course/quiz/QUERY_BY_ID_SUCCESS';
export const QUERY_LMS_COURSE_QUIZ_BY_ID_FAILURE = '@lms/course/quiz/QUERY_BY_ID_FAILURE';

export const START_LMS_COURSE_QUIZ_REQUEST = '@lms/course/quiz/START_QUIZ_REQUEST';
export const START_LMS_COURSE_QUIZ_SUCCESS = '@lms/course/quiz/START_QUIZ_SUCCESS';
export const START_LMS_COURSE_QUIZ_FAILURE = '@lms/course/quiz/START_QUIZ_FAILURE';

export const QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_REQUEST =
	'@lms/course/quiz/QUERY_SUBMISSION_DRAFT_BY_SUBMIT_ID_REQUEST';
export const QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_SUCCESS =
	'@lms/course/quiz/QUERY_SUBMISSION_DRAFT_BY_SUBMIT_ID_SUCCESS';
export const QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_FAILURE =
	'@lms/course/quiz/QUERY_SUBMISSION_DRAFT_BY_SUBMIT_ID_FAILURE';

export const UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_REQUEST = '@lms/course/quiz/UPDATE_SUBMISSION_DRAFT_REQUEST';
export const UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_SUCCESS = '@lms/course/quiz/UPDATE_SUBMISSION_DRAFT_SUCCESS';
export const UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_FAILURE = '@lms/course/quiz/UPDATE_SUBMISSION_DRAFT_FAILURE';

export const SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_REQUEST = '@lms/course/quiz/SUBMIT_SUBMISSION_REQUEST';
export const SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_SUCCESS = '@lms/course/quiz/SUBMIT_SUBMISSION_SUCCESS';
export const SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_FAILURE = '@lms/course/quiz/SUBMIT_SUBMISSION_FAILURE';

export const RETRY_LMS_COURSE_QUIZ_REQUEST = '@lms/course/quiz/RETRY_REQUEST';
export const RETRY_LMS_COURSE_QUIZ_SUCCESS = '@lms/course/quiz/RETRY_SUCCESS';
export const RETRY_LMS_COURSE_QUIZ_FAILURE = '@lms/course/quiz/RETRY_FAILURE';
