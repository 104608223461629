import { enums, internalStatistic } from '@nimbly-technologies/nimbly-common';

export const FETCH_MISSED_MAKEUP_OVERVIEW_REQUEST = 'dashboard/overview/v1/FETCH_MISSED_MAKEUP_OVERVIEW_REQUEST';
export const FETCH_MISSED_MAKEUP_OVERVIEW_SUCCESS = 'dashboard/overview/v1/FETCH_MISSED_MAKEUP_OVERVIEW_SUCCESS';
export const FETCH_MISSED_MAKEUP_OVERVIEW_FAILURE = 'dashboard/overview/v1/FETCH_MISSED_MAKEUP_OVERVIEW_FAILURE';

export const FETCH_MISSED_MAKEUP_LIST_REQUEST = 'dashboard/overview/v1/FETCH_MISSED_MAKEUP_LIST_REQUEST';
export const FETCH_MISSED_MAKEUP_LIST_SUCCESS = 'dashboard/overview/v1/FETCH_MISSED_MAKEUP_LIST_SUCCESS';
export const FETCH_MISSED_MAKEUP_LIST_FAILURE = 'dashboard/overview/v1/FETCH_MISSED_MAKEUP_LIST_FAILURE';

/** Use this query for Missed Makeup Overview */
export interface PeriodPayloadType {
	periodType: enums.PeriodTypes | string;
	startDate: string;
	endDate: string;
}
export type FetchMissedMakeupQuery = {
	departments: string[];
	sites: string[];
	users: string[];
	questionnaires: string[];
} & PeriodPayloadType;

export type FetchMissedMakeupReportListQuery = {
	limit: number;
	page: number;
	field: string;
	direction?: 'asc' | 'desc';
} & FetchMissedMakeupQuery;

export interface MissedMakeupOverviewState {
	missedMakeupOverview: {
		data: internalStatistic.ReportSummaryOverview;
		isLoading: boolean;
		error: string;
	};
}
export interface MissedMakeupReportListState {
	missedMakeupReportList: {
		data: internalStatistic.ReportSummaryList;
		isLoading: boolean;
		error: string;
	};
}
