import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './questionnaire.action';
import { QuestionnaireByPropsState } from './type';
import * as types from './questionnaire.actionTypes';

export type QuestionnaireAction = ActionType<typeof actions>;

const initialState: QuestionnaireByPropsState = {
	data: [],
	isLoading: false,
	mappedData: {},
	error: '',
};

const questionnaireByPropsReducer = createReducer<QuestionnaireByPropsState, QuestionnaireAction>(initialState)
	.handleAction(types.FETCH_QUESTIONNAIRES_BY_PROPS_REQUEST as any, (state, action) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(types.FETCH_QUESTIONNAIRES_BY_PROPS_SUCCESS as any, (state, action) => {
		const mappedData: QuestionnaireByPropsState['mappedData'] = {};
		const { data } = action.payload;
		(data as QuestionnaireByPropsState['data']).forEach((eachData) => {
			const { questionnaireIndexID } = eachData;
			mappedData[questionnaireIndexID] = eachData;
		});
		return {
			...state,
			data: data,
			mappedData,
			isLoading: false,
		};
	})
	.handleAction(types.FETCH_QUESTIONNAIRES_BY_PROPS_FAILURE as any, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}));

export { questionnaireByPropsReducer, initialState };
