import { SyllabusPaginateResponse } from '@nimbly-technologies/nimbly-common';
import { SortDirection } from 'reducers/lms/userManagement/userList/type';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './syllabuses.actionTypes';
import { SyllabusFilters, SyllabusSortFields } from './type';
import { ConfirmModalProps } from 'components/global/ConfirmationModal/ConfirmModal';

export const setSyllabusesPageIndex = (pageIndex: number) => action(types.SET_SYLLABUSES_PAGE_INDEX, { pageIndex });

export const setSyllabusesSearchQuery = (searchQuery: string) =>
	action(types.SET_SYLLABUSES_SEARCH_QUERY, { searchQuery });

export const setSyllabusesSort = (sortField: SyllabusSortFields, sortDirection: SortDirection) =>
	action(types.SET_SYLLABUSES_SORT, { sortField, sortDirection });

export const setSyllabusesFilters = (filters: Partial<SyllabusFilters>) =>
	action(types.SET_SYLLABUSES_FILTER_QUERY, { filters });

export const resetSyllabusesFilters = () => action(types.RESET_SYLLABUSES_FILTER_QUERY);

export const setConfirmationModalConfig = (modalConfig: Partial<ConfirmModalProps>) =>
	action(types.SET_CONFIRMATION_MODAL_CONFIG, { modalConfig });
export const toggleConfirmationModal = (isOpen: boolean) => action(types.TOGGLE_CONFIRMATION_MODAL, { isOpen });

export const fetchSyllabuses = createAsyncAction(
	types.FETCH_SYLLABUSES_REQUEST,
	types.FETCH_SYLLABUSES_SUCCESS,
	types.FETCH_SYLLABUSES_FAILED,
)<
	undefined,
	{
		syllabuses: SyllabusPaginateResponse[];
		totalDocs?: number;
		page?: number;
		totalPages?: number;
	},
	{ error: string }
>();
