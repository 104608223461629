import { ActionType, createReducer } from 'typesafe-actions';
import { LmsUsersCompactFetchState, LmsUsersCompactState } from './type.d';
import update from 'immutability-helper';
import * as actions from './lmsUsersCompact.action';
import * as types from './lmsUsersCompact.actionTypes';

export type lmsUsersCompactAction = ActionType<typeof actions>;

export const initialState: LmsUsersCompactState = {
	error: '',
	fetchState: LmsUsersCompactFetchState.SUCCESS,
	lmsUsersCompact: [],
};

export const lmsUsersCompactReducer = createReducer<LmsUsersCompactState, lmsUsersCompactAction>(initialState)
	.handleType(types.FETCH_USERS_COMPACT_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: LmsUsersCompactFetchState.LOADING },
			lmsUsersCompact: { $set: initialState.lmsUsersCompact },
		}),
	)
	.handleType(types.FETCH_USERS_COMPACT_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: LmsUsersCompactFetchState.SUCCESS },
			lmsUsersCompact: { $set: action.payload.lmsUsersCompact },
		}),
	)
	.handleType(types.FETCH_USERS_COMPACT_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: LmsUsersCompactFetchState.ERROR },
			lmsUsersCompact: { $set: initialState.lmsUsersCompact },
		}),
	);
