import * as Common from 'nimbly-common';
import { FetchRankByCompletionPayload, RankByCompletion } from 'reducers/dashboard/sites/rankByCompletion/types';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

import site1_reportsCompleted from '../../../assets/dummy/json/site1-reportsCompleted.json';
import site2_reportsCompleted from '../../../assets/dummy/json/site2-reportsCompleted.json';
import site3_reportsCompleted from '../../../assets/dummy/json/site3-reportsCompleted.json';
import site4_reportsCompleted from '../../../assets/dummy/json/site4-reportsCompleted.json';
import site5_reportsCompleted from '../../../assets/dummy/json/site5-reportsCompleted.json';

const sites_reportsCompleted = [
  site1_reportsCompleted,
  site2_reportsCompleted,
  site3_reportsCompleted,
  site4_reportsCompleted,
  site5_reportsCompleted
];

const sitesName = ['PIK', 'Kota Kasablanka', 'Pondok Indah Mall', 'Mall of Indonesia', 'Pacific Place'];
const sitesCity = ['Jakarta Selatan', 'Jakarta Pusat', 'Jakarta Selatan', 'Jakarta Utara', 'Jakarta Utara'];
const sitesOwners = ['Herby Herado', 'Restu Utomo', 'Samuel Sandro', 'Arief Gusti', 'Nikita Wong'];
const sitesTeam = ['Samuel Sandro', 'Krisnata Panji', 'Komang Mahendra', 'Tiffany Eunike', 'Suci Fransiska'];

const dummyRankByCompletion_31D: RankByCompletion[] = new Array(31).fill('with_date').map((_, dateIndex) => {
  const rankByFlags: RankByCompletion = sitesName.map((siteName, siteIndex) => {
    const totalCompleted = sites_reportsCompleted[siteIndex][dateIndex];
    const totalScheduled = totalCompleted + (totalCompleted % 5);
    const totalAdhocDone = dateIndex % 2 === 0 ? Math.floor(totalCompleted / 2) : Math.ceil(totalCompleted / 3);

    return {
      siteName,
      city: sitesCity[siteIndex],
      siteID: `site_${siteIndex + 1}`,
      owners: [
        {
          userName: sitesOwners[siteIndex],
          departmentID: '',
          departmentName: '',
          userID: ''
        }
      ],
      team: [
        {
          userName: sitesTeam[siteIndex],
          departmentID: '',
          departmentName: '',
          userID: ''
        }
      ],
      totalCompleted,
      totalScheduled,
      totalAdhocDone,
      totalPending: 0,
      totalMissed: 0
    };
  });
  return rankByFlags;
});

export const getDummyRankByCompletion = (payload: FetchRankByCompletionPayload) => {
  const selectedSites = payload.sites;

  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let dummyRankByCompletion: RankByCompletion[] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    dummyRankByCompletion = dummyRankByCompletion_31D.slice(-diffInDay);
  } else {
    dummyRankByCompletion = dummyRankByCompletion_31D.slice(0, diffInDay);
  }

  // gets cumulative site statistics based on diffInDay
  const reduced_dummyRankByCompletion: RankByCompletion = dummyRankByCompletion.reduce(
    (curr: Common.statistic.SiteCompletionRank[], acc: Common.statistic.SiteCompletionRank[]) =>
      curr.map((_: Common.statistic.SiteCompletionRank, index: number) => ({
        ...curr[index],
        totalCompleted: curr[index].totalCompleted + acc[index].totalCompleted,
        totalScheduled: curr[index].totalScheduled + acc[index].totalScheduled,
        totalAdhocDone: curr[index].totalAdhocDone + acc[index].totalAdhocDone,
        totalMissed: 0,
        totalPending: 0
      }))
  );

  if (selectedSites !== '_all_') {
    return reduced_dummyRankByCompletion.filter(siteRank => siteRank.siteName === selectedSites);
  }

  return reduced_dummyRankByCompletion;
};
