import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducers';

// ui components
import styled from 'styled-components/macro';

// related components
import Auth from '../components/landing/Auth/Auth';
import Slider from '../components/landing/Slider/Slider';

type SliderProps = ReturnType<typeof mapStateToProps>;

const LoginPage = (props: SliderProps) => {
	const { authenticatingStatus } = props;
	return (
		<ContainerLogin>
			<div className="logo-mobile">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/sustainnovation-audit.appspot.com/o/assets%2Flogo%2FNimbly%20Logo.svg?alt=media&token=b04baf81-9fe6-4527-9c77-94b3a71fe454"
					alt="nimbly"
				/>
			</div>
			<div className="auth-section">
				<Suspense fallback={<div />}>
					<Auth />
				</Suspense>
			</div>
			<div className="slider">
				<div className="container">
					<Suspense fallback={<div />}>
						<Slider authenticatingStatus={authenticatingStatus} />
					</Suspense>
				</div>
			</div>
		</ContainerLogin>
	);
};
const mapStateToProps = (state: RootState) => ({
	authenticatingStatus: state.userEnrollment.authenticatingStatus,
});

const ContainerLogin = styled.div`
	display: flex;
	overflow-y: scroll;
	height: 130vh;

	@media (min-width: 359px) {
		height: 100vh;
	}

	.auth-section {
		width: 520px;

		@media (min-width: 768px) {
			width: 100%;
		}

		@media (min-width: 992px) {
			width: 520px;
		}
	}

	.logo-mobile {
		display: block;
		position: fixed;
		width: 100%;
		padding: 20px;
		background: linear-gradient(12.82deg, #34cb65 29.25%, #34cb65 29.25%, #5048b3 91.65%);
		> img {
			filter: brightness(255);
		}

		@media (min-width: 992px) {
			display: none;
		}
	}

	.slider {
		background: linear-gradient(246.74deg, #34cb65 32.18%, #5048b3 98.5%);
		box-sizing: border-box;
		width: calc(100% - 520px);
		display: none;
		justify-content: center;
		align-items: center;

		@media (min-width: 769px) {
			display: none;
		}
		@media (min-width: 992px) {
			display: flex;
		}
	}
`;

export default connect(mapStateToProps, null)(LoginPage);
