import * as Common from 'nimbly-common';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { UserAccess } from 'components/permissions/typings';
import { UserAccessState } from 'reducers/useraccess';

import getNestedObject from 'utils/getNestedObject';
import { apiURL } from 'config/baseURL';
import { getToken } from 'reducers/api';
import API from 'helpers/api';
import { Resource } from 'nimbly-common';

type FetchOptions = {
  method: string;
  headers: {
    'Content-Type': string;
    authorization: string;
  };
  body?: string;
};
export const setUserAccessPermissions = async (targetAccess: Resource, currUserAccessState: UserAccessState) => {
  const token = await getToken();
  const clonedUserAccessState = cloneDeep(currUserAccessState) as UserAccessState;
  const options: FetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    }
  };
  const res = await fetch(`${apiURL}/user-roles/role-for-user/`, options);

  if (res.status !== 200) {
    return Promise.resolve();
  }

  const result = await res.json();
  if (!result || !result.data) {
    return;
  }

  /**
   * List of all `resources` retrieved
   */
  const resources = result.data.resources;
  const organizationID = result.data.organizationID;
  const role = result.data.role;
  const level = result.data.level;

  /**
   * READ & WRITE all `permissions`
   * from `resources` retirieved from API
   * to local `redux`
   */
  resources.forEach((access: Resource) => {
    const resultPermissions = access.permission;
    const resourcePath = access.resource.split(':');
    const permissions = getNestedObject(clonedUserAccessState, resourcePath) as UserAccess;
    const clonedPermissions = cloneDeep(permissions);

    resultPermissions.forEach((permission: Common.enums.Permission) => {
      if (clonedPermissions && clonedPermissions.permissions) {
        clonedPermissions.permissions[permission] = true;
      }
    });

    set(clonedUserAccessState, resourcePath.join('.'), clonedPermissions);
  });

  /**
   * Current target view permissions
   * to be used immediately,
   * without having to read from `redux`
   */
  const targetPermission = getNestedObject(clonedUserAccessState, targetAccess.resource.split(':')) as UserAccess;

  set(clonedUserAccessState, 'organizationID', organizationID);
  set(clonedUserAccessState, 'role', role);
  set(clonedUserAccessState, 'level', level);
  return {
    isViewAllowed: targetPermission.permissions.view,
    updatedAccessState: clonedUserAccessState
  };
};
let abortController = new AbortController();
export async function isAccessGranted(resource: string, permission: Common.enums.Permission): Promise<any> {
  abortController.abort(); // Cancel the previous request
  abortController = new AbortController();
  const token = await API.getFirebaseToken();

  const url = `${apiURL}/user-roles/permissions/${resource}`;
  const res = await API.getJSONCancellation(url, token, abortController.signal);

  const permissions: Common.enums.Permission[] = res?.data;
  if (!permissions) {
    return false;
  }

  return !!permissions.find(f => f === permission);
}
