import { ActionType, createReducer } from 'typesafe-actions';
import { ICreateCourse } from './type';
import * as actions from './createCourse.action';
import * as types from './createCourse.actionTypes';

export type CreateCourseAction = ActionType<typeof actions>;

export const initialState: ICreateCourse = {
	data: null,
	isLoading: false,
	isSuccess: false,
	errorMessage: '',
};

const createCourseReducer = createReducer<ICreateCourse, CreateCourseAction>(initialState)
	.handleAction(actions.createCourse.request, (state) => ({
		...state,
		errorMessage: '',
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.createCourse.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.createCourse.failure, (state, action) => ({
		...state,
		errorMessage: action.payload.error,
		isLoading: false,
		isSuccess: false,
	}));

export { createCourseReducer };
