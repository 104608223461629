import { CreateSitePayload, UpsertSiteResponse } from 'reducers/admin/manage/sites/types';
import { getToken } from 'reducers/api';
import { appengineURL } from '../../../../config/baseURL';
import Monitoring from '../../../../utils/monitoring/Monitoring';
import { APIResponseMessageEnum } from 'constants/ResponseCollection';

export const hasErrorMessage = (message: string) => message !== APIResponseMessageEnum.FAILED;
export const createSite = async (site: CreateSitePayload): Promise<UpsertSiteResponse> => {
	try {
		const token = await getToken();
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: token,
			},
			body: JSON.stringify(site),
		};
		const url = `${appengineURL}/sites/`;

		const res = await fetch(url, options);
		const createSiteResult: UpsertSiteResponse = await res.json();

		// THROW IF RESPONSE NOT OK
		if (res.status !== 200) {
			throw new Error(hasErrorMessage(createSiteResult.message) ? createSiteResult.message : createSiteResult.data);
		}

		return createSiteResult;
	} catch (e) {
		Monitoring.logEvent('createSite', e);
		const errorResult: UpsertSiteResponse = {
			message: 'FAILED',
			error: e.message,
		};
		return errorResult;
	}
};
