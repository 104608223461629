import { call, put, takeLatest } from 'redux-saga/effects';

import {
	fetchFlagsChart,
	fetchOverviewStat,
	fetchQuestionnaireStat,
	fetchFlagsOverview,
	fetchIssuesFlagStat,
	downloadIssuesFlagStat,
	DownloadIssuesFlagStatResponse,
	DownloadQuestionnaireStatResponse,
	downloadQuestionnaireStat,
	fetchIssuesResolutionRate,
} from './helpers/issues';
import { FETCH_ISSUES_FLAGS_CHART_REQUEST, FetchFlagsChartResponse } from 'reducers/dashboard/issues/flagsChart/types';
import {
	FETCH_ISSUES_FLAGS_OVERVIEW_REQUEST,
	FetchFlagsOverviewResponse,
} from 'reducers/dashboard/issues/flagsOverview/types';
import { FETCH_OVERVIEW_STAT_REQUEST, FetchOverviewStatResponse } from 'reducers/dashboard/issues/overviewStat/types';
import {
	FETCH_QUESTIONNAIRE_STAT_REQUEST,
	DOWNLOAD_QUESTIONNAIRE_STAT_REQUEST,
	FetchQuestionnaireStatResponse,
} from 'reducers/dashboard/issues/questionnaireStat/types';
import {
	FETCH_ISSUES_FLAG_STAT_REQUEST,
	DOWNLOAD_ISSUES_FLAG_STAT_REQUEST,
	FetchIssuesFlagStatResponse,
} from 'reducers/dashboard/issues/issuesFlagStat/types';
import { fetchIssuesFlagsChartAsync } from 'reducers/dashboard/issues/flagsChart/action';
import { fetchOverviewStatAsync } from 'reducers/dashboard/issues/overviewStat/action';
import {
	fetchQuestionnaireStatAsync,
	downloadQuestionnaireStatAsync,
} from 'reducers/dashboard/issues/questionnaireStat/action';
import { fetchIssuesFlagsOverviewAsync } from 'reducers/dashboard/issues/flagsOverview/action';
import { fetchIssuesFlagStatAsync, downloadIssuesFlagStatAsync } from 'reducers/dashboard/issues/issuesFlagStat/action';
import { fetchIssuesResolutionRateAsync } from 'reducers/dashboard/issues/issuesResolutionRate/action';
import { FETCH_ISSUES_RESOLUTION_RATE_REQUEST } from 'reducers/dashboard/issues/issuesResolutionRate/types';
import { IssueResolutionRate } from '@nimbly-technologies/nimbly-common';

export function* fetchFlagsChartSaga(action: ReturnType<typeof fetchIssuesFlagsChartAsync.request>): Generator {
	try {
		const fetchRes: FetchFlagsChartResponse = (yield call(fetchFlagsChart, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchIssuesFlagsChartAsync.success(fetchRes.data));
		} else {
			yield put(fetchIssuesFlagsChartAsync.failure('FETCH FLAGS CHART FAILED'));
		}
	} catch (e) {
		yield put(fetchIssuesFlagsChartAsync.failure('FETCH FLAGS CHART FAILED'));
	}
}

export function* fetchFlagsOverviewSaga(action: ReturnType<typeof fetchIssuesFlagsOverviewAsync.request>): Generator {
	try {
		const fetchRes: FetchFlagsOverviewResponse = (yield call(fetchFlagsOverview, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchIssuesFlagsOverviewAsync.success(fetchRes.data));
		} else {
			yield put(fetchIssuesFlagsOverviewAsync.failure('FETCH FLAGS OVERVIEW FAILED'));
		}
	} catch (e) {
		yield put(fetchIssuesFlagsOverviewAsync.failure('FETCH FLAGS OVERVIEW FAILED'));
	}
}

export function* fetchOverviewStatSaga(action: ReturnType<typeof fetchOverviewStatAsync.request>): Generator {
	try {
		const fetchRes: FetchOverviewStatResponse = (yield call(fetchOverviewStat, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchOverviewStatAsync.success(fetchRes.data));
		} else {
			yield put(fetchOverviewStatAsync.failure('FETCH OVERVIEW STAT FAILED'));
		}
	} catch (e) {
		yield put(fetchOverviewStatAsync.failure('FETCH OVERVIEW STAT FAILED'));
	}
}

export function* fetchQuestionnaireStatSaga(action: ReturnType<typeof fetchQuestionnaireStatAsync.request>): Generator {
	try {
		const fetchRes: FetchQuestionnaireStatResponse = (yield call(fetchQuestionnaireStat, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchQuestionnaireStatAsync.success(fetchRes.data));
		} else {
			yield put(fetchQuestionnaireStatAsync.failure('FETCH QUESTIONNAIRE STAT FAILED'));
		}
	} catch (e) {
		yield put(fetchQuestionnaireStatAsync.failure('FETCH QUESTIONNAIRE STAT FAILED'));
	}
}

export function* fetchIssuesFlagStatSaga(action: ReturnType<typeof fetchIssuesFlagStatAsync.request>): Generator {
	try {
		const fetchRes: FetchIssuesFlagStatResponse = (yield call(fetchIssuesFlagStat, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchIssuesFlagStatAsync.success(fetchRes.data));
		} else {
			yield put(fetchIssuesFlagStatAsync.failure('FETCH ISSUES FLAG STAT FAILED'));
		}
	} catch (e) {
		yield put(fetchIssuesFlagStatAsync.failure('FETCH ISSUES FLAG STAT FAILED'));
	}
}

export function* downloadIssuesFlagStatSaga(action: ReturnType<typeof downloadIssuesFlagStatAsync.request>): Generator {
	try {
		const fetchRes: DownloadIssuesFlagStatResponse = (yield call(downloadIssuesFlagStat, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(downloadIssuesFlagStatAsync.success(fetchRes.url));
		} else {
			yield put(downloadIssuesFlagStatAsync.failure('DOWNLOAD ISSUES FLAG STAT FAILED'));
		}
	} catch (e) {
		yield put(downloadIssuesFlagStatAsync.failure('DOWNLOAD ISSUES FLAG STAT FAILED'));
	}
}

export function* downloadQuestionnaireStatSaga(
	action: ReturnType<typeof downloadQuestionnaireStatAsync.request>,
): Generator {
	try {
		const fetchRes: DownloadQuestionnaireStatResponse = (yield call(downloadQuestionnaireStat, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(downloadQuestionnaireStatAsync.success(fetchRes.url));
		} else {
			yield put(downloadQuestionnaireStatAsync.failure('DOWNLOAD QUESTIONNAIRE STAT FAILED'));
		}
	} catch (e) {
		yield put(downloadQuestionnaireStatAsync.failure('DOWNLOAD QUESTIONNAIRE STAT FAILED'));
	}
}

export function* fetchIssuesResolutionRateSaga(
	action: ReturnType<typeof fetchIssuesResolutionRateAsync.request>,
): Generator {
	try {
		const data: IssueResolutionRate = (yield call(fetchIssuesResolutionRate, action.payload)) as any;
		yield put(fetchIssuesResolutionRateAsync.success(data));
	} catch (error) {
		yield put(fetchIssuesResolutionRateAsync.failure(error.message));
	}
}

export default function* sitesSagaWatcher() {
	yield takeLatest(FETCH_ISSUES_FLAGS_CHART_REQUEST, fetchFlagsChartSaga);
	yield takeLatest(FETCH_OVERVIEW_STAT_REQUEST, fetchOverviewStatSaga);
	yield takeLatest(FETCH_QUESTIONNAIRE_STAT_REQUEST, fetchQuestionnaireStatSaga);
	yield takeLatest(FETCH_ISSUES_FLAGS_OVERVIEW_REQUEST, fetchFlagsOverviewSaga);
	yield takeLatest(FETCH_ISSUES_FLAG_STAT_REQUEST, fetchIssuesFlagStatSaga);
	yield takeLatest(DOWNLOAD_ISSUES_FLAG_STAT_REQUEST, downloadIssuesFlagStatSaga);
	yield takeLatest(DOWNLOAD_QUESTIONNAIRE_STAT_REQUEST, downloadQuestionnaireStatSaga);
	yield takeLatest(FETCH_ISSUES_RESOLUTION_RATE_REQUEST, fetchIssuesResolutionRateSaga);
}
