import { getToken } from 'reducers/api';
import { apiURL } from '../../../../config/baseURL';
import { CreateAuditorRequest } from 'nimbly-common';
import { UpsertUserResponse } from 'reducers/admin/manage/users/types';
import { APIResponse } from 'reducers/admin/fetch-types/manage-user-fetch.types';
import Monitoring from '../../../../utils/monitoring/Monitoring';

export const createUser = async (user: CreateAuditorRequest): Promise<UpsertUserResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify(user)
    };
    const url = `${apiURL}/users/`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      const error: APIResponse = await res.json();
      throw new Error(error.message);
    }

    const response: APIResponse<string> = await res.json();
    const createUserResult: UpsertUserResponse = {
      message: 'SUCCESS',
      data: response.data || ''
    };

    return createUserResult;
  } catch (error) {
    Monitoring.logEvent('createUser', error);

    const rawErrorMessage = error.message;
    let errorMessage: string;

    if (rawErrorMessage.includes('$')) {
      const markedMessage: string = rawErrorMessage.replace(/[^A-Za-z0-9 ]/gi, '$');

      const splittedMarkedMessage = markedMessage.split('$');
      const errorProps = splittedMarkedMessage[2] || 'Some field(s)';
      const tailMessage = splittedMarkedMessage[splittedMarkedMessage.length - 1];
      errorMessage = errorProps + tailMessage;
    } else {
      errorMessage = rawErrorMessage;
    }
    const errorResult: UpsertUserResponse = {
      message: 'FAILED',
      error: errorMessage
    };

    return errorResult;
  }
};
