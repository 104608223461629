import * as Common from 'nimbly-common';
import { RoleLabel } from '../typings';

// function for sorting user role account-holder, superadmin, etc
export const sortUserRoles = (userRoles: RoleLabel[]): RoleLabel[] => {
  return userRoles.sort((a, b) => {
    let aVal = a.value;
    let bVal = b.value;
    let aCustom = false;
    let bCustom = false;

    if (a.origin) {
      aVal = a.origin;
      aCustom = true;
    }

    if (b.origin) {
      bVal = b.origin;
      bCustom = true;
    }

    const aRank = Common.enums.RoleOrder.indexOf(aVal as any);
    const bRank = Common.enums.RoleOrder.indexOf(bVal as any);

    // if by chance any custom role has no origin push to end
    if (aRank === -1) return 1;

    if (aRank > bRank) return 1;
    if (aRank < bRank) return -1;
    if (aRank === bRank) {
      if (aCustom && !bCustom) return 1;
      // TODO: may need further sort based on alphabetical order of the same rank
      if (!aCustom && !bCustom) return 0;
      return 0;
    }
    return 0;
  });
};
