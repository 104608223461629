import { call, put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_ALL_ORG_REQUEST,
  FetchAllOrgResponse,
  CREATE_MASTER_PASSWORD_REQUEST,
  CreateMasterPasswordResponse
} from 'reducers/superadmin/clientLogin/masterPassword/types';
import {
  LOGIN_AS_CLIENT_REQUEST,
  LoginAsClientResponse,
  VERIFY_AUTH_CODE_REQUEST,
  VerifyAuthCodeResponse
} from 'reducers/superadmin/clientLogin/loginAccess/types';

import { createOrgMasterPasswordAsync, fetchAllOrgAsync } from 'reducers/superadmin/clientLogin/masterPassword/action';
import { loginAsClientAsync, verifyAuthCodeAsync } from 'reducers/superadmin/clientLogin/loginAccess/action';

import { fetchAllOrg, createOrgMasterPassword, loginAsClient, verifyAuthCode } from './helpers/clientLogin';

export function* fetchAllOrgSaga(): Generator {
  try {
    const fetchRes: FetchAllOrgResponse = (yield call(fetchAllOrg)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchAllOrgAsync.success(fetchRes.data));
    } else {
      yield put(fetchAllOrgAsync.failure('CLIENT LOGIN - CREATE ORG MASTER PASSWORD FAILED'));
    }
  } catch (e) {
    yield put(fetchAllOrgAsync.failure('CLIENT LOGIN - CREATE ORG MASTER PASSWORD FAILED'));
  }
}

export function* createOrgMasterPasswordSaga(
  action: ReturnType<typeof createOrgMasterPasswordAsync.request>
): Generator {
  try {
    const fetchRes: CreateMasterPasswordResponse = (yield call(createOrgMasterPassword, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(createOrgMasterPasswordAsync.success());
    } else {
      yield put(createOrgMasterPasswordAsync.failure('CLIENT LOGIN - CREATE ORG MASTER PASSWORD FAILED'));
    }
  } catch (e) {
    yield put(createOrgMasterPasswordAsync.failure('CLIENT LOGIN - CREATE ORG MASTER PASSWORD FAILED'));
  }
}

export function* loginAsClientSaga(action: ReturnType<typeof loginAsClientAsync.request>): Generator {
  try {
    const fetchRes: LoginAsClientResponse = (yield call(loginAsClient, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(loginAsClientAsync.success());
    } else {
      yield put(loginAsClientAsync.failure('CLIENT LOGIN - LOGIN ORG MASTER PASSWORD FAILED'));
    }
  } catch (e) {
    yield put(loginAsClientAsync.failure('CLIENT LOGIN - LOGIN ORG MASTER PASSWORD FAILED'));
  }
}

export function* verifyAuthCodeSaga(action: ReturnType<typeof verifyAuthCodeAsync.request>): Generator {
  try {
    const fetchRes: VerifyAuthCodeResponse = (yield call(verifyAuthCode, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(verifyAuthCodeAsync.success(fetchRes.data));
    } else {
      yield put(verifyAuthCodeAsync.failure('CLIENT LOGIN - VERIFY AUTH CODE FAILED'));
    }
  } catch (e) {
    yield put(verifyAuthCodeAsync.failure('CLIENT LOGIN - VERIFY AUTH CODE FAILED'));
  }
}

export default function* superadminClientLoginSagaWatcher() {
  yield takeLatest(FETCH_ALL_ORG_REQUEST, fetchAllOrgSaga);
  yield takeLatest(CREATE_MASTER_PASSWORD_REQUEST, createOrgMasterPasswordSaga);
  yield takeLatest(LOGIN_AS_CLIENT_REQUEST, loginAsClientSaga);
  yield takeLatest(VERIFY_AUTH_CODE_REQUEST, verifyAuthCodeSaga);
}
