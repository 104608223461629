import {
  IssuesQuestionnaireStatisticResponse,
  DashboardIssuesPayload
} from 'reducers/dashboard/fetch-types/issues-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesQuestionnaireStat = IssuesQuestionnaireStatisticResponse['data'];

export interface QuestionnaireStatState {
  readonly questionnaireStat_loading: boolean;
  readonly questionnaireStat_data: IssuesQuestionnaireStat;
  readonly questionnaireStat_error: string;
  readonly questionnaireStat_dl_loading: boolean;
  readonly questionnaireStat_dl_url: string;
  readonly questionnaireStat_dl_error: string;
}
type DownloadPayload = {
  dl: 1;
};

export const FETCH_QUESTIONNAIRE_STAT_REQUEST = '@@dashboard/issues/FETCH_QUESTIONNAIRE_STAT_REQUEST';
export const FETCH_QUESTIONNAIRE_STAT_SUCCESS = '@@dashboard/issues/FETCH_QUESTIONNAIRE_STAT_SUCCESS';
export const FETCH_QUESTIONNAIRE_STAT_FAILURE = '@@dashboard/issues/FETCH_QUESTIONNAIRE_STAT_FAILURE';

export type FetchQuestionnaireStatPayload = DashboardIssuesPayload;
export type FetchQuestionnaireStatResponse = IssuesQuestionnaireStatisticResponse | ErrorResponse;

export const DOWNLOAD_QUESTIONNAIRE_STAT_REQUEST = '@@dashboard/issues/DOWNLOAD_QUESTIONNAIRE_STAT_REQUEST';
export const DOWNLOAD_QUESTIONNAIRE_STAT_SUCCESS = '@@dashboard/issues/DOWNLOAD_QUESTIONNAIRE_STAT_SUCCESS';
export const DOWNLOAD_QUESTIONNAIRE_STAT_FAILURE = '@@dashboard/issues/DOWNLOAD_QUESTIONNAIRE_STAT_FAILURE';

export type DownloadQuestionnaireStatPayload = DashboardIssuesPayload & DownloadPayload;
