import { CheckSiteScheduleCountPayload, CheckSiteScheduleCountResponse } from 'reducers/admin/manage/sites/types';
import { getToken } from 'reducers/api';
import { apiURL } from 'config/baseURL';
import Monitoring from '../../../../../utils/monitoring/Monitoring';

export const checkSiteSchedule = async (
  siteID: CheckSiteScheduleCountPayload
): Promise<CheckSiteScheduleCountResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const url = `${apiURL}/schedules/sites/${siteID}`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const siteSchedulesResult: CheckSiteScheduleCountResponse = await res.json();
    return siteSchedulesResult;
  } catch (e) {
    Monitoring.logEvent('blockSite', e);
    const errorResult: CheckSiteScheduleCountResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
