export enum SiteHierarchyFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface SiteHierarchyDepartment {
	departmentID: string;
	name: string;
	users: { userID: string; name: string }[];
}

export interface SiteHierarchyCompact {
	siteID: string;
	name: string;
	departments: SiteHierarchyDepartment[];
}

export interface SiteHierarchyState {
	error: string;
	fetchState: SiteHierarchyFetchState;
	siteHierarchy: SiteHierarchyCompact[];
}
