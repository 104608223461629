import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import adminVersion from '../../../package.json';
import { sentryConfig } from '../../config/sentryKey';
import { createBrowserHistory } from 'history';

const sentrySetup = () => {
	const envSentry = process.env.NODE_ENV === 'development' ? 'staging' : 'prod';
	const history = createBrowserHistory();
	Sentry.init({
		dsn: sentryConfig.dsn,
		environment: envSentry,
		release: adminVersion?.version || 'no-version',
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
			}),
		],
		tracesSampleRate: envSentry === 'prod' ? 0.2 : 1.0,
		maxBreadcrumbs: envSentry === 'prod' ? 50 : 100,
		debug: envSentry !== 'prod',
		attachStacktrace: true,
		autoSessionTracking: true,
		normalizeDepth: 3,
	});
};

export { sentrySetup };
