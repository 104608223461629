import { PaginationResult } from '@nimbly-technologies/nimbly-common';
import _ from 'lodash';
import { getPaginatedResponseData } from './getPaginatedResponseData';
import { getPaginationNextPage } from './getPaginationNextPage';

export async function fetchLmsCoursesSyllabuses<
	FetchParams extends {
		[x: string]: any;
		page?: number;
		totalPages?: number;
		limit?: number;
	},
	FetchResponse extends PaginationResult<Data>,
	Data extends {}
>(args: {
	loadMore: boolean | undefined;
	nextPage?: number;
	extraQuery?: Record<string, any>;
	prevQuery: FetchParams;
	prevData: Data[];
	fetchData: (args: { page: number; [key: string]: any }) => Promise<unknown>;
	currentQuery?: FetchParams;
}) {
	const { fetchData, loadMore, prevQuery, prevData, currentQuery, extraQuery, nextPage } = args;
	const { page, totalPages } = prevQuery;

	const query = {
		...prevQuery,
		...currentQuery,
	};
	const queryParams: Pick<FetchParams, 'page' | 'limit'> = _.pick(query, ['page', 'limit']);

	const res = (await fetchData({
		...queryParams,
		...extraQuery,
		page: nextPage ?? getPaginationNextPage(page, totalPages, loadMore),
	})) as FetchResponse;

	const data = getPaginatedResponseData<Data>(loadMore, prevData, res);

	return data;
}
