import { FetchFlagsChartPayload, SitesFlagsChart } from 'reducers/dashboard/sites/flagsChart/types';
import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

import greenFlags from '../../../assets/dummy/json/flag-green.json';
import yellowFlags from '../../../assets/dummy/json/flag-yellow.json';
import redFlags from '../../../assets/dummy/json/flag-red.json';

const dummyFlagsChart_SCHEDULED_31D: Common.statistic.QuestionFlagDaily[] = new Array(31)
  .fill('with_date')
  .map((_, dateIndex) => {
    const date = dateIndex < 10 ? `2020-03-0${dateIndex}` : `2020-03-${dateIndex}`;
    return {
      date,
      green: greenFlags[dateIndex],
      yellow: yellowFlags[dateIndex],
      red: redFlags[dateIndex],
      total: greenFlags[dateIndex] + yellowFlags[dateIndex] + redFlags[dateIndex]
    };
  });

/**
 * Also used for Issues `FlagsChart` DUMMY
 */
export const getDummyFlagsChart = (payload: FetchFlagsChartPayload) => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let dummyFlagsChart_SCHEDULED: Common.statistic.QuestionFlagDaily[] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    dummyFlagsChart_SCHEDULED = dummyFlagsChart_SCHEDULED_31D.slice(-diffInDay);
  } else {
    dummyFlagsChart_SCHEDULED = dummyFlagsChart_SCHEDULED_31D.slice(0, diffInDay);
  }

  const dummyFlagsChart: SitesFlagsChart = {
    adhoc: [],
    scheduled: dummyFlagsChart_SCHEDULED
  };
  return dummyFlagsChart;
};
