import { SyllabusPaginateResponse } from '@nimbly-technologies/nimbly-common';
import { ConfirmModalProps } from 'components/global/ConfirmationModal/ConfirmModal';

export enum SyllabusesFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface SyllabusFilters {
	userIDs: string[];
	startDate: string;
	endDate: string;
}

export type SyllabusSortFields = 'name' | 'createdDate' | 'createdBy' | 'participants';

export interface SyllabusesState {
	error: string;
	fetchState: SyllabusesFetchState;
	syllabuses: SyllabusPaginateResponse[];

	totalPages: number;
	totalDocs: number;
	page: number;
	limit: number;

	sortField: SyllabusSortFields;
	sortDirection: 'asc' | 'desc';
	search: string;
	filters: SyllabusFilters;

	confirmationModalConfig: ConfirmModalProps;
	isConfirmationModalOpen: boolean;
}
