import { store } from 'store/configStore';
import queryString from 'query-string';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { GetCompleteReportDetail } from '@nimbly-technologies/nimbly-common';
import {
	GroupByEnum,
	DrillByEnum,
	DashboardPeriodEnum,
	DownloadDashboardFileType,
} from '@nimbly-technologies/nimbly-common/lib/enumerators';
import {
	NodeData,
	IssueReportTabs,
	TrendsIssueData,
	TrendsReportData,
	DashboardDataType,
} from 'pages/dashboardRevamp/dashboardUtils';
import { OperationalFilters } from 'reducers/dashboardRevamp/operational/operationalStore';
import { isEmptyValue } from 'pages/dashboardRevamp/utils/isEmptyValue';
import { getRequestOptions } from '../issueInsights/issueInsights.service';
import { filterObjectWithEmptyValues } from 'pages/dashboardRevamp/utils/filterObjectWithEmptyValues';
import { cloneDeep } from 'lodash';

export const buildQueryParams = () => {
	const {
		limit,
		filters,
		sortDirection,
		metric,
		viewBy,
		groupBy,
		sortBy,
		period,
	} = store.getState().dashboardOperational;
	// Don't include ManageBy filters
	const globalFilters = cloneDeep(filters);
	delete globalFilters.managedByDeptIDs;
	delete globalFilters.managedByUserIDs;
	delete globalFilters.siteRegionIDs;
	const query = filterObjectWithEmptyValues({
		...globalFilters,
		metric,
		viewBy,
		groupBy,
		sortBy,
		limit,
		sortDirection,
		period,
	});
	return query;
};

const buildQueryParamsLastMonth = () => {
	const {
		limit,
		lastMonthFilters,
		sortDirection,
		metric,
		viewBy,
		groupBy,
		sortBy,
		period,
	} = store.getState().dashboardOperational;
	const query = filterObjectWithEmptyValues({
		...lastMonthFilters,
		metric,
		viewBy,
		groupBy,
		sortBy,
		limit,
		sortDirection,
		period,
	});
	return query;
};

const fetchDashboardData = async (endpoint: string): Promise<any | undefined> => {
	try {
		const queryParams = buildQueryParams();
		const url = `${apiURL}/statistics/dashboard/${endpoint}`;
		const options = await getRequestOptions(queryParams);
		const res = await fetch(url, options);
		const responseData = await res.json();

		if (responseData?.data?.issueList) {
			return responseData?.data?.issueList;
		}

		return responseData?.data as GetCompleteReportDetail;
	} catch (error) {
		return Promise.reject(error);
	}
};

const fetchLastMonthDashboardData = async (endpoint: string): Promise<any | undefined> => {
	try {
		const queryParams = buildQueryParamsLastMonth();
		const url = `${apiURL}/statistics/dashboard/${endpoint}`;
		const options = await getRequestOptions(queryParams);
		const res = await fetch(url, options);
		const responseData = await res.json();

		if (responseData?.data?.issueList) {
			return responseData?.data?.issueList;
		}

		return responseData?.data as GetCompleteReportDetail;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchReportsDetail = async (): Promise<any | undefined> => {
	try {
		return fetchDashboardData('report/detail');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchIssueOverviewDetail = async (): Promise<any | undefined> => {
	try {
		return fetchDashboardData('issue/detail');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLastMonthIssueOverviewDetail = async (): Promise<any | undefined> => {
	try {
		return fetchLastMonthDashboardData('issue/detail');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchTopList = async ({
	groupBy,
	tab,
}: {
	groupBy: GroupByEnum;
	tab: IssueReportTabs;
}): Promise<any | undefined> => {
	try {
		const { filters } = store.getState().dashboardOperational;
		const filtersQueryParams = filterObjectWithEmptyValues({
			...filters,
			groupBy,
			metric: tab === IssueReportTabs.Issues ? 'issueList' : 'reportList',
			viewBy: tab === IssueReportTabs.Issues ? 'irr' : 'rcr',
			sortBy: tab === IssueReportTabs.Issues ? 'resolutionRate' : 'rcr',
			sortDirection: 'desc',
			limit: 5,
		});

		const issueUrl = `${apiURL}/statistics/dashboard/issue`;
		const reportUrl = `${apiURL}/statistics/dashboard/report`;

		const url = tab === IssueReportTabs.Issues ? issueUrl : reportUrl;
		const options = await getRequestOptions(filtersQueryParams);
		const res = await fetch(url, options);
		const { message, data } = await res.json();

		if (message === 'SUCCESS') {
			return data?.issueList || data?.irrTimeArray || data;
		}

		return [];
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const fetchTrendsData = async ({ period, tab }: { period: DashboardPeriodEnum; tab: IssueReportTabs }) => {
	try {
		const { filters } = store.getState().dashboardOperational;
		const filtersQueryParams = filterObjectWithEmptyValues({
			...filters,
			period,
			metric: tab === IssueReportTabs.Issues ? 'issueChart' : 'reportChart',
			groupBy: 'date',
		});

		const issueUrl = `${apiURL}/statistics/dashboard/issue`;
		const reportUrl = `${apiURL}/statistics/dashboard/report`;

		const url = tab === IssueReportTabs.Issues ? issueUrl : reportUrl;
		const options = await getRequestOptions(filtersQueryParams);
		const res = await fetch(url, options);
		const { message, data } = await res.json();

		if (message === 'SUCCESS') {
			return data?.issueList || data?.irrTimeArray || data;
		}

		return [];
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const fetchReportDownloadView = async ({
	groupBy = '',
	period = '',
	fieldNames,
	tab,
}: {
	groupBy?: string | null;
	period?: string | null;
	fieldNames: string[];
	tab: IssueReportTabs;
}): Promise<any | undefined> => {
	const { filters, sortBy } = store.getState().dashboardOperational;
	const query = filterObjectWithEmptyValues({
		period,
		groupBy,
		fieldNames,
		...filters,
		sortBy: tab === IssueReportTabs.Issues ? sortBy : 'avgReportCompletionRate',
		sortDirection: 'asc',
	});
	const urlTab = tab === IssueReportTabs.Issues ? 'issue' : 'report';

	const url = `${apiURL}/statistics/dashboard/${urlTab}/download/view`;

	try {
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const { message, data } = await res.json();

		if (message === 'SUCCESS') {
			return data;
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const fetchReportDownload = async ({
	groupBy = '',
	period = '',
	fieldNames,
	tab,
	fileType,
}: {
	groupBy?: string | null;
	period?: string | null;
	fieldNames: string[];
	tab: IssueReportTabs;
	fileType: DownloadDashboardFileType;
}): Promise<any | undefined> => {
	const { filters, sortBy } = store.getState().dashboardOperational;
	const additionalFilter = `period=${period}&groupBy=${groupBy}`;

	const fieldNameParams = fieldNames.map((fieldName) => `fieldName=${fieldName}`).join('&');
	const sortByTab = tab === IssueReportTabs.Issues ? sortBy : 'rcr';
	const urlTab = tab === IssueReportTabs.Issues ? 'issue' : 'report';

	const url = `${apiURL}/statistics/dashboard/${urlTab}/download?${additionalFilter}&${fieldNameParams}&fileType=${fileType}&sortDirection=asc&sortBy=${sortByTab}&${queryString.stringify(
		filters,
	)}`;

	try {
		const { data, message } = (await API.getJSON(url)) as { data: Record<string, any>[]; message: string };
		if (message === 'SUCCESS') {
			return data;
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const fetchTopReportsList = async (): Promise<any | undefined> => {
	try {
		return fetchDashboardData('report');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchIssueChartData = async (): Promise<any | undefined> => {
	try {
		return fetchDashboardData('issue');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchReportChartData = async (): Promise<any | undefined> => {
	try {
		return fetchDashboardData('report');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchDrillIntoData = async ({
	groupBy,
	drillByFields = [],
	type,
	filters = {},
	page,
}: {
	groupBy: GroupByEnum;
	drillByFields: DrillByEnum[];
	page: number;
	type: DashboardDataType;
	filters: OperationalFilters;
}): Promise<any | undefined> => {
	if (!groupBy) {
		return [];
	}

	try {
		const query = filterObjectWithEmptyValues({
			...filters,
			metric: type === DashboardDataType.Report ? 'reportList' : 'issueList',
			viewBy: type === DashboardDataType.Report ? 'rcr' : 'irr',
			sortBy: type === DashboardDataType.Report ? 'rcr' : 'resolutionRate',
			sortDirection: 'desc',
			drillBy: drillByFields,
			groupBy,
			page,
		});
		const rcrUrl = `${apiURL}/statistics/dashboard/report`;
		const irrUrl = `${apiURL}/statistics/dashboard/issue`;

		const url = type === DashboardDataType.Report ? rcrUrl : irrUrl;
		const options = await getRequestOptions(query);
		const res = await fetch(url, options);
		const { message, data } = await res.json();

		if (message === 'SUCCESS') {
			return data;
		}

		return [];
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const fetchIssueIDList = async (): Promise<any | undefined> => {
	try {
		const url = `${apiURL}/statistics/dashboard/issue/issueIDs/`;
		const { data, message } = (await API.getJSON(url)) as { data: Record<string, any>[]; message: string };
		if (message === 'SUCCESS') {
			return data;
		}

		return [];
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const fetchSavedFilters = async (source = 'kpi'): Promise<any | undefined> => {
	try {
		const url = `${apiURL}/statistics/dashboard/dashboard-filters?source=${source}`;
		const { data = [], message } = (await API.getJSON(url)) as { data: Record<string, any>[]; message: string };
		if (message === 'SUCCESS') {
			return data;
		}
		return [];
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const fetchSaveFilters = async (source: string, payload: object): Promise<any | undefined> => {
	try {
		const url = `${apiURL}/statistics/dashboard/dashboard-filters?source=${source}`;
		const token = await API.getFirebaseToken();
		const response = await API.post(url, token, payload);
		const json = await response.json();
		if (response.status !== 200) {
			return null;
		}
		return json;
	} catch (error) {
		console.error(error);
		return [];
	}
};
