import moment from 'moment';
import update from 'immutability-helper';

import * as types from './siteReports.actionTypes';
import { SiteReportState, SiteReportAction, SiteReportSortKeys } from './siteReport.types';

const initialState: SiteReportState = {
	showVisibilityWarning: false,
	pageIndexReport: 1,
	perPageReports: [],
	totalReports: 0,
	reports: [],
	skuReports: [],
	filteredType: 'reportSchedule',
	filteredUsers: [],
	filteredQuestionnaires: [],
	filteredReports: [],
	isLoading: false,
	isFetching: false,
	sortBy: 'createdAt',
	questionnaireTitle: 'desc',
	auditorName: 'desc',
	createdAt: 'desc',
	reportID: 'desc',
	checkInAt: 'desc',
	checkOutAt: 'desc',
	timeDifference: 'desc',
	colorSort: 'desc',
	color: 2,
	scoreWeighted: 'desc',
	isAdhoc: 'desc',
	// date filters
	periodType: null,
	startDate: moment().startOf('isoWeek'),
	endDate: moment().endOf('isoWeek'),
	prevStartDate: moment().parseZone().subtract(1, 'week').startOf('isoWeek'),
	prevEndDate: moment().parseZone().subtract(1, 'week'),
	customStart: null,
	customEnd: null,
	issueIDs: [],
};

// eslint-disable-next-line complexity
export function siteReportsReducer(state = initialState, action: SiteReportAction): SiteReportState {
	switch (action.type) {
		case types.SET_SORT_TYPE:
			if (state.sortBy === action.payload.sortBy) {
				const objectKey: SiteReportSortKeys = action.payload.sortBy;
				return {
					...state,
					sortBy: action.payload.sortBy,
					[action.payload.sortBy]: state[objectKey] === 'asc' ? 'desc' : 'asc',
				};
			} else {
				return {
					...state,
					sortBy: action.payload.sortBy,
					questionnaireTitle: 'desc',
					auditorName: 'desc',
					createdAt: 'desc',
					reportID: 'desc',
					checkInAt: 'desc',
					checkOutAt: 'desc',
					timeDifference: 'desc',
					colorSort: 'desc',
					scoreWeighted: 'desc',
					isAdhoc: 'desc',
				};
			}
		case types.SET_COLOR_CHANGE:
			if (state.sortBy === 'colorSort') {
				return {
					...state,
					sortBy: 'colorSort',
					colorSort: 'desc',
					color: state.color === 2 ? 0 : state.color + 1,
				};
			} else {
				return {
					...state,
					sortBy: 'colorSort',
					colorSort: 'desc',
					color: 2,
				};
			}
		case types.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.payload.status,
			};
		case types.SET_IS_FETCHING:
			return {
				...state,
				isFetching: action.payload.status,
			};
		case types.SET_REPORTS:
			// reports will be the single source of truth
			return {
				...state,
				reports: action.payload.reports,
				showVisibilityWarning:
					action.payload.showWarning !== null ? !!action.payload.showWarning : state.showVisibilityWarning,
			};
		case types.SET_PAGE_INDEX_REPORT: {
			// change start of list and end of will every page index is changing
			const countPerPage: number = action.payload.value;
			return {
				...state,
				pageIndexReport: action.payload.value,
				perPageReports: state.filteredReports.slice((countPerPage - 1) * 15, countPerPage * 15),
			};
		}
		case types.SET_REPORT_TYPE_FILTER:
			return {
				...state,
				filteredType: action.payload.type,
				filteredQuestionnaires: [],
				pageIndexReport: 1,
			};
		case types.SET_REPORT_QUESTIONNAIRE_FILTERS:
			return {
				...state,
				filteredQuestionnaires: action.payload.questionnaires,
				pageIndexReport: 1,
			};
		case types.SET_REPORT_USER_FILTERS:
			return {
				...state,
				filteredUsers: action.payload.users,
				pageIndexReport: 1,
			};
		case types.SET_FILTERED_REPORTS:
			// set reports and total report number - show 15 items per page
			return {
				...state,
				totalReports: action.payload.reports.length,
				perPageReports: action.payload.reports.slice(0, 15),
				filteredReports: action.payload.reports,
				// pageIndexReport: 1
			};
		case types.SET_CUSTOM_DATES:
			return {
				...state,
				customStart: action.payload.startDate,
				customEnd: action.payload.endDate,
				pageIndexReport: 1,
			};
		case types.SET_DATES:
			return {
				...state,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
				pageIndexReport: 1,
			};
		case types.SET_PREVIOUS_DATES:
			return {
				...state,
				prevStartDate: action.payload.prevStartDate,
				prevEndDate: action.payload.prevEndDate,
			};
		case types.SET_PERIOD_TYPE:
			return {
				...state,
				periodType: action.payload.periodType,
				pageIndexReport: 1,
			};
		case types.FETCH_SITE_REPORTS_REQUEST:
			return update(state, {
				isFetching: { $set: true },
				isLoading: { $set: true },
			});
		case types.FETCH_SITE_REPORTS_SUCCESS:
			return update(state, {
				isFetching: { $set: false },
				skuReports: { $set: action.payload.skuReports },
				reports: { $set: action.payload.reports },
				isLoading: { $set: false },
				totalReports: { $set: action.payload.total },
			});
		case types.FETCH_SITE_REPORTS_FAILURE:
			return update(state, {
				isFetching: { $set: false },
				isLoading: { $set: false },
			});
		case types.SET_ISSUE_IDS:
			return {
				...state,
				issueIDs: action.payload.issues,
			};
		case types.CLEAR_ISSUE_IDS:
			return {
				...state,
				issueIDs: initialState.issueIDs,
			};
		default:
			return state;
	}
}
