import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Arrow from '../../../assets/icon/arrow-back.svg';
import { ReadMore, Article, Dot, Container } from './Slider.styles';
import fetchBlogData from '../utils/fetchBlogData';
/**
 *
 *      Slider is a blog slider used to display website current
 *      articles. List of props:
 *
 *      @articles list of articles retrieved using fetchBlogData()
 *      @indexDot current position of article slider
 *
 */

interface SliderProps {
	authenticatingStatus: boolean;
}

const SliderBlog = (props: SliderProps) => {
	const [articles, setArticles] = useState<any>([]);
	const [indexDot, setIndexDot] = useState<number>(0);
	const { authenticatingStatus } = props;

	useEffect(() => {
		async function fetch() {
			const blog_data = await fetchBlogData(3);
			if (blog_data.msg === 'ok') setArticles(blog_data.data);
		}

		fetch();
	}, []);

	const renderArticle = () => {
		if (!articles[indexDot]) return null;

		const trimmedTitle = articles[indexDot].title?.rendered.substr(0, 120);
		const renderedTitle = trimmedTitle.split(' ').slice(0, -1).join(' ');

		return (
			<div className="slider-container">
				<div
					onClick={() => {
						indexDot === 0 ? setIndexDot(articles.length - 1) : setIndexDot(indexDot - 1);
					}}
				>
					<img src={Arrow} alt="arrow-prev" className="svg-white arrow-prev" />
				</div>
				<div className="slide-article">
					<Article>
						<div className="fade-in">
							<div className="line" />
							<div className="date"> {moment(articles[indexDot].date).locale('en').format('DD MMMM YYYY')}</div>
							<div className="title">{`${renderedTitle}...`}</div>
							<div
								className="article-body"
								dangerouslySetInnerHTML={{
									__html: articles[indexDot].content?.rendered.replace(/(<([^>]+)>)/gi, '').substr(0, 150) + '...',
								}}
							/>
						</div>

						<div className="button-container">
							{authenticatingStatus ? (
								<ReadMore> Read More </ReadMore>
							) : (
								<a href={`https://hellonimbly.com/${articles[indexDot].slug}`} target="_blank">
									<ReadMore> Read More</ReadMore>
								</a>
							)}
						</div>
					</Article>
				</div>
				<div>
					<div
						className="arrow-next"
						onClick={() => {
							indexDot === articles.length - 1 ? setIndexDot(0) : setIndexDot(indexDot + 1);
						}}
					>
						<img src={Arrow} alt="arrow-next" className="svg-white" />
					</div>
				</div>
			</div>
		);
	};

	return (
		<Container>
			<div className="title">Latest Article</div>
			{renderArticle()}
			<div className="dot-indicator">
				<div className="dot-container">
					{articles.map((_: any, i: number) => {
						return <Dot onClick={() => setIndexDot(i)} styleIndex={i} dataIndex={indexDot} key={i} />;
					})}
				</div>
			</div>
		</Container>
	);
};

export default SliderBlog;
