import React, { useMemo } from 'react';
import { MenuItem, CollapsibleMenuItem } from '../styles/SubSidebar.styles';
import { useLocation, useHistory } from 'react-router';
import { SubMenuDetail } from '../constants/SubMenuList.constants';
import { cloneDeep } from 'lodash';
import ChevronVerticalIcon from '../../../../assets/icon/sidebar/chevron-vertical.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducers';

function SubMenuItem(props: OwnProps) {
	const {
		icon,
		label,
		path,
		collapsible,
		collapsibleList,
		onChangeSubMenuCollapsed,
		subMenuCollapsedStateList,
		id,
	} = props;

	const { pathname } = useLocation();
	const history = useHistory();
	const { t } = useTranslation();

	const mainPath = path.split('/')[1];
	const subPath = path.split('/')[2];
	const match = pathname.includes(mainPath) === pathname.includes(subPath);
	const profile = useSelector((state: RootState) => state?.firebase?.profile);
	const loggedInUserOrgID = profile.organization!;

	const collapsibleListDetail = useMemo(() => {
		let filteredCollapsibleList = cloneDeep(collapsibleList);

		if (loggedInUserOrgID === 'shinkanzensushi') {
			filteredCollapsibleList = filteredCollapsibleList?.filter((list) => list.id !== 'menu_download_mng1');
		}

		if (collapsible && collapsibleList && filteredCollapsibleList) {
			return {
				length: filteredCollapsibleList.length,
				data: filteredCollapsibleList,
			};
		}
		return {
			length: 0,
			data: [],
		};
	}, [collapsible, collapsibleList]);

	const manageTabStatus = useMemo(() => {
		const manageCollapsibleTab = collapsibleListDetail.data.map((detail) => detail);

		let status = {
			isActive: false,
			path: '',
		};

		manageCollapsibleTab.forEach((item) => {
			const splittedPathName = item.path.split('/');
			const mainAccessedPath = splittedPathName[1];
			const subAccessedPath = splittedPathName[2];
			const splittedAccessedPathName = pathname.split('/');

			if (splittedAccessedPathName.includes(mainAccessedPath) && splittedAccessedPathName.includes(subAccessedPath)) {
				status = {
					isActive: true,
					path: item.path,
				};
			}
		});
		return status;
	}, [collapsibleListDetail, pathname]);

	return collapsibleListDetail.length ? (
		<CollapsibleMenuItem
			isActive={manageTabStatus.isActive}
			isCollapsed={subMenuCollapsedStateList[label]}
			itemCount={collapsibleListDetail.length}
		>
			<div
				className="header"
				onClick={() => onChangeSubMenuCollapsed(label, !subMenuCollapsedStateList[label])}
				id={id}
			>
				<img src={manageTabStatus.isActive ? icon.active : icon.inactive} alt={`${mainPath}-icon`} />
				<span>{t(label)}</span>
				<div className="chevron-container">
					<img src={ChevronVerticalIcon} alt="chevron-icon" />
				</div>
			</div>

			<div className="collapsible">
				{collapsibleListDetail.data.map((menu, i) => {
					return (
						<MenuItem
							key={`${menu.label}-${i}`}
							isActive={menu.path === manageTabStatus.path}
							href={menu.path}
							id={menu.id}
							onClick={(e) => {
								e.preventDefault();
								history.push(menu.path);
							}}
						>
							<span>{t(menu.label)}</span>
						</MenuItem>
					);
				})}
			</div>
		</CollapsibleMenuItem>
	) : (
		<MenuItem
			id={id}
			isActive={match}
			href={path}
			onClick={(e) => {
				e.preventDefault();
				history.push(path);
			}}
		>
			<img src={match ? icon.active : icon.inactive} alt={`${mainPath}-icon`} />
			<span>{t(label)}</span>
		</MenuItem>
	);
}

export default SubMenuItem;

type OwnProps = SubMenuDetail & {
	onChangeSubMenuCollapsed: (subMenuID: string, isCollapsed: boolean) => void;
	subMenuCollapsedStateList: { [subMenuID: string]: boolean };
	id?: string;
};
