import { createReducer, ActionType } from 'typesafe-actions';
import cloneDeep from 'lodash/cloneDeep';

import * as actions from './journeys.action';
import { JourneysState } from './type';

export type JourneysAction = ActionType<typeof actions>;

const initialState: JourneysState = {
  journeys: null,
  journey: null,
  isLoading: false,
  error: null,
  bulkErrors: [],
  isBulkError: false,
  isUpdate: false,
  reportJourney: [],
  isCreate: false
};

export const journeysReducer = createReducer<JourneysState, JourneysAction>(initialState)
  .handleAction(actions.fetchJourneys.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isUpdate: false,
    isCreate: false
  }))
  .handleAction(actions.fetchJourneys.success, (state, action) => ({
    ...state,
    journeys: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchJourneys.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.createJourneys.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isCreate: false,
    isUpdate: false
  }))
  .handleAction(actions.createJourneys.success, (state, action) => ({
    ...state,
    isLoading: false,
    journeys: { [action.payload.data.code]: action.payload.data, ...state.journeys },
    isCreate: true
  }))
  .handleAction(actions.createJourneys.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.updateJourney.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isUpdate: false,
    isCreate: false
  }))
  .handleAction(actions.updateJourney.success, (state, action) => {
    let filteredJourney = { ...state.journeys };
    let prevCode = Object.keys(action.payload.data)[0];
    let newJourney: any = action.payload.data[prevCode];
    delete filteredJourney[prevCode];
    return {
      ...state,
      isLoading: false,
      journeys: { [newJourney.code]: newJourney, ...filteredJourney },
      journey: newJourney,
      isUpdate: true
    };
  })
  .handleAction(actions.updateJourney.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.fetchJourneyDetail.request, state => ({
    ...state,
    journey: null,
    error: null,
    isLoading: true,
    isUpdate: false,
    isCreate: false
  }))
  .handleAction(actions.fetchJourneyDetail.success, (state, action) => ({
    ...state,
    journey: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchJourneyDetail.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.deleteJourney.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isUpdate: false,
    isCreate: false
  }))
  .handleAction(actions.deleteJourney.success, (state, action: { payload: { code: string } }) => {
    let newJourneys = cloneDeep(state.journeys);
    if (newJourneys && Object.keys(newJourneys).length !== 0 && newJourneys[action.payload.code])
      delete newJourneys[action.payload.code];

    return {
      ...state,
      journeys: newJourneys,
      isLoading: false
    };
  })
  .handleAction(actions.deleteJourney.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false,
    isUpdate: false
  }))
  .handleAction(actions.fetchReportJourney.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isUpdate: false,
    isCreate: false
  }))
  .handleAction(actions.fetchReportJourney.success, (state, action) => ({
    ...state,
    reportJourney: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchReportJourney.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.createJourneyBulk.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isUpdate: false,
    isCreate: false
  }))
  .handleAction(actions.createJourneyBulk.success, (state, action) => ({
    ...state,
    isLoading: false,
    isCreate: true,
    isBulkError: false
  }))
  .handleAction(actions.createJourneyBulk.failure, (state, action) => ({
    ...state,
    bulkErrors: action.payload.errors,
    isBulkError: true,
    isCreate: false,
    isLoading: false
  }));
export default journeysReducer;
