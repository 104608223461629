import { action, createAsyncAction, deprecated } from 'typesafe-actions';
import { DepartmentGroup } from 'nimbly-common';

import * as types from './departmentGroup.actionTypes';
import { ListTabOptions } from 'components/dashboard/AnalyticsController/AnalyticsController.Types';
const { createStandardAction } = deprecated;

const clearDepartmentsGroupFilter = (text: string) => action(types.CLEAR_DEPT_GROUP_FILTER, { text });

const setDepartmentsGroupFilter = (text: string) => action(types.SET_DEPT_GROUP_FILTER, { text });

const setEditingDetail = (editing: boolean) => action(types.SET_EDITING_DETAIL, { editing });

const getDepartmentGroup = createAsyncAction(
  types.GET_DEPT_GROUP_REQUEST,
  types.GET_DEPT_GROUP_SUCCESS,
  types.GET_DEPT_GROUP_FAILURE
)<undefined, { data: any }, { error: string }>();

const getDepartmentGroupById = createAsyncAction(
  types.GET_DEPT_GROUP_BY_ID_REQUEST,
  types.GET_DEPT_GROUP_BY_ID_SUCCESS,
  types.GET_DEPT_GROUP_BY_ID_FAILURE
)<{ groupDeptID: string }, { data: DepartmentGroup | null }, { error: string }>();

// TODO: rename this to toggle department group status, as deleting and reactivating department group is using the same api.
const deleteDepartmentGroup = createAsyncAction(
  types.DELETE_DEPT_GROUP_REQUEST,
  types.DELETE_DEPT_GROUP_SUCCESS,
  types.DELETE_DEPT_GROUP_FAILURE
)<
  { groupDeptID: string },
  { newDeptGroup: DepartmentGroup[] | null; selectedDepartment: DepartmentGroup | null },
  { error: string }
>();

const createDepartmentGroup = createAsyncAction(
  types.CREATE_DEPARTMENT_GROUP_REQUEST,
  types.CREATE_DEPARTMENT_GROUP_SUCCESS,
  types.CREATE_DEPARTMENT_GROUP_FAILURE
)<{ data: DepartmentGroup }, { newData: DepartmentGroup[] | null }, { error: string }>();

const updateDepartmentGroup = createAsyncAction(
  types.UPDATE_DEPARTMENT_GROUP_REQUEST,
  types.UPDATE_DEPARTMENT_GROUP_SUCCESS,
  types.UPDATE_DEPARTMENT_GROUP_FAILURE
)<{ groupDeptID: string; data: DepartmentGroup }, { newDeptGroup: DepartmentGroup[] | null }, { error: string }>();

const setDeptGroupActiveTab = createStandardAction(types.SET_DEPT_GROUP_TAB)<ListTabOptions>();

export {
  createDepartmentGroup,
  updateDepartmentGroup,
  getDepartmentGroup,
  getDepartmentGroupById,
  deleteDepartmentGroup,
  clearDepartmentsGroupFilter,
  setDepartmentsGroupFilter,
  setDeptGroupActiveTab,
  setEditingDetail
};
