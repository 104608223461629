import {
  FetchQuestionnaireStatPayload,
  IssuesQuestionnaireStat
} from 'reducers/dashboard/issues/questionnaireStat/types';
import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';
import { QuestionnaireStatUsedData } from 'reducers/dashboard/fetch-types/issues-fetch.types';

import questionnaire1_issuesCreated from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire1-issuesCreated.json';
import questionnaire1_issuesResolved from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire1-issuesResolved.json';
import questionnaire1_avgTime from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire1-avgTime.json';

import questionnaire2_issuesCreated from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire2-issuesCreated.json';
import questionnaire2_issuesResolved from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire2-issuesResolved.json';
import questionnaire2_avgTime from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire2-avgTime.json';

import questionnaire3_issuesCreated from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire3-issuesCreated.json';
import questionnaire3_issuesResolved from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire3-issuesResolved.json';
import questionnaire3_avgTime from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaire3-avgTime.json';

import questionnaires_prevIssuesOverdue from '../../../assets/dummy/json/issuesQuestionnaireStat/questionnaires_prevIssuesOverdue.json';

const questionnaires_issuesCreated = [
  questionnaire1_issuesCreated,
  questionnaire2_issuesCreated,
  questionnaire3_issuesCreated
];

const questionnaires_issuesResolved = [
  questionnaire1_issuesResolved,
  questionnaire2_issuesResolved,
  questionnaire3_issuesResolved
];

const questionnaires_avgTime = [questionnaire1_avgTime, questionnaire2_avgTime, questionnaire3_avgTime];

const questionnaireCategories = ['Operations', 'Inventory', 'Products'];
const questionnaireNames = ['Opening Checklist', 'Stock Opname', 'Food Hygiene'];
const questionTitles = [
  'How many cash are in the drawer?',
  'How many kg of flour left in the outlet?',
  'What is the average temperature of the storage?'
];

type QuestionnaireStatUsedData_NUMBERS = Pick<
  QuestionnaireStatUsedData,
  'issuesCreated' | 'issuesOverdue' | 'prevIssuesOverdue' | 'issuesCompletedAvgTime' | 'issuesCompletionRate'
>;
type QuestionnaireStatUsedData_INFO = Omit<QuestionnaireStatUsedData, keyof QuestionnaireStatUsedData_NUMBERS>;

const dummyIssuesQuestionnaireStats_NUMBERS_31D: QuestionnaireStatUsedData_NUMBERS[][] = new Array(31)
  .fill('with_date')
  .map((_, dateIndex) => {
    const all_questionnaireUsedData_NUMBERS: QuestionnaireStatUsedData_NUMBERS[] = questionnaireNames.map(
      (_, questionnaireIndex) => {
        const issuesCreated = questionnaires_issuesCreated[questionnaireIndex][dateIndex];
        const issuesResolved = questionnaires_issuesResolved[questionnaireIndex][dateIndex];
        const issuesCompletedAvgTime = questionnaires_avgTime[questionnaireIndex][dateIndex];
        const prevIssuesOverdue = questionnaires_prevIssuesOverdue[dateIndex];
        const questionnaireStatUsedData_NUMBERS: QuestionnaireStatUsedData_NUMBERS = {
          issuesCompletedAvgTime,
          issuesCreated,
          issuesOverdue: issuesCreated - issuesResolved,
          issuesCompletionRate: issuesResolved / issuesCreated,
          prevIssuesOverdue
        };
        return questionnaireStatUsedData_NUMBERS;
      }
    );

    return all_questionnaireUsedData_NUMBERS;
  });

export const getDummyIssuesQuestionnaireStat = (payload: FetchQuestionnaireStatPayload) => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let dummyIssuesQuestionnaireStats_NUMBERS: QuestionnaireStatUsedData_NUMBERS[][] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    dummyIssuesQuestionnaireStats_NUMBERS = dummyIssuesQuestionnaireStats_NUMBERS_31D.slice(-diffInDay);
  } else {
    dummyIssuesQuestionnaireStats_NUMBERS = dummyIssuesQuestionnaireStats_NUMBERS_31D.slice(0, diffInDay);
  }

  const reduced_dummyIssuesQuestionnaireStat_NUMBERS: QuestionnaireStatUsedData_NUMBERS[] = dummyIssuesQuestionnaireStats_NUMBERS.reduce(
    (curr, acc) => {
      return curr.map((_, currIndex) => {
        const currIssuesCompletionRate =
          curr[currIndex].issuesCompletionRate !== 'N/A' ? Number(curr[currIndex].issuesCompletionRate) : 0;
        const accIssuesCompletionRate =
          acc[currIndex].issuesCompletionRate !== 'N/A' ? Number(acc[currIndex].issuesCompletionRate) : 0;
        return {
          issuesCompletedAvgTime: curr[currIndex].issuesCompletedAvgTime + acc[currIndex].issuesCompletedAvgTime,
          issuesCompletionRate: currIssuesCompletionRate + accIssuesCompletionRate,
          issuesCreated: curr[currIndex].issuesCreated + acc[currIndex].issuesCreated,
          issuesOverdue: curr[currIndex].issuesOverdue + acc[currIndex].issuesOverdue,
          prevIssuesOverdue: curr[currIndex].prevIssuesOverdue + acc[currIndex].prevIssuesOverdue
        };
      });
    }
  );

  const dummyIssuesQuestionnaireStats_INFO: QuestionnaireStatUsedData_INFO[] = questionnaireNames.map(
    (questionnaireName, questionnaireIndex) => {
      return {
        category: questionnaireCategories[questionnaireIndex],
        questionText: questionTitles[questionnaireIndex],
        questionnaireID: `questionnaire_${questionnaireIndex}`,
        questionnaireIndexID: `questionnaire_index_${questionnaireIndex}`,
        questionnaireTitle: questionnaireName
      };
    }
  );

  const dummyIssuesQuestionnaireStats: IssuesQuestionnaireStat = questionnaireNames.map((_, questionnaireIndex) => {
    const avgTotal_issuesCompletedAvgTime =
      reduced_dummyIssuesQuestionnaireStat_NUMBERS[questionnaireIndex].issuesCompletedAvgTime / diffInDay;
    const issuesCompletionRate = reduced_dummyIssuesQuestionnaireStat_NUMBERS[questionnaireIndex].issuesCompletionRate;
    let avgTotal_issuesCompletionRate: number | 'N/A' = 'N/A';
    if (issuesCompletionRate !== 'N/A') {
      avgTotal_issuesCompletionRate = Number(issuesCompletionRate) / diffInDay;
    }
    return {
      ...dummyIssuesQuestionnaireStats_INFO[questionnaireIndex],
      ...reduced_dummyIssuesQuestionnaireStat_NUMBERS[questionnaireIndex],
      issuesCompletedAvgTime: avgTotal_issuesCompletedAvgTime,
      issuesCompletionRate: avgTotal_issuesCompletionRate
    };
  });

  return dummyIssuesQuestionnaireStats;
};
