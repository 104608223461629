import { FlagCardsResponse, DashboardOverviewPayload } from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type OverviewFlagCards = FlagCardsResponse['data'];

export interface FlagCardsState {
  readonly flagCards_loading: boolean;
  readonly flagCards_data: OverviewFlagCards;
  readonly flagCards_error: string;
}

export const FETCH_CARD_FLAG_REQUEST = '@@dashboard/overview/FETCH_CARD_FLAG_REQUEST';
export const FETCH_CARD_FLAG_SUCCESS = '@@dashboard/overview/FETCH_CARD_FLAG_SUCCESS';
export const FETCH_CARD_FLAG_FAILURE = '@@dashboard/overview/FETCH_CARD_FLAG_FAILURE';

export const FETCH_CARD_FLAGV1_REQUEST = '@@dashboard/overviewv1/FETCH_CARD_FLAGV1_REQUEST';
export const FETCH_CARD_FLAGV1_SUCCESS = '@@dashboard/overviewv1/FETCH_CARD_FLAGV1_SUCCESS';
export const FETCH_CARD_FLAGV1_FAILURE = '@@dashboard/overviewv1/FETCH_CARD_FLAGV1_FAILURE';

export type FetchFlagsOverviewPayload = DashboardOverviewPayload;
export type FetchFlagsOverviewResponse = FlagCardsResponse | ErrorResponse;
