import { ActionType, createReducer } from 'typesafe-actions';
import { LmsUserGroupI } from './type';
import * as actions from './userGroup.action';
import * as types from './userGroup.actionTypes';

export type CreateUserGroupAction = ActionType<typeof actions>;

export const userGroupInitialState: LmsUserGroupI = {
	isSuccess: false,
	isLoading: false,
	groupName: '',
	description: '',
	userIDs: [],
};

const createUserGroupReducer = createReducer<LmsUserGroupI, CreateUserGroupAction>(userGroupInitialState)
	.handleAction(actions.createUserGroup.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.createUserGroup.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.createUserGroup.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
		isSuccess: false,
	}))
	.handleAction(actions.updateUserGroup.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.updateUserGroup.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.updateUserGroup.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
		isSuccess: false,
		errorData: action.payload.errorData || null,
	}))
	.handleAction(types.SELECTED_EDIT_LMS_USER_GROUP as any, (state, action) => ({
		...state,
		selectedEditLmsUser: action.payload.data,
	}))
	.handleAction(types.SELECTED_EDIT_LMS_USERS_GROUP as any, (state, action) => ({
		...state,
		selectedEditLmsUsers: action.payload.data,
	}))
	.handleAction(types.SET_SHOW_MODAL as any, (state, action) => ({
		...state,
		isShowModal: action.payload.value,
	}))
	.handleAction(types.SET_SUCCESS as any, (state, action) => {
		return {
			...state,
			isSuccess: action.payload.value,
		};
	});

export { createUserGroupReducer };
