import React, { Component } from 'react';
import LoadingPage from 'pages/loading';

export default function AsyncComponent(importComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({ component });
    }

    render() {
      const { component: C } = this.state;

      return C ? <C {...this.props} /> : <LoadingPage />;
    }
  };
}
