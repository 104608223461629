import { IAPIResponse } from 'types/api';
import { apiURL } from '../../../config/baseURL';
import API from '../../../helpers/api';
import { CreateSyllabusRequest, SyllabusDetailResponse } from '@nimbly-technologies/nimbly-common';
import { toast } from 'react-toastify';

export const fetchSyllabuses = async (searchParams: string): Promise<any> => {
	const url = `${apiURL}/lms/syllabuses?${searchParams}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export const getSyllabus = async (id: string): Promise<{ data: SyllabusDetailResponse }> => {
	const url = `${apiURL}/lms/syllabuses/${id}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export const createSyllabus = async (payload: CreateSyllabusRequest): Promise<any> => {
	const url = `${apiURL}/lms/syllabuses`;
	const token = await API.getFirebaseToken();
	const res = await API.post(url, token, payload);
	const response = await res.json();

	if (res.status === 200) {
		return response.data as string;
	} else {
		if (response.message === 'FAILED') {
			throw new Error(response.data);
		}
		throw new Error(response.message);
	}
};

export const updateSyllabus = async (id: string, payload: Partial<CreateSyllabusRequest>): Promise<any> => {
	const url = `${apiURL}/lms/syllabuses/${id}`;
	const token = await API.getFirebaseToken();
	const res = await API.put(url, token, payload);
	const response = await res.json();

	if (res.status === 200) {
		return response.data as string;
	} else {
		if (response.message === 'FAILED') {
			throw new Error(response.data);
		}
		throw new Error(response.message);
	}
};

export const fetchLmsSyllabusesById = async (syllabusId: string): Promise<SyllabusDetailResponse | undefined> => {
	const url = `${apiURL}/lms/syllabuses/${syllabusId}`;
	const res = await API.getJSON<IAPIResponse<{ data: SyllabusDetailResponse }>>(url);

	return res?.data.data;
};

export const deleteSyllabus = async (syllabusID: string[]): Promise<any> => {
	const url = `${apiURL}/lms/syllabuses/bulk-delete`;
	const token = await API.getFirebaseToken();
	const res = await API.post(url, token, {
		syllabusIDs: syllabusID,
	});
	const response = await res.json();

	if (res.status === 200) {
		toast.success('Course Deleted Successfully');
		return response.data as string;
	} else {
		if (response.message === 'FAILED') {
			throw new Error(response.data);
		}
		toast.error('Something went wrong');
		throw new Error(response.message);
	}
};
