import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesQuestionnaireStat,
  FETCH_QUESTIONNAIRE_STAT_REQUEST,
  FETCH_QUESTIONNAIRE_STAT_SUCCESS,
  FETCH_QUESTIONNAIRE_STAT_FAILURE,
  DOWNLOAD_QUESTIONNAIRE_STAT_REQUEST,
  DOWNLOAD_QUESTIONNAIRE_STAT_SUCCESS,
  DOWNLOAD_QUESTIONNAIRE_STAT_FAILURE,
  FetchQuestionnaireStatPayload,
  DownloadQuestionnaireStatPayload
} from './types';

export const fetchQuestionnaireStatAsync = createAsyncAction(
  FETCH_QUESTIONNAIRE_STAT_REQUEST,
  FETCH_QUESTIONNAIRE_STAT_SUCCESS,
  FETCH_QUESTIONNAIRE_STAT_FAILURE
)<FetchQuestionnaireStatPayload, IssuesQuestionnaireStat, string>();

export const downloadQuestionnaireStatAsync = createAsyncAction(
  DOWNLOAD_QUESTIONNAIRE_STAT_REQUEST,
  DOWNLOAD_QUESTIONNAIRE_STAT_SUCCESS,
  DOWNLOAD_QUESTIONNAIRE_STAT_FAILURE
)<DownloadQuestionnaireStatPayload, string, string>();
