import { createAsyncAction } from 'typesafe-actions';
import * as types from './skuCategory.actionTypes';

export const fetchSkuCategories = createAsyncAction(
	types.FETCH_SKU_CATEGORIES_REQUEST,
	types.FETCH_SKU_CATEGORIES_SUCCESS,
	types.FETCH_SKU_CATEGORIES_FAILURE,
)<undefined, string[], string>();

export const createAndRefreshSkuCategory = createAsyncAction(
	types.CREATE_AND_REFRESH_SKU_CATEGORY_REQUEST,
	types.CREATE_AND_REFRESH_SKU_CATEGORY_SUCCESS,
	types.CREATE_AND_REFRESH_SKU_CATEGORY_FAILURE,
)<string, string[], string>();
