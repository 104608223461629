import * as Common from 'nimbly-common';
import moment, { Moment } from 'moment-timezone';

import { currentPeriodStartDate, currentPeriodEndDate } from '../utils/schedule';

/* BASE FUNCTION */
// Returns array of string date from startDate to dueDate
export function getSelectedPeriodDates(analytics: any) {
	if (analytics.periodType === 'custom') {
		if (analytics.customStart && analytics.customEnd) {
			const periodDate = analytics.customStart.clone();
			const periodDueDate = analytics.customEnd;
			const chartPeriodDates = [];
			while (periodDate.isSameOrBefore(periodDueDate)) {
				chartPeriodDates.push(periodDate.format('YYYY-MM-DD'));
				periodDate.add(1, 'day');
			}
			return chartPeriodDates;
		}
	}
	const periodDate = analytics.startDate.clone();
	const periodDueDate = analytics.endDate;
	const chartPeriodDates = [];
	while (periodDate.isSameOrBefore(periodDueDate)) {
		chartPeriodDates.push(periodDate.format('YYYY-MM-DD'));
		periodDate.add(1, 'day');
	}
	return chartPeriodDates;
}

const currentPeriodYear = () => {
	return moment.parseZone().startOf('year');
};

const currentPeriodMonth = () => {
	return moment.parseZone().startOf('month');
};

const prevPeriodYear = () => {
	return moment.parseZone().subtract(1, 'year').startOf('year');
};

const prevPeriodMonth = () => {
	return moment.parseZone().subtract(1, 'month').startOf('month');
};

export const getDatesByPeriod = (
	periodType: Common.enums.PeriodTypes | 'ytd' | null,
	orgSchedule: Common.OrganizationSchedule,
	date: any = null,
	previous = false,
	// eslint-disable-next-line max-params
): { startDate: Moment | null; endDate: Moment | null } => {
	switch (periodType) {
		case Common.enums.PeriodTypes.CURRENT:
			return {
				startDate: currentPeriodStartDate(orgSchedule),
				endDate: currentPeriodEndDate(orgSchedule),
			};
		case Common.enums.PeriodTypes.PREVIOUS: {
			const periodUnit = orgSchedule.periodUnit as Common.PeriodUnit;
			return {
				startDate: currentPeriodStartDate(orgSchedule)?.subtract(1, periodUnit) || null,
				endDate: currentPeriodEndDate(orgSchedule)?.subtract(1, periodUnit) || null,
			};
		}
		case Common.enums.PeriodTypes.LAST_7:
			return {
				startDate: moment().subtract(6, 'days'),
				endDate: moment(),
			};
		case Common.enums.PeriodTypes.LAST_28:
			return {
				startDate: moment().subtract(27, 'days'),
				endDate: moment(),
			};
		case Common.enums.PeriodTypes.LAST_30:
			return {
				startDate: moment().subtract(29, 'days'),
				endDate: moment(),
			};
		case Common.enums.PeriodTypes.LAST_90:
			return {
				startDate: moment().subtract(89, 'days'),
				endDate: moment(),
			};
		case Common.enums.PeriodTypes.LAST_6_M:
			return {
				startDate: moment().subtract(30 * 6 - 1, 'days'),
				endDate: moment(),
			};
		case Common.enums.PeriodTypes.LAST_12_M:
			return {
				startDate: moment().subtract(30 * 12 - 1, 'days'),
				endDate: moment(),
			};
		case Common.enums.PeriodTypes.LAST_YEAR:
			return {
				startDate: moment().subtract(1, 'year').startOf('year'),
				endDate: moment().subtract(1, 'year').endOf('year'),
			};
		case Common.enums.PeriodTypes.MTD:
			if (previous) {
				return {
					startDate: prevPeriodMonth(),
					endDate: moment.parseZone().subtract(1, 'month'),
				};
			}
			return {
				startDate: currentPeriodMonth(),
				endDate: moment.parseZone(),
			};
		case Common.enums.PeriodTypes.CUSTOM:
			if (previous) {
				const customDate = moment(date.customStart);
				const diff = customDate.diff(date.customEnd, 'd');
				const prevCustomStartDate = moment(date.customStart).subtract(Math.abs(diff), 'd');
				const prevCustomEndDate = moment(date.customStart).subtract(1, 'ms');
				return {
					startDate: prevCustomStartDate,
					endDate: prevCustomEndDate,
				};
			}
			return {
				startDate: date.customStart,
				endDate: date.customEnd,
			};
		case 'ytd':
			if (previous) {
				return {
					startDate: prevPeriodYear(),
					endDate: moment.parseZone().subtract(1, 'year'),
				};
			}
			return {
				startDate: currentPeriodYear(),
				endDate: moment.parseZone(),
			};
		default:
			return {
				startDate: moment.parseZone().subtract(1, 'week'),
				endDate: moment.parseZone(),
			};
	}
};

export function getDatesByPeriodType(periodType: Common.enums.PeriodTypes) {
	switch (periodType) {
		case Common.enums.PeriodTypes.CURRENT:
			return {
				startDate: moment.parseZone().startOf('isoWeek'),
				endDate: moment.parseZone().endOf('isoWeek'),
			};
		case Common.enums.PeriodTypes.PREVIOUS:
			return {
				startDate: moment.parseZone().startOf('isoWeek').subtract(1, 'week'),
				endDate: moment.parseZone().endOf('isoWeek').subtract(1, 'week'),
			};
		case Common.enums.PeriodTypes.LAST_7:
			return {
				startDate: moment.parseZone().subtract(6, 'days'),
				endDate: moment.parseZone(),
			};
		case Common.enums.PeriodTypes.LAST_28:
			return {
				startDate: moment.parseZone().subtract(27, 'days'),
				endDate: moment.parseZone(),
			};
		case Common.enums.PeriodTypes.LAST_30:
			return {
				startDate: moment.parseZone().subtract(29, 'days'),
				endDate: moment.parseZone(),
			};
		case Common.enums.PeriodTypes.LAST_90:
			return {
				startDate: moment.parseZone().subtract(89, 'days'),
				endDate: moment.parseZone(),
			};
		case Common.enums.PeriodTypes.LAST_6_M:
			return {
				startDate: moment.parseZone().subtract(6, 'months').add(1, 'day'),
				endDate: moment.parseZone(),
			};
		case Common.enums.PeriodTypes.LAST_12_M:
			return {
				startDate: moment.parseZone().subtract(12, 'months').add(1, 'day'),
				endDate: moment.parseZone(),
			};
		case Common.enums.PeriodTypes.LAST_YEAR:
			return {
				startDate: moment.parseZone().subtract(1, 'year').startOf('year'),
				endDate: moment.parseZone().subtract(1, 'year').endOf('year'),
			};
		case Common.enums.PeriodTypes.MTD:
			return {
				startDate: moment.parseZone().startOf('month'),
				endDate: moment.parseZone(),
			};
		default:
			return {
				startDate: moment.parseZone().startOf('isoWeek'),
				endDate: moment.parseZone().endOf('isoWeek'),
			};
	}
}
