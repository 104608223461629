import React from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { firebaseConnect } from 'react-redux-firebase';
import { FirebaseConnect } from 'types/app';

// styles
import { Button } from 'styles/Button';
import { Overlay, Modal } from 'styles/General';

// action-reducers

// types
import { RootState } from 'store/rootReducers';

const FreezeAccountModal = (props: any) => {
  const { isVisible } = props;
  const { t } = useTranslation();
  const handleLogout = () => {
    // Push to placeholder loading page while we log out
    props.dispatch(push('/loading'));
    props.firebase.logout().then(() => {
      // Reload the page to empty the redux state and start from 0
      props.handleDismissModal();
      window.location.reload();
    });
  };

  return isVisible ? (
    <Overlay>
      <ConfirmModal data-testid="confirmModal">
        <div className="title">{t('modal.frozen.title')}</div>
        <div className="description">
          <span>{t('modal.frozen.description')}</span>
        </div>
        <div className="button__container">
          <Button medium onClick={handleLogout}>
            {t('label.okay')}
          </Button>
        </div>
      </ConfirmModal>
    </Overlay>
  ) : null;
};

export default FreezeAccountModal;

const ConfirmModal = styled(Modal)`
  padding: 20px 0px;
  width: calc(100% - 2rem);
  min-height: 190px;
  max-width: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.title {
    text-align: center;

    font-weight: bold;
  }

  div.description {
    text-align: center;
    padding: 11px;
  }

  > div.button__container {
    margin-top: 25px;
    display: 'flex';
    flex-direction: 'row';
    justify-content: 'space-evenly';
    width: '200px';

    *:not(:first-child) {
      margin-left: 10px;
    }
  }

  @media (min-width: 992px) {
    min-width: 330px;
    min-height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div.title {
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      padding: 5px;
      font-weight: 600;
    }
    div.description {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      font-size: 12px;
      padding: 10px;
  }
`;
