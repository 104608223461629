import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './promotion.action';
import * as types from './promotion.actionTypes';
import { PromotionState } from './type';

export type PromotionAction = ActionType<typeof actions>;

const initialState: PromotionState = {
  promotionList: [],
  promotionListRaw: [],
  promotion: null,
  isLoading: false,
  isCreateUpdateLoading: false,
  error: null,
  sortBy: 'companyName',
  company: 'asc',
  title: 'asc',
  type: 'asc',
  detail: 'asc',
  createdAt: 'asc',
  isSuccess: false,
  isBulkSuccess: false
};

export const promotionReducer = createReducer<PromotionState, PromotionAction>(initialState)
  .handleAction(actions.fetchPromotion.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isSuccess: false,
    isBulkSuccess: false
  }))
  .handleAction(actions.fetchPromotion.success, (state, action) => ({
    ...state,
    promotionList: action.payload.data.mapped,
    promotionListRaw: action.payload.data.raw,
    isLoading: false
  }))
  .handleAction(actions.fetchPromotion.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.updatePromotion.request, state => ({
    ...state,
    error: null,
    isCreateUpdateLoading: true,
    isSuccess: false,
    isBulkSuccess: false
  }))
  .handleAction(actions.updatePromotion.success, (state, action) => ({
    ...state,
    isCreateUpdateLoading: false
  }))
  .handleAction(actions.updatePromotion.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isCreateUpdateLoading: false
  }))
  .handleAction(actions.createPromotion.request, state => ({
    ...state,
    error: null,
    isCreateUpdateLoading: true,
    isSuccess: false,
    isBulkSuccess: false
  }))
  .handleAction(actions.createPromotion.success, (state, action) => ({
    ...state,
    // promotionListRaw: [action.payload.data, ...state.promotionListRaw],
    isCreateUpdateLoading: false,
    isSuccess: true
  }))
  .handleAction(actions.createPromotion.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isCreateUpdateLoading: false
  }))
  .handleAction(actions.fetchPromotionDetail.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchPromotionDetail.success, (state, action) => ({
    ...state,
    promotion: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchPromotionDetail.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.createPromotionBulk.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.createPromotionBulk.success, (state, action) => ({
    ...state,
    isLoading: false,
    isBulkSuccess: true
  }))
  .handleAction(actions.createPromotionBulk.failure, (state, action) => ({
    ...state,
    error: action.payload.error
  }))
  .handleAction(types.SET_LOADING as any, (state, action) => {
    return { ...state, isLoading: action.payload.value };
  })
  .handleAction(types.SET_SORT as any, (state, action) => {
    if (state.sortBy !== action.payload.sortType) {
      return {
        ...state,
        sortBy: action.payload.sortType,
        company: 'asc',
        title: 'asc',
        type: 'asc',
        detail: 'asc',
        createdAt: 'asc'
      };
    }
    return {
      ...state,
      [action.payload.sortType]: action.payload.sortOrder
    };
  });

export default promotionReducer;
