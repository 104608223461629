import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesIssuesFlagStat,
  FETCH_ISSUES_FLAG_STAT_REQUEST,
  FETCH_ISSUES_FLAG_STAT_SUCCESS,
  FETCH_ISSUES_FLAG_STAT_FAILURE,
  DOWNLOAD_ISSUES_FLAG_STAT_REQUEST,
  DOWNLOAD_ISSUES_FLAG_STAT_SUCCESS,
  DOWNLOAD_ISSUES_FLAG_STAT_FAILURE,
  FetchIssuesFlagStatPayload,
  DownloadIssuesFlagStatPayload
} from './types';

export const fetchIssuesFlagStatAsync = createAsyncAction(
  FETCH_ISSUES_FLAG_STAT_REQUEST,
  FETCH_ISSUES_FLAG_STAT_SUCCESS,
  FETCH_ISSUES_FLAG_STAT_FAILURE
)<FetchIssuesFlagStatPayload, IssuesIssuesFlagStat, string>();

export const downloadIssuesFlagStatAsync = createAsyncAction(
  DOWNLOAD_ISSUES_FLAG_STAT_REQUEST,
  DOWNLOAD_ISSUES_FLAG_STAT_SUCCESS,
  DOWNLOAD_ISSUES_FLAG_STAT_FAILURE
)<DownloadIssuesFlagStatPayload, string, string>();
