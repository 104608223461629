import update from 'immutability-helper';
import { createReducer } from 'typesafe-actions';
import { SiteGroupAction, siteGroupsActions as actions } from './siteGroups.actions';
import { SiteGroupState } from './siteGroups.types';

const asyncInitialState = {
	isLoading: false,
	data: null,
	error: null,
};

const siteGroupsInitialState: SiteGroupState = {
	filterQueryAll: {},

	createMutation: asyncInitialState,
	queryAll: asyncInitialState,
	queryById: asyncInitialState,
	toggleActiveStatusMutation: asyncInitialState,
	updateMutation: asyncInitialState,
};

export const siteGroupsReducer = createReducer<SiteGroupState, SiteGroupAction>(siteGroupsInitialState)
	.handleAction(actions.queryAllAsync.request, (state) =>
		update(state, {
			queryAll: {
				isLoading: { $set: true },
				error: { $set: null },
			},
		}),
	)
	.handleAction(actions.queryAllAsync.success, (state, action) =>
		update(state, {
			queryAll: {
				isLoading: { $set: false },
				data: { $set: action.payload },
			},
		}),
	)
	.handleAction(actions.queryAllAsync.failure, (state, action) =>
		update(state, {
			queryAll: {
				isLoading: { $set: false },
				error: {
					$set: { message: action.payload.error },
				},
			},
		}),
	)
	.handleAction(actions.createAsync.request, (state) =>
		update(state, {
			createMutation: {
				isLoading: { $set: true },
				error: { $set: null },
			},
		}),
	)
	.handleAction(actions.createAsync.success, (state, action) =>
		update(state, {
			createMutation: {
				isLoading: { $set: false },
				data: { $set: action.payload },
			},
		}),
	)
	.handleAction(actions.createAsync.failure, (state, action) =>
		update(state, {
			createMutation: {
				isLoading: { $set: false },
				error: {
					$set: { message: action.payload.error },
				},
			},
		}),
	)
	.handleAction(actions.queryByIdAsync.request, (state) =>
		update(state, {
			queryById: {
				isLoading: { $set: true },
				error: { $set: null },
			},
		}),
	)
	.handleAction(actions.queryByIdAsync.success, (state, action) =>
		update(state, {
			queryById: {
				isLoading: { $set: false },
				data: { $set: action.payload },
			},
		}),
	)
	.handleAction(actions.queryByIdAsync.failure, (state, action) =>
		update(state, {
			queryById: {
				isLoading: { $set: false },
				error: {
					$set: { message: action.payload.error },
				},
			},
		}),
	)
	.handleAction(actions.updateAsync.request, (state) =>
		update(state, {
			updateMutation: {
				isLoading: { $set: true },
				error: { $set: null },
			},
		}),
	)
	.handleAction(actions.updateAsync.success, (state) =>
		update(state, {
			updateMutation: {
				isLoading: { $set: false },
			},
		}),
	)
	.handleAction(actions.updateAsync.failure, (state, action) =>
		update(state, {
			updateMutation: {
				isLoading: { $set: false },
				error: {
					$set: { message: action.payload.error },
				},
			},
		}),
	)
	.handleAction(actions.toggleActiveStatusAsync.request, (state) =>
		update(state, {
			toggleActiveStatusMutation: {
				isLoading: { $set: true },
				error: { $set: null },
			},
		}),
	)
	.handleAction(actions.toggleActiveStatusAsync.success, (state) =>
		update(state, {
			toggleActiveStatusMutation: {
				isLoading: { $set: false },
			},
		}),
	)
	.handleAction(actions.toggleActiveStatusAsync.failure, (state, action) =>
		update(state, {
			toggleActiveStatusMutation: {
				isLoading: { $set: false },
				error: {
					$set: { message: action.payload.error },
				},
			},
		}),
	)
	.handleAction(actions.setFilterQuery, (state, action) =>
		update(state, {
			filterQueryAll: {
				status: { $set: action.payload.status ?? state.filterQueryAll.status },
				search: { $set: action.payload.search ?? state.filterQueryAll.search },
			},
			queryAll: {
				isLoading: { $set: true },
			},
		}),
	);
