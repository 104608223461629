import { action, createAsyncAction } from 'typesafe-actions';
import { Moment } from 'moment';
import * as types from './reports.actionTypes';
import * as Common from 'nimbly-common';

import { ReportsSortKeys, SkuReportSummary } from './reports.types';
import { getDatesByPeriod } from 'utils/dates';

export const setIsLoading = (status: boolean) => action(types.SET_IS_LOADING, { status });

export const setIsFetching = (status: boolean) => action(types.SET_IS_FETCHING, { status });

export const setReports = (
	reports: (Common.SiteReportSummary & { questionnaire: Common.Questionnaire })[],
	showWarning?: boolean,
) => action(types.SET_REPORTS, { reports, showWarning });

export const setPageIndexReport = (value: number) => action(types.SET_PAGE_INDEX_REPORT, { value });

export const setSortType = (sortBy: ReportsSortKeys) => action(types.SET_SORT_TYPE, { sortBy });

export const setColorChange = () => action(types.SET_COLOR_CHANGE);

export const setFilteredType = (type: string) => action(types.SET_REPORT_TYPE_FILTER, { type });

export const setFilteredQuestionnaires = (questionnaires: string[]) =>
	action(types.SET_REPORT_QUESTIONNAIRE_FILTERS, { questionnaires });

export const setIssueIDs = (issues: string[]) => action(types.SET_ISSUE_IDS, { issues });

export const clearIssueIDs = () => action(types.CLEAR_ISSUE_IDS, {});

export const setFilteredUsers = (users: string[]) => action(types.SET_REPORT_USER_FILTERS, { users });

export const setFilteredSites = (sites: string[]) => action(types.SET_REPORT_SITES_FILTERS, { sites });

export const setFilteredReports = (reports: (Common.SiteReportSummary & { questionnaire: Common.Questionnaire })[]) =>
	action(types.SET_FILTERED_REPORTS, { reports });

export const setPeriodType = (periodType: Common.enums.PeriodTypes | null) =>
	action(types.SET_PERIOD_TYPE, { periodType });

export const resetFilterDates = () => action(types.RESET_REPORTS_FILTER_DATES);

export const selectScheduleKeys = (scheduleKeyIDs: string[]) =>
	action(types.SET_SELECT_SCHEDULE_KEY, { scheduleKeyIDs });

export const selectSkuKeys = (skuKeyIDs: string[]) => action(types.SET_SELECT_SKU_KEY, { skuKeyIDs });

export const setDates = (
	periodType: Common.enums.PeriodTypes | null,
	orgSchedule: Common.OrganizationSchedule,
	date: { customStart: Moment; customEnd: Moment } | null = null,
) => {
	const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date);
	return action(types.SET_DATES, {
		startDate: startDate,
		endDate: endDate,
	});
};

export const setCustomDates = (startDate: Moment | null, endDate: Moment | null) =>
	action(types.SET_CUSTOM_DATES, { startDate, endDate });

export const setPreviousDates = (
	periodType: Common.enums.PeriodTypes | null,
	orgSchedule: Common.OrganizationSchedule,
	date: { customStart: Moment; customEnd: Moment } | null = null,
) => {
	const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date, true);
	return action(types.SET_PREVIOUS_DATES, {
		prevStartDate: startDate,
		prevEndDate: endDate,
	});
};

// TODO: move this to nimbly-common
export interface QueryReports {
	startDate: Moment | null;
	endDate: Moment | null;
	type: string;
	questionnaires: string[];
	departments: string[];
	auditors: string[];
	pageIndex: number;
	sortBy: string;
	sites: string[];
}

export interface DownloadBulkReports {
	reportIDs: string[];
	skuReportIDs: string[];
}

export const fetchReports = createAsyncAction(
	types.FETCH_REPORTS_REQUEST,
	types.FETCH_REPORTS_SUCCESS,
	types.FETCH_REPORTS_FAILURE,
)<
	QueryReports,
	{
		skuReports: SkuReportSummary[];
		reports: (Common.SiteReportSummary & { questionnaire: Common.Questionnaire })[];
		total: number;
	},
	{ error: string }
>();

export const ReportsActions = {
	setIsLoading,
	setReports,
	setPageIndexReport,
	setSortType,
	setColorChange,
	setFilteredType,
	setFilteredQuestionnaires,
	setFilteredUsers,
	setFilteredReports,
	setPeriodType,
	setDates,
	setCustomDates,
	setPreviousDates,
	fetchReports,
	setIssueIDs,
	setFilteredSites,
	selectScheduleKeys,
	selectSkuKeys,
};
