import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './notification.action';
import { INotificationStoreBase } from './notification.type';

const initialState: INotificationStoreBase = {
	data: [],
	isLoading: false,
};

export type LmsNotificationAction = ActionType<typeof actions>;

export const lmsNotificationReducer = createReducer<INotificationStoreBase, LmsNotificationAction>(initialState)
	.handleAction(actions.fetchLmsNotificationAsync.request, (state) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(actions.fetchLmsNotificationAsync.success, (state, action) => {
		return {
			...state,
			isLoading: false,
			data: action.payload,
		};
	})
	.handleAction(actions.fetchLmsNotificationAsync.failure, (state, action) => ({
		...state,
		isLoading: false,
	}));
