import { createAsyncAction } from 'typesafe-actions';

import * as types from './integration.actionTypes';

export const createIntegrationToken = createAsyncAction(
  types.CREATE_INTEGRATION_TOKEN_REQUEST,
  types.CREATE_INTEGRATION_TOKEN_SUCCESS,
  types.CREATE_INTEGRATION_TOKEN_FAILURE
)<
  {
    userId: string;
    organizationId: string;
  },
  { token: string },
  { error: string }
>();

export const retrieveIntegrationToken = createAsyncAction(
  types.RETRIEVE_INTEGRATION_TOKEN_REQUEST,
  types.RETRIEVE_INTEGRATION_TOKEN_SUCCESS,
  types.RETRIEVE_INTEGRATION_TOKEN_FAILURE
)<undefined, { token: string }, { error: string }>();
