const prefix = 'poEditor';

export const SET_VENDOR = `${prefix}/SET_VENDOR`;
export const SET_VENDOR_NAME = `${prefix}/SET_VENDOR_NAME`;
export const SET_VENDOR_ADDRESS = `${prefix}/SET_VENDOR_ADDRESS`;
export const SET_NEW_VENDOR = `${prefix}/SET_NEW_VENDOR`;
export const SET_EXISTING_VENDOR = `${prefix}/SET_EXISTING_VENDOR`;

export const ADD_VENDOR_ITEM = `${prefix}/ADD_VENDOR_ITEM`;
export const UPDATE_VENDOR_ITEM = `${prefix}/UPDATE_VENDOR_ITEM`;
export const UPDATE_VENDOR_ITEM_PRICE = `${prefix}/UPDATE_VENDOR_ITEM_PRICE`;

export const CLEAR_VENDOR = `${prefix}/CLEAR_VENDOR`;
