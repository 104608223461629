import { purple, green, neutral, yellow, red } from './colors';

export const main = {
	primary: purple[500],
	primaryHover: purple[400],
	primaryFocus: purple[400],
	primaryDisabled: purple[200],
	disabled: '',
	textColor: neutral[800],
	textColorInverted: neutral[100],
	textColorOnPrimary: '',
	textOnDisabled: '',
	status: {
		warning: '',
		warningHover: '',
		error: '',
		errorHover: '',
		errorFocus: red[100],
		errorDisabled: red[100],
		success: green[500],
		successHover: '',
		successFocus: '',
	},
	flag: {
		green: green[600],
		yellow: yellow[600],
		red: red[600],
	},
	table: {
		cell: neutral[200],
		cellHover: purple[100],
		cellFocus: purple[200],
	},
	toast: {
		success: green[100],
		error: red[400],
		info: neutral[800],
		text: neutral[100],
		close: neutral[1000],
		textHover: neutral[800],
	},
	dates: {
		text: neutral[500],
		textHover: neutral[100],
		textFocus: neutral[100],
		inputBorder: neutral[700],
		cell: neutral[100],
		cellHover: purple[300],
		cellFocus: purple[300],
	},
	page: {
		background: neutral[300],
	},
	tags: {
		normal: purple[300],
		text: '#fff',
	},
	buttonsColor: {
		swiper: {
			active: yellow[500],
		},
	},
};

export const dark = {
	primary: '',
	primaryHover: '',
	secondary: '',
	secondaryHover: '',
	disabled: '',
	textColor: '',
	textColorInverted: '',
	textColorOnPrimary: '',
	textOnDisabled: '',
	status: {
		warningColor: '',
		warningColorHover: '',
		errorColor: '',
		errorColorHover: '',
		successColor: green[300],
		successColorHover: '',
		successColorActive: '',
	},
};
