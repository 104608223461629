import { call, put, takeLatest } from 'redux-saga/effects';
import {
	CREATE_SITE_REQUEST,
	UPDATE_SITE_REQUEST,
	UpsertSiteResponse,
	ToggleBlockSiteResponse,
	TOGGLE_BLOCK_SITE_REQUEST,
	CheckSiteScheduleCountResponse,
	CHECK_SITE_SCHEDULE_COUNT_REQUEST,
} from 'reducers/admin/manage/sites/types';
import {
	UPSERT_QUESTIONNAIRE_REQUEST,
	UpsertQuestionnaireResponse,
	FetchQuestionCategoriesResponse,
	FETCH_QUESTION_CATEGORIES_REQUEST,
} from 'reducers/admin/manage/questionnaires/types';

import {
	createSiteAsync,
	updateSiteAsync,
	toggleBlockSiteAsync,
	checkSiteScheduleCountAsync,
} from 'reducers/admin/manage/sites/action';
import { upsertQuestionnaireAsync, fetchQuestionCategoriesAsync } from 'reducers/admin/manage/questionnaires/action';

import { UPDATE_USER_REQUEST, CREATE_USER_REQUEST, UpsertUserResponse } from 'reducers/admin/manage/users/types';
import { createUserAsync, updateUserAsync } from 'reducers/admin/manage/users/action';

import { createUser } from './helpers/manage/createUser';
import { updateUser } from './helpers/manage/updateUser';
import {
	createSite,
	updateSite,
	upsertQuestionnaire,
	fetchQuestionCategories,
	blockSite,
	checkSiteSchedule,
} from './helpers/manage';
import { toast } from 'react-toastify';

function* createSiteSaga(action: ReturnType<typeof createSiteAsync.request>): Generator {
	try {
		const fetchRes: UpsertSiteResponse = (yield call(createSite, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(createSiteAsync.success(fetchRes.data));
		} else {
			yield put(createSiteAsync.failure(fetchRes.error || 'MANAGE - CREATE SITE FAILED'));
		}
	} catch (e) {
		yield put(createSiteAsync.failure('MANAGE - CREATE SITE FAILED'));
	}
}
function* updateSiteSaga(action: ReturnType<typeof updateSiteAsync.request>): Generator {
	try {
		const fetchRes: UpsertSiteResponse = (yield call(updateSite, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(updateSiteAsync.success(action.payload.siteID));
		} else {
			yield put(updateSiteAsync.failure(fetchRes.error || 'MANAGE - UPDATE SITE FAILED'));
		}
	} catch (e) {
		yield put(updateSiteAsync.failure('MANAGE - UPDATE SITE FAILED'));
	}
}
function* checkSiteSchedulesSaga(action: ReturnType<typeof checkSiteScheduleCountAsync.request>): Generator {
	try {
		const fetchRes: CheckSiteScheduleCountResponse = (yield call(checkSiteSchedule, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(checkSiteScheduleCountAsync.success(fetchRes.data));
		} else {
			yield put(checkSiteScheduleCountAsync.failure(fetchRes.error || 'MANAGE - BLOCK SITE FAILED'));
		}
	} catch (e) {
		yield put(checkSiteScheduleCountAsync.failure('MANAGE - BLOCK SITE FAILED'));
	}
}
function* blockSiteSaga(action: ReturnType<typeof toggleBlockSiteAsync.request>): Generator {
	try {
		const fetchRes: ToggleBlockSiteResponse = (yield call(blockSite, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(toggleBlockSiteAsync.success(fetchRes.data));
		} else {
			yield put(toggleBlockSiteAsync.failure(fetchRes.error || 'MANAGE - BLOCK SITE FAILED'));
		}
	} catch (e) {
		yield put(toggleBlockSiteAsync.failure('MANAGE - BLOCK SITE FAILED'));
	}
}

function* fetchQuestionCategoriesSaga(action: ReturnType<typeof fetchQuestionCategoriesAsync.request>): Generator {
	try {
		const fetchRes: FetchQuestionCategoriesResponse = (yield call(fetchQuestionCategories)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchQuestionCategoriesAsync.success(fetchRes.data));
		} else {
			yield put(fetchQuestionCategoriesAsync.failure('MANAGE - FETCH QUESTION CATEGORIES FAILED'));
		}
	} catch (e) {
		yield put(fetchQuestionCategoriesAsync.failure('MANAGE - FETCH QUESTION CATEGORIES FAILED'));
	}
}
function* upsertQuestionnaireSaga(action: ReturnType<typeof upsertQuestionnaireAsync.request>): Generator {
	try {
		const fetchRes: UpsertQuestionnaireResponse = (yield call(upsertQuestionnaire, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(upsertQuestionnaireAsync.success(fetchRes.data));
		} else {
			if (fetchRes?.data?.message) {
				yield put(upsertQuestionnaireAsync.failure(fetchRes?.data?.message));
			} else {
				yield put(upsertQuestionnaireAsync.failure('FAILED TO UPDATE QUESTIONNAIRE'));
			}
		}
	} catch (e) {
		yield put(upsertQuestionnaireAsync.failure('FAILED TO UPDATE QUESTIONNAIRE'));
	}
}

function* createUserSaga(action: ReturnType<typeof createUserAsync.request>): Generator {
	try {
		const fetchRes: UpsertUserResponse = (yield call(createUser, action.payload)) as any;
		if (fetchRes.message === 'SUCCESS') {
			yield put(createUserAsync.success(fetchRes.data));
		} else {
			yield put(createUserAsync.failure(fetchRes.error || 'MANAGE - CREATE USER FAILED'));
		}
	} catch (e) {
		yield put(createUserAsync.failure('MANAGE - CREATE USER FAILED'));
	}
}
function* updateUserSaga(action: ReturnType<typeof updateUserAsync.request>): Generator {
	try {
		const fetchRes: UpsertUserResponse = (yield call(updateUser, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(updateUserAsync.success(action.payload.email));
		} else {
			yield put(updateUserAsync.failure(fetchRes.error || 'MANAGE - UPDATE USER FAILED'));
		}
	} catch (e) {
		yield put(updateUserAsync.failure('MANAGE - UPDATE SITE FAILED'));
	}
}

export default function* adminManageSagaWatcher() {
	yield takeLatest(CREATE_SITE_REQUEST, createSiteSaga);
	yield takeLatest(UPDATE_SITE_REQUEST, updateSiteSaga);
	yield takeLatest(TOGGLE_BLOCK_SITE_REQUEST, blockSiteSaga);
	yield takeLatest(CHECK_SITE_SCHEDULE_COUNT_REQUEST, checkSiteSchedulesSaga);
	yield takeLatest(FETCH_QUESTION_CATEGORIES_REQUEST, fetchQuestionCategoriesSaga);
	yield takeLatest(UPSERT_QUESTIONNAIRE_REQUEST, upsertQuestionnaireSaga);
	yield takeLatest(UPDATE_USER_REQUEST, updateUserSaga);
	yield takeLatest(CREATE_USER_REQUEST, createUserSaga);
}
