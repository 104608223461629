import { ActionType, createReducer } from 'typesafe-actions';
import { SkusCompactFetchState, SkusCompactState } from './type.d';
import update from 'immutability-helper';
import * as actions from './skusCompact.action';
import * as types from './skusCompact.actionTypes';

export type SkusCompactAction = ActionType<typeof actions>;

export const initialState: SkusCompactState = {
	error: '',
	fetchState: SkusCompactFetchState.SUCCESS,
	skusCompactWithCategory: [],
	skusCompact: [],
};

export const skusCompactReducer = createReducer<SkusCompactState, SkusCompactAction>(initialState)
	.handleType(types.FETCH_SKUS_COMPACT_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: SkusCompactFetchState.LOADING },
			skusCompactWithCategory: { $set: initialState.skusCompactWithCategory },
			skusCompact: { $set: initialState.skusCompact },
		}),
	)
	.handleType(types.FETCH_SKUS_COMPACT_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: SkusCompactFetchState.SUCCESS },
			skusCompactWithCategory: { $set: action.payload.skusCompactWithCategory },
			skusCompact: { $set: action.payload.skusCompact },
		}),
	)
	.handleType(types.FETCH_SKUS_COMPACT_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: SkusCompactFetchState.ERROR },
			skusCompactWithCategory: { $set: initialState.skusCompactWithCategory },
			skusCompact: { $set: initialState.skusCompact },
		}),
	);
