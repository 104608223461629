import { call, put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_AUDITORS_DAILY_CHART_REQUEST,
  FetchAuditorsDailyChartResponse
} from 'reducers/dashboard/auditors/dailyChart/types';
import {
  FETCH_AUDITORS_LIST_REQUEST,
  DOWNLOAD_AUDITORS_LIST_REQUEST,
  FetchAuditorsListResponse
} from 'reducers/dashboard/auditors/auditorsList/types';
import {
  FETCH_MANY_USERS_CHART_REQUEST,
  FetchManyUsersChartResponse
} from 'reducers/dashboard/auditors/manyUsersChart/types';
import {
  FETCH_SINGLE_USER_CHART_REQUEST,
  FetchSingleUserChartResponse
} from 'reducers/dashboard/auditors/singleUserChart/types';
import { fetchAuditorsDailyChartAsync } from 'reducers/dashboard/auditors/dailyChart/action';
import { fetchAuditorsListAsync, downloadAuditorsListAsync } from 'reducers/dashboard/auditors/auditorsList/action';
import { fetchManyUsersChartAsync } from 'reducers/dashboard/auditors/manyUsersChart/action';
import { fetchSingleUserChartAsync } from 'reducers/dashboard/auditors/singleUserChart/action';
import Monitoring from '../../utils/monitoring/Monitoring';
import {
  fetchAuditorsList,
  fetchDailyChart,
  fetchManyUsersChart,
  fetchSingleUserChart,
  downloadAuditorsList,
  DownloadAuditorsListResponse
} from './helpers/auditors';
/**
 *
 * @param state TODO: Separate selector
 */

export function* fetchDailyChartSaga(action: ReturnType<typeof fetchAuditorsDailyChartAsync.request>): Generator {
  try {
    const fetchRes: FetchAuditorsDailyChartResponse = (yield call(fetchDailyChart, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchAuditorsDailyChartAsync.success(fetchRes.data));
    } else {
      yield put(fetchAuditorsDailyChartAsync.failure('FETCH AUDITORS DAILY CHART FAILED'));
    }
  } catch (e) {
    Monitoring.logEvent('fetchDailyChartSaga', e.message);
    yield put(fetchAuditorsDailyChartAsync.failure('FETCH DAILY CHART FAILED'));
  }
}

export function* fetchAuditorsListSaga(action: ReturnType<typeof fetchAuditorsListAsync.request>): Generator {
  try {
    const fetchRes: FetchAuditorsListResponse = (yield call(fetchAuditorsList, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchAuditorsListAsync.success(fetchRes.data));
    } else {
      yield put(fetchAuditorsListAsync.failure('FETCH FLAG CARDS FAILED'));
    }
  } catch (e) {
    Monitoring.logEvent('fetchAuditorsListSaga', e.message);
    yield put(fetchAuditorsListAsync.failure('FETCH FLAG CARDS FAILED'));
  }
}

export function* fetchManyUsersChartSaga(action: ReturnType<typeof fetchManyUsersChartAsync.request>): Generator {
  try {
    const fetchRes: FetchManyUsersChartResponse = (yield call(fetchManyUsersChart, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchManyUsersChartAsync.success(fetchRes.data));
    } else {
      yield put(fetchManyUsersChartAsync.failure('FETCH MANY USERS CHART FAILED'));
    }
  } catch (e) {
    Monitoring.logEvent('fetchManyUsersChartSaga', e.message);
    yield put(fetchManyUsersChartAsync.failure('FETCH MANY USERS CHART FAILED'));
  }
}

export function* fetchSingleUserChartSaga(action: ReturnType<typeof fetchSingleUserChartAsync.request>): Generator {
  try {
    const fetchRes: FetchSingleUserChartResponse = (yield call(fetchSingleUserChart, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchSingleUserChartAsync.success(fetchRes.data));
    } else {
      yield put(fetchSingleUserChartAsync.failure('FETCH SINGLE USER CHART FAILED'));
    }
  } catch (e) {
    Monitoring.logEvent('fetchSingleUserChartSaga', e.message);
    yield put(fetchSingleUserChartAsync.failure('FETCH SINGLE USER CHART FAILED'));
  }
}

export function* downloadAuditorsListSaga(action: ReturnType<typeof downloadAuditorsListAsync.request>): Generator {
  try {
    const fetchRes: DownloadAuditorsListResponse = (yield call(downloadAuditorsList, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(downloadAuditorsListAsync.success(fetchRes.url));
    } else {
      yield put(downloadAuditorsListAsync.failure('DOWNLOAD AUDITORS LIST FAILED'));
    }
  } catch (e) {
    Monitoring.logEvent('downloadAuditorsListSaga', e.message);
    yield put(downloadAuditorsListAsync.failure('DOWNLOAD AUDITORS LIST FAILED'));
  }
}

export default function* overviewSagaWatcher() {
  yield takeLatest(FETCH_AUDITORS_DAILY_CHART_REQUEST, fetchDailyChartSaga);
  yield takeLatest(FETCH_AUDITORS_LIST_REQUEST, fetchAuditorsListSaga);
  yield takeLatest(FETCH_MANY_USERS_CHART_REQUEST, fetchManyUsersChartSaga);
  yield takeLatest(FETCH_SINGLE_USER_CHART_REQUEST, fetchSingleUserChartSaga);
  yield takeLatest(DOWNLOAD_AUDITORS_LIST_REQUEST, downloadAuditorsListSaga);
}
