import React from 'react';
import { AuthContainerProps } from './AuthContainer';

export type AuthHeadlineProps = Omit<
	AuthContainerProps,
	| 'loginCredentials'
	| 'forgotPasswordData'
	| 'isLoading'
	| 'siteFlashMessage'
	| 'handleSubmitForm'
	| 'handleChangeEmail'
	| 'handleChangePassword'
	| 'handleChangeForgotPass'
	| 'handleToggleForgotPass'
	| 'isFederated'
	| 'validationMessage'
	| 'isRequestAccess'
	| 'handleRequestAccess'
	| 'isCheckingEmail'
	| 'isEmailValid'
	| 'isRequestSuccess'
	| 'isRequestFailed'
	| 'isAuthenticating'
	| 'isMultipleRequest'
	| 'handleDismissRequestSuccess'
>;

export const AuthHeadline = (props: AuthHeadlineProps) => {
	const renderHeadline1 = () => {
		return !props.modeForgot ? (
			<div className="fade-in">
				<span data-testid="header-title">Log in</span>
			</div>
		) : (
			<span className="fade-in" data-testid="header-title">
				Forgot <br />
				password ?
			</span>
		);
	};
	const renderHeadline2 = () => {
		return !props.modeForgot ? (
			<div>
				<span className="fade-in">Welcome back. If you don’t have an account yet, contact our team for a demo.</span>
			</div>
		) : (
			<span className="fade-in">
				It happens to the best of us! We’ll email you instructions to reset your password.
			</span>
		);
	};
	return (
		<div>
			<div className="logo">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/sustainnovation-audit.appspot.com/o/assets%2Flogo%2FNimbly%20Logo.svg?alt=media&token=b04baf81-9fe6-4527-9c77-94b3a71fe454"
					alt="Nimbly logo"
				/>
			</div>
			<div className="greeting"> Hello.</div>
			<div className="login">{renderHeadline1()}</div>
			<div className="description">{renderHeadline2()}</div>
		</div>
	);
};

export default AuthHeadline;
