import { QuestionnaireIndex } from 'nimbly-common';
import { takeLatest, put, call } from 'redux-saga/effects';

import { apiURL } from 'config/baseURL';
import { QuestionnaireIndexes } from 'reducers/questionnaireIndex/type';
import * as types from 'reducers/questionnaireIndex/questionnaireIndex.actionType';
import * as actions from 'reducers/questionnaireIndex/questionnaireIndex.action';
import { getToken } from 'reducers/api';
import { checkExpiredStorageItem, setToSession } from 'helpers/sessionStorageHelper';

const QUESTIONNAIRE_INDEX = 'QUESTIONNAIRE_INDEX';
export function* fetchQuestionnaireIndex() {
  try {
    yield put(actions.setLoading(true));
    const token = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        authorization: token
      }
    };
    const questionnaireIndex = checkExpiredStorageItem<any>(QUESTIONNAIRE_INDEX);
    if (!questionnaireIndex) {
      const url = `${apiURL}/questionnaires/questionnaireIndexes`;
      const request = () => fetch(url, options);
      const response = yield call(request);

      if (response && response.status === 200) {
        const result = yield response.json();
        setToSession(result, QUESTIONNAIRE_INDEX);
        const mappedQuestionnaireIndex: QuestionnaireIndexes = {};

        result.data.forEach((questionnaireIdx: QuestionnaireIndex, i: number) => {
          const { questionnaireIndexID } = questionnaireIdx;
          if (!mappedQuestionnaireIndex.hasOwnProperty(questionnaireIndexID)) {
            mappedQuestionnaireIndex[questionnaireIndexID] = questionnaireIdx;
          }
        });
        yield put(actions.fetchQuestionnaireIndex.success({ data: mappedQuestionnaireIndex }));
        return null;
      }
      const result = yield response.json();
      yield put(actions.fetchQuestionnaireIndex.failure({ error: result.message }));
      return null;
    } else {
      const result = questionnaireIndex;
      const mappedQuestionnaireIndex: QuestionnaireIndexes = {};

      result.data.forEach((questionnaireIdx: QuestionnaireIndex, i: number) => {
        const { questionnaireIndexID } = questionnaireIdx;
        if (!mappedQuestionnaireIndex.hasOwnProperty(questionnaireIndexID)) {
          mappedQuestionnaireIndex[questionnaireIndexID] = questionnaireIdx;
        }
      });
      yield put(actions.fetchQuestionnaireIndex.success({ data: mappedQuestionnaireIndex }));
      return null;
    }
  } catch (err) {
    yield put(actions.fetchQuestionnaireIndex.success({ data: null }));
    yield put(actions.fetchQuestionnaireIndex.failure({ error: '' }));
    return null;
  }
}

export function* fetchQuestionnaireProfileOnly() {
  try {
    yield put(actions.setLoading(true));
    const token = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        authorization: token
      }
    };
    const url = `${apiURL}/questionnaires/questionnaireIndexes/profile-only`;
    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const result = yield response.json();
      setToSession(result, QUESTIONNAIRE_INDEX);

      yield put(actions.fetchQuestionnaireProfileOnly.success({ data: result.data }));
      return null;
    }
    return null;
  } catch (err) {
    yield put(actions.fetchQuestionnaireProfileOnly.success({ data: null }));
    yield put(actions.fetchQuestionnaireProfileOnly.failure({ error: err }));
    return null;
  }
}

export default function* questionnaireIndexSaga() {
  yield takeLatest(types.FETCH_QUESTIONNAIRE_INDEXES_REQUEST, fetchQuestionnaireIndex);
  yield takeLatest(types.FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_REQUEST, fetchQuestionnaireProfileOnly);
}
