import {
  ExportRequestDemoListResponse,
  ExportRequestDemoListPayload
} from 'reducers/superadmin/reqForDemo/requestDemoList/types';
import { getToken } from 'reducers/api';
import { apiURL } from 'config/baseURL';

export const exportRequestDemoList = async (
  payload: ExportRequestDemoListPayload
): Promise<ExportRequestDemoListResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };

    const url = `${apiURL}/miscellaneous/demo-requests/export?startDate=${payload.startDate}&endDate=${payload.endDate}`;
    const res = await fetch(url, options);

    const blob = await res.blob();
    const blobURL = window.URL.createObjectURL(blob);

    const exportDemoListResult: ExportRequestDemoListResponse = {
      message: 'SUCCESS',
      url: blobURL
    };
    return exportDemoListResult;
  } catch {
    const errorResult: ExportRequestDemoListResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
