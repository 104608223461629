export enum LmsCoursesDataState {
	LOADING,
	SUCCESS,
	FAILURE,
}

export interface LmsCoursesState {
	dataState: LmsCoursesDataState;
	lmsCourses: SingleCourse[];
	errorMessage?: string;
}

export interface SingleCourse {
	_id: string;
	courseID: string;
	courseName: string;
	courseDescription: string;
	organizationID: string;
	syllabusIDs: string[];
	status: Status;
	courseCode: string;
	courseImageID: string;
	availableFrom: number;
	availableUntil: number;
	enrolledUserIDs: string[];
	enrolledUserGroupIDs: string[];
	publishedAt: number;
	files: File[];
	chapters?: Chapter[];
	createdAt: number;
	createdBy: string;
	updatedAt: number;
	updatedBy: string;
	hasExpiryDates?: boolean;
	hasPrerequisiteCourse?: boolean;
	prerequisiteCourses?: any[];
	coursePrivacy?: string;
	containChapters?: boolean;
	__v?: number;
}

export interface Chapter {
	chapterID: string;
	chapterName: string;
	files: File[];
}

export interface File {
	fileID: string;
	fileMetadataID: string;
	fileName: string;
	customFileName: string;
	sourceType: SourceType;
	fileType: FileType;
	value: string;
}

export enum FileType {
	Document = 'document',
	Image = 'image',
	Video = 'video',
}

export enum SourceType {
	File = 'file',
	URL = 'url',
}

export enum Status {
	Active = 'active',
	Draft = 'draft',
	Inactive = 'inactive',
}
