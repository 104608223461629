import styled from 'styled-components';
import { neutral } from '../../../styles';

export const Container = styled.div`
	a,
	.nav-item {
		padding-bottom: 0;

		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 12px;
		color: #c4c4c4;
		text-decoration: none !important;
		text-align: center;

		border-bottom: 0 solid transparent;

		transition: all 0.2s ease-in-out;

		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;

		img {
			display: block;
			filter: grayscale(100%) brightness(1.55);
		}
	}

	.nav-item.active,
	a.active {
		color: #535353;

		border-bottom-color: transparent;
		font-weight: normal;

		img {
			filter: none;
		}
	}

	.nav-item.disabled {
		cursor: not-allowed;

		img {
			filter: grayscale(100%) brightness(2) opacity(0.5);
		}
	}

	.tooltip-container {
		z-index: 10020;
		max-width: 275px;

		border: 0;
		border-radius: 0.3125rem;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
		padding: 0.375rem 1rem;

		font-weight: 400;
		font-size: 0.75rem;
		line-height: 1.125rem;
		color: ${neutral[800]};
	}

	@media (min-width: 992px) {
		.nav-item,
		a {
			padding-bottom: 0.25rem;
			color: #ffffff;
			font-size: 15px;
			line-height: 22px;
			flex: none;

			border-bottom: 2px solid transparent;

			img {
				display: none;
			}
		}

		.nav-item.active,
		a.active {
			color: #ffffff;
			border-bottom-color: #f6bb42;
			font-weight: bold;
		}

		.nav-item:hover,
		a:hover {
			border-bottom-color: #f6bb42;
		}
	}
`;
