import { AttachmentGallery, FilterAttachmentsResult } from '@nimbly-technologies/nimbly-common';
import { apiURLAlt, GALLERY_API_URL, GALLERY_DOWNLOAD_URL } from 'config/baseURL';
import API from 'helpers/api';
import { SearchAttachmentResult } from 'reducers/gallery/type.d';

export const getData = async (url: string): Promise<any> => {
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw new Error(json.message);
	}
	return json.data;
};

export const fetchGalleryAttachments = async (queryParams: string): Promise<FilterAttachmentsResult> => {
	const url = `${GALLERY_API_URL}/filter?${queryParams}`;
	return await getData(url);
};

export const fetchGalleryAttachmentById = async (id: string): Promise<AttachmentGallery> => {
	const url = `${GALLERY_API_URL}/${id}`;
	return await getData(url);
};

export const searchGalleryAttachment = async (queryParams: string): Promise<SearchAttachmentResult> => {
	const url = `${GALLERY_API_URL}/search?${queryParams}`;
	return await getData(url);
};

export const getJwtToken = async (): Promise<string> => {
	const token = await API.getFirebaseToken();
	const url = `${apiURLAlt}/auth/retrieve-jwt?token=${token}`;
	return await getData(url);
};

export const downloadGalleryAttachment = async (queryParams: string, token: string): Promise<any> => {
	const url = `${GALLERY_DOWNLOAD_URL}?${queryParams}`;
	const response = await fetch(url, { headers: { Authorization: token } });

	if (response.status !== 200) {
		const json = await response.json();
		throw new Error(json.message);
	}

	const responseBlob = await response.blob();
	return responseBlob;
};
