import React from 'react';
import { ProgressBoardContainer, Container, Circle, MobileContainer } from './ProgressBoardStyle';
import { useTranslation } from 'react-i18next';

type ProgressBoardProps = {
  tutorialCurrentProgress: string;
  tutorialCumulative: any;
};
const ProgressBoard = (props: ProgressBoardProps) => {
  const { t } = useTranslation();
  const { tutorialCurrentProgress, tutorialCumulative } = props;
  return (
    <ProgressBoardContainer data-testid="progressBoard">
      <Container done={tutorialCumulative['WELCOME']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle current={!tutorialCumulative['WELCOME']} done={tutorialCumulative['WELCOME']}>
            {tutorialCumulative['WELCOME'] ? <div className="checkmark">L</div> : null}
          </Circle>
          <span>{t('message.onBoarding.progressBoard.welcome')}</span>
        </div>
      </Container>
      <Container done={tutorialCumulative['DEPARTMENTS']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['WELCOME'] && !tutorialCumulative['USER_ROLES'] && !tutorialCumulative['DEPARTMENTS']
            }
            done={tutorialCumulative['DEPARTMENTS']}
          >
            {tutorialCumulative['DEPARTMENTS'] ? <div className="checkmark">L</div> : null}
          </Circle>
          <span>{t('message.onBoarding.progressBoard.departments')}</span>
        </div>
      </Container>
      <Container done={tutorialCumulative['USER_ROLES']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['DEPARTMENTS'] && !tutorialCumulative['ADD_USERS'] && !tutorialCumulative['USER_ROLES']
            }
            done={tutorialCumulative['USER_ROLES']}
          >
            {tutorialCumulative['USER_ROLES'] ? <div className="checkmark">L</div> : null}
          </Circle>
          <span>{t('message.onBoarding.progressBoard.userRoles')}</span>
        </div>
      </Container>
      <Container done={tutorialCumulative['ADD_USERS']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['USER_ROLES'] &&
              !tutorialCumulative['SETUP_QUESTIONNAIRE'] &&
              !tutorialCumulative['ADD_USERS']
            }
            done={tutorialCumulative['ADD_USERS']}
          >
            {tutorialCumulative['ADD_USERS'] ? <div className="checkmark">L</div> : null}
          </Circle>
          <span>{t('message.onBoarding.progressBoard.users')}</span>
        </div>
      </Container>
      <Container done={tutorialCumulative['SETUP_QUESTIONNAIRE']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['ADD_USERS'] &&
              !tutorialCumulative['ADD_A_SITE'] &&
              !tutorialCumulative['SETUP_QUESTIONNAIRE']
            }
            done={tutorialCumulative['SETUP_QUESTIONNAIRE']}
          >
            {tutorialCumulative['SETUP_QUESTIONNAIRE'] ? <div className="checkmark">L</div> : null}
          </Circle>
          <span>{t('message.onBoarding.progressBoard.questionnaire')}</span>
        </div>
      </Container>
      <Container done={tutorialCumulative['ADD_A_SITE']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['SETUP_QUESTIONNAIRE'] &&
              !tutorialCumulative['SCHEDULE_REPORT'] &&
              !tutorialCumulative['ADD_A_SITE']
            }
            done={tutorialCumulative['ADD_A_SITE']}
          >
            {tutorialCumulative['ADD_A_SITE'] ? <div className="checkmark">L</div> : null}
          </Circle>
          <span>{t('message.onBoarding.progressBoard.site')}</span>
        </div>
      </Container>
      <Container done={tutorialCumulative['SCHEDULE_REPORT']}>
        <div className={'left-container'}>
          <Circle
            current={!tutorialCumulative['SCHEDULE_REPORT'] && tutorialCumulative['ADD_A_SITE']}
            done={tutorialCumulative['SCHEDULE_REPORT']}
          >
            {tutorialCumulative['SCHEDULE_REPORT'] ? <div className="checkmark">L</div> : null}
          </Circle>
          <span>{t('message.onBoarding.progressBoard.schedule')}</span>
        </div>
      </Container>
      <MobileContainer done={tutorialCumulative['WELCOME']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle current={!tutorialCumulative['WELCOME']} done={tutorialCumulative['WELCOME']}></Circle>
          {!tutorialCumulative['WELCOME'] ? <span>{t('message.onBoarding.progressBoard.welcome')}</span> : null}
        </div>
      </MobileContainer>
      <MobileContainer done={tutorialCumulative['DEPARTMENTS']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['WELCOME'] && !tutorialCumulative['USER_ROLES'] && !tutorialCumulative['DEPARTMENTS']
            }
            done={tutorialCumulative['DEPARTMENTS']}
          ></Circle>
          {tutorialCumulative['WELCOME'] && !tutorialCumulative['USER_ROLES'] && !tutorialCumulative['DEPARTMENTS'] ? (
            <span>{t('message.onBoarding.progressBoard.departments')}</span>
          ) : null}
        </div>
      </MobileContainer>
      <MobileContainer done={tutorialCumulative['USER_ROLES']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['DEPARTMENTS'] && !tutorialCumulative['ADD_USERS'] && !tutorialCumulative['USER_ROLES']
            }
            done={tutorialCumulative['USER_ROLES']}
          ></Circle>
          {tutorialCumulative['DEPARTMENTS'] &&
          !tutorialCumulative['ADD_USERS'] &&
          !tutorialCumulative['USER_ROLES'] ? (
            <span>{t('message.onBoarding.progressBoard.userRoles')}</span>
          ) : null}
        </div>
      </MobileContainer>
      <MobileContainer done={tutorialCumulative['ADD_USERS']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['USER_ROLES'] &&
              !tutorialCumulative['SETUP_QUESTIONNAIRE'] &&
              !tutorialCumulative['ADD_USERS']
            }
            done={tutorialCumulative['ADD_USERS']}
          ></Circle>
          {tutorialCumulative['USER_ROLES'] &&
          !tutorialCumulative['SETUP_QUESTIONNAIRE'] &&
          !tutorialCumulative['ADD_USERS'] ? (
            <span>{t('message.onBoarding.progressBoard.users')}</span>
          ) : null}
        </div>
      </MobileContainer>
      <MobileContainer done={tutorialCumulative['SETUP_QUESTIONNAIRE']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['ADD_USERS'] &&
              !tutorialCumulative['ADD_A_SITE'] &&
              !tutorialCumulative['SETUP_QUESTIONNAIRE']
            }
            done={tutorialCumulative['SETUP_QUESTIONNAIRE']}
          ></Circle>
          {tutorialCumulative['ADD_USERS'] &&
          !tutorialCumulative['ADD_A_SITE'] &&
          !tutorialCumulative['SETUP_QUESTIONNAIRE'] ? (
            <span>{t('message.onBoarding.progressBoard.questionnaire')}</span>
          ) : null}
        </div>
      </MobileContainer>
      <MobileContainer done={tutorialCumulative['ADD_A_SITE']}>
        <div className={'left-container'}>
          <div className="connector" />
          <Circle
            current={
              tutorialCumulative['SETUP_QUESTIONNAIRE'] &&
              !tutorialCumulative['SCHEDULE_REPORT'] &&
              !tutorialCumulative['ADD_A_SITE']
            }
            done={tutorialCumulative['ADD_A_SITE']}
          ></Circle>
          {tutorialCumulative['SETUP_QUESTIONNAIRE'] &&
          !tutorialCumulative['SCHEDULE_REPORT'] &&
          !tutorialCumulative['ADD_A_SITE'] ? (
            <span>{t('message.onBoarding.progressBoard.site')}</span>
          ) : null}
        </div>
      </MobileContainer>
      <MobileContainer done={tutorialCumulative['SCHEDULE_REPORT']}>
        <div className={'left-container'}>
          <Circle
            current={!tutorialCumulative['SCHEDULE_REPORT'] && tutorialCumulative['ADD_A_SITE']}
            done={tutorialCumulative['SCHEDULE_REPORT']}
          ></Circle>
          {!tutorialCumulative['SCHEDULE_REPORT'] && tutorialCumulative['ADD_A_SITE'] ? (
            <span>{t('message.onBoarding.progressBoard.schedule')}</span>
          ) : null}
        </div>
      </MobileContainer>
    </ProgressBoardContainer>
  );
};

export default ProgressBoard;
