import { createAction, createAsyncAction } from 'typesafe-actions';
import {
	OperationalFilters,
	IssueOverviewDetail,
	LastMonthIssueOverviewDetail,
	IssueChartData,
	ReportChartData,
	DefaultChartData,
	TrendsChartIssueList,
	TrendsChartReportList,
} from './operationalStore';
import * as types from './operational.actionTypes';
import { GetCompleteReportDetail } from '@nimbly-technologies/nimbly-common';
import { DashboardPeriodEnum, GroupByEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export const setGroupBy = createAction(types.SET_GROUP_BY, (groupBy: GroupByEnum | string) => ({ groupBy }))();
export const setPeriod = createAction(types.SET_PERIOD, (period: DashboardPeriodEnum | string) => ({ period }))();
export const setFilters = createAction(types.SET_FILTERS, (filters: Partial<OperationalFilters>) => ({ filters }))();
export const clearFilters = () => createAction(types.SET_FILTERS, (filters: Partial<OperationalFilters>) => ({}))();
export const setTopChartData = createAction(types.SET_TOP_CHART_DATA, ({ labels, data }: DefaultChartData) => ({
	labels,
	data,
}))();
export const setTopChartIsLoading = createAction(types.SET_TOP_CHART_IS_LOADING, (isLoading: boolean) => ({
	isLoading,
}))();

export const setTrendsChartIsLoading = createAction(types.SET_TRENDS_CHART_IS_LOADING, (isLoading: boolean) => ({
	isLoading,
}))();
export const setReportDownloadViewIsLoading = createAction(
	types.SET_REPORT_DOWNLOAD_VIEW_IS_LOADING,
	(isLoading: boolean) => ({
		isLoading,
	}),
)();
export const setReportDownloadGroupByFieldNames = createAction(
	types.SET_REPORT_DOWNLOAD_VIEW_GROUP_BY_FIELD_NAMES,
	(groupBy?: GroupByEnum | string, fieldNames?: string[]) => ({ fieldNames }),
)();

export const setReportDownloadViewTrendsFieldNames = createAction(
	types.SET_REPORT_DOWNLOAD_VIEW_TRENDS_FIELD_NAMES,
	(fieldNames: string[]) => ({ fieldNames }),
)();
export const setReportDownloadViewData = createAction(types.SET_REPORT_DOWNLOAD_VIEW_DATA, (data: any[]) => ({
	data,
}))();
export const setReportDownloadViewTrendsData = createAction(
	types.SET_REPORT_DOWNLOAD_VIEW_TENDS_DATA,
	(data: any[]) => ({
		data,
	}),
)();
export const setTrendsChartIssueData = createAction(
	types.SET_TRENDS_CHART_ISSUE_DATA,
	({
		labels,
		resolvedIssuesList,
		openedIssuesList,
		inProgressIssuesList,
		inReviewIssuesList,
		blockedIssuesList,
		totalIssuesList,
	}: TrendsChartIssueList) => ({
		labels,
		resolvedIssuesList,
		openedIssuesList,
		inProgressIssuesList,
		inReviewIssuesList,
		blockedIssuesList,
		totalIssuesList,
	}),
)();
export const setTrendsChartReportData = createAction(
	types.SET_TRENDS_CHART_REPORT_DATA,
	({ labels, completedReportList, incompleteReportList, totalReportList }: TrendsChartReportList) => ({
		labels,
		completedReportList,
		incompleteReportList,
		totalReportList,
	}),
)();

export const fetchCompleteReportDetailAsync = createAsyncAction(
	types.FETCH_COMPLETE_REPORT_DETAIL_REQUEST,
	types.FETCH_COMPLETE_REPORT_DETAIL_SUCCESS,
	types.FETCH_COMPLETE_REPORT_DETAIL_FAILURE,
)<undefined, GetCompleteReportDetail, string>();

export const fetchIssueOverviewDetailAsync = createAsyncAction(
	types.FETCH_ISSUE_OVERVIEW_DETAIL_REQUEST,
	types.FETCH_ISSUE_OVERVIEW_DETAIL_SUCCESS,
	types.FETCH_ISSUE_OVERVIEW_DETAIL_FAILURE,
)<undefined, IssueOverviewDetail, string>();

export const fetchLastMonthIssueOverviewDetailAsync = createAsyncAction(
	types.FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_REQUEST,
	types.FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_SUCCESS,
	types.FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_FAILURE,
)<undefined, LastMonthIssueOverviewDetail, string>();

export const fetchIssueChartAsync = createAsyncAction(
	types.FETCH_ISSUE_CHART_REQUEST,
	types.FETCH_ISSUE_CHART_SUCCESS,
	types.FETCH_ISSUE_CHART_FAILURE,
)<undefined, IssueChartData[], string>();

export const fetchReportChartAsync = createAsyncAction(
	types.FETCH_REPORT_CHART_REQUEST,
	types.FETCH_REPORT_CHART_SUCCESS,
	types.FETCH_REPORT_CHART_FAILURE,
)<undefined, ReportChartData[], string>();
