import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/storage';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { getFirebase } from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { createFirestoreInstance, reduxFirestore } from 'redux-firestore';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { ThunkExtra } from 'types/app';
import { fbConfig, reduxfbConfig } from '../config/firebase';
import { makeRootReducer } from './rootReducers';
import { rootSaga } from './rootSaga';

// Initialize Firebase
if (!firebase.apps.length) {
	firebase.initializeApp(fbConfig);
	firebase.firestore!();
}

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();
history.listen((location) => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

const db = firebase.database!();

const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const routeMiddleware = routerMiddleware(history);

const argFb = { getFirebase, db };

// Sentry middleware
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// Combine middleware
const middlewares = [routeMiddleware, sagaMiddleware, thunkMiddleware.withExtraArgument<ThunkExtra>(argFb as any)];

// Create enhancers.
const enhancers = [sentryReduxEnhancer];
if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
	if (typeof window === 'object' && typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

const store = createStore(
	makeRootReducer(history),
	compose(reduxFirestore(firebase), applyMiddleware(...middlewares), ...enhancers),
);

const rrfProps = {
	firebase,
	config: reduxfbConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
};

/**
 * SAGA MIDDLEWARE
 */
sagaMiddleware.run(rootSaga);

if (module.hot) {
	module.hot.accept('./rootReducers', () => {
		// eslint-disable-next-line @typescript-eslint/no-require-imports
		const nextRootReducer = require('./rootReducers').default;
		store.replaceReducer(nextRootReducer);
	});
}

const firestore = firebase.firestore!;
const auth = firebase.auth!;
const firebaseFunction = firebase.functions!;

export { history, store, db, firestore, auth, firebaseFunction, rrfProps };
