export const SET_DASHBOARD_TYPE = '@EXECUTIVE/SET_DASHBOARD_TYPE';
export const SET_SHOW_MAP = '@EXECUTIVE/SET_SHOW_MAP';
export const SET_SHOW_FILTERS = '@EXECUTIVE/SET_SHOW_FILTERS';

export const SET_FILTER_DATE = '@EXECUTIVE/SET_FILTER_DATE';
export const SET_FILTERS = '@EXECUTIVE/SET_FILTERS';
export const SET_MULTI_SELECT = '@EXECUTIVE/SET_MULTI_SELECT';
export const SET_SEARCH_TRIGGER = '@EXECUTIVE/SET_SEARCH_TRIGGER';
export const SET_RESET_FILTER = '@EXECUTIVE/SET_RESET_FILTER';

export const SET_LAST_UPDATE = '@EXECUTIVE/SET_LAST_UPDATE';
export const SET_LAST_SELECTED_WIDGET = '@EXECUTIVE/SET_LAST_SELECTED_WIDGET';

export const FETCH_DEPARTMENT_RATIO_REQUEST = '@EXECUTIVE/FETCH_DEPARTMENT_RATIO_REQUEST';
export const FETCH_DEPARTMENT_RATIO_SUCCESS = '@REPORTSHUB/FETCH_DEPARTMENT_RATIO_SUCCESS';
export const FETCH_DEPARTMENT_RATIO_FAILURE = '@REPORTSHUB/FETCH_DEPARTMENT_RATIO_FAILURE';

export const FETCH_IRR_RATIO_REQUEST = '@EXECUTIVE/FETCH_IRR_RATIO_REQUEST';
export const FETCH_IRR_RATIO_SUCCESS = '@REPORTSHUB/FETCH_IRR_RATIO_SUCCESS';
export const FETCH_IRR_RATIO_FAILURE = '@REPORTSHUB/FETCH_IRR_RATIO_FAILURE';

export const FETCH_VOC_RATIO_REQUEST = '@EXECUTIVE/FETCH_VOC_RATIO_REQUEST';
export const FETCH_VOC_RATIO_SUCCESS = '@REPORTSHUB/FETCH_VOC_RATIO_SUCCESS';
export const FETCH_VOC_RATIO_FAILURE = '@REPORTSHUB/FETCH_VOC_RATIO_FAILURE';

export const FETCH_RCR_RATIO_REQUEST = '@EXECUTIVE/FETCH_RCR_RATIO_REQUEST';
export const FETCH_RCR_RATIO_SUCCESS = '@REPORTSHUB/FETCH_RCR_RATIO_SUCCESS';
export const FETCH_RCR_RATIO_FAILURE = '@REPORTSHUB/FETCH_RCR_RATIO_FAILURE';

export const FETCH_HEATMAP_ISSUE_FLAGS_REQUEST = '@EXECUTIVE/FETCH_HEATMAP_ISSUE_FLAGS_REQUEST';
export const FETCH_HEATMAP_ISSUE_FLAGS_SUCCESS = '@EXECUTIVE/FETCH_HEATMAP_ISSUE_FLAGS_SUCCESS';
export const FETCH_HEATMAP_ISSUE_FLAGS_FAILURE = '@EXECUTIVE/FETCH_HEATMAP_ISSUE_FLAGS_FAILURE';
