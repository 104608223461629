import { createAsyncAction } from 'typesafe-actions';
import {
  LiveOrganization,
  FETCH_LIVE_ORGANIZATION_REQUEST,
  FETCH_LIVE_ORGANIZATION_SUCCESS,
  FETCH_LIVE_ORGANIZATION_FAILURE
} from './types';

export const fetchLiveOrganizationAsync = createAsyncAction(
  FETCH_LIVE_ORGANIZATION_REQUEST,
  FETCH_LIVE_ORGANIZATION_SUCCESS,
  FETCH_LIVE_ORGANIZATION_FAILURE
)<undefined, LiveOrganization, string>();
