import styled from 'styled-components/macro';

export const CalendarContainer = styled.div`
	.dropdown-icon {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 25px;
		height: 25px;

		img {
			transition: transform 0.15s ease-out;
			filter: brightness(0) saturate(100%) invert(14%) sepia(14%) saturate(313%) hue-rotate(169deg) brightness(96%)
				contrast(94%);
		}

		&__previous {
			position: absolute;
			top: 21px;
			left: 31px;
			img {
				transform: rotate(180deg);
			}
		}

		&__next {
			position: absolute;
			top: 21px;
			right: 31px;
			img {
				transform: rotate(0deg);
			}
		}
	}

	.CalendarMonth_caption {
		color: #25282b;
		font-size: 1rem;
		line-height: 1.5625rem;
		padding-bottom: 20px;

		strong {
			font-weight: 400;
		}
	}

	.DayPicker__withBorder {
		box-shadow: none;
	}

	.DayPicker_weekHeader {
		top: 52px;
		padding: 0 23px 0 13px !important;
		width: 100%;

		.DayPicker_weekHeader_ul {
			margin: 0;
			display: flex;
			flex-direction: row;
			justify-content: start;
			align-items: center;
		}

		.DayPicker_weekHeader_li {
			flex: 1;
			margin: 0;
			font-weight: 400;
			font-size: 0.625rem;
			line-height: 0.9375rem;
			color: #8f8f8f;
			padding-bottom: 0;
			width: unset !important;

			small {
				font-size: 100%;
			}
		}
	}

	.DayPicker {
		.CalendarDay {
			border: 0;
			padding: 2px;

			width: 39px !important;
			height: 39px !important;

			&:hover {
				background: none;
				.custom-day {
					background: #f8f7ff !important;
				}
			}

			.custom-day {
				border: 1px solid #eae9f9;
				border-radius: 4px;
				color: #574fcf;
				font-size: 0.875rem;
				line-height: 1.4375rem;
				font-weight: 400;
				max-width: 35px !important;
				height: 35px !important;
				margin: 0 !important;

				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
			}

			&__selected {
				border: none;
				background: none;
				.custom-day {
					background: #eae9f9 !important;
					&:hover {
						background: #7e78ea !important;
					}
				}
			}

			&__highlighted_calendar {
				border: none;
				background: none;
				.custom-day {
					background: #574fcf !important;
					color: #ffffff;
				}
			}

			&__blocked_calendar {
				border: none;
				background: none;
				cursor: not-allowed;
				pointer-events: none;
				.custom-day {
					border-color: #eae9f9;
					background: #f8f7ff;
					color: #bebbf3;
				}
			}
		}
	}
`;

export const CalendarPickerContainer = styled(CalendarContainer)`
	overflow: hidden;

	& > div {
		margin: -22px -23px -27px -23px;
	}
`;

export const DateInputContainer = styled(CalendarContainer)<{ hasError?: boolean }>`
	.DateInput {
		width: 100%;

		&_input {
			border-radius: 4px;
			border-color: ${({ hasError }) => (hasError ? '#FF5E6F' : '#c4c4c4')};
			outline: ${({ hasError }) => (hasError ? '1px solid #FF5E6F ' : 'none')};

			height: auto;
			padding: 6px 8px;

			&:focus {
				border-color: #7e78ea;
				outline: 1px solid #7e78ea;
				background: #f5f9ff;
			}
		}

		&_fang {
			top: 41px !important;
			z-index: 4;
		}
	}
`;

export const SingleDatePickerContainer = styled(DateInputContainer)`
	.SingleDatePicker {
		width: 100%;

		&Input {
			width: 100%;
		}

		&_picker {
			border: 1px solid #dbdbdb;
			border-radius: 4px;
			top: 50px !important;
			z-index: 3;
		}
	}
`;

export const DateRangePickerContainer = styled(DateInputContainer)`
	.DateRangePickerInput {
		display: flex;
		align-items: center;
		gap: 8px;

		background: none;
	}

	.DateRangePicker {
		&_picker {
			border: 1px solid #dbdbdb;
			border-radius: 4px;
			top: 50px !important;
		}
	}

	.DayPicker {
		&_weekHeader_ul {
			width: 273px;
		}

		.CalendarDay {
			&__selected {
				.custom-day {
					background-color: #574fcf !important;
					color: #ffffff !important;
				}
			}

			&__hovered_span {
				background: none;
				.custom-day {
					background: #eae9f9 !important;
				}
			}

			&__selected_span {
				background: none;

				.custom-day {
					background: #eae9f9;
					color: #574fcf !important;
				}
			}

			&__blocked_out_of_range {
				> div.custom-day {
					background: pink !important;
					color: #574fcf !important;
				}
			}
		}
	}
`;
