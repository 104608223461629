import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from 'reducers/userDept/userDept.action';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';

function* fetchUserDeptCompact({ payload }: ReturnType<typeof actions.userDeptAction.request>) {
	const authToken = yield API.getFirebaseToken();

	const body = {
		withDisabled: payload?.withDisabled || false,
		departmentIDs: payload?.departmentIDs || [],
	};
	const URL = `${apiURL}/users/user-dept`;
	const response = yield call(API.post, URL, authToken, body);
	const { data, message } = yield response.json();
	if (response && response.status === 200) {
		yield put(actions.userDeptAction.success({ data, message }));
	} else {
		yield put(actions.userDeptAction.failure({ message }));
	}
}

export default function* userDeptCompactSaga() {
	yield takeLatest(actions.userDeptAction.request, fetchUserDeptCompact);
}
