import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { ScheduleActionResponse, SiteScheduleIndex } from '@nimbly-technologies/nimbly-common';

export default class ScheduleAPI {
	public static async uploadSiteSchedules(siteID: string, schedules: SiteScheduleIndex[]) {
		const api = new ScheduleAPI();

		const token = await API.getFirebaseToken();

		const url = `${api.scheduleURL}/sites/${siteID}`;

		const response = await API.put(url, token, { schedules });

		const contentType = response.headers.get('content-type');

		// #region response no JSON
		if (!contentType || !contentType.includes('application/json') || response.status >= 500) {
			throw new Error('invalid response json');
		}

		const { data }: { data: ScheduleActionResponse[] } = await response.json();

		const success = response.status >= 200 && response.status < 400;

		return { data, success };
	}

	private scheduleURL: string;
	private constructor() {
		this.scheduleURL = `${apiURL}/schedules`;
	}
}
