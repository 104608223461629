import { createAsyncAction, action } from 'typesafe-actions';
import * as types from './enrollCourseUserGroup.actionTypes';
import { LmsCouseList, SortDirection, UpdateUserGroupEnrollCourse } from './type';

export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });
export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });
export const setCourseListPageIndex = (pageIndex: number) => action(types.SET_COURSE_LIST_PAGE_INDEX, { pageIndex });
export const setCourseListSearchQuery = (search: string) => action(types.SET_COURSE_LIST_SEARCH_QUERY, { search });
export const setCourseListSort = (sortBy: string, sortDirection: SortDirection) =>
	action(types.SET_COURSE_LIST_SORT, { sortBy, sortDirection });
export const resetCourseFilter = () => action(types.RESET_COURSE_FILTER, {});

export const getCourseList = createAsyncAction(
	types.GET_COURSE_LIST_REQUEST,
	types.GET_COURSE_LIST_SUCCESS,
	types.GET_COURSE_LIST_FAILURE,
)<{ limit: number }, LmsCouseList, { error: string }>();
export const updateUserGroupEnrollCourse = createAsyncAction(
	types.UPDATE_USERGROUP_ENROLLCOURSE_REQUEST,
	types.UPDATE_USERGROUP_ENROLLCOURSE_SUCCESS,
	types.UPDATE_USERGROUP_ENROLLCOURSE_FAILURE,
)<{ data: UpdateUserGroupEnrollCourse[] }, { message: string }, { error: string }>();
