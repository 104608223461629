import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from 'reducers/siteGroup/siteGroup.action';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';

function* fetchSiteGroupCompact({ payload }: ReturnType<typeof actions.siteGroupAction.request>) {
	const authToken = yield API.getFirebaseToken();

	const URL = `${apiURL}/sites/group?withDisabled=${payload?.withDisabled || false}&minimal=${
		payload?.minimal || true
	}`;
	const response = yield call(API.get, URL, authToken);
	const { data, message } = yield response.json();
	if (response && response.status === 200) {
		yield put(actions.siteGroupAction.success({ data, message }));
	} else {
		yield put(actions.siteGroupAction.failure({ message }));
	}
}

export default function* siteGroupCompactSaga() {
	yield takeLatest(actions.siteGroupAction.request, fetchSiteGroupCompact);
}
