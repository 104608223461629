import { LMSUserResponse, PaginationResult } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { SingleUser } from 'reducers/lms/userManagement/userList/type';
import { IAPIResponse } from 'types/api';

export const fetchLmsUsers = async (searchParams: string): Promise<PaginationResult<SingleUser>> => {
	const url = `${apiURL}/lms/users?${searchParams}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);

	return res?.data;
};

export const fetchLmsUserById = async (userID: string): Promise<LMSUserResponse | undefined> => {
	const url = `${apiURL}/lms/users/${userID}`;
	const res = await API.getJSON<IAPIResponse<LMSUserResponse>>(url);

	return res?.data;
};
