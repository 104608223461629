import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './lmsDashboard.action';
import {
	IDashboardIndicatorStoreBase,
	ILmsDashboardStore,
	IDashboardCompletionStoreBase,
	IDashboardPassFailRatioStoreBase,
	IDashboardLessonProgressStoreBase,
	IDashboardLessonStatusStoreBase,
	IDashboardSummaryStoreBase,
	IDashboardQuizInSightBase,
	EntityStatusStoreBase,
	IDashboardFilters,
	OverallQuizInsights,
	lmsDashboardOverallStatusBase,
	IDashboardtimeSpentBase,
	LmsDashboardTrendsBase,
	LmsDashboardTopBottomEntitiesBase,
	LmsEntityDetailBase,
	OverallQuizInsightsBase,
} from './lmsDashboard.type';
import moment from 'moment';
import * as types from './lmsDashboard.actionTypes';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from 'pages/lms/lmsDashboardRevampPage/lmsDashboardUtils';

const initialDashboardIndicator: IDashboardIndicatorStoreBase = {
	data: null,
	isLoading: false,
};
const initialDashboardCompletionStats: IDashboardCompletionStoreBase = {
	data: null,
	isLoading: false,
};
const initialDashboardPassFailRatio: IDashboardPassFailRatioStoreBase = {
	data: null,
	isLoading: false,
};
const initialDashboardLessonProgress: IDashboardLessonProgressStoreBase = {
	data: null,
	isLoading: false,
};

const initialDashboardQuizInsight: IDashboardQuizInSightBase = {
	data: null,
	isLoading: false,
};
const initialDashboardLessonStatus: IDashboardLessonStatusStoreBase = {
	data: null,
	isLoading: false,
	startDate: null,
	endDate: null,
};

const initialDashboardSummary: IDashboardSummaryStoreBase = {
	data: null,
	isLoading: false,
	limit: 10,
	page: 1,
	sortFields: '',
	sortDirections: '',
	startDate: null,
	endDate: null,
};

const initialEntityStatus: EntityStatusStoreBase = {
	data: null,
	isLoading: false,
	startDate: null,
	endDate: null,
	entity: 'course',
};

const initDashboardFilters: IDashboardFilters = {
	startDate: DEFAULT_START_DATE,
	endDate: DEFAULT_END_DATE,
	publishedStartDate: DEFAULT_START_DATE,
	publishedEndDate: DEFAULT_END_DATE,
};

const initOverallQuizInsight: OverallQuizInsightsBase = {
	data: null,
	isLoading: false,
};

const initOverallInsight: lmsDashboardOverallStatusBase = {
	data: null,
	isLoading: false,
};

const initTimeSpent: IDashboardtimeSpentBase = {
	data: null,
	isLoading: false,
	totaCount: 0,
	totalPages: 0,
	limit: 5,
	page: 1,
};

const initTrends: LmsDashboardTrendsBase = {
	data: null,
	isLoading: false,
};

const initTopEntities: LmsDashboardTopBottomEntitiesBase = {
	data: null,
	isLoading: false,
};

const initEntityDetails: LmsEntityDetailBase = {
	data: null,
	isLoading: false,
	limit: 5,
	page: 1,
};

const initEnrollmentStats: ILmsDashboardStore['enrollmentStats'] = {
	data: null,
	isLoading: false,
};

const initEntityStatusWiseSplit: ILmsDashboardStore['entityStatusWiseSplit'] = {
	data: null,
	isLoading: false,
};

const initialState: ILmsDashboardStore = {
	dashboardIndicator: initialDashboardIndicator,
	dashboardFilters: initDashboardFilters,
	completionStats: initialDashboardCompletionStats,
	passFailRatio: initialDashboardPassFailRatio,
	lessonProgress: initialDashboardLessonProgress,
	lessonStatus: initialDashboardLessonStatus,
	summary: initialDashboardSummary,
	quizInsight: initialDashboardQuizInsight,
	quizInsightV2: initialDashboardQuizInsight,
	entityStatus: initialEntityStatus,
	overallQuizInsights: initOverallQuizInsight,
	overallInsights: initOverallInsight,
	timeSpent: initTimeSpent,
	trends: initTrends,
	topEntities: initTopEntities,
	entityDetail: initEntityDetails,
	enrollmentStats: initEnrollmentStats,
	entityStatusWiseSplit: initEntityStatusWiseSplit,
};

export type LmsDashboardAction = ActionType<typeof actions>;

export const lmsDashboardReducer = createReducer<ILmsDashboardStore, LmsDashboardAction>(initialState)
	.handleAction(actions.fetchLmsDashboardIndicatorAsync.request, (state) => ({
		...state,
		dashboardIndicator: {
			...state.dashboardIndicator,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardIndicatorAsync.success, (state, action) => {
		return {
			...state,
			dashboardIndicator: {
				...state.dashboardIndicator,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardIndicatorAsync.failure, (state, action) => ({
		...state,

		dashboardIndicator: {
			...state.dashboardIndicator,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardCompletionStatsAsync.request, (state) => ({
		...state,
		completionStats: {
			...state.completionStats,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardCompletionStatsAsync.success, (state, action) => {
		return {
			...state,
			completionStats: {
				...state.completionStats,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardCompletionStatsAsync.failure, (state, action) => ({
		...state,

		completionStats: {
			...state.completionStats,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardPassFailRatioAsync.request, (state) => ({
		...state,
		passFailRatio: {
			...state.passFailRatio,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardPassFailRatioAsync.success, (state, action) => {
		return {
			...state,
			passFailRatio: {
				...state.passFailRatio,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardEntityStatusAsync.request, (state) => {
		return {
			...state,
			entityStatus: {
				...state.entityStatus,
				isLoading: true,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardEntityStatusAsync.success, (state, action) => {
		return {
			...state,
			entityStatus: {
				...state.entityStatus,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardEntityStatusAsync.failure, (state, action) => {
		return {
			...state,
			entityStatus: {
				...state.entityStatus,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardPassFailRatioAsync.failure, (state, action) => ({
		...state,
		passFailRatio: {
			...state.passFailRatio,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardLessonProgressAsync.request, (state) => ({
		...state,
		lessonProgress: {
			...state.lessonProgress,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardLessonProgressAsync.success, (state, action) => {
		return {
			...state,
			lessonProgress: {
				...state.lessonProgress,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardLessonProgressAsync.failure, (state, action) => ({
		...state,
		lessonProgress: {
			...state.lessonProgress,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardLessonStatusAsync.request, (state) => ({
		...state,
		lessonStatus: {
			...state.lessonStatus,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardLessonStatusAsync.success, (state, action) => {
		return {
			...state,
			lessonStatus: {
				...state.lessonStatus,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardLessonStatusAsync.failure, (state, action) => ({
		...state,
		lessonStatus: {
			...state.lessonStatus,
			isLoading: false,
		},
	}))
	.handleAction(actions.fetchLmsDashboardSummaryAsync.request, (state) => ({
		...state,
		summary: {
			...state.summary,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardSummaryAsync.success, (state, action) => {
		return {
			...state,
			summary: {
				...state.summary,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardSummaryAsync.failure, (state, action) => ({
		...state,
		summary: {
			...state.summary,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardQuizInsightAsync.request, (state) => ({
		...state,
		quizInsight: {
			...state.quizInsight,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardQuizInsightAsync.success, (state, action) => {
		return {
			...state,
			quizInsight: {
				...state.quizInsight,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardQuizInsightAsync.failure, (state, action) => ({
		...state,
		quizInsight: {
			...state.quizInsight,
			isLoading: false,
		},
	}))
	.handleAction(actions.fetchLmsDashboardQuizInsightV2Async.request, (state) => ({
		...state,
		quizInsightV2: {
			...state.quizInsightV2,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardQuizInsightV2Async.success, (state, action) => {
		return {
			...state,
			quizInsightV2: {
				...state.quizInsightV2,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardQuizInsightV2Async.failure, (state, action) => ({
		...state,
		quizInsightV2: {
			...state.quizInsightV2,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardOverallQuizInsightAsync.request, (state) => ({
		...state,
		overallQuizInsights: {
			...state.overallQuizInsights,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardOverallQuizInsightAsync.success, (state, action) => {
		return {
			...state,
			overallQuizInsights: {
				...state.overallQuizInsights,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardOverallQuizInsightAsync.failure, (state, action) => ({
		...state,
		overallQuizInsights: {
			...state.overallQuizInsights,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardOverviewAsync.request, (state) => ({
		...state,
		overallInsights: {
			...state.overallInsights,
			isLoading: true,
		},
	}))

	.handleAction(actions.fetchLmsDashboardOverviewAsync.success, (state, action) => {
		return {
			...state,
			overallInsights: {
				...state.overallInsights,
				data: action.payload,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardOverviewAsync.failure, (state, action) => ({
		...state,
		overallInsights: {
			...state.overallInsights,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardTimeSpentAsync.request, (state) => ({
		...state,
		timeSpent: {
			...state.timeSpent,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardTimeSpentAsync.success, (state, action) => {
		return {
			...state,
			timeSpent: {
				...state.timeSpent,
				data: action.payload,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardTimeSpentAsync.failure, (state, action) => ({
		...state,
		timeSpent: {
			...state.timeSpent,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardTrendsAsync.request, (state) => ({
		...state,
		trends: {
			...state.trends,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardTrendsAsync.success, (state, action) => {
		return {
			...state,
			trends: {
				...state.trends,
				data: action.payload,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardTrendsAsync.failure, (state, action) => ({
		...state,
		trends: {
			...state.trends,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsDashboardTopBottomEntitiesAsync.request, (state) => ({
		...state,
		topEntities: {
			...state.topEntities,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsDashboardTopBottomEntitiesAsync.success, (state, action) => {
		return {
			...state,
			topEntities: {
				...state.topEntities,
				data: action.payload,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsDashboardTopBottomEntitiesAsync.failure, (state, action) => ({
		...state,
		topEntities: {
			...state.topEntities,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsEntityDetailsAsync.request, (state) => ({
		...state,
		entityDetail: {
			...state.entityDetail,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsEntityDetailsAsync.success, (state, action) => {
		return {
			...state,
			entityDetail: {
				...state.entityDetail,
				data: action.payload,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsEntityDetailsAsync.failure, (state, action) => ({
		...state,
		entityDetail: {
			...state.entityDetail,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsEnrollmentStatsAsync.request, (state) => ({
		...state,
		enrollmentStats: {
			...state.enrollmentStats,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsEnrollmentStatsAsync.success, (state, action) => {
		return {
			...state,
			enrollmentStats: {
				...state.enrollmentStats,
				data: action.payload,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsEnrollmentStatsAsync.failure, (state) => ({
		...state,
		enrollmentStats: {
			...state.enrollmentStats,
			isLoading: false,
		},
	}))

	.handleAction(actions.fetchLmsEntityStatusWiseSplitAsync.request, (state) => ({
		...state,
		entityStatusWiseSplit: {
			...state.entityStatusWiseSplit,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsEntityStatusWiseSplitAsync.success, (state, action) => {
		return {
			...state,
			entityStatusWiseSplit: {
				...state.entityStatusWiseSplit,
				data: action.payload,
				isLoading: false,
			},
		};
	})
	.handleAction(actions.fetchLmsEntityStatusWiseSplitAsync.failure, (state) => ({
		...state,
		entityStatusWiseSplit: {
			...state.entityStatusWiseSplit,
			isLoading: false,
		},
	}))

	.handleAction(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_PAGE as any, (state, action) => {
		return {
			...state,
			summary: {
				...state.summary,
				page: action.payload.page,
			},
		};
	})
	.handleAction(types.SET_LMS_ENTITY_DETAILS_PAGE as any, (state, action) => {
		return {
			...state,
			entityDetail: {
				...state.entityDetail,
				page: action.payload.page,
			},
		};
	})
	.handleAction(types.SET_LMS_DASHBOARD_LESSONSTATUS_DATE as any, (state, action) => {
		return {
			...state,
			lessonStatus: {
				...state.lessonStatus,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			},
			summary: {
				...state.summary,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			},
		};
	})

	.handleAction(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_SORT as any, (state, action) => {
		return {
			...state,
			summary: {
				...state.summary,
				sortDirections: action.payload.sortDirection,
				sortFields: action.payload.sortField,
			},
		};
	})

	.handleAction(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_STATUS as any, (state, action) => {
		return {
			...state,
			summary: {
				...state.summary,
				status: action.payload.status,
			},
		};
	})
	.handleAction(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_TYPE as any, (state, action) => {
		return {
			...state,
			summary: {
				...state.summary,
				type: action.payload.type,
			},
		};
	})
	.handleAction(types.SET_LMS_DASHBOARD_FILTERS as any, (state, action) => {
		return {
			...state,
			dashboardFilters: {
				...state.dashboardFilters,
				...action.payload.filters,
			},
		};
	});
