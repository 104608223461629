import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './organizations.actions';
import { OrganizationsState } from './organizations.types';

const initialState: OrganizationsState = {
	isLoading: false,
	error: null,
	organization: null,
	timestamp: null,
};

const organizationReducer = createReducer<OrganizationsState, ActionType<typeof actions>>(initialState)
	.handleAction(actions.fetchOrganizations.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchOrganizations.success, (state, action) => ({
		...state,
		error: null,
		isLoading: false,
		organization: action.payload.data,
		timestamp: Date.now(),
	}))
	.handleAction(actions.fetchOrganizations.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchOrganizations.cancel, (state) => ({
		...state,
		isLoading: false,
	}));

export { organizationReducer };
