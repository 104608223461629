import { createAsyncAction } from 'typesafe-actions';
import {
  SitesFlagsOverview,
  FETCH_SITES_FLAGS_OVERVIEW_REQUEST,
  FETCH_SITES_FLAGS_OVERVIEW_SUCCESS,
  FETCH_SITES_FLAGS_OVERVIEW_FAILURE,
  FetchFlagsOverviewPayload
} from './types';

export const fetchSitesFlagsOverviewAsync = createAsyncAction(
  FETCH_SITES_FLAGS_OVERVIEW_REQUEST,
  FETCH_SITES_FLAGS_OVERVIEW_SUCCESS,
  FETCH_SITES_FLAGS_OVERVIEW_FAILURE
)<FetchFlagsOverviewPayload, SitesFlagsOverview, string>();
