export const FETCH_HEALTH_DASHBOARD_OVERALL_HEALTH_REQUEST = '@dashboard/FETCH_HEALTH_DASHBOARD_OVERALL_HEALTH_REQUEST';
export const FETCH_HEALTH_DASHBOARD_OVERALL_HEALTH_SUCCESS = '@dashboard/FETCH_HEALTH_DASHBOARD_OVERALL_HEALTH_SUCCESS';
export const FETCH_HEALTH_DASHBOARD_OVERALL_HEALTH_FAILURE = '@dashboard/FETCH_HEALTH_DASHBOARD_OVERALL_HEALTH_FAILURE';

export const SET_HEALTH_DASHBOARD_FILTER_DATE = '@dashboard/SET_HEALTH_DASHBOARD_FILTER_DATE';

export const FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_REQUEST =
	'@dashboard/FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_REQUEST';
export const FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_SUCCESS =
	'@dashboard/FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_SUCCESS';
export const FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_FAILURE =
	'@dashboard/FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_FAILURE';

export const FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_REQUEST =
	'@dashboard/FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_REQUEST';
export const FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_SUCCESS =
	'@dashboard/FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_SUCCESS';
export const FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_FAILURE =
	'@dashboard/FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_FAILURE';

export const FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_REQUEST =
	'@dashboard/FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_REQUEST';
export const FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_SUCCESS =
	'@dashboard/FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_SUCCESS';
export const FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_FAILURE =
	'@dashboard/FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_FAILURE';

export const FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_REQUEST =
	'@dashboard/FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_REQUEST';
export const FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_SUCCESS =
	'@dashboard/FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_SUCCESS';
export const FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_FAILURE =
	'@dashboard/FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_FAILURE';

export const FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_REQUEST =
	'@dashboard/FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_REQUEST';
export const FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_SUCCESS =
	'@dashboard/FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_SUCCESS';
export const FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_FAILURE =
	'@dashboard/FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_FAILURE';

export const FETCH_HEALTH_DASHBOARD_FLAG_RATE_REQUEST = '@dashboard/FETCH_HEALTH_DASHBOARD_FLAG_RATE_REQUEST';
export const FETCH_HEALTH_DASHBOARD_FLAG_RATE_SUCCESS = '@dashboard/FETCH_HEALTH_DASHBOARD_FLAG_RATE_SUCCESS';
export const FETCH_HEALTH_DASHBOARD_FLAG_RATE_FAILURE = '@dashboard/FETCH_HEALTH_DASHBOARD_FLAG_RATE_FAILURE';
