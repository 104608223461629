import { action, createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './siteDetails.actionTypes';
import { Site, Coordinates, PopulatedSiteSupervisor, SiteSupervisor, SiteChild } from 'nimbly-common';

export const setSiteLoading = (bool: boolean) =>
	action(types.SET_SITE_LOADING, {
		bool,
	});

export const fetchSiteDepartments = createAsyncAction(
	types.FETCH_SITE_DEPARTMENTS_REQUEST,
	types.FETCH_SITE_DEPARTMENTS_SUCCESS,
	types.FETCH_SITE_DEPARTMENTS_FAILURE,
)<string, { data: any }, { error: string }>();

export const showRemoveMemberModal = () => action(types.SHOW_REMOVE_MEMBER_MODAL);

export const dismissRemoveMemberModal = () => action(types.DISMISS_REMOVE_MEMBER_MODAL);

export const setSelectedRemoveTeamMember = (userKey: string) => action(types.SET_REMOVE_TEAM_MEMBER, { userKey });

export const setSelectedRemoveQuestionnaireSchedules = (indexes: number[]) =>
	action(types.SET_QUESTIONNAIRE_SCHEDULE_MEMBER, { indexes });

export const setTeam = (team: string[]) => action(types.SET_TEAM, { team });

export const setSiteOwner = (userKey: string) => action(types.SET_SITE_OWNER, { userKey });

export const setSite = (site: Site) => action(types.SET_SITE, { site });

export const setSiteValue = (site: Site) => action(types.SET_SITE_VALUE, { site });

export const setSiteName = (text: string) => action(types.SET_SITE_NAME, { text });

export const setSiteSubtitle = (text: string) => action(types.SET_SITE_SUBTITLE, { text });

export const setSiteAddress = (text: string) => action(types.SET_SITE_ADDRESS, { text });

export const setSiteCountry = (text: string) => action(types.SET_SITE_COUNTRY, { text });

export const setSiteProvince = (text: string) => action(types.SET_SITE_PROVINCE, { text });

export const setSiteCity = (text: string) => action(types.SET_SITE_CITY, { text });

export const setSiteLocationName = (text: string) => action(types.SET_SITE_LOCATION_NAME, { text });

export const setSiteRadius = (radius: string) => action(types.SET_SITE_RADIUS, { radius });

export const setSiteSupervisors = (supervisors: (PopulatedSiteSupervisor | SiteSupervisor)[]) =>
	action(types.SET_SITE_SUPERVISORS, { supervisors });

export const setChildren = createAction(types.SET_CHILDREN)<{ children: SiteChild[] }>();

export const setHasChanges = (hasChanges: boolean) => action(types.SET_HAS_CHANGES, { hasChanges });

export const setAssignedAuditor = createAction(types.SET_ASSIGNED_AUDITOR)<{ assignedAuditor: string }>();

export const setSiteIssueDefaultAssignedUser = (uid: string | null) =>
	action(types.SET_SITE_ISSUE_DEFAULT_ASSIGNED_USER, { uid });

/**
 * @param offset - in minutes
 */
export const setSiteTimezone = (offset: number, timezone: string) =>
	action(types.SET_SITE_TIMEZONE, { offset, timezone });

export const addSiteAuditor = (userKey: string) => action(types.ADD_SITE_AUDITOR, { userKey });

export const removeTeamMember = (userKey: string) => action(types.REMOVE_TEAM_MEMBER, { userKey });

export const setCoordinate = (coordinate: Coordinates) => action(types.SET_COORDINATE, { coordinate });

export const updateInitialValue = (site?: Site) => action(types.UPDATE_INITIAL_VALUE);
