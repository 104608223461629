import * as Common from 'nimbly-common';
import { Dispatch } from 'redux';
import { action, createAsyncAction } from 'typesafe-actions';

import { apiURL } from '../../../config/baseURL';
import { getFirebase } from 'react-redux-firebase';
import * as types from './actionType';
import { toQueryString } from 'utils/string';

import { TrendsAnalyticsQuery } from 'constants/api/v1/types';

export const getToken = async () => {
  const firebase = getFirebase();
  // @ts-ignore
  const { currentUser } = firebase.auth();
  let token = '';
  if (currentUser) {
    token = await currentUser.getIdToken(true);
  }
  return token;
};

const getAPIData = async (api: any, options: any) => {
  const getData = await fetch(api, options);
  return getData.status === 200 && (await getData.json());
};

export const setQuestionnaire = (questionnaireKey: string | null) =>
  action(types.SET_QUESTIONNAIRE, { questionnaireKey });

export const setQuestionCategory = (categoryName: string | null) =>
  action(types.SET_QUESTION_CATEGORY, { categoryName });

export const setQuestionType = (questionType: Common.enums.QuestionTypes) =>
  action(types.SET_QUESTION_TYPE, { questionType });

export const setEmptyData = () => async (dispatch: Dispatch) => {
  dispatch({ type: types.IS_DATA_EMPTY, payload: { isEmpty: true } });
  dispatch({ type: types.LOAD_CHART, payload: { data: [] } });
};

export const fetchChart = (params: any, query: any) => async (dispatch: Dispatch, getState: any) => {
  dispatch({ type: types.START_REQUESTING_CHART });
  const state = getState();

  if (state.dashboard.trends.selectedQuestionnaire) {
    if (state.dashboard.trends.isEmpty) {
      dispatch({ type: types.IS_DATA_EMPTY, payload: { isEmpty: false } });
    }

    if (query.periodType !== 'custom') {
      query.startDate = state.analytics.startDate.format('YYYY-MM-DD');
      query.endDate = state.analytics.endDate.format('YYYY-MM-DD');
    }

    const { questionType } = params;

    const token = await getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/csv'
      }
    };
    const queryString = toQueryString(query);

    // ROLE - TRENDS - VIEW - 2
    const url = `${apiURL}/statistics/trends/${questionType}?${queryString}`;

    const response = await getAPIData(url, options);

    if (response && response.data) {
      let result = [];
      result = response.data;

      if (result.isEmpty) {
        dispatch({ type: types.IS_DATA_EMPTY, payload: { isEmpty: true } });
        dispatch({
          type: types.LOAD_CHART,
          payload: { data: [] }
        });
      } else {
        dispatch({ type: types.IS_DATA_EMPTY, payload: { isEmpty: result.length === 0 } });
        dispatch({
          type: types.LOAD_CHART,
          payload: { data: result }
        });
      }
    } else {
      dispatch({ type: types.IS_DATA_EMPTY, payload: { isEmpty: true } });
      dispatch({
        type: types.LOAD_CHART,
        payload: { data: [] }
      });
    }
  }

  return dispatch({ type: types.FINISH_REQUESTING_CHART });
};

export const fetchTrendsAnalyticsAsync = createAsyncAction(
  types.FETCH_TRENDS_CHART_REQUEST,
  types.FETCH_TRENDS_CHART_SUCCESS,
  types.FETCH_TRENDS_CHART_FAILURE
)<
  { params: { questionType: Common.enums.QuestionTypes }; query: TrendsAnalyticsQuery },
  { data: any; isEmpty: boolean },
  { error: string }
>();

export const trendsAnalyticsActions = {
  setQuestionnaire,
  setQuestionCategory,
  setQuestionType,
  setEmptyData,
  fetchChart,
  fetchTrendsAnalyticsAsync
};
