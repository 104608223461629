import * as types from './action.type';

const initialState = {
  selectedSite: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SITE:
      return {
        ...state,
        selectedSite: action.payload.siteKey
      };

    default:
      return state;
  }
};
