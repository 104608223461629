export const downloadFile = async (blob: Blob, fileName: string, onBeforeClick?: any) => {
	const blobURL = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = blobURL;
	link.download = fileName;
	link.target = '_blank';
	link.rel = 'noopener noreferrer';
	if (onBeforeClick) {
		onBeforeClick();
	}
	const click = new MouseEvent('click');
	link.dispatchEvent(click);
	// link.click();
};

export async function downloadURL(url: string, filename: string) {
	const res = await fetch(url);
	const blob = await res.blob();

	await downloadFile(blob, filename);
}

export async function downloadArrayBuffer(
	data: ArrayBuffer,
	filename: string,
	type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
) {
	const blob = new Blob([data], { type: type });
	await downloadFile(blob, filename);
}
