import { PaginationResult, Quiz, QuizPaginateResponse, QuizResponse } from '@nimbly-technologies/nimbly-common';
import { apiURL, apiURLAlt, baseApiURLAlt } from '../../../config/baseURL';
import API from '../../../helpers/api';
import { CreateQuizPayloadRequest, EditQuizPayloadRequest } from 'components/lms/quiz/quizEditor/QuizForm.types';

const getJson = async (response: Response) => {
	const json = await response.json();

	if (response.status === 200) {
		return json.data;
	} else {
		throw json.data;
	}
};

export const fetchQuizzes = async (searchParams: string): Promise<PaginationResult<QuizPaginateResponse>> => {
	const url = `${apiURL}/lms/quizzes?${searchParams}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export const getQuizById = async (id: string): Promise<QuizResponse> => {
	const url = `${apiURLAlt}/lms/quizzes/${id}`;
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);
	return getJson(response);
};

export const fetchQuizAssociationOptions = async () => {
	const url = `${apiURLAlt}/lms/courses-syllabuses/courses-lessons-topics`;
	const response = await API.getJSON(url);
	return response?.data;
};

export const createQuiz = async (payload: CreateQuizPayloadRequest, queryString?: string) => {
	const url = `${apiURLAlt}/lms/quizzes?${queryString}`;
	const token = await API.getFirebaseToken();
	const response = await API.post(url, token, payload);
	return getJson(response);
};

export const editQuiz = async (
	quizID: string,
	payload: Partial<EditQuizPayloadRequest>,
	queryString?: string,
): Promise<any> => {
	const url = `${apiURLAlt}/lms/quizzes/${quizID}?${queryString}`;
	const token = await API.getFirebaseToken();
	const response = await API.put(url, token, payload);
	return getJson(response);
};

export const patchQuiz = async (quizID: string, updatedData: Partial<Quiz>): Promise<any> => {
	const url = `${apiURL}/lms/quizzes/${quizID}`;
	const token = await API.getFirebaseToken();
	const response = await API.patch(url, token, updatedData);
	return getJson(response);
};

export const deleteQuiz = async (quizID: string): Promise<any> => {
	const url = `${apiURL}/lms/quizzes/${quizID}`;
	const token = await API.getFirebaseToken();
	const response = await API.delete(url, token);
	return getJson(response);
};

const getDataBlob = async (url: string): Promise<any> => {
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);

	if (response.status !== 200) throw new Error('Failed to retrieve data');

	return await response.blob();
};

export const getBatchCreateTemplateData = async (queryString?: string): Promise<any> => {
	const url = `${baseApiURLAlt}/api-lms/quizzes/template-bulk-upload${queryString ? `?${queryString}` : ''}`;
	return await getDataBlob(url);
};

const getFormData = (file: File) => {
	const body = new FormData();
	body.append('file', file);
	return body;
};

const getError = async (response: Response) => {
	if (response.status === 500) {
		throw 'Internal Server Error';
	}

	const json = await response.json();
	if (response.status !== 200) {
		throw json.data;
	}

	return json.data;
};

export const submitQuizBulkCreate = async (file: File): Promise<void> => {
	const url = `${apiURL}/lms/quizzes/bulk-upload`;
	const token = await API.getFirebaseToken();
	const body = getFormData(file);
	const response = await API.postFile(url, token, body);
	return getError(response);
};

export const submitQuizBulkEdit = async (file: File): Promise<void> => {
	const url = `${apiURL}/lms/quizzes/edit-bulk-upload`;
	const token = await API.getFirebaseToken();
	const body = getFormData(file);
	const response = await API.putFile(url, token, body);
	return getError(response);
};

export const bulkDeleteQuiz = async (queryString: string): Promise<void> => {
	const url = `${apiURL}/lms/quizzes?${queryString}`;
	const token = await API.getFirebaseToken();
	const response = await API.delete(url, token);
	return getJson(response);
};
