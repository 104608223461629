import { RootState } from 'store/rootReducers';
import { call, put, select } from 'typed-redux-saga';
import * as actions from '../../../reducers/syllabuses/syllabuses.action';
import * as types from '../../../reducers/syllabuses/syllabuses.actionTypes';
import queryString from 'query-string';
import { SyllabusesState } from 'reducers/syllabuses/type.d';
import { fetchSyllabuses } from 'services/lms/syllabuses/syllabuses.service';
import moment from 'moment';
import { takeLatest } from 'redux-saga/effects';
import { LMSUserRole } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export function* fetchSyllabusesSaga() {
	try {
		const { page, limit, sortField, sortDirection, search, filters }: SyllabusesState = yield select(
			(state: RootState) => state.syllabuses,
		);
		const { auth } = yield select((state: RootState) => state.firebase);
		const { lmsRole } = yield select((state: RootState) => state.userAccess);

		const newFilters = {
			...filters,
			startDate: filters.startDate ? moment(filters.startDate).valueOf() : undefined,
			endDate: filters.endDate ? moment(filters.endDate).endOf('day').valueOf() : undefined,
			userIDs: lmsRole === LMSUserRole.INSTRUCTOR ? [auth.uid] : filters.userIDs,
		};

		const query: Record<string, any> = {
			page,
			limit,
			sortFields: sortField,
			sortDirections: sortDirection,
			search,
			...newFilters,
		};
		const queryStr = queryString.stringify(query);
		const { data } = yield* call(fetchSyllabuses, queryStr);

		yield put(actions.fetchSyllabuses.success({ syllabuses: data.docs, ...data }));
	} catch (e) {
		yield put(actions.fetchSyllabuses.failure({ error: 'Failed to fetch syllabuses' }));
		return null;
	}
}

export default function* syllabusesSaga() {
	yield takeLatest(types.FETCH_SYLLABUSES_REQUEST, fetchSyllabusesSaga);
	yield takeLatest(types.SET_SYLLABUSES_PAGE_INDEX, fetchSyllabusesSaga);
	yield takeLatest(types.SET_SYLLABUSES_FILTER_QUERY, fetchSyllabusesSaga);
	yield takeLatest(types.RESET_SYLLABUSES_FILTER_QUERY, fetchSyllabusesSaga);
	yield takeLatest(types.SET_SYLLABUSES_SEARCH_QUERY, fetchSyllabusesSaga);
	yield takeLatest(types.SET_SYLLABUSES_SORT, fetchSyllabusesSaga);
}
