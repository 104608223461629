import { isEmptyValue } from './isEmptyValue';

export const filterObjectWithEmptyValues = (obj: any): any => {
	return Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
		if (!isEmptyValue(value)) {
			acc[key] = value;
		}
		return acc;
	}, {});
};
