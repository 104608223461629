import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';

import { Option } from 'types/app';

import * as actions from './action';

export type FilterSitesAction = ActionType<typeof actions>;

export interface FilterSitesState {
  data: Array<{
    siteID: string;
    name: string;
    departments: { [deptID: string]: boolean };
  }>;
  selectedSite: null | Option;
  isLoading: boolean;
  isLoaded: boolean;
  timestamp: null | number;
  error: string;
}

const initialState: FilterSitesState = {
  data: [],
  selectedSite: null,
  isLoading: false,
  isLoaded: false,
  timestamp: null,
  error: ''
};

export const filterSitesReducer = createReducer<FilterSitesState, FilterSitesAction>(initialState)
  .handleAction(actions.fetchFilterSites.request, state =>
    update(state, {
      isLoading: { $set: true }
    })
  )
  .handleAction(actions.fetchFilterSites.success, (state, action) =>
    update(state, {
      $merge: {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
        timestamp: Date.now(),
        error: ''
      }
    })
  )
  .handleAction(actions.fetchFilterSites.failure, (state, action) =>
    update(state, {
      $merge: {
        timestamp: null,
        isLoading: false,
        isLoaded: false,
        error: action.payload.error
      }
    })
  )
  .handleAction(actions.fetchFilterSites.cancel, (state, action) =>
    update(state, {
      isLoading: { $set: false }
    })
  )
  .handleAction(actions.selectSiteOption, (state, action) =>
    update(state, {
      selectedSite: { $set: action.payload }
    })
  )
  .handleAction(actions.clearSiteOption, state =>
    update(state, {
      selectedSite: { $set: null }
    })
  );
