import { Quiz, QuizQuestion, QuizResult } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { IAPIResponse } from 'types/api';
import Monitoring from 'utils/monitoring/Monitoring';

interface QueryLmsCourseQuizByIdArgs {
	quizId: string;
}
const queryLmsCourseQuizById = async (args: QueryLmsCourseQuizByIdArgs) => {
	const { quizId } = args;

	try {
		const url = `${apiURL}/lms/learner-quiz/${quizId}`;

		const token = await API.getFirebaseToken();
		const fetchResponse = await API.get(url, token);
		const response: IAPIResponse<Quiz> = await fetchResponse.json();

		return response;
	} catch (error) {
		Monitoring.logError({
			name: 'queryLmsCourseQuizById',
			message: (error as unknown) as string,
		});
		throw error;
	}
};

export interface StartLmsCourseQuizArgs {
	quizId: string;
	learnerActivityId: string;
	questions: Array<QuizQuestion>;
}
const startLmsCourseQuiz = async (args: StartLmsCourseQuizArgs) => {
	const { learnerActivityId, questions, quizId } = args;

	try {
		const url = `${apiURL}/lms/learner-quiz/${quizId}`;

		const token = await API.getFirebaseToken();
		const fetchResponse = await API.post(url, token, {
			learnerActivityID: learnerActivityId,
			questions,
		});

		const response: IAPIResponse<string> = await fetchResponse.json();

		return response;
	} catch (error) {
		Monitoring.logError({
			name: 'startLmsCourseQuiz',
			message: (error as unknown) as string,
		});
		throw error;
	}
};

export interface QueryLmsCourseQuizSubmissionDraftBySubmitIdArgs {
	submitId: string;
}
export type QueryLmsCourseQuizByIdResponse =
	| QuizResult
	| (SubmitLmsCourseQuizSubmissionResponse & {
			totalAttempts: number;
			latestAttempts: number;
	  });
const queryLmsCourseQuizSubmissionDraftBySubmitId = async (args: QueryLmsCourseQuizSubmissionDraftBySubmitIdArgs) => {
	const { submitId } = args;

	try {
		const url = `${apiURL}/lms/learner-quiz/submit/${submitId}`;

		const token = await API.getFirebaseToken();
		const fetchResponse = await API.get(url, token);
		const response: IAPIResponse<QueryLmsCourseQuizByIdResponse> = await fetchResponse.json();

		return response;
	} catch (error) {
		Monitoring.logError({
			name: 'queryLmsCourseQuizBySubmitId',
			message: (error as unknown) as string,
		});
		throw error;
	}
};

export interface UpdateLmsCourseQuizSubmissionDraft extends QueryLmsCourseQuizSubmissionDraftBySubmitIdArgs {
	questions: Question[];
	elapsedTime: string;
}
export interface Question {
	answer: string;
	points: number;
	questionText: string;
	questionType: string;
	choices: Choice[];
}
export interface Choice {
	choiceText: string;
}
const updateLmsCourseQuizSubmissionDraft = async (args: UpdateLmsCourseQuizSubmissionDraft) => {
	const { submitId, ...quizResult } = args;

	try {
		const url = `${apiURL}/lms/learner-quiz/${submitId}`;

		const token = await API.getFirebaseToken();
		const fetchResponse = await API.put(url, token, quizResult);
		const response: IAPIResponse<QuizResult> = await fetchResponse.json();

		return response;
	} catch (error) {
		Monitoring.logError({
			name: 'updateLmsCourseQuizSubmission',
			message: (error as unknown) as string,
		});

		throw error;
	}
};

export interface SubmitLmsCourseQuizSubmissionArgs extends UpdateLmsCourseQuizSubmissionDraft {
	autoSubmit?: boolean;
}
export interface SubmitLmsCourseQuizSubmissionResponse {
	passCriteria: SubmitLmsCourseQuizSubmissionPassCriteria;
	score: number;
	totalCorrectAnswer: number;
	totalInCorrectAnswer: number;
	summary: SubmitLmsCourseQuizSubmissionSummary[];
}
interface SubmitLmsCourseQuizSubmissionPassCriteria {
	type: string;
	value: number;
}
interface SubmitLmsCourseQuizSubmissionSummary {
	isCorrect: boolean | undefined;
	questionText: string;
	choices: string[];
	correctAnswer: string;
	submittedAnswer: string;
	points: number;
	questionType: string;
}
const submitLmsCourseQuizSubmission = async (args: SubmitLmsCourseQuizSubmissionArgs) => {
	const { submitId, autoSubmit, ...quizResult } = args;

	try {
		const url = `${apiURL}/lms/learner-quiz/submit/${submitId}?autoSubmit=${autoSubmit ?? false}`;

		const token = await API.getFirebaseToken();
		const fetchResponse = await API.post(url, token, quizResult);
		const response: IAPIResponse<SubmitLmsCourseQuizSubmissionResponse> = await fetchResponse.json();

		return response;
	} catch (error) {
		Monitoring.logError({
			name: 'submitLmsCourseQuizSubmission',
			message: (error as unknown) as string,
		});

		throw error;
	}
};

export interface RetryLmsCourseQuizArgs {
	quizId: string;
	learnerActivityId: string;
}
export interface RetryLmsCourseQuizResponse {
	queryByIdResponse: IAPIResponse<Quiz>;
	startMutationResponse: IAPIResponse<string>;
}
const retryLmsCourseQuiz = async (args: RetryLmsCourseQuizArgs): Promise<RetryLmsCourseQuizResponse> => {
	try {
		const { quizId, learnerActivityId } = args;

		const quiz = await queryLmsCourseQuizById({ quizId });
		if (!quiz.data) throw new Error('Quiz not found');

		const res = await startLmsCourseQuiz({
			quizId,
			learnerActivityId,
			questions: quiz.data.questions,
		});

		return {
			queryByIdResponse: quiz,
			startMutationResponse: res,
		};
	} catch (error) {
		Monitoring.logError({
			name: 'retryLmsCourseQuiz',
			message: (error as unknown) as string,
		});

		throw error;
	}
};

export const lmsCourseQuizService = {
	queryCourseQuizById: queryLmsCourseQuizById,
	startQuiz: startLmsCourseQuiz,
	retryQuiz: retryLmsCourseQuiz,
	querySubmissionDraftBySubmitId: queryLmsCourseQuizSubmissionDraftBySubmitId,
	updateSubmissionDraft: updateLmsCourseQuizSubmissionDraft,
	submitSubmission: submitLmsCourseQuizSubmission,
};
