import { FindSKUPerCategory, SKUCompact } from '@nimbly-technologies/nimbly-common';

export enum SkusCompactFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface SkuCompactData {
	skuID: string;
	name: string;
}

export interface SkusCompactState {
	error: string;
	fetchState: SkusCompactFetchState;
	skusCompactWithCategory: FindSKUPerCategory;
	skusCompact: SKUCompact[];
}
