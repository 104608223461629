import styled, { keyframes } from 'styled-components/macro';
import { FilledButton } from 'styles/Button';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const ReadMore = styled(FilledButton)`
	box-shadow: 0px 4px 4px rgba(86, 56, 191, 0.2);
	padding: 0.375rem 2rem;
`;

const Article = styled.div`
	padding: 22px;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;

	> div {
		> .article-body {
			white-space: wrap;

			font-size: 14px;
			line-height: 21px;
			letter-spacing: 0.01em;
			color: #4f4f4f;
			margin-top: 15px;
		}

		> .category {
			font-weight: bold;
			font-size: 14px;
			line-height: 32px;
			letter-spacing: 0.01em;
			color: #828282;
		}

		> .line {
			width: 38px;
			border: 1px solid #34ca65;
			margin-top: 6px;
		}

		> .date {
			font-size: 12px;
			line-height: 32px;
			letter-spacing: 0.01em;
			color: #828282;
			margin-top: 6px;
		}

		> .title {
			color: #34ca65;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			letter-spacing: 0.01em;
			margin-top: 10px;
		}
	}

	> .button-container {
		font-size: 10px;
		line-height: 22px;
	}
`;

const Dot = styled.div<any>`
	background: ${(props) => (props.styleIndex === props.dataIndex ? '#F6BB42' : '#fff')};
	height: 9px;
	width: 9px;
	border-radius: 50%;
`;

const Container = styled.div`
	width: 615px;
	height: 484px;

	.arrow-next {
		transform: rotate(180deg);
		cursor: pointer;
	}

	.arrow-next:hover {
		transform: scale(1.5) rotate(180deg);
		animation: ${fadeIn} 1s linear;
	}

	.arrow-prev {
		cursor: pointer;
	}

	.arrow-prev:hover {
		transform: scale(1.5);
		animation: ${fadeIn} 1s linear;
	}

	.svg-white {
		filter: brightness(255);
	}

	.fade-in {
		animation: ${fadeIn} 1s linear;
	}

	> .dot-indicator {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 60px;

		> .dot-container {
			display: flex;
			justify-content: center;
			width: 100%;
			div:not(:first-child) {
				margin-left: 15px;
			}
		}
	}

	> .title {
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 52px;
		text-align: center;
		letter-spacing: 0.01em;
		color: #ffffff;
	}

	> .slider-container {
		display: flex;
		justify-content: space-around;
		align-items: center;

		> .slide-article {
			width: 475px;
			height: 350px;
			box-shadow: 0px 19px 14px rgba(0, 0, 0, 0.25);
			background: #ffffff;
		}
	}
`;

export { fadeIn, ReadMore, Article, Dot, Container };
