import { takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as types from 'reducers/journeyPlans/journeys.actionType';
import * as actions from 'reducers/journeyPlans/journeys.action';
import { dismissActionJourneyPlanModal, dismissBulkJourneyPlanModal } from 'reducers/journeyPlan';
import { fmcgURL } from 'config/baseURL';
import API from 'helpers/api';
import i18n from 'i18n';

const apiURL = fmcgURL;

type Action = {
  type: string;
  payload: any;
  meta: string | undefined;
};

export function* fetchJourneyPlans() {
  try {
    const authToken = yield call(API.getFirebaseToken);
    yield put(actions.setLoading(true));

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchJourneysURL = `${apiURL}/journey/plans`;
    const request = () => fetch(fetchJourneysURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      const mappingData: { [key: string]: any } = {};

      responseData.data.forEach((journey: any) => {
        const journeyKey: string = journey.code;
        if (!mappingData.hasOwnProperty(journeyKey)) {
          mappingData[journeyKey] = journey;
        }
      });

      yield put(actions.fetchJourneys.success({ data: mappingData }));
      return mappingData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchJourneys.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchJourneys.success({ data: null }));
    yield put(actions.fetchJourneys.failure({ error: i18n.t('addOn.journeyPlan.fetch.fail') }));
    return null;
  }
}

function* createJourneyPlan(action: Action) {
  try {
    const authToken = yield call(API.getFirebaseToken);
    const payload = action.payload;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(payload)
    };

    const createJourneyPlanURL = `${apiURL}/journey/plans`;

    const request = () => fetch(createJourneyPlanURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      yield put(actions.createJourneys.success({ data: responseData.data }));
      toast.success('Success create Journey plan');
    } else {
      const responseData = yield response.json();
      yield put(actions.createJourneys.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.createJourneys.failure({ error: i18n.t('addOn.journeyPlan.create.fail') }));
    return null;
  }
}

function* createJourneyPlanBulk(action: Action) {
  try {
    const authToken = yield call(API.getFirebaseToken);
    const payload: File = action.payload;

    const formData = new FormData();
    formData.append('file', payload);

    const options = {
      method: 'POST',
      headers: {
        Authorization: authToken
      },
      body: formData
    };

    const createJourneyPlanURL = `${apiURL}/journey/plans/bulk`;

    const response = yield call(fetch, createJourneyPlanURL, options);

    if (response && response.status === 200) {
      toast.success(i18n.t('addOn.journeyPlan.bulk.success'));
      yield put(actions.createJourneyBulk.success({}));
      yield put(actions.fetchJourneys.request());
      yield put(dismissBulkJourneyPlanModal());
    } else {
      toast.error(i18n.t('addOn.journeyPlan.bulk.fail'));
      let errors: any[] = [];
      try {
        const responseData = yield response.json();
        errors = responseData.data;
        errors = errors.sort((a, b) => a.lines - b.lines);
        errors = errors.map(x => `${x.lines}: [${x.fields}] ${x.message}`);
      } catch {}

      yield put(actions.createJourneyBulk.failure({ errors }));
    }
  } catch (e) {
    toast.error(i18n.t('addOn.journeyPlan.bulk.fail'));
    yield put(actions.createJourneyBulk.failure({ errors: [i18n.t('addOn.journeyPlan.bulk.fail')] }));
  }
}

export function* fetchJourneyDetail(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield call(API.getFirebaseToken);

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchJourneysURL = `${apiURL}/journey/plans/${action.payload}`;
    const request = () => fetch(fetchJourneysURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchJourneyDetail.success({ data: responseData.data }));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchJourneyDetail.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchJourneyDetail.success({ data: null }));
    yield put(actions.fetchJourneyDetail.failure({ error: i18n.t('addOn.journeyPlan.fetch.fail') }));
    return null;
  }
}

function* updateJourneyPlan(action: Action) {
  try {
    const authToken = yield call(API.getFirebaseToken);
    const payload = action.payload;
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(payload)
    };

    const createJourneyPlanURL = `${apiURL}/journey/plans/${action.payload.code}`;

    const request = () => fetch(createJourneyPlanURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      toast.success(i18n.t('addOn.journeyPlan.update.success'));
      const responseData = yield response.json();

      yield put(actions.updateJourney.success({ data: { [responseData.data.code]: responseData.data } }));
    } else {
      const responseData = yield response.json();
      yield put(actions.updateJourney.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.updateJourney.failure({ error: i18n.t('addOn.journeyPlan.update.fail') }));
    return null;
  }
}

function* deleteJourneyPlan(action: Action) {
  try {
    const authToken = yield call(API.getFirebaseToken);
    const payload = action.payload;

    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      }
    };

    const deleteJourneyURL = `${apiURL}/journey/plans/${payload}`;

    const request = () => fetch(deleteJourneyURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      toast.success(i18n.t('addOn.journeyPlan.delete.success'));
      yield put(dismissActionJourneyPlanModal());
      yield put(actions.deleteJourney.success({ code: payload }));
    } else {
      const responseData = yield response.json();
      yield put(actions.deleteJourney.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.deleteJourney.failure({ error: i18n.t('addOn.journeyPlan.delete.fail') }));
    return null;
  }
}

export function* fetchReportJourney(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield call(API.getFirebaseToken);

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchReportJourneyURL = `${apiURL}/journey/reports?journey-id=${action.payload}`;

    const request = () => fetch(fetchReportJourneyURL, options);
    const response = yield call(request);


    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchReportJourney.success({ data: responseData.data }));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchReportJourney.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchReportJourney.success({ data: null }));
    yield put(actions.fetchReportJourney.failure({ error: i18n.t('addOn.journeyPlan.report.fail') }));
    return null;
  }
}

export default function* journeyPlansSaga() {
  yield takeLatest(types.FETCH_JOURNEYS_REQUEST, fetchJourneyPlans);
  yield takeLatest(types.CREATE_JOURNEYS_REQUEST, createJourneyPlan);
  yield takeLatest(types.FETCH_JOURNEY_DETAIL_REQUEST, fetchJourneyDetail);
  yield takeLatest(types.UPDATE_JOURNEY_REQUEST, updateJourneyPlan);
  yield takeLatest(types.DELETE_JOURNEY_REQUEST, deleteJourneyPlan);
  yield takeLatest(types.FETCH_REPORT_JOURNEYS_REQUEST, fetchReportJourney);
  yield takeLatest(types.CREATE_JOURNEYS_BULK_REQUEST, createJourneyPlanBulk);
}
