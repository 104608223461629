import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesResolvedChart,
  FETCH_ISSUES_RESOLVED_CHART_REQUEST,
  FETCH_ISSUES_RESOLVED_CHART_SUCCESS,
  FETCH_ISSUES_RESOLVED_CHART_FAILURE,
  FetchIssuesResolvedChartPayload
} from './types';

export const fetchIssuesResolvedChartAsync = createAsyncAction(
  FETCH_ISSUES_RESOLVED_CHART_REQUEST,
  FETCH_ISSUES_RESOLVED_CHART_SUCCESS,
  FETCH_ISSUES_RESOLVED_CHART_FAILURE
)<FetchIssuesResolvedChartPayload, IssuesResolvedChart, string>();
