import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './customerFeedback.action';
import { CustomerFeerbackTemplateState } from './type';

export type CustomerFeedbackAction = ActionType<typeof actions>;

const initialState: CustomerFeerbackTemplateState = {
	data: '',
	isLoading: false,
	message: '',
};

export const customerFeedbackTemplateReducer = createReducer<CustomerFeerbackTemplateState, CustomerFeedbackAction>(
	initialState,
)
	.handleAction(actions.getCampaignTemplate.request, (state, action) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(actions.getCampaignTemplate.success, (state, action) => ({
		...state,
		data: action.payload.data,
		isLoading: false,
		message: 'success',
	}))
	.handleAction(actions.getCampaignTemplate.failure, (state, action) => ({
		...state,
		isLoading: false,
		message: 'failure',
	}));
