import {
  FETCH_EXPORT_RequestForDemoPayload,
  FETCH_RequestForDemoResponse,
  EXPORT_RequestForDemoResponse,
  ErrorResponse
} from 'reducers/superadmin/fetch-types/requestDemo-fetch.types';
import { DemoRequest } from 'nimbly-common';

export type RequestDemoList = FETCH_RequestForDemoResponse['data'];
export type RequestDemoListExport = EXPORT_RequestForDemoResponse['url'];

export type FetchRequestDemoListState = {
  loading: boolean;
  data: RequestDemoList;
  error: string;
};
export type ExportRequestDemoListState = {
  loading: boolean;
  url: RequestDemoListExport;
  error: string;
};
export type SortRequestDemoListState = {
  sortBy: keyof DemoRequest;
  sortDirections: Record<keyof DemoRequest, 'asc' | 'desc'>;
};

export interface RequestDemoListState {
  readonly fetchRequestDemoList: FetchRequestDemoListState;
  readonly exportRequestDemoList: ExportRequestDemoListState;
  readonly sortRequestDemoList: SortRequestDemoListState;
}

export const FETCH_REQUEST_DEMO_LIST_REQUEST =
  '@@superadmin/clientLogin/createMasterPassword/FETCH_REQUEST_DEMO_LIST_REQUEST';
export const FETCH_REQUEST_DEMO_LIST_SUCCESS =
  '@@superadmin/clientLogin/createMasterPassword/FETCH_REQUEST_DEMO_LIST_SUCCESS';
export const FETCH_REQUEST_DEMO_LIST_FAILURE =
  '@@superadmin/clientLogin/createMasterPassword/FETCH_REQUEST_DEMO_LIST_FAILURE';
export type FetchRequestDemoListPayload = FETCH_EXPORT_RequestForDemoPayload;
export type FetchRequestDemoListResponse = FETCH_RequestForDemoResponse | ErrorResponse;

export const EXPORT_REQUEST_DEMO_LIST_REQUEST =
  '@@superadmin/clientLogin/createMasterPassword/EXPORT_REQUEST_DEMO_LIST_REQUEST';
export const EXPORT_REQUEST_DEMO_LIST_SUCCESS =
  '@@superadmin/clientLogin/createMasterPassword/EXPORT_REQUEST_DEMO_LIST_SUCCESS';
export const EXPORT_REQUEST_DEMO_LIST_FAILURE =
  '@@superadmin/clientLogin/createMasterPassword/EXPORT_REQUEST_DEMO_LIST_FAILURE';
export type ExportRequestDemoListPayload = FETCH_EXPORT_RequestForDemoPayload;
export type ExportRequestDemoListResponse = EXPORT_RequestForDemoResponse | ErrorResponse;

export const SORT_REQUEST_DEMO_LIST = '@@superadmin/clientLogin/createMasterPassword/SORT_REQUEST_DEMO_LIST';
export type SortRequestDemoListPayload = keyof DemoRequest;
