import { action, createAsyncAction, deprecated } from 'typesafe-actions';
import * as types from './healthDashboard.actionTypes';
import { IHealthDashboardStore, IOverallHealthStore, IHealthStore, ISuggestionStore } from './healthDashboard.type';
import moment from 'moment';
const { createAction } = deprecated;

export const setCustomDates = (startDate: moment.Moment | null, endDate: moment.Moment | null) =>
	action(types.SET_HEALTH_DASHBOARD_FILTER_DATE, { startDate, endDate });

export const fetchHealthDashboardResolutionSuggestionAsync = createAsyncAction(
	types.FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_REQUEST,
	types.FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_SUCCESS,
	types.FETCH_HEALTH_DASHBOARD_RESOLUTION_SUGGESTION_FAILURE,
)<undefined, ISuggestionStore | null, string>();

export const fetchHealthDashboardCompletionSuggestionAsync = createAsyncAction(
	types.FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_REQUEST,
	types.FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_SUCCESS,
	types.FETCH_HEALTH_DASHBOARD_COMPLETION_SUGGESTION_FAILURE,
)<undefined, ISuggestionStore | null, string>();

export const fetchHealthDashboardFlagRateSuggestionAsync = createAsyncAction(
	types.FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_REQUEST,
	types.FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_SUCCESS,
	types.FETCH_HEALTH_DASHBOARD_FLAGRATE_SUGGESTION_FAILURE,
)<undefined, ISuggestionStore | null, string>();

export const fetchHealthDashboardResolutionRateAsync = createAsyncAction(
	types.FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_REQUEST,
	types.FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_SUCCESS,
	types.FETCH_HEALTH_DASHBOARD_RESOLUTION_RATE_FAILURE,
)<undefined, IHealthStore, string>();

export const fetchHealthDashboardCompletionRateAsync = createAsyncAction(
	types.FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_REQUEST,
	types.FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_SUCCESS,
	types.FETCH_HEALTH_DASHBOARD_COMPLETION_RATE_FAILURE,
)<undefined, IHealthStore, string>();

export const fetchHealthDashboardFlagRateAsync = createAsyncAction(
	types.FETCH_HEALTH_DASHBOARD_FLAG_RATE_REQUEST,
	types.FETCH_HEALTH_DASHBOARD_FLAG_RATE_SUCCESS,
	types.FETCH_HEALTH_DASHBOARD_FLAG_RATE_FAILURE,
)<undefined, IHealthStore, string>();
