import { createAsyncAction, action } from 'typesafe-actions';
import * as types from './siteActivityHistory.actionTypes';
import { SiteActivityHistoryResponse } from './type';

export const fetchSiteActivityHistory = createAsyncAction(
	types.FETCH_SITE_ACTIVITY_HISTORY_REQUEST,
	types.FETCH_SITE_ACTIVITY_HISTORY_SUCCESS,
	types.FETCH_SITE_ACTIVITY_HISTORY_FAILED,
)<{ siteID: string }, { data: SiteActivityHistoryResponse[] }, { error: string }>();

export const clearSiteActivityHistory = () => action(types.CLEAR_SITE_ACTIVITY_HISTORY);
