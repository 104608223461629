import { action, createAsyncAction } from 'typesafe-actions';
import { UpdateDepartementByRolesSagaPayload } from 'reducers/departments/type';
import { AddUserI } from './type';
import * as types from './addUser.actionTypes';
import { AddUserRequest } from 'components/lms/userManagement/CreateUser/type';
export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });

export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });

export const addUser = createAsyncAction(types.ADD_USER_REQUEST, types.ADD_USER_SUCCESS, types.ADD_USER_FAILURE)<
	{ data: AddUserRequest[] },
	undefined,
	{ error: string }
>();
