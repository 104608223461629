import { FetchDailyChartPayload, FetchDailyChartResponse } from 'reducers/dashboard/overview/dailyChart/types';
import { apiURL } from '../../../../config/baseURL';
import { getDummyDailyChart } from 'utils/dashboardDummy/overview/dummyDailyChart';
import API from 'helpers/api';

export const fetchOverviewDailyChart = async (payload: FetchDailyChartPayload): Promise<FetchDailyChartResponse> => {
	const { isDummy, ...query } = payload;
	// HANDLE DUMMY DATA
	if (isDummy) {
		const dummyDailyChart = getDummyDailyChart(payload);
		return {
			message: 'SUCCESS',
			data: dummyDailyChart,
		};
	}

	try {
		const token = await API.getFirebaseToken();
		// eslint-disable-next-line no-undef
		const options: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: token,
			},
			body: JSON.stringify(query),
		};
		const url = `${apiURL}/statistics/sites/completion-daily`;
		const res = await fetch(url, options);

		if (res.status !== 200) {
			throw new Error('');
		}

		const dailyChartResult: FetchDailyChartResponse = await res.json();
		return dailyChartResult;
	} catch {
		const errorResult: FetchDailyChartResponse = {
			message: 'FAILED',
		};
		return errorResult;
	}
};
