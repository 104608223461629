import { OrganizationSKU } from 'nimbly-common';
import { ActionType, Reducer } from 'typesafe-actions';

import * as types from './sku.actionTypes';
import * as actions from './sku.action';

type EditingSKU = {
  key: string;
  value: OrganizationSKU;
  isNew: boolean;
};
export type SKUState = {
  skuModalVisible: boolean;
  isUploading: boolean;
  editingSKU: null | EditingSKU;
};

export type SKUAction = ActionType<typeof actions>;

const initialState: SKUState = {
  skuModalVisible: false,
  isUploading: false,
  editingSKU: null
};

const skuReducer: Reducer<SKUState, SKUAction> = (state = initialState, action: SKUAction): SKUState => {
  switch (action.type) {
    case types.SHOW_SKU_MODAL:
      return {
        ...state,
        skuModalVisible: true
      };
    case types.HIDE_SKU_MODAL:
      return {
        ...state,
        skuModalVisible: false
      };
    case types.SET_UPLOADING_STATE:
      return {
        ...state,
        isUploading: action.payload.bool
      };
    case types.SET_EDITING_SKU:
      return {
        ...state,
        editingSKU: {
          isNew: false,
          key: action.payload.skuKey,
          value: { ...action.payload.skuProfile }
        }
      };
    case types.REMOVE_EDITING_SKU:
      return {
        ...state,
        editingSKU: null
      };
    case types.UPDATE_SITE_SKU:
      return {
        ...state,
        skuModalVisible: true,
        editingSKU: {
          key: action.payload.skuKey,
          value: { ...action.payload.skuProfile },
          isNew: false
        }
      };
    case types.CREAT_NEW_SITE_SKU:
      return {
        ...state,
        skuModalVisible: true,
        editingSKU: {
          key: '',
          value: { ...action.payload.skuProfile },
          isNew: true
        }
      };
    default:
      return state;
  }
};

export default skuReducer;
