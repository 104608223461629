import { BaseSelectOptionType } from 'components/global/Select/utils/optionsMapper';
import { UserGroupResponse, BaseStatus } from '@nimbly-technologies/nimbly-common';

export enum USER_GROUPS_FETCH_STATE {
	LOADING,
	SUCCESS,
	ERROR,
}

export type SortDirection = 'asc' | 'desc';

export interface UserListI {
	userName: string;
	email: string;
	userGroups: string[];
	lmsRole: string;
}

export type UserGroupPopulatedResponse = UserGroupResponse & { status: BaseStatus; populated?: any };
export interface UserGroupListState {
	pageIndex: number;
	limit: number;
	search: string;
	sortBy: string;
	sortDirection: SortDirection;
	fetchState: USER_GROUPS_FETCH_STATE;
	userGroupList: UserGroupPopulatedResponse[];
	totalUserGroups: number;
	selectedUserGroupCount: number;
	isLoading: boolean;
	isSuccess: boolean;
}

export interface GetUserRequestInterface {
	pageIndex: number;
	limit: number;
	search: string;
}

export interface LmsUser {
	message: string;
	data: LmsUserData;
}

export interface LmsUserData {
	docs: SingleUser[];
	totalDocs: number;
	limit: number;
	page: number;
	totalPages: number;
}

export interface SingleUser {
	_id: string;
	userID: string;
	__v?: number;
	lastLoginDate: number;
	lmsRole: string;
	lmsStatus: string;
	userGroupIDs: string[];
	verificationDate: number;
	populated: Populated;
}

export interface Populated {
	user: User;
	userGroups: UserGroup[];
	groupCount: number;
	courses: any[];
	syllabus: any[];
	allEnrolledCourses: any[];
	allEnrolledCourseCount: number;
}

export interface User {
	userID: string;
	clearedTutorial?: boolean;
	displayName: string;
	email: string;
	language: string;
	role: string;
	status: string;
	uuid: string;
	__v?: number;
}

export interface UserGroup {
	groupID: string;
	__v: number;
	description: string;
	groupName: string;
	status: string;
}
