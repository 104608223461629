import { FunctionReturn, SiteGroupListResponse } from '@nimbly-technologies/nimbly-common';
import { SITE_GROUPS_BASE_URL } from 'config/baseURL';
import API from 'helpers/api';
import { CreateSiteGroupPayload, QuerySiteGroupsParams, UpdateSiteGroupPayload } from './siteGroups.servicesTypes';

export const querySiteGroups = (params: QuerySiteGroupsParams) => {
	return API.getJSON<FunctionReturn<SiteGroupListResponse[]>>(`${SITE_GROUPS_BASE_URL}`, undefined, {
		...params,
	});
};

export const createSiteGroup = (payload: CreateSiteGroupPayload) => {
	return API.postJSON<FunctionReturn<SiteGroupListResponse>>(SITE_GROUPS_BASE_URL, payload);
};

export type UpdateSiteGroupParams = Parameters<typeof updateSiteGroup>;
export const updateSiteGroup = (siteGroupId: string, payload: UpdateSiteGroupPayload) => {
	return API.putJSON<FunctionReturn<null>>(`${SITE_GROUPS_BASE_URL}/${siteGroupId}`, payload);
};

export const querySiteGroupById = (siteGroupId: string) => {
	return API.getJSON<FunctionReturn<SiteGroupListResponse>>(`${SITE_GROUPS_BASE_URL}/${siteGroupId}`);
};

export const toggleSiteGroupActiveStatus = (siteGroupId: string) => {
	return API.putJSON<FunctionReturn<null>>(`${SITE_GROUPS_BASE_URL}/toggle-status/${siteGroupId}`, undefined);
};
