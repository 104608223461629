import * as Common from 'nimbly-common';
import { FetchOverviewStatPayload, IssuesOverviewStat } from 'reducers/dashboard/issues/overviewStat/types';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

import org_issuesCreated from '../../../assets/dummy/json/issuesOverviewStat/org-issuesCreated.json';
import org_issuesResolved from '../../../assets/dummy/json/issuesOverviewStat/org-issuesResolved.json';
import org_issuesAvgTime from '../../../assets/dummy/json/issuesOverviewStat/org-issuesAvgTime.json';
import org_issuesDue from '../../../assets/dummy/json/issuesOverviewStat/org-issuesDue.json';
import org_issuesOverdue from '../../../assets/dummy/json/issuesOverviewStat/org-issuesOverdue.json';

const arr_dummyIssuesOverviewStat_31D: IssuesOverviewStat[] = new Array(31).fill('with_date').map((_, dateIndex) => {
  const issuesCreated = org_issuesCreated[dateIndex];
  const issuesDue = org_issuesDue[dateIndex];
  const issuesOverdue = org_issuesOverdue[dateIndex];
  const prevIssuesOverdue = issuesOverdue - (issuesOverdue % 3);
  const issuesCompletedAvgTime = org_issuesAvgTime[dateIndex];
  const issuesCompleted = org_issuesResolved[dateIndex];
  const issuesCompletionRate = issuesCompleted / issuesDue;
  const prevIssuesCompletionRate = issuesCompletionRate < 0.5 ? issuesCompletionRate * 2 : issuesCompletionRate / 2;

  return {
    //USED
    issuesCreated,
    issuesDue,
    issuesOverdue,
    prevIssuesOverdue,
    issuesCompletedAvgTime,
    issuesCompleted,
    issuesCompletionRate,
    prevIssuesCompletionRate,
    // UNUSED
    prevIssuesCompletedAvgTime: 0,
    issuesResolved: 0,
    issuesResolvedAvgTime: 0,
    prevIssuesCompleted: 0,
    prevIssuesCreated: 0,
    prevIssuesDue: 0,
    prevIssuesResolved: 0,
    prevIssuesResolvedAvgTime: 0
  };
});

export const getDummyIssuesOverviewStat = (payload: FetchOverviewStatPayload) => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let arr_dummyIssuesOverviewStats: IssuesOverviewStat[] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    arr_dummyIssuesOverviewStats = arr_dummyIssuesOverviewStat_31D.slice(-diffInDay);
  } else {
    arr_dummyIssuesOverviewStats = arr_dummyIssuesOverviewStat_31D.slice(0, diffInDay);
  }

  const reduced_dummyIssuesOverviewStat = arr_dummyIssuesOverviewStats.reduce((curr, acc) => {
    const totalIssuesCompleted = curr.issuesCompleted + acc.issuesCompleted;
    const totalIssuesDue = curr.issuesDue + acc.issuesDue;
    const totalIssuesCompletionRate = totalIssuesCompleted / totalIssuesDue;
    const totalPrevIssuesCompletionRate =
      (totalIssuesCompleted + acc.issuesCompleted) / (totalIssuesDue + acc.issuesDue);

    return {
      //USED
      issuesCreated: curr.issuesCreated + acc.issuesCreated,
      issuesDue: totalIssuesDue,
      issuesOverdue: curr.issuesOverdue + acc.issuesOverdue,
      prevIssuesOverdue: curr.prevIssuesOverdue + acc.prevIssuesOverdue,
      issuesCompletedAvgTime: curr.issuesCompletedAvgTime + acc.issuesCompletedAvgTime,
      issuesCompleted: totalIssuesCompleted,
      issuesCompletionRate: totalIssuesCompletionRate,
      prevIssuesCompletionRate: totalPrevIssuesCompletionRate,
      // UNUSED
      prevIssuesCompletedAvgTime: 0,
      issuesResolved: 0,
      issuesResolvedAvgTime: 0,
      prevIssuesCompleted: 0,
      prevIssuesCreated: 0,
      prevIssuesDue: 0,
      prevIssuesResolved: 0,
      prevIssuesResolvedAvgTime: 0
    };
  });

  return reduced_dummyIssuesOverviewStat;
};
