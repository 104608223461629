import { ActionType, createReducer } from 'typesafe-actions';
import { MyCoursesAndSyllabusesState, FetchState } from './type.d';
import update from 'immutability-helper';
import * as actions from './myCourseAndSyllabuses.action';
import * as types from './myCourseAndSyllabuses.actionTypes';

export type MyCourseAndSyllabusesAction = ActionType<typeof actions>;

export const initialState: MyCoursesAndSyllabusesState = {
	error: '',
	fetchState: FetchState.SUCCESS,
	myCoursesAndSyllabuses: [],

	totalPages: 0,
	totalDocs: 0,
	page: 1,
	limit: 15,

	sortField: 'title',
	sortDirection: 'asc',
	search: '',

	filters: {
		type: [],
		status: [],
	},
};

export const lmsMyCoursesAndSyllabusesReducer = createReducer<MyCoursesAndSyllabusesState, MyCourseAndSyllabusesAction>(
	initialState,
)
	.handleType(types.SET_MY_COURSES_AND_SYLLABUSES_PAGE_INDEX, (state, action) =>
		update(state, {
			fetchState: { $set: FetchState.LOADING },
			page: { $set: action.payload.page },
		}),
	)
	.handleType(types.SET_MY_COURSES_AND_SYLLABUSES_SEARCH_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: FetchState.LOADING },
			page: { $set: initialState.page },
			search: { $set: action.payload.searchQuery },
		}),
	)
	.handleType(types.SET_MY_COURSES_AND_SYLLABUSES_SORT, (state, action) =>
		update(state, {
			fetchState: { $set: FetchState.LOADING },
			page: { $set: 1 },
			sortField: { $set: action.payload.sortField },
			sortDirection: { $set: action.payload.sortDirection },
		}),
	)
	.handleType(types.SET_MY_COURSES_AND_SYLLABUSES_FILTER_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: FetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: { ...state.filters, ...action.payload.filters } },
		}),
	)
	.handleType(types.RESET_MY_COURSES_AND_SYLLABUSES_FILTER_QUERY, (state) =>
		update(state, {
			fetchState: { $set: FetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_MY_COURSES_AND_SYLLABUSES_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: FetchState.LOADING },
			myCoursesAndSyllabuses: { $set: initialState.myCoursesAndSyllabuses },
			page: { $set: initialState.page },
			sortField: { $set: initialState.sortField },
			sortDirection: { $set: initialState.sortDirection },
			search: { $set: initialState.search },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_MY_COURSES_AND_SYLLABUSES_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: FetchState.SUCCESS },
			myCoursesAndSyllabuses: { $set: action.payload.myCoursesAndSyllabuses },
			totalDocs: { $set: action.payload.totalDocs || 0 },
			page: { $set: action.payload.page || 0 },
			totalPages: { $set: action.payload.totalPages || 0 },
		}),
	)
	.handleType(types.FETCH_MY_COURSES_AND_SYLLABUSES_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: FetchState.ERROR },
			myCoursesAndSyllabuses: { $set: initialState.myCoursesAndSyllabuses },
		}),
	);
