/* eslint-disable */
import * as actions from 'reducers/quizzes/quizzes.action';
import * as types from 'reducers/quizzes/quizzes.actionTypes';
import queryString from 'query-string';
import { takeLatest } from 'redux-saga/effects';
import { select, call, put } from 'typed-redux-saga';
import { RootState } from 'store/rootReducers';
import { QuizState } from 'reducers/quizzes/type';
import { LMSUserRole } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import { editQuiz, patchQuiz, deleteQuiz, fetchQuizzes } from 'services/lms/quizzes/quizzes.service';
import { toast } from 'react-toastify';
import { PaginationResult, QuizPaginateResponse } from '@nimbly-technologies/nimbly-common';

export function* fetchQuizzesSaga(action: ReturnType<typeof actions.fetchQuizzes.request>) {
	try {
		if (action.payload.state) {
			yield put(actions.setQuizzesFilters({ state: [action.payload.state] }));
		}
		const { page, limit, sortField, sortDirection, search, filters }: QuizState = yield select(
			(state: RootState) => state.quizzes,
		);
		const { auth } = yield select((state: RootState) => state.firebase);
		const { lmsRole } = yield select((state: RootState) => state.userAccess);

		const newFilters = {
			state: action.payload.state ?? filters.state,
			associationStates: filters.associationStates,
			userIDs: lmsRole === LMSUserRole.INSTRUCTOR ? [auth.uid] : filters.userIDs,
			publishedDate: [
				filters?.publishedDate?.startDate?.startOf('day').valueOf(),
				filters?.publishedDate?.endDate?.endOf('day').valueOf(),
			].filter(Boolean),
			lastModifiedDate: [
				filters?.lastModified?.startDate?.startOf('day').valueOf(),
				filters?.lastModified?.endDate?.endOf('day').valueOf(),
			].filter(Boolean),
		};

		const query: Record<string, any> = {
			page,
			limit,
			sortFields: sortField,
			sortDirections: sortDirection,
			search,
			...newFilters,
		};
		const queryStr = queryString.stringify(query);
		const { docs, ...result }: PaginationResult<QuizPaginateResponse> = yield call(fetchQuizzes, queryStr);

		yield put(actions.fetchQuizzes.success({ quizzes: docs, ...result }));
	} catch (e) {
		yield put(actions.fetchQuizzes.failure({ error: 'Failed to fetch quizzes' }));
		return null;
	}
}

export function* editQuizSaga(action: ReturnType<typeof actions.editQuiz.request>) {
	try {
		yield* call(editQuiz, action.payload.quizID, action.payload.editedQuiz);
		yield put(actions.editQuiz.success());
		toast.success(action.payload.successMessage);

		yield put(actions.fetchQuizzes.request({}));
	} catch (e) {
		toast.error(e as string);
		yield put(actions.editQuiz.failure({ error: e as string }));
		return null;
	}
}

export function* patchQuizSaga(action: ReturnType<typeof actions.patchQuiz.request>) {
	try {
		yield* call(patchQuiz, action.payload.quizID, action.payload.patchedQuiz);
		yield put(actions.patchQuiz.success());
		toast.success(action.payload.successMessage);

		yield put(actions.fetchQuizzes.request({}));
	} catch (e) {
		toast.error(e as string);
		yield put(actions.patchQuiz.failure({ error: e as string }));
		return null;
	}
}

export function* deleteQuizSaga(action: ReturnType<typeof actions.deleteQuiz.request>) {
	try {
		yield* call(deleteQuiz, action.payload.quizID);
		yield put(actions.deleteQuiz.success());
		toast.success(action.payload.successMessage);

		yield put(actions.fetchQuizzes.request({}));
	} catch (e) {
		toast.error(e as string);
		yield put(actions.deleteQuiz.failure({ error: e as string }));
		return null;
	}
}

export default function* quizzesSaga() {
	yield takeLatest(types.FETCH_QUIZZES_REQUEST, fetchQuizzesSaga);
	yield takeLatest(types.SET_QUIZZES_PAGE_INDEX, fetchQuizzesSaga);
	yield takeLatest(types.SET_QUIZZES_FILTER_QUERY, fetchQuizzesSaga);
	yield takeLatest(types.RESET_QUIZZES_FILTER_QUERY, fetchQuizzesSaga);
	yield takeLatest(types.SET_QUIZZES_SEARCH_QUERY, fetchQuizzesSaga);
	yield takeLatest(types.SET_QUIZZES_SORT, fetchQuizzesSaga);
	yield takeLatest(types.PATCH_QUIZ_REQUEST, patchQuizSaga);
	yield takeLatest(types.DELETE_QUIZ_REQUEST, deleteQuizSaga);
}
