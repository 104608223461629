import { IHealthDashboardStore, IHealthStore, ISuggestionStore } from './healthDashboard.type';
import { call, takeEvery } from 'redux-saga/effects';

import { put } from 'typed-redux-saga';
import * as actions from './healthDashboard.action';
import {
	fetchResolutionSuggestion,
	fetchCompletionSuggestion,
	fetchFlagRateSuggestion,
	fetchCompletionRate,
	fetchFlagRate,
	fetchResolutionRate,
} from 'services/healthDashboard.service';

function* fetchResolutionSuggestionSaga(
	action: ReturnType<typeof actions.fetchHealthDashboardResolutionSuggestionAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchResolutionSuggestion) as unknown) as ISuggestionStore | null;
		yield put(actions.fetchHealthDashboardResolutionSuggestionAsync.success(res));
	} catch {
		yield put(actions.fetchHealthDashboardResolutionSuggestionAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchCompletionSuggestionSaga(
	action: ReturnType<typeof actions.fetchHealthDashboardCompletionSuggestionAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchCompletionSuggestion) as unknown) as ISuggestionStore | null;
		yield put(actions.fetchHealthDashboardCompletionSuggestionAsync.success(res));
	} catch {
		yield put(actions.fetchHealthDashboardCompletionSuggestionAsync.failure('Failed to get dashboard details'));
	}
}
function* fetchFlagRateSuggestionSaga(
	action: ReturnType<typeof actions.fetchHealthDashboardFlagRateSuggestionAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchFlagRateSuggestion) as unknown) as ISuggestionStore | null;
		yield put(actions.fetchHealthDashboardFlagRateSuggestionAsync.success(res));
	} catch {
		yield put(actions.fetchHealthDashboardFlagRateSuggestionAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchResolutionRateSaga(
	action: ReturnType<typeof actions.fetchHealthDashboardResolutionRateAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchResolutionRate) as unknown) as IHealthStore;
		yield put(actions.fetchHealthDashboardResolutionRateAsync.success(res));
	} catch {
		yield put(actions.fetchHealthDashboardResolutionRateAsync.failure('Failed to get dashboard details'));
	}
}
function* fetchCompletionSaga(
	action: ReturnType<typeof actions.fetchHealthDashboardCompletionRateAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchCompletionRate) as unknown) as IHealthStore;
		yield put(actions.fetchHealthDashboardCompletionRateAsync.success(res));
	} catch {
		yield put(actions.fetchHealthDashboardCompletionRateAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchFlagRateSaga(action: ReturnType<typeof actions.fetchHealthDashboardFlagRateAsync.request>): Generator {
	try {
		const res = (yield call(fetchFlagRate) as unknown) as IHealthStore;
		yield put(actions.fetchHealthDashboardFlagRateAsync.success(res));
	} catch {
		yield put(actions.fetchHealthDashboardFlagRateAsync.failure('Failed to get dashboard details'));
	}
}

export default function* healthDashboardSaga() {
	yield takeEvery(actions.fetchHealthDashboardResolutionSuggestionAsync.request, fetchResolutionSuggestionSaga);
	yield takeEvery(actions.fetchHealthDashboardCompletionSuggestionAsync.request, fetchCompletionSuggestionSaga);
	yield takeEvery(actions.fetchHealthDashboardFlagRateSuggestionAsync.request, fetchFlagRateSuggestionSaga);

	yield takeEvery(actions.fetchHealthDashboardResolutionRateAsync.request, fetchResolutionRateSaga);
	yield takeEvery(actions.fetchHealthDashboardCompletionRateAsync.request, fetchCompletionSaga);
	yield takeEvery(actions.fetchHealthDashboardFlagRateAsync.request, fetchFlagRateSaga);
}
