import { ActionType, createReducer } from 'typesafe-actions';
import { LmsCourseQuizStore } from './lmsCourseQuiz';
import * as types from './lmsCourseQuiz.actionTypes';
import actions from './lmsCourseQuiz.actions';

export type LmsCourseQuizAction = ActionType<typeof actions>;

const queryMutationInitialState = {
	data: null,
	error: '',
	isLoading: false,
};

export const lmsCourseQuizStoreInitialState: LmsCourseQuizStore = {
	selectedQuizId: null,
	currentQuestionIndex: 0,
	step: 'welcome',
	timeoutModalVisible: false,
	isSummaryQuestionsMobileVisible: false,

	queryById: queryMutationInitialState,
	startMutation: queryMutationInitialState,
	retryMutation: queryMutationInitialState,
	querySubmissionDraftBySubmitId: queryMutationInitialState,
	updateSubmissionDraftMutation: queryMutationInitialState,
	submitSubmissionMutation: queryMutationInitialState,
};

export const lmsCourseQuizReducer = createReducer<LmsCourseQuizStore, LmsCourseQuizAction>(
	lmsCourseQuizStoreInitialState,
)
	.handleAction(
		(types.SET_LMS_COURSE_QUIZ_SELECTED_ID as unknown) as typeof actions.setSelectedQuizId,
		(state, action) => ({
			...state,
			selectedQuizId: action.payload,
			querySubmissionDraftBySubmitId: queryMutationInitialState,
		}),
	)
	.handleAction(actions.setCurrentQuestionIndex, (state, action) => ({
		...state,
		currentQuestionIndex: action.payload,
	}))
	.handleAction(actions.setStep, (state, action) => ({
		...state,
		step: action.payload,
	}))
	.handleAction(actions.setTimeoutModalVisible, (state, action) => ({
		...state,
		timeoutModalVisible: action.payload,
	}))
	.handleAction(actions.setIsSummarQuestionsMobileVisible, (state, action) => ({
		...state,
		isSummaryQuestionsMobileVisible: action.payload,
	}))

	.handleAction(
		actions.queryCourseById.request,
		(state): LmsCourseQuizStore => ({
			...state,
			queryById: {
				...state.queryById,
				isLoading: true,
				error: '',
			},
		}),
	)
	.handleAction(
		actions.queryCourseById.success,
		(state, action): LmsCourseQuizStore => ({
			...state,
			queryById: {
				...state.queryById,
				isLoading: false,
				data: action.payload,
			},
		}),
	)
	.handleAction(
		actions.queryCourseById.failure,
		(state, action): LmsCourseQuizStore => ({
			...state,
			queryById: {
				...state.queryById,
				isLoading: false,
				error: action.payload.error,
			},
		}),
	)
	.handleAction(
		actions.startQuiz.request,
		(state): LmsCourseQuizStore => ({
			...state,
			startMutation: {
				...state.startMutation,
				isLoading: true,
				error: '',
			},
		}),
	)
	.handleAction(
		actions.startQuiz.success,
		(state, action): LmsCourseQuizStore => ({
			...state,
			startMutation: {
				...state.startMutation,
				isLoading: false,
				data: action.payload,
			},
		}),
	)
	.handleAction(
		actions.startQuiz.failure,
		(state, action): LmsCourseQuizStore => ({
			...state,
			startMutation: {
				...state.startMutation,
				isLoading: false,
				error: action.payload.error,
			},
		}),
	)
	.handleAction(
		actions.querySubmissionDraftBySubmitId.request,
		(state): LmsCourseQuizStore => ({
			...state,
			querySubmissionDraftBySubmitId: {
				...state.querySubmissionDraftBySubmitId,
				isLoading: true,
				error: '',
			},
		}),
	)
	.handleAction(
		actions.querySubmissionDraftBySubmitId.success,
		(state, action): LmsCourseQuizStore => ({
			...state,
			querySubmissionDraftBySubmitId: {
				...state.querySubmissionDraftBySubmitId,
				isLoading: false,
				data: action.payload,
			},
		}),
	)
	.handleAction(
		actions.querySubmissionDraftBySubmitId.failure,
		(state, action): LmsCourseQuizStore => ({
			...state,
			querySubmissionDraftBySubmitId: {
				...state.querySubmissionDraftBySubmitId,
				isLoading: false,
				error: action.payload.error,
			},
		}),
	)
	.handleAction(
		actions.updateSubmissionDraft.request,
		(state): LmsCourseQuizStore => ({
			...state,
			updateSubmissionDraftMutation: {
				...state.updateSubmissionDraftMutation,
				isLoading: true,
				error: '',
			},
		}),
	)
	.handleAction(
		actions.updateSubmissionDraft.success,
		(state, action): LmsCourseQuizStore => ({
			...state,
			updateSubmissionDraftMutation: {
				...state.updateSubmissionDraftMutation,
				isLoading: false,
				data: action.payload,
			},
		}),
	)
	.handleAction(
		actions.updateSubmissionDraft.failure,
		(state, action): LmsCourseQuizStore => ({
			...state,
			updateSubmissionDraftMutation: {
				...state.updateSubmissionDraftMutation,
				isLoading: false,
				error: action.payload.error,
			},
		}),
	)
	.handleAction(
		actions.submitSubmission.request,
		(state): LmsCourseQuizStore => ({
			...state,
			submitSubmissionMutation: {
				...state.submitSubmissionMutation,
				isLoading: true,
				error: '',
			},
		}),
	)
	.handleAction(
		actions.submitSubmission.success,
		(state, action): LmsCourseQuizStore => ({
			...state,
			submitSubmissionMutation: {
				...state.submitSubmissionMutation,
				isLoading: false,
				data: action.payload,
			},
		}),
	)
	.handleAction(
		actions.submitSubmission.failure,
		(state, action): LmsCourseQuizStore => ({
			...state,
			submitSubmissionMutation: {
				...state.submitSubmissionMutation,
				isLoading: false,
				error: action.payload.error,
			},
		}),
	)
	.handleAction(
		actions.retryQuiz.request,
		(state): LmsCourseQuizStore => ({
			...state,
			retryMutation: {
				...state.retryMutation,
				isLoading: true,
				error: '',
			},
		}),
	)
	.handleAction(
		actions.retryQuiz.success,
		(state, action): LmsCourseQuizStore => ({
			...state,
			retryMutation: {
				...state.retryMutation,
				isLoading: false,
				data: null,
			},
			queryById: {
				...state.queryById,
				data: action.payload.queryByIdResponse.data,
			},
			startMutation: {
				...state.startMutation,
				data: action.payload.startMutationResponse.data,
			},
		}),
	)
	.handleAction(
		actions.retryQuiz.failure,
		(state, action): LmsCourseQuizStore => ({
			...state,
			retryMutation: {
				...state.retryMutation,
				isLoading: false,
				error: action.payload.error,
			},
		}),
	);
