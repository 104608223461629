import { call, put, select, takeLeading } from 'redux-saga/effects';

import { apiURL } from 'config/baseURL';
import { getToken } from '../api';
import * as actions from './organizations.actions';
import { APIResponse } from '../../constants/ResponseCollection';
import * as types from './organizations.actionsTypes';
import { RootState } from 'store/rootReducers';
import { OrganizationsState } from './organizations.types';

function getOrganizationState(state: RootState) {
  return state.organization;
}

function* fetchOrganizations() {
  const organizationState: OrganizationsState = yield select(getOrganizationState);

  if (organizationState.organization && organizationState.timestamp) {
    const now = Date.now();

    const diff = (now - organizationState.timestamp) / 1000;
    // if less than 30 mins no need to re fetch
    const threshold = 30 * 60;

    if (diff < threshold) {
      yield put(actions.fetchOrganizations.cancel());
    }
  }

  try {
    const authToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };
    const strURL = `${apiURL}/organizations/`;
    const request = () => fetch(strURL, options);
    const response = yield call(request);

    if (response && response.status === APIResponse.CODE.SUCCESS) {
      const responseData = yield response.json();
      yield put(actions.fetchOrganizations.success({ data: responseData.data }));
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchOrganizations.failure({ error: responseData.message }));
    }
  } catch (e) {
    yield put(actions.fetchOrganizations.failure({ error: 'Failed to fetch Organizations' }));
  }
}

export default function* organizationsSaga() {
  yield takeLeading(types.GET_ORGANIZATION_REQUEST, fetchOrganizations);
}
