import { createAsyncAction, action, deprecated } from 'typesafe-actions';
import { UserConfig } from 'nimbly-common';

import * as types from './users.actionType';
import { Users, UserRoles } from './type';

const { createAction } = deprecated;

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const fetchUsers = createAsyncAction(
	types.FETCH_USERS_REQUEST,
	types.FETCH_USERS_SUCCESS,
	types.FETCH_USERS_FAILURE,
)<undefined, { data: Users | null }, { error: string }>();

export const fetchUserConfig = createAsyncAction(
	types.FETCH_USER_CONFIG_REQUEST,
	types.FETCH_USER_CONFIG_SUCCESS,
	types.FETCH_USER_CONFIG_FAILURE,
)<undefined, { data: UserConfig[] }, { error: string }>();

export const setUserConfig = createAction(types.SET_USER_CONFIG, (action) => (data: UserConfig[]) => action({ data }));

export const fetchUserRole = createAsyncAction(
	types.FETCH_USER_ROLE_REQUEST,
	types.FETCH_USER_ROLE_SUCCESS,
	types.FETCH_USER_ROLE_FAILURE,
)<undefined, { data: UserRoles }, { error: string }>();

export const fetchSiteOwners = createAsyncAction(
	types.FETCH_USER_OWNER_REQUEST,
	types.FETCH_USER_OWNER_SUCCESS,
	types.FETCH_USER_OWNER_FAILURE,
)<undefined, any, { error: string }>();

export const fetchUserSchedule = createAsyncAction(
	types.FETCH_USER_SCHEDULE_REQUEST,
	types.FETCH_USER_SCHEDULE_SUCCESS,
	types.FETCH_USER_SCHEDULE_FAILURE,
)<undefined, any, { error: string }>();

interface FetchPaginateUsersPayload {
	withLMSUsers?: boolean;
	limit: number;
}

export const fetchPaginateUsers = createAsyncAction(
	types.FETCH_PAGINATE_USERS_REQUEST,
	types.FETCH_PAGINATE_USERS_SUCCESS,
	types.FETCH_PAGINATE_USERS_FAILURE,
)<FetchPaginateUsersPayload, { data: Users | null; total: number | null }, { error: string }>();

export const fetchUsersDataVisibility = createAsyncAction(
	types.FETCH_USERS_DATA_VISIBILITY_REQUEST,
	types.FETCH_USERS_DATA_VISIBILITY_SUCCESS,
	types.FETCH_USERS_DATA_VISIBILITY_FAILURE,
)<undefined, { data: Users | null }, { error: string }>();

export const fetchBaseRoleUsers = createAsyncAction(
	types.FETCH_BASE_ROLE_REQUEST,
	types.FETCH_BASE_ROLE_SUCCESS,
	types.FETCH_BASE_ROLE_FAILURE,
)<undefined, { data: Users | null }, { error: string }>();
