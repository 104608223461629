export const SET_LOADING = '@users/SET_LOADING';

export const FETCH_USERS_REQUEST = '@users/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = '@users/FETCH_USERS_LOADING';
export const FETCH_USERS_FAILURE = '@users/FETCH_USERS_FAILURE';

export const FETCH_USER_ROLE_REQUEST = '@departmentEditor/FETCH_USER_ROLE_REQUEST';
export const FETCH_USER_ROLE_SUCCESS = '@departmentEditor/FETCH_USER_ROLE_SUCCESS';
export const FETCH_USER_ROLE_FAILURE = '@departmentEditor/FETCH_USER_ROLE_FAILURE';

export const FETCH_USER_OWNER_REQUEST = '@users/FETCH_USER_OWNER_REQUEST';
export const FETCH_USER_OWNER_SUCCESS = '@users/FETCH_USER_OWNER_SUCCESS';
export const FETCH_USER_OWNER_FAILURE = '@users/FETCH_USER_OWNER_FAILURE';

export const FETCH_USER_CONFIG_REQUEST = '@userConfig/FETCH_USER_CONFIG_REQUEST';
export const FETCH_USER_CONFIG_SUCCESS = '@userConfig/FETCH_USER_CONFIG_SUCCESS';
export const FETCH_USER_CONFIG_FAILURE = '@userConfig/FETCH_USER_CONFIG_FAILURE';

export const FETCH_USER_SCHEDULE_REQUEST = '@user/FETCH_USER_SCHEDULE_REQUEST';
export const FETCH_USER_SCHEDULE_SUCCESS = '@user/FETCH_USER_SCHEDULE_SUCCESS';
export const FETCH_USER_SCHEDULE_FAILURE = '@user/FETCH_USER_SCHEDULE_FAILURE';

export const FETCH_PAGINATE_USERS_REQUEST = '@users/FETCH_PAGINATE_USERS_REQUEST';
export const FETCH_PAGINATE_USERS_SUCCESS = '@users/FETCH_PAGINATE_USERS_SUCCESS';
export const FETCH_PAGINATE_USERS_FAILURE = '@users/FETCH_PAGINATE_USERS_FAILURE';

export const SET_USER_CONFIG = '@userConfig/SET_USER_CONFIG';

export const FETCH_USERS_DATA_VISIBILITY_REQUEST = '@users/FETCH_USERS_DATA_VISIBILITY_REQUEST';
export const FETCH_USERS_DATA_VISIBILITY_SUCCESS = '@users/FETCH_USERS_DATA_VISIBILITY_SUCCESS';
export const FETCH_USERS_DATA_VISIBILITY_FAILURE = '@users/FETCH_USERS_DATA_VISIBILITY_FAILURE';

export const FETCH_BASE_ROLE_REQUEST = '@users/FETCH_BASE_ROLE_REQUEST';
export const FETCH_BASE_ROLE_SUCCESS = '@users/FETCH_BASE_ROLE_SUCCESS';
export const FETCH_BASE_ROLE_FAILURE = '@users/FETCH_BASE_ROLE_FAILURE';
