import { createReducer } from 'typesafe-actions';
import * as Common from 'nimbly-common';
import * as types from './actionType';
import { TrendsAnalyticsState, TrendsAnalyticsAction } from './types';

const initialState: TrendsAnalyticsState = {
  selectedQuestionnaire: null,
  selectedQuestionCategory: null,
  selectedQuestionType: 'multiple-choice' as Common.enums.QuestionTypes,
  data: [],
  isRequesting: false,
  isEmpty: true
};

export default createReducer<TrendsAnalyticsState, TrendsAnalyticsAction>(initialState)
  .handleAction(types.START_REQUESTING_CHART as any, state => ({
    ...state,
    isRequesting: true
  }))
  .handleAction(types.LOAD_CHART as any, (state, action) => ({
    ...state,
    data: action.payload.data
  }))
  .handleAction(types.FINISH_REQUESTING_CHART as any, state => ({
    ...state,
    isRequesting: false
  }))
  .handleAction(types.IS_DATA_EMPTY as any, (state, action) => ({
    ...state,
    isEmpty: action.payload.isEmpty
  }))
  .handleAction(types.SET_QUESTIONNAIRE as any, (state, action) => ({
    ...state,
    selectedQuestionnaire: action.payload.questionnaireKey
  }))
  .handleAction(types.SET_QUESTION_CATEGORY as any, (state, action) => ({
    ...state,
    selectedQuestionCategory: action.payload.categoryName
  }))
  .handleAction(types.SET_QUESTION_TYPE as any, (state, action) => ({
    ...state,
    selectedQuestionType: action.payload.questionType
  }))
  .handleAction(types.FETCH_TRENDS_CHART_REQUEST as any, (state, action) => ({
    ...state,
    isRequesting: true
  }))
  .handleAction(types.FETCH_TRENDS_CHART_SUCCESS as any, (state, action) => ({
    ...state,
    isRequesting: false,
    data: action.payload.data,
    isEmpty: action.payload.isEmpty
  }))
  .handleAction(types.FETCH_TRENDS_CHART_FAILURE as any, (state, action) => ({
    ...state,
    isRequesting: false
  }));
