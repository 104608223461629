export const SHOW_MODAL = 'salestarget/SHOW_MODAL';
export const DISMISS_MODAL = 'salestarget/DISMISS_MODAL';
export const SET_PERIOD = 'salestarget/SET_PERIOD';
export const SET_FILTER_QUERY = 'salestarget/SET_FILTER_QUERY';
export const CLEAR_FILTER_QUERY = 'salestarget/CLEAR_FILTER_QUERY';
export const SET_SORT = 'salestarget/SET_SITES_SORT';
export const SET_SCROLL = 'salestarget/SET_SCROLL';
export const SET_PAGE_INDEX = 'salestarget/SET_PAGE_INDEX';
export const RESET_BULK = 'salestarget/RESET_BULK';

// ActionSagas

// All
export const FETCH_PAGINATE_SALESTARGET_REQUEST = 'SALESTARGET/FETCH_PAGINATE_SALESTARGET_REQUEST';
export const FETCH_PAGINATE_SALESTARGET_SUCCESS = 'SALESTARGET/FETCH_PAGINATE_SALESTARGET_SUCCESS';
export const FETCH_PAGINATE_SALESTARGET_FAILURE = 'SALESTARGET/FETCH_PAGINATE_SALESTARGET_FAILURE';

// Sales

export const FETCH_SALES_BY_ID_REQUEST = 'SALESTARGET/FETCH_SALES_BY_ID_REQUEST';
export const FETCH_SALES_BY_ID_SUCCESS = 'SALESTARGET/FETCH_SALES_BY_ID_SUCCESS';
export const FETCH_SALES_BY_ID_FAILURE = 'SALESTARGET/FETCH_SALES_BY_ID_FAILURE';

export const CREATE_SALES_BY_ID_REQUEST = 'SALESTARGET/CREATE_SALES_BY_ID_REQUEST';
export const CREATE_SALES_BY_ID_SUCCESS = 'SALESTARGET/CREATE_SALES_BY_ID_SUCCESS';
export const CREATE_SALES_BY_ID_FAILURE = 'SALESTARGET/CREATE_SALES_BY_ID_FAILURE';

export const UPDATE_SALES_BY_ID_REQUEST = 'SALESTARGET/UPDATE_SALES_BY_ID_REQUEST';
export const UPDATE_SALES_BY_ID_SUCCESS = 'SALESTARGET/UPDATE_SALES_BY_ID_SUCCESS';
export const UPDATE_SALES_BY_ID_FAILURE = 'SALESTARGET/UPDATE_SALES_BY_ID_FAILURE';

export const CREATE_SALES_BULK_REQUEST = 'SALESTARGET/CREATE_SALES_BULK_REQUEST';
export const CREATE_SALES_BULK_SUCCESS = 'SALESTARGET/CREATE_SALES_BULK_SUCCESS';
export const CREATE_SALES_BULK_FAILURE = 'SALESTARGET/CREATE_SALES_BULK_FAILURE';

// Sales Target

export const FETCH_SALESTARGET_BY_ID_REQUEST = 'SALESTARGET/FETCH_SALESTARGET_BY_ID_REQUEST';
export const FETCH_SALESTARGET_BY_ID_SUCCESS = 'SALESTARGET/FETCH_SALESTARGET_BY_ID_SUCCESS';
export const FETCH_SALESTARGET_BY_ID_FAILURE = 'SALESTARGET/FETCH_SALESTARGET_BY_ID_FAILURE';

export const CREATE_SALESTARGET_BY_ID_REQUEST = 'SALESTARGET/CREATE_SALESTARGET_BY_ID_REQUEST';
export const CREATE_SALESTARGET_BY_ID_SUCCESS = 'SALESTARGET/CREATE_SALESTARGET_BY_ID_SUCCESS';
export const CREATE_SALESTARGET_BY_ID_FAILURE = 'SALESTARGET/CREATE_SALESTARGET_BY_ID_FAILURE';

export const UPDATE_SALESTARGET_BY_ID_REQUEST = 'SALESTARGET/UPDATE_SALESTARGET_BY_ID_REQUEST';
export const UPDATE_SALESTARGET_BY_ID_SUCCESS = 'SALESTARGET/UPDATE_SALESTARGET_BY_ID_SUCCESS';
export const UPDATE_SALESTARGET_BY_ID_FAILURE = 'SALESTARGET/UPDATE_SALESTARGET_BY_ID_FAILURE';

export const CREATE_SALESTARGET_BULK_REQUEST = 'SALESTARGET/CREATE_SALESTARGET_BULK_REQUEST';
export const CREATE_SALESTARGET_BULK_SUCCESS = 'SALESTARGET/CREATE_SALESTARGET_BULK_SUCCESS';
export const CREATE_SALESTARGET_BULK_FAILURE = 'SALESTARGET/CREATE_SALESTARGET_BULK_FAILURE';
