import { ActionType, createReducer } from 'typesafe-actions';
import { UserListState, USERS_FETCH_STATE } from './type.d';
import * as actions from './userList.action';
import * as types from './userList.actionTypes';

export type CreateUserAction = ActionType<typeof actions>;

export const userInitialState: UserListState = {
	page: 1,
	limit: 15,
	search: '',
	totalUsers: 0,
	filters: {
		courses: [],
		status: [],
		roles: [],
		verificationDate: { startDate: null, endDate: null },
		lastLoginDate: { startDate: null, endDate: null },
	},
	userList: [],
	fetchState: USERS_FETCH_STATE.SUCCESS,
	sortBy: 'userName',
	sortDirection: 'asc',
};

const getUserListReducer = createReducer<UserListState, CreateUserAction>(userInitialState)
	.handleAction(actions.getUserList.request, (state) => ({
		...state,
		limit: userInitialState.limit,
		fetchState: USERS_FETCH_STATE.LOADING,
		filters: userInitialState.filters,
		search: userInitialState.search,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.getUserList.success, (state, action) => ({
		...state,
		userList: action.payload.data,
		fetchState: USERS_FETCH_STATE.SUCCESS,
		isLoading: false,
		totalUsers: action.payload.totalDocs ?? userInitialState.totalUsers,
		isSuccess: true,
	}))
	.handleAction(actions.getUserList.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(types.SET_USER_LIST_PAGE_INDEX as any, (state, action) => ({
		...state,
		page: action.payload.page,
		fetchState: USERS_FETCH_STATE.LOADING,
	}))
	.handleAction(types.SET_USER_LIST_SEARCH_QUERY as any, (state, action) => ({
		...state,
		search: action.payload.search,
		fetchState: USERS_FETCH_STATE.LOADING,
	}))
	.handleAction(types.SET_USER_LIST_SORT as any, (state, action) => ({
		...state,
		sortBy: action.payload.sortBy,
		sortDirection: action.payload.sortDirection,
		fetchState: USERS_FETCH_STATE.LOADING,
	}))
	.handleAction(types.SET_USER_LIST_FILTER_QUERY as any, (state, action) => ({
		...state,
		filters: action.payload.filters,
		fetchState: USERS_FETCH_STATE.LOADING,
	}))
	.handleAction(types.RESET_USER_LIST_FILTER_QUERY as any, (state) => ({
		...state,
		filters: userInitialState.filters,
		fetchState: USERS_FETCH_STATE.LOADING,
	}))
	.handleAction(types.SET_SHOW_MODAL as any, (state, action) => ({
		...state,
		isShowModal: action.payload.value,
	}))
	.handleAction(types.SET_SUCCESS as any, (state, action) => {
		return {
			...state,
			isSuccess: action.payload.value,
		};
	})
	.handleAction(types.RESET_USER_FILTER as any, (state, action) => {
		return {
			...state,
			...userInitialState,
		};
	});

export { getUserListReducer };
