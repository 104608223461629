import { takeLatest, put, call, all } from 'redux-saga/effects';
import moment from 'moment';
import { getFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';

import * as types from 'reducers/competitorPromotion/promotion.actionTypes';
import * as actions from 'reducers/competitorPromotion/promotion.action';

import { uploadImage } from '../../utils/file';
import { fmcgURL, fmcgAuth } from 'config/baseURL';
import { CompetitorPromotion } from 'nimbly-common';
import { getToken } from 'reducers/api';


const apiURL = fmcgURL;

type Action = {
  type: string;
  payload: any;
  meta: string | undefined;
};

export function* fetchAllPromotion() {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchAllPromotionUrl = `${apiURL}/competitor/promotions`;
    const request = () => fetch(fetchAllPromotionUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      const mappingData: { [key: string]: any } = {};

      responseData.data.forEach((promotion: CompetitorPromotion) => {
        const promotionId: string = promotion.code;
        if (!mappingData.hasOwnProperty(promotionId)) {
          mappingData[promotionId] = promotion;
        }
      });
      yield put(
        actions.fetchPromotion.success({
          data: { mapped: responseData.data ? mappingData : [], raw: responseData.data ? responseData.data : [] }
        })
      );
      return mappingData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchPromotion.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchPromotion.success({ data: [] }));
    yield put(actions.fetchPromotion.failure({ error: 'Failed to Fetch Promotion List' }));
    return null;
  }
}

function* createPromotion(action: Action) {
  try {
    const payload = { ...action.payload };
    const firebase = getFirebase();
    const authToken = yield getToken();

    const createPromotionUrl = `${apiURL}/competitor/promotions`;

    if (payload.photos && payload.photos.length > 0) {
      const baseRef = `/competitorAnalysis/promotion`;
      const uploadTasks = payload.photos.map((photo: any) => {
        if (typeof photo === 'string') {
          return Promise.resolve(photo);
        } else {
          const imageRef = `${baseRef}/${photo.name}${moment().format('YYYY-MM-DD-X')}`;
          return uploadImage(firebase, imageRef, photo);
        }
      });
      const photoURLs = yield all(uploadTasks);
      payload.imageURL = photoURLs[0];
    } else {
      payload.imageURL = '';
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(payload)
    };

    const request = () => fetch(createPromotionUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.createPromotion.success({ data: responseData.data }));
      toast.success('Success create promotion');
    } else {
      const responseData = yield response.json();
      yield put(actions.createPromotion.failure({ error: responseData.message }));
      toast.error(responseData.message);
      return null;
    }
  } catch (e) {
    yield put(actions.createPromotion.failure({ error: 'Failed to Create Competitor Promotion' }));
    return null;
  }
}

function* createPromotionBulk(action: Action) {
  try {
    const authToken = yield getToken();
    const payload = action.payload;
    let success = [];
    let error = [];

    for (let i = 0; i < payload.length; i++) {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify(payload[i])
      };

      const createPromotionUrl = `${apiURL}/competitor/promotions`;

      const request = () => fetch(createPromotionUrl, options);
      const response = yield call(request);

      if (response && response.status === 200) {
        const responseData = yield response.json();
        yield put(actions.createPromotion.success({ data: responseData.data }));
        success.push(i);
      } else {
        const responseData = yield response.json();
        error.push(responseData.message);
      }
    }
    if (success.length === payload.length) {
      yield put(actions.createPromotionBulk.success({}));
      toast.success(`Success create ${payload.length} promotion`);
    } else {
      yield put(actions.createPromotion.failure({ error: error.join(' ') }));
      return null;
    }
  } catch (e) {
    yield put(actions.createPromotion.failure({ error: 'Failed to Create Bulk Promotion' }));
    return null;
  }
}

export function* fetchPromotionDetail(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchPromotionDetail = `${apiURL}/competitor/promotions/${action.payload}`;
    const request = () => fetch(fetchPromotionDetail, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchPromotionDetail.success({ data: responseData.data }));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchPromotionDetail.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchPromotionDetail.success({ data: null }));
    yield put(actions.fetchPromotionDetail.failure({ error: 'Failed to Fetch Promotion Detail' }));
    return null;
  }
}

function* updatePromotion(action: Action) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const firebase = getFirebase();

    if (payload.photos && payload.photos.length > 0 && typeof payload.photos !== 'string') {
      const baseRef = `/competitorAnalysis/promotion`;
      const uploadTasks = payload.photos.map((photo: any) => {
        if (typeof photo === 'string') {
          return Promise.resolve(photo);
        } else {
          const imageRef = `${baseRef}/${photo.name}${moment().format('YYYY-MM-DD-X')}`;
          return uploadImage(firebase, imageRef, photo);
        }
      });
      const photoURLs = yield all(uploadTasks);
      payload.imageURL = photoURLs[0];
    }

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(payload)
    };

    const updatePromotionUrl = `${apiURL}/competitor/promotions/${action.payload.code}`;

    const request = () => fetch(updatePromotionUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      yield put(actions.updatePromotion.success({ [payload.id]: payload }));
      toast.success('Success create promotion');
    } else {
      const responseData = yield response.json();
      yield put(actions.updatePromotion.failure({ error: responseData.message }));
      toast.error(responseData.message);
      return null;
    }
  } catch (e) {
    yield put(actions.updatePromotion.failure({ error: 'Failed to Update Promotion' }));
    return null;
  }
}

export default function* journeyPlansSaga() {
  yield takeLatest(types.FETCH_PROMOTIONS_REQUEST, fetchAllPromotion);
  yield takeLatest(types.CREATE_PROMOTION_REQUEST, createPromotion);
  yield takeLatest(types.FETCH_PROMOTION_DETAIL_REQUEST, fetchPromotionDetail);
  yield takeLatest(types.UPDATE_PROMOTION_REQUEST, updatePromotion);
  yield takeLatest(types.CREATE_PROMOTIONS_BULK_REQUEST, createPromotionBulk);
}
