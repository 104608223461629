export enum SkuCategoryDataState {
	LOADING,
	SUCCESS,
	FAILURE,
}

export interface SkuCategoryState {
	dataState: SkuCategoryDataState;
	skuCategories: string[];
	errorMessage?: string;
}
