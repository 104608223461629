import React, { lazy, Suspense } from 'react';

const LmsUserGroupList = lazy(() => import('../../components/lms/userGroupManagement/LmsUserGroupList'));

const LmsUserGroupListPage = () => {
	return (
		<Suspense fallback={<div />}>
			<LmsUserGroupList />
		</Suspense>
	);
};

export default LmsUserGroupListPage;
