import { UOM } from '@nimbly-technologies/nimbly-common';

export enum SkuUomFetchState {
	LOADING,
	SUCCESS,
	FAILURE,
}

export interface SkuUomState {
	fetchState: SkuUomFetchState;
	skuUoms: UOM[];
	errorMessage?: string;
}
