import React, { ReactNode } from 'react';
import { NavLink, match } from 'react-router-dom';
import * as H from 'history';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Container } from './NavigationItem.style';

interface NavigationItemProps {
	path: string;
	icon: ReactNode;
	title: string;
	className?: string;
	activeClassName: string;
	isActive?: <Params extends { [K in keyof Params]?: string }>(
		match: match<Params> | null,
		location: H.Location<H.LocationState>,
	) => boolean;
	isDisabled?: boolean;
}

const NavigationItem = (props: NavigationItemProps) => {
	const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
		{
			trigger: 'click',
		},
		{
			strategy: 'absolute',
		},
	);

	const render = () => {
		if (props.isDisabled) {
			return (
				<Container>
					<div className={`nav-item disabled ${props.className}`} ref={setTriggerRef}>
						{props.icon} {props.title}
					</div>
					{visible ? (
						<div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
							Feature unavailable. Please contact our support team for further information.
						</div>
					) : null}
				</Container>
			);
		}

		return (
			<Container>
				<NavLink
					exact
					to={props.path}
					activeClassName={props.activeClassName}
					isActive={props.isActive}
					className={props.className}
				>
					{props.icon} {props.title}
				</NavLink>
			</Container>
		);
	};

	return render();
};

export default NavigationItem;
