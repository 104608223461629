import { createReducer } from 'typesafe-actions';
import { ClientLoginState } from './types';
import { ClientLoginActions } from './action';
import { createOrgMasterPasswordAsync, fetchAllOrgAsync } from './masterPassword/action';
import {
  loginAsClientAsync,
  verifyAuthCodeAsync,
  clearLoginAsClientState,
  clearVerifyAuthCodeState
} from './loginAccess/action';
import { LoginAsClientState, VerifyAuthCodeState } from './loginAccess/types';

const loginAsClient_default: LoginAsClientState = {
  loading: false,
  status: 'FAILED',
  error: ''
};
const verifyAuthCode_default: VerifyAuthCodeState = {
  loading: false,
  tokenInfo: {
    clientToken: '',
    internalToken: '',
    organizationName: ''
  },
  error: ''
};

const initialState: ClientLoginState = {
  /**
   * Master Password State
   */
  fetchAllOrg: {
    loading: false,
    data: [],
    error: ''
  },
  createMasterPassword: {
    loading: false,
    status: 'FAILED',
    error: ''
  },
  /**
   * Login Access State
   */
  loginAsClient: loginAsClient_default,
  verifyAuthCode: verifyAuthCode_default
};

export const clientLoginReducer = createReducer<ClientLoginState, ClientLoginActions>(initialState)
  .handleAction(fetchAllOrgAsync.request, state => ({
    ...state,
    fetchAllOrg: {
      ...state.fetchAllOrg,
      loading: true
    }
  }))
  .handleAction(fetchAllOrgAsync.success, (state, action) => ({
    ...state,
    fetchAllOrg: {
      ...state.fetchAllOrg,
      loading: false,
      data: action.payload,
      error: ''
    }
  }))
  .handleAction(fetchAllOrgAsync.failure, (state, action) => ({
    ...state,
    fetchAllOrg: {
      ...state.fetchAllOrg,
      loading: false,
      error: action.payload
    }
  }))
  .handleAction(createOrgMasterPasswordAsync.request, state => ({
    ...state,
    createMasterPassword: {
      ...state.createMasterPassword,
      loading: true
    }
  }))
  .handleAction(createOrgMasterPasswordAsync.success, state => ({
    ...state,
    createMasterPassword: {
      ...state.createMasterPassword,
      loading: false,
      status: 'SUCCESS',
      error: ''
    }
  }))
  .handleAction(createOrgMasterPasswordAsync.failure, (state, action) => ({
    ...state,
    createMasterPassword: {
      ...state.createMasterPassword,
      loading: false,
      status: 'FAILED',
      error: action.payload
    }
  }))
  .handleAction(loginAsClientAsync.request, state => ({
    ...state,
    loginAsClient: {
      ...state.loginAsClient,
      loading: true
    }
  }))
  .handleAction(loginAsClientAsync.success, state => ({
    ...state,
    loginAsClient: {
      ...state.loginAsClient,
      loading: false,
      status: 'SUCCESS',
      error: ''
    }
  }))
  .handleAction(loginAsClientAsync.failure, (state, action) => ({
    ...state,
    loginAsClient: {
      ...state.loginAsClient,
      loading: false,
      status: 'FAILED',
      error: action.payload
    }
  }))
  .handleAction(clearLoginAsClientState, state => ({
    ...state,
    loginAsClient: loginAsClient_default
  }))
  .handleAction(verifyAuthCodeAsync.request, state => ({
    ...state,
    verifyAuthCode: {
      ...state.verifyAuthCode,
      loading: true
    }
  }))
  .handleAction(verifyAuthCodeAsync.success, (state, action) => ({
    ...state,
    verifyAuthCode: {
      ...state.verifyAuthCode,
      loading: false,
      tokenInfo: action.payload,
      error: ''
    }
  }))
  .handleAction(verifyAuthCodeAsync.failure, (state, action) => ({
    ...state,
    verifyAuthCode: {
      ...state.verifyAuthCode,
      loading: false,
      status: 'FAILED',
      error: action.payload
    }
  }))
  .handleAction(clearVerifyAuthCodeState, state => ({
    ...state,
    verifyAuthCode: verifyAuthCode_default
  }));
