import { createAsyncAction, action } from 'typesafe-actions';
import * as types from './lmsSettings.actionTypes';
import { LMSSettings } from './type';

export const setDownloadContent = (allow: boolean) => action(types.SET_DOWNLOAD_CONTENT, { allow });
export const setShowQuizAns = (show: boolean) => action(types.SET_SHOW_QUIZ_ANSWER, { show });

export const lmsSettingsAction = createAsyncAction(
	types.FETCH_LMS_SETTING_REQUEST,
	types.FETCH_LMS_SETTING_SUCCESS,
	types.FETCH_LMS_SETTING_FAILURE,
)<undefined, { data: LMSSettings; message: string }, { message: string }>();
