import styled, { css } from 'styled-components/macro';
import { neutral } from '../../../../styles';

interface MenuItemProps {
	isActive?: boolean;
	isDisabled?: boolean;
}

interface CollapseButtonProps {
	isCollapsed: boolean;
	isVisible: boolean;
	isDisabled?: boolean;
}

interface LanguageSelectorProps {
	isVisible: boolean;
}

const Container = styled.div`
	z-index: 9999;
	position: relative;
	height: 100%;
	width: 90px;
	background-color: #3cd070;

	display: flex;
	flex-direction: column;

	@media (max-width: 991px) {
		display: none;
	}
`;

const Header = styled.div`
	height: 75px;
	display: flex;
	justify-content: center;
	align-items: center;

	border-bottom: 1px solid #fff;
`;

const MenuItem = styled.a<MenuItemProps>`
	text-decoration: none;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
	width: 100%;
	height: 80px;

	transition: opacity 200ms;
	opacity: ${(props) => (props.isDisabled ? 0.7 : 1)};
	background: ${(props) => (props.isActive ? 'rgb(0,0,0,0.1)' : 'transparent')};

	span {
		max-width: 76px;
		text-align: center;
		margin-top: 8px;
		font-size: 12px;
		font-weight: 600;
		color: #fff;
	}

	:hover {
		opacity: ${(props) => (props.isDisabled ? 0.7 : 1)};
		background: ${(props) =>
			props.isDisabled ? 'transparent' : props.isActive ? 'rgb(0,0,0,0.1)' : 'rgb(255,255,255,0.15)'};
	}
`;

const MenuContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 15px;

	border-top: 1px solid #a0a4a8;
	border-bottom: 1px solid #fff;

	.tooltip-container {
		z-index: 10020;
		min-width: 275px;

		border: 0;
		border-radius: 0.3125rem;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
		padding: 0.375rem 1rem;

		font-weight: 400;
		font-size: 0.75rem;
		line-height: 1.125rem;
		color: ${neutral[800]};
	}
`;

const Footer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	height: 190px;
	padding-bottom: 20px;
	border-top: 1px solid #a0a4a8;

	${MenuItem} {
		height: 56px;
	}
`;

const Version = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	span {
		font-weight: 100;
		font-size: 8px;
		line-height: 18px;
		letter-spacing: 0.05em;
		position: absolute;
		bottom: 0;
		color: #fff;
	}
`;

const LanguageItem = styled.div`
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;

	cursor: pointer;
	margin-bottom: 16px;

	span {
		text-transform: uppercase;
		font-size: 16px;
		color: #fff;

		line-height: initial;
		margin-right: 8px;
	}
`;

const CollapseButtonTooltip = styled.div`
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #32353b;
	border-radius: 4px;
	width: 200px;
	padding: 8px;

	position: absolute;
	z-index: 1;
	top: -55px;
	left: -4px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

	span {
		font-size: 12px;
		line-height: 16px;
		color: #fff;
	}
`;

const disabledCollapseButton = css`
	background-color: #ddd;
	cursor: not-allowed;
`;

const CollapseButton = styled.div<CollapseButtonProps>`
	visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
	position: absolute;
	top: 64px;
	right: -12px;

	transform: ${(props) => (props.isCollapsed ? 'translateX(160px)' : 'translateX(0px)')};

	display: flex;
	justify-content: center;
	align-items: center;

	height: 24px;
	width: 24px;
	border-radius: 12px;
	background-color: #f6bb42;
	cursor: pointer;

	transition: transform 300ms;

	.back-arrow {
		position: absolute;
		transform: ${(props) => (props.isCollapsed ? 'rotate(0deg)' : 'rotate(-180deg)')};

		transition: transform 400ms, filter 300ms;
	}

	:hover {
		${CollapseButtonTooltip} {
			visibility: ${(props) => (props.isDisabled ? 'visible' : 'hidden')};
		}
	}

	${(props) => props.isDisabled && disabledCollapseButton};
`;

const LanguageSelector = styled.div<LanguageSelectorProps>`
	visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};

	position: absolute;
	left: 45px;
	border-radius: 4px;

	background-color: #32353b;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	padding: 8px 0;

	z-index: 5;

	> div {
		display: flex;
		justify-content: center;
		align-items: center;

		height: 32px;
		padding: 0 12px;
		font-size: 14px;
		color: #fff;
		text-transform: uppercase;

		:hover {
			background-color: #454a54;
		}
	}
`;

export {
	Container,
	Header,
	MenuContainer,
	MenuItem,
	Footer,
	LanguageItem,
	CollapseButton,
	CollapseButtonTooltip,
	LanguageSelector,
	Version,
};
