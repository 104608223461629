import {
  IssuesCategoriesChart,
  FetchIssuesCategoriesChartPayload,
  FetchIssuesCategoriesChartResponse
} from 'reducers/dashboard/overview/issuesCategoriesChart/types';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from '../../../../config/baseURL';
import { getDummyIssuesCategoriesChart } from 'utils/dashboardDummy/overview/dummyIssuesCategoriesChart';
import API from 'helpers/api';

export const fetchIssuesCategoriesChart = async (
  payload: FetchIssuesCategoriesChartPayload
): Promise<FetchIssuesCategoriesChartResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyIssuesCategoriesChart = getDummyIssuesCategoriesChart(payload);
    return {
      message: 'SUCCESS',
      data: dummyIssuesCategoriesChart
    };
  }

  try {
    const token = await API.getFirebaseToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/issues/category?${serializedQueries}`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const issuesCategoriesChartResult: IssuesCategoriesChart = await res.json();
    return {
      message: 'SUCCESS',
      data: issuesCategoriesChartResult
    };
  } catch {
    const errorResult: FetchIssuesCategoriesChartResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
