import { ManyUsersChartResponse, DashboardAuditorsPayload } from 'reducers/dashboard/fetch-types/auditors-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type ManyUsersChart = ManyUsersChartResponse['data'];

export interface ManyUsersChartState {
  readonly manyUsersChart_loading: boolean;
  readonly manyUsersChart_data: ManyUsersChart;
  readonly manyUsersChart_error: string;
}

export const FETCH_MANY_USERS_CHART_REQUEST = '@@dashboard/auditors/FETCH_MANY_USERS_CHART_REQUEST';
export const FETCH_MANY_USERS_CHART_SUCCESS = '@@dashboard/auditors/FETCH_MANY_USERS_CHART_SUCCESS';
export const FETCH_MANY_USERS_CHART_FAILURE = '@@dashboard/auditors/FETCH_MANY_USERS_CHART_FAILURE';

export type FetchManyUsersChartPayload = DashboardAuditorsPayload;
export type FetchManyUsersChartResponse = ManyUsersChartResponse | ErrorResponse;
