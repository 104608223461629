import { IssuesIssuesFlagStat } from 'reducers/dashboard/issues/issuesFlagStat/types';
import { IssueFlagStatistic } from 'nimbly-common/lib/models/statistic';

import allQuestionnaires_info from '../../../assets/dummy/json/issuesFlagStat/allQuestionnaires_info.json';
import allQuestionnaires_redFlags from '../../../assets/dummy/json/issuesFlagStat/allQuestionnaires_redFlags.json';
import allQuestionnaires_yellowFlags from '../../../assets/dummy/json/issuesFlagStat/allQuestionnaires_yellowFlags.json';

const sitesName = ['Pacific Place', 'Kota Kasablanka', 'Pondok Indah Mall', 'Mall of Indonesia', 'PIK'];
const sitesCity = ['Jakarta Selatan', 'Jakarta Pusat', 'Jakarta Selatan', 'Jakarta Utara', 'Jakarta Utara'];
const sitesTeam = ['Samuel Sandro', 'Krisnata Panji', 'Komang Mahendra', 'Tiffany Eunike', 'Suci Fransiska'];

const reduceFunc = (curr: number, acc: number) => curr + acc;
const issuesIssuesFlagStat_SCHEDULED: IssuesIssuesFlagStat['scheduled'] = allQuestionnaires_info.map(
  (questionnaireInfo, questionnaireIndex) => {
    const totalRed = allQuestionnaires_redFlags[questionnaireIndex].reduce(reduceFunc);
    const totalYellow = allQuestionnaires_yellowFlags[questionnaireIndex].reduce(reduceFunc);
    const totalIssues = totalRed + totalYellow;

    const questionnaireDetail: IssueFlagStatistic['details'] = sitesName.map((siteName, siteIndex) => {
      const date = questionnaireIndex < 10 ? `2020-03-0${questionnaireIndex}` : `2020-03-${questionnaireIndex}`;
      return {
        date,
        siteName,
        siteID: `site_${siteIndex + 1}`,
        city: sitesCity[siteIndex],
        userID: 'not_important',
        userName: sitesTeam[siteIndex],
        redCount: allQuestionnaires_redFlags[questionnaireIndex][siteIndex],
        yellowCount: allQuestionnaires_yellowFlags[questionnaireIndex][siteIndex]
      };
    });

    const filtered_questionnaireDetail = questionnaireDetail.filter(details => details.redCount || details.yellowCount);

    return {
      title: questionnaireInfo.title,
      category: questionnaireInfo.category,
      questionText: questionnaireInfo.question,
      totalRed,
      totalYellow,
      totalIssues,
      details: filtered_questionnaireDetail,
      questionnaireIndexID: `questionnaire_index_${questionnaireIndex + 1}`
    };
  }
);

export const getDummyIssuesFlagStat = () => {
  const dummyIssuesFlagStat: IssuesIssuesFlagStat = {
    adhoc: [],
    scheduled: issuesIssuesFlagStat_SCHEDULED
  };
  return dummyIssuesFlagStat;
};
