export const SET_SKU_FILTER_QUERY = '@sku/SET_SKU_FILTER_QUERY';
export const SET_SKU_PAGE_INDEX = '@sku/SET_SKU_PAGE_INDEX';
export const SET_SKU_SEARCH_QUERY = '@sku/SET_SKU_SEARCH_QUERY';
export const SET_SKU_SORT = '@sku/SET_SKU_SORT';

export const FETCH_SKUS_REQUEST = '@sku/FETCH_SKUS_REQUEST';
export const FETCH_SKUS_SUCCESS = '@sku/FETCH_SKUS_SUCCESS';
export const FETCH_SKUS_FAILED = '@sku/FETCH_SKUS_FAILED';

export const FETCH_ALL_SKUS_REQUEST = '@sku/FETCH_ALL_SKUS_REQUEST';
export const FETCH_ALL_SKUS_SUCCESS = '@sku/FETCH_ALL_SKUS_SUCCESS';
export const FETCH_ALL_SKUS_FAILED = '@sku/FETCH_ALL_SKUS_FAILED';
