import { createReducer } from 'typesafe-actions';
import { AdminManageState } from './types';
import { AdminManageAction } from './action';
import {
  createSiteAsync,
  updateSiteAsync,
  clearSiteState,
  toggleBlockSiteAsync,
  clearToggleBlockSiteState,
  checkSiteScheduleCountAsync
} from './sites/action';
import { createUserAsync, updateUserAsync, clearUserState } from './users/action';
import {
  upsertQuestionnaireAsync,
  clearQuestionnaireState,
  fetchQuestionCategoriesAsync
} from './questionnaires/action';

const initialState: AdminManageState = {
  /**
   * Sites State
   */
  upsertedSite_loading: false,
  upsertedSite_siteID: '',
  upsertedSite_error: '',
  blockSite_loading: false,
  blockSite_siteID: '',
  blockSite_error: '',
  checkSiteSchedule_loading: false,
  checkSiteSchedule_count: 0,
  checkSiteSchedule_error: '',
  /**
   * Questionnaire State
   */
  upsertedQuestionnaire_loading: false,
  upsertedQuestionnaire_questionnaireID: '',
  upsertedQuestionnaire_error: '',
  /**
   * User State
   */
  upsertedUser_loading: false,
  upsertedUser_userID: '',
  upsertedUser_error: '',
  /**
   * Questionnaire Categories State
   */
  questionCategories_loading: false,
  questionCategories_data: [],
  questionCategories_error: ''
};

export const adminManageReducer = createReducer<AdminManageState, AdminManageAction>(initialState)
  .handleAction(createSiteAsync.request, state => ({
    ...state,
    upsertedSite_loading: true
  }))
  .handleAction(createSiteAsync.success, (state, action) => ({
    ...state,
    upsertedSite_loading: false,
    upsertedSite_siteID: action.payload,
    upsertedSite_error: ''
  }))
  .handleAction(createSiteAsync.failure, (state, action) => ({
    ...state,
    upsertedSite_loading: false,
    upsertedSite_error: action.payload
  }))
  .handleAction(updateSiteAsync.request, state => ({
    ...state,
    upsertedSite_loading: true
  }))
  .handleAction(updateSiteAsync.success, (state, action) => ({
    ...state,
    upsertedSite_loading: false,
    upsertedSite_siteID: action.payload,
    upsertedSite_error: ''
  }))
  .handleAction(updateSiteAsync.failure, (state, action) => ({
    ...state,
    upsertedSite_loading: false,
    upsertedSite_error: action.payload
  }))
  .handleAction(checkSiteScheduleCountAsync.request, state => ({
    ...state,
    checkSiteSchedule_loading: true
  }))
  .handleAction(checkSiteScheduleCountAsync.success, (state, action) => ({
    ...state,
    checkSiteSchedule_loading: false,
    checkSiteSchedule_count: action.payload.length,
    checkSiteSchedule_error: ''
  }))
  .handleAction(checkSiteScheduleCountAsync.failure, (state, action) => ({
    ...state,
    checkSiteSchedule_loading: false,
    checkSiteSchedule_error: action.payload
  }))
  .handleAction(toggleBlockSiteAsync.request, state => ({
    ...state,
    blockSite_loading: true
  }))
  .handleAction(toggleBlockSiteAsync.success, (state, action) => ({
    ...state,
    blockSite_loading: false,
    blockSite_siteID: action.payload,
    blockSite_error: ''
  }))
  .handleAction(toggleBlockSiteAsync.failure, (state, action) => ({
    ...state,
    blockSite_loading: false,
    blockSite_error: action.payload
  }))
  .handleAction(clearSiteState, state => ({
    ...state,
    upsertedSite_siteID: '',
    upsertedSite_error: ''
  }))
  .handleAction(clearToggleBlockSiteState, state => ({
    ...state,
    blockSite_siteID: '',
    blockSite_error: ''
  }))
  .handleAction(upsertQuestionnaireAsync.request, state => ({
    ...state,
    upsertedQuestionnaire_loading: true
  }))
  .handleAction(upsertQuestionnaireAsync.success, (state, action) => ({
    ...state,
    upsertedQuestionnaire_loading: false,
    upsertedQuestionnaire_questionnaireID: action.payload,
    upsertedQuestionnaire_error: ''
  }))
  .handleAction(upsertQuestionnaireAsync.failure, (state, action) => ({
    ...state,
    upsertedQuestionnaire_loading: false,
    upsertedQuestionnaire_error: action.payload
  }))
  .handleAction(clearQuestionnaireState, state => ({
    ...state,
    upsertedQuestionnaire_questionnaireID: '',
    upsertedQuestionnaire_error: ''
  }))
  .handleAction(createUserAsync.request, state => ({
    ...state,
    upsertedUser_loading: true
  }))
  .handleAction(createUserAsync.success, (state, action) => ({
    ...state,
    upsertedUser_loading: false,
    upsertedUser_userID: action.payload,
    upsertedUser_error: ''
  }))
  .handleAction(createUserAsync.failure, (state, action) => ({
    ...state,
    upsertedUser_loading: false,
    upsertedUser_error: action.payload
  }))
  .handleAction(updateUserAsync.request, state => ({
    ...state,
    upsertedUser_loading: true
  }))
  .handleAction(updateUserAsync.success, (state, action) => ({
    ...state,
    upsertedUser_loading: false,
    upsertedUser_userID: action.payload,
    upsertedUser_error: ''
  }))
  .handleAction(updateUserAsync.failure, (state, action) => ({
    ...state,
    upsertedUser_loading: false,
    upsertedUser_error: action.payload
  }))
  .handleAction(clearUserState, state => ({
    ...state,
    upsertedUser_userID: '',
    upsertedUser_error: ''
  }))
  .handleAction(fetchQuestionCategoriesAsync.request, state => ({
    ...state,
    questionCategories_loading: true
  }))
  .handleAction(fetchQuestionCategoriesAsync.success, (state, action) => ({
    ...state,
    questionCategories_loading: false,
    questionCategories_data: action.payload,
    questionCategories_error: ''
  }))
  .handleAction(fetchQuestionCategoriesAsync.failure, (state, action) => ({
    ...state,
    questionCategories_loading: false,
    questionCategories_error: action.payload
  }));
