const SET_FILTER_QUERY = 'journeyPlan/SET_FILTER_QUERY';
const CLEAR_FILTER_QUERY = 'journeyPlan/CLEAR_FILTER_QUERY';
const SET_SORT = 'journeyPlan/SET_JOURNEY_PLAN_SORT';
const SHOW_MODAL_BULK = 'journeyPlan/SHOW_MODAL_BULK';
const DISMISS_MODAL_BULK = 'journeyPlan/DISMISS_MODAL_BULK';
const SHOW_MODAL_ACTION = 'journeyPlan/SHOW_MODAL_ACTION';
const DISMISS_MODAL_ACTION = 'journeyPlan/DISMISS_MODAL_ACTION';

// Reducer for journey plan management
export const showActionJourneyPlanModal = data => {
  return { type: SHOW_MODAL_ACTION, payload: data };
};

export const dismissActionJourneyPlanModal = () => {
  return { type: DISMISS_MODAL_ACTION };
};

export const showBulkJourneyPlanModal = () => {
  return { type: SHOW_MODAL_BULK };
};

export const dismissBulkJourneyPlanModal = () => {
  return { type: DISMISS_MODAL_BULK };
};

export const setJourneyPlansFilterQuery = text => {
  return { type: SET_FILTER_QUERY, text };
};

export const clearJourneyPlansFilterQuery = () => {
  return { type: CLEAR_FILTER_QUERY };
};

export const setSortJourneyPlan = (newType, newOrder = null) => {
  return { type: SET_SORT, newType, newOrder };
};

const initialState = {
  selectedRow: null,
  filterQuery: '',
  sortBy: 'journeyName',
  journeyName: 'asc',
  sites: 'asc',
  questionnaires: 'asc',
  createdAt: 'asc',
  updatedAt: 'asc',
  scrollPosition: 0,
  bulkModal: false,
  showModal: false,
  selectedJourney: null
};

export function journeyPlanReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL_ACTION:
      return {
        ...state,
        showModal: action.payload.type,
        selectedJourney: action.payload.data
      };
    case DISMISS_MODAL_ACTION:
      return {
        ...state,
        showModal: false,
        selectedJourney: null
      };
    case SHOW_MODAL_BULK:
      return {
        ...state,
        bulkModal: true
      };
    case DISMISS_MODAL_BULK:
      return {
        ...state,
        bulkModal: false
      };
    case SET_FILTER_QUERY:
      return {
        ...state,
        filterQuery: action.text
      };
    case CLEAR_FILTER_QUERY:
      return {
        ...state,
        filterQuery: ''
      };
    case SET_SORT:
      if (state.sortBy !== action.newType) {
        return {
          ...state,
          sortBy: action.newType,
          journeyName: 'asc',
          sites: 'asc',
          questionnaires: 'asc',
          createdAt: 'asc',
          updatedAt: 'asc'
        };
      }
      return {
        ...state,
        [action.newType]: action.newOrder
      };
    default:
      return state;
  }
}
