import { createAsyncAction, action } from 'typesafe-actions';

import * as types from './departmentEditor.actionTypes';
import { DeptIndexInterface } from 'components/departments/DepartmentEditor/type';
import { UpdateDepartementByRolesSagaPayload } from 'reducers/departments/type';

export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });

export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });

export const createDepartment = createAsyncAction(
  types.CREATE_DEPARTMENT_REQUEST,
  types.CREATE_DEPARTMENT_SUCCESS,
  types.CREATE_DEPARTMENT_FAILURE
)<{ data: DeptIndexInterface }, undefined, { error: string }>();

export const updateDepartment = createAsyncAction(
  types.UPDATE_DEPARTMENT_REQUEST,
  types.UPDATE_DEPARTMENT_SUCCESS,
  types.UPDATE_DEPARTMENT_FAILURE
)<
  { deptID: string; data: DeptIndexInterface; assignSupervisor: UpdateDepartementByRolesSagaPayload },
  undefined,
  { error: string; errorData?: string[] | null }
>();
