import { SiteOffday, SiteOffdayMongo } from '@nimbly-technologies/nimbly-common';
import { BooleanSchema } from 'yup';

export enum SiteOffDaysCallState {
	INITIAL,
	LOADING,
	SUCCESS,
	ERROR,
}

export interface SiteOffDaysState {
	error: string;
	fetchState: SiteOffDaysCallState;
	upsertState: SiteOffDaysCallState;
	siteOffDays: SiteOffdayMongo;
}

export interface ProcessedSiteOffDays extends SiteOffdayMongo {
	dates: { [siteID: string]: boolean };
}
