import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './stockAudit.action';
import { StockAuditReducerState } from './stockAuditStore';
import moment from 'moment';

export type StockAuditActionType = ActionType<typeof actions>;

export const initialState: StockAuditReducerState = {
	filters: {
		startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		siteIDs: [],
		skuIDs: [],
		skuNames: [],
	},
};

export const stockAuditReducer = createReducer<StockAuditReducerState, StockAuditActionType>(initialState).handleAction(
	actions.setFilters,
	(state, action) => ({
		...state,
		filters: action.payload.filters,
	}),
);
