import { takeLeading, put, call, select } from 'redux-saga/effects';
import moment from 'moment-timezone';

import * as actions from './action';
import * as types from './actionType';

import API from 'helpers/api';
import { RootState } from 'store/rootReducers';
import { FilterSitesState } from './reducer';
import { apiURL } from 'config/baseURL';

const getFilterSitesState = (state: RootState) => state.filter.sites;

function* fetchFilterSites() {
  const state: FilterSitesState = yield select(getFilterSitesState);

  if (state.isLoaded && state.timestamp) {
    const timeDiff = moment().diff(state.timestamp, 'minutes');

    if (timeDiff < 5) {
      yield put(actions.fetchFilterSites.cancel());

      return;
    }
  }

  const url = `${apiURL}/sites/filter-options`;

  try {
    const authToken: string = yield call(API.getFirebaseToken);

    const res = yield call(API.getJSON, url, authToken);

    yield put(actions.fetchFilterSites.success({ data: res.data || [] }));
  } catch (error) {
    yield put(actions.fetchFilterSites.failure({ error: error.message }));
  }
}

export default function* fetchFilterSitesSaga() {
  yield takeLeading(types.FETCH_FILTER_SITES_REQUEST, fetchFilterSites);
}
