import moment from 'moment';
import { createReducer } from 'typesafe-actions';
import * as Common from 'nimbly-common';

import { ActivityLogState, ActivityLogActions } from './types';
import { fetchAllOrganizationAsync, fetchOrganizationUsersAsync, fetchOrganizationActivitiesAsync } from './action';
import * as types from './actionType';

const initialState: ActivityLogState = {
  organizations: {
    options: [],
    isLoading: false,
    isError: false
  },
  users: {
    data: {},
    isLoading: false,
    isError: false
  },
  activities: {
    data: [],
    isLoading: false,
    isError: false
  },
  selectedOrganization: null,
  selectedUserKey: null,
  selectedActivity: null,
  periodType: Common.enums.PeriodTypes.CURRENT,
  startDate: moment().startOf('isoWeek'),
  endDate: moment().endOf('isoWeek'),
  prevStartDate: moment().parseZone().subtract(1, 'week').startOf('isoWeek'),
  prevEndDate: moment().parseZone().subtract(1, 'week').startOf('isoWeek'),
  customStart: null,
  customEnd: null,
  limit: 50,
  pageIndex: 1,
  totalPages: null,
  totalDocs: null
};

export const activityLogReducer = createReducer<ActivityLogState, ActivityLogActions>(initialState)
  .handleAction(types.SET_PAGE_INDEX as any, (state, action) => ({
    ...state,
    pageIndex: action.payload.pageIndex
  }))
  .handleAction(types.SET_TOTAL_PAGES as any, (state, action) => ({
    ...state,
    totalPages: action.payload.total
  }))
  .handleAction(types.SET_TOTAL_DOCS as any, (state, action) => ({
    ...state,
    totalDocs: action.payload.total
  }))
  .handleAction(types.RESET_ORGANIZATION_ACTIVITIES as any, state => ({
    ...state,
    activities: {
      ...state.activities,
      data: []
    }
  }))
  .handleAction(types.SELECT_ORGANIZATION as any, (state, action) => ({
    ...state,
    selectedOrganization: action.payload.organizationKey
  }))
  .handleAction(types.SELECT_USER as any, (state, action) => ({
    ...state,
    selectedUserKey: action.payload.userKey
  }))
  .handleAction(types.SELECT_ACTIVITY_TYPE as any, (state, action) => ({
    ...state,
    selectedActivity: action.payload.activityType
  }))
  .handleAction(types.SELECT_PERIOD_TYPE as any, (state, action) => ({
    ...state,
    periodType: action.payload.periodType
  }))
  .handleAction(types.SET_DATES as any, (state, action) => ({
    ...state,
    startDate: action.payload.startDate,
    endDate: action.payload.endDate
  }))
  .handleAction(types.SET_PREVIOUS_DATES as any, (state, action) => ({
    ...state,
    prevStartDate: action.payload.startDate,
    prevEndDate: action.payload.endDate
  }))
  .handleAction(types.SET_CUSTOM_DATES as any, (state, action) => ({
    ...state,
    customStart: action.payload.startDate,
    customEnd: action.payload.endDate
  }))
  .handleAction(fetchAllOrganizationAsync.request as any, state => ({
    ...state,
    organizations: {
      ...state.organizations,
      isLoading: true
    }
  }))
  .handleAction(fetchAllOrganizationAsync.success as any, (state, action) => ({
    ...state,
    organizations: {
      ...state.organizations,
      options: action.payload.options,
      isLoading: false,
      isError: false
    }
  }))
  .handleAction(fetchAllOrganizationAsync.failure as any, (state, action) => ({
    ...state,
    organizations: {
      ...state.organizations,
      isLoading: false,
      isError: true
    }
  }))
  .handleAction(fetchOrganizationUsersAsync.request as any, state => ({
    ...state,
    users: {
      ...state.users,
      isLoading: true
    }
  }))
  .handleAction(fetchOrganizationUsersAsync.success as any, (state, action) => ({
    ...state,
    users: {
      ...state.users,
      data: {
        ...state.users.data,
        [action.payload.organizationID]: action.payload.options
      },
      isLoading: false,
      isError: false
    }
  }))
  .handleAction(fetchOrganizationUsersAsync.failure as any, (state, action) => ({
    ...state,
    users: {
      ...state.users,
      isLoading: false,
      isError: true
    }
  }))
  .handleAction(fetchOrganizationActivitiesAsync.request as any, state => ({
    ...state,
    activities: {
      ...state.activities,
      isLoading: true
    }
  }))
  .handleAction(fetchOrganizationActivitiesAsync.success as any, (state, action) => ({
    ...state,
    activities: {
      ...state.activities,
      data: action.payload.data,
      isLoading: false,
      isError: false
    }
  }))
  .handleAction(fetchOrganizationActivitiesAsync.failure as any, (state, action) => ({
    ...state,
    activities: {
      ...state.activities,
      isLoading: false,
      isError: true
    }
  }));
