import React from 'react';
import { ModalScrim, ModalContainer, ModalHeader, ModalTextContainer } from '../styles/TransitionModal.styles';
import TransferIcon from 'assets/icon/exchange-white.svg';

function TransitionModal({ orgName, isVisible }: OwnProps) {
  return isVisible ? (
    <ModalScrim>
      <ModalContainer>
        <ModalHeader>
          <img src={TransferIcon} alt="transfer-icon" />
        </ModalHeader>

        <ModalTextContainer>
          <p>
            Transferring you to <span>{orgName}</span>'s account
          </p>
        </ModalTextContainer>
      </ModalContainer>
    </ModalScrim>
  ) : null;
}

type OwnProps = {
  isVisible: boolean;
  orgName: string;
};

export default TransitionModal;
