import { createReducer } from 'typesafe-actions';
import * as types from './settingsNotification.actionTypes';
import * as actions from './settingsNotification.actions';
import { SettingsNotificationAction, SettingsNotificationState } from './settingsNotification.type';

const initialState: SettingsNotificationState = {
	query: {
		isLoading: false,
		error: null,
		data: null,
	},
	updateMutation: {
		isLoading: false,
		error: null,
	},
	createMutation: {
		isLoading: false,
		error: null,
	},

	unsubscribeQuery: {
		isLoading: false,
		error: null,
		data: null,
	},
	unsubscribeUpdateMutation: {
		isLoading: false,
		error: null,
	},
};

const settingsNotificationReducer = createReducer<SettingsNotificationState, SettingsNotificationAction>(initialState)
	.handleAction(actions.fetchSettingsNotificationAsync.request, (state, action) => ({
		...state,
		query: {
			...state.query,
			isLoading: true,
			error: null,
		},
	}))
	.handleAction(actions.fetchSettingsNotificationAsync.success, (state, action) => ({
		...state,
		query: {
			...state.query,
			isLoading: false,
			data: action.payload.data,
		},
	}))
	.handleAction(actions.fetchSettingsNotificationAsync.failure, (state, action) => ({
		...state,
		query: {
			...state.query,
			isLoading: false,
			error: action.payload.error,
		},
	}))
	.handleAction(actions.updateSettingsNotificationAsync.request, (state, action) => ({
		...state,
		updateMutation: {
			...state.updateMutation,
			isLoading: true,
			error: null,
		},
	}))
	.handleAction(actions.updateSettingsNotificationAsync.success, (state, action) => ({
		...state,
		updateMutation: {
			...state.updateMutation,
			isLoading: false,
		},
	}))
	.handleAction(actions.updateSettingsNotificationAsync.failure, (state, action) => ({
		...state,
		updateMutation: {
			...state.updateMutation,
			isLoading: false,
			error: action.payload.error,
		},
	}))
	.handleAction(actions.createSettingsNotificationAsync.request, (state, action) => ({
		...state,
		createMutation: {
			...state.createMutation,
			isLoading: true,
			error: null,
		},
	}))
	.handleAction(actions.createSettingsNotificationAsync.success, (state, action) => ({
		...state,
		createMutation: {
			...state.createMutation,
			isLoading: false,
		},
	}))
	.handleAction(actions.createSettingsNotificationAsync.failure, (state, action) => ({
		...state,
		createMutation: {
			...state.createMutation,
			isLoading: false,
			error: action.payload.error,
		},
	}))

	.handleAction(actions.fetchSettingsNotificationUnsubscribeAsync.request, (state, action) => ({
		...state,
		unsubscribeQuery: {
			...state.unsubscribeQuery,
			isLoading: true,
			error: null,
		},
	}))
	.handleAction(actions.fetchSettingsNotificationUnsubscribeAsync.success, (state, action) => ({
		...state,
		unsubscribeQuery: {
			...state.unsubscribeQuery,
			isLoading: false,
			data: action.payload.data,
		},
	}))
	.handleAction(actions.fetchSettingsNotificationUnsubscribeAsync.failure, (state, action) => ({
		...state,
		unsubscribeQuery: {
			...state.unsubscribeQuery,
			isLoading: false,
			error: action.payload.error,
		},
	}))
	.handleAction(actions.updateSettingsNotificationUnsubscribeAsync.request, (state, action) => ({
		...state,
		unsubscribeUpdateMutation: {
			...state.unsubscribeUpdateMutation,
			isLoading: true,
			error: null,
		},
	}))
	.handleAction(actions.updateSettingsNotificationUnsubscribeAsync.success, (state, action) => ({
		...state,
		unsubscribeUpdateMutation: {
			...state.unsubscribeUpdateMutation,
			isLoading: false,
		},
	}))
	.handleAction(actions.updateSettingsNotificationUnsubscribeAsync.failure, (state, action) => ({
		...state,
		unsubscribeUpdateMutation: {
			...state.unsubscribeUpdateMutation,
			isLoading: false,
			error: action.payload.error,
		},
	}))

	.handleAction((types.RESET_SETTINGS_NOTIFICATION as unknown) as typeof actions.resetSettingsNotification, () => ({
		...initialState,
	}));

export default settingsNotificationReducer;
