import {
  IssuesOverviewStatisticResponse,
  DashboardIssuesPayload
} from 'reducers/dashboard/fetch-types/issues-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesOverviewStat = IssuesOverviewStatisticResponse['data'];

export interface OverviewStatState {
  readonly overviewStat_loading: boolean;
  readonly overviewStat_data: IssuesOverviewStat;
  readonly overviewStat_error: string;
}

export const FETCH_OVERVIEW_STAT_REQUEST = '@@dashboard/issues/FETCH_OVERVIEW_STAT_REQUEST';
export const FETCH_OVERVIEW_STAT_SUCCESS = '@@dashboard/issues/FETCH_OVERVIEW_STAT_SUCCESS';
export const FETCH_OVERVIEW_STAT_FAILURE = '@@dashboard/issues/FETCH_OVERVIEW_STAT_FAILURE';

export type FetchOverviewStatPayload = DashboardIssuesPayload;
export type FetchOverviewStatResponse = IssuesOverviewStatisticResponse | ErrorResponse;
