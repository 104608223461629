import NoAccessImage from 'assets/img/no-access.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const NoAccessContent = () => {
	const { t } = useTranslation();
	return (
		<Root>
			<img src={NoAccessImage} alt="user has no access" />
			<h1>{t('label.noAccessPage.title')}</h1>
			<p>{t('label.noAccessPage.subtitle')}</p>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1 {
		font-size: 2em;
		margin: 0.6em 0 0.3em 0;
	}

	p {
		font-size: 1em;
		margin: 0;
		text-align: center;
	}

	img {
		width: 60%;
		max-width: 250px;
	}
`;

export default NoAccessContent;
