import React, { Suspense, lazy } from 'react';
import Layout from 'components/global/Layout/Layout';

const LmsDashboardRevamp = lazy(() => import('../lmsDashboardRevampPage/LmsDashboardRevamp'));

const LmsDashboardRevampPage = () => {
	return (
		<Suspense fallback={<div />}>
			<Layout containerStyle="overflow: hidden">
				<LmsDashboardRevamp />
			</Layout>
		</Suspense>
	);
};

export default LmsDashboardRevampPage;
