import { IssuesFlagStatisticResponse, DashboardIssuesPayload } from 'reducers/dashboard/fetch-types/issues-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesIssuesFlagStat = IssuesFlagStatisticResponse['data'];

export interface IssuesFlagState {
  readonly issuesFlagStat_loading: boolean;
  readonly issuesFlagStat_data: IssuesIssuesFlagStat;
  readonly issuesFlagStat_error: string;
  readonly issuesFlagStat_dl_loading: boolean;
  readonly issuesFlagStat_dl_url: string;
  readonly issuesFlagStat_dl_error: string;
}

type DownloadPayload = {
  dl: 1;
};

export const FETCH_ISSUES_FLAG_STAT_REQUEST = '@@dashboard/issues/FETCH_ISSUES_FLAG_STAT_REQUEST';
export const FETCH_ISSUES_FLAG_STAT_SUCCESS = '@@dashboard/issues/FETCH_ISSUES_FLAG_STAT_SUCCESS';
export const FETCH_ISSUES_FLAG_STAT_FAILURE = '@@dashboard/issues/FETCH_ISSUES_FLAG_STAT_FAILURE';

export type FetchIssuesFlagStatPayload = DashboardIssuesPayload;
export type FetchIssuesFlagStatResponse = IssuesFlagStatisticResponse | ErrorResponse;

export const DOWNLOAD_ISSUES_FLAG_STAT_REQUEST = '@@dashboard/issues/DOWNLOAD_ISSUES_FLAG_STAT_REQUEST';
export const DOWNLOAD_ISSUES_FLAG_STAT_SUCCESS = '@@dashboard/issues/DOWNLOAD_ISSUES_FLAG_STAT_SUCCESS';
export const DOWNLOAD_ISSUES_FLAG_STAT_FAILURE = '@@dashboard/issues/DOWNLOAD_ISSUES_FLAG_STAT_FAILURE';

export type DownloadIssuesFlagStatPayload = DashboardIssuesPayload & DownloadPayload;
