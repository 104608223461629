import { createAsyncAction } from 'typesafe-actions';
import {
  RankByFlags,
  FETCH_RANK_BY_FLAGS_REQUEST,
  FETCH_RANK_BY_FLAGS_SUCCESS,
  FETCH_RANK_BY_FLAGS_FAILURE,
  DOWNLOAD_RANK_BY_FLAGS_REQUEST,
  DOWNLOAD_RANK_BY_FLAGS_SUCCESS,
  DOWNLOAD_RANK_BY_FLAGS_FAILURE,
  FetchRankByFlagsPayload,
  DownloadRankByFlagsPayload
} from './types';

export const fetchRankByFlagsAsync = createAsyncAction(
  FETCH_RANK_BY_FLAGS_REQUEST,
  FETCH_RANK_BY_FLAGS_SUCCESS,
  FETCH_RANK_BY_FLAGS_FAILURE
)<FetchRankByFlagsPayload, RankByFlags, string>();

export const downloadRankByFlagsAsync = createAsyncAction(
  DOWNLOAD_RANK_BY_FLAGS_REQUEST,
  DOWNLOAD_RANK_BY_FLAGS_SUCCESS,
  DOWNLOAD_RANK_BY_FLAGS_FAILURE
)<DownloadRankByFlagsPayload, string, string>();
