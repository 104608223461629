import { OverviewDailyChart, FetchDailyChartPayload } from 'reducers/dashboard/overview/dailyChart/types';
import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

import orgReportsSubmitted from '../../../assets/dummy/json/org-number.json';
import orgReportsScheduled from '../../../assets/dummy/json/org-scheduled.json';
import orgReportsDuration from '../../../assets/dummy/json/org-time.json';

const dummyDailyChart: OverviewDailyChart = orgReportsSubmitted.map((completedNum, index) => {
  const isEvenIdx = index % 2 === 0;
  const duration = orgReportsDuration[index] || 20;
  const date = index < 10 ? `2020-03-0${index}` : `2020-03-${index}`;

  return {
    completedNum,
    scheduledNum: orgReportsScheduled[index] || 0,
    duration: orgReportsDuration[index] || 0,
    adhocDone: isEvenIdx ? Math.ceil(completedNum / 2) : Math.floor(completedNum * 2),
    adhocDuration: isEvenIdx ? Math.ceil(duration / 2) : Math.floor(duration * 2),
    date,
    pendingNum: 0,
    missedNum: 0
  };
});

export const getDummyDailyChart = (payload: FetchDailyChartPayload) => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    return dummyDailyChart.slice(-diffInDay);
  }

  return dummyDailyChart.slice(0, diffInDay);
};
