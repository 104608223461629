import { createAsyncAction, createAction } from 'typesafe-actions';

import * as types from './actionType';
import { enums } from 'nimbly-common';

export const setAccessPath = createAsyncAction(
	types.SET_ACCESS_PATH_REQUEST,
	types.SET_ACCESS_PATH_SUCCESS,
	types.SET_ACCESS_PATH_FAILURE,
)<
	{ path: string; feature: enums.FeaturesType; resource: string; lmsRoleAccessType: string },
	{ path: string; isAccessible: boolean },
	{ path: string; error: string }
>();

/**
 * Reset all path access to empty
 */
export const reset = createAction(types.RESET)();
/**
 * Reset one path to empty
 */
export const resetPath = createAction(types.RESET_PATH)<{ path: string }>();
