import {
	AvailableCourseAndSyllabusResponse,
	InProgressCourseAndSyllabusResponse,
	PaginationResult,
	MyCourseAndSyllabusResponse,
} from '@nimbly-technologies/nimbly-common';
import { IEnrollLmsCourseSyllabus, TCoursesSyllabusesRecentlyViewedResponse } from 'services/lms/lms.service';
import { createAsyncAction, deprecated, action } from 'typesafe-actions';
import * as types from './coursesSyllabuses.actionTypes';
import { CoursesSyllabusesFilters, TLmsCoursesSyllabusesFetchSagaRequest } from './coursesSyllabuses.type';
import { IDashboardLessonStatusStore } from '../lmsDashboard/lmsDashboard.type';
export const setLmsCoursesSyllabusesAvailableSearch = deprecated.createStandardAction(
	types.SET_COURSES_AND_SYLLABUSES_AVAILABLE_SEARCH,
)<string>();

export const setLmsCoursesSyllabusesAvailableSort = deprecated.createStandardAction(
	types.SET_COURSES_AND_SYLLABUSES_AVAILABLE_SORT,
)<string>();

export const setLmsCoursesSyllabusesAvailableFilters = deprecated.createStandardAction(
	types.SET_COURSES_AND_SYLLABUSES_AVAILABLE_FILTERS,
)<Partial<CoursesSyllabusesFilters>>();

export const resetLmsCoursesSyllabusesAvailableFilters = deprecated.createStandardAction(
	types.RESET_COURSES_AND_SYLLABUSES_AVAILABLE_FILTERS,
)<undefined>();

export const setLmsMyCoursesSyllabusesSearch = deprecated.createStandardAction(
	types.SET_MY_COURSES_AND_SYLLABUSES_SEARCH,
)<string>();

export const setCurrentTab = (tab: string) => action(types.SET_MY_COURSES_AND_SYLLABUSES_TAB, { tab });
export const setLearnerCourseId = (courseID: string) => action(types.SET_LMS_LEARNER_COURSEID, { courseID });

export const fetchLmsCoursesSyllabusesAvailableAsync = createAsyncAction(
	types.FETCH_LMS_COURSES_SYLLABUSES_AVAILABLE_REQUEST,
	types.FETCH_LMS_COURSES_SYLLABUSES_AVAILABLE_SUCCESS,
	types.FETCH_LMS_COURSES_SYLLABUSES_AVAILABLE_FAILURE,
)<TLmsCoursesSyllabusesFetchSagaRequest, PaginationResult<AvailableCourseAndSyllabusResponse>, string>();

export const fetchLmsCoursesSyllabusesInProgressAsync = createAsyncAction(
	types.FETCH_LMS_COURSES_SYLLABUSES_IN_PROGRESS_REQUEST,
	types.FETCH_LMS_COURSES_SYLLABUSES_IN_PROGRESS_SUCCESS,
	types.FETCH_LMS_COURSES_SYLLABUSES_IN_PROGRESS_FAILURE,
)<TLmsCoursesSyllabusesFetchSagaRequest, PaginationResult<InProgressCourseAndSyllabusResponse>, string>();

export const fetchLmsCoursesSyllabusesRecentlyViewedAsync = createAsyncAction(
	types.FETCH_LMS_COURSES_SYLLABUSES_RECENTLY_VIEWED_REQUEST,
	types.FETCH_LMS_COURSES_SYLLABUSES_RECENTLY_VIEWED_SUCCESS,
	types.FETCH_LMS_COURSES_SYLLABUSES_RECENTLY_VIEWED_FAILURE,
)<TLmsCoursesSyllabusesFetchSagaRequest, PaginationResult<TCoursesSyllabusesRecentlyViewedResponse>, string>();

export const enrollLmsCoursesSyllabusesAsync = createAsyncAction(
	types.ENROLL_LMS_COURSES_SYLLABUSES_REQUEST,
	types.ENROLL_LMS_COURSES_SYLLABUSES_SUCCESS,
	types.ENROLL_LMS_COURSES_SYLLABUSES_FAILURE,
)<IEnrollLmsCourseSyllabus, { courseOrSyllabusID: string }, { courseOrSyllabusID: string; errorMessage: string }>();

export const fetchLmsMyCoursesSyllabusesAsync = createAsyncAction(
	types.FETCH_LMS_MY_COURSES_SYLLABUSES_REQUEST,
	types.FETCH_LMS_MY_COURSES_SYLLABUSES_SUCCESS,
	types.FETCH_LMS_MY_COURSES_SYLLABUSES_FAILURE,
)<TLmsCoursesSyllabusesFetchSagaRequest, PaginationResult<MyCourseAndSyllabusResponse>, string>();

export const fetchLmsIndicatorAsync = createAsyncAction(
	types.FETCH_LMS_INDICATOR_REQUEST,
	types.FETCH_LMS_INDICATOR_SUCCESS,
	types.FETCH_LMS_INDICATOR_FAILURE,
)<undefined, IDashboardLessonStatusStore, string>();
