import { apiURL } from 'config/baseURL';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/userManagement/bulkEditUser/bulkEdit.action';
import * as types from 'reducers/lms/userManagement/bulkEditUser/bulkEdit.actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';

interface Action {
	type: string;
	payload: { [key: string]: string };
	meta: string | undefined;
}

function* bulkUpdateUser(action: Action) {
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const body = { data: action.payload.data };

		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const updateLmsUserURL = `${apiURL}/lms/users/bulk-edit`;
		const request = () => fetch(updateLmsUserURL, options);
		// @ts-ignore
		const response = yield call(request);

		if (response && response.status === 200) {
			yield put(actions.bulkUpdateUser.success());
			toast.success(i18n.t('message.lms.userManagement.user.bulkUpdate.success'));
		} else {
			// @ts-ignore
			const responseData = yield response.json();
			const message = responseData.message;
			toast.error(message);
			if (message === 'No access to user' || message === 'Failed to Fetch Bulk Update User API') {
				//
			}
			yield put(actions.bulkUpdateUser.failure({ error: responseData.data }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.user.bulkUpdate.failed'));
		yield put(actions.bulkUpdateUser.failure({ error: 'Failed to Update Users' }));
	}
}
export default function* bulkUpdateUserSaga() {
	yield takeLatest(types.BULK_UPDATE_USER_REQUEST, bulkUpdateUser);
}
