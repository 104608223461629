import styled, { keyframes } from 'styled-components/macro';

const fadeIn = keyframes`
 0% { display: none; opacity: 0; }
 1% { display: flex; opacity: 0; }
 100% { display: flex; opacity: 1; }
`;
const breatheAnimation = keyframes`
 0% { height: 75px; width: 75px; }
 50% { height: 125px; width: 125px; opacity: 1 }
 100% { height: 75px; width: 75px; opacity: 0.6; }
`;

const ModalScrim = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;

  animation-name: ${fadeIn};
  animation-duration: 0.5s;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  width: 350px;
  background-color: white;
  border-radius: 20px;
  background-color: #454a54;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #32353b;

  height: 150px;

  img {
    animation-name: ${breatheAnimation};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
`;

const ModalTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  flex: 1;

  p {
    color: white;
    font-size: 16px;
    text-align: center;
    margin: 0;

    span {
      color: #3cd070;
      font-weight: 600;
    }
  }
`;

export { ModalScrim, ModalContainer, ModalHeader, ModalTextContainer };
