import { FetchFlagsOverviewPayload, FetchFlagsOverviewResponse } from 'reducers/dashboard/overview/cardFlag/actionType';
import serializeQuery from 'helpers/serializeQuery';
import { cloudV2ApiURL } from 'config/baseURL';
import { getDummyFlagCards } from 'utils/dashboardDummy/overview/dummyFlagCards';
import API from 'helpers/api';
import Monitoring from 'utils/monitoring/Monitoring';

export const fetchFlagCards = async (payload: FetchFlagsOverviewPayload): Promise<FetchFlagsOverviewResponse> => {
	const { isDummy, ...query } = payload;

	// HANDLE DUMMY DATA
	if (isDummy) {
		const dummyFlagCards = getDummyFlagCards(payload);
		return {
			message: 'SUCCESS',
			data: dummyFlagCards,
		};
	}

	try {
		const token = await API.getFirebaseToken();
		// eslint-disable-next-line no-undef
		const options: RequestInit = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				authorization: token,
			},
		};
		const serializedQueries = serializeQuery(query);
		const url = `${cloudV2ApiURL}/statistics/flags/overview-scheduled?${serializedQueries}`;

		const res = await fetch(url, options);
		// THROW IF RESPONSE NOT OK
		if (res.status !== 200) {
			throw new Error('');
		}

		const overviewFlagOverviewResult: FetchFlagsOverviewResponse = await res.json();

		return overviewFlagOverviewResult;
	} catch (err) {
		// ERROR
		Monitoring.logError(err);
		const errorResult: FetchFlagsOverviewResponse = {
			message: 'FAILED',
		};
		return errorResult;
	}
};

export const fetchFlagCardsV1 = async (payload: FetchFlagsOverviewPayload): Promise<FetchFlagsOverviewResponse> => {
	const { isDummy, ...query } = payload;

	try {
		const token = await API.getFirebaseToken();
		// eslint-disable-next-line no-undef
		const options: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: token,
			},
			body: JSON.stringify(query),
		};
		const urlScheduled = `${cloudV2ApiURL}/statistics/flags/overview-all`;

		const resScheduled = await fetch(urlScheduled, options);
		if (resScheduled.status !== 200) {
			throw new Error('');
		}

		const overviewFlagOverviewResult: FetchFlagsOverviewResponse = await resScheduled.json();

		return overviewFlagOverviewResult;
	} catch (err) {
		Monitoring.logError(err);
		const errorResult: FetchFlagsOverviewResponse = {
			message: 'FAILED',
		};
		return errorResult;
	}
};
