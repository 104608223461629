import styled from 'styled-components';

export const MenuControlContainer = styled.div`
	cursor: pointer;
	flex: 0 0 auto;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	padding: 10px;

	border-top: 1px solid #efeeed;

	&:hover {
		background: #eae9f9;
	}
`;

export const MenuControlLabel = styled.label`
	cursor: pointer;
	margin-left: 7px;
	color: #535353;
	font-size: 0.875rem;
	line-height: 1.4375rem;
	font-weight: 400;
`;

export const ClearIndicatorContainer = styled.div`
	> div {
		padding: 0;
	}
`;
