export const SET_LOADING = '@competitorExport/SET_LOADING';
export const SET_SORT = '@competitorExport/SET_SORT';

export const FETCH_EXPORT_REQUEST = '@competitorExport/FETCH_EXPORT_REQUEST';
export const FETCH_EXPORT_SUCCESS = '@competitorExport/FETCH_EXPORT_LOADING';
export const FETCH_EXPORT_FAILURE = '@competitorExport/FETCH_EXPORT_FAILURE';

export const CREATE_DOWNLOAD_ACT_REQUEST = '@competitorExport/CREATE_DOWNLOAD_ACT_REQUEST';
export const CREATE_DOWNLOAD_ACT_SUCCESS = '@competitorExport/CREATE_DOWNLOAD_ACT_SUCCESS';
export const CREATE_DOWNLOAD_ACT_FAILURE = '@competitorExport/CREATE_DOWNLOAD_ACT_FAILURE';

export const FETCH_DOWNLOAD_ACT_REQUEST = '@competitorExport/FETCH_DOWNLOAD_ACT_REQUEST';
export const FETCH_DOWNLOAD_ACT_SUCCESS = '@competitorExport/FETCH_DOWNLOAD_ACT_LOADING';
export const FETCH_DOWNLOAD_ACT_FAILURE = '@competitorExport/FETCH_DOWNLOAD_ACT_FAILURE';
