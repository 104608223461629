import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './downloadManager.action';
import { DownloadManagerState } from './type';

export type DownloadManagerAction = ActionType<typeof actions>;

const initialState: DownloadManagerState = {
  missedReportsDownloadable: [],
  missedReportsNumbers: 0,
  missedReportsExists: false,
  missedReportsDownloadLink: '',
  isLoadingMissedList: false,
  status: 'init'
};

export const downloadManagerReducer = createReducer<DownloadManagerState, DownloadManagerAction>(initialState)
  .handleAction(actions.fetchMissedReportDownloadList.request, (state, action) => ({
    ...state,
    isLoadingMissedList: true
  }))
  .handleAction(actions.fetchMissedReportDownloadList.success, (state, action) => ({
    ...state,
    missedReportsDownloadable: action.payload.data,
    isLoadingMissedList: false
  }))
  .handleAction(actions.fetchMissedReportDownloadList.failure, (state, action) => ({
    ...state,
    isLoadingMissedList: false
  }))
  .handleAction(actions.countMissedReport.request, (state, action) => ({
    ...state,
    isLoadingMissedList: true,
    status: 'init'
  }))
  .handleAction(actions.countMissedReport.success, (state, action) => ({
    ...state,
    missedReportsNumbers: action.payload.numberOfReports,
    missedReportsExists: action.payload.isDownloadableExists,
    isLoadingMissedList: false,
    status: action.payload.numberOfReports ? 'success' : 'not_found'
  }))
  .handleAction(actions.countMissedReport.failure, (state, action) => ({
    ...state,
    missedReportsNumbers: 0,
    isLoadingMissedList: false,
    status: 'init'
  }))
  .handleAction(actions.generateMissedReport.request, (state, action) => ({
    ...state,
    isLoadingMissedList: true
  }))
  .handleAction(actions.generateMissedReport.success, (state, action) => ({
    ...state,
    isLoadingMissedList: false
    // missedReports: action.payload.data
  }))
  .handleAction(actions.generateMissedReport.failure, (state, action) => ({
    ...state,
    isLoadingMissedList: false
  }));

export default downloadManagerReducer;
