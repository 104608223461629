export const SITE_GROUPS_QUERY_REQUEST = '@siteGroups/QUERY_REQUEST';
export const SITE_GROUPS_QUERY_SUCCESS = '@siteGroups/QUERY_SUCCESS';
export const SITE_GROUPS_QUERY_FAILURE = '@siteGroups/QUERY_FAILURE';

export const SITE_GROUPS_CREATE_REQUEST = '@siteGroups/CREATE_REQUEST';
export const SITE_GROUPS_CREATE_SUCCESS = '@siteGroups/CREATE_SUCCESS';
export const SITE_GROUPS_CREATE_FAILURE = '@siteGroups/CREATE_FAILURE';

export const SITE_GROUPS_UPDATE_REQUEST = '@siteGroups/UPDATE_REQUEST';
export const SITE_GROUPS_UPDATE_SUCCESS = '@siteGroups/UPDATE_SUCCESS';
export const SITE_GROUPS_UPDATE_FAILURE = '@siteGroups/UPDATE_FAILURE';

export const SITE_GROUPS_QUERY_BY_ID_REQUEST = '@siteGroups/QUERY_BY_ID_REQUEST';
export const SITE_GROUPS_QUERY_BY_ID_SUCCESS = '@siteGroups/QUERY_BY_ID_SUCCESS';
export const SITE_GROUPS_QUERY_BY_ID_FAILURE = '@siteGroups/QUERY_BY_ID_FAILURE';

export const SITE_GROUPS_TOGGLE_ACTIVE_STATUS_REQUEST = '@siteGroups/TOGGLE_STATUS_REQUEST';
export const SITE_GROUPS_TOGGLE_ACTIVE_STATUS_SUCCESS = '@siteGroups/TOGGLE_STATUS_SUCCESS';
export const SITE_GROUPS_TOGGLE_ACTIVE_STATUS_FAILURE = '@siteGroups/TOGGLE_STATUS_FAILURE';

export const SET_SITE_GROUPS_FILTER_QUERY = '@siteGroups/SET_FILTER_QUERY';
