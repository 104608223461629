import { createReducer, ActionType } from 'typesafe-actions';
import { TutorialState, CumulativeProgressType, ProgressType, CumulativeProgress } from './type';
import { enums } from 'nimbly-common';
import * as actions from './tutorial.action';
import * as types from './tutorial.actionTypes';

export type TutorialActions = ActionType<typeof actions>;
const initalState: TutorialState = {
  isFinished: false,
  isOrganizationOnboarding: false,
  progress: {
    [enums.OrganizationOnboardingProgress.DASHBOARD_START]: false,
    [enums.OrganizationOnboardingProgress.DEPARTMENTS_CREATE]: false,
    [enums.OrganizationOnboardingProgress.DEPARTMENTS_TO_USER_ROLE]: false,
    [enums.OrganizationOnboardingProgress.USER_ROLE_CREATE]: false,
    [enums.OrganizationOnboardingProgress.USER_ROLE_TO_USER]: false,
    [enums.OrganizationOnboardingProgress.USER_CREATE]: false,
    [enums.OrganizationOnboardingProgress.USER_TO_QUESTIONNAIRE]: false,
    [enums.OrganizationOnboardingProgress.QUESTIONNAIRE_CREATE]: false,
    [enums.OrganizationOnboardingProgress.QUESTIONNARE_TO_SITE]: false,
    [enums.OrganizationOnboardingProgress.SITE_CREATE]: false,
    [enums.OrganizationOnboardingProgress.SITE_TO_CREATE_SCHEDULE]: false,
    [enums.OrganizationOnboardingProgress.SCHEDULE_CREATE]: false,
    [enums.OrganizationOnboardingProgress.SCHEDULE_SAVE]: false,
    [enums.OrganizationOnboardingProgress.SCHEDULE_TO_DASHBOARD]: false,
    [enums.OrganizationOnboardingProgress.SCHEDULE_TO_ISSUE_TRACKER]: false,
    [enums.OrganizationOnboardingProgress.DASHBOARD_OVERVIEW]: false,
    [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_START]: false,
    [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_DETAILS]: false,
    [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_CHANGE_STATUS]: false,
    [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_CREATE_ISSUE]: false,
    [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_TO_DASHBOARD]: false
  },
  currentProgress: enums.OrganizationOnboardingProgress.DASHBOARD_START,
  cumulativeProgress: {
    WELCOME: false,
    DEPARTMENTS: false,
    USER_ROLES: false,
    ADD_USERS: false,
    SETUP_QUESTIONNAIRE: false,
    ADD_A_SITE: false,
    SCHEDULE_REPORT: false
  },
  isLoaded: false
};

export const tutorialReducer = createReducer<TutorialState, TutorialActions>(initalState)
  .handleAction(actions.fetchTutorialAsync.request, state => ({
    ...state,
    error: null,
    isLoaded: false
  }))
  .handleAction(actions.fetchTutorialAsync.success, (state: TutorialState, action: any) => {
    if (action.payload.isOrganizationOnboarding) {
      return {
        ...state,
        progress: action.payload.progress,
        currentProgress: action.payload.currentProgress,
        cumulativeProgress: action.payload.cumulativeProgress || {},
        isOrganizationOnboarding: action.payload.isOrganizationOnboarding,
        isFinished: action.payload.isFinished,
        isLoaded: true
      };
    } else {
      return {
        ...state,
        progress: action.payload.progress,
        isOrganizationOnboarding: action.payload.isOrganizationOnboarding,
        isLoaded: true
      };
    }
  })
  .handleAction(actions.fetchTutorialAsync.failure as any, (state: TutorialState, action: any) => ({
    ...state,
    error: action.payload.error
  }))
  .handleAction(types.SET_TO_FINISH as any, (state, action) => {
    const completeProgress = {
      [enums.OrganizationOnboardingProgress.DASHBOARD_START]: true,
      [enums.OrganizationOnboardingProgress.DEPARTMENTS_CREATE]: true,
      [enums.OrganizationOnboardingProgress.DEPARTMENTS_TO_USER_ROLE]: true,
      [enums.OrganizationOnboardingProgress.USER_ROLE_CREATE]: true,
      [enums.OrganizationOnboardingProgress.USER_ROLE_TO_USER]: true,
      [enums.OrganizationOnboardingProgress.USER_CREATE]: true,
      [enums.OrganizationOnboardingProgress.USER_TO_QUESTIONNAIRE]: true,
      [enums.OrganizationOnboardingProgress.QUESTIONNAIRE_CREATE]: true,
      [enums.OrganizationOnboardingProgress.QUESTIONNARE_TO_SITE]: true,
      [enums.OrganizationOnboardingProgress.SITE_CREATE]: true,
      [enums.OrganizationOnboardingProgress.SITE_TO_CREATE_SCHEDULE]: true,
      [enums.OrganizationOnboardingProgress.SCHEDULE_CREATE]: true,
      [enums.OrganizationOnboardingProgress.SCHEDULE_SAVE]: true,
      [enums.OrganizationOnboardingProgress.SCHEDULE_TO_DASHBOARD]: true,
      [enums.OrganizationOnboardingProgress.SCHEDULE_TO_ISSUE_TRACKER]: true,
      [enums.OrganizationOnboardingProgress.DASHBOARD_OVERVIEW]: true,
      [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_START]: true,
      [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_DETAILS]: true,
      [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_CHANGE_STATUS]: true,
      [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_CREATE_ISSUE]: true,
      [enums.OrganizationOnboardingProgress.ISSUE_TRACKER_TO_DASHBOARD]: true
    };
    const completeCumulative = {
      WELCOME: true,
      DEPARTMENTS: true,
      USER_ROLES: true,
      ADD_USERS: true,
      SETUP_QUESTIONNAIRE: true,
      ADD_A_SITE: true,
      SCHEDULE_REPORT: true
    };
    if (state.isOrganizationOnboarding) {
      return {
        ...state,
        isFinished: true,
        progress: completeProgress,
        cumulativeProgress: completeCumulative
      };
    } else {
      return {
        ...state,
        progress: completeProgress
      };
    }
  })
  .handleAction(types.UPDATE_CUMULATIVE_PROGRESS as any, (state: TutorialState, action: any) => {
    let newCumulativeProgress: CumulativeProgressType = { ...state.cumulativeProgress };
    newCumulativeProgress[action.payload.value as Partial<CumulativeProgress>] = true;
    if (state.isOrganizationOnboarding) {
      if (action.payload.value === 'SCHEDULE_REPORT') {
        return {
          ...state,
          cumulativeProgress: newCumulativeProgress
        };
      }
      return {
        ...state,
        cumulativeProgress: newCumulativeProgress
      };
    } else {
      return {
        ...state
      };
    }
  })
  .handleAction(types.UPDATE_PROGRESS as any, (state: TutorialState, action: any) => {
    if (state.isOrganizationOnboarding) {
      let newProgress: ProgressType = { ...state.progress };
      newProgress[action.payload.previous as Partial<enums.OrganizationOnboardingProgress>] = true;
      let newCurrentProgress = action.payload.next;
      return {
        ...state,
        progress: newProgress,
        currentProgress: newCurrentProgress
      };
    } else {
      let newProgress: ProgressType = { ...state.progress };
      newProgress[action.payload.previous as Partial<enums.OrganizationOnboardingProgress>] = true;
      return {
        ...state,
        progress: newProgress
      };
    }
  });
