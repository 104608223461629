import { ActionType, createReducer } from 'typesafe-actions';
import { RepositoryOrganizationUsageState } from './type';
import * as actions from './repositoryOrganizationUsage.action';

export type RepositoryOrganizationUsageAction = ActionType<typeof actions>;

export const initialState: RepositoryOrganizationUsageState = {
	error: '',
	isLoading: false,
	organizationID: '',
	planID: '',
	totalAllowedStorageGB: 0,
	totalAllowedUsers: 0,
	totalUsedStorageGB: 0,
	currentUserCount: 0,
	allocationPerEntity: {
		// file sizes in MB
		documents: 0,
		photos: 0,
		videos: 0,
		LMS: 0,
		trash: 0,
	},
	createdAt: '',
	updatedAt: '',
	totalOrganizationUsedStorageMB: 0,
	totalOrganizationUsedStorageGB: 0,
};

export const repositoryOrganizationUsageReducer = createReducer<
	RepositoryOrganizationUsageState,
	RepositoryOrganizationUsageAction
>(initialState)
	.handleAction(actions.fetchRepositoryOrganizationUsage.request, (state, action) => ({
		...state,
		error: initialState.error,
		isLoading: true,
	}))
	.handleAction(actions.fetchRepositoryOrganizationUsage.success, (state, action) => ({
		...state,
		...action.payload,
		error: initialState.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchRepositoryOrganizationUsage.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}));
