import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './bulkOps.action';
import { BulkOpsStore } from './bulkOps.types';
import * as types from './bulkOps.actionTypes';

export type BulkOpsAction = ActionType<typeof actions>;

export const initialState: BulkOpsStore = {
	openQuestionnaireBulkEditFilter: false,
	openSchedulesBulkEditFilter: false,
};

export const bulkOpsReducer = createReducer<BulkOpsStore, BulkOpsAction>(initialState)
	.handleAction(types.SET_OPEN_QUESTIONNAIRE_BULK_EDIT_FILTER as any, (state, action) => ({
		...state,
		openQuestionnaireBulkEditFilter: action.payload.value,
	}))
	.handleAction(types.SET_OPEN_SCHEDULES_BULK_EDIT_FILTER as any, (state, action) => ({
		...state,
		openSchedulesBulkEditFilter: action.payload.value,
	}));
