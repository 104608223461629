import { NotificationSettings } from '@nimbly-technologies/nimbly-common';
import {
	CreateSettingsNotificationPayload,
	FetchSettingsNotificationUnsubscribePayload,
	FetchSettingsNotificationUnsubscribeResponse,
	UpdateSettingsNotificationPayload,
	UpdateSettingsNotificationUnsubscribePayload,
	UpdateSettingsNotificationUnsubscribeResponse,
} from 'services/settings/settingsNotification/settingsNotification.service';
import { IAPIResponse } from 'types/api';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './settingsNotification.actionTypes';

export const createSettingsNotificationAsync = createAsyncAction(
	types.CREATE_SETTINGS_NOTIFICATION_REQUEST,
	types.CREATE_SETTINGS_NOTIFICATION_SUCCESS,
	types.CREATE_SETTINGS_NOTIFICATION_FAILURE,
)<
	{ data: CreateSettingsNotificationPayload; onSuccess?: () => void },
	IAPIResponse<NotificationSettings>,
	{ error: string }
>();

export const fetchSettingsNotificationAsync = createAsyncAction(
	types.FETCH_SETTINGS_NOTIFICATION_REQUEST,
	types.FETCH_SETTINGS_NOTIFICATION_SUCCESS,
	types.FETCH_SETTINGS_NOTIFICATION_FAILURE,
)<{ organizationID: string }, IAPIResponse<NotificationSettings>, { error: string }>();

export const updateSettingsNotificationAsync = createAsyncAction(
	types.UPDATE_SETTINGS_NOTIFICATION_REQUEST,
	types.UPDATE_SETTINGS_NOTIFICATION_SUCCESS,
	types.UPDATE_SETTINGS_NOTIFICATION_FAILURE,
)<
	{ data: UpdateSettingsNotificationPayload; onSuccess?: () => void },
	IAPIResponse<NotificationSettings>,
	{ error: string }
>();

export const fetchSettingsNotificationUnsubscribeAsync = createAsyncAction(
	types.FETCH_SETTINGS_NOTIFICATION_UNSUBSCRIBE_REQUEST,
	types.FETCH_SETTINGS_NOTIFICATION_UNSUBSCRIBE_SUCCESS,
	types.FETCH_SETTINGS_NOTIFICATION_UNSUBSCRIBE_FAILURE,
)<FetchSettingsNotificationUnsubscribePayload, FetchSettingsNotificationUnsubscribeResponse, { error: string }>();

export const updateSettingsNotificationUnsubscribeAsync = createAsyncAction(
	types.UPDATE_SETTINGS_NOTIFICATION_UNSUBSCRIBE_REQUEST,
	types.UPDATE_SETTINGS_NOTIFICATION_UNSUBSCRIBE_SUCCESS,
	types.UPDATE_SETTINGS_NOTIFICATION_UNSUBSCRIBE_FAILURE,
)<
	{ data: UpdateSettingsNotificationUnsubscribePayload; onSuccess?: () => void },
	UpdateSettingsNotificationUnsubscribeResponse,
	{ error: string }
>();

export const resetSettingsNotification = () => action(types.RESET_SETTINGS_NOTIFICATION);

const settingsNotificationActions = {
	createSettingsNotificationAsync,
	fetchSettingsNotificationAsync,
	updateSettingsNotificationAsync,
	fetchSettingsNotificationUnsubscribeAsync,
	updateSettingsNotificationUnsubscribeAsync,
	resetSettingsNotification,
};

export default settingsNotificationActions;
