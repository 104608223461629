import {
	AttachmentGalleryResponse,
	FilterAttachmentsResult,
	GetRepositoryItemsResponse,
	IssueType,
	PaginatedRepositoryItems,
	mimeTypes,
} from '@nimbly-technologies/nimbly-common';
import { RepositoryListEnum, RepositorySortByOptionsEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import { AttachmentDetails } from 'components/gallery/component/AttachmentThumbnailCard';
import { LmsViewType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';
import { AttachmentState, AttachmentUploadState } from 'hooks/useUploadFileAttachment';
import { SortDirection } from 'reducers/skuManagement/type';
import { AttachmentResult } from 'services/gallery/gallery.service';

export enum RepositoryUserUsageSortBy {
	USED_STORAGE = 'usedStorage',
	NAME = 'name',
}

export interface UserUsageDetail {
	usedStorageGB: number;
	displayName: string;
}

export interface RepositoryUserUsageState {
	isLoading: boolean;
	page: number;
	limit: number;
	totalDocs: number;
	totalPages: number;
	error: string;
	sortBy: RepositoryUserUsageSortBy;
	sortType: SortDirection;
	docs: UserUsageDetail[];
}
