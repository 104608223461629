import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './courses.action';
import { LmsCoursesDataState, LmsCoursesState } from './type.d';

const initialState: LmsCoursesState = {
	dataState: LmsCoursesDataState.LOADING,
	lmsCourses: [],
};
export type LmsCoursesAction = ActionType<typeof actions>;
export const lmsCoursesReducer = createReducer<LmsCoursesState, LmsCoursesAction>(initialState)
	.handleAction(actions.fetchLmsCourses.request, () => ({
		dataState: LmsCoursesDataState.LOADING,
		lmsCourses: [],
		errorMessage: '',
	}))
	.handleAction(actions.fetchLmsCourses.success, (state, action) => ({
		dataState: LmsCoursesDataState.SUCCESS,
		lmsCourses: action.payload,
		errorMessage: '',
	}))
	.handleAction(actions.fetchLmsCourses.failure, (state, action) => ({
		dataState: LmsCoursesDataState.FAILURE,
		lmsCourses: [],
		errorMessage: action.payload,
	}));
