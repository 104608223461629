import { createReducer } from 'typesafe-actions';

import { ReqForDemoState } from './types';
import { ReqForDemoActions } from './action';
import {
  fetchRequestForDemoListAsync,
  exportRequestForDemoListAsync,
  sortRequestDemoList
} from './requestDemoList/action';

const initialState: ReqForDemoState = {
  /**
   * Request Demo List State
   */
  fetchRequestDemoList: {
    loading: false,
    data: [],
    error: ''
  },
  exportRequestDemoList: {
    loading: false,
    url: '',
    error: ''
  },
  sortRequestDemoList: {
    sortBy: 'name',
    sortDirections: {
      createdAt: 'asc',
      name: 'asc',
      email: 'asc',
      phoneNumber: 'asc',
      companyName: 'asc',
      companySize: 'asc'
    }
  }
};

export const reqForDemoReducer = createReducer<ReqForDemoState, ReqForDemoActions>(initialState)
  .handleAction(fetchRequestForDemoListAsync.request, state => ({
    ...state,
    fetchRequestDemoList: {
      ...state.fetchRequestDemoList,
      loading: true
    }
  }))
  .handleAction(fetchRequestForDemoListAsync.success, (state, action) => ({
    ...state,
    fetchRequestDemoList: {
      ...state.fetchRequestDemoList,
      loading: false,
      data: action.payload,
      error: ''
    }
  }))
  .handleAction(fetchRequestForDemoListAsync.failure, (state, action) => ({
    ...state,
    fetchRequestDemoList: {
      ...state.fetchRequestDemoList,
      loading: false,
      error: action.payload
    }
  }))
  .handleAction(exportRequestForDemoListAsync.request, state => ({
    ...state,
    exportRequestDemoList: {
      ...state.exportRequestDemoList,
      loading: true
    }
  }))
  .handleAction(exportRequestForDemoListAsync.success, (state, action) => ({
    ...state,
    exportRequestDemoList: {
      ...state.exportRequestDemoList,
      loading: false,
      url: action.payload,
      error: ''
    }
  }))
  .handleAction(exportRequestForDemoListAsync.failure, (state, action) => ({
    ...state,
    exportRequestDemoList: {
      ...state.exportRequestDemoList,
      loading: false,
      url: '',
      error: action.payload
    }
  }))
  .handleAction(sortRequestDemoList, (state, action) => ({
    ...state,
    sortRequestDemoList: {
      ...state.sortRequestDemoList,
      sortBy: action.payload,
      sortDirections:
        action.payload === state.sortRequestDemoList.sortBy
          ? {
              ...state.sortRequestDemoList.sortDirections,
              [action.payload]: state.sortRequestDemoList.sortDirections[action.payload] === 'asc' ? 'desc' : 'asc'
            }
          : {
              ...state.sortRequestDemoList.sortDirections,
              [action.payload]: 'asc'
            }
    }
  }));
