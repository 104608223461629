import loadable from '@loadable/component';
import { Features } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import Route from 'components/routeWithValidation/RouteWithValidation';
import React from 'react';
import LmsDashboardPage from '../pages/lms/LmsDashboardPage';
import LmsUserGroupListPage from '../pages/lms/LmsUserGroupListPage';
import { Switch } from 'react-router-dom';
import LmsDashboardRevampPage from 'pages/lms/lmsDashboardRevampPage/LmsDashboardRevampPage';
const LmsSyllabusQuizDetailsPage = loadable(() => import('pages/lms/learner/LmsSyllabusQuizDetailsPage'));
const CourseDetailsPage = loadable(() => import('../pages/lms/learner/LmsCourseDetailsPage'));
const SyllabusDetailsPage = loadable(() => import('../pages/lms/learner/LmsSyllabusDetailsPage'));
const LmsLearnerDashboardPage = loadable(() =>
	import('../components/dashboard/AnalyticsOverviewV1/SisenseDashboard/AnalysticsSisenseLMSContainer'),
);
const CourseAndSyllabusListPage = loadable(() => import('../pages/lms/learner/CoursesAndSyllabusesCatalogPage'));

const CourseAndSyllabusSearchPage = loadable(() => import('../pages/lms/learner/CourseAndSyllabusSearchPage'));
const MyCoursesAndSyllabusesPage = loadable(() => import('../pages/lms/learner/MyCoursesAndSyllabusesPage'));
const CreateUserPage = loadable(() => import('../pages/lms/UserManagement/CreateUserPage'));
const EditUserPage = loadable(() => import('../pages/lms/UserManagement/EditUserPage'));
const BulkEditUserPage = loadable(() => import('../pages/lms/UserManagement/BulkEditUserPage'));
const AddUserPage = loadable(() => import('../pages/lms/UserManagement/AddUserPage'));
const UserManagement = loadable(() => import('../pages/lms/UserManagement/UserManagementPage'));

const CreateUserGroupPage = loadable(() => import('../pages/lms/userGroupManagement/CreateUserGroupPage'));
const EditUserGroupPage = loadable(() => import('../pages/lms/userGroupManagement/EditUserGroupPage'));
const LmsCsManagementPage = loadable(() => import('../pages/lms/courseAndSyllabusManagement/LmsCsManagementPage'));
const CreateAndEditCoursePage = loadable(() => import('../pages/lms/course/CreateAndEditCoursePage'));
const CreateSyllabusPage = loadable(() => import('../pages/lms/syllabus/CreateSyllabusPage'));
const EditSyllabusPage = loadable(() => import('../pages/lms/syllabus/EditSyllabusPage'));

const QuizManagementPage = loadable(() => import('../pages/lms/quiz/QuizManagementPage'));
const CreateQuizPage = loadable(() => import('../pages/lms/quiz/CreateQuizPage'));
const EditQuizPage = loadable(() => import('../pages/lms/quiz/EditQuizPage'));
const BatchQuizCreatePage = loadable(() => import('../pages/lms/quiz/BatchQuizCreatePage'));
const BatchQuizEditPage = loadable(() => import('../pages/lms/quiz/BatchQuizEditPage'));

const CourseDetailPage = loadable(() => import('../pages/lms/course/CourseDetailsPage'));

export const LMS_ROUTE_BASE = '/nimblylearn';

const LMS_ROUTE_USERS = `${LMS_ROUTE_BASE}/users`;
const LMS_ROUTE_USER_GROUP = `${LMS_ROUTE_BASE}/user-groups`;

const LMS_ROUTE_COURSE = `${LMS_ROUTE_BASE}/course`;
const LMS_ROUTE_SYLLABUS = `${LMS_ROUTE_BASE}/syllabus`;
const LMS_ROUTE_COURSE_SYLLABUS = `${LMS_ROUTE_BASE}/courses-and-syllabus`;
const LMS_ROUTE_QUIZ = `${LMS_ROUTE_BASE}/quiz`;

export const LMS_ROUTE = {
	DASHBOARD: `${LMS_ROUTE_BASE}/dashboard`,

	USERS: LMS_ROUTE_USERS,
	USER_CREATE: `${LMS_ROUTE_USERS}/new`,
	USER_ADD: `${LMS_ROUTE_USERS}/add`,
	USER_EDIT: `${LMS_ROUTE_USERS}/edit`,
	USER_EDIT_BULK: `${LMS_ROUTE_USERS}/bulk-edit`,

	USER_GROUPS: LMS_ROUTE_USER_GROUP,
	USER_GROUP_CREATE: `${LMS_ROUTE_USER_GROUP}/new`,
	USER_GROUP_EDIT: `${LMS_ROUTE_USER_GROUP}/edit`,

	COURSE_AND_SYLLABUS_MANAGEMENT: `${LMS_ROUTE_COURSE_SYLLABUS}/manage`,

	COURSE: `${LMS_ROUTE_COURSE}`,
	COURSE_CREATE: `${LMS_ROUTE_COURSE}/new`,
	COURSE_EDIT: `${LMS_ROUTE_COURSE}/edit`,
	COURSE_DETAILS: `${LMS_ROUTE_COURSE}/details`,

	SYLLABUS: `${LMS_ROUTE_SYLLABUS}`,
	SYLLABUS_CREATE: `${LMS_ROUTE_SYLLABUS}/new`,
	SYLLABUS_EDIT: `${LMS_ROUTE_SYLLABUS}/edit`,
	SYLLABUS_QUIZ: `${LMS_ROUTE_SYLLABUS}/:syllabusId/quiz/:quizId`,

	COURSE_AND_SYLLABUS_CATALOG: `${LMS_ROUTE_COURSE_SYLLABUS}`,
	COURSE_AND_SYLLABUS_SEARCH: `${LMS_ROUTE_COURSE_SYLLABUS}/search`,
	MY_COURSE_AND_SYLLABUS: `${LMS_ROUTE_COURSE_SYLLABUS}/me`,

	QUIZ_MANAGEMENT: `${LMS_ROUTE_QUIZ}/manage`,
	QUIZ_CREATE: `${LMS_ROUTE_QUIZ}/new`,
	QUIZ_CREATE_BULK: `${LMS_ROUTE_QUIZ}/bulk-create`,
	QUIZ_EDIT: `${LMS_ROUTE_QUIZ}/edit`,
	QUIZ_EDIT_BULK: `${LMS_ROUTE_QUIZ}/bulk-edit`,
};

export default function LmsRoutes() {
	return (
		<Switch>
			{/* <Route
				exact
				path={LMS_ROUTE.DASHBOARD}
				component={LmsLearnerDashboardPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="dashboard.view"
			/> */}

			<Route
				exact
				path={LMS_ROUTE.USERS}
				component={UserManagement}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="users.view"
			/>
			<Route
				exact
				path={LMS_ROUTE.USER_CREATE}
				component={CreateUserPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="users.create"
			/>
			<Route
				exact
				path={`${LMS_ROUTE.USER_EDIT}/:userID`}
				component={EditUserPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="users.edit"
			/>
			<Route
				exact
				path={LMS_ROUTE.USER_EDIT_BULK}
				component={BulkEditUserPage}
				withValidation
				lmsRoleAccessType="users.bulk-edit"
			/>
			<Route
				exact
				path={LMS_ROUTE.USER_ADD}
				component={AddUserPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="users.edit"
			/>

			<Route
				exact
				path={LMS_ROUTE.USER_GROUPS}
				component={LmsUserGroupListPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="user-groups.view"
			/>
			<Route
				exact
				path={LMS_ROUTE.USER_GROUP_CREATE}
				component={CreateUserGroupPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="user-groups.create"
			/>
			<Route
				exact
				path={`${LMS_ROUTE.USER_GROUP_EDIT}/:groupId`}
				component={EditUserGroupPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="user-groups.edit"
			/>

			<Route
				exact
				path={LMS_ROUTE.COURSE_AND_SYLLABUS_CATALOG}
				component={CourseAndSyllabusListPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus.view"
			/>
			<Route
				exact
				path={LMS_ROUTE.COURSE_AND_SYLLABUS_SEARCH}
				component={CourseAndSyllabusSearchPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus.view"
			/>
			<Route
				exact
				path={LMS_ROUTE.MY_COURSE_AND_SYLLABUS}
				component={MyCoursesAndSyllabusesPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus.view"
			/>

			<Route
				exact
				path={LMS_ROUTE.COURSE_AND_SYLLABUS_MANAGEMENT}
				component={LmsCsManagementPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus-manage.view"
			/>

			<Route
				exact
				path={LMS_ROUTE.COURSE_CREATE}
				component={CreateAndEditCoursePage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus-manage.create"
			/>
			<Route exact path="/nimblylearn/dashboard" component={LmsDashboardRevampPage} />

			<Route
				exact
				path={`${LMS_ROUTE.COURSE_EDIT}/:courseId`}
				component={CreateAndEditCoursePage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus-manage.edit-self-created"
			/>
			<Route
				exact
				path={`${LMS_ROUTE.COURSE}/:courseId`}
				component={CourseDetailsPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus.view"
			/>
			<Route
				exact
				path={`${LMS_ROUTE.COURSE_DETAILS}/:courseId`}
				component={CourseDetailPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus.view"
			/>
			<Route
				exact
				path={LMS_ROUTE.SYLLABUS_CREATE}
				component={CreateSyllabusPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus-manage.create"
			/>
			<Route
				exact
				path={`${LMS_ROUTE.SYLLABUS_EDIT}/:id`}
				component={EditSyllabusPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus-manage.edit-self-created"
			/>
			<Route
				exact
				path={`${LMS_ROUTE.SYLLABUS}/:syllabusId`}
				component={SyllabusDetailsPage}
				withValidation
				feature={Features.LMS}
				lmsRoleAccessType="courses-syllabus.view"
			/>
			<Route
				exact
				path={LMS_ROUTE.QUIZ_MANAGEMENT}
				component={QuizManagementPage}
				withValidation
				feature={Features.LMS_QUIZ}
				lmsRoleAccessType="quiz-manage.view"
			/>
			<Route
				exact
				path={LMS_ROUTE.QUIZ_CREATE}
				component={CreateQuizPage}
				withValidation
				feature={Features.LMS_QUIZ}
				lmsRoleAccessType="quiz-manage.create"
			/>
			<Route
				exact
				path={LMS_ROUTE.QUIZ_CREATE_BULK}
				component={BatchQuizCreatePage}
				withValidation
				feature={Features.LMS_QUIZ}
				lmsRoleAccessType="quiz-manage.bulk-create"
			/>
			<Route
				exact
				path={`${LMS_ROUTE.QUIZ_EDIT}/:id`}
				component={EditQuizPage}
				withValidation
				feature={Features.LMS_QUIZ}
				lmsRoleAccessType="quiz-manage.edit"
			/>
			<Route
				exact
				path={LMS_ROUTE.QUIZ_EDIT_BULK}
				component={BatchQuizEditPage}
				withValidation
				feature={Features.LMS_QUIZ}
				lmsRoleAccessType="quiz-manage.bulk-edit"
			/>
			<Route
				exact
				path={LMS_ROUTE.SYLLABUS_QUIZ}
				component={LmsSyllabusQuizDetailsPage}
				withValidation
				feature={Features.LMS_QUIZ}
				lmsRoleAccessType="courses-syllabus.view"
			/>
		</Switch>
	);
}
