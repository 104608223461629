export const FETCH_TUTORIAL_REQUEST = '@tutorial/FETCH_TUTORIAL_REQUEST';
export const FETCH_TUTORIAL_SUCCESS = '@tutorial/FETCH_TUTORIAL_LOADING';
export const FETCH_TUTORIAL_FAILURE = '@tutorial/FETCH_TUTORIAL_FAILURE';

export const UPDATE_TUTORIAL_REQUEST = '@tutorial/UPDATE_TUTORIAL_REQUEST';
export const UPDATE_TUTORIAL_SUCCESS = '@tutorial/UPDATE_TUTORIAL_LOADING';
export const UPDATE_TUTORIAL_FAILURE = '@tutorial/UPDATE_TUTORIAL_FAILURE';

export const SET_TO_FINISH = '@tutorial/SET_TO_FINISH';
export const UPDATE_PROGRESS = '@tutorial/UPDATE_PROGRESS';
export const UPDATE_CUMULATIVE_PROGRESS = '@tutorial/UPDATE_CUMULATIVE_PROGRESS';
