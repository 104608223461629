import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import * as actions from './siteOffDays.action';
import * as types from './siteOffDays.actionTypes';
import { SiteOffDaysState, SiteOffDaysCallState } from './type.d';
import { SiteOffdayMongo } from '@nimbly-technologies/nimbly-common';

export type SiteOffDaysAction = ActionType<typeof actions>;

export const initialState: SiteOffDaysState = {
	error: '',
	fetchState: SiteOffDaysCallState.INITIAL,
	upsertState: SiteOffDaysCallState.INITIAL,
	siteOffDays: new SiteOffdayMongo({}),
};

export const siteOffDaysReducer = createReducer<SiteOffDaysState, SiteOffDaysAction>(initialState)
	.handleType(types.FETCH_SITE_OFF_DAYS_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: SiteOffDaysCallState.LOADING },
			siteOffDays: { $set: initialState.siteOffDays },
		}),
	)
	.handleType(types.FETCH_SITE_OFF_DAYS_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: SiteOffDaysCallState.SUCCESS },
			siteOffDays: { $set: action.payload.data },
		}),
	)
	.handleType(types.FETCH_SITE_OFF_DAYS_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: SiteOffDaysCallState.ERROR },
			siteOffDays: { $set: initialState.siteOffDays },
		}),
	)
	.handleType(types.UPSERT_SITE_OFF_DAYS_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			upsertState: { $set: SiteOffDaysCallState.LOADING },
		}),
	)
	.handleType(types.UPSERT_SITE_OFF_DAYS_SUCCESS, (state, action) =>
		update(state, {
			upsertState: { $set: SiteOffDaysCallState.SUCCESS },
			siteOffDays: { $set: action.payload.data },
		}),
	)
	.handleType(types.UPSERT_SITE_OFF_DAYS_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			upsertState: { $set: SiteOffDaysCallState.ERROR },
		}),
	)
	.handleType(types.RESET_SITE_OFF_DAYS_CALL_STATE, (state) =>
		update(state, {
			upsertState: { $set: SiteOffDaysCallState.INITIAL },
		}),
	);
