import { createAsyncAction } from 'typesafe-actions';
import {
  AuditorsAuditorsList,
  FETCH_AUDITORS_LIST_REQUEST,
  FETCH_AUDITORS_LIST_SUCCESS,
  FETCH_AUDITORS_LIST_FAILURE,
  DOWNLOAD_AUDITORS_LIST_REQUEST,
  DOWNLOAD_AUDITORS_LIST_SUCCESS,
  DOWNLOAD_AUDITORS_LIST_FAILURE,
  FetchAuditorsListPayload,
  DownloadAuditorsListPayload
} from './types';

export const fetchAuditorsListAsync = createAsyncAction(
  FETCH_AUDITORS_LIST_REQUEST,
  FETCH_AUDITORS_LIST_SUCCESS,
  FETCH_AUDITORS_LIST_FAILURE
)<FetchAuditorsListPayload, AuditorsAuditorsList, string>();

export const downloadAuditorsListAsync = createAsyncAction(
  DOWNLOAD_AUDITORS_LIST_REQUEST,
  DOWNLOAD_AUDITORS_LIST_SUCCESS,
  DOWNLOAD_AUDITORS_LIST_FAILURE
)<DownloadAuditorsListPayload, string, string>();
