import { INotificationAPIBase, INotificationStore } from './notification.type';
import { call, takeEvery } from 'redux-saga/effects';
import { fetchLmsNotification } from 'services/lms/lmsLearnerActivity.service';
import { put } from 'typed-redux-saga';
import * as actions from './notification.action';

function* fetchLmsNotificationSaga(action: ReturnType<typeof actions.fetchLmsNotificationAsync.request>): Generator {
	try {
		const res = (yield call(fetchLmsNotification) as unknown) as INotificationStore[];
		yield put(actions.fetchLmsNotificationAsync.success(res));
	} catch {
		yield put(actions.fetchLmsNotificationAsync.failure('Failed to get syllabuses details'));
	}
}

export default function* lmsNotificationSaga() {
	yield takeEvery(actions.fetchLmsNotificationAsync.request, fetchLmsNotificationSaga);
}
