import styled from 'styled-components';

export const CheckboxOption = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
`;

export const CheckboxLabelWrapper = styled.div`
	flex: 0 1 100%;
	width: 100%;
`;

export const CheckboxLabel = styled.label<{ isDisabled?: boolean }>`
	cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
	color: ${({ isDisabled }) => (isDisabled ? '#8F8F8F' : '#535353')};

	width: 100%;
	word-break: break-word;

	font-size: 0.875rem;
	line-height: 1.4375rem;
	font-weight: 400;
`;
