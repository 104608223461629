export enum SkuErrorEnum {
	USED_SKU_ID = 'used_sku_id',
	SKU_ID_EMPTY = 'sku_id_empty',
}

const singleSkuErrors: { [errorCode: number]: SkuErrorEnum } = {
	409: SkuErrorEnum.USED_SKU_ID,
	400: SkuErrorEnum.SKU_ID_EMPTY,
};

export class SkuApiError extends Error {
	public static async getError(response: Response): Promise<SkuApiError | Error | null> {
		const error = singleSkuErrors[response.status];
		const json = await response.clone().json();

		if (error) {
			return new SkuApiError(error, json?.message);
		}

		if (response.status >= 200 && response.status < 400) {
			return null;
		}

		return new Error(json?.message);
	}

	private _errorEnum: SkuErrorEnum;

	public constructor(error: SkuErrorEnum, message?: string) {
		super(message);
		this._errorEnum = error;
	}

	public get errorEnum() {
		return this._errorEnum;
	}
}
