import { PeriodPayload } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';
import { IssueResolutionRate } from '@nimbly-technologies/nimbly-common';

export const FETCH_ISSUES_RESOLUTION_RATE_REQUEST = '@@dashboard/issues/FETCH_ISSUES_RESOLUTION_RATE_REQUEST';
export const FETCH_ISSUES_RESOLUTION_RATE_SUCCESS = '@@dashboard/issues/FETCH_ISSUES_RESOLUTION_RATE_SUCCESS';
export const FETCH_ISSUES_RESOLUTION_RATE_FAILURE = '@@dashboard/issues/FETCH_ISSUES_RESOLUTION_RATE_FAILURE';

export type FetchIssuesResolutionRateQuery = {
	departments?: string[];
	sites?: string[];
	users?: string[];
	questionnaires?: string[];
} & PeriodPayload;

export interface IssuesResolutionRateState {
	issuesResolutionRate: {
		data: IssueResolutionRate;
		isLoading: boolean;
		error: string;
	};
}
