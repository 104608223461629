import { combineReducers } from 'redux';
import { all, spawn, call } from 'redux-saga/effects';

import { filterSitesReducer } from './sites/reducer';

import filterSitesSaga from './sites/actionSaga';

export const filterReducer = combineReducers({
  sites: filterSitesReducer
});

// keep alive saga pattern https://redux-saga.js.org/docs/advanced/RootSaga.html
export function* filterSaga() {
  const sagas = [filterSitesSaga];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);

            break;
          } catch (error) {
            if (process.env.NODE_ENV === 'development') {
              console.log(error);
            }
          }
        }
      })
    )
  );
}
