import { takeLatest, call, put } from 'redux-saga/effects';

import * as types from './actionType';
import * as actions from './action';

import { fetchCompletionCards } from './helper/fetchCompletionCards';

function* fetchCompletionCardsSaga(action: ReturnType<typeof actions.fetchCardCompletionAsync.request>): Generator {
  try {
    const fetchRes: types.FetchCompletionCardsResponse = (yield call(fetchCompletionCards, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(actions.fetchCardCompletionAsync.success(fetchRes.data));
    } else {
      yield put(actions.fetchCardCompletionAsync.failure('FETCH COMPLETION CARDS FAILED'));
    }
  } catch (e) {
    yield put(actions.fetchCardCompletionAsync.failure('FETCH FLAG CARDS FAILED'));
  }
}

export function* cardCompletionSaga() {
  yield takeLatest(types.FETCH_CARD_COMPLETION_REQUEST, fetchCompletionCardsSaga);
}
