import { combineReducers } from 'redux';

import siteDetails from './siteDetails/siteDetails.reducer';
import siteSKU from './sku/sku.reducer';
import siteSchedule from './siteSchedule/siteSchedule.reducer';

export default combineReducers({
  siteDetails,
  siteSKU,
  siteSchedule
});
