import { createAsyncAction } from 'typesafe-actions';
import * as types from './downloadManager.actionTypes';
import { IBulkDownloadReportRequest, enums } from 'nimbly-common';

export const fetchMissedReportDownloadList = createAsyncAction(
  types.FETCH_MISSED_REPORTS_LIST_REQUEST,
  types.FETCH_MISSED_REPORTS_LIST_SUCCESS,
  types.FETCH_MISSED_REPORTS_LIST_FAILURE
)<undefined, { data: IBulkDownloadReportRequest[] }, { error: string }>();

export const countMissedReport = createAsyncAction(
  types.COUNT_MISSED_REPORTS_REQUEST,
  types.COUNT_MISSED_REPORTS_SUCCESS,
  types.COUNT_MISSED_REPORTS_FAILURE
)<
  { startDate: string; endDate: string; department: string; user: string; site: string },
  { numberOfReports: number; isDownloadableExists: boolean },
  { error: string }
>();

export const generateMissedReport = createAsyncAction(
  types.GENERATE_MISSED_REPORTS_REQUEST,
  types.GENERATE_MISSED_REPORTS_SUCCESS,
  types.GENERATE_MISSED_REPORTS_FAILURE
)<
  {
    startDate: string;
    endDate: string;
    department: string;
    user: string;
    site: string;
    reportFormat: enums.ReportType;
  },
  { downloadableUrl: string },
  { error: string }
>();
