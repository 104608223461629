import { UpsertQuestionnairePayload, UpsertQuestionnaireResponse } from 'reducers/admin/manage/questionnaires/types';
import { getToken } from 'reducers/api';
import { apiURL } from '../../../../config/baseURL';
import Monitoring from '../../../../utils/monitoring/Monitoring';

export const upsertQuestionnaire = async (
	questionnaire: UpsertQuestionnairePayload,
): Promise<UpsertQuestionnaireResponse> => {
	try {
		const token = await getToken();
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: token,
			},
			body: JSON.stringify(questionnaire),
		};
		const url = `${apiURL}/questionnaires/`;

		const res = await fetch(url, options);
		// THROW IF RESPONSE NOT OK
		if (res.status !== 200) {
			const errorResponse: UpsertQuestionnaireResponse = await res.json();
			const errMsg = errorResponse?.data?.message || '';
			throw new Error(errMsg);
		}

		const upsertQuestionnaireResult: UpsertQuestionnaireResponse = await res.json();
		return upsertQuestionnaireResult;
	} catch (e) {
		Monitoring.logEvent('upsertQuestionnaire', e);
		const errorResult: UpsertQuestionnaireResponse = {
			message: 'FAILED',
			data: e,
		};
		return errorResult;
	}
};
