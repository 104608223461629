import { FunctionReturn } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/userManagement/createUserGroup/userGroup.action';
import * as types from 'reducers/lms/userManagement/createUserGroup/userGroup.actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';

interface ActionI {
	type: string;
	payload: { [key: string]: string };
	meta: string | undefined;
}

function* createGroupUser(action: ActionI) {
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const body = action.payload.data;

		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const createUserURL = `${apiURL}/lms/user-groups`;
		const request = () => fetch(createUserURL, options);
		// @ts-ignore
		const response = yield call(request);

		if (response && response.status === 200) {
			yield put(actions.createUserGroup.success());
			toast.success(i18n.t('message.lms.userManagement.userGroup.create.success'));
		} else {
			// @ts-ignore
			const CreateUserGroupResponseData = yield response.json();
			const message = CreateUserGroupResponseData.data;
			toast.error(message);
			if (message === 'No access to user' || message === 'Failed to Fetch Create User Group API') {
				//
			}
			yield put(actions.createUserGroup.failure({ error: CreateUserGroupResponseData.data }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.userGroup.create.failed'));
		yield put(actions.createUserGroup.failure({ error: 'Failed to Create User Group' }));
	}
}

function* updateUserGroup(action: ActionI) {
	try {
		const groupID = action.payload.groupID;
		const body = action.payload.data;
		// @ts-ignore
		const authToken = yield getToken();
		const options = {
			method: 'PUT',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const updateUser = `${apiURL}/lms/user-groups/${groupID}`;
		const request = () => fetch(updateUser, options);

		const response: Response = yield (call(request) as unknown) as Response;

		if (response && response.status === 200) {
			yield put(actions.updateUserGroup.success());
			toast.success(i18n.t('message.lms.userManagement.userGroup.update.success'));
		} else if (response && response.status === 409) {
			const data: FunctionReturn<null> = yield (response.json() as unknown) as FunctionReturn<null>;
			const errorMessage = `${i18n.t('message.lms.userManagement.userGroup.update.failed')} - ${data.data}`;

			yield put(actions.updateUserGroup.failure({ error: errorMessage }));
			toast.error(errorMessage);
		} else {
			// @ts-ignore
			const UpdateUserGroupResponseData = yield response.json();
			if (UpdateUserGroupResponseData.data && Array.isArray(UpdateUserGroupResponseData.data)) {
				yield put(actions.setShowModal(true));
				yield put(
					actions.updateUserGroup.failure({
						error: UpdateUserGroupResponseData.message,
						errorData: UpdateUserGroupResponseData.data,
					}),
				);
				return;
			}
			yield put(actions.updateUserGroup.failure({ error: UpdateUserGroupResponseData.message }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.userGroup.update.failed'));
		yield put(actions.updateUserGroup.failure({ error: 'Failed to update User Group' }));
	}
}

export default function* createUserGroupSaga() {
	yield takeLatest(types.UPDATE_USER_GROUP_REQUEST, updateUserGroup);
	yield takeLatest(types.CREATE_USER_GROUP_REQUEST, createGroupUser);
}
