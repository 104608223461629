import { ActionType, createReducer } from 'typesafe-actions';
import { RepositoryUserUsageSortBy, RepositoryUserUsageState } from './type.d';
import * as actions from './repositoryUserUsage.action';
import { SortDirection } from 'utils/sort';

export type RepositoryUserUsageAction = ActionType<typeof actions>;

export const initialState: RepositoryUserUsageState = {
	isLoading: false,
	error: '',
	page: 1,
	limit: 10,
	totalDocs: 0,
	totalPages: 0,
	sortBy: RepositoryUserUsageSortBy.NAME,
	sortType: SortDirection.ASC,
	docs: [],
};

export const repositoryUserUsageReducer = createReducer<RepositoryUserUsageState, RepositoryUserUsageAction>(
	initialState,
)
	.handleAction(actions.setRepositoryUserUsagePageIndex, (state, action) => ({
		...state,
		page: action.payload,
	}))
	.handleAction(actions.setRepositoryUserUsageSortBy, (state, action) => ({
		...state,
		sortBy: action.payload,
	}))
	.handleAction(actions.setRepositoryUserUsageSortType, (state, action) => ({
		...state,
		sortType: action.payload,
	}))
	.handleAction(actions.fetchRepositoryUserUsage.request, (state, action) => ({
		...state,
		error: initialState.error,
		isLoading: true,
	}))
	.handleAction(actions.fetchRepositoryUserUsage.success, (state, action) => ({
		...state,
		...action.payload,
		error: initialState.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchRepositoryUserUsage.failure, (state, action) => {
		return {
			...state,
			error: action.payload.error,
			isLoading: false,
		};
	});
