import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './userDept.action';
import { userDeptCompactState } from './type';

const initialState: userDeptCompactState = {
	isLoading: false,
	data: [],
	message: '',
};

export type userDeptCompactAction = ActionType<typeof actions.userDeptAction>;
export const userDeptCompactReducer = createReducer<userDeptCompactState, userDeptCompactAction>(initialState)
	.handleAction(actions.userDeptAction.request, (state) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(actions.userDeptAction.success, (state, action) => ({
		...state,
		data: action.payload.data,
		message: action.payload.message,
		isLoading: false,
	}))
	.handleAction(actions.userDeptAction.failure, (state, action) => ({
		...state,
		message: action.payload.message,
		isLoading: false,
	}));
