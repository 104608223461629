export const SET_SYLLABUSES_PAGE_INDEX = '@lms/SET_SYLLABUSES_PAGE_INDEX';
export const SET_SYLLABUSES_SORT = '@lms/SET_SYLLABUSES_SORT';
export const SET_SYLLABUSES_SEARCH_QUERY = '@lms/SET_SYLLABUSES_SEARCH_QUERY';
export const SET_SYLLABUSES_FILTER_QUERY = '@lms/SET_SYLLABUSES_FILTER_QUERY';
export const RESET_SYLLABUSES_FILTER_QUERY = '@lms/RESET_SYLLABUSES_FILTER_QUERY';

export const FETCH_SYLLABUSES_REQUEST = '@lms/FETCH_SYLLABUSES_REQUEST';
export const FETCH_SYLLABUSES_SUCCESS = '@lms/FETCH_SYLLABUSES_SUCCESS';
export const FETCH_SYLLABUSES_FAILED = '@lms/FETCH_SYLLABUSES_FAILED';

export const SET_CONFIRMATION_MODAL_CONFIG = '@lms/SET_CONFIRMATION_MODAL_CONFIG';
export const TOGGLE_CONFIRMATION_MODAL = '@lms/TOGGLE_CONFIRMATION_MODAL';
