import { createAsyncAction, action } from 'typesafe-actions';
import { JourneyPlan } from 'nimbly-common';

import * as types from './journeys.actionType';
import { Journeys } from './type';

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const fetchJourneys = createAsyncAction(
  types.FETCH_JOURNEYS_REQUEST,
  types.FETCH_JOURNEYS_SUCCESS,
  types.FETCH_JOURNEYS_FAILURE
)<undefined, { data: Journeys | null }, { error: string }>();

export const createJourneys = createAsyncAction(
  types.CREATE_JOURNEYS_REQUEST,
  types.CREATE_JOURNEYS_SUCCESS,
  types.CREATE_JOURNEYS_FAILURE
)<any, any, { error: string }>();

export const createJourneyBulk = createAsyncAction(
  types.CREATE_JOURNEYS_BULK_REQUEST,
  types.CREATE_JOURNEYS_BULK_SUCCESS,
  types.CREATE_JOURNEYS_BULK_FAILURE
)<any, any, { errors: string[] }>();

export const fetchJourneyDetail = createAsyncAction(
  types.FETCH_JOURNEY_DETAIL_REQUEST,
  types.FETCH_JOURNEY_DETAIL_SUCCESS,
  types.FETCH_JOURNEY_DETAIL_FAILURE
)<any, { data: JourneyPlan | null }, { error: string }>();

export const updateJourney = createAsyncAction(
  types.UPDATE_JOURNEY_REQUEST,
  types.UPDATE_JOURNEY_SUCCESS,
  types.UPDATE_JOURNEY_FAILURE
)<any, any, { error: string }>();

export const deleteJourney = createAsyncAction(
  types.DELETE_JOURNEY_REQUEST,
  types.DELETE_JOURNEY_SUCCESS,
  types.DELETE_JOURNEY_FAILURE
)<any, any, { error: string }>();

export const fetchReportJourney = createAsyncAction(
  types.FETCH_REPORT_JOURNEYS_REQUEST,
  types.FETCH_REPORT_JOURNEYS_SUCCESS,
  types.FETCH_REPORT_JOURNEYS_FAILURE
)<any, { data: any }, { error: string }>();
