import {
  FetchSingleUserChartPayload,
  FetchSingleUserChartResponse
} from 'reducers/dashboard/auditors/singleUserChart/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummySingleUserChart } from 'utils/dashboardDummy/auditors/dummySingleUserChart';

export const fetchSingleUserChart = async (
  payload: FetchSingleUserChartPayload
): Promise<FetchSingleUserChartResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummySingleUserChart = getDummySingleUserChart(payload);
    return {
      message: 'SUCCESS',
      data: dummySingleUserChart
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const { userID, ...otherQuery } = query;
    const serializedQueries = serializeQuery(otherQuery);
    const url = `${apiURL}/statistics/users/${userID}/daily?${serializedQueries}`;
    const res = await fetch(url, options);

    const auditorsListResult: FetchSingleUserChartResponse = await res.json();
    return auditorsListResult;
  } catch {
    const errorResult: FetchSingleUserChartResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
