// utilities
import API from 'helpers/api';
import { toast } from 'react-toastify';
import { call, delay, put, takeLatest, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

// constants and data types
import { apiURL } from 'config/baseURL';
import { RootState } from 'store/rootReducers';
import { APIResponse } from 'constants/ResponseCollection';
import { DepartmentGroup } from 'nimbly-common';

// related redux
import * as actions from 'reducers/departmentGroup/departmentGroup.action';
import * as types from 'reducers/departmentGroup/departmentGroup.actionTypes';
import i18n from 'i18n';

// SELECTORS
const departmentGroupSelector = (state: RootState) => state.departmentGroup.departmentGroup;
const currentlyActiveIndexSelector = (state: RootState) => state.departmentGroup.currentlyActiveIndex;

function* getDepartmentGroup() {
  try {
    const authToken = yield API.getFirebaseToken();
    const url = `${apiURL}/departments/group?isWithDisabled=true`;
    const response = yield call(API.get, url, authToken);
    const responseData = yield call(response.json.bind(response));

    if (response && response.status === APIResponse.CODE.SUCCESS) {
      yield put(actions.getDepartmentGroup.success({ data: responseData.data }));
    } else {
      yield put(actions.getDepartmentGroup.failure({ error: responseData.message }));
    }
  } catch (e) {
    yield put(actions.getDepartmentGroup.failure({ error: 'Failed to get DepartmentGroup data' }));
  }
}

function* getDepartmentGroupByID(action: ReturnType<typeof actions.getDepartmentGroupById.request>) {
  try {
    const groupDeptID = action.payload.groupDeptID;

    const authToken = yield API.getFirebaseToken();
    const url = `${apiURL}/departments/group/${groupDeptID}`;
    const response = yield call(API.get, url, authToken);
    const responseData = yield call(response.json.bind(response));

    if (response && response.status === APIResponse.CODE.SUCCESS) {
      const newData = responseData.data;
      newData.id = responseData.data.ID;
      yield put(actions.getDepartmentGroupById.success({ data: newData }));
    } else {
      const departmentGroupList: DepartmentGroup[] = yield select(departmentGroupSelector) || [];
      const newData: DepartmentGroup | null = departmentGroupList.find(d => d.ID === groupDeptID) || null;

      if (newData) {
        yield put(actions.getDepartmentGroupById.success({ data: newData as DepartmentGroup }));
      } else {
        yield put(actions.getDepartmentGroupById.failure({ error: responseData.message }));
      }
    }
  } catch (e) {
    yield put(actions.getDepartmentGroupById.failure({ error: 'Failed to get DepartmentGroupByID data' }));
  }
}

// TODO: rename this to toggle department group status, as deleting and reactivating department group is using the same api.
function* deleteDepartmentGroupByID(action: ReturnType<typeof actions.deleteDepartmentGroup.request>) {
  try {
    const departmentGroup: DepartmentGroup[] = yield select(departmentGroupSelector) || [];
    const departmentGroupList: DepartmentGroup[] = cloneDeep(departmentGroup);
    const groupDeptID = action.payload.groupDeptID;
    let currentlyActiveIndex: number = yield select(currentlyActiveIndexSelector);

    if (currentlyActiveIndex < 0) {
      currentlyActiveIndex = departmentGroupList.findIndex(d => d.ID === groupDeptID);
    }

    const authToken = yield API.getFirebaseToken();
    const url = `${apiURL}/departments/group/toggle-status/${groupDeptID}`;
    const response = yield call(API.put, url, authToken, {});
    const responseData = yield call(response.json.bind(response));

    const newStatus = departmentGroupList[currentlyActiveIndex].status === 'active' ? 'disabled' : 'active';
    departmentGroupList[currentlyActiveIndex].status = newStatus;

    if (response && response.status === APIResponse.CODE.SUCCESS) {
      yield delay(500);
      yield put(
        actions.deleteDepartmentGroup.success({
          newDeptGroup: departmentGroupList,
          selectedDepartment: departmentGroupList[currentlyActiveIndex]
        })
      );
      toast.success(
        newStatus === 'active'
          ? i18n.t('message.departmentGroupPage.reactivate.success')
          : i18n.t('message.departmentGroupPage.deactivate.success')
      );
    } else {
      toast.error(
        newStatus === 'active'
          ? i18n.t('message.departmentGroupPage.reactivate.failed')
          : i18n.t('message.departmentGroupPage.deactivate.failed')
      );
      yield put(actions.deleteDepartmentGroup.failure({ error: responseData.message }));
    }
  } catch (e) {
    toast.error(`Unable to modify your Department Group`);
    yield put(actions.deleteDepartmentGroup.failure({ error: 'Failed to get delete Department Group' }));
  }
}

export default function* departmentGroupSaga() {
  yield takeLatest(types.GET_DEPT_GROUP_REQUEST, getDepartmentGroup);
  yield takeLatest(types.GET_DEPT_GROUP_BY_ID_REQUEST, getDepartmentGroupByID);
  yield takeLatest(types.DELETE_DEPT_GROUP_REQUEST, deleteDepartmentGroupByID);
}
