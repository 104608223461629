import { createAsyncAction } from 'typesafe-actions';
import { IssueResolutionRate } from '@nimbly-technologies/nimbly-common';

import {
	FETCH_ISSUES_RESOLUTION_RATE_REQUEST,
	FETCH_ISSUES_RESOLUTION_RATE_SUCCESS,
	FETCH_ISSUES_RESOLUTION_RATE_FAILURE,
	FetchIssuesResolutionRateQuery,
} from './types';

export const fetchIssuesResolutionRateAsync = createAsyncAction(
	FETCH_ISSUES_RESOLUTION_RATE_REQUEST,
	FETCH_ISSUES_RESOLUTION_RATE_SUCCESS,
	FETCH_ISSUES_RESOLUTION_RATE_FAILURE,
)<FetchIssuesResolutionRateQuery, IssueResolutionRate, string>();
