import { UserCompactResponse } from '@nimbly-technologies/nimbly-common/src/models/api/lms/response';
import { fetchLmsUsersCompact } from 'services/lms/lmsUsersCompact.service';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import * as actions from '../../../reducers/lms/userManagement/lmsUsersCompact/lmsUsersCompact.action';
import queryString from 'query-string';

export function* fetchLmsUsersCompactSaga({ payload }: ReturnType<typeof actions.fetchLmsUsersCompact.request>) {
	try {
		const query: Record<string, any> = {
			withDisabled: payload.withDisabled ?? false,
			role: payload.roles,
		};
		const queryStr = queryString.stringify(query);
		const lmsUsersCompact: UserCompactResponse[] = yield* call(fetchLmsUsersCompact, queryStr);

		yield put(actions.fetchLmsUsersCompact.success({ lmsUsersCompact }));
	} catch (e) {
		yield put(actions.fetchLmsUsersCompact.failure({ error: 'Failed to fetch sku compact' }));
		return null;
	}
}

export default function* lmsUsersCompactSaga() {
	yield all([takeEvery(actions.fetchLmsUsersCompact.request.toString(), fetchLmsUsersCompactSaga)]);
}
