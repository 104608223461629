import { UpdateSitePayload, UpsertSiteResponse } from 'reducers/admin/manage/sites/types';
import { getToken } from 'reducers/api';
import { appengineURL } from '../../../../config/baseURL';
import Monitoring from '../../../../utils/monitoring/Monitoring';
import { hasErrorMessage } from './createSite';

export const updateSite = async (payload: UpdateSitePayload): Promise<UpsertSiteResponse> => {
	const { siteID, ...update } = payload;
	try {
		const token = await getToken();
		const options = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				authorization: token,
			},
			body: JSON.stringify(update),
		};
		const url = `${appengineURL}/sites/${siteID}`;

		const res = await fetch(url, options);
		const uploadSiteResult: UpsertSiteResponse = await res.json();
		// THROW IF RESPONSE NOT OK
		if (res.status !== 200) {
			throw new Error(hasErrorMessage(uploadSiteResult.message) ? uploadSiteResult.message : uploadSiteResult.data);
		}

		return uploadSiteResult;
	} catch (error) {
		Monitoring.logEvent('updateSite', error);
		const rawErrorMessage = error.message;
		const markedMessage: string = rawErrorMessage.replace(/[^A-Za-z0-9 ]/gi, '$');

		let processedErrorMessage = '';

		if (markedMessage.indexOf('$') > -1) {
			const splittedMarkedMessage = markedMessage.split('$');
			const errorProps = splittedMarkedMessage[2] || 'Some field(s)';
			const tailMessage = splittedMarkedMessage[splittedMarkedMessage.length - 1];
			processedErrorMessage = errorProps + tailMessage;
		}

		const errorResult: UpsertSiteResponse = {
			message: 'FAILED',
			error: processedErrorMessage || rawErrorMessage,
		};
		return errorResult;
	}
};
