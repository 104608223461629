import { call, takeEvery } from 'redux-saga/effects';

import { put } from 'typed-redux-saga';
import * as actions from './reportsHub.action';
import { fetchReportList, fetchReportCount, fetchReportsSavedFilters } from 'services/reportshub.service';
import { ReportsTableData, ReportCountDetails } from 'components/reportshub/ReportHub.types';
import { SavedFiltersI } from './reportsHub.types';

function* fetchReportListSaga(action: ReturnType<typeof actions.fetchReportsHubTableAsync.request>): Generator {
	try {
		const res = (yield call(fetchReportList) as unknown) as ReportsTableData;
		yield put(actions.fetchReportsHubTableAsync.success(res));
	} catch {
		yield put(actions.fetchReportsHubTableAsync.failure('Failed to get Report details'));
	}
}

function* fetchReportsCountSaga(action: ReturnType<typeof actions.fetchReportsCountAsync.request>): Generator {
	try {
		const res = (yield call(fetchReportCount) as unknown) as ReportCountDetails;
		yield put(actions.fetchReportsCountAsync.success(res));
	} catch {
		yield put(actions.fetchReportsCountAsync.failure('Failed to get Reports count'));
	}
}

function* fetchReportsFiltersSaga(action: ReturnType<typeof actions.fetchReportsFilterAsync.request>): Generator {
	try {
		const res = (yield call(fetchReportsSavedFilters) as unknown) as SavedFiltersI[];
		yield put(actions.fetchReportsFilterAsync.success(res));
	} catch {
		yield put(actions.fetchReportsFilterAsync.failure('Failed to get Reports count'));
	}
}

export default function* reportsHubSaga() {
	yield takeEvery(actions.fetchReportsHubTableAsync.request, fetchReportListSaga);
	yield takeEvery(actions.fetchReportsCountAsync.request, fetchReportsCountSaga);
	yield takeEvery(actions.fetchReportsFilterAsync.request, fetchReportsFiltersSaga);
}
