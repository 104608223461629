import { createAsyncAction } from 'typesafe-actions';

import * as types from './organizations.actionsTypes';

const fetchOrganizations = createAsyncAction(
  types.GET_ORGANIZATION_REQUEST,
  types.GET_ORGANIZATION_SUCCESS,
  types.GET_ORGANIZATION_FAILURE,
  types.GET_ORGANIZATION_CANCEL
)<undefined, { data: any }, { error: string }, undefined>();

export { fetchOrganizations };
