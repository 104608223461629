import { call, put, takeLatest } from 'typed-redux-saga';
import * as types from '../../reducers/lms/userManagement/courses/courses.actionTypes';
import * as actions from '../../reducers/lms/userManagement/courses/courses.action';
import { UOM } from '@nimbly-technologies/nimbly-common';
import { fetchLmsCourses } from '../../services/lms/lms.service';

function* fetchLmsCoursesSaga(): Generator {
	try {
		const courses: any[] = yield* call(fetchLmsCourses);
		yield put(actions.fetchLmsCourses.success(courses));
	} catch {
		yield put(actions.fetchLmsCourses.failure('Failed to get categories'));
	}
}

export default function* lmsCoursesSaga() {
	yield takeLatest(types.FETCH_LMS_COURSES_REQUEST, fetchLmsCoursesSaga);
}
