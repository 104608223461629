import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import * as actions from './siteActivityHistory.action';
import * as types from './siteActivityHistory.actionTypes';
import { SiteActivityHistoryState, SiteActivityHistoryFetchState } from './type.d';

export type SiteActivityHistoryAction = ActionType<typeof actions>;

export const initialState: SiteActivityHistoryState = {
	error: '',
	fetchState: SiteActivityHistoryFetchState.SUCCESS,
	siteActivityHistory: [],
};

export const siteActivityHistoryReducer = createReducer<SiteActivityHistoryState, SiteActivityHistoryAction>(
	initialState,
)
	.handleType(types.FETCH_SITE_ACTIVITY_HISTORY_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: SiteActivityHistoryFetchState.LOADING },
			siteActivityHistory: { $set: initialState.siteActivityHistory },
		}),
	)
	.handleType(types.FETCH_SITE_ACTIVITY_HISTORY_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: SiteActivityHistoryFetchState.SUCCESS },
			siteActivityHistory: { $set: action.payload.data },
		}),
	)
	.handleType(types.FETCH_SITE_ACTIVITY_HISTORY_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: SiteActivityHistoryFetchState.ERROR },
			siteActivityHistory: { $set: initialState.siteActivityHistory },
		}),
	)
	.handleType(types.CLEAR_SITE_ACTIVITY_HISTORY, () => initialState);
