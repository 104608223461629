const Constants = {
	VIEW_TAB: {
		STOCKS: 'stocks',
		TRENDS: 'trends',
		AUDITOR_DETAILS: 'auditorDetails',
		OVERVIEW: 'overview',
		AUDITORS: 'auditors',
		CALCULATION: 'calculation',
		SITES: 'sites',
		ISSUES: 'issues',
		INVENTORY: 'inventory',
	},
	USER_ROLE: {
		ACCOUNT_HOLDER: 'account_holder',
		SUPER_ADMIN: 'superadmin',
	},
	HOSTING_SERVER: {
		DEV: 'development',
		STAGING: 'staging',
		PROD: 'production',
		TEST: 'testing',
	},
};

const RingColor = {
	RED: 'red',
	GREEN: 'green',
	YELLOW: 'yellow',
};

const GOOGLE_TAG_MANAGER_ID = 'GTM-W6PN2M3';
const REACT_GA_TRACKING_CODE = 'UA-136013319-1';
export { Constants, RingColor, GOOGLE_TAG_MANAGER_ID, REACT_GA_TRACKING_CODE };

export const SCREEN_SIZE_DEFAULT_SMALL = 991;

export enum SORTING_ORDER {
	DESC = 'DESC',
	ASC = 'ASC',
}

export const DEFAULT_TIMEZONE = 'Asia/Jakarta';
