import { toast } from 'react-toastify';
import { call, put, select } from 'typed-redux-saga';
import * as actions from './repositoryUserUsage.action';
import * as types from './repositoryUserUsage.actionTypes';
import { takeLatest } from 'redux-saga/effects';
import { fetchRepositoryUserUsage } from 'services/repositoryUserUsage/repositoryUserUsage.service';
import { RootState } from 'store/rootReducers';
import { RepositoryUserUsageState } from './type.d';
import queryString from 'query-string';

export function* fetchRepositoryUserUsageSaga() {
	const repositoryUserUsageState = (state: RootState) => state.repositoryUserUsage;
	const state: RepositoryUserUsageState = yield select(repositoryUserUsageState);

	try {
		const query = {
			page: state.page,
			limit: state.limit,
			sortBy: state.sortBy,
			sortType: state.sortType,
		};
		const queryStr = queryString.stringify(query);
		const result = yield* call(fetchRepositoryUserUsage, queryStr);
		yield put(actions.fetchRepositoryUserUsage.success(result));
	} catch (e) {
		toast.error((e as Error).message);
		yield put(actions.fetchRepositoryUserUsage.failure({ error: 'Failed to fetch attachments' }));
	}
}

export default function* repositoryUserUsageSaga() {
	yield takeLatest(types.FETCH_REPOSITORY_USER_USAGE_REQUEST, fetchRepositoryUserUsageSaga);
	yield takeLatest(types.SET_REPOSITORY_USER_USAGE_SORT_BY, fetchRepositoryUserUsageSaga);
	yield takeLatest(types.SET_REPOSITORY_USER_USAGE_SORT_TYPE, fetchRepositoryUserUsageSaga);
	yield takeLatest(types.SET_REPOSITORY_USER_USAGE_PAGE_INDEX, fetchRepositoryUserUsageSaga);
}
