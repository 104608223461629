import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import LoadingDots from '../../global/LoadingDots/LoadingDots';
import Layout from 'components/global/Layout/Layout';
import AdBlockBlocker from 'components/adblockblocker/AdBlockBlocker';
import { AdminPage, PageWrapper } from 'styles/General';

function LoadingAccess() {
  const { t } = useTranslation();
  return (
    <Root>
      <LoadingDots />
      <h1>{t('addOn.noAccessPage.loadingLabel')}</h1>
    </Root>
  );
}

const LoadingAccessPage = () => {
  return (
    <Layout>
      <AdBlockBlocker />
      <AdminPage>
        <AdaptiveWrapper>
          <LoadingAccess />
        </AdaptiveWrapper>
      </AdminPage>
    </Layout>
  );
};

const AdaptiveWrapper = styled(PageWrapper)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1em;
    margin: 0.6em 0 0.3em 0;
  }

  img {
    width: 60%;
    max-width: 250px;
  }
`;

export default LoadingAccessPage;
