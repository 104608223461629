import { createAsyncAction, createAction } from 'typesafe-actions';

import {
  UPSERT_QUESTIONNAIRE_REQUEST,
  UPSERT_QUESTIONNAIRE_SUCCESS,
  UPSERT_QUESTIONNAIRE_FAILURE,
  UpsertQuestionnairePayload,
  UpsertedQuestionnaireID,
  CLEAR_QUESTIONNAIRE_STATE,
  FETCH_QUESTION_CATEGORIES_REQUEST,
  FETCH_QUESTION_CATEGORIES_SUCCESS,
  FETCH_QUESTION_CATEGORIES_FAILURE,
  QuestionCategories
} from './types';

export const upsertQuestionnaireAsync = createAsyncAction(
  UPSERT_QUESTIONNAIRE_REQUEST,
  UPSERT_QUESTIONNAIRE_SUCCESS,
  UPSERT_QUESTIONNAIRE_FAILURE
)<UpsertQuestionnairePayload, UpsertedQuestionnaireID, string>();

export const clearQuestionnaireState = createAction(CLEAR_QUESTIONNAIRE_STATE)<undefined>();

export const fetchQuestionCategoriesAsync = createAsyncAction(
  FETCH_QUESTION_CATEGORIES_REQUEST,
  FETCH_QUESTION_CATEGORIES_SUCCESS,
  FETCH_QUESTION_CATEGORIES_FAILURE
)<undefined, QuestionCategories, string>();
