import * as Sentry from '@sentry/react';

import {store} from '../../store/configStore';

export enum LogLevel {
	INFO,
	WARN,
	ERROR,
}
const SentryLogLevelMap: Record<LogLevel, Sentry.Severity> = {
	[LogLevel.INFO]: Sentry.Severity.Info,
	[LogLevel.WARN]: Sentry.Severity.Warning,
	[LogLevel.ERROR]: Sentry.Severity.Error,
};

class Monitoring {
	private _email: string;

	public constructor() {
		this._email = '';
	}
	public logEvent(event: string, data?: any, level: LogLevel = LogLevel.INFO ) {
		this.setUserAttributes();
		Sentry.addBreadcrumb({
			category: 'log',
			message: event,
			level: SentryLogLevelMap[level],
			data,
		});
	}

	public logError(error: Error) {
		this.setUserAttributes();
		Sentry.captureException(error, { level: Sentry.Severity.Error });
	}

	public setUserAttributes() {
		const state = store.getState();
		const { auth, profile } = state.firebase;
		this._email = auth.email || '';

		Sentry.setUser({
			email: this._email,
		});

		Sentry.setContext('User Details', {
			email: auth?.email || '-',
			displayName: profile?.displayName || '-',
			organizationId: profile?.organization || '-',
			role: profile?.role || '-',
			status: profile?.status || '-',
			language: profile?.language || '-',
		});
	}
}

export default new Monitoring();
