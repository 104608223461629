/* eslint-disable */
import { apiURL, appengineURL } from 'config/baseURL';
import { PopulatedSite } from 'nimbly-common';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/sites/sites.action';
import * as types from 'reducers/sites/sites.actionTypes';
import { OrderedPopulatedSite, OrderedSite, SitesState } from 'reducers/sites/sites.reducer';
import { SiteExtend, Sites } from 'reducers/sites/type';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/rootReducers';

interface Action {
	type: string;
	payload: any;
	meta: string | undefined;
}

export function* fetchPaginateSites(limit: number) {
	try {
		const authToken = yield getToken();
		const getState = (state: RootState) => state;
		const state: RootState = yield select(getState);

		const siteState: SitesState = state.sites;

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};
		let sortDirections = 'asc';
		if (siteState.sortBy === 'name') {
			sortDirections = siteState.name;
		} else if (siteState.sortBy === 'primaryDepartment') {
			sortDirections = siteState.primaryDepartment;
		} else if (siteState.sortBy === 'supervisor') {
			sortDirections = siteState.supervisor;
		} else if (siteState.sortBy === 'auditor') {
			sortDirections = siteState.auditor;
		} else if (siteState.sortBy === 'updatedAt') {
			sortDirections = siteState.updatedAt;
		} else if (siteState.sortBy === 'updatedBy') {
			sortDirections = siteState.updatedBy;
		}

		const status = siteState.listActiveTab === 'blocked' ? 'disabled' : 'active';

		const query: Record<string, string> = {
			page: String(siteState.pageIndex),
			sortFields: siteState.sortBy,
			sortDirections,
			limit: String(limit),
			status,
		};
		if (siteState.filterQuery) query.search = siteState.filterQuery;
		const queryStr = new URLSearchParams(query);

		const fetchSitesURL = `${appengineURL}/sites/paginate?${queryStr}`;
		const request = () => fetch(fetchSitesURL, options);
		const response = yield call(request);

		if (response?.status === 200) {
			const responseData = yield response.json();
			const mappingData: Sites = {};

			responseData.data.docs.forEach((site: PopulatedSite) => {
				const siteKey: string = site.siteID;
				if (!mappingData.hasOwnProperty(siteKey)) {
					mappingData[siteKey] = site;
				}
			});
			yield put(actions.setTotalSites(responseData.data.totalDocs));
			yield put(actions.setTotalActive(responseData.data.totalActive));
			yield put(actions.setTotalDisabled(responseData.data.totalDisabled));
			yield put(actions.fetchPaginateSites.success({ data: mappingData }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchPaginateSites.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchPaginateSites.success({ data: null }));
		yield put(actions.fetchPaginateSites.failure({ error: 'Failed to Fetch Sites' }));
		return null;
	}
}

export function* fetchSiteDepartments(action: Action) {
	yield actions.setIsLoading(true);
	try {
		const authToken = yield getToken();
		const siteKey = action.payload.siteKey;

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchSiteDepartemntsURL = `${apiURL}/departments/site/${siteKey}`;
		const request = () => fetch(fetchSiteDepartemntsURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			yield put(actions.fetchSiteDepartments.success({ data: responseData.data }));
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchSiteDepartments.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchSiteDepartments.success({ data: [] }));
		yield put(actions.fetchSiteDepartments.failure({ error: 'Failed to Fetch Site' }));
		return null;
	}
}

export function* fetchSiteById(action: Action) {
	yield actions.setIsLoading(true);
	try {
		const authToken = yield getToken();
		const siteKey = action.payload.siteKey;

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchSitesURL = `${appengineURL}/sites/${siteKey}/populated`;
		const request = () => fetch(fetchSitesURL, options);
		const response = yield call(request);

		if (response && response?.status === 200) {
			const responseData = yield response.json();

			yield put(actions.fetchSiteById.success({ data: responseData.data }));
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchSiteById.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchSiteById.success({ data: null }));
		yield put(actions.fetchSiteById.failure({ error: 'Failed to Fetch Site' }));
		return null;
	}
}

export function* fetchSites() {
	try {
		const authToken = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchSitesURL = `${appengineURL}/sites`;
		const request = () => fetch(fetchSitesURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			const mappingData: Sites = {};
			const orderedSites: OrderedSite[] = [];

			responseData.data.forEach((site: SiteExtend) => {
				const siteKey: string = site.siteID;
				if (!mappingData.hasOwnProperty(siteKey)) {
					mappingData[siteKey] = site;
					orderedSites.push({
						key: siteKey,
						value: site,
					});
				}
			});

			yield put(actions.fetchSites.success({ data: mappingData, ordered: orderedSites }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchSites.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchSites.success({ data: null, ordered: [] }));
		yield put(actions.fetchSites.failure({ error: 'Failed to Fetch Sites' }));
		return null;
	}
}

function* getPopulatedSites(action: Action) {
	yield put(actions.setIsSitesLoaded(false));
	try {
		yield put(actions.setIsLoading(true));
		// TODO
		const sites: { [key: string]: PopulatedSite } = yield fetchPaginateSites(action.payload.limit);

		const orderSites = (loadedSites: { [key: string]: PopulatedSite }): Promise<OrderedPopulatedSite[]> => {
			return new Promise((resolve) => {
				let orderedSitesData: OrderedPopulatedSite[] = [];

				if (loadedSites) {
					orderedSitesData = Object.keys(loadedSites).map((key) => {
						const site = loadedSites[key];
						const primaryDepartment = site.primaryDepartment ? site.primaryDepartment : 'N/A';
						const supervisor = site.populated.owner ? site.populated.owner.displayName : 'None';
						const updatedByName = site.populated.updatedBy ? site.populated.updatedBy.displayName : '';
						const assignedAuditorName = site.primaryAuditor.displayName ? site.primaryAuditor.displayName : 'None';

						const updatedSite = {
							key,
							updatedByName,
							assignedAuditorName,
							value: { ...site, primaryDepartment, supervisor },
						};

						return updatedSite;
					});
				}
				return resolve(orderedSitesData);
			});
		};

		const orderedSites = yield orderSites(sites);

		yield put(actions.setSites(orderedSites));
	} catch (e) {}
	yield put(actions.setIsSitesLoaded(true));
	yield put(actions.setIsLoading(false));
}

function* updateSiteDepartments(action: Action) {
	try {
		const authToken = yield getToken();
		const siteKey = action.payload.siteKey;
		const departments = action.payload.data;

		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ siteID: siteKey, departmentIDs: departments }),
		};

		const updateSiteDepartmentsURL = `${apiURL}/departments/insert-site`;
		const request = () => fetch(updateSiteDepartmentsURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			yield put(actions.updateSiteDepartments.success());
		} else {
			const responseData = yield response.json();
			yield put(actions.updateSiteDepartments.failure({ error: responseData.message }));
		}
	} catch (e) {
		yield put(actions.updateSiteDepartments.failure({ error: 'Failed to Fetch Sites' }));
		return null;
	}
}

export default function* sitesSaga() {
	yield takeLatest(types.FETCH_SITES_REQUEST, fetchSites);
	yield takeLatest(types.GET_POPULATED_SITES, getPopulatedSites);
	yield takeLatest(types.FETCH_SITE_BY_ID_REQUEST, fetchSiteById);
	yield takeLatest(types.FETCH_SITE_DEPARTMENTS_REQUEST, fetchSiteDepartments);
	yield takeLatest(types.UPDATE_SITE_DEPARTMENTS_REQUEST, updateSiteDepartments);
}
