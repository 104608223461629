import _ from 'lodash';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './coursesSyllabuses.action';
import { ILmsCoursesSyllabusesStore } from './coursesSyllabuses.type';
import * as types from './coursesSyllabuses.actionTypes';

const initialFetchState = {
	data: null,
	errorMessage: '',
	isLoading: false,
	search: '',
	sortFields: 'name',
	filters: {
		type: [],
	},
	query: {
		limit: 10,
		offset: 0,
		page: 1,
		totalPages: 1,
	},
};

const initialMyCoursesSyllabusState = {
	data: null,
	errorMessage: '',
	isLoading: false,
	search: '',
	filters: {
		type: [],
	},
	sortFields: 'lastUpdated',
	sortDirections: 'desc',
	query: {
		limit: 10,
		offset: 0,
		page: 1,
		totalPages: 1,
	},
};

const initialState: ILmsCoursesSyllabusesStore = {
	fetchAvailable: initialFetchState,
	fetchInProgress: initialFetchState,
	fetchRecentlyViewed: initialFetchState,
	fetchMyCoursesSyllabuses: initialMyCoursesSyllabusState,
	indicator: {
		data: null,
		isLoading: false,
		startDate: null,
		endDate: null,
	},
	enroll: {},
	currentTab: 'myprogress',
	courseID: '',
};

export type LmsCoursesSyllabusesAction = ActionType<typeof actions>;

export const lmsCoursesSyllabusesReducer = createReducer<ILmsCoursesSyllabusesStore, LmsCoursesSyllabusesAction>(
	initialState,
)
	.handleAction(actions.fetchLmsCoursesSyllabusesAvailableAsync.request, (state) => ({
		...state,
		fetchAvailable: {
			...state.fetchAvailable,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsCoursesSyllabusesAvailableAsync.success, (state, action) => {
		const query = _.omit(action.payload, ['docs']);

		return {
			...state,
			fetchAvailable: {
				...state.fetchAvailable,
				isLoading: false,
				data: action.payload.docs,
				query,
			},
		};
	})
	.handleAction(actions.fetchLmsCoursesSyllabusesAvailableAsync.failure, (state, action) => ({
		...state,
		fetchAvailable: {
			...state.fetchAvailable,
			isLoading: false,
			errorMessage: action.payload,
		},
	}))
	.handleAction(actions.setLmsCoursesSyllabusesAvailableSearch, (state, action) => ({
		...state,
		fetchAvailable: {
			...state.fetchAvailable,
			isLoading: true,
			query: { page: 1 },
			search: action.payload,
		},
	}))
	.handleAction(actions.setLmsCoursesSyllabusesAvailableSort, (state, action) => ({
		...state,
		fetchAvailable: {
			...state.fetchAvailable,
			isLoading: true,
			query: { page: 1 },
			sortFields: action.payload,
		},
	}))
	.handleAction(actions.setLmsCoursesSyllabusesAvailableFilters, (state, action) => ({
		...state,
		fetchAvailable: {
			...state.fetchAvailable,
			isLoading: true,
			query: { page: 1 },
			filters: {
				...state.fetchAvailable.filters,
				...action.payload,
			},
		},
	}))
	.handleAction(actions.resetLmsCoursesSyllabusesAvailableFilters, (state) => ({
		...state,
		fetchAvailable: {
			...state.fetchAvailable,
			isLoading: true,
			query: { page: 1 },
			filters: initialState.fetchAvailable.filters,
		},
		fetchMyCoursesSyllabuses: {
			...state.fetchMyCoursesSyllabuses,
			query: { page: 1 },
			filters: initialMyCoursesSyllabusState.filters,
		},
	}))
	.handleAction(actions.fetchLmsCoursesSyllabusesInProgressAsync.request, (state) => ({
		...state,
		fetchInProgress: {
			...state.fetchInProgress,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsCoursesSyllabusesInProgressAsync.success, (state, action) => {
		const query = _.omit(action.payload, ['docs']);

		return {
			...state,
			fetchInProgress: {
				...state.fetchInProgress,
				isLoading: false,
				data: action.payload.docs,
				query,
			},
		};
	})
	.handleAction(actions.fetchLmsCoursesSyllabusesInProgressAsync.failure, (state, action) => ({
		...state,
		fetchInProgress: {
			...state.fetchInProgress,
			isLoading: false,
			errorMessage: action.payload,
		},
	}))
	.handleAction(actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.request, (state) => ({
		...state,
		fetchRecentlyViewed: {
			...state.fetchRecentlyViewed,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.success, (state, action) => {
		const query = _.omit(action.payload, ['docs']);

		return {
			...state,
			fetchRecentlyViewed: {
				...state.fetchRecentlyViewed,
				isLoading: false,
				data: action.payload.docs,
				query,
			},
		};
	})
	.handleAction(actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.failure, (state, action) => ({
		...state,
		fetchRecentlyViewed: {
			...state.fetchRecentlyViewed,
			isLoading: false,
			errorMessage: action.payload,
		},
	}))
	.handleAction(actions.enrollLmsCoursesSyllabusesAsync.request, (state, action) => {
		const courseOrSyllabusID = action.payload.courseID ?? action.payload.syllabusID ?? '';

		return {
			...state,
			enroll: {
				...state.enroll,
				[courseOrSyllabusID]: {
					...state.enroll[courseOrSyllabusID],
					isLoading: true,
					errorMessage: '',
				},
			},
		};
	})
	.handleAction(actions.enrollLmsCoursesSyllabusesAsync.success, (state, action) => {
		return {
			...state,
			enroll: {
				...state.enroll,
				[action.payload.courseOrSyllabusID ?? '']: {
					...state.enroll[action.payload.courseOrSyllabusID ?? ''],
					isLoading: false,
					errorMessage: '',
				},
			},
		};
	})
	.handleAction(actions.enrollLmsCoursesSyllabusesAsync.failure, (state, action) => ({
		...state,
		enroll: {
			...state.enroll,
			[action.payload.courseOrSyllabusID ?? '']: {
				...state.enroll[action.payload.courseOrSyllabusID ?? ''],
				isLoading: false,
				errorMessage: action.payload.errorMessage,
			},
		},
	}))
	.handleAction(actions.fetchLmsMyCoursesSyllabusesAsync.request, (state) => ({
		...state,
		fetchMyCoursesSyllabuses: {
			...state.fetchMyCoursesSyllabuses,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsMyCoursesSyllabusesAsync.success, (state, action) => {
		const query = _.omit(action.payload, ['docs']);

		return {
			...state,
			fetchMyCoursesSyllabuses: {
				...state.fetchMyCoursesSyllabuses,
				isLoading: false,
				data: action.payload.docs,
				query,
			},
		};
	})
	.handleAction(actions.fetchLmsMyCoursesSyllabusesAsync.failure, (state, action) => ({
		...state,
		fetchMyCoursesSyllabuses: {
			...state.fetchMyCoursesSyllabuses,
			isLoading: false,
			errorMessage: action.payload,
		},
	}))
	.handleAction(actions.setLmsMyCoursesSyllabusesSearch, (state, action) => ({
		...state,
		fetchMyCoursesSyllabuses: {
			...state.fetchMyCoursesSyllabuses,
			isLoading: true,
			query: { page: 1 },
			search: action.payload,
		},
	}))

	.handleAction(actions.fetchLmsIndicatorAsync.request, (state) => ({
		...state,
		indicator: {
			...state.indicator,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsIndicatorAsync.success, (state, action) => {
		return {
			...state,
			indicator: {
				...state.indicator,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsIndicatorAsync.failure, (state, action) => ({
		...state,
		indicator: {
			...state.indicator,
			isLoading: false,
		},
	}))
	.handleAction(types.SET_MY_COURSES_AND_SYLLABUSES_TAB as any, (state, action) => {
		return {
			...state,
			currentTab: action.payload.tab,
		};
	})
	.handleAction(types.SET_LMS_LEARNER_COURSEID as any, (state, action) => ({
		...state,
		courseID: action.payload.courseID || '',
	}));
