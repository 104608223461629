import {
  OverviewCompletionCards,
  FetchCompletionCardsPayload
} from 'reducers/dashboard/overview/cardCompletion/actionType';

import orgReportsSubmitted from '../../../assets/dummy/json/org-number.json';
import orgReportsDuration from '../../../assets/dummy/json/org-time.json';
import { getDummyDailyChart } from './dummyDailyChart';

export const dummyOrgNumbers = orgReportsSubmitted;
export const dummyOrgTimes = orgReportsDuration;

export const getDummyCompletionCards = (payload: FetchCompletionCardsPayload) => {
  const dummyDailyStat = getDummyDailyChart(payload);

  const reducedDummyDailyStat = dummyDailyStat.reduce((curr, acc) => ({
    adhocDone: curr.adhocDone + acc.adhocDone,
    adhocDuration: curr.adhocDuration + acc.adhocDuration,
    completedNum: curr.completedNum + acc.completedNum,
    date: '',
    duration: curr.duration + acc.duration,
    scheduledNum: curr.scheduledNum + acc.scheduledNum,
    pendingNum: 2,
    missedNum: 2
  }));

  const dummyCompletionCards: OverviewCompletionCards = {
    adhocCompleted: reducedDummyDailyStat.adhocDone,
    currentCompleted: reducedDummyDailyStat.completedNum,
    currentScheduled: reducedDummyDailyStat.completedNum * 1.5,
    previousCompleted: reducedDummyDailyStat.completedNum * 0.75,
    previousScheduled: reducedDummyDailyStat.completedNum * 1.5 * 0.5,
    growthRate: 0.333,
    isGrowthValid: reducedDummyDailyStat.completedNum !== 0,
    currentMissed: 0,
    previousPending: 0,
    previousMissed: 0,
    currentPending: 0
  };

  return dummyCompletionCards;
};
