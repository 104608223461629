import {
  LOGIN_MasterPasswordPayload,
  LOGIN_MasterPasswordResponse,
  ErrorResponse,
  VERIFY_AuthCodePayload,
  VERIFY_AuthCodeResponse
} from 'reducers/superadmin/fetch-types/clientLogin-fetch.types';

export type ClientTokenInfo = VERIFY_AuthCodeResponse['data'];
export type LoginAsClientState = {
  loading: boolean;
  status: 'SUCCESS' | 'FAILED';
  error: string;
};
export type VerifyAuthCodeState = {
  loading: boolean;
  tokenInfo: ClientTokenInfo;
  error: string;
};

export interface LoginAccessState {
  readonly loginAsClient: LoginAsClientState;
  readonly verifyAuthCode: VerifyAuthCodeState;
}

export const LOGIN_AS_CLIENT_REQUEST = '@@superadmin/clientLogin/createMasterPassword/LOGIN_AS_CLIENT_REQUEST';
export const LOGIN_AS_CLIENT_SUCCESS = '@@superadmin/clientLogin/createMasterPassword/LOGIN_AS_CLIENT_SUCCESS';
export const LOGIN_AS_CLIENT_FAILURE = '@@superadmin/clientLogin/createMasterPassword/LOGIN_AS_CLIENT_FAILURE';

export type LoginAsClientPayload = LOGIN_MasterPasswordPayload;
export type LoginAsClientResponse = LOGIN_MasterPasswordResponse | ErrorResponse;

export const CLEAR_LOGIN_AS_CLIENT_STATE = '@@superadmin/clientLogin/createMasterPassword/CLEAR_LOGIN_AS_CLIENT_STATE';

export const VERIFY_AUTH_CODE_REQUEST = '@@superadmin/clientLogin/createMasterPassword/VERIFY_AUTH_CODE_REQUEST';
export const VERIFY_AUTH_CODE_SUCCESS = '@@superadmin/clientLogin/createMasterPassword/VERIFY_AUTH_CODE_SUCCESS';
export const VERIFY_AUTH_CODE_FAILURE = '@@superadmin/clientLogin/createMasterPassword/VERIFY_AUTH_CODE_FAILURE';

export type VerifyAuthCodePayload = VERIFY_AuthCodePayload;
export type VerifyAuthCodeResponse = VERIFY_AuthCodeResponse | ErrorResponse;

export const CLEAR_VERIFY_AUTH_CODE_STATE =
  '@@superadmin/clientLogin/createMasterPassword/CLEAR_VERIFY_AUTH_CODE_STATE';
