export const FETCH_MISSED_REPORTS_LIST_REQUEST = 'downloadManager/FETCH_MISSED_REPORTS_LIST_REQUEST';
export const FETCH_MISSED_REPORTS_LIST_SUCCESS = 'downloadManager/FETCH_MISSED_REPORTS_LIST_SUCCESS';
export const FETCH_MISSED_REPORTS_LIST_FAILURE = 'downloadManager/FETCH_MISSED_REPORTS_LIST_FAILURE';

export const COUNT_MISSED_REPORTS_REQUEST = 'downloadManager/COUNT_MISSED_REPORTS_REQUEST';
export const COUNT_MISSED_REPORTS_SUCCESS = 'downloadManager/COUNT_MISSED_REPORTS_SUCCESS';
export const COUNT_MISSED_REPORTS_FAILURE = 'downloadManager/COUNT_MISSED_REPORTS_FAILURE';

export const GENERATE_MISSED_REPORTS_REQUEST = 'downloadManager/GENERATE_MISSED_REPORTS_REQUEST';
export const GENERATE_MISSED_REPORTS_SUCCESS = 'downloadManager/GENERATE_MISSED_REPORTS_SUCCESS';
export const GENERATE_MISSED_REPORTS_FAILURE = 'downloadManager/GENERATE_MISSED_REPORTS_FAILURE';
