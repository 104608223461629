import { CustomerFeedbackCampaign, CustomerFeedbackTrends } from 'nimbly-common';
import { UpsertedQuestionnaireID } from 'reducers/admin/manage/questionnaires/types';
import { QuestionnaireCustomerFeedbackRequest } from 'reducers/questionnaireDetail/types';
import { createAsyncAction } from 'typesafe-actions';
import * as types from './customerFeedback.actionTypes';
import { CustomerFeedbackReportRequest } from './type';

export const fetchCampaignList = createAsyncAction(
	types.FETCH_CAMPAIGN_LIST_REQUEST,
	types.FETCH_CAMPAIGN_LIST_SUCCESS,
	types.FETCH_CAMPAIGN_LIST_FAIL,
)<undefined, { data: CustomerFeedbackCampaign[] }, { error: string }>();

export const fetchCampaignReport = createAsyncAction(
	types.FETCH_CAMPAIGN_REPORT_REQUEST,
	types.FETCH_CAMPAIGN_REPORT_SUCCESS,
	types.FETCH_CAMPAIGN_REPORT_FAIL,
)<CustomerFeedbackReportRequest, { data: CustomerFeedbackTrends; message: string }, { message: string }>();

export const postQuestionnaires = createAsyncAction(
	types.POST_QUESTIONNAIRE_REQUEST,
	types.POST_QUESTIONNAIRE_SUCCESS,
	types.POST_QUESTIONNAIRE_FAIL,
)<QuestionnaireCustomerFeedbackRequest, UpsertedQuestionnaireID, string>();

export const updateQuestionnaires = createAsyncAction(
	types.PUT_QUESTIONNAIRE_REQUEST,
	types.PUT_QUESTIONNAIRE_SUCCESS,
	types.PUT_QUESTIONNAIRE_FAIL,
)<QuestionnaireCustomerFeedbackRequest, UpsertedQuestionnaireID, string>();
export const setCampaignStatus = createAsyncAction(
	types.SET_CAMPAIGN_STATUS_REQUEST,
	types.SET_CAMPAIGN_STATUS_SUCCESS,
	types.SET_CAMPAIGN_STATUS_FAIL,
)<
	{ status: boolean; questionnaireID: string },
	{ data: { status: boolean; questionnaireID: string }; message: string },
	{ error: string }
>();

export const getCampaignTemplate = createAsyncAction(
	types.GET_CAMPAIGN_TEMPLATE_REQUEST,
	types.GET_CAMPAIGN_TEMPLATE_SUCCESS,
	types.GET_CAMPAIGN_TEMPLATE_FAILED,
)<undefined, { data: string }, { error: string }>();
