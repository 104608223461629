import { FetchAuditorsListPayload, FetchAuditorsListResponse } from 'reducers/dashboard/auditors/auditorsList/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';

import { getDummyAuditorsList } from 'utils/dashboardDummy/auditors/dummyAuditorsList';

export const fetchAuditorsList = async (payload: FetchAuditorsListPayload): Promise<FetchAuditorsListResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyAuditorsList = getDummyAuditorsList(payload);
    return {
      message: 'SUCCESS',
      data: dummyAuditorsList
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/users/rank?${serializedQueries}`;
    const res = await fetch(url, options);

    const auditorsListResult: FetchAuditorsListResponse = await res.json();
    return auditorsListResult;
  } catch {
    const errorResult: FetchAuditorsListResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
