export function extractSearchParams(searchString) {
  const intermediate = searchString.slice(1).split('&');
  const search = {};
  intermediate.forEach(el => {
    const split = el.split('=');
    search[split[0]] = split[1];
  });
  return search;
}

export function compileSearchString(search) {
  if (typeof search === 'object') {
    let searchString = '?';
    for (const key in search) {
      if (search.hasOwnProperty(key) && search[key] != null) {
        if (searchString !== '?') {
          searchString += '&';
        }
        searchString += key;
        searchString += '=';
        searchString += search[key];
      }
    }
    return searchString;
  }
  return '';
}

export function queryStringify(obj, prefix, withoutArray) {
  let pairs = [];
  for (let key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }

    let value = obj[key];
    let enkey = encodeURIComponent(key);
    let pair;
    if (typeof value === 'object') {
      pair = queryStringify(value, prefix ? (withoutArray ? prefix : prefix + '[' + enkey + ']') : enkey, withoutArray);
    } else {
      pair = (prefix ? (withoutArray ? prefix : prefix + '[' + enkey + ']') : enkey) + '=' + encodeURIComponent(value);
    }
    pairs.push(pair);
  }
  return pairs.join('&');
}
