import { ActionType, createReducer } from 'typesafe-actions';

import { DashboardPeriodEnum, DataTrendsEnum, GroupByEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';

import * as actions from './operational.action';
import { OperationalReducerState } from './operationalStore';
import {
	DEFAULT_OPERATIONAL_PAGE_GLOBAL_FILTERS,
	LAST_MONTH_OPERATIONAL_PAGE_GLOBAL_FILTERS,
} from 'pages/dashboardRevamp/dashboardUtils';

export type OperationalAction = ActionType<typeof actions>;

export const initialState: OperationalReducerState = {
	isReportDetailLoading: false,
	isIssueDetailLoading: false,
	isIssuesListLoading: false,
	isReportsListLoading: false,
	isIssueChartLoading: false,
	isReportChartLoading: false,
	errorMessage: '',
	metric: 'report-overview',
	limit: 5,
	/** Filter query information */
	filters: DEFAULT_OPERATIONAL_PAGE_GLOBAL_FILTERS,
	lastMonthFilters: LAST_MONTH_OPERATIONAL_PAGE_GLOBAL_FILTERS,
	groupBy: GroupByEnum.SITE,
	useTrends: false,
	period: DashboardPeriodEnum.QUARTERLY,
	sortBy: 'createdAt',
	sortDirection: 'desc',
	viewBy: 'site',
	reportRCRDetailData: {
		rcr: 0,
		trend: DataTrendsEnum.STABLE,
		dataPoints: [],
	},
	completeReportDetailData: {
		missedPercentage: 0,
		missed: 0,
		pending: 0,
		completed: 0,
		adhoc: 0,
		scheduled: 0,
		rcr: 0,
		total: 0,
	},
	issueOverviewDetailData: {
		irr: 0,
		resolvedIssues: 0,
		openIssuesCount: 0,
		openIssuesPercentage: 0,
		totalIssues: 0,
		issueOpenLastWeekPercentage: 0,
		avgIssueResolutionTime: '',
	},
	issueOverviewLastMonthDetailData: {
		irr: 0,
		resolvedIssues: 0,
		openIssuesCount: 0,
		openIssuesPercentage: 0,
		totalIssues: 0,
		issueOpenLastWeekPercentage: 0,
		avgIssueResolutionTime: '',
	},
	issueChartDataList: [],
	reportChartDataList: [],
	topChartDataList: { labels: [], data: [] },
	topChartIsLoading: false,
	trendsChartIsLoading: false,
	trendsChartDataIssueList: {
		labels: [],
		resolvedIssuesList: [],
		openedIssuesList: [],
		inProgressIssuesList: [],
		inReviewIssuesList: [],
		blockedIssuesList: [],
		totalIssuesList: [],
	},
	trendsChartDataReportList: {
		labels: [],
		completedReportList: [],
		incompleteReportList: [],
		totalReportList: [],
	},
	reportDownloadIsLoading: false,
	reportDownloadData: [],
	reportDownloadGroupBy: GroupByEnum.SITE,
	reportDownloadFieldNames: [],

	reportDownloadTrendsData: [],
	reportDownloadTrendsFieldNames: [],
};
export const operationalReducer = createReducer<OperationalReducerState, OperationalAction>(initialState)
	.handleAction(actions.setGroupBy, (state, action) => ({
		...state,
		groupBy: action.payload.groupBy,
	}))
	.handleAction(actions.setPeriod, (state, action) => ({
		...state,
		period: action.payload.period,
	}))
	.handleAction(actions.setTopChartIsLoading, (state, action) => ({
		...state,
		topChartIsLoading: action.payload.isLoading,
	}))
	.handleAction(actions.setTopChartData, (state, action) => {
		const { labels, data } = action.payload;

		return {
			...state,
			topChartDataList: { labels, data },
		};
	})
	.handleAction(actions.setTrendsChartIsLoading, (state, action) => ({
		...state,
		trendsChartIsLoading: action.payload.isLoading,
	}))
	.handleAction(actions.setTrendsChartIssueData, (state, action) => {
		const {
			labels,
			resolvedIssuesList,
			openedIssuesList,
			totalIssuesList,
			inProgressIssuesList,
			inReviewIssuesList,
			blockedIssuesList,
		} = action.payload;

		return {
			...state,
			trendsChartDataIssueList: {
				labels,
				resolvedIssuesList,
				openedIssuesList,
				totalIssuesList,
				inProgressIssuesList,
				inReviewIssuesList,
				blockedIssuesList,
			},
		};
	})
	.handleAction(actions.setTrendsChartReportData, (state, action) => {
		const { labels, completedReportList, incompleteReportList, totalReportList } = action.payload;

		return {
			...state,
			trendsChartDataReportList: { labels, completedReportList, incompleteReportList, totalReportList },
		};
	})
	.handleAction(actions.setReportDownloadViewIsLoading, (state, action) => ({
		...state,
		reportDownloadIsLoading: action.payload.isLoading,
	}))
	.handleAction(actions.setReportDownloadViewData, (state, action) => ({
		...state,
		reportDownloadData: action.payload.data,
	}))
	.handleAction(actions.setReportDownloadViewTrendsData, (state, action) => ({
		...state,
		reportDownloadTrendsData: action.payload.data,
	}))
	.handleAction(actions.setReportDownloadViewTrendsFieldNames, (state, action) => ({
		...state,
		reportDownloadTrendsFieldNames: action.payload.fieldNames,
	}))
	.handleAction(actions.setFilters, (state, action) => ({
		...state,
		filters: action.payload.filters,
	}))
	.handleAction(actions.fetchCompleteReportDetailAsync.request, (state) => ({
		...state,
		metric: 'report-overview',
		isReportDetailLoading: true,
	}))
	.handleAction(actions.fetchCompleteReportDetailAsync.success, (state, action) => {
		return {
			...state,
			completeReportDetailData: action.payload,
			isReportDetailLoading: false,
		};
	})
	.handleAction(actions.fetchCompleteReportDetailAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isReportDetailLoading: false,
	}))
	.handleAction(actions.fetchIssueOverviewDetailAsync.request, (state) => ({
		...state,
		metric: 'issue-overview',
		groupBy: 'issue',
		isIssueDetailLoading: true,
	}))
	.handleAction(actions.fetchIssueOverviewDetailAsync.success, (state, action) => {
		return {
			...state,
			issueOverviewDetailData: action.payload,
			isIssueDetailLoading: false,
		};
	})
	.handleAction(actions.fetchIssueOverviewDetailAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueDetailLoading: false,
	}))
	.handleAction(actions.fetchLastMonthIssueOverviewDetailAsync.request, (state) => ({
		...state,
		metric: 'issue-overview',
		groupBy: 'issue',
		isIssueDetailLoading: true,
	}))
	.handleAction(actions.fetchLastMonthIssueOverviewDetailAsync.success, (state, action) => {
		return {
			...state,
			issueOverviewLastMonthDetailData: action.payload,
			isIssueDetailLoading: false,
		};
	})
	.handleAction(actions.fetchLastMonthIssueOverviewDetailAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueDetailLoading: false,
	}))
	.handleAction(actions.fetchIssueChartAsync.request, (state) => ({
		...state,
		metric: 'issueChart',
		groupBy: 'date',
		sortDirection: 'asc',
		isIssueChartLoading: true,
	}))
	.handleAction(actions.fetchIssueChartAsync.success, (state, action) => {
		return {
			...state,
			issueChartDataList: action.payload,
			isIssueChartLoading: false,
		};
	})
	.handleAction(actions.fetchIssueChartAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueChartLoading: false,
	}))
	.handleAction(actions.fetchReportChartAsync.request, (state) => ({
		...state,
		metric: 'reportChart',
		groupBy: 'date',
		sortDirection: 'asc',
		isReportChartLoading: true,
	}))
	.handleAction(actions.fetchReportChartAsync.success, (state, action) => {
		return {
			...state,
			reportChartDataList: action.payload,
			isReportChartLoading: false,
		};
	})
	.handleAction(actions.fetchReportChartAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isReportChartLoading: false,
	}));
