import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './competitorExport.action';
import * as types from './competitorExport.actionTypes';
import { CompetitorExportState } from './type';

export type competitorExportAction = ActionType<typeof actions>;

const initialState: CompetitorExportState = {
  exportList: [],
  downloadActList: [],
  isLoading: false,
  error: null,
  downloadOn: 'asc',
  type: 'asc',
  numberOfProductOrPromotion: 'asc',
  start: 'asc',
  sortBy: 'downloadOn'
};

export const exportReducer = createReducer<CompetitorExportState, competitorExportAction>(initialState)
  .handleAction(actions.fetchExportList.request, state => ({
    ...state,
    exportList: [],
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchExportList.success, (state, action) => ({
    ...state,
    exportList: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchDownloadAct.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.fetchDownloadAct.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchDownloadAct.success, (state, action) => ({
    ...state,
    downloadActList: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchExportList.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.createDownloadAct.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.createDownloadAct.success, (state, action) => ({
    ...state,
    isLoading: false,
    downloadActList: [action.payload.data, ...state.downloadActList]
  }))
  .handleAction(actions.createDownloadAct.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(types.SET_LOADING as any, (state, action) => {
    return { ...state, isLoading: action.payload.value };
  })
  .handleAction(types.SET_SORT as any, (state, action) => {
    if (state.sortBy !== action.payload.sortType) {
      return {
        ...state,
        sortBy: action.payload.sortType,
        downloadOn: 'asc',
        type: 'asc',
        numberOfProductOrPromotion: 'asc',
        date: 'asc'
      };
    }
    return {
      ...state,
      [action.payload.sortType]: action.payload.sortOrder
    };
  });

export default exportReducer;
