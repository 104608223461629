import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  FetchRequestDemoListPayload,
  FETCH_REQUEST_DEMO_LIST_REQUEST,
  FETCH_REQUEST_DEMO_LIST_SUCCESS,
  FETCH_REQUEST_DEMO_LIST_FAILURE,
  RequestDemoList,
  ExportRequestDemoListPayload,
  EXPORT_REQUEST_DEMO_LIST_REQUEST,
  EXPORT_REQUEST_DEMO_LIST_SUCCESS,
  EXPORT_REQUEST_DEMO_LIST_FAILURE,
  RequestDemoListExport,
  SORT_REQUEST_DEMO_LIST,
  SortRequestDemoListPayload
} from './types';

export const fetchRequestForDemoListAsync = createAsyncAction(
  FETCH_REQUEST_DEMO_LIST_REQUEST,
  FETCH_REQUEST_DEMO_LIST_SUCCESS,
  FETCH_REQUEST_DEMO_LIST_FAILURE
)<FetchRequestDemoListPayload, RequestDemoList, string>();

export const exportRequestForDemoListAsync = createAsyncAction(
  EXPORT_REQUEST_DEMO_LIST_REQUEST,
  EXPORT_REQUEST_DEMO_LIST_SUCCESS,
  EXPORT_REQUEST_DEMO_LIST_FAILURE
)<ExportRequestDemoListPayload, RequestDemoListExport, string>();

export const sortRequestDemoList = createAction(SORT_REQUEST_DEMO_LIST)<SortRequestDemoListPayload>();
