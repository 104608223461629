import { createAsyncAction, action } from 'typesafe-actions';

import * as types from './competitorExport.actionTypes';

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });
export const setSort = (sortType: string, sortOrder: string) => action(types.SET_SORT, { sortType, sortOrder });

export const fetchExportList = createAsyncAction(
  types.FETCH_EXPORT_REQUEST,
  types.FETCH_EXPORT_SUCCESS,
  types.FETCH_EXPORT_FAILURE
)<any, { data: any | null }, { error: string }>();

export const createDownloadAct = createAsyncAction(
  types.CREATE_DOWNLOAD_ACT_REQUEST,
  types.CREATE_DOWNLOAD_ACT_SUCCESS,
  types.CREATE_DOWNLOAD_ACT_FAILURE
)<any, any, { error: string }>();

export const fetchDownloadAct = createAsyncAction(
  types.FETCH_DOWNLOAD_ACT_REQUEST,
  types.FETCH_DOWNLOAD_ACT_SUCCESS,
  types.FETCH_DOWNLOAD_ACT_FAILURE
)<undefined, { data: any | null }, { error: string }>();
