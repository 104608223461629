import React from 'react';
import { SitePropsFlashMessage } from './Auth';
import styled from 'styled-components';
import RedNotif from '../../../assets/icon/red-notif.svg';

export interface AuthFlashMessageProps {
	siteFlashMessage: SitePropsFlashMessage;
	validationMessage: string;
}

export const AuthFlashMessage = (props: AuthFlashMessageProps) => {
	return (
		<Container>
			{props.siteFlashMessage.successMessage ? (
				<div className="success-message">
					<span className="fade-in" data-testid="success-message" id="message-success-login">
						{props.siteFlashMessage.successMessage}
					</span>
				</div>
			) : props.siteFlashMessage.errorMessage || props.validationMessage ? (
				<MessageContainer>
					<IconContainer src={RedNotif} alt="red-notif" />
					<span className="fade-in" data-testid="error-message" id="message-error-login">
						{props.siteFlashMessage.errorMessage || props.validationMessage}
					</span>
				</MessageContainer>
			) : (
				<div className="error-message fade-in" id="message-error-fadein-login">
					<span>&nbsp; </span>
				</div>
			)}
		</Container>
	);
};

export default AuthFlashMessage;

const Container = styled.div`
	width: 297px;
`;

const MessageContainer = styled.div`
	display: grid;
	grid-template-columns: 10% 90%;
	font-size: 13px;
	padding: 8px 0;

	& > span {
		color: #fc4c5e;
	}
`;

const IconContainer = styled.img`
	height: 16px;
	width: 16px;
`;
