import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SidebarLockedState } from 'reducers/sidebar';
import { LMS_ROUTE } from 'routes/LmsRoutes';
import { RootState } from 'store/rootReducers';
import { version } from '../../../../package.json';
import ChevronLightIcon from '../../../assets/icon/chevron-light.svg';
import AdminIcon from '../../../assets/icon/sidebar/admin-icon.svg';
import DashboardIcon from '../../../assets/icon/sidebar/dashboard-icon.svg';
import GalleryIcon from '../../../assets/icon/sidebar/gallery-icon.svg';
import RepositoryIcon from '../../../assets/icon/sidebar/repository-icon.svg';
import HelpIcon from '../../../assets/icon/sidebar/help-icon.svg';
import IssueTrackerIcon from '../../../assets/icon/sidebar/issue-tracker-icon.svg';
import LogoutIcon from '../../../assets/icon/sidebar/logout-icon.svg';
import NimblyIconMd from '../../../assets/icon/sidebar/nimbly-icon-md.svg';
import NimblyIcon from '../../../assets/icon/sidebar/nimbly-icon.svg';
import SettingsIcon from '../../../assets/icon/sidebar/settings-icon.svg';
import SuperAdminIcon from '../../../assets/icon/sidebar/superadmin-icon.svg';
import BackIcon from '../../../assets/icon/sidebar/white-arrow-back-icon.svg';
import Report from '../../../assets/icon/sidebar/report-icon.svg';
import { FeatureAccessState } from '../../../reducers/featureAccess/type';
import { OrganizationsState } from '../../../reducers/organizations/organizations.types';
import MainMenuItem from './components/MainMenuItem';
import { LANG_OPTIONS, LangOptions } from './constants';
import { useLocation } from 'react-router';
import {
	CollapseButton,
	CollapseButtonTooltip,
	Container,
	Footer,
	Header,
	LanguageItem,
	LanguageSelector,
	MenuContainer,
	MenuItem,
	Version,
} from './styles/MainSidebar.styles';

// eslint-disable-next-line complexity
function PrimarySidebar(props: OwnProps) {
	const {
		isSubSidebarCollapsed,
		onChangeCollapsedState,
		subSidebarLockedState,
		onChangeLockedState,
		onLogout,
		onLangChange,
		shouldRenderCollapseButton,
		isSuperadmin,
	} = props;
	const [isLangSelectorVisible, setLangSelectorVisible] = useState(false);
	const isSandboxAccount = useSelector((state: RootState) => state.jwtauth.isSandboxAccount);
	const organizationState = useSelector<RootState, OrganizationsState>((state: RootState) => state.organization);
	const featureAccessState = useSelector<RootState, FeatureAccessState>((state: RootState) => state.featureAccess);
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const isQuizEnabled = useSelector((state: RootState) => state.featureAccess.features.LMS_QUIZ);

	const loggedInUserOrgID = profile.organization!;
	const location = useLocation();

	// Is users is from internal organization
	const csOrgList = ['cs'];
	const isCSOrg = csOrgList.includes(loggedInUserOrgID);
	const { t } = useTranslation();
	const organizationHasLms = featureAccessState.features.LMS;

	const adminAccess = useSelector((state: RootState) => state.userAccess.admin.sites.all.permissions.edit);
	const reportsHubAdminAccess = useSelector(
		(state: RootState) => state.userAccess.admin['download-manager'].all.permissions.view,
	);
	const galleryAccess = adminAccess && featureAccessState.features.ATTACHMENT_GALLERY;
	const repositoryAccess = adminAccess && featureAccessState.features.FILE_REPOSITORY;
	const dashboardAccess = !isQuizEnabled || (adminAccess && featureAccessState.features.ADVANCED_ANALYTICS);
	const issueTrackerAccess = !isQuizEnabled || (adminAccess && featureAccessState.features.ISSUE_TRACKER);

	const isLmsStandalone = organizationState?.organization?.lmsType === 'standalone';

	const handleChangeCollapsedState = () => {
		if (!isSubSidebarCollapsed) {
			onChangeLockedState('locked');
			onChangeCollapsedState(true);
		} else {
			onChangeLockedState('unlocked');
			setTimeout(() => onChangeCollapsedState(false), 25);
		}
	};

	const handleLangChange = useCallback((lang: LangOptions) => {
		onLangChange(lang);
	}, []);

	useEffect(() => {
		if (!shouldRenderCollapseButton) {
			if (subSidebarLockedState === 'locked') {
				onChangeLockedState('temp-unlocked');
				// WAIT FOR LOCKED STATE TO CHANGE
				setTimeout(() => onChangeCollapsedState(false), 25);
			} else {
				onChangeCollapsedState(false);
			}
		}
	}, [shouldRenderCollapseButton]);

	return (
		<Container>
			<Header>
				<img src={NimblyIcon} alt="nimbly-icon" />
			</Header>

			<CollapseButton
				isCollapsed={isSubSidebarCollapsed}
				onClick={handleChangeCollapsedState}
				isVisible={shouldRenderCollapseButton}
				id="burger_bar"
			>
				<img className="back-arrow" src={BackIcon} alt="back-arrow-icon" />

				<CollapseButtonTooltip>
					<span>{t('label.sidebar.lockedTooltip')}</span>
				</CollapseButtonTooltip>
			</CollapseButton>

			<MenuContainer>
				{dashboardAccess ? (
					<MainMenuItem
						path="/analytics/executive"
						icon={DashboardIcon}
						label={t('nav.dashboard')}
						id="menu_dsbrd"
						isDisabled={organizationHasLms && isLmsStandalone}
					/>
				) : null}
				{reportsHubAdminAccess ? (
					<MainMenuItem
						path="/reportsHub"
						icon={Report}
						label={t('nav.reportsHub')}
						id="menu_reports"
						match={location.pathname === '/reportsHub'}
					/>
				) : null}
				{issueTrackerAccess ? (
					<MainMenuItem
						path="/issues"
						icon={IssueTrackerIcon}
						label={t('nav.issuetracker')}
						id="menu_issue_tracker"
						isDisabled={organizationHasLms && isLmsStandalone}
					/>
				) : null}
				{galleryAccess ? (
					<MainMenuItem path="/gallery" icon={GalleryIcon} label={t('nav.gallery')} id="menu_gallery" />
				) : null}
				{/* TODO: Add role and access based url */}
				<MainMenuItem
					path={!isQuizEnabled || !isMobile ? LMS_ROUTE.DASHBOARD : LMS_ROUTE.COURSE_AND_SYLLABUS_CATALOG}
					icon={NimblyIconMd}
					label={t('nav.nimblyLearn')}
					id="menu_nimbly_learn"
					isDisabled={!organizationHasLms}
				/>
				{repositoryAccess ? (
					<MainMenuItem path="/repository" icon={RepositoryIcon} label={t('nav.repository')} id="menu_repository" />
				) : null}
				{adminAccess ? (
					<MainMenuItem
						path="/admin/sites"
						icon={AdminIcon}
						label={t('nav.manage')}
						id="menu_adm"
						isDisabled={organizationHasLms && isLmsStandalone}
					/>
				) : null}

				{adminAccess ? (
					<MainMenuItem
						path="/settings/general"
						icon={SettingsIcon}
						label={t('nav.settings')}
						id="menu_settings"
						match={location.pathname === '/admin'}
						isDisabled={organizationHasLms && isLmsStandalone}
					/>
				) : null}
				{isCSOrg && (
					<MainMenuItem path="/customerSuccess/deleteReports" icon={HelpIcon} label="CS-Utility" id="cs-utility" />
				)}
			</MenuContainer>

			<Footer>
				<LanguageItem onClick={() => setLangSelectorVisible(!isLangSelectorVisible)} id="menu_language">
					<span>{t('locale')}</span>
					<img src={ChevronLightIcon} alt="language-select" />

					<LanguageSelector isVisible={isLangSelectorVisible}>
						{LANG_OPTIONS.map(({ lang, label }) => (
							<div key={`lang-opt-${lang}`} onClick={() => handleLangChange(lang)}>
								{label}
							</div>
						))}
					</LanguageSelector>
				</LanguageItem>

				{!isSandboxAccount && <MainMenuItem path="/help" icon={HelpIcon} id="menu_help" />}

				<MenuItem onClick={onLogout} id="menu_logout">
					<img src={LogoutIcon} alt="logout-icon" />
				</MenuItem>
				<Version>
					<span>ver {version}</span>
				</Version>
			</Footer>
		</Container>
	);
}

interface OwnProps {
	isSubSidebarCollapsed: boolean;
	onChangeCollapsedState: (state: boolean) => void;
	subSidebarLockedState: SidebarLockedState;
	onChangeLockedState: (state: SidebarLockedState) => void;
	onLogout: () => void;
	onLangChange: (lang: LangOptions) => void;
	shouldRenderCollapseButton: boolean;
	isSuperadmin: boolean;
}

export default PrimarySidebar;
