export const SET_COURSES_PAGE_INDEX = '@lms/SET_COURSES_PAGE_INDEX';
export const SET_COURSES_SORT = '@lms/SET_COURSES_SORT_FIELD';
export const SET_COURSES_SEARCH_QUERY = '@lms/SET_COURSES_SEARCH_QUERY';
export const SET_COURSES_FILTER_QUERY = '@lms/SET_COURSES_FILTER_QUERY';
export const RESET_COURSES_FILTER_QUERY = '@lms/RESET_COURSES_FILTER_QUERY';

export const FETCH_COURSES_REQUEST = '@lms/FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = '@lms/FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILED = '@lms/FETCH_COURSES_FAILED';

export const EDIT_COURSES_REQUEST = '@lms/EDIT_COURSES_REQUEST';
export const EDIT_COURSES_SUCCESS = '@lms/EDIT_COURSES_SUCCESS';
export const EDIT_COURSES_FAILED = '@lms/EDIT_COURSES_FAILED';

export const EDIT_COURSE_REQUEST = '@lms/EDIT_COURSE_REQUEST';
export const EDIT_COURSE_SUCCESS = '@lms/EDIT_COURSE_SUCCESS';
export const EDIT_COURSE_FAILED = '@lms/EDIT_COURSE_FAILED';
