import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './customerFeedback.action';

export type CustomerFeedbackStatusAction = ActionType<typeof actions>;

interface CustomerFeedbackStatusState {
  data: {
    status: boolean;
    questionnaireID: string;
  };
  message: string;
  isLoadingStatus: boolean;
  status: string;
}

const initialState: CustomerFeedbackStatusState = {
  data: {
    status: false,
    questionnaireID: ''
  },
  message: '',
  isLoadingStatus: false,
  status: 'init'
};

export const customerFeedbackStatusReducer = createReducer<CustomerFeedbackStatusState, CustomerFeedbackStatusAction>(
  initialState
)
  .handleAction(actions.setCampaignStatus.request, (state, action) => ({
    ...state,
    isLoadingStatus: true,
    status: 'req'
  }))
  .handleAction(actions.setCampaignStatus.success, (state, action) => ({
    ...state,
    isLoadingStatus: false,
    message: action.payload.message,
    data: action.payload.data,
    status: 'success'
  }))
  .handleAction(actions.setCampaignStatus.failure, (state, action) => ({
    ...state,
    isLoadingStatus: false,
    message: action.payload.error
  }));
