import {
  FetchIssuesFlagStatPayload,
  FetchIssuesFlagStatResponse
} from 'reducers/dashboard/issues/issuesFlagStat/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyIssuesFlagStat } from 'utils/dashboardDummy/issues/dummyIssuesFlagStat';

export const fetchIssuesFlagStat = async (
  payload: FetchIssuesFlagStatPayload
): Promise<FetchIssuesFlagStatResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyIssuesFlagStat = getDummyIssuesFlagStat();
    return {
      message: 'SUCCESS',
      data: dummyIssuesFlagStat
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/issue-flags?${serializedQueries}`;

    const res = await fetch(url, options);

    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const issuesFlagStatResult: FetchIssuesFlagStatResponse = await res.json();
    return issuesFlagStatResult;
  } catch {
    const errorResult: FetchIssuesFlagStatResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
