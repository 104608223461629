import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './customerFeedback.action';
import { CustomerFeedbackListState } from './type';

export type CustomerFeedbackAction = ActionType<typeof actions>;

const initialState: CustomerFeedbackListState = {
  campaignList: [],
  status: 'init',
  isLoading: false
};

export const customerFeedbackListReducer = createReducer<CustomerFeedbackListState, CustomerFeedbackAction>(
  initialState
)
  .handleAction(actions.fetchCampaignList.request, (state, action) => ({
    ...state,
    status: 'init',
    isLoading: true
  }))
  .handleAction(actions.fetchCampaignList.success, (state, action) => ({
    ...state,
    campaignList: action.payload.data,
    status: 'success',
    isLoading: false
  }))
  .handleAction(actions.fetchCampaignList.failure, (state, action) => ({
    ...state,
    status: 'failure',
    isLoading: false
  }));
