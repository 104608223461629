import { createReducer } from 'typesafe-actions';
import { SitesState } from './types';
import { SitesAction } from './action';
import { fetchSitesFlagsChartAsync } from './flagsChart/action';
import { fetchRankByFlagsAsync, downloadRankByFlagsAsync } from './rankByFlags/action';
import { fetchIssueListAsync, downloadIssueListAsync } from './issueList/action';
import { fetchRankByCompletionAsync, downloadRankByCompletionAsync } from './rankByCompletion/action';
import { fetchSitesFlagsOverviewAsync } from './flagsOverview/action';

const initialState: SitesState = {
  /**
   * Flag List State
   */
  rankByFlags_loading: false,
  rankByFlags_data: {
    scheduled: [],
    adhoc: []
  },
  rankByFlags_error: '',
  rankByFlags_dl_loading: false,
  rankByFlags_dl_url: '',
  rankByFlags_dl_error: '',
  /**
   * Issue List State
   */
  issueList_loading: false,
  issueList_data: [],
  issueList_error: '',
  issueList_dl_loading: false,
  issueList_dl_url: '',
  issueList_dl_error: '',
  /**
   * Rank By Completion State
   */
  reportList_loading: false,
  reportList_data: [],
  reportList_error: '',
  reportList_dl_loading: false,
  reportList_dl_url: '',
  reportList_dl_error: '',
  /**
   * Sites Daily Chart State
   */
  sitesFlagsChart_loading: false,
  sitesFlagsChart_data: {
    scheduled: [],
    adhoc: []
  },
  sitesFlagsChart_error: '',
  /**
   * Sites Flags Overview State
   */
  flagsOverview_loading: false,
  flagsOverview_data: {
    scheduled: {
      currentGreen: 0,
      previousGreen: 0,
      currentRed: 0,
      previousRed: 0,
      currentYellow: 0,
      previousYellow: 0,
      currentTotal: 0,
      previousTotal: 0
    },
    adhoc: {
      currentGreen: 0,
      previousGreen: 0,
      currentRed: 0,
      previousRed: 0,
      currentYellow: 0,
      previousYellow: 0,
      currentTotal: 0,
      previousTotal: 0
    }
  },
  flagsOverview_error: ''
};

export const sitesReducer = createReducer<SitesState, SitesAction>(initialState)
  .handleAction(fetchSitesFlagsChartAsync.request, state => ({
    ...state,
    sitesFlagsChart_loading: true
  }))
  .handleAction(fetchSitesFlagsChartAsync.success, (state, action) => ({
    ...state,
    sitesFlagsChart_loading: false,
    sitesFlagsChart_data: action.payload,
    sitesFlagsChart_error: ''
  }))
  .handleAction(fetchSitesFlagsChartAsync.failure, (state, action) => ({
    ...state,
    sitesFlagsChart_loading: false,
    sitesFlagsChart_error: action.payload
  }))
  .handleAction(fetchRankByFlagsAsync.request, state => ({
    ...state,
    rankByFlags_loading: true
  }))
  .handleAction(fetchRankByFlagsAsync.success, (state, action) => ({
    ...state,
    rankByFlags_loading: false,
    rankByFlags_data: action.payload,
    rankByFlags_error: ''
  }))
  .handleAction(fetchRankByFlagsAsync.failure, (state, action) => ({
    ...state,
    rankByFlags_loading: false,
    rankByFlags_error: action.payload
  }))
  .handleAction(fetchIssueListAsync.request, state => ({
    ...state,
    issueList_loading: true
  }))
  .handleAction(fetchIssueListAsync.success, (state, action) => ({
    ...state,
    issueList_loading: false,
    issueList_data: action.payload,
    issueList_error: ''
  }))
  .handleAction(fetchIssueListAsync.failure, (state, action) => ({
    ...state,
    issueList_loading: false,
    issueList_error: action.payload
  }))
  .handleAction(fetchRankByCompletionAsync.request, state => ({
    ...state,
    reportList_loading: true
  }))
  .handleAction(fetchRankByCompletionAsync.success, (state, action) => ({
    ...state,
    reportList_loading: false,
    reportList_data: action.payload,
    reportList_error: ''
  }))
  .handleAction(fetchRankByCompletionAsync.failure, (state, action) => ({
    ...state,
    reportList_loading: false,
    reportList_error: action.payload
  }))
  .handleAction(fetchSitesFlagsOverviewAsync.request, state => ({
    ...state,
    flagsOverview_loading: true
  }))
  .handleAction(fetchSitesFlagsOverviewAsync.success, (state, action) => ({
    ...state,
    flagsOverview_loading: false,
    flagsOverview_data: action.payload,
    flagsOverview_error: ''
  }))
  .handleAction(fetchSitesFlagsOverviewAsync.failure, (state, action) => ({
    ...state,
    flagsOverview_loading: false,
    flagsOverview_error: action.payload
  }))
  .handleAction(downloadRankByCompletionAsync.request, state => ({
    ...state,
    reportList_dl_loading: true
  }))
  .handleAction(downloadRankByCompletionAsync.success, (state, action) => ({
    ...state,
    reportList_dl_loading: false,
    reportList_dl_url: action.payload,
    reportList_dl_error: ''
  }))
  .handleAction(downloadRankByCompletionAsync.failure, (state, action) => ({
    ...state,
    reportList_dl_loading: false,
    reportList_dl_url: '',
    reportList_dl_error: action.payload
  }))
  .handleAction(downloadRankByFlagsAsync.request, state => ({
    ...state,
    rankByFlags_dl_loading: true
  }))
  .handleAction(downloadRankByFlagsAsync.success, (state, action) => ({
    ...state,
    rankByFlags_dl_loading: false,
    rankByFlags_dl_url: action.payload,
    rankByFlags_dl_error: ''
  }))
  .handleAction(downloadRankByFlagsAsync.failure, (state, action) => ({
    ...state,
    rankByFlags_dl_loading: false,
    rankByFlags_dl_url: '',
    rankByFlags_dl_error: action.payload
  }))
  .handleAction(downloadIssueListAsync.request, state => ({
    ...state,
    issueList_dl_loading: true
  }))
  .handleAction(downloadIssueListAsync.success, (state, action) => ({
    ...state,
    issueList_dl_loading: false,
    issueList_dl_url: action.payload,
    issueList_dl_error: ''
  }))
  .handleAction(downloadIssueListAsync.failure, (state, action) => ({
    ...state,
    issueList_dl_loading: false,
    issueList_dl_url: '',
    issueList_dl_error: action.payload
  }));
