import { UPSERT_UserResponse, ErrorResponse } from '../../fetch-types/manage-user-fetch.types';

export type UpsertedSiteID = UPSERT_UserResponse['data'];

export interface UsersState {
  readonly upsertedUser_loading: boolean;
  readonly upsertedUser_userID: string;
  readonly upsertedUser_error: string;
}

export const CREATE_USER_REQUEST = '@@admin/manage/users/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = '@@admin/manage/users/CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = '@@admin/manage/users/CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = '@@admin/manage/users/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = '@@admin/manage/users/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = '@@admin/manage/users/UPDATE_USER_FAILURE';

export type UpsertUserResponse = UPSERT_UserResponse | ErrorResponse;

export const CLEAR_USER_STATE = '@@admin/manage/users/CLEAR_USER_STATE';
