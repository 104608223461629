const prefix = 'poEditor';

export const SET_ALL = `${prefix}/SET_ALL`;
export const SET_PO_NUMBER = `${prefix}/SET_PO_NUMBER`;
export const SET_PO_ITEM = `${prefix}/SET_PO_ITEM`;
export const ADD_PO_ITEM = `${prefix}/ADD_PO_ITEM`;
export const REMOVE_PO_ITEM = `${prefix}/REMOVE_PO_ITEM`;
export const UPDATE_PO_ITEM = `${prefix}/UPDATE_PO_ITEM`;
export const SET_PO_VENDOR = `${prefix}/SET_PO_VENDOR`;
export const SET_PO_SITE = `${prefix}/SET_PO_SITE`;
export const SET_PO_EMAIL_TARGET = `${prefix}/SET_PO_EMAIL_TARGET`;
export const ADD_PO_EMAIL_TARGET = `${prefix}/ADD_PO_EMAIL_TARGET`;
export const SET_PO_NOTE = `${prefix}/SET_PO_NOTE`;
export const SET_PO_ESTIMATED_DELIVERY = `${prefix}/SET_PO_ESTIMATED_DELIVERY`;
export const SET_PO_STATUS = `${prefix}/SET_PO_STATUS`;
export const CLEAR_PO = `${prefix}/CLEAR_PO`;
