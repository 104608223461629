import { FetchQuestionCategoriesResponse } from 'reducers/admin/manage/questionnaires/types';
import { getToken } from 'reducers/api';
import { apiURL } from '../../../../config/baseURL';
import Monitoring from '../../../../utils/monitoring/Monitoring';

export const fetchQuestionCategories = async (): Promise<FetchQuestionCategoriesResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const url = `${apiURL}/questionnaires/questioncategories`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const questionCategoriesResult: FetchQuestionCategoriesResponse = await res.json();

    // filter the question categories whose status is disabled
    if (questionCategoriesResult.message === 'SUCCESS') {
      questionCategoriesResult.data = questionCategoriesResult.data.filter((c: { label: string;
        value: string;
        __isNew__?: boolean;
        _id?: string;
        status?: string;}) => {
          if (c.status && c.status === 'disabled'  ) {
            return false
          }
          return true
        });
    }

    return questionCategoriesResult;
  } catch (e) {
    Monitoring.logEvent('fetchQuestionCategories', e);
    const errorResult: FetchQuestionCategoriesResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
