import { toast } from 'react-toastify';
import { createRepositoryUser, fetchRepository, searchRepository } from 'services/repository/repository.service';
import { RootState } from 'store/rootReducers';
import { call, put, select } from 'typed-redux-saga';
import * as actions from './repository.action';
import * as types from './repository.actionTypes';
import { FileSection, RepositoryQueryOptions, RepositoryState } from './type.d';
import queryString from 'query-string';
import { GetRepositoryItemsResponse, PaginatedRepositoryItems } from '@nimbly-technologies/nimbly-common';
import { takeLatest } from 'redux-saga/effects';
import { initialSearchValues } from './repository.reducer';
import { RepositoryListEnum, RepositorySortByOptionsEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import { cloneDeep } from 'lodash';

const repositorySectionList = [RepositoryListEnum.SHARED_WITH_ME, RepositoryListEnum.RECENT, RepositoryListEnum.TRASH];

function getQueryString(
	{ limit, list, sortBy, sortType, selectedFolder, folderHierarchy, ...state }: RepositoryState,
	queryObject?: Partial<RepositoryQueryOptions>,
) {
	const page = state.files?.length || state.folders?.length || Object.keys(state.fileSections)?.length ? state.page : 1;
	const currentFolder = folderHierarchy[folderHierarchy.length - 1];
	const query: any = {
		// limit,
		page,
		list,
		...queryObject,
		sortBy,
		sortType,
	};

	if (state.searchQuery) {
		query.qSearch = state.searchQuery;
	}

	if (currentFolder) {
		query.parentID = currentFolder?.id;
		query.qSearch = undefined;
		query.list = RepositoryListEnum.FOLDER;
	}

	for (const [key, value] of Object.entries(state.filters)) {
		if (value?.length) {
			query[key as keyof RepositoryQueryOptions] = value;
		}
	}

	return queryString.stringify(query);
}

function getResult(results: PaginatedRepositoryItems, state: RepositoryState, loadMore?: boolean) {
	const isSection = repositorySectionList.includes(state.list);
	const files: GetRepositoryItemsResponse[] = results.docs ?? [];
	const folders: GetRepositoryItemsResponse[] = (results.folders as GetRepositoryItemsResponse[]) ?? [];

	const folderHierarchy = state.folderHierarchy.slice();
	const currentFolder = folderHierarchy.pop();
	const result = {};

	if (currentFolder && !currentFolder.name && state.page === 1) {
		folderHierarchy[state.folderHierarchy.length - 1] = {
			...currentFolder,
			// @ts-ignore
			name: results.folderInfo.name ?? 'Shared Folder',
		};
		// @ts-ignore
		result.folderHierarchy = folderHierarchy;
	}

	if (isSection && state.sortBy !== RepositorySortByOptionsEnum.NAME) {
		const fileSections = (files as unknown) as FileSection[];
		const updatedSections: Record<string, GetRepositoryItemsResponse[]> = loadMore ? cloneDeep(state.fileSections) : {};
		fileSections.forEach((section: FileSection, index) => {
			updatedSections[section.date] = [...new Set([...(updatedSections[section.date] || []), ...section.entities])];
		});

		return { fileSections: updatedSections, files: [], folders: [], ...result };
	}

	if (!loadMore) {
		return { files, folders, fileSections: {}, ...result };
	}

	return { files: [...state.files, ...files], ...result };
}

export function* createRepoUserSaga(loadMore?: boolean) {
	try {
		yield* call(createRepositoryUser);
		yield* put(actions.fetchRepository.request({ loadMore: loadMore }));
	} catch (e) {
		yield put(actions.fetchRepository.failure({ error: 'Failed to fetch attachments' }));
	}
}

export function* fetchRepositorySaga({ payload }: ReturnType<typeof actions.fetchRepository.request>) {
	const repositoryState = (state: RootState) => state.repository;
	const state: RepositoryState = yield select(repositoryState);

	try {
		const queryStr = getQueryString(state);

		const { folders, ...res } = yield* call(fetchRepository, queryStr);
		const result = getResult({ ...res, folders }, state, payload?.loadMore);
		const returnPayload = { ...res, folders: folders as GetRepositoryItemsResponse[], ...result };

		yield put(actions.fetchRepository.success(returnPayload));
	} catch (e) {
		if ((e as Error).message.includes('user repository record not found')) {
			yield* call(createRepoUserSaga);
			yield put(actions.fetchRepository.failure({ error: 'User repository record not found' }));
		} else {
			toast.error((e as Error).message);
			yield put(actions.fetchRepository.failure({ error: 'Failed to fetch attachments' }));
		}
	}
}

export function* searchRepositorySaga() {
	const repositoryState = (state: RootState) => state.repository;
	const state: RepositoryState = yield select(repositoryState);

	if (!state.searchQuery) {
		yield put(actions.searchRepository.success(initialSearchValues));
		return;
	}

	try {
		const queryStr = getQueryString(state, {
			limit: undefined,
			page: undefined,
		});
		const result = yield* call(searchRepository, queryStr);

		yield put(actions.searchRepository.success(result));
	} catch (e) {
		toast.error((e as Error).message);
		yield put(actions.searchRepository.failure({ error: 'Failed to get attachments esarch result' }));
	}
}

export default function* repositorySaga() {
	yield takeLatest(types.FETCH_REPOSITORY_REQUEST, fetchRepositorySaga);
	yield takeLatest(types.RESET_REPOSITORY_FILTERS, fetchRepositorySaga);
	yield takeLatest(types.SET_REPOSITORY_PAGE_INDEX, fetchRepositorySaga);
	yield takeLatest(types.SEARCH_REPOSITORY_REQUEST, searchRepositorySaga);
}
