import { cloudV2ApiURL } from '../../../../config/baseURL';
import { getToken } from 'reducers/api';
import { FetchMissedMakeupQuery, FetchMissedMakeupReportListQuery } from 'reducers/dashboard/missedMakeup/types';
import { internalStatistic } from '@nimbly-technologies/nimbly-common';
import { pickBy, identity } from 'lodash';

export const fetchMissedMakeupOverview = async (query: FetchMissedMakeupQuery) => {
	try {
		const cleanedQuery = pickBy(query, identity);
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: await getToken(),
			},
			body: JSON.stringify(cleanedQuery),
		};
		const url = `${cloudV2ApiURL}/statistics/report/late-report/overview`;
		const response = await fetch(url, options);
		if (response?.status === 200) {
			const responseJson = await response.json();
			return responseJson.data as internalStatistic.ReportSummaryOverview;
		}
		throw new Error(`Failed to get response, status ${response?.status}`);
	} catch (error) {
		throw new Error(error.message);
	}
};

export const fetchMissedMakeupMakeupList = async (query: FetchMissedMakeupReportListQuery) => {
	try {
		const cleanedQuery = pickBy(query, identity);
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: await getToken(),
			},
			body: JSON.stringify(cleanedQuery),
		};
		const url = `${cloudV2ApiURL}/statistics/report/late-report/list`;
		const response = await fetch(url, options);
		if (response?.status === 200) {
			const responseJson = await response.json();
			return responseJson.data as internalStatistic.ReportSummaryList;
		}
		throw new Error(`Failed to get response, status ${response?.status}`);
	} catch (error) {
		throw new Error(error.message);
	}
};
