import { CourseStatus } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/course/createCourse/createCourse.action';
import * as types from 'reducers/lms/course/createCourse/createCourse.actionTypes';
import { getSuccessToastMessage } from 'reducers/lms/coursesSyllabuses/utils/getSuccessToastMessage';
import { call, put, takeLatest } from 'redux-saga/effects';

function* createCourse(action: ReturnType<typeof actions.createCourse.request>) {
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const body = action.payload.data;

		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};
		const URL = `${apiURL}/lms/courses`;
		const request = () => fetch(URL, options);
		// @ts-ignore
		const response = yield call(request);
		const responseJson = yield response.json();
		if (response && response.status === 200) {
			yield put(actions.createCourse.success());
			getSuccessToastMessage(action.payload.data?.status);
		} else {
			const errorMessage = responseJson.data;
			toast.error(errorMessage);
			yield put(actions.createCourse.failure({ error: errorMessage }));
		}
	} catch (e) {
		toast.error('Failed to Create Course');
		yield put(actions.createCourse.failure({ error: 'Failed to Create Course' }));
	}
}

export default function* createCourseSaga() {
	yield takeLatest(types.CREATE_LMS_COURSE_REQUEST, createCourse);
}
