export const TIME_LIMIT = process.env.REACT_APP_CACHE_TIME_LIMIT ? parseInt(process.env.REACT_APP_CACHE_TIME_LIMIT, 10) : 1;

export function setToSession(value: any, name: string) {
  let expiredAt = new Date(new Date().getTime() + 60000 * TIME_LIMIT);
  let obj = {
    value: value,
    expiredAt: expiredAt.toISOString()
  };
  sessionStorage.setItem(name, JSON.stringify(obj));
}

export function clearSessionStorageCache() {
  sessionStorage.clear();
}

export function removeItemSessionStorageCache(name: string) {
  sessionStorage.removeItem(name);
}

export function checkExpiredStorageItem<T>(name: string): T | null {
  const item = sessionStorage.getItem(name);
  if (!item) {
    return null;
  }
  const expired = Date.parse(JSON.parse(item).expiredAt);
  const now = Date.now();
  if (now > expired) {
    sessionStorage.removeItem(name);
    return null;
  } else {
    const obj = JSON.parse(item).value;
    return <T>obj;
  }
}
