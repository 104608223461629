import { ActionType, createReducer } from 'typesafe-actions';
import { QuizEditorState } from './type.d';
import * as types from './quizEditor.actionTypes';
import * as actions from './quizEditor.action';
import { SyllabusFormValues } from 'components/lms/syllabus/SyllabusForm/SyllabusInterface';
import { CourseFormState } from 'components/lms/course/CourseForm/CourseInterface';
import moment from 'moment';

export type QuizAction = ActionType<typeof actions>;

export const syllabusFormInitialValues: SyllabusFormValues = {
	syllabusImageID: '',
	syllabusName: '',
	syllabusCode: '',
	syllabusDescription: '',
	syllabusImageURL: '',
	courseIDs: [],
	enrolledUserIDs: [],
	enrolledUserGroupIDs: [],
	quizzes: [],
};

export const courseFormInitialValues: CourseFormState = {
	courseName: '',
	courseCode: '',
	courseDescription: '',
	courseImageID: '',
	availableFrom: Number(moment().format('x')),
	availableUntil: 0,
	enrolledUserIDs: [],
	enrolledUserGroupIDs: [],
	quizzes: [],
};

export const initialState: QuizEditorState = {
	source: undefined,
	prevPath: '',
	prevStep: 2,

	syllabusValues: syllabusFormInitialValues,
	courseValues: {
		form: courseFormInitialValues,
		errorForm: [],
		chapterIndex: 0,
		chapterList: [],

		profileImage: '',
		userList: [],
		userGroupList: [],
		selectedUserList: [],
		selectedUserGroupList: [],

		fileUploadList: [],
		urlList: [],
		unCategoriedFiles: [],
		isFirstUpload: false,
	},
};

export const quizEditorReducer = createReducer<QuizEditorState, QuizAction>(initialState)
	.handleType(types.SET_QUIZ_EDITOR_PREV_PATH, (state, action) => ({
		...state,
		source: action.payload.source,
		prevPath: action.payload.prevPath,
		prevStep: action.payload.step ?? 2,
	}))
	.handleType(types.RESET_QUIZ_EDITOR_PREV_PATH, (state) => ({
		...state,
		source: initialState.source,
		prevPath: initialState.prevPath,
		prevStep: initialState.prevStep,
		syllabusValues: initialState.syllabusValues,
		courseValues: initialState.courseValues,
	}))
	.handleType(types.SET_SYLLABUS_FORM_VALUES, (state, action) => ({
		...state,
		syllabusValues: { ...state.syllabusValues, ...action.payload },
		courseValues: initialState.courseValues,
	}))
	.handleType(types.SET_COURSE_FORM_VALUES, (state, action) => ({
		...state,
		syllabusValues: initialState.syllabusValues,
		courseValues: { ...state.courseValues, ...action.payload },
	}));
