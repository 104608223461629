/* eslint-disable complexity */
import { Site, User } from 'nimbly-common';
import { ActionType } from 'typesafe-actions';

import * as actions from './sites.action';
import * as types from './sites.actionTypes';
import { Sites, SiteExtend, TabOptions } from './type';

export type SitesAction = ActionType<typeof actions>;

export type SortType =
	| 'name'
	| 'primaryDepartment'
	| 'auditor'
	| 'reports'
	| 'status'
	| 'color'
	| 'colorSort'
	| 'colorSelected'
	| 'score'
	| 'updatedAt'
	| 'updatedBy'
	| 'supervisor';

interface ModalVisible {
	bulk: boolean;
	schedule: boolean;
	bulkSchedule: boolean;
	bulkScheduleEdit: boolean;
	confirmBlockSite: boolean;
}

type DirectionType = 'asc' | 'desc';

export interface SitesState {
	auditor: DirectionType;
	color: DirectionType;
	colorSelected: number;
	departments: string[];
	error: string | null;
	filterQuery: string;
	isConfirmationLoading: boolean;
	isLoading: boolean;
	isSiteLoaded: boolean;
	isSitesLoaded: boolean;
	modalVisible: ModalVisible;
	name: DirectionType;
	orderedSites: OrderedSite[];
	allOrderedSites: OrderedSite[];
	pageIndex: number;
	paginateSites: OrderedSite[];
	primaryDepartment: DirectionType;
	processedSites: OrderedSite[];
	reports: DirectionType;
	score: DirectionType;
	scrollPosition: number;
	selectedSite: any;
	selectedSiteKeySchedule: string;
	selectedTab: string;
	listActiveTab: TabOptions;
	sortBy: SortType;
	status: DirectionType;
	supervisor: DirectionType;
	totalSites: number;
	totalActive: number;
	totalDisabled: number;
	updatedAt: DirectionType;
	updatedBy: DirectionType;
	sites: Sites;
	site: SiteExtend | null;
	paginationSites: Sites;
}

export interface OrderedPopulatedSite {
	key: string;
	value: Site & {
		primaryDepartment?: string;
		supervisor?: string;
		updatedBy?: string;
		primaryTeam: User;
		primaryAuditor: User;
	} & {
		populated: SitePopulate;
	};
}

export interface OrderedSite {
	key: string;
	value: Site;
}

export interface SitePopulate {
	owner: User;
	assignedAuditor: User;
	team: User[];
	updatedBy: User;
}

export interface ExtendOrderedSite {
	key: string;
	value: Site & {
		primaryDepartment?: string;
		supervisor?: string;
		updatedBy?: string;
		primaryTeam: User;
		primaryAuditor: User;
	} & { populated: SitePopulate };
	assignedAuditorName?: string;
	updatedByName?: string;
}

const initialState: SitesState = {
	auditor: 'asc',
	color: 'desc',
	colorSelected: 2,
	departments: [],
	error: null,
	filterQuery: '',
	isConfirmationLoading: false,
	isSiteLoaded: false,
	isSitesLoaded: false,
	name: 'asc',
	orderedSites: [],
	allOrderedSites: [],
	pageIndex: 1,
	paginateSites: [],
	totalSites: 0,
	totalActive: 0,
	totalDisabled: 0,
	primaryDepartment: 'asc',
	processedSites: [],
	reports: 'desc',
	score: 'desc',
	scrollPosition: 0,
	selectedSite: {},
	selectedSiteKeySchedule: '',
	selectedTab: 'report',
	listActiveTab: 'active',
	sites: null,
	site: null,
	sortBy: 'name',
	status: 'asc',
	supervisor: 'asc',
	updatedAt: 'asc',
	updatedBy: 'asc',
	isLoading: false,
	modalVisible: {
		bulk: false,
		bulkSchedule: false,
		bulkScheduleEdit: false,
		confirmBlockSite: false,
		schedule: false,
	},
	paginationSites: null,
};

export function sitesReducer(state = initialState, action: SitesAction): SitesState {
	switch (action.type) {
		case types.RESET_SITE_SETTINGS:
			return {
				...state,
				pageIndex: 1,
				sortBy: 'name',
				filterQuery: '',
			};
		case types.SET_TOTAL_SITES:
			return {
				...state,
				totalSites: action.payload.value,
			};
		case types.SET_TOTAL_ACTIVE:
			return {
				...state,
				totalActive: action.payload.value,
			};
		case types.SET_TOTAL_DISABLED:
			return {
				...state,
				totalDisabled: action.payload.value,
			};
		case types.FETCH_PAGINATES_SITES_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case types.FETCH_PAGINATES_SITES_SUCCESS:
			return {
				...state,
				isLoading: false,
				paginationSites: action.payload.data,
			};
		case types.FETCH_PAGINATES_SITES_FAILURE:
			return {
				...state,
				error: action.payload.error,

				isLoading: false,
			};
		case types.FETCH_SITES_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case types.FETCH_SITES_SUCCESS:
			return {
				...state,
				isLoading: false,
				sites: action.payload.data,
				orderedSites: action.payload.ordered,
				allOrderedSites: action.payload.ordered,
			};
		case types.FETCH_SITES_FAILURE:
			return {
				...state,
				error: action.payload.error,

				isLoading: false,
			};
		case types.FETCH_SITE_BY_ID_REQUEST:
			return {
				...state,
				isLoading: true,
				isSiteLoaded: false,
			};
		case types.FETCH_SITE_BY_ID_SUCCESS: {
			return {
				...state,
				isLoading: false,
				site: action.payload.data,
				isSiteLoaded: true,
			};
		}
		case types.FETCH_SITE_BY_ID_FAILURE:
			return {
				...state,
				error: action.payload.error,
				isLoading: false,
				isSiteLoaded: true,
			};
		case types.FETCH_SITE_DEPARTMENTS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case types.FETCH_SITE_DEPARTMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				departments: action.payload.data,
			};
		case types.FETCH_SITE_DEPARTMENTS_FAILURE:
			return {
				...state,
				error: action.payload.error,
				isLoading: false,
			};
		case types.UPDATE_SITE_DEPARTMENTS_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case types.UPDATE_SITE_DEPARTMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case types.UPDATE_SITE_DEPARTMENTS_FAILURE:
			return {
				...state,
				error: action.payload.error,
				isLoading: false,
			};
		case types.SET_PROCESSED_SITES: {
			return {
				...state,
				pageIndex: 1,
				processedSites: action.payload.sites,
				paginateSites: action.payload.sites,
			};
		}
		case types.SET_PAGE_INDEX:
			return {
				...state,
				pageIndex: action.payload.value,
				paginateSites: state.processedSites,
			};
		case types.SET_SITES:
			return {
				...state,
				orderedSites: action.payload.sites,
				processedSites: action.payload.sites,
				paginateSites: action.payload.sites,
			};
		case types.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.payload.value,
			};
		case types.SET_IS_CONFIRMATION_LOADING:
			return {
				...state,
				isConfirmationLoading: action.payload.value,
			};
		case types.SET_FILTER_QUERY:
			return {
				...state,
				filterQuery: action.payload.text,
			};
		case types.SET_IS_SITES_LOADED:
			return {
				...state,
				isSitesLoaded: action.payload.value,
			};
		case types.CLEAR_FILTER_QUERY:
			return {
				...state,
				filterQuery: '',
			};
		case types.SET_SORT: {
			if (state.sortBy !== action.payload.newType) {
				return {
					...state,
					sortBy: action.payload.newType,
					name: 'asc',
					primaryDepartment: 'asc',
					auditor: 'asc',
					reports: 'desc',
					status: 'asc',
					color: 'desc',
					updatedBy: 'asc',
					updatedAt: 'asc',
					colorSelected: 2,
				};
			}
			return {
				...state,
				[action.payload.newType]: action.payload.newOrder,
			};
		}
		case types.SET_COLOR:
			return {
				...state,
				sortBy: 'colorSort',
				color: 'desc',
				colorSelected: state.colorSelected === 2 ? 0 : state.colorSelected + 1,
			};
		case types.SET_SCROLL:
			return {
				...state,
				scrollPosition: action.payload.newScroll,
			};
		case types.SET_SITE_TAB:
			return {
				...state,
				selectedTab: action.payload.tab,
			};
		case types.SET_SITE_LIST_TAB:
			return {
				...state,
				listActiveTab: action.payload.tab,
			};
		case types.SHOW_SCHEDULE_MODAL:
			return {
				...state,
				selectedSiteKeySchedule: action.payload.key,
				modalVisible: {
					...state.modalVisible,
					schedule: true,
				},
			};
		case types.DISMISS_SCHEDULE_MODAL:
			return {
				...state,
				selectedSiteKeySchedule: '',
				modalVisible: {
					...state.modalVisible,
					schedule: false,
				},
			};
		case types.SHOW_BULK_SCHEDULE_EDIT:
			return {
				...state,
				modalVisible: {
					...state.modalVisible,
					bulkScheduleEdit: true,
				},
			};
		case types.DISMISS_BULK_SCHEDULE_EDIT:
			return {
				...state,
				selectedSiteKeySchedule: '',
				modalVisible: {
					...state.modalVisible,
					bulkScheduleEdit: false,
				},
			};
		case types.SHOW_BULK_MODAL:
			return {
				...state,

				modalVisible: {
					...state.modalVisible,
					bulk: true,
				},
			};
		case types.SHOW_CONFIRM_BLOCK_MODAL:
			return {
				...state,
				modalVisible: {
					...state.modalVisible,
					confirmBlockSite: true,
				},
				selectedSite: action.payload,
			};
		case types.DISMISS_CONFIRM_BLOCK_MODAL:
			return {
				...state,
				modalVisible: {
					...state.modalVisible,
					confirmBlockSite: false,
				},
				selectedSite: {},
			};
		case types.DISMISS_BULK_MODAL:
			return {
				...state,
				modalVisible: {
					...state.modalVisible,
					bulk: false,
				},
			};
		case types.SHOW_BULK_SCHEDULE:
			return {
				...state,
				modalVisible: {
					...state.modalVisible,
					bulkSchedule: true,
				},
			};
		case types.DISMISS_BULK_SCHEDULE:
			return {
				...state,
				modalVisible: {
					...state.modalVisible,
					bulkSchedule: false,
				},
			};
		case types.RESET_SITE:
			return {
				...state,
				site: null,
			};
		case types.GET_POPULATED_SITES:
			return state;
		default:
			return state;
	}
}
