// libraries and utilities
import { put, call, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
// Redux
import * as questionnaireActions from './questionnaire.action';
import { apiURL } from '../../config/baseURL';
import API from '../../helpers/api';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import i18n from '../../i18n';

// TODO: migrate to API.

/**
 * Create new questionnaire based on previous questionnaire department data. This
 * function will fetch the department list of selected questionnaire from API first,
 * then create new questionnaire using recently fetched department.
 *
 */
export function* cloneQuestionnaire(action: ReturnType<typeof questionnaireActions.cloneQuestionnaireAsync.request>) {
  try {
    // data initialization
    const token: string = yield call(API.getFirebaseToken);

    // first fetch for department list
    const urlFetchDepartment = `${apiURL}/departments/questionnaire/${action.payload.oldQuestionnaireKey}`;
    const resultFetchDepartment: Response = yield call(API.get, urlFetchDepartment, token);
    const resultFetchDepartmentParsed = yield call(resultFetchDepartment.json.bind(resultFetchDepartment));

    // check data integrity for department list
    if (resultFetchDepartment.status !== 200) {
      toast.error(i18n.t('error.common.fetchAccess'));
      yield put(questionnaireActions.cloneQuestionnaireAsync.failure({ message: 'Unable to get department.' }));
      return;
    }

    if (resultFetchDepartmentParsed.message !== 'SUCCESS' || isUndefined(resultFetchDepartmentParsed.data)) {
      toast.error(i18n.t('error.common.fetchAccess'));
      yield put(questionnaireActions.cloneQuestionnaireAsync.failure({ message: 'Unable to get department.' }));
      return;
    } else {
      if (isArray(resultFetchDepartmentParsed.data)) {
        if (resultFetchDepartmentParsed.data.length === 0) {
          toast.error(i18n.t('pageQuestionnaire.departmentTools.empty'));
          yield put(questionnaireActions.cloneQuestionnaireAsync.failure({ message: 'Unable to get department.' }));
          return;
        }
      } else {
        toast.error(i18n.t('error.common.fetchAccess'));
        yield put(questionnaireActions.cloneQuestionnaireAsync.failure({ message: 'Unable to get department.' }));
        return;
      }
    }

    // second fetch to create new questionnaire
    const urlPostQuestionnaire = `${apiURL}/questionnaires/`;
    const newQuestionnaire = {
      ...action.payload.newQuestionnaireData,
      departmentIDs: resultFetchDepartmentParsed.data
    };
    const resultPostQuestionnaire: Response = yield call(API.post, urlPostQuestionnaire, token, newQuestionnaire);
    const resultPostQuestionnaireParsed = yield call(resultPostQuestionnaire.json.bind(resultPostQuestionnaire));

    // check data integrity for post questionnaire
    if (resultPostQuestionnaire.status !== 200) {
      yield put(questionnaireActions.cloneQuestionnaireAsync.failure({ message: 'Unable to clone questionnaire.' }));
      return;
    }

    if (resultPostQuestionnaireParsed.message !== 'SUCCESS' || isUndefined(resultPostQuestionnaireParsed.data)) {
      yield put(questionnaireActions.cloneQuestionnaireAsync.failure({ message: 'Unable to clone questionnaire.' }));
      return;
    }

    // this will finish the state calls
    yield put(questionnaireActions.cloneQuestionnaireAsync.success({ newQuestionnaireId: '' }));
    yield put(
      questionnaireActions.fetchPaginateQuestionnairesAsync.request({ sortFields: 'title', sortDirections: 'asc' })
    );
  } catch (err) {
    yield put(questionnaireActions.cloneQuestionnaireAsync.failure({ message: '' }));
  }
}

export default function* QuestionnaireSagaCloneQuestionnaire() {
  yield takeEvery(questionnaireActions.cloneQuestionnaireAsync.request, cloneQuestionnaire);
}
