import { createAsyncAction } from 'typesafe-actions';
import {
  OverviewDailyChart,
  FETCH_OVERVIEW_DAILY_CHART_REQUEST,
  FETCH_OVERVIEW_DAILY_CHART_SUCCESS,
  FETCH_OVERVIEW_DAILY_CHART_FAILURE,
  FetchDailyChartPayload
} from './types';

export const fetchOverviewDailyChartAsync = createAsyncAction(
  FETCH_OVERVIEW_DAILY_CHART_REQUEST,
  FETCH_OVERVIEW_DAILY_CHART_SUCCESS,
  FETCH_OVERVIEW_DAILY_CHART_FAILURE
)<FetchDailyChartPayload, OverviewDailyChart, string>();
