import { getDummyAuditorsDailyChart } from './dummyAuditorsDailyChart';
import { FetchSingleUserChartPayload, SingleUserChart } from 'reducers/dashboard/auditors/singleUserChart/types';

const sitesName = ['Pacific Place', 'Kota Kasablanka', 'Pondok Indah Mall', 'Mall of Indonesia', 'PIK'];

export const getDummySingleUserChart = (payload: FetchSingleUserChartPayload) => {
  const { userID, ...otherPayload } = payload;

  /**
   * Already filtered by selected date from dummy `AuditorsDailyChart`
   */
  const dummyAuditorsDailyChart = getDummyAuditorsDailyChart(otherPayload);

  const dummySingleUserChart_DAILY: SingleUserChart['daily'] = dummyAuditorsDailyChart.daily.map(manyUserCompletion => {
    const userKeys = Object.keys(manyUserCompletion.users);
    const manyUserCompletion_arr = userKeys.map(userKey => manyUserCompletion.users[userKey]);

    const filteredSingleUserObj: { [userID: string]: any } = {};
    manyUserCompletion_arr.forEach((site, index) => {
      if (!filteredSingleUserObj[site.userID]) {
        filteredSingleUserObj[site.userID] = {
          // change dummy auditors name to dummy sites name
          siteName: sitesName[index],
          scheduledNum: site.scheduledNum,
          completedNum: site.completedNum,
          duration: site.duration,
          adhocNum: site.adhocNum,
          adhocDuration: site.adhocDuration
        };
      }
    });

    return {
      ...manyUserCompletion,
      sites: filteredSingleUserObj
    };
  });

  const reduced_singleUserChartData = dummySingleUserChart_DAILY.reduce((curr, acc) => ({
    date: '',
    totalAdhocCompleted: curr.totalAdhocCompleted + acc.totalAdhocCompleted,
    totalAdhocDuration: curr.totalAdhocDuration + acc.totalAdhocDuration,
    totalCompleted: curr.totalCompleted + acc.totalCompleted,
    totalDuration: curr.totalDuration + acc.totalDuration,
    totalScheduled: curr.totalScheduled + acc.totalScheduled,
    sites: {},

    previousScheduled: curr.previousScheduled + acc.previousScheduled,
    previousCompleted: curr.previousCompleted + acc.previousCompleted,
    previousDuration: curr.previousDuration + acc.previousDuration,
    previousAdhocCompleted: curr.previousAdhocCompleted + acc.previousAdhocCompleted,
    previousAdhocDuration: curr.previousAdhocDuration + acc.previousAdhocDuration,
    totalMadeUp: 0
  }));

  const dummySingleUserChart_OVERVIEW: SingleUserChart['overview'] = {
    currentCompleted: reduced_singleUserChartData.totalCompleted,
    currentDuration: reduced_singleUserChartData.totalDuration,
    currentScheduled: reduced_singleUserChartData.totalScheduled,
    previousCompleted:
      reduced_singleUserChartData.totalCompleted % 2
        ? reduced_singleUserChartData.totalCompleted * 0.75
        : reduced_singleUserChartData.totalCompleted * 1.25,
    previousDuration:
      reduced_singleUserChartData.totalDuration % 2
        ? reduced_singleUserChartData.totalDuration * 0.6
        : reduced_singleUserChartData.totalDuration * 1.05,
    previousScheduled:
      reduced_singleUserChartData.totalScheduled % 2
        ? reduced_singleUserChartData.totalScheduled * 1
        : reduced_singleUserChartData.totalScheduled * 1.15
  };

  const dummySingleUserChart: SingleUserChart = {
    overview: dummySingleUserChart_OVERVIEW,
    daily: dummySingleUserChart_DAILY
  };

  return dummySingleUserChart;
};
