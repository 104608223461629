import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from 'reducers/lmsSettings/lmsSettings.action';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { toast } from 'react-toastify';

function* fetchLMSSettings() {
	const authToken = yield API.getFirebaseToken();

	const URL = `${apiURL}/lms/lms-settings`;
	const response = yield call(API.get, URL, authToken);
	const { data, message } = yield response.json();
	if (response && response.status === 200) {
		yield put(actions.lmsSettingsAction.success({ data, message }));
	} else {
		yield put(actions.lmsSettingsAction.failure({ message }));
	}
}

export const upsertLMSSettings = async ({
	showCorrectQuizAnswer,
	allowDownloadContent,
}: {
	showCorrectQuizAnswer: boolean;
	allowDownloadContent: boolean;
}) => {
	const url = `${apiURL}/lms/lms-settings/upsert`;
	const token = await API.getFirebaseToken();
	const res = await API.post(url, token, {
		showCorrectQuizAnswer,
		allowDownloadContent,
	});
	const response = await res.json();

	if (res.status === 200) {
		toast.success('LMS Settings Updated Successfully');
		return response.data as string;
	} else {
		if (response.message === 'FAILED') {
			throw new Error(response.data);
		}
		toast.error('Something went wrong');
		throw new Error(response.message);
	}
};

export default function* lmsSettingsSaga() {
	yield takeLatest(actions.lmsSettingsAction.request, fetchLMSSettings);
}
