import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesCategoriesChart,
  FETCH_ISSUES_CATEGORIES_CHART_REQUEST,
  FETCH_ISSUES_CATEGORIES_CHART_SUCCESS,
  FETCH_ISSUES_CATEGORIES_CHART_FAILURE,
  FetchIssuesCategoriesChartPayload
} from './types';

export const fetchIssuesCategoriesChartAsync = createAsyncAction(
  FETCH_ISSUES_CATEGORIES_CHART_REQUEST,
  FETCH_ISSUES_CATEGORIES_CHART_SUCCESS,
  FETCH_ISSUES_CATEGORIES_CHART_FAILURE
)<FetchIssuesCategoriesChartPayload, IssuesCategoriesChart, string>();
