export const SET_ACTIVE_GALLERY_TAB = '@gallery/SET_ACTIVE_GALLERY_TAB';
export const SET_GALLERY_SEARCH_QUERY = '@gallery/SET_GALLERY_SEARCH_QUERY';
export const SET_GALLERY_FILTERS = '@gallery/SET_GALLERY_FILTERS';
export const RESET_GALLERY_FILTERS = '@gallery/RESET_GALLERY_FILTERS';
export const SET_GALLERY_GROUP_BY = '@gallery/SET_GALLERY_GROUP_BY';
export const SET_GALLERY_PAGE_INDEX = '@gallery/SET_GALLERY_PAGE_INDEX';
export const SET_GALLERY_GRID_SIZE = '@gallery/SET_GALLERY_GRID_SIZE';

export const SET_SELECTED_GALLERY_ATTACHMENT = '@gallery/SET_SELECTED_GALLERY_ATTACHMENT';
export const SET_SELECTED_GALLERY_ATTACHMENT_IDS = '@gallery/SET_SELECTED_GALLERY_ATTACHMENT_IDS';
export const SET_TOTAL_SELECTED_GALLERY_ATTACHMENTS = '@gallery/SET_TOTAL_SELECTED_GALLERY_ATTACHMENTS';
export const SET_TOTAL_SELECTED_GALLERY_ATTACHMENTS_SIZE = '@gallery/SET_TOTAL_SELECTED_GALLERY_ATTACHMENTS_SIZE';

export const SET_SELECTED_GALLERY_ALBUM = '@gallery/SET_SELECTED_GALLERY_ALBUM';
export const SET_SELECTED_GALLERY_ALBUM_IDS = '@gallery/SET_SELECTED_GALLERY_ALBUM_IDS';

export const SET_SELECTED_GALLERY_DATES = '@gallery/SET_SELECTED_GALLERY_DATES';

export const TOGGLE_GALLERY_FILTER = '@gallery/TOGGLE_GALLERY_FILTER';
export const TOGGLE_GALLERY_SELECT_MODE = '@gallery/TOGGLE_GALLERY_SELECT_MODE';

export const TOGGLE_DOWNLOAD_INITIALIZATION_MODAL = '@gallery/TOGGLE_DOWNLOAD_INITIALIZATION_MODAL';
export const TOGGLE_DOWNLOAD_ERROR_MODAL = '@gallery/TOGGLE_DOWNLOAD_ERROR_MODAL';
export const SET_DOWNLOAD_PROGRESS_MODAL_CONFIG = '@gallery/SET_DOWNLOAD_PROGRESS_MODAL_CONFIG';

export const FETCH_ATTACHMENTS_REQUEST = '@gallery/FETCH_ATTACHMENTS_REQUEST';
export const FETCH_ATTACHMENTS_SUCCESS = '@gallery/FETCH_ATTACHMENTS_SUCCESS';
export const FETCH_ATTACHMENTS_FAILED = '@gallery/FETCH_ATTACHMENTS_FAILED';

export const SEARCH_ATTACHMENTS_REQUEST = '@gallery/SEARCH_ATTACHMENTS_REQUEST';
export const SEARCH_ATTACHMENTS_SUCCESS = '@gallery/SEARCH_ATTACHMENTS_SUCCESS';
export const SEARCH_ATTACHMENTS_FAILED = '@gallery/SEARCH_ATTACHMENTS_FAILED';
