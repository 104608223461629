import API from 'helpers/api';
import { apiURL, baseApiURLAlt } from 'config/baseURL';
import { ISuggestionBase, IHealthStore, ISuggestionStore } from 'reducers/healthDashboard/healthDashboard.type';
import i18next from 'i18next';
import { store } from 'store/configStore';
import queryString from 'query-string';
import moment from 'moment';

export const fetchResolutionSuggestion = async (): Promise<ISuggestionStore | null | undefined> => {
	try {
		const { startDate, endDate } = store.getState().healthDashboard;
		let query = '';
		if (startDate) {
			let utcStartDate = moment(startDate).format('YYYY-MM-DD');

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = moment(endDate).format('YYYY-MM-DD');
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}
		query = `${query}&${queryString.stringify({
			lang: i18next.language?.toUpperCase(),
		})}`;

		const url = `${apiURL}/issues/healthIndicators/issueResolutionRateInsights?${query}`;
		const res = await API.getJSON(url);
		return (res?.data ?? null) as ISuggestionStore | null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchCompletionSuggestion = async (): Promise<ISuggestionStore | null | undefined> => {
	try {
		const { startDate, endDate } = store.getState().healthDashboard;
		let query = '';
		if (startDate) {
			let utcStartDate = moment(startDate).format('YYYY-MM-DD');

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = moment(endDate).format('YYYY-MM-DD');
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}
		query = `${query}&${queryString.stringify({
			lang: i18next.language?.toUpperCase(),
		})}`;

		const url = `${apiURL}/issues/healthIndicators/reportCompletionRateInsights?${query}`;
		const res = await API.getJSON(url);
		return (res?.data ?? null) as ISuggestionStore | null;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const fetchFlagRateSuggestion = async (): Promise<ISuggestionStore | null | undefined> => {
	try {
		const { startDate, endDate } = store.getState().healthDashboard;
		let query = '';
		if (startDate) {
			let utcStartDate = moment(startDate).format('YYYY-MM-DD');

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = moment(endDate).format('YYYY-MM-DD');
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}
		query = `${query}&${queryString.stringify({
			lang: i18next.language?.toUpperCase(),
		})}`;

		const url = `${apiURL}/issues/healthIndicators/flagRateInsights?${query}`;
		const res = await API.getJSON(url);
		return (res?.data ?? null) as ISuggestionStore | null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchResolutionRate = async (): Promise<IHealthStore | undefined> => {
	try {
		const { startDate, endDate } = store.getState().healthDashboard;
		let query = '';
		if (startDate) {
			let utcStartDate = moment(startDate).format('YYYY-MM-DD');

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = moment(endDate).format('YYYY-MM-DD');
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}
		const url = `${apiURL}/issues/healthIndicators/filteredIssueResolutionRate?${query}`;
		const res = await API.getJSON(url);
		return res?.data as IHealthStore;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const fetchCompletionRate = async (): Promise<IHealthStore | undefined> => {
	try {
		const { startDate, endDate } = store.getState().healthDashboard;
		let query = '';
		if (startDate) {
			let utcStartDate = moment(startDate).format('YYYY-MM-DD');

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = moment(endDate).format('YYYY-MM-DD');
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}

		const url = `${apiURL}/issues/healthIndicators/filteredReportCompletionRate?${query}`;
		const res = await API.getJSON(url);
		return res?.data as IHealthStore;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const fetchFlagRate = async (): Promise<IHealthStore | undefined> => {
	try {
		const { startDate, endDate } = store.getState().healthDashboard;
		let query = '';
		if (startDate) {
			let utcStartDate = moment(startDate).format('YYYY-MM-DD');

			query = `${query}&${queryString.stringify({
				startDate: utcStartDate,
			})}`;
		}
		if (endDate) {
			let utcEndDate = moment(endDate).format('YYYY-MM-DD');
			query = `${query}&${queryString.stringify({
				endDate: utcEndDate,
			})}`;
		}

		const url = `${apiURL}/issues/healthIndicators/filteredFlagRate?${query}`;
		const res = await API.getJSON(url);
		return res?.data as IHealthStore;
	} catch (error) {
		return Promise.reject(error);
	}
};
