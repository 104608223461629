import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './customerFeedback.action';
import { CustomerFeedbackReportState } from './type';

export type CustomerFeedbackAction = ActionType<typeof actions.fetchCampaignReport>;

const initialState: CustomerFeedbackReportState = {
  data: {
    organizationID: '',
    questionnaireID: '',
    questions: []
  },
  message: '',
  isLoading: false
};

export const customerFeedbackReportReducer = createReducer<CustomerFeedbackReportState, CustomerFeedbackAction>(
  initialState
)
  .handleAction(actions.fetchCampaignReport.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(actions.fetchCampaignReport.success, (state, action) => ({
    ...state,
    data: action.payload.data,
    message: action.payload.message,
    isLoading: false
  }))
  .handleAction(actions.fetchCampaignReport.failure, (state, action) => ({
    ...state,
    message: action.payload.message,
    isLoading: false
  }));
