import { createAsyncAction, action } from 'typesafe-actions';

import * as types from './questionnaireIndex.actionType';
import { QuestionnaireIndexes } from './type';

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const fetchQuestionnaireIndex = createAsyncAction(
  types.FETCH_QUESTIONNAIRE_INDEXES_REQUEST,
  types.FETCH_QUESTIONNAIRE_INDEXES_SUCCESS,
  types.FETCH_QUESTIONNAIRE_INDEXES_FAILURE
)<undefined, { data: QuestionnaireIndexes | null }, { error: string }>();

export const fetchQuestionnaireProfileOnly = createAsyncAction(
  types.FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_REQUEST,
  types.FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_SUCCESS,
  types.FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_FAILURE
)<undefined, { data: QuestionnaireIndexes | null }, { error: string }>();
