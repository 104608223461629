import { createAsyncAction } from 'typesafe-actions';
import {
  SitesIssueList,
  FETCH_ISSUE_LIST_REQUEST,
  FETCH_ISSUE_LIST_SUCCESS,
  FETCH_ISSUE_LIST_FAILURE,
  DOWNLOAD_ISSUE_LIST_REQUEST,
  DOWNLOAD_ISSUE_LIST_SUCCESS,
  DOWNLOAD_ISSUE_LIST_FAILURE,
  FetchSiteIssueStatListPayload,
  DownloadSiteIssueStatListPayload
} from './types';

export const fetchIssueListAsync = createAsyncAction(
  FETCH_ISSUE_LIST_REQUEST,
  FETCH_ISSUE_LIST_SUCCESS,
  FETCH_ISSUE_LIST_FAILURE
)<FetchSiteIssueStatListPayload, SitesIssueList, string>();

export const downloadIssueListAsync = createAsyncAction(
  DOWNLOAD_ISSUE_LIST_REQUEST,
  DOWNLOAD_ISSUE_LIST_SUCCESS,
  DOWNLOAD_ISSUE_LIST_FAILURE
)<DownloadSiteIssueStatListPayload, string, string>();
