import { FetchFlagsOverviewPayload, FetchFlagsOverviewResponse } from 'reducers/dashboard/sites/flagsOverview/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyFlagCards as getDummyFlagsOverview } from 'utils/dashboardDummy/overview/dummyFlagCards';

export const fetchFlagsOverview = async (
  payload: FetchFlagsOverviewPayload
): Promise<FetchFlagsOverviewResponse | undefined> => {
  const { isDummy, ...query } = payload;

  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyFlagCards = getDummyFlagsOverview(payload);
    return {
      message: 'SUCCESS',
      data: dummyFlagCards
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };

    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/flags/overview?${serializedQueries}`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const sitesFlagsOverviewResult: FetchFlagsOverviewResponse = await res.json();
    return sitesFlagsOverviewResult;
  } catch {
    // ERROR
    const errorResult: FetchFlagsOverviewResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
