import { apiURL } from 'config/baseURL';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/userManagement/roleMapping/addUser.action';
import * as types from 'reducers/lms/userManagement/roleMapping/addUser.actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';

interface Action {
	type: string;
	payload: { [key: string]: string };
	meta: string | undefined;
}

function* addUser(action: Action) {
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const body = { data: action.payload.data };

		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const addUserURL = `${apiURL}/lms/users/bulk-role-mapping`;
		const request = () => fetch(addUserURL, options);
		// @ts-ignore
		const response = yield call(request);

		if (response && response.status === 200) {
			yield put(actions.addUser.success());
			toast.success(i18n.t('message.lms.userManagement.user.add.success'));
		} else {
			// @ts-ignore
			const responseData = yield response.json();
			const message = responseData.message;
			toast.error(message);
			if (message === 'No access to user' || message === 'Failed to Fetch Add User API') {
				//
			}
			yield put(actions.addUser.failure({ error: responseData.message }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.user.add.failed'));
		yield put(actions.addUser.failure({ error: 'Failed to Add User' }));
	}
}

export default function* addUserSaga() {
	yield takeLatest(types.ADD_USER_REQUEST, addUser);
}
