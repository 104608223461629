import { createReducer } from 'typesafe-actions';
import { IssuesState } from './types';
import { IssuesAction } from './action';
import { fetchIssuesFlagsChartAsync } from './flagsChart/action';
import { fetchOverviewStatAsync } from './overviewStat/action';
import { fetchQuestionnaireStatAsync, downloadQuestionnaireStatAsync } from './questionnaireStat/action';
import { fetchIssuesFlagsOverviewAsync } from './flagsOverview/action';
import { fetchIssuesFlagStatAsync, downloadIssuesFlagStatAsync } from './issuesFlagStat/action';
import { fetchIssuesResolutionRateAsync } from './issuesResolutionRate/action';

const initialState: IssuesState = {
	/**
	 * Issues Daily Chart State
	 */
	issuesFlagsChart_loading: false,
	issuesFlagsChart_data: {
		scheduled: [],
		adhoc: [],
	},
	issuesFlagsChart_error: '',
	issuesResolutionRate: {
		data: {
			issuesReported: {
				unique: 0,
				recurred: 0,
				reopened: 0,
				total: 0,
			},
			issuesResolved: {
				unique: 0,
				recurred: 0,
				reopened: 0,
				total: 0,
			},
			issuesResolutionRate: 0,
		},
		isLoading: false,
		error: '',
	},
	/**
	 * Overview Stat State
	 */
	overviewStat_loading: false,
	overviewStat_data: {
		issuesCompleted: 0,
		prevIssuesCompleted: 0,
		issuesCompletedAvgTime: 0,
		prevIssuesCompletedAvgTime: 0,
		issuesCompletionRate: 'N/A',
		prevIssuesCompletionRate: 'N/A',
		issuesCreated: 0,
		prevIssuesCreated: 0,
		issuesDue: 0,
		prevIssuesDue: 0,
		issuesOverdue: 0,
		prevIssuesOverdue: 0,
		// Currently not in use
		issuesResolved: 0,
		prevIssuesResolved: 0,
		issuesResolvedAvgTime: 0,
		prevIssuesResolvedAvgTime: 0,
	},
	overviewStat_error: '',
	/**
	 * Questionnaire Stat State
	 */
	questionnaireStat_loading: false,
	questionnaireStat_data: [],
	questionnaireStat_error: '',
	questionnaireStat_dl_loading: false,
	questionnaireStat_dl_url: '',
	questionnaireStat_dl_error: '',
	/**
	 * Flags Overview State
	 */
	flagsOverview_loading: false,
	flagsOverview_data: {
		scheduled: {
			currentGreen: 0,
			previousGreen: 0,
			currentRed: 0,
			previousRed: 0,
			currentYellow: 0,
			previousYellow: 0,
			currentTotal: 0,
			previousTotal: 0,
		},
		adhoc: {
			currentGreen: 0,
			previousGreen: 0,
			currentRed: 0,
			previousRed: 0,
			currentYellow: 0,
			previousYellow: 0,
			currentTotal: 0,
			previousTotal: 0,
		},
	},
	flagsOverview_error: '',
	/**
	 * Issues Flag State
	 */
	issuesFlagStat_loading: false,
	issuesFlagStat_data: {
		scheduled: [],
		adhoc: [],
	},
	issuesFlagStat_error: '',
	issuesFlagStat_dl_loading: false,
	issuesFlagStat_dl_url: '',
	issuesFlagStat_dl_error: '',
};

export const issuesReducer = createReducer<IssuesState, IssuesAction>(initialState)
	.handleAction(fetchIssuesFlagsChartAsync.request, (state) => ({
		...state,
		issuesFlagsChart_loading: true,
	}))
	.handleAction(fetchIssuesFlagsChartAsync.success, (state, action) => ({
		...state,
		issuesFlagsChart_loading: false,
		issuesFlagsChart_data: action.payload,
		issuesFlagsChart_error: '',
	}))
	.handleAction(fetchIssuesFlagsChartAsync.failure, (state, action) => ({
		...state,
		issuesFlagsChart_loading: false,
		issuesFlagsChart_error: action.payload,
	}))
	.handleAction(fetchOverviewStatAsync.request, (state) => ({
		...state,
		overviewStat_loading: true,
	}))
	.handleAction(fetchOverviewStatAsync.success, (state, action) => ({
		...state,
		overviewStat_loading: false,
		overviewStat_data: action.payload,
		overviewStat_error: '',
	}))
	.handleAction(fetchOverviewStatAsync.failure, (state, action) => ({
		...state,
		overviewStat_loading: false,
		overviewStat_error: action.payload,
	}))
	.handleAction(fetchQuestionnaireStatAsync.request, (state) => ({
		...state,
		questionnaireStat_loading: true,
	}))
	.handleAction(fetchQuestionnaireStatAsync.success, (state, action) => ({
		...state,
		questionnaireStat_loading: false,
		questionnaireStat_data: action.payload,
		questionnaireStat_error: '',
	}))
	.handleAction(fetchQuestionnaireStatAsync.failure, (state, action) => ({
		...state,
		questionnaireStat_loading: false,
		questionnaireStat_error: action.payload,
	}))
	.handleAction(fetchIssuesFlagsOverviewAsync.request, (state) => ({
		...state,
		flagsOverview_loading: true,
	}))
	.handleAction(fetchIssuesFlagsOverviewAsync.success, (state, action) => ({
		...state,
		flagsOverview_loading: false,
		flagsOverview_data: action.payload,
		flagsOverview_error: '',
	}))
	.handleAction(fetchIssuesFlagsOverviewAsync.failure, (state, action) => ({
		...state,
		flagsOverview_loading: false,
		flagsOverview_error: action.payload,
	}))
	.handleAction(fetchIssuesFlagStatAsync.request, (state) => ({
		...state,
		issuesFlagStat_loading: true,
	}))
	.handleAction(fetchIssuesFlagStatAsync.success, (state, action) => ({
		...state,
		issuesFlagStat_loading: false,
		issuesFlagStat_data: action.payload,
		issuesFlagStat_error: '',
	}))
	.handleAction(fetchIssuesFlagStatAsync.failure, (state, action) => ({
		...state,
		issuesFlagStat_loading: false,
		issuesFlagStat_error: action.payload,
	}))
	.handleAction(downloadIssuesFlagStatAsync.request, (state) => ({
		...state,
		issuesFlagStat_dl_loading: true,
	}))
	.handleAction(downloadIssuesFlagStatAsync.success, (state, action) => ({
		...state,
		issuesFlagStat_dl_loading: false,
		issuesFlagStat_dl_url: action.payload,
		issuesFlagStat_dl_error: '',
	}))
	.handleAction(downloadIssuesFlagStatAsync.failure, (state, action) => ({
		...state,
		issuesFlagStat_dl_loading: false,
		issuesFlagStat_dl_url: '',
		issuesFlagStat_dl_error: action.payload,
	}))
	.handleAction(downloadQuestionnaireStatAsync.request, (state) => ({
		...state,
		questionnaireStat_dl_loading: true,
	}))
	.handleAction(downloadQuestionnaireStatAsync.success, (state, action) => ({
		...state,
		questionnaireStat_dl_loading: false,
		questionnaireStat_dl_url: action.payload,
		questionnaireStat_dl_error: '',
	}))
	.handleAction(downloadQuestionnaireStatAsync.failure, (state, action) => ({
		...state,
		questionnaireStat_dl_loading: false,
		questionnaireStat_dl_url: '',
		questionnaireStat_dl_error: action.payload,
	}))
	.handleAction(fetchIssuesResolutionRateAsync.request, (state) => ({
		...state,
		issuesResolutionRate: {
			...state.issuesResolutionRate,
			isLoading: true,
			error: '',
		},
	}))
	.handleAction(fetchIssuesResolutionRateAsync.failure, (state, action) => ({
		...state,
		issuesResolutionRate: {
			...state.issuesResolutionRate,
			error: action.payload,
			isLoading: false,
		},
	}))
	.handleAction(fetchIssuesResolutionRateAsync.success, (state, action) => ({
		...state,
		issuesResolutionRate: {
			...state.issuesResolutionRate,
			data: action.payload,
			isLoading: false,
		},
	}));
