import { createAsyncAction, action } from 'typesafe-actions';
import { SalesTargetPayload, SalesTargetBulkPayload, SalesPayload, SalesBulkPayload } from './type';
import * as types from './salesTarget.actionTypes';

export const showSalesTargetModal = (modalType: any, modalMode: any, site: any, department: string) => {
  return action(types.SHOW_MODAL, { modalType, modalMode, site, department });
};

export const dismissSalesTargetModal = () => {
  return action(types.DISMISS_MODAL);
};

export const setSalesTargetsPeriod = (month: number, year: number) => {
  return action(types.SET_PERIOD, { month, year });
};

export const setSalesTargetsFilterQuery = (text: string) => {
  return action(types.SET_FILTER_QUERY, { text });
};

export const clearSalesTargetsFilterQuery = () => {
  return action(types.CLEAR_FILTER_QUERY);
};

export const setSortSalesTarget = (newType: any, newOrder = null) => {
  return action(types.SET_SORT, { newType, newOrder });
};

export const resetBulk = () => action(types.RESET_BULK);

export const setSalesTargetScrollPosition = (newScroll: any) => {
  return action(types.SET_SCROLL, { newScroll });
};
export const setPageIndex = (value: number) => action(types.SET_PAGE_INDEX, { value });
// All
export const fetchPaginateSalestargetAsync = createAsyncAction(
  types.FETCH_PAGINATE_SALESTARGET_REQUEST,
  types.FETCH_PAGINATE_SALESTARGET_SUCCESS,
  types.FETCH_PAGINATE_SALESTARGET_FAILURE
)<undefined, { data: { value: any; key: string }[] | null; total: number }, { error: string }>();

// Sales
export const fetchSalesBySiteIdAsync = createAsyncAction(
  types.FETCH_SALES_BY_ID_REQUEST,
  types.FETCH_SALES_BY_ID_SUCCESS,
  types.FETCH_SALES_BY_ID_FAILURE
)<string, { data: { [key: string]: any } }, { error: string }>();

export const createSalesBySiteIdAsync = createAsyncAction(
  types.CREATE_SALES_BY_ID_REQUEST,
  types.CREATE_SALES_BY_ID_SUCCESS,
  types.CREATE_SALES_BY_ID_FAILURE
)<SalesPayload, { data: string; payload: SalesPayload }, { error: string }>();

export const updateSalesBySiteIdAsync = createAsyncAction(
  types.UPDATE_SALES_BY_ID_REQUEST,
  types.UPDATE_SALES_BY_ID_SUCCESS,
  types.UPDATE_SALES_BY_ID_FAILURE
)<any, { data: any }, { error: string }>();

export const createSalesBulkAsync = createAsyncAction(
  types.CREATE_SALES_BULK_REQUEST,
  types.CREATE_SALES_BULK_SUCCESS,
  types.CREATE_SALES_BULK_FAILURE
)<SalesBulkPayload, { data: string }, { error: string[] }>();

// Sales Target

export const fetchSalesTargetBySiteIdAsync = createAsyncAction(
  types.FETCH_SALESTARGET_BY_ID_REQUEST,
  types.FETCH_SALESTARGET_BY_ID_SUCCESS,
  types.FETCH_SALESTARGET_BY_ID_FAILURE
)<string, { data: { [key: string]: any } }, { error: string }>();

export const createSalesTargetBySiteIdAsync = createAsyncAction(
  types.CREATE_SALESTARGET_BY_ID_REQUEST,
  types.CREATE_SALESTARGET_BY_ID_SUCCESS,
  types.CREATE_SALESTARGET_BY_ID_FAILURE
)<SalesTargetPayload, { data: string; payload: SalesTargetPayload }, { error: string }>();

export const updateSalesTargetBySiteIdAsync = createAsyncAction(
  types.UPDATE_SALESTARGET_BY_ID_REQUEST,
  types.UPDATE_SALESTARGET_BY_ID_SUCCESS,
  types.UPDATE_SALESTARGET_BY_ID_FAILURE
)<Partial<SalesTargetPayload>, { data: any }, { error: string }>();

export const createSalesTargetBulkAsync = createAsyncAction(
  types.CREATE_SALESTARGET_BULK_REQUEST,
  types.CREATE_SALESTARGET_BULK_SUCCESS,
  types.CREATE_SALESTARGET_BULK_FAILURE
)<SalesTargetBulkPayload, { data: string }, { error: string[] }>();
