import { DashboardAuditorsPayload } from 'reducers/dashboard/fetch-types/auditors-fetch.types';
import { AuditorsDailyChartResponse } from 'reducers/dashboard/fetch-types/auditors-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type AuditorsDailyChart = AuditorsDailyChartResponse['data'];

export interface AuditorsDailyChartState {
  readonly dailyChart_loading: boolean;
  readonly dailyChart_data: AuditorsDailyChart;
  readonly dailyChart_error: string;
}

export const FETCH_AUDITORS_DAILY_CHART_REQUEST = '@@dashboard/auditors/FETCH_DAILY_CHART_REQUEST';
export const FETCH_AUDITORS_DAILY_CHART_SUCCESS = '@@dashboard/auditors/FETCH_DAILY_CHART_SUCCESS';
export const FETCH_AUDITORS_DAILY_CHART_FAILURE = '@@dashboard/auditors/FETCH_DAILY_CHART_FAILURE';

export type FetchAuditorsDailyChartPayload = DashboardAuditorsPayload;
export type FetchAuditorsDailyChartResponse = AuditorsDailyChartResponse | ErrorResponse;
