import { createAsyncAction } from 'typesafe-actions';
import {
  RankByCompletion,
  FETCH_RANK_BY_COMPLETION_REQUEST,
  FETCH_RANK_BY_COMPLETION_SUCCESS,
  FETCH_RANK_BY_COMPLETION_FAILURE,
  DOWNLOAD_RANK_BY_COMPLETION_REQUEST,
  DOWNLOAD_RANK_BY_COMPLETION_SUCCESS,
  DOWNLOAD_RANK_BY_COMPLETION_FAILURE,
  FetchRankByCompletionPayload,
  DownloadRankByCompletionPayload
} from './types';

export const fetchRankByCompletionAsync = createAsyncAction(
  FETCH_RANK_BY_COMPLETION_REQUEST,
  FETCH_RANK_BY_COMPLETION_SUCCESS,
  FETCH_RANK_BY_COMPLETION_FAILURE
)<FetchRankByCompletionPayload, RankByCompletion, string>();

export const downloadRankByCompletionAsync = createAsyncAction(
  DOWNLOAD_RANK_BY_COMPLETION_REQUEST,
  DOWNLOAD_RANK_BY_COMPLETION_SUCCESS,
  DOWNLOAD_RANK_BY_COMPLETION_FAILURE
)<DownloadRankByCompletionPayload, string, string>();
