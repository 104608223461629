import { CoursePaginateResponse, SyllabusPaginateResponse } from '@nimbly-technologies/nimbly-common';
import { ConfirmModalProps } from 'components/global/ConfirmationModal/ConfirmModal';
import { TableRow } from 'components/global/Table/Table.types';
import { LmsDataType, LmsViewType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';
import { action } from 'typesafe-actions';
import * as types from './lmsCsManagementPage.actionTypes';

export const setDataType = (dataType: LmsDataType) => action(types.SET_DATA_TYPE, { dataType });
export const setViewType = (viewType: LmsViewType) => action(types.SET_VIEW_TYPE, { viewType });
export const setSelectedCourses = (selectedCourses: CoursePaginateResponse[]) =>
	action(types.SET_SELECTED_COURSES, { selectedCourses });
export const setSelectedCourseRows = (selectedCourseRows: TableRow[]) =>
	action(types.SET_SELECTED_COURSE_ROWS, { selectedCourseRows });

export const setConfirmationModalConfig = (modalConfig: Partial<ConfirmModalProps>) =>
	action(types.SET_CONFIRMATION_MODAL_CONFIG, { modalConfig });
export const toggleConfirmationModal = (isOpen: boolean) => action(types.TOGGLE_CONFIRMATION_MODAL, { isOpen });
export const toggleBulkEditModal = (isOpen: boolean) => action(types.TOGGLE_BULK_EDIT_MODAL, { isOpen });
export const toggleBulkEditErrorModal = (isOpen: boolean) => action(types.TOGGLE_BULK_EDIT_ERROR_MODAL, { isOpen });

export const resetPageState = () => action(types.RESET_PAGE_STATE);

export const setSelectedSyllabus = (selectedSyllabus: SyllabusPaginateResponse[]) =>
	action(types.SET_SELECTED_SYLLABUS, { selectedSyllabus });
export const setSelectedSyllabusRows = (selectedSyllabusRow: TableRow[]) =>
	action(types.SET_SELECTED_SYLLABUS_ROWS, { selectedSyllabusRow });
