import { takeLatest, put, call, all } from 'redux-saga/effects';
import moment from 'moment';
import { getFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';

import { getToken } from 'reducers/api';
import { uploadImage } from '../../utils/file';
import * as types from 'reducers/company/company.actionTypes';
import * as actions from 'reducers/company/company.action';
import { fmcgURL, fmcgAuth } from 'config/baseURL';

const apiURL = fmcgURL;
const authToken = fmcgAuth || '';

type Action = {
  type: string;
  payload: any;
  meta: string | undefined;
};

export function* fetchAllCompany() {
  try {
    yield put(actions.setLoading(true));
    const firebaseToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchAllCompanyUrl = `${apiURL}/competitor/companies`;
    const request = () => fetch(fetchAllCompanyUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData: types.FETCH_CompaniesResponse = yield response.json();

      yield put(actions.fetchCompany.success(responseData.data));
      return responseData.data;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchCompany.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchCompany.success([]));
    yield put(actions.fetchCompany.failure({ error: 'Failed to Fetch Company List' }));
    return null;
  }
}

function* createCompany(action: Action) {
  try {
    const payload: types.POST_CompaniesPayload = { ...action.payload };
    const firebase = getFirebase();
    const createCompanyUrl = `${apiURL}/competitor/companies`;
    const firebaseToken = yield getToken();

    if (payload.logoFile && payload.logoFile.length > 0 && typeof payload.logoFile !== 'string') {
      const baseRef = `/competitorAnalysis/logo`;
      const uploadTasks = payload.logoFile.map((photo: any) => {
        if (typeof photo === 'string') {
          return Promise.resolve(photo);
        } else {
          const imageRef = `${baseRef}/${photo.name}-${moment().format('YYYY-MM-DD-X')}`;
          return uploadImage(firebase, imageRef, photo);
        }
      });
      const photoURLs = yield all(uploadTasks);
      payload.company.logo = photoURLs[0];
    } else {
      if (typeof payload.logoFile === 'string') {
        payload.company.logo = payload.logoFile as any;
      } else {
        payload.company.logo = '';
      }
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: firebaseToken
      },
      body: JSON.stringify(payload.company)
    };

    const request = () => fetch(createCompanyUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseJson: types.POST_CompaniesResponse = yield response.json();
      yield put(actions.createCompany.success(responseJson.data.name));
      toast.success(`Success create company ${responseJson.data.name}`);
    } else {
      const responseJson: types.POST_CompaniesResponse = yield response.json();
      yield put(actions.createCompany.failure({ error: responseJson.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.createCompany.failure({ error: 'Failed to Create Company' }));
    return null;
  }
}

function* createCompanyBulk(action: Action) {
  try {
    const payload: types.POST_BulkCompaniesPayload = action.payload;
    let success = [];
    let error = [];
    const firebaseToken = yield getToken();

    for (let i = 0; i < payload.length; i++) {
      const companyPayload = payload[i];
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: firebaseToken
        },
        body: JSON.stringify(companyPayload)
      };

      const createCompanyUrl = `${apiURL}/competitor/companies`;

      const request = () => fetch(createCompanyUrl, options);
      const response = yield call(request);

      if (response && response.status === 200) {
        const responseJson: types.POST_CompaniesResponse = yield response.json();
        yield put(actions.createCompany.success(responseJson.data.name));
        success.push(i);
      } else {
        const responseJson = yield response.json();
        error.push(responseJson.message);
      }
    }
    if (success.length === payload.length) {
      yield put(actions.createCompanyBulk.success());
      toast.success(`Success create ${payload.length} companies `);
    } else {
      yield put(actions.createCompany.failure({ error: error.join(' ') }));
      return null;
    }
  } catch (e) {
    yield put(actions.createCompany.failure({ error: 'Failed to Create Company' }));
    return null;
  }
}

export function* fetchCompanyDetail(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const firebaseToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchCompanyDetail = `${apiURL}/competitor/companies/${action.payload}`;
    const request = () => fetch(fetchCompanyDetail, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData: types.FETCH_CompanyDetailResponse = yield response.json();
      yield put(actions.fetchCompanyDetail.success(responseData.data));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchCompanyDetail.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchCompanyDetail.failure({ error: 'Failed to Fetch Company Detail' }));
    return null;
  }
}

function* updateCompany(action: Action) {
  try {
    const payload: types.PUT_CompaniesPayload = { ...action.payload };
    const firebase = getFirebase();
    const updateCompanyUrl = `${apiURL}/competitor/companies/${payload.company.code}`;
    const firebaseToken = yield getToken();
    if (payload.logoFile && payload.logoFile.length > 0 && typeof payload.logoFile !== 'string') {
      const baseRef = `/competitorAnalysis/logo`;
      const uploadTasks = payload.logoFile.map((photo: any) => {
        if (typeof photo === 'string') {
          return Promise.resolve(photo);
        } else {
          const imageRef = `${baseRef}/${photo.name}-${moment().format('YYYY-MM-DD-X')}`;
          return uploadImage(firebase, imageRef, photo);
        }
      });
      const photoURLs = yield all(uploadTasks);
      payload.company.logo = photoURLs[0];
    } else {
      payload.company.logo = payload.logoFile as any;
    }

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: firebaseToken
      },
      body: JSON.stringify(payload.company)
    };

    const request = () => fetch(updateCompanyUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseJson: types.PUT_CompaniesResponse = yield response.json();
      yield put(actions.updateCompany.success(responseJson.data.name));
      toast.success(`Success update company ${responseJson.data.name}`);
    } else {
      const responseData = yield response.json();
      yield put(actions.updateCompany.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.updateCompany.failure({ error: 'Failed to Update Company' }));
    return null;
  }
}

export function* fetchCompanyProductsSaga(action: Action) {
  try {
    const payload: types.FETCH_CompanyProductsPayload = { ...action.payload };
    const firebaseToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchCompanyProductUrl = `${apiURL}/competitor/companies/${payload.companyCode}/products`;
    const request = () => fetch(fetchCompanyProductUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData: types.FETCH_CompanyProductsResponse = yield response.json();

      yield put(actions.fetchCompanyProducts.success(responseData.data));
      return responseData.data;
    } else {
      const responseData: types.FETCH_CompanyProductsResponse = yield response.json();
      yield put(actions.fetchCompanyProducts.failure(responseData.message));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchCompanyProducts.failure('Failed to Fetch Product List'));
    return null;
  }
}

export function* fetchCompanyPromotionsSaga(action: Action) {
  try {
    const payload: any = { ...action.payload };
    const firebaseToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchCompanyPromotionUrl = `${apiURL}/competitor/promotions?company-id=${payload.companyCode}`;
    const request = () => fetch(fetchCompanyPromotionUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData: any = yield response.json();

      yield put(actions.fetchCompanyPromotions.success(responseData.data));
      return responseData.data;
    } else {
      const responseData: any = yield response.json();
      yield put(actions.fetchCompanyPromotions.failure(responseData.message));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchCompanyPromotions.failure('Failed to Fetch Product List'));
    return null;
  }
}

export default function* journeyPlansSaga() {
  yield takeLatest(types.FETCH_COMPANIES_REQUEST, fetchAllCompany);
  yield takeLatest(types.CREATE_COMPANIES_REQUEST, createCompany);
  yield takeLatest(types.FETCH_COMPANY_DETAIL_REQUEST, fetchCompanyDetail);
  yield takeLatest(types.UPDATE_COMPANY_REQUEST, updateCompany);
  yield takeLatest(types.CREATE_COMPANIES_BULK_REQUEST, createCompanyBulk);
  yield takeLatest(types.FETCH_COMPANY_PRODUCTS_REQUEST, fetchCompanyProductsSaga);
  yield takeLatest(types.FETCH_COMPANY_PROMOTIONS_REQUEST, fetchCompanyPromotionsSaga);
}
