import { apiURL } from 'config/baseURL';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/departmentEditor/departmentEditor.action';
import * as types from 'reducers/departmentEditor/departmentEditor.actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { removeItemSessionStorageCache } from '../../helpers/sessionStorageHelper';

const DEPARTMENTS = 'DEPARTMENTS';

type Action = {
  type: string;
  payload: { [key: string]: string };
  meta: string | undefined;
};

function* createDepartment(action: Action) {
  try {
    const authToken = yield getToken();
    const body = action.payload.data;

    const options = {
      method: 'POST',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    const createDepartmentIndexURL = `${apiURL}/departments/`;
    const request = () => fetch(createDepartmentIndexURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      yield put(actions.createDepartment.success());
      toast.success(i18n.t('message.departmentPage.create.success'));
    } else {
      const responseData = yield response.json();
      const message = responseData.message;
      toast.error(message);
      if (message === 'No access to department' || message === 'Failed to Fetch Department API') {
      }
      yield put(actions.createDepartment.failure({ error: responseData.message }));
    }
  } catch (e) {
    toast.error(i18n.t('message.departmentPage.create.failed'));
    yield put(actions.createDepartment.failure({ error: 'Failed to create Department' }));
  }
}

function* updateDepartment(action: Action) {
  try {
    const deptID = action.payload.deptID;
    const body = Object.assign({}, action.payload.data, { replaceSupervisors: action.payload.assignSupervisor });
    const authToken = yield getToken();
    const options = {
      method: 'PUT',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    const updateDepartmentIndexURL = `${apiURL}/departments/index/${deptID}`;
    const request = () => fetch(updateDepartmentIndexURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      removeItemSessionStorageCache(DEPARTMENTS);
      yield put(actions.updateDepartment.success());
      toast.success(i18n.t('message.departmentPage.update.success'));
    } else if (response && response.status === 409) {
      yield put(actions.updateDepartment.failure({ error: i18n.t('message.departmentPage.update.deptExist') }));
      toast.error(i18n.t('message.departmentPage.update.deptExist'));
    } else {
      const responseData = yield response.json();
      if (responseData.data && Array.isArray(responseData.data)) {
        yield put(actions.setShowModal(true));
        yield put(actions.updateDepartment.failure({ error: responseData.message, errorData: responseData.data }));
        return;
      }
      yield put(actions.updateDepartment.failure({ error: responseData.message }));
    }
  } catch (e) {
    toast.error(i18n.t('message.departmentPage.update.failed'));
    yield put(actions.updateDepartment.failure({ error: 'Failed to update Department' }));
  }
}

export default function* departmentEditorSaga() {
  yield takeLatest(types.UPDATE_DEPARTMENT_REQUEST, updateDepartment);
  yield takeLatest(types.CREATE_DEPARTMENT_REQUEST, createDepartment);
}
