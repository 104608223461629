import { takeLatest, put, call } from 'redux-saga/effects';
import moment from 'moment';
import { getFirebase } from 'react-redux-firebase';

import { getToken } from 'reducers/api';
import { uploadImage } from '../../utils/file';
import * as types from 'reducers/competitorExport/competitorExport.actionTypes';
import * as actions from 'reducers/competitorExport/competitorExport.action';
import { fmcgURL, fmcgAuth } from 'config/baseURL';

const apiURL = fmcgURL;

type Action = {
  type: string;
  payload: any;
  meta: string | undefined;
};

export function* fetchExportList(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield getToken();
    const payload = action.payload;

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    //process payload
    let fetchExportList = `${apiURL}/competitor/${payload.type}s/export`;
    fetchExportList += `?start_from=${payload.startFrom}&end_to=${payload.endTo}`;

    const request = () => fetch(fetchExportList, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchExportList.success({ data: responseData.data }));
      return responseData.data;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchExportList.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchExportList.success({ data: [] }));
    yield put(actions.fetchExportList.failure({ error: 'Failed to Fetch Export List' }));
    return null;
  }
}

function* createDownloadAct(action: Action) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const firebase = getFirebase();
    const createDownloadAct = `${apiURL}/competitor/download`;

    if (payload.excel) {
      const baseRef = `/competitorAnalysis/journeyDownloadManager`;
      const imageRef = `${baseRef}/${payload.excel.name}${moment().format('YYYY-MM-DD-X')}`;
      const urlRef = yield uploadImage(firebase, imageRef, payload.excel);
      payload.urlRef = urlRef;
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(payload)
    };

    const request = () => fetch(createDownloadAct, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.createDownloadAct.success({ data: responseData.data }));
    } else {
      const responseData = yield response.json();
      yield put(actions.createDownloadAct.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.createDownloadAct.failure({ error: 'Failed to Create Download Activity' }));
    return null;
  }
}

export function* fetchDownloadAct(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchDownloadActUrl = `${apiURL}/competitor/download`;
    const request = () => fetch(fetchDownloadActUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchDownloadAct.success({ data: responseData.data }));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchDownloadAct.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchDownloadAct.success({ data: null }));
    yield put(actions.fetchDownloadAct.failure({ error: 'Failed to Fetch Download Activity' }));
    return null;
  }
}

export default function* competitorExportSaga() {
  yield takeLatest(types.FETCH_DOWNLOAD_ACT_REQUEST, fetchDownloadAct);
  yield takeLatest(types.FETCH_EXPORT_REQUEST, fetchExportList);
  yield takeLatest(types.CREATE_DOWNLOAD_ACT_REQUEST, createDownloadAct);
}
