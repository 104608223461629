import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SiteReportSummary, Questionnaire } from 'nimbly-common';
import moment from 'moment';

import API from 'helpers/api';
import { apiURL } from 'config/baseURL';
import { siteReportActions as actions } from 'reducers/siteReports/siteReport.action';
import { queryStringify } from 'utils/router';
import { RootState } from 'store/rootReducers';
import { SiteReportState, SkuReportSummary } from 'reducers/siteReports/siteReport.types';

const selectSiteReport = (state: RootState) => state.siteReports;

let newSortBy = 'color-red';

// eslint-disable-next-line complexity
function* fetchSiteReports({ payload }: ReturnType<typeof actions.fetchSiteReports.request>) {
	const { siteID, startDate, endDate, type, questionnaires, auditors, departments, pageIndex, sortBy } = payload;
	const reportState: SiteReportState = yield select(selectSiteReport);
	const token: string = yield call(API.getFirebaseToken);

	let skuSortBy = '';
	let sortDirections = 'asc';
	switch (sortBy) {
		case 'questionnaireTitle':
			skuSortBy = 'ScheduleName';
			sortDirections = reportState.questionnaireTitle;
			break;
		case 'auditorName':
			skuSortBy = 'AuditorName';
			sortDirections = reportState.auditorName;
			break;
		case 'createdAt':
			skuSortBy = 'ReportDate';
			sortDirections = reportState.createdAt;
			break;
		case 'checkInAt':
			skuSortBy = 'CheckIn';
			sortDirections = reportState.checkInAt;
			break;
		case 'checkOutAt':
			skuSortBy = 'CheckOut';
			sortDirections = reportState.checkOutAt;
			break;
		case 'scoreWeighted':
			skuSortBy = 'ScheduleName';
			sortDirections = reportState.scoreWeighted;
			break;
		case 'isAdhoc':
			skuSortBy = 'ReportType';
			sortDirections = reportState.isAdhoc;
			break;
		case 'timeDifference':
			skuSortBy = 'Duration';
			sortDirections = reportState.timeDifference;
			break;
		case 'colorSort': {
			skuSortBy = 'ReportType';
			const colorNames: { [key: number]: string } = {
				0: 'colorYellow',
				1: 'colorGreen',
				2: 'colorRed',
			};
			sortDirections = reportState.colorSort;
			newSortBy = colorNames[reportState.color];
			break;
		}
		default:
			break;
	}

	let query;
	let url;

	if (type === 'reportSchedule') {
		query = queryStringify(
			{
				startDate: startDate ? moment(startDate!.startOf('day')).format() : '',
				endDate: endDate ? moment(endDate!.endOf('day')).format() : '',
				limit: 15,
				page: pageIndex,
				questionnaires,
				auditors,
				sortBy: sortBy === 'colorSort' ? newSortBy : sortBy,
				sortType: sortDirections,
			},
			undefined,
			true,
		);
		url = `${apiURL}/reports/new-summary/site/${siteID}?${query}`;
	} else {
		query = queryStringify(
			{
				startDate: startDate ? moment(startDate!.startOf('day')).format() : '',
				endDate: endDate ? moment(endDate!.endOf('day')).format() : '',
				limit: 15,
				sortBy: skuSortBy,
				sortType: sortDirections,
				page: pageIndex,
				scheduleIds: questionnaires,
				auditors,
			},
			undefined,
			true,
		);
		url = `${apiURL}/skus/reports/site/${siteID}?${query}`;
	}

	try {
		const response: Response = yield call(API.get, url, token);

		const contentType = response.headers.get('content-type');
		if (!contentType || !contentType.includes('application/json')) {
			yield put(
				actions.fetchSiteReports.failure({
					error: 'internal',
				}),
			);
			return;
		}

		const json = yield call(response.json.bind(response));

		if (response.status >= 400) {
			const message = json.message;
			yield put(
				actions.fetchSiteReports.failure({
					error: message,
				}),
			);
		}

		const responseData = json.data;
		yield put(
			actions.fetchSiteReports.success({
				reports: type === 'reportSchedule' && responseData.data ? responseData.data : [],
				skuReports: type === 'inventory' && responseData.data ? responseData.data : [],
				total: responseData.total || 0,
			}),
		);
	} catch (error) {
		yield put(
			actions.fetchSiteReports.failure({
				error: error.message,
			}),
		);
	}
}

export default function* siteReportsSaga() {
	yield takeLatest(actions.fetchSiteReports.request, fetchSiteReports);
}
