import { apiURL } from 'config/baseURL';
import { getToken } from 'reducers/api';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import * as actions from '../../reducers/usersCompact/usersCompact.action';

export function* fetchUsersCompactSaga({ payload }: ReturnType<typeof actions.fetchUsersCompact.request>) {
	try {
		const authToken = yield* call(getToken);
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const projectionsQuery = payload.projection.reduce(
			(prev: Array<string[]>, value) => [...prev, ['projection', value]],
			[],
		);
		const query: Record<string, string> = {
			withDepartmentAccess: String(payload.withDepartmentAccess || false),
		};
		const queryStr = new URLSearchParams([...Object.entries(query), ...projectionsQuery]).toString();

		const fetchUsersCompactUrl = `${apiURL}/users/compact?${queryStr}`;
		const response = yield* call(fetch, fetchUsersCompactUrl, options);
		const responseJson = yield response.json();

		if (response?.status !== 200) {
			yield put(actions.fetchUsersCompact.failure({ error: responseJson.message }));
			return;
		}

		const usersCompact = responseJson.data;
		yield put(actions.fetchUsersCompact.success({ usersCompact }));
	} catch (e) {
		yield put(actions.fetchUsersCompact.failure({ error: 'Failed to fetch sku compact' }));
		return null;
	}
}

export default function* usersCompactSaga() {
	yield all([takeEvery(actions.fetchUsersCompact.request.toString(), fetchUsersCompactSaga)]);
}
