import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './skuUom.action';
import { SkuUomFetchState, SkuUomState } from './type.d';

const initialState: SkuUomState = {
	fetchState: SkuUomFetchState.LOADING,
	skuUoms: [],
};

export type SkuUomAction = ActionType<typeof actions>;
export const skuUomReducer = createReducer<SkuUomState, SkuUomAction>(initialState)
	.handleAction(actions.fetchSkuUoms.request, (state) => ({
		fetchState: SkuUomFetchState.LOADING,
		skuUoms: [],
		errorMessage: '',
	}))
	.handleAction(actions.fetchSkuUoms.success, (state, action) => ({
		fetchState: SkuUomFetchState.SUCCESS,
		skuUoms: action.payload,
		errorMessage: '',
	}))
	.handleAction(actions.fetchSkuUoms.failure, (state, action) => ({
		fetchState: SkuUomFetchState.FAILURE,
		skuUoms: [],
		errorMessage: action.payload,
	}));
