import { createAsyncAction } from 'typesafe-actions';
import {
  ManyUsersChart,
  FETCH_MANY_USERS_CHART_REQUEST,
  FETCH_MANY_USERS_CHART_SUCCESS,
  FETCH_MANY_USERS_CHART_FAILURE,
  FetchManyUsersChartPayload
} from './types';

export const fetchManyUsersChartAsync = createAsyncAction(
  FETCH_MANY_USERS_CHART_REQUEST,
  FETCH_MANY_USERS_CHART_SUCCESS,
  FETCH_MANY_USERS_CHART_FAILURE
)<FetchManyUsersChartPayload, ManyUsersChart, string>();
