import { call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchRankByFlags,
  fetchFlagsChart,
  fetchIssueList,
  fetchRankByCompletion,
  fetchFlagsOverview,
  downloadRankByCompletion,
  DownloadRankByCompletionResponse,
  downloadRankByFlags,
  DownloadRankByFlagsResponse,
  downloadIssueList,
  DownloadIssueListResponse
} from './helpers/sites';
import { FETCH_SITES_FLAGS_CHART_REQUEST, FetchFlagsChartResponse } from 'reducers/dashboard/sites/flagsChart/types';
import {
  FETCH_RANK_BY_FLAGS_REQUEST,
  DOWNLOAD_RANK_BY_FLAGS_REQUEST,
  FetchRankByFlagsResponse
} from 'reducers/dashboard/sites/rankByFlags/types';
import {
  FETCH_ISSUE_LIST_REQUEST,
  DOWNLOAD_ISSUE_LIST_REQUEST,
  FetchSiteIssueStatListResponse
} from 'reducers/dashboard/sites/issueList/types';
import {
  FETCH_RANK_BY_COMPLETION_REQUEST,
  DOWNLOAD_RANK_BY_COMPLETION_REQUEST,
  FetchRankByCompletionResponse
} from 'reducers/dashboard/sites/rankByCompletion/types';
import { FETCH_SITES_FLAGS_OVERVIEW_REQUEST } from 'reducers/dashboard/sites/flagsOverview/types';
import { fetchRankByFlagsAsync, downloadRankByFlagsAsync } from 'reducers/dashboard/sites/rankByFlags/action';
import { fetchIssueListAsync, downloadIssueListAsync } from 'reducers/dashboard/sites/issueList/action';
import {
  fetchRankByCompletionAsync,
  downloadRankByCompletionAsync
} from 'reducers/dashboard/sites/rankByCompletion/action';
import { fetchSitesFlagsChartAsync } from 'reducers/dashboard/sites/flagsChart/action';
import { fetchSitesFlagsOverviewAsync } from 'reducers/dashboard/sites/flagsOverview/action';
import { FetchFlagsOverviewResponse } from 'reducers/dashboard/overview/cardFlag/actionType';

export function* fetchRankByFlagsSaga(action: ReturnType<typeof fetchRankByFlagsAsync.request>): Generator {
  try {
    const fetchRes: FetchRankByFlagsResponse = (yield call(fetchRankByFlags, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchRankByFlagsAsync.success(fetchRes.data));
    } else {
      yield put(fetchRankByFlagsAsync.failure('FETCH FLAG LIST FAILED'));
    }
  } catch (e) {
    yield put(fetchRankByFlagsAsync.failure('FETCH FLAG LIST FAILED'));
  }
}

export function* fetchIssueListSaga(action: ReturnType<typeof fetchIssueListAsync.request>): Generator {
  try {
    const fetchRes: FetchSiteIssueStatListResponse = (yield call(fetchIssueList, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchIssueListAsync.success(fetchRes.data));
    } else {
      yield put(fetchIssueListAsync.failure('FETCH ISSUE LIST FAILED'));
    }
  } catch (e) {
    yield put(fetchIssueListAsync.failure('FETCH ISSUE LIST FAILED'));
  }
}

export function* fetchRankByCompletionSaga(action: ReturnType<typeof fetchRankByCompletionAsync.request>): Generator {
  try {
    const fetchRes: FetchRankByCompletionResponse = (yield call(fetchRankByCompletion, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchRankByCompletionAsync.success(fetchRes.data));
    } else {
      yield put(fetchRankByCompletionAsync.failure('FETCH REPORT LIST FAILED'));
    }
  } catch (e) {
    yield put(fetchRankByCompletionAsync.failure('FETCH REPORT LIST FAILED'));
  }
}

export function* fetchFlagsChartSaga(action: ReturnType<typeof fetchSitesFlagsChartAsync.request>): Generator {
  try {
    const fetchRes: FetchFlagsChartResponse = (yield call(fetchFlagsChart, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchSitesFlagsChartAsync.success(fetchRes.data));
    } else {
      yield put(fetchSitesFlagsChartAsync.failure('FETCH FLAGS CHART FAILED'));
    }
  } catch (e) {
    yield put(fetchSitesFlagsChartAsync.failure('FETCH FLAGS CHART FAILED'));
  }
}

export function* fetchFlagsOverviewSaga(action: ReturnType<typeof fetchSitesFlagsOverviewAsync.request>): Generator {
  try {
    const fetchRes: FetchFlagsOverviewResponse = (yield call(fetchFlagsOverview, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchSitesFlagsOverviewAsync.success(fetchRes.data));
    } else {
      yield put(fetchSitesFlagsOverviewAsync.failure('FETCH COMPLETION OVERVIEW FAILED'));
    }
  } catch (e) {
    yield put(fetchSitesFlagsOverviewAsync.failure('FETCH COMPLETION OVERVIEW FAILED'));
  }
}

export function* downloadRankByCompletionSaga(
  action: ReturnType<typeof downloadRankByCompletionAsync.request>
): Generator {
  try {
    const fetchRes: DownloadRankByCompletionResponse = (yield call(downloadRankByCompletion, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(downloadRankByCompletionAsync.success(fetchRes.url));
    } else {
      yield put(downloadRankByCompletionAsync.failure('DOWNLOAD SITES RANK BY COMPLETION FAILED'));
    }
  } catch (e) {
    yield put(downloadRankByCompletionAsync.failure('DOWNLOAD SITES RANK BY COMPLETION FAILED'));
  }
}

export function* downloadRankByFlagsSaga(action: ReturnType<typeof downloadRankByFlagsAsync.request>): Generator {
  try {
    const fetchRes: DownloadRankByFlagsResponse = (yield call(downloadRankByFlags, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(downloadRankByFlagsAsync.success(fetchRes.url));
    } else {
      yield put(downloadRankByFlagsAsync.failure('DOWNLOAD SITES RANK BY FLAGS FAILED'));
    }
  } catch (e) {
    yield put(downloadRankByFlagsAsync.failure('DOWNLOAD SITES RANK BY FLAGS FAILED'));
  }
}

export function* downloadIssuesListSaga(action: ReturnType<typeof downloadIssueListAsync.request>): Generator {
  try {
    const fetchRes: DownloadIssueListResponse = (yield call(downloadIssueList, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(downloadIssueListAsync.success(fetchRes.url));
    } else {
      yield put(downloadIssueListAsync.failure('DOWNLOAD SITES ISSUES LIST FAILED'));
    }
  } catch (e) {
    yield put(downloadIssueListAsync.failure('DOWNLOAD SITES ISSUES LIST FAILED'));
  }
}

export default function* sitesSagaWatcher() {
  yield takeLatest(FETCH_RANK_BY_FLAGS_REQUEST, fetchRankByFlagsSaga);
  yield takeLatest(FETCH_ISSUE_LIST_REQUEST, fetchIssueListSaga);
  yield takeLatest(FETCH_RANK_BY_COMPLETION_REQUEST, fetchRankByCompletionSaga);
  yield takeLatest(FETCH_SITES_FLAGS_CHART_REQUEST, fetchFlagsChartSaga);
  yield takeLatest(FETCH_SITES_FLAGS_OVERVIEW_REQUEST, fetchFlagsOverviewSaga);
  yield takeLatest(DOWNLOAD_RANK_BY_COMPLETION_REQUEST, downloadRankByCompletionSaga);
  yield takeLatest(DOWNLOAD_RANK_BY_FLAGS_REQUEST, downloadRankByFlagsSaga);
  yield takeLatest(DOWNLOAD_ISSUE_LIST_REQUEST, downloadIssuesListSaga);
}
