import { SyllabusDetailResponse } from '@nimbly-technologies/nimbly-common';
import { call, takeEvery } from 'redux-saga/effects';
import { fetchLmsSyllabusesById } from 'services/lms/syllabuses/syllabuses.service';
import { put } from 'typed-redux-saga';
import * as actions from './syllabuses.action';

function* fetchLmsSyllabusesByIdSaga(
	action: ReturnType<typeof actions.fetchLmsSyllabusesByIdAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLmsSyllabusesById, action.payload) as unknown) as SyllabusDetailResponse;
		yield put(actions.fetchLmsSyllabusesByIdAsync.success(res));
	} catch {
		yield put(actions.fetchLmsSyllabusesByIdAsync.failure('Failed to get syllabuses details'));
	}
}

export default function* lmsSyllabusesSaga() {
	yield takeEvery(actions.fetchLmsSyllabusesByIdAsync.request, fetchLmsSyllabusesByIdSaga);
}
