export const SET_VIEW_TYPE = '@lms/SET_VIEW_TYPE';
export const SET_SELECTED_QUIZZES = '@lms/SET_SELECTED_QUIZZES';
export const SET_CONFIRMATION_MODAL_CONFIG = '@lms/SET_CONFIRMATION_MODAL_CONFIG';

export const SET_SELECTED_QUIZ_ROWS = '@lms/SET_SELECTED_QUIZ_ROWS';
export const TOGGLE_CONFIRMATION_MODAL = '@lms/TOGGLE_CONFIRMATION_MODAL';
export const TOGGLE_BULK_EDIT_MODAL = '@lms/TOGGLE_BULK_EDIT_MODAL';
export const TOGGLE_BULK_EDIT_ERROR_MODAL = '@lms/TOGGLE_BULK_EDIT_ERROR_MODAL';
export const TOGGLE_IS_SELECT_MODE = '@lms/TOGGLE_IS_SELECT_MODE';

export const RESET_PAGE_STATE = '@lms/RESET_PAGE_STATE';
