import { createAsyncAction } from 'typesafe-actions';
import * as types from './userRoles.actionTypes';
import { userRoleI } from './type';
export const userRolesAction = createAsyncAction(
	types.FETCH_USER_ROLES_REQUEST,
	types.FETCH_USER_ROLES_SUCCESS,
	types.FETCH_USER_ROLES_FAILURE,
)<
	{
		withLMS?: boolean;
	},
	{ data: userRoleI[]; message: string },
	{ message: string }
>();
