import { AuditorsDailyChart, FetchAuditorsDailyChartPayload } from 'reducers/dashboard/auditors/dailyChart/types';

import orgReportsSubmitted from '../../../assets/dummy/json/org-number.json';
import orgReportsDuration from '../../../assets/dummy/json/org-time.json';

import user1ReportsSubmitted from '../../../assets/dummy/json/auditor1-number.json';
import user2ReportsSubmitted from '../../../assets/dummy/json/auditor2-number.json';
import user3ReportsSubmitted from '../../../assets/dummy/json/auditor3-number.json';
import user4ReportsSubmitted from '../../../assets/dummy/json/auditor4-number.json';
import user5ReportsSubmitted from '../../../assets/dummy/json/auditor5-number.json';

import user1ReportsDuration from '../../../assets/dummy/json/auditor1-time.json';
import user2ReportsDuration from '../../../assets/dummy/json/auditor2-time.json';
import user3ReportsDuration from '../../../assets/dummy/json/auditor3-time.json';
import user4ReportsDuration from '../../../assets/dummy/json/auditor4-time.json';
import user5ReportsDuration from '../../../assets/dummy/json/auditor5-time.json';
import * as Common from 'nimbly-common';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

const usersReportSubmitted = [
  user1ReportsSubmitted,
  user2ReportsSubmitted,
  user3ReportsSubmitted,
  user4ReportsSubmitted,
  user5ReportsSubmitted
];

const usersReportDuration = [
  user1ReportsDuration,
  user2ReportsDuration,
  user3ReportsDuration,
  user4ReportsDuration,
  user5ReportsDuration
];

const usersName = ['Herby Herado', 'Restu Utomo', 'Samuel Sandro', 'Krisnata Pandji', 'Komang Mahendra'];

const dummyAuditorsDailyChart_DAILY: AuditorsDailyChart['daily'] = orgReportsSubmitted.map((completedNum, index) => {
  // DAILY SUMMARY
  const isEvenIdx = index % 2 === 0;
  const duration = orgReportsDuration[index] * 60 || 20;
  const date = index < 10 ? `2020-03-0${index}` : `2020-03-${index}`;
  const dummySchedule = completedNum + (completedNum % 5);

  const users = usersReportSubmitted.map((user, userIndex) => {
    const dummyUserSchedule = user[index] ? user[index] + (user[index] % 5) : 0;
    return {
      userID: `user_${userIndex + 1}`,
      userName: usersName[userIndex],
      completedNum: user[index] || 0,
      adhocNum: user[index] % 3,
      duration: usersReportDuration[userIndex][index] * 60,
      adhocDuration: (usersReportDuration[userIndex][index] * 60) % 5740,
      scheduledNum: dummyUserSchedule,
      previousScheduled: 0,
      previousCompleted: 0,
      previousDuration: 0,
      previousAdhocCompleted: 0,
      previousAdhocDuration: 0,
      totalMadeUp: 0
    };
  });

  const filteredUser = users.filter(user => user.completedNum !== 0);
  const filteredUserObj: { [userID: string]: any } = {};
  filteredUser.forEach(user => {
    if (!filteredUserObj[user.userID]) {
      filteredUserObj[user.userID] = user;
    }
  });

  return {
    date,
    totalAdhocCompleted: isEvenIdx ? Math.ceil(completedNum / 2) : Math.floor(completedNum * 2),
    totalAdhocDuration: isEvenIdx ? Math.ceil(duration / 2) : Math.floor(duration * 2),
    totalCompleted: completedNum,
    totalDuration: duration,
    totalScheduled: dummySchedule,
    users: filteredUserObj,
    previousScheduled: 0,
    previousCompleted: 0,
    previousDuration: 0,
    previousAdhocCompleted: 0,
    previousAdhocDuration: 0,
    totalMadeUp: 0
  };
});

/**
 * Also used for Auditors `ManyUsers` Chart DUMMY
 */
export const getDummyAuditorsDailyChart = (payload: FetchAuditorsDailyChartPayload) => {
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let selectedUsersDailyData: AuditorsDailyChart['daily'] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    selectedUsersDailyData = dummyAuditorsDailyChart_DAILY.slice(-diffInDay);
  } else {
    selectedUsersDailyData = dummyAuditorsDailyChart_DAILY.slice(0, diffInDay);
  }

  const reduced_selectedUsersDailyData = selectedUsersDailyData.reduce((curr, acc) => ({
    date: '',
    totalAdhocCompleted: curr.totalAdhocCompleted + acc.totalAdhocCompleted,
    totalAdhocDuration: curr.totalAdhocDuration + acc.totalAdhocDuration,
    totalCompleted: curr.totalCompleted + acc.totalCompleted,
    totalDuration: curr.totalDuration + acc.totalDuration,
    totalScheduled: curr.totalScheduled + acc.totalScheduled,
    users: {},

    previousScheduled: curr.previousScheduled + acc.previousScheduled,
    previousCompleted: curr.previousCompleted + acc.previousCompleted,
    previousDuration: curr.previousDuration + acc.previousDuration,
    previousAdhocCompleted: curr.previousAdhocCompleted + acc.previousAdhocCompleted,
    previousAdhocDuration: curr.previousAdhocDuration + acc.previousAdhocDuration,
    totalMadeUp: 0
  }));

  const dummyAuditorsDailyChart_OVERVIEW: AuditorsDailyChart['overview'] = {
    currentCompleted: reduced_selectedUsersDailyData.totalCompleted,
    currentDuration: reduced_selectedUsersDailyData.totalDuration,
    currentScheduled: reduced_selectedUsersDailyData.totalScheduled,
    previousCompleted:
      reduced_selectedUsersDailyData.totalCompleted % 2
        ? reduced_selectedUsersDailyData.totalCompleted * 0.75
        : reduced_selectedUsersDailyData.totalCompleted * 1.25,
    previousDuration:
      reduced_selectedUsersDailyData.totalDuration % 2
        ? reduced_selectedUsersDailyData.totalDuration * 0.6
        : reduced_selectedUsersDailyData.totalDuration * 1.05,
    previousScheduled:
      reduced_selectedUsersDailyData.totalScheduled % 2
        ? reduced_selectedUsersDailyData.totalScheduled * 1
        : reduced_selectedUsersDailyData.totalScheduled * 1.15
  };

  const dummyAuditorsDailyChart: AuditorsDailyChart = {
    overview: dummyAuditorsDailyChart_OVERVIEW,
    daily: selectedUsersDailyData
  };

  return dummyAuditorsDailyChart;
};
