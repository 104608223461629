import { ActionType, createReducer } from 'typesafe-actions';
import { IUpdateCourse } from './type';
import * as actions from './updateCourse.action';
import * as types from './updateCourse.actionTypes';

export type UpdateCourseAction = ActionType<typeof actions>;

export const initialState: IUpdateCourse = {
	data: null,
	isLoading: false,
	isSuccess: false,
	errorMessage: '',
};

const updateCourseReducer = createReducer<IUpdateCourse, UpdateCourseAction>(initialState)
	.handleAction(actions.updateCourse.request, (state) => ({
		...state,
		errorMessage: '',
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.updateCourse.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.updateCourse.failure, (state, action) => ({
		...state,
		errorMessage: action.payload.error,
		isLoading: false,
		isSuccess: false,
	}));

export { updateCourseReducer };
