import { all, fork } from 'redux-saga/effects';

import overviewSagaWatcher from 'sagas/dashboard/overviewSagas';
import overviewCardSaga from 'reducers/dashboard/overview/overviewCardSaga';
import sitesSagaWatcher from 'sagas/dashboard/sitesSaga';
import issuesSagaWatcher from 'sagas/dashboard/issuesSaga';
import auditorsSagaWatcher from 'sagas/dashboard/auditorsSaga';
import trendsSagaWatcher from 'sagas/dashboard/trends.actionSaga';

import adminManageSagaWatcher from 'sagas/admin/manageSagas';
import superadminClientLoginSagaWatcher from 'sagas/superadmin/clientLoginSaga';
import superadminReqForDemoSagaWatcher from 'sagas/superadmin/reqForDemoSaga';
import superadminActivityLogSaga from 'sagas/superadmin/activityLog.actionSaga';

import departmentEditor from 'sagas/departmentEditor/departmentEditor.actionSaga';
import departments from 'sagas/departments/departments.actionSaga';
import sites from 'sagas/sites/sites.actionSaga';
import { siteGroupsSaga as siteGroups } from 'reducers/site/siteGroups/siteGroups.sagas';
import users from 'sagas/users/users.actionSaga';
import siteDetails from 'sagas/siteDetails/siteDetails.actionSaga';
import departmentGroupEditorSaga from '../sagas/DepartmentGroupEditor/DepartmentGroupEditor.Saga';
import organizationSaga from '../reducers/organizations/organizations.actionsSaga';
import departmentGroupSaga from '../sagas/DepartmentGroup/DepartmentGroup.Saga';
import questionnaireSaga from 'sagas/questionnaire/questionnaire.actionSaga';
import questionnaireIndexSaga from 'sagas/questionnaireIndex/questionnaireIndex.actionSaga';
import issues from '../reducers/issues/issues.saga';
import journeyPlansSaga from 'sagas/journeyPlans/journeyPlans.actionSaga';
import integrationSaga from '../reducers/integration/integration.saga';
import siteSchedulesSaga from '../reducers/site/siteSchedule/siteSchedules.actionSaga';
import siteReportsSaga from 'sagas/siteReports/siteReports.actionSaga';
import downloadManagerSaga from 'reducers/downloadManager/downloadManager.saga';
import companySaga from 'sagas/company/company.actionSaga';
import productSaga from 'sagas/product/product.actionSaga';
import competitorPromotionSaga from 'sagas/competitorPromotion/competitorPromotion.actionSaga';
import promotionSaga from 'sagas/Promotions/promotions.actionsaga';
import salesTargetSaga from 'sagas/salesTarget/salesTarget.actionSaga';
import exportSaga from 'sagas/competitorExport/competitorExport.actionSaga';
import featureAccessSaga from 'sagas/featureAccess/featureAccess.actionSaga';
import tutorialSaga from 'sagas/tutorial/tutorial.actionSaga';
import routeSaga from 'reducers/routeAccess/actionSaga';
import customerFeedbackSaga from 'sagas/customerFeedback/customerFeedback.actionSaga';
import siteCompactSaga from 'sagas/siteCompact/siteCompact.actionSaga';
import skusSaga from 'sagas/skuManagement/skus.actionSaga';
import gallerySaga from 'reducers/gallery/gallery.saga';
// questionnaire manager saga
import QuestionnaireSagaCloneQuestionnaire from 'reducers/questionnaire/questionnaire.saga.cloneQuestionnaire';
import { filterSaga } from 'reducers/filter';
import formulasSaga from 'reducers/formulas/formulas.actionSaga';
import skuCategorySaga from '../sagas/skuCategory/skuCategory.actionSaga';
import skuUomSaga from 'sagas/skuUom/skuUom.actionSaga';
import stockAuditSchedulerSaga from '../sagas/stockAuditScheduler/stockAuditScheduler.actionSaga';
import skusCompactSaga from 'sagas/skusCompact/skusCompact.actionsSaga';
import siteHierarchySaga from 'sagas/siteHierarchy/siteHierarchy.actionSaga';
import usersCompactSaga from 'sagas/usersCompact/usersCompact.actionSaga';
import siteActivityHistorySaga from 'sagas/siteActivityHistory/siteActivityHistory.actionSaga';
import siteOffDaysSaga from 'sagas/siteOffDays/siteOffDays.actionSaga';
import issueTrackerSettingsSaga from 'reducers/settingsPage/IssueTrackerSettings.saga';
import settingsNotificationSaga from 'reducers/settingsPage/settingsNotification/settingsNotification.saga';
import reportsSaga from 'sagas/reports/reports.actionSaga';

// lms
import userListSaga from 'sagas/lms/userManagement/userList.actionSaga';
import lmsCoursesSaga from 'sagas/lms/courses.actionSaga';
import createUserSaga from 'sagas/lms/userManagement/createUser.actionSaga';
import addUserSaga from 'sagas/lms/userManagement/addUser.actionSaga';
import userGroupListSaga from 'sagas/lms/userManagement/userGroupList.actionSaga';
import bulkUpdateUserSaga from 'sagas/lms/userManagement/bulkUpdateUser.actionSaga';
import lmsCoursesSyllabusesSaga from 'reducers/lms/coursesSyllabuses/coursesSyllabuses.actionSaga';
import createUserGroupSaga from 'sagas/lms/userManagement/createUserGroup.actionSaga';
import courseListSaga from 'sagas/lms/userManagement/courseList.actionSaga';
import coursesSaga from 'sagas/lms/courses/courses.actionSaga';
import syllabusesSaga from 'sagas/lms/syllabuses/syllabuses.actionSaga';
import lmsUsersCompactSaga from 'sagas/lms/userManagement/lmsUsersCompact.actionsSaga';
import createCourseSaga from 'sagas/lms/courses/createCourse.actionSaga';
import lmsCourseSaga from 'reducers/lms/course/lmsCourse.saga';
import lmsLearnerActivitySaga from 'reducers/lms/learnerActivity/lmsLearnerActivity.saga';
import myCoursesAndSyllabusesSaga from 'sagas/lms/myCoursesAndSyllabuses/myCoursesAndSyllabuses.actionSaga';
import lmsSyllabusesSaga from 'reducers/lms/syllabuses/syllabuses.actionSaga';
import lmsUserManagementUserSaga from 'reducers/lms/userManagement/userById/lmsUserManagementUserById.saga';
import updateCourseSaga from 'sagas/lms/courses/updateCourse.actionSaga';
import quizzesSaga from 'sagas/lms/quizzes/quizzes.actionSaga';
import quizAssociationOptionsSaga from 'sagas/lms/quiz/quizAssociationOptions/quizAssociationOptions.actionsSaga';
import lmsCourseQuizSaga from 'reducers/lms/course/courseQuiz/lmsCourseQuiz.saga';
import lmsNotificationSaga from 'reducers/lms/notification/notification.actionSaga';
import lmsDashboardSaga from 'reducers/lms/lmsDashboard/lmsDashboard.actionSaga';
import siteScheduleActivitySaga from 'reducers/site/siteScheduleActivity/siteScheduleActivity.saga';
import healthDashboardSaga from 'reducers/healthDashboard/healthDashboard.actionSaga';
import reportsHubSaga from 'reducers/reportsHub/reportsHub.actionSaga';
import repositorySaga from 'reducers/repository/repository.saga';
import repositoryUserUsageSaga from 'reducers/repositoryUserUsage/repositoryUserUsage.saga';
import repositoryOrganizationUsageSaga from 'reducers/repositoryOrganizationUsage/repositoryOrganizationUsage.saga';
import repositoryFolderListSaga from 'reducers/repositoryFolderList/repositoryFolderList.saga';
import executiveDashboardSaga from 'reducers/dashboardRevamp/executive/executive.actionSaga';
import operationalDashboardSaga from 'reducers/dashboardRevamp/operational/operational.actionSaga';
import issueInsightsDashboardSaga from 'reducers/dashboardRevamp/issueInsights/issueInsights.actionSaga';

import userDeptCompactSaga from 'sagas/userDeptCompact/userDeptCompact.Saga';
import siteGroupCompactSaga from 'sagas/siteGroup/siteGroup.Saga';
import siteUserCompactSaga from 'sagas/siteUser/siteUser.Saga';
import userRolesCompactSaga from 'sagas/userRoles/userRoles.Saga';
import lmsSettingsSaga from 'sagas/lms/settings/lmsSettings.saga';
import adminDashboardSaga from 'reducers/adminDashboard/adminDashboard.actionSaga';

export function* rootSaga() {
	yield all([
		fork(overviewSagaWatcher),
		fork(overviewCardSaga),
		fork(sitesSagaWatcher),
		fork(issuesSagaWatcher),
		fork(auditorsSagaWatcher),
		fork(trendsSagaWatcher),
		fork(adminManageSagaWatcher),
		fork(competitorPromotionSaga),
		fork(superadminClientLoginSagaWatcher),
		fork(superadminReqForDemoSagaWatcher),
		fork(superadminActivityLogSaga),
		fork(departmentEditor),
		fork(departmentGroupEditorSaga),
		fork(departments),
		fork(questionnaireSaga),
		fork(questionnaireIndexSaga),
		fork(sites),
		fork(siteGroups),
		fork(issues),
		fork(siteDetails),
		fork(journeyPlansSaga),
		fork(siteSchedulesSaga),
		fork(siteScheduleActivitySaga),
		fork(siteReportsSaga),
		fork(organizationSaga),
		fork(departmentGroupSaga),
		fork(users),
		fork(downloadManagerSaga),
		fork(companySaga),
		fork(productSaga),
		fork(promotionSaga),
		fork(exportSaga),
		fork(integrationSaga),
		fork(featureAccessSaga),
		fork(tutorialSaga),
		fork(routeSaga),
		fork(salesTargetSaga),
		fork(customerFeedbackSaga),
		fork(QuestionnaireSagaCloneQuestionnaire),
		fork(filterSaga),
		fork(siteCompactSaga),
		fork(filterSaga),
		fork(formulasSaga),
		fork(skusSaga),
		fork(skuCategorySaga),
		fork(skuUomSaga),
		fork(stockAuditSchedulerSaga),
		fork(skusCompactSaga),
		fork(siteHierarchySaga),
		fork(siteActivityHistorySaga),
		fork(siteOffDaysSaga),
		fork(usersCompactSaga),
		fork(userListSaga),
		fork(lmsCoursesSaga),
		fork(createUserSaga),
		fork(addUserSaga),
		fork(userGroupListSaga),
		fork(bulkUpdateUserSaga),
		fork(lmsCoursesSyllabusesSaga),
		fork(createUserGroupSaga),
		fork(courseListSaga),
		fork(coursesSaga),
		fork(syllabusesSaga),
		fork(lmsUsersCompactSaga),
		fork(createCourseSaga),
		fork(lmsCourseSaga),
		fork(lmsLearnerActivitySaga),
		fork(myCoursesAndSyllabusesSaga),
		fork(lmsSyllabusesSaga),
		fork(lmsUserManagementUserSaga),
		fork(updateCourseSaga),
		fork(issueTrackerSettingsSaga),
		fork(settingsNotificationSaga),
		fork(gallerySaga),
		fork(quizzesSaga),
		fork(reportsSaga),
		fork(quizAssociationOptionsSaga),
		fork(lmsCourseQuizSaga),
		fork(lmsNotificationSaga),
		fork(lmsDashboardSaga),
		fork(healthDashboardSaga),
		fork(reportsHubSaga),
		fork(repositorySaga),
		fork(repositoryUserUsageSaga),
		fork(repositoryOrganizationUsageSaga),
		fork(repositoryFolderListSaga),
		fork(executiveDashboardSaga),
		fork(operationalDashboardSaga),
		fork(issueInsightsDashboardSaga),
		fork(userDeptCompactSaga),
		fork(siteGroupCompactSaga),
		fork(siteUserCompactSaga),
		fork(userRolesCompactSaga),
		fork(lmsSettingsSaga),
		fork(adminDashboardSaga),
	]);
}
