import { LearnerActivity, PatchLearnerActivityRequest } from '@nimbly-technologies/nimbly-common';
import { FetchLmsLearnerActivityByIdArgs } from 'services/lms/lmsLearnerActivity.service';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './lmsLearnerActivity.actionTypes';

export const fetchLmsLearnerActivityByIdAsync = createAsyncAction(
	types.FETCH_LMS_LEARNER_ACTIVITY_BY_ID_REQUEST,
	types.FETCH_LMS_LEARNER_ACTIVITY_BY_ID_SUCCESS,
	types.FETCH_LMS_LEARNER_ACTIVITY_BY_ID_FAILURE,
)<FetchLmsLearnerActivityByIdArgs, LearnerActivity, string>();

export const updateLmsLearnerActivityAsync = createAsyncAction(
	types.UPDATE_LMS_LEARNER_ACTIVITY_REQUEST,
	types.UPDATE_LMS_LEARNER_ACTIVITY_SUCCESS,
	types.UPDATE_LMS_LEARNER_ACTIVITY_FAILURE,
)<Partial<PatchLearnerActivityRequest> & { learnerActivityID: string }, LearnerActivity, string>();

export const clearLmsLearnerActivityById = () => action(types.CLEAR_LMS_LEARNER_ACTIVITY_BY_ID);
