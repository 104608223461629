import { ActionType, createReducer } from 'typesafe-actions';
import { ILmsUserManagementUserStore } from './lmsUserManagementUserById';
import * as actions from './lmsUserManagementUserById.action';

const initialState: ILmsUserManagementUserStore = {
	fetchById: {
		data: null,
		isLoading: false,
		errorMessage: '',
	},
};

export type LmsUserManagementUserAction = ActionType<typeof actions>;
export const lmsUserManagementUserReducer = createReducer<ILmsUserManagementUserStore, LmsUserManagementUserAction>(
	initialState,
)
	.handleAction(actions.fetchLmsUserByIdAsync.request, (state) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsUserByIdAsync.success, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: false,
			data: action.payload,
			errorMessage: '',
		},
	}))
	.handleAction(actions.fetchLmsUserByIdAsync.failure, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: false,
			errorMessage: action.payload,
		},
	}));
