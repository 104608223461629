import { action, ActionType } from 'typesafe-actions';

const SET_SIDEBAR_COLLAPSE = '@sidebar/SET_SIDEBAR_COLLAPSE';
const SET_SIDEBAR_LOCK = '@sidebar/SET_SIDEBAR_LOCK';
const SET_SIDEBAR_SUB_MENU_COLLAPSIBLE = '@sidebar/SET_SIDEBAR_SUB_MENU_COLLAPSIBLE';

export const setSidebarCollapse = (bool: boolean) => action(SET_SIDEBAR_COLLAPSE, { bool });
export const setSidebarLock = (state: SidebarLockedState) => action(SET_SIDEBAR_LOCK, { state });
export const setSidebarSubMenuCollapse = (subMenuID: string, isCollapsed: boolean) =>
  action(SET_SIDEBAR_SUB_MENU_COLLAPSIBLE, { subMenuID, isCollapsed });

export const sidebarActions = {
  setSidebarCollapse,
  setSidebarLock,
  setSidebarSubMenuCollapse
};

type SidebarActions = ActionType<typeof sidebarActions>;

export type SidebarLockedState = 'locked' | 'unlocked' | 'temp-unlocked';

export type SidebarState = {
  readonly isCollapsed: boolean;
  readonly lockedState: SidebarLockedState;
  readonly subMenuCollapsedStateList: { [subMenuID: string]: boolean };
};

const initialState: SidebarState = { isCollapsed: true, lockedState: 'locked', subMenuCollapsedStateList: {} };

export function sidebarReducer(state = initialState, action: SidebarActions): SidebarState {
  switch (action.type) {
    case SET_SIDEBAR_COLLAPSE:
      return {
        ...state,
        isCollapsed: action.payload.bool
      };
    case SET_SIDEBAR_LOCK:
      return {
        ...state,
        lockedState: action.payload.state
      };
    case SET_SIDEBAR_SUB_MENU_COLLAPSIBLE:
      const { subMenuID, isCollapsed } = action.payload;
      return {
        ...state,
        subMenuCollapsedStateList: {
          ...state.subMenuCollapsedStateList,
          [subMenuID]: isCollapsed
        }
      };
    default:
      return state;
  }
}
