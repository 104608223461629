/* eslint-disable complexity */
import { ActionType, createReducer } from 'typesafe-actions';
import { DashboardPeriodEnum, GroupByEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import * as actions from './issueInsights.action';
import moment from 'moment';
import { IssueInsightsFilters, IssueInsightsReducerState } from './issueInsightsStore';

export type IssueInsightsReducerAction = ActionType<typeof actions>;

const initialState: IssueInsightsReducerState = {
	isIssueInsightsDetailLoading: false,
	isIssueCountListLoading: false,
	isIssueChartLoading: false,
	isIssueChartDetailsLoading: false,
	isIssueTableListLoading: false,
	errorMessage: '',
	metric: 'issue-insight-overview',
	limit: 5,
	page: 1,
	filters: {
		startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		siteIDs: [],
		departmentIDs: [],
		userIDs: [],
		questionnaireIDs: [],
		categories: [],
	},
	issueDetailsChartFilters: {
		viewBy: 'issueSeverity',
		groupBy: 'issueSeverity',
		groupByID: '',
		metric: 'issuePieChart',
		sortBy: 'percentage',
	},
	groupBy: GroupByEnum.SITE,
	period: DashboardPeriodEnum.QUARTERLY,
	sortBy: 'createdAt',
	sortDirection: 'desc',
	viewBy: 'site',
	status: 'all',
	issueInsightsData: {
		issueOpened: 0,
		issueClosed: 0,
		issueReoccured: 0,
		avgIRRTime: '',
		openedLastWeek: 0,
		trendDifference: 0,
		trend: '',
		DepartmentWithHighestIRR: '',
		maxIRRTime: '',
		minIRRTime: '',
	},
	issueCountList: [],
	issueTableList: {
		issueList: [],
		pageCount: null,
		totalDocs: null,
	},
	issueStatusData: [],
	issueChartList: [],
};

export const DEFAULT_FILTERS: IssueInsightsFilters = {
	startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
	endDate: moment().format('YYYY-MM-DD'),
	siteIDs: [],
	departmentIDs: [],
	userIDs: [],
	questionnaireIDs: [],
	categories: [],
	primaryStatus: [],
	secondaryStatus: [],
	priority: [],
	issueID: [],
};

export const issueInsightsReducer = createReducer<IssueInsightsReducerState, IssueInsightsReducerAction>(initialState)
	.handleAction(actions.setGroupBy, (state, action) => ({
		...state,
		groupBy: action.payload.groupBy,
	}))
	.handleAction(actions.setLimit, (state, action) => ({
		...state,
		limit: action.payload.limit,
	}))
	.handleAction(actions.setPage, (state, action) => ({
		...state,
		page: action.payload.page,
	}))
	.handleAction(actions.setFilters, (state, action) => ({
		...state,
		filters: action.payload.filters,
	}))
	.handleAction(actions.clearFilters, (state) => ({
		...state,
		filters: DEFAULT_FILTERS,
	}))
	.handleAction(actions.setIssueStatus, (state, action) => ({
		...state,
		status: action.payload.status,
	}))
	.handleAction(actions.setIssueInsightChartDetailFilters, (state, action) => ({
		...state,
		issueDetailsChartFilters: {
			...state.issueDetailsChartFilters,
			...action.payload.filters,
		},
	}))
	.handleAction(actions.fetchIssueInsightDetailAsync.request, (state) => ({
		...state,
		metric: 'issue-insight-overview',
		isIssueInsightsDetailLoading: true,
	}))
	.handleAction(actions.fetchIssueInsightDetailAsync.success, (state, action) => ({
		...state,
		issueInsightsData: action.payload,
		isIssueInsightsDetailLoading: false,
	}))
	.handleAction(actions.fetchIssueInsightDetailAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueInsightsDetailLoading: false,
	}))
	.handleAction(actions.fetchIssueInsightListAsync.request, (state, action) => ({
		page: 1,
		...state,
		metric: 'issueInsightList',
		...action.payload,
		isIssueCountListLoading: true,
	}))
	.handleAction(actions.fetchIssueInsightListAsync.success, (state, action) => ({
		...state,
		issueCountList: action.payload,
		isIssueCountListLoading: false,
	}))
	.handleAction(actions.fetchIssueInsightListAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueCountListLoading: false,
	}))
	.handleAction(actions.fetchIssueChartAsync.request, (state, action) => ({
		...state,
		...action.payload,
		isIssueChartLoading: true,
	}))
	.handleAction(actions.fetchIssueChartAsync.success, (state, action) => ({
		...state,
		issueStatusData: action.payload,
		isIssueChartLoading: false,
	}))
	.handleAction(actions.fetchIssueChartAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueChartLoading: false,
	}))
	.handleAction(actions.fetchIssueChartDetailsAsync.request, (state) => ({
		...state,
		isIssueChartDetailsLoading: true,
	}))
	.handleAction(actions.fetchIssueChartDetailsAsync.success, (state, action) => ({
		...state,
		issueChartList: action.payload,
		isIssueChartDetailsLoading: false,
	}))
	.handleAction(actions.fetchIssueChartDetailsAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueChartDetailsLoading: false,
	}))
	.handleAction(actions.fetchIssueTableDataAsync.request, (state) => ({
		...state,
		isIssueTableListLoading: true,
	}))
	.handleAction(actions.fetchIssueTableDataAsync.success, (state, action) => ({
		...state,
		issueTableList: action.payload,
		isIssueTableListLoading: false,
	}))
	.handleAction(actions.fetchIssueTableDataAsync.failure, (state, action) => ({
		...state,
		errorMessage: action.payload,
		isIssueTableListLoading: false,
	}));
