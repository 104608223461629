import React from 'react';
import styled from 'styled-components/macro';

export interface CheckboxProps {
	disabled?: boolean;
	checked?: boolean;
	isIndeterminate?: boolean;
	defaultChecked?: boolean;
	className?: string;
	label?: string;
	labelSize?: 'small' | 'medium' | 'large';
	onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	index?: number;
	uncontrolled?: boolean;
	shouldPropagate?: boolean;
	name?: string;
}

export const Checkbox = (props: CheckboxProps) => {
	const { disabled, checked, isIndeterminate, index, label, name } = props;

	const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onClick!(event);
	};

	return (
		<CheckboxContainer
			indeterminate={isIndeterminate}
			className={props.className ? props.className : ''}
			disabled={disabled || false}
			onClick={(e) => {
				if (!props.shouldPropagate) {
					e.stopPropagation();
				}
			}}
		>
			<input
				data-testid="checkbox"
				type="checkbox"
				disabled={disabled || false}
				onChange={handleClick}
				checked={props.uncontrolled ? undefined : isIndeterminate || checked || false}
				defaultChecked={props.defaultChecked}
				id={index ? `chkbox_check_${index}` : checked ? 'chkbox_uncheck_all' : 'chkbox_check_all'}
				name={name}
			/>
			<div className="wrapper">
				<span className="checkmark" />
				{label ? <span className={`label ${props.labelSize}`}>{props.label}</span> : null}
			</div>
		</CheckboxContainer>
	);
};

const CheckboxContainer = styled.label<{ disabled?: boolean; indeterminate?: boolean }>`
	display: block;
	position: relative;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	& > input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;

		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	}

	/* On mouse-over, add a grey background color */
	&:hover {
		& > input {
			& ~ .wrapper {
				& > .checkmark {
					background-color: ${({ disabled }) => (disabled ? '#E2E2E2' : '#eae9f9')};
				}
			}
		}
	}

	/* Create a custom checkbox */
	& > .wrapper {
		display: flex;
		align-items: center;

		& > .label {
			margin-left: 5px;
			font-weight: normal;
			font-size: 12px;
			color: #25282b;

			&.medium {
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
			}
		}

		& > .checkmark {
			top: 0;
			left: 0;
			height: 15px;
			width: 15px;
			min-height: 15px;
			min-width: 15px;
			position: relative;
			background-color: ${({ disabled }) => (disabled ? '#E2E2E2' : '#fff')};

			border: ${({ disabled }) => (disabled ? '#E2E2E2 solid 1px' : '1px #574fcf solid')};
			border-radius: 2px;
		}

		/* Create the checkmark/indicator (hidden when not checked) */
		& > .checkmark:after {
			content: '';
			position: absolute;
			display: none;
		}

		/* Style the checkmark/indicator */
		& > .checkmark:after {
			left: ${({ indeterminate }) => (indeterminate ? '5px' : '4px')};
			top: ${({ indeterminate }) => (indeterminate ? '2px' : '1px')};
			width: ${({ indeterminate }) => (indeterminate ? '0px' : '5px')};
			height: ${({ indeterminate }) => (indeterminate ? '9px' : '8px')};
			border: solid ${({ disabled, indeterminate }) => (indeterminate && !disabled ? '#574fcf' : '#fff')};
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(${({ indeterminate }) => (indeterminate ? '90deg' : '45deg')});
		}
	}

	/* When the checkbox is checked, add a blue background */
	& > input:checked {
		& ~ .wrapper {
			& > .checkmark {
				background-color: ${({ disabled, indeterminate }) =>
					disabled ? '#E2E2E2' : indeterminate ? '#fff' : '#574fcf'};
				border-color: ${({ disabled }) => (disabled ? '#C4C4C4' : '#574fcf')};
			}
		}
	}

	/* Show the checkmark when checked */
	& > input:checked {
		& ~ .wrapper {
			& > .checkmark:after {
				display: block;
			}
		}
	}
`;

export default Checkbox;
