import * as Common from 'nimbly-common';
import { FetchSiteIssueStatListPayload, SitesIssueList } from 'reducers/dashboard/sites/issueList/types';
import { getDummyCustomIdxRange, getDummyIdxRange } from '../getDummyIdxRange';

import site1_issuesResolved from '../../../assets/dummy/json/site1-issuesResolved.json';
import site2_issuesResolved from '../../../assets/dummy/json/site2-issuesResolved.json';
import site3_issuesResolved from '../../../assets/dummy/json/site3-issuesResolved.json';
import site4_issuesResolved from '../../../assets/dummy/json/site4-issuesResolved.json';
import site5_issuesResolved from '../../../assets/dummy/json/site5-issuesResolved.json';

const sites_issuesResolved = [
  site1_issuesResolved,
  site2_issuesResolved,
  site3_issuesResolved,
  site4_issuesResolved,
  site5_issuesResolved
];

const sitesName = ['PIK', 'Kota Kasablanka', 'Pondok Indah Mall', 'Mall of Indonesia', 'Pacific Place'];
const sitesDeptName = ['Operations', 'Sales', 'Operations', 'Marketing', 'Sales'];

const dummySitesIssueList_31D: SitesIssueList[] = new Array(31).fill('with_date').map((_, dateIndex) => {
  const rankByFlags: SitesIssueList = sitesName.map((siteName, siteIndex) => {
    const issuesResolved = sites_issuesResolved[siteIndex][dateIndex];
    const issuesDue = issuesResolved + ((issuesResolved % 3) + 2);
    const issuesCompletionRate = issuesResolved / issuesDue;

    return {
      siteName,
      siteID: `site_${siteIndex + 1}`,
      departmentName: sitesDeptName[siteIndex],
      issuesResolved,
      issuesDue,
      issuesCompletionRate
    };
  });
  return rankByFlags;
});

export const getSitesIssueList = (payload: FetchSiteIssueStatListPayload) => {
  const selectedSites = payload.sites;
  let diffInDay = 0;
  if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
    diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
  } else {
    diffInDay = getDummyIdxRange(payload.periodType);
  }

  let dummySitesIssueList: SitesIssueList[] = [];
  if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
    dummySitesIssueList = dummySitesIssueList_31D.slice(-diffInDay);
  } else {
    dummySitesIssueList = dummySitesIssueList_31D.slice(0, diffInDay);
  }

  const reduced_dummySitesIssueList: SitesIssueList = dummySitesIssueList.reduce((curr, acc) => {
    return curr.map((_, index) => {
      const totalIssuesResolved = curr[index].issuesResolved + acc[index].issuesResolved;
      const totalIssuesDue = curr[index].issuesDue + acc[index].issuesDue;
      const totalIssuesCompletionRate = totalIssuesResolved / totalIssuesDue;

      return {
        siteName: curr[index].siteName,
        siteID: curr[index].siteID,
        departmentName: curr[index].departmentName,
        issuesResolved: totalIssuesResolved,
        issuesDue: totalIssuesDue,
        issuesCompletionRate: totalIssuesCompletionRate
      };
    });
  });

  if (selectedSites !== '_all_') {
    return reduced_dummySitesIssueList.filter(siteRank => siteRank.siteName === selectedSites);
  }

  return reduced_dummySitesIssueList;
};
