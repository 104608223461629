import {
	createSiteGroup,
	querySiteGroupById,
	querySiteGroups,
	toggleSiteGroupActiveStatus,
	updateSiteGroup,
} from 'services/sites/siteGroups.services';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { siteGroupsActions as actions } from './siteGroups.actions';
import { RootState } from 'store/rootReducers';
import * as types from './siteGroups.actionTypes';
import { toast } from 'react-toastify';

export function* queryAllSiteGroupsSaga(action: ReturnType<typeof actions.queryAllAsync.request>): Generator {
	try {
		const filterQuery = yield* select((state: RootState) => state.siteGroups.filterQueryAll);
		const statusFilter = action.payload?.status ?? filterQuery.status;
		const withDisabled = statusFilter === 'active' ? false : true;
		const searchFilter = (action.payload?.search || filterQuery.search)?.toLocaleLowerCase();

		const siteGroups = yield* call(querySiteGroups, {
			withDisabled: action.payload?.status || filterQuery.status ? withDisabled : undefined,
		});

		if (!siteGroups?.data) {
			throw new Error('Failed to fetch site groups');
		}

		const dataFiltered = siteGroups.data.filter((siteGroup) => {
			const isStatusMatch = siteGroup.status === (statusFilter ?? 'active');

			const isSearchMatch = !searchFilter || siteGroup.name.toLowerCase().includes(searchFilter);

			return isStatusMatch && isSearchMatch;
		});

		yield* put(actions.queryAllAsync.success(dataFiltered));
	} catch (e) {
		yield* put(actions.queryAllAsync.failure({ error: 'Failed to fetch site groups' }));
		return null;
	}
}

export function* createSiteGroupSaga(action: ReturnType<typeof actions.createAsync.request>): Generator {
	try {
		const siteGroup = yield* call(createSiteGroup, action.payload);
		if (!siteGroup.data) {
			throw new Error('Failed to create site group');
		}

		yield* put(actions.createAsync.success(siteGroup.data));
		toast.success('Site group created successfully');
	} catch (e) {
		yield* put(actions.createAsync.failure({ error: 'Failed to create site group' }));
		return null;
	}
}

export function* querySiteGroupByIdSaga(action: ReturnType<typeof actions.queryByIdAsync.request>): Generator {
	try {
		const siteGroup = yield* call(querySiteGroupById, action.payload);
		if (!siteGroup?.data) {
			throw new Error('Failed to fetch site group');
		}

		yield* put(actions.queryByIdAsync.success(siteGroup.data));
	} catch (e) {
		yield* put(actions.queryByIdAsync.failure({ error: 'Failed to fetch site group' }));
		return null;
	}
}

export function* toggleSiteGroupActiveStatusSaga(
	action: ReturnType<typeof actions.toggleActiveStatusAsync.request>,
): Generator {
	try {
		const { siteGroupId, onSuccess } = action.payload;

		yield* call(toggleSiteGroupActiveStatus, siteGroupId);
		yield* put(actions.toggleActiveStatusAsync.success(null));

		onSuccess?.();
		toast.success('Site group active status changed successfully');
	} catch (e) {
		yield* put(actions.toggleActiveStatusAsync.failure({ error: 'Failed to toggle site group active status' }));
		return null;
	}
}

export function* updateSiteGroupSaga(action: ReturnType<typeof actions.updateAsync.request>): Generator {
	try {
		yield* call(() => updateSiteGroup(action.payload.siteGroupId, action.payload.siteGroup));
		yield* put(actions.updateAsync.success(null));

		toast.success('Site group updated successfully');
	} catch (e) {
		yield* put(actions.updateAsync.failure({ error: 'Failed to update site group' }));
		return null;
	}
}

export function* siteGroupsSaga() {
	// Triggered queryAllSiteGroupsSaga when the following actions are dispatched
	yield* takeLatest(actions.queryAllAsync.request, queryAllSiteGroupsSaga);
	yield* takeLatest(types.SET_SITE_GROUPS_FILTER_QUERY, queryAllSiteGroupsSaga);
	yield* takeLatest(types.SITE_GROUPS_CREATE_SUCCESS, queryAllSiteGroupsSaga);
	yield* takeLatest(types.SITE_GROUPS_UPDATE_SUCCESS, queryAllSiteGroupsSaga);
	yield* takeLatest(types.SITE_GROUPS_TOGGLE_ACTIVE_STATUS_SUCCESS, queryAllSiteGroupsSaga);

	yield* takeLatest(actions.createAsync.request, createSiteGroupSaga);
	yield* takeLatest(actions.queryByIdAsync.request, querySiteGroupByIdSaga);
	yield* takeLatest(actions.toggleActiveStatusAsync.request, toggleSiteGroupActiveStatusSaga);
	yield* takeLatest(actions.updateAsync.request, updateSiteGroupSaga);
}
