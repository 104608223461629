import React from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// styles
import { Button } from 'styles/Button';
import { Overlay, Modal } from 'styles/General';

// action-reducers
import { dismissFeatureModal } from 'reducers/featureAccess/featureAccess.action';

// types
import { RootState } from 'store/rootReducers';

export const FeatureAccessModal = (props: FeatureAccessModalProps) => {
  const { isVisible } = props;
  const { t } = useTranslation();

  const handleDismissModal = () => {
    props.dispatch(dismissFeatureModal());
  };

  return isVisible ? (
    <Overlay>
      <ConfirmModal data-testid="confirmModal">
        <div className="title">{t('label.noAccessPage.title')}</div>
        <div className="description">
          <span>{t('label.noAccessPage.description')}</span>
          <span>{t('label.noAccessPage.contact')}</span>
        </div>
        <div className="button__container">
          <Button medium onClick={handleDismissModal}>
            {t('label.okay')}
          </Button>
        </div>
      </ConfirmModal>
    </Overlay>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  isVisible: state.featureAccess.modalVisible
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = { dispatch: Dispatch };
type FeatureAccessModalProps = OwnProps & StateProps & DispatchProps;

export default connect(mapStateToProps)(FeatureAccessModal);

const ConfirmModal = styled(Modal)`
  padding: 20px 0px;
  width: calc(100% - 2rem);
  min-height: 190px;
  max-width: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.title {
    text-align: center;

    font-weight: bold;
  }

  div.description {
    text-align: center;
    padding: 11px;
  }

  > div.button__container {
    margin-top: 25px;
    display: 'flex';
    flex-direction: 'row';
    justify-content: 'space-evenly';
    width: '200px';

    *:not(:first-child) {
      margin-left: 10px;
    }
  }

  @media (min-width: 992px) {
    min-width: 330px;
    min-height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div.title {
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      padding: 5px;
      font-weight: 600;
    }
    div.description {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      font-size: 12px;
      width: 400px;
      padding: 5px;
  }
`;
