import {
  IssuesResolvedChart,
  FetchIssuesResolvedChartPayload,
  FetchIssuesResolvedChartResponse
} from 'reducers/dashboard/overview/issuesResolvedChart/types';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from '../../../../config/baseURL';
import { getDummyIssuesResolvedChart } from 'utils/dashboardDummy/overview/dummyIssuesResolvedChart';
import API from 'helpers/api';

export const fetchIssuesResolvedChart = async (
  payload: FetchIssuesResolvedChartPayload
): Promise<FetchIssuesResolvedChartResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyIssuesResolvedChart = getDummyIssuesResolvedChart(payload);
    return {
      message: 'SUCCESS',
      data: dummyIssuesResolvedChart
    };
  }

  try {
    const token = await API.getFirebaseToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/issues/daily?${serializedQueries}`;

    const res = await fetch(url, options);

    if (res.status !== 200) {
      throw new Error('');
    }

    const issuesResolvedChartResult: IssuesResolvedChart = await res.json();
    return {
      message: 'SUCCESS',
      data: issuesResolvedChartResult
    };
  } catch {
    const errorResult: FetchIssuesResolvedChartResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
