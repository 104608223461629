import { toast } from 'react-toastify';
import { RootState } from 'store/rootReducers';
import { call, put, select } from 'typed-redux-saga';
import * as actions from './repositoryOrganizationUsage.action';
import * as types from './repositoryOrganizationUsage.actionTypes';
import { takeLatest } from 'redux-saga/effects';
import { fetchRepositoryOrganizationUsage } from 'services/repositoryOrganizationUsage/repositoryOrganizationUsage.service';

export function* fetchRepositoryOrganizationUsageSaga() {
	const selector = (state: RootState) => state.firebase.profile;
	const state = yield select(selector);

	try {
		const result = yield* call(fetchRepositoryOrganizationUsage, state.organization);
		const totalUsedStorageMB = result.totalUsedStorageGB * 1024;
		const totalOrganizationUsedStorageMB = totalUsedStorageMB + result.allocationPerEntity.LMS;
		const totalOrganizationUsedStorageGB = totalOrganizationUsedStorageMB / 1024;
		const res = {
			...result,
			totalOrganizationUsedStorageMB,
			totalOrganizationUsedStorageGB,
		};

		yield put(actions.fetchRepositoryOrganizationUsage.success(res));
	} catch (e) {
		toast.error((e as Error).message);
		yield put(actions.fetchRepositoryOrganizationUsage.failure({ error: 'Failed to fetch attachments' }));
	}
}

export default function* repositoryOrganizationUsageSaga() {
	yield takeLatest(types.FETCH_REPOSITORY_ORGANIZATION_USAGE_REQUEST, fetchRepositoryOrganizationUsageSaga);
}
