import moment from 'moment-timezone';
import { action, ActionType } from 'typesafe-actions';
import * as Common from 'nimbly-common';

import { getDatesByPeriod } from 'utils/dates';

const SET_PERIOD_PREVIEW = 'analytics/SET_PERIOD_PREVIEW';
const SET_CHART_TYPE = 'analytics/SET_CHART_TYPE';
const SET_ORGANIZATION_CHART_TYPE = 'analytics/SET_ORGANIZATION_CHART_TYPE';
const SET_AUDITOR_CHART_TYPE = 'analytics/SET_AUDITOR_CHART_TYPE';
const SET_SITE_CHART_TYPE = 'analytics/SET_SITE_CHART_TYPE';
const SET_SELECTED_DEPARTMENT = 'analytics/SET_SELECTED_DEPARTMENT';
const SET_SELECTED_AUDITOR = 'analytics/SET_SELECTED_AUDITOR';
const SET_SELECTED_SITE = 'analytics/SET_SELECTED_SITE';
const SET_SELECTED_DEPARTMENT_GROUP = 'analytics/SET_SELECTED_DEPARTMENT_GROUP';

const SET_DATES = 'analytics/SET_DATES';
const SET_CUSTOM_DATES = 'analytics/SET_CUSTOM_DATES';
const SET_PREVIOUS_DATES = 'analytics/SET_PREVIOUS_DATES';

const SET_FILTER_SKU = 'analytics/SET_FILTER_SKU';

export const setChartType = (viewTab: ChartKeys, option: string) => {
  switch (viewTab) {
    case 'overview' || 'issues':
      return action(SET_ORGANIZATION_CHART_TYPE, {
        option
      });
    case 'auditors':
      return action(SET_AUDITOR_CHART_TYPE, {
        option
      });
    case 'sites':
      return action(SET_SITE_CHART_TYPE, {
        option
      });
    default:
      return action(SET_CHART_TYPE, {
        option,
        viewTab
      });
  }
};

export const setSelectedDepartment = (departmentKey: string) => action(SET_SELECTED_DEPARTMENT, { departmentKey });

export const setSelectedDepartmentAndGroup = (departmentGroupKey: string) =>
  action(SET_SELECTED_DEPARTMENT_GROUP, { departmentGroupKey });

export const setSelectedAuditor = (auditorKey: string) => action(SET_SELECTED_AUDITOR, { auditorKey });

export const setSelectedSite = (siteKey: string) => action(SET_SELECTED_SITE, { siteKey });

export const setFilterSKU = (skuKey: string) => action(SET_FILTER_SKU, { skuKey });

export const setPeriodType = (periodType: Common.enums.PeriodTypes) => action(SET_PERIOD_PREVIEW, { periodType });

export const setDates = (
  periodType: Common.enums.PeriodTypes,
  orgSchedule: Common.OrganizationSchedule,
  date: { customStart: moment.Moment; customEnd: moment.Moment } | null = null
) => {
  const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date);
  return action(SET_DATES, { startDate, endDate });
};

export const setCustomDates = (startDate: moment.Moment | null, endDate: moment.Moment | null) =>
  action(SET_CUSTOM_DATES, {
    startDate,
    endDate
  });

export const setPreviousDates = (
  periodType: Common.enums.PeriodTypes,
  orgSchedule: Common.OrganizationSchedule,
  date: { customStart: moment.Moment; customEnd: moment.Moment } | null = null
) => {
  const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date, true);
  return action(SET_PREVIOUS_DATES, { prevStartDate: startDate, prevEndDate: endDate });
};

const analyticsActions = {
  setChartType,
  setDates,
  setPreviousDates,
  setCustomDates,
  setPeriodType,
  setSelectedDepartment,
  setSelectedDepartmentAndGroup,
  setSelectedAuditor,
  setSelectedSite,
  setFilterSKU
};

export type AnalyticsActions = ActionType<typeof analyticsActions>;

export type ChartKeys = 'overview' | 'issues' | 'sites' | 'auditors';
export type ChartOverviewKeys = 'report';
export type ChartIssueKeys = 'red-yellow' | 'red' | 'yellow';
export type ChartSiteKeys = 'green';
export type ChartAuditorsKeys = 'report' | 'time';

export type AnalyticsState = {
  readonly periodOptions: Common.enums.PeriodTypes[];
  readonly periodType: Common.enums.PeriodTypes;
  readonly chartType: {
    overview: ChartOverviewKeys;
    issues: ChartIssueKeys;
    sites: ChartSiteKeys;
    auditors: ChartAuditorsKeys;
  };
  readonly selectedDepartment: string;
  readonly selectedDepartmentGroup: string;
  readonly selectedAuditor: string;
  readonly selectedSite: string;
  readonly startDate: moment.Moment;
  readonly endDate: moment.Moment;
  readonly prevStartDate: moment.Moment | null;
  readonly prevEndDate: moment.Moment | null;
  readonly customStart: moment.Moment | null;
  readonly customEnd: moment.Moment | null;
  readonly filterSKU: string;
};

const initialState: AnalyticsState = {
  periodOptions: [
    'current' as Common.enums.PeriodTypes,
    'previous' as Common.enums.PeriodTypes,
    'last-7' as Common.enums.PeriodTypes,
    'last-28' as Common.enums.PeriodTypes,
    'last-30' as Common.enums.PeriodTypes,
    'last-90' as Common.enums.PeriodTypes,
    'last-6-m' as Common.enums.PeriodTypes,
    'last-12-m' as Common.enums.PeriodTypes,
    'last-year' as Common.enums.PeriodTypes,
    'mtd' as Common.enums.PeriodTypes,
    'custom' as Common.enums.PeriodTypes
  ],
  periodType: 'current' as Common.enums.PeriodTypes,
  chartType: {
    overview: 'report', // option: 'report'
    issues: 'red-yellow', // option: 'red-yellow', 'red', 'yellow'
    sites: 'green', // option: 'green',
    auditors: 'report' // option: 'report', 'time'
  },
  selectedDepartment: '_all_',
  selectedAuditor: '_all_',
  selectedSite: '_all_',
  selectedDepartmentGroup: '_all_',
  filterSKU: '',
  // state to select custom dates
  startDate: moment().startOf('isoWeek'),
  endDate: moment().endOf('isoWeek'),
  prevStartDate: moment().parseZone().subtract(1, 'week').startOf('isoWeek'),
  prevEndDate: moment().parseZone().subtract(1, 'week'),
  customStart: null,
  customEnd: null
};

export function analyticsReducer(state = initialState, action: AnalyticsActions): AnalyticsState {
  switch (action.type) {
    case SET_PERIOD_PREVIEW:
      return {
        ...state,
        periodType: action.payload.periodType
      };
    case SET_CHART_TYPE:
      return {
        ...state,
        chartType: {
          ...state.chartType,
          [action.payload.viewTab as ChartKeys]: action.payload.option
        }
      };
    case SET_ORGANIZATION_CHART_TYPE:
      return {
        ...state,
        chartType: {
          ...state.chartType,
          ['overview' as ChartKeys]: action.payload.option
        }
      };
    case SET_AUDITOR_CHART_TYPE:
      return {
        ...state,
        chartType: {
          ...state.chartType,
          ['auditors' as ChartKeys]: action.payload.option
        }
      };
    case SET_SITE_CHART_TYPE:
      return {
        ...state,
        chartType: {
          ...state.chartType,
          ['sites' as ChartKeys]: action.payload.option
        }
      };
    case SET_SELECTED_DEPARTMENT:
      return {
        ...state,
        selectedDepartment: action.payload.departmentKey
      };
    case SET_SELECTED_DEPARTMENT_GROUP:
      return {
        ...state,
        selectedDepartmentGroup: action.payload.departmentGroupKey
      };
    case SET_SELECTED_AUDITOR:
      return {
        ...state,
        selectedAuditor: action.payload.auditorKey
      };
    case SET_SELECTED_SITE:
      return {
        ...state,
        selectedSite: action.payload.siteKey
      };
    case SET_DATES:
      return {
        ...state,
        startDate: action.payload.startDate as moment.Moment,
        endDate: action.payload.endDate as moment.Moment
      };
    case SET_PREVIOUS_DATES:
      return {
        ...state,
        prevStartDate: action.payload.prevStartDate,
        prevEndDate: action.payload.prevEndDate
      };
    case SET_CUSTOM_DATES:
      return {
        ...state,
        customStart: action.payload.startDate,
        customEnd: action.payload.endDate
      };
    case SET_FILTER_SKU:
      return {
        ...state,
        filterSKU: action.payload.skuKey
      };
    default:
      return state;
  }
}
