import i18n from 'i18n';
import { toast } from 'react-toastify';
import { GetUserListSuccessResponse, SingleUser, UserListState } from 'reducers/lms/userManagement/userList/type';
import * as actions from 'reducers/lms/userManagement/userList/userList.action';
import * as types from 'reducers/lms/userManagement/userList/userList.actionTypes';
import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'typed-redux-saga';
import { RootState } from 'store/rootReducers';
import queryString from 'query-string';
import moment from 'moment';
import { PaginationResult } from '@nimbly-technologies/nimbly-common';
import { fetchLmsUsers } from 'services/lms/lmsUserManagement.service';

function* getUserList(action: ReturnType<typeof actions.getUserList.request>) {
	try {
		if (action.payload?.roles) {
			yield put(actions.setUserListFilters({ roles: action.payload.roles }));
		}

		const { page, limit, sortBy, sortDirection, search, filters }: UserListState = yield select(
			(state: RootState) => state.lmsUserList,
		);

		const newFilters = {
			course: filters.courses,
			role: filters.roles,
			status: filters.status,
			lastLoginDate: [
				filters?.lastLoginDate?.startDate ? Date.parse(filters?.lastLoginDate?.startDate.startOf('day')) : undefined,
				filters?.lastLoginDate?.endDate ? Date.parse(filters?.lastLoginDate?.endDate.endOf('day')) : undefined,
			],
			verificationDate: [
				filters?.verificationDate?.startDate
					? Date.parse(filters?.verificationDate?.startDate.startOf('day'))
					: undefined,
				filters?.verificationDate?.endDate ? Date.parse(filters?.verificationDate?.endDate.endOf('day')) : undefined,
			],
		};

		const query: Record<string, any> = {
			page,
			limit,
			search,
			sortFields: sortBy,
			sortDirections: sortDirection,
			...newFilters,
		};
		const queryStr = queryString.stringify(query);
		const response: PaginationResult<SingleUser> = yield* call(fetchLmsUsers, queryStr);
		const successPayload: GetUserListSuccessResponse = {
			data: response.docs,
			totalDocs: response.totalDocs,
		};

		yield put(actions.getUserList.success(successPayload));
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.user.fetch.failed'));
		yield put(actions.getUserList.failure({ error: 'Failed to Fetch User List' }));
	}
}

export default function* userListSaga() {
	yield takeLatest(types.GET_USER_LIST_REQUEST, getUserList);
	yield takeLatest(types.SET_USER_LIST_PAGE_INDEX, getUserList);
	yield takeLatest(types.SET_USER_LIST_FILTER_QUERY, getUserList);
	yield takeLatest(types.RESET_USER_LIST_FILTER_QUERY, getUserList);
	yield takeLatest(types.SET_USER_LIST_SEARCH_QUERY, getUserList);
	yield takeLatest(types.SET_USER_LIST_SORT, getUserList);
}
