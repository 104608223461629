import set from 'lodash/set';
import * as Common from '@nimbly-technologies/nimbly-common';
import { RestructuredResource, RoleLabel, PermissionValue, UserPermissionValue } from '../typings';
// { RoleResources, ResourceEnum, Permission }
/**
 * restructure data from template or from backend to render on front-end side
 */
export const createTemplateResource = (roleList: RoleLabel[]) => {
	const ResourceKeys = Object.keys(Common.enums.RoleResources);
	const restructuredResource: RestructuredResource = {};

	ResourceKeys.forEach((key: string) => {
		const permissions = Common.enums.RoleResources[key as Common.enums.ResourceEnum].permission;
		const resource = Common.enums.RoleResources[key as Common.enums.ResourceEnum].resource;
		const resourceItems = resource.split(':');

		// creates initial (boolean) value of each permission - and role listed set by the org
		const permissionValue: PermissionValue = {};
		permissions.forEach((permissionAction: Common.enums.Permission) => {
			permissionValue[permissionAction] = {};
			roleList.forEach((role) => {
				Object.assign(permissionValue[permissionAction], { [role.value]: false });
			});
		});

		const reducedPaths: string[] = [];
		let resourcePaths: string[] = [];
		let pathFound = false;

		// Checks current restructured Object against the resource item paths
		// Will dynamically collect paths of resource item keys that is not yet assigned to the Object
		resourceItems.forEach((item, i) => {
			resourcePaths = resourceItems.slice(0, i + 1);
			if (pathFound) return null;

			if (!restructuredResource[resourcePaths.join('.')]) {
				let reduceResourcePaths = resourceItems.slice(i + 1, resourceItems.length - 1);
				if (i === 0) reduceResourcePaths.pop();
				reducedPaths.push(...reduceResourcePaths);
				pathFound = true;
			}
		});

		// Create new (dynamically nested) keys in restructured Object based on previously collected paths
		const resourceObject: any = reducedPaths.reduceRight((value, key) => ({ [key]: value } as any), {
			permissions: permissionValue,
		});

		// Assigns the restructured Object with the created resourceObject
		set(restructuredResource, resourcePaths.join('.'), resourceObject);
	});

	return restructuredResource;
};

export const createUserTemplateResource = () => {
	const ResourceKeys = Object.keys(Common.enums.RoleResources);
	const restructuredResource: RestructuredResource = {};

	ResourceKeys.forEach((key: string) => {
		const permissions = Common.enums.RoleResources[key as Common.enums.ResourceEnum].permission;
		const resource = Common.enums.RoleResources[key as Common.enums.ResourceEnum].resource;
		const resourceItems = resource.split(':');

		// creates initial (boolean) value of each permission - and role listed set by the org
		const userPermissionValue: UserPermissionValue = {};
		permissions.forEach((permissionAction: Common.enums.Permission) => {
			userPermissionValue[permissionAction] = false;
		});

		const reducedPaths: string[] = [];
		let resourcePaths: string[] = [];
		let pathFound = false;

		// Checks current restructured Object against the resource item paths
		// Will dynamically collect paths of resource item keys that is not yet assigned to the Object
		resourceItems.forEach((item, i) => {
			resourcePaths = resourceItems.slice(0, i + 1);
			if (pathFound) return null;

			if (!restructuredResource[resourcePaths.join('.')]) {
				let reduceResourcePaths = resourceItems.slice(i + 1, resourceItems.length - 1);
				if (i === 0) reduceResourcePaths.pop();
				reducedPaths.push(...reduceResourcePaths);
				pathFound = true;
			}
		});

		// Create new (dynamically nested) keys in restructured Object based on previously collected paths
		const resourceObject: any = reducedPaths.reduceRight((value, key) => ({ [key]: value } as any), {
			permissions: userPermissionValue,
		});

		// Assigns the restructured Object with the created resourceObject
		set(restructuredResource, resourcePaths.join('.'), resourceObject);
	});

	return restructuredResource;
};
