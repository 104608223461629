import {
  UPSERT_QuestionnairePayload,
  UPSERT_QuestionnaireResponse,
  QuestionCategoriesResponse,
  ErrorResponse
} from '../../fetch-types/manage-questionnaires-fetch.types';

export type UpsertedQuestionnaireID = UPSERT_QuestionnaireResponse['data'];
export type QuestionCategories = QuestionCategoriesResponse['data'];
export type QuestionCategory = QuestionCategoriesResponse['data'][0];

export interface QuestionnaireState {
  readonly upsertedQuestionnaire_loading: boolean;
  readonly upsertedQuestionnaire_questionnaireID: UpsertedQuestionnaireID;
  readonly upsertedQuestionnaire_error: string;
  readonly questionCategories_loading: boolean;
  readonly questionCategories_data: QuestionCategories;
  readonly questionCategories_error: string;
}

export const UPSERT_QUESTIONNAIRE_REQUEST = '@@admin/manage/questionnaires/UPSERT_QUESTIONNAIRE_REQUEST';
export const UPSERT_QUESTIONNAIRE_SUCCESS = '@@admin/manage/questionnaires/UPSERT_QUESTIONNAIRE_SUCCESS';
export const UPSERT_QUESTIONNAIRE_FAILURE = '@@admin/manage/questionnaires/UPSERT_QUESTIONNAIRE_FAILURE';

export type UpsertQuestionnairePayload = UPSERT_QuestionnairePayload;
export type UpsertQuestionnaireResponse = UPSERT_QuestionnaireResponse | ErrorResponse;

export const CLEAR_QUESTIONNAIRE_STATE = '@@admin/manage/questionnaires/CLEAR_QUESTIONNAIRE_STATE';

export const FETCH_QUESTION_CATEGORIES_REQUEST = '@@admin/manage/questionnaires/FETCH_QUESTION_CATEGORIES_REQUEST';
export const FETCH_QUESTION_CATEGORIES_SUCCESS = '@@admin/manage/questionnaires/FETCH_QUESTION_CATEGORIES_SUCCESS';
export const FETCH_QUESTION_CATEGORIES_FAILURE = '@@admin/manage/questionnaires/FETCH_QUESTION_FAILURE';

export type FetchQuestionCategoriesResponse = QuestionCategoriesResponse | ErrorResponse;
