/* eslint-disable */
import { apiURL } from 'config/baseURL';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/userManagement/enrollCourseUserGroup/enrollCourseUserGroup.action';
import * as actionsUserGroup from 'reducers/lms/userManagement/userGroupList/userGroupList.action';
import * as types from 'reducers/lms/userManagement/enrollCourseUserGroup/enrollCourseUserGroup.actionTypes';
import { LmsCouseList } from 'reducers/lms/userManagement/enrollCourseUserGroup/type';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/rootReducers';
import { select } from 'typed-redux-saga';

function* getCourseList(action: ReturnType<typeof actions.getCourseList.request>) {
	try {
		// @ts-ignore
		const authToken = yield getToken();

		const courseListState: any = yield* select((state: RootState) => state.lmsEnrollCourse);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const query: { [key: string]: string | number } = {
			page: courseListState.pageIndex || 1,
			limit: courseListState.limit || 15,
			search: courseListState.search || '',
			sortFields: courseListState.sortBy,
			sortDirections: courseListState.sortDirection,
		};

		const queryString = Object.keys(query).length
			? Object.keys(query)
					.map((key: string) => {
						return query[key] && `${key}=${query[key]}`;
					})
					.join('&')
			: '';

		const getCourseListURL = `${apiURL}/lms/courses?${queryString}`;

		const request = () => fetch(getCourseListURL, options);
		// @ts-ignore
		const response = yield call(request);

		if (response && response.status === 200) {
			const resData: LmsCouseList = yield response.json();
			yield put(actions.getCourseList.success(resData));
		} else {
			// @ts-ignore
			const CourseresponseData = yield response.json();
			const message = CourseresponseData.message;
			toast.error(message, { containerId: 'toast-message-container' });
			yield put(actions.getCourseList.failure({ error: CourseresponseData.message }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.user.fetch.failed'));
		yield put(actions.getCourseList.failure({ error: 'Failed to Fetch Lesson List' }));
	}
}

function* updateUserGroupEnrollCourse(action: ReturnType<typeof actions.updateUserGroupEnrollCourse.request>) {
	try {
		const body = { data: action.payload.data };
		// @ts-ignore3
		const authToken = yield getToken();
		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const updateURL = `${apiURL}/lms/user-groups/bulk-edit`;

		const request = () => fetch(updateURL, options);
		// @ts-ignore
		const response = yield call(request);

		if (response && response.status === 200) {
			yield put(actions.updateUserGroupEnrollCourse.success({ message: response.message }));
			yield put(actionsUserGroup.getUserGroupList.request({ limit: 15 }));
			toast.success(i18n.t('You have successfully updated user group(s).'));
		} else if (response && response.status === 409) {
			yield put(actions.updateUserGroupEnrollCourse.failure({ error: i18n.t('Already Exist') }));
			toast.error(i18n.t('Already Exist'));
		} else {
			// @ts-ignore
			const responseData = yield response.json();
			if (responseData.data && Array.isArray(responseData.data)) {
				yield put(actions.updateUserGroupEnrollCourse.failure({ error: responseData.message }));
				return;
			}
			yield put(actions.updateUserGroupEnrollCourse.failure({ error: responseData.message }));
		}
	} catch (e) {
		toast.error(i18n.t('Failed to Update Lesson'));
		yield put(actions.getCourseList.failure({ error: 'Failed to Update Lesson' }));
	}
}

export default function* courseListSaga() {
	yield takeLatest(types.GET_COURSE_LIST_REQUEST, getCourseList);
	yield takeLatest(types.SET_COURSE_LIST_PAGE_INDEX, getCourseList);
	yield takeLatest(types.SET_COURSE_LIST_FILTER_QUERY, getCourseList);
	yield takeLatest(types.SET_COURSE_LIST_SEARCH_QUERY, getCourseList);
	yield takeLatest(types.SET_COURSE_LIST_SORT, getCourseList);
	yield takeLatest(types.UPDATE_USERGROUP_ENROLLCOURSE_REQUEST, updateUserGroupEnrollCourse);
}
