/* eslint-disable */
import { apiURL } from 'config/baseURL';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/userManagement/userGroupList/userGroupList.action';
import * as types from 'reducers/lms/userManagement/userGroupList/userGroupList.actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/rootReducers';
import { select } from 'typed-redux-saga';
import { UsecaseReturn, PaginationResult, UserGroupResponse } from '@nimbly-technologies/nimbly-common';
import { UserGroupPopulatedResponse } from 'reducers/lms/userManagement/userGroupList/type';

function* getUserGroupList(action: ReturnType<typeof actions.getUserGroupList.request>) {
	try {
		// @ts-ignore
		const authToken = yield getToken();

		const userListState: any = yield* select((state: RootState) => state.lmsUserGroup);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const query: { [key: string]: string | number } = {
			page: userListState.pageIndex || 1,
			limit: userListState.limit || 15,
			search: userListState.search || '',
			sortFields: userListState.sortBy,
			sortDirections: userListState.sortDirection,
		};

		const queryStr = Object.keys(query).length
			? Object.keys(query)
					.map((key: string) => {
						return query[key] && `${key}=${query[key]}`;
					})
					.join('&')
			: '';

		const getUserListURL = `${apiURL}/lms/user-groups?${queryStr}`;

		const request = () => fetch(getUserListURL, options);
		// @ts-ignore
		const response = yield call(request);

		if (response && response.status === 200) {
			const resData: UsecaseReturn<PaginationResult<UserGroupPopulatedResponse>> = yield response.json();
			yield put(actions.getUserGroupList.success(resData.data!));
		} else {
			// @ts-ignore
			const UserGroupResponseData = yield response.json();
			const message = UserGroupResponseData.message;
			toast.error(message, { containerId: 'toast-message-container' });
			yield put(actions.getUserGroupList.failure({ error: UserGroupResponseData.message }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.userGroup.fetch.failed'));
		yield put(actions.getUserGroupList.failure({ error: 'Failed to Fetch User Group List' }));
	}
}

export default function* userGroupListSaga() {
	yield takeLatest(types.GET_USER_GROUP_LIST_REQUEST, getUserGroupList);
	yield takeLatest(types.SET_USER_GROUP_LIST_PAGE_INDEX, getUserGroupList);
	yield takeLatest(types.SET_USER_GROUP_LIST_FILTER_QUERY, getUserGroupList);
	yield takeLatest(types.SET_USER_GROUP_LIST_SEARCH_QUERY, getUserGroupList);
	yield takeLatest(types.SET_USER_GROUP_LIST_SORT, getUserGroupList);
}
