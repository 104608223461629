import { call, put, takeLatest } from 'redux-saga/effects';
import {
	FETCH_OVERVIEW_DAILY_CHART_REQUEST,
	FetchDailyChartResponse,
} from 'reducers/dashboard/overview/dailyChart/types';
import {
	FetchIssuesOverviewCardsResponse,
	FETCH_ISSUES_OVERVIEW_CARDS_REQUEST,
} from 'reducers/dashboard/overview/issuesOverviewCards/types';
import {
	FetchIssuesResolvedChartResponse,
	FETCH_ISSUES_RESOLVED_CHART_REQUEST,
} from 'reducers/dashboard/overview/issuesResolvedChart/types';
import {
	FetchIssuesCategoriesChartResponse,
	FETCH_ISSUES_CATEGORIES_CHART_REQUEST,
} from 'reducers/dashboard/overview/issuesCategoriesChart/types';
import {
	FetchUserNPSResponse,
	FETCH_USER_NPS_REQUEST,
	UpsertUserNPSResponse,
	UPSERT_USER_NPS_REQUEST,
	FetchAllUserNPSResponse,
	FETCH_ALL_USER_NPS_REQUEST,
} from 'reducers/dashboard/overview/userNPS/types';

import { fetchOverviewDailyChartAsync } from 'reducers/dashboard/overview/dailyChart/action';
import { fetchIssuesOverviewCardsAsync } from 'reducers/dashboard/overview/issuesOverviewCards/action';
import { fetchIssuesResolvedChartAsync } from 'reducers/dashboard/overview/issuesResolvedChart/action';
import { fetchIssuesCategoriesChartAsync } from 'reducers/dashboard/overview/issuesCategoriesChart/action';
import {
	fetchUserNPSAsync,
	upsertUserNPSAsync,
	fetchAllUserNPSAsync,
} from 'reducers/dashboard/overview/userNPS/action';
import { fetchMapStatisticsAsync } from 'reducers/dashboard/overview/mapStatistics/action';
import { FETCH_MAP_STATISTICS_REQUEST } from 'reducers/dashboard/overview/mapStatistics/actionTypes';

import {
	fetchOverviewDailyChart,
	fetchIssuesOverviewCards,
	fetchIssuesResolvedChart,
	fetchIssuesCategoriesChart,
	fetchUserNPS,
	upsertUserNPS,
	fetchLiveOrganization,
	fetchAllUserNPS,
} from './helpers/overview';

/** Missed Makeup Report */
import { fetchMissedMakeupOverview, fetchMissedMakeupMakeupList } from './helpers/missedMakeup/fetchMissedMakeupReport';
import { internalStatistic } from '@nimbly-technologies/nimbly-common';

import { fetchLiveOrganizationAsync } from 'reducers/dashboard/overview/liveOrganization/action';
import {
	FetchLiveOrganizationResponse,
	FETCH_LIVE_ORGANIZATION_REQUEST,
} from 'reducers/dashboard/overview/liveOrganization/types';

import * as APIService from 'constants/api/v1';
import { MapStatisticOverviewResponse } from 'constants/api/v1/types';
import { fetchMissedMakeupListAsync, fetchMissedMakeupOverviewAsync } from 'reducers/dashboard/missedMakeup/action';
import {
	FETCH_MISSED_MAKEUP_LIST_REQUEST,
	FETCH_MISSED_MAKEUP_OVERVIEW_REQUEST,
} from 'reducers/dashboard/missedMakeup/types';

export function* fetchOverviewDailyChartSaga(
	action: ReturnType<typeof fetchOverviewDailyChartAsync.request>,
): Generator {
	try {
		const fetchRes: FetchDailyChartResponse = (yield call(fetchOverviewDailyChart, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchOverviewDailyChartAsync.success(fetchRes.data));
		} else {
			yield put(fetchOverviewDailyChartAsync.failure('FETCH DAILY CHART FAILED'));
		}
	} catch (e) {
		yield put(fetchOverviewDailyChartAsync.failure('FETCH DAILY CHART FAILED'));
	}
}

export function* fetchIssuesOverviewCardsSaga(
	action: ReturnType<typeof fetchIssuesOverviewCardsAsync.request>,
): Generator {
	try {
		const fetchRes: FetchIssuesOverviewCardsResponse = (yield call(fetchIssuesOverviewCards, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchIssuesOverviewCardsAsync.success(fetchRes.data));
		} else {
			yield put(fetchIssuesOverviewCardsAsync.failure('FETCH ISSUES OVERVIEW CARDS FAILED'));
		}
	} catch (e) {
		yield put(fetchIssuesOverviewCardsAsync.failure('FETCH ISSUES OVERVIEW CARDS FAILED'));
	}
}

export function* fetchIssuesResolvedChartSaga(
	action: ReturnType<typeof fetchIssuesResolvedChartAsync.request>,
): Generator {
	try {
		const fetchRes: FetchIssuesResolvedChartResponse = (yield call(fetchIssuesResolvedChart, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchIssuesResolvedChartAsync.success(fetchRes.data));
		} else {
			yield put(fetchIssuesResolvedChartAsync.failure('FETCH ISSUES RESOLVED CHART FAILED'));
		}
	} catch (e) {
		yield put(fetchIssuesResolvedChartAsync.failure('FETCH ISSUES RESOLVED CHART FAILED'));
	}
}

export function* fetchIssuesCategoriesChartSaga(
	action: ReturnType<typeof fetchIssuesCategoriesChartAsync.request>,
): Generator {
	try {
		const fetchRes: FetchIssuesCategoriesChartResponse = (yield call(
			fetchIssuesCategoriesChart,
			action.payload,
		)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchIssuesCategoriesChartAsync.success(fetchRes.data));
		} else {
			yield put(fetchIssuesCategoriesChartAsync.failure('FETCH ISSUES CATEGORIES CHART FAILED'));
		}
	} catch (e) {
		yield put(fetchIssuesCategoriesChartAsync.failure('FETCH ISSUES CATEGORIES CHART FAILED'));
	}
}

export function* fetchAllUserNPSSaga(): Generator {
	try {
		const fetchRes: FetchAllUserNPSResponse = (yield call(fetchAllUserNPS)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchAllUserNPSAsync.success(fetchRes.data));
		} else {
			yield put(fetchAllUserNPSAsync.failure('FETCH ALL USER NPS FAILED'));
		}
	} catch (e) {
		yield put(fetchAllUserNPSAsync.failure('FETCH ALL USER NPS FAILED'));
	}
}

export function* fetchUserNPSSaga(action: ReturnType<typeof fetchUserNPSAsync.request>): Generator {
	try {
		const fetchRes: FetchUserNPSResponse = (yield call(fetchUserNPS, action.payload)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchUserNPSAsync.success(fetchRes.data));
		} else {
			yield put(fetchUserNPSAsync.failure('FETCH USER NPS FAILED'));
		}
	} catch (e) {
		yield put(fetchUserNPSAsync.failure('FETCH USER NPS FAILED'));
	}
}

export function* upsertUserNPSSaga(action: ReturnType<typeof upsertUserNPSAsync.request>): Generator {
	try {
		// @ts-ignore
		const fetchRes: UpsertUserNPSResponse = (yield call(upsertUserNPS)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(upsertUserNPSAsync.success(fetchRes.data));
		} else {
			yield put(upsertUserNPSAsync.failure('UPSERT USER NPS FAILED'));
		}
	} catch (e) {
		yield put(upsertUserNPSAsync.failure('UPSERT USER NPS FAILED'));
	}
}

export function* fetchLiveOrganizationSaga(): Generator {
	try {
		const fetchRes: FetchLiveOrganizationResponse = (yield call(fetchLiveOrganization)) as any;

		if (fetchRes.message === 'SUCCESS') {
			yield put(fetchLiveOrganizationAsync.success(fetchRes.data));
		} else {
			yield put(fetchLiveOrganizationAsync.failure('FETCH LIVE ORGANIZATION FAILED'));
		}
	} catch (e) {
		yield put(fetchLiveOrganizationAsync.failure('FETCH LIVE ORGANIZATION FAILED'));
	}
}

export function* fetchMapStatisticsSaga(action: ReturnType<typeof fetchMapStatisticsAsync.request>): Generator {
	try {
		const { query } = action.payload;
		const { data, error, message } = (yield call(
			// @ts-ignore
			APIService.getMapStatisticOverview,
			query,
		)) as MapStatisticOverviewResponse;

		if (message === 'SUCCESS') {
			yield put(fetchMapStatisticsAsync.success({ data }));
		} else {
			yield put(fetchMapStatisticsAsync.failure({ error }));
		}
	} catch (e) {
		yield put(fetchMapStatisticsAsync.failure({ error: true }));
	}
}

export function* fetchMissedMakeupOverviewSaga(
	action: ReturnType<typeof fetchMissedMakeupOverviewAsync.request>,
): Generator {
	try {
		const data: internalStatistic.ReportSummaryOverview = (yield call(
			fetchMissedMakeupOverview,
			action.payload,
		)) as any;
		yield put(fetchMissedMakeupOverviewAsync.success(data));
	} catch (error) {
		yield put(fetchMissedMakeupOverviewAsync.failure(error.message));
	}
}

export function* fetchMissedMakeupListSaga(action: ReturnType<typeof fetchMissedMakeupListAsync.request>): Generator {
	try {
		const data: internalStatistic.ReportSummaryList = (yield call(fetchMissedMakeupMakeupList, action.payload)) as any;
		yield put(fetchMissedMakeupListAsync.success(data));
	} catch (error) {
		yield put(fetchMissedMakeupListAsync.failure(error.message));
	}
}

export default function* overviewSagaWatcher() {
	yield takeLatest(FETCH_OVERVIEW_DAILY_CHART_REQUEST, fetchOverviewDailyChartSaga);
	yield takeLatest(FETCH_ISSUES_OVERVIEW_CARDS_REQUEST, fetchIssuesOverviewCardsSaga);
	yield takeLatest(FETCH_ISSUES_RESOLVED_CHART_REQUEST, fetchIssuesResolvedChartSaga);
	yield takeLatest(FETCH_ISSUES_CATEGORIES_CHART_REQUEST, fetchIssuesCategoriesChartSaga);
	yield takeLatest(FETCH_ALL_USER_NPS_REQUEST, fetchAllUserNPSSaga);
	yield takeLatest(FETCH_USER_NPS_REQUEST, fetchUserNPSSaga);
	yield takeLatest(UPSERT_USER_NPS_REQUEST, upsertUserNPSSaga);
	yield takeLatest(FETCH_LIVE_ORGANIZATION_REQUEST, fetchLiveOrganizationSaga);
	yield takeLatest(FETCH_MAP_STATISTICS_REQUEST, fetchMapStatisticsSaga);
	yield takeLatest(FETCH_MISSED_MAKEUP_OVERVIEW_REQUEST, fetchMissedMakeupOverviewSaga);
	yield takeLatest(FETCH_MISSED_MAKEUP_LIST_REQUEST, fetchMissedMakeupListSaga);
}
