import {
	AvailableCourseAndSyllabusResponse,
	AvailableCoursesReponse,
	AvailableSyllabusesResponse,
	Course,
	InProgressCourseAndSyllabusResponse,
	LearnerActivity,
	PaginationOptions,
	PaginationResult,
	PatchLearnerActivityRequest,
	MyCourseAndSyllabusResponse,
} from '@nimbly-technologies/nimbly-common';
import queryString, { UrlObject } from 'query-string';
import { IAPIResponse } from 'types/api';
import { apiURL } from '../../config/baseURL';
import API from '../../helpers/api';
import { IDashboardLessonStatusStore } from 'reducers/lms/lmsDashboard/lmsDashboard.type';
import Monitoring from 'utils/monitoring/Monitoring';
export const fetchLmsCourses = async (): Promise<any[]> => {
	const url = `${apiURL}/lms/courses/all`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export const fetchLmsCourseById = async (id: string): Promise<Course | undefined> => {
	const url = `${apiURL}/lms/courses/${id}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON<IAPIResponse<Course>>(url, token);
	return res?.data;
};

export interface IFetchLmsCoursesSyllabusesAvailableParams extends Partial<PaginationOptions> {
	sortFields?: 'name' | 'publishedDate' | 'expiryDate';
	sortDirections?: 'asc' | 'desc';
}
export const fetchLmsCoursesSyllabusesAvailable = async (
	params?: IFetchLmsCoursesSyllabusesAvailableParams,
): Promise<PaginationResult<AvailableCourseAndSyllabusResponse[]> | undefined> => {
	const url = queryString.stringifyUrl({
		url: `${apiURL}/lms/courses-syllabuses/available`,
		query: params as UrlObject['query'],
	});

	const res = await API.getJSON<IAPIResponse<PaginationResult<AvailableCourseAndSyllabusResponse[]>>>(url);

	return res?.data;
};

export interface IFetchLmsCoursesSyllabusesInProgressParams extends Partial<PaginationOptions> {
	sortFields?: 'updatedAt';
	sortDirections?: 'asc' | 'desc';
}
export const fetchLmsCoursesSyllabusesInProgress = async (
	params?: IFetchLmsCoursesSyllabusesInProgressParams,
): Promise<PaginationResult<InProgressCourseAndSyllabusResponse[]> | undefined> => {
	const url = queryString.stringifyUrl({
		url: `${apiURL}/lms/courses-syllabuses/in-progress`,
		query: params as UrlObject['query'],
	});

	const res = await API.getJSON<IAPIResponse<PaginationResult<InProgressCourseAndSyllabusResponse[]>>>(url);

	return res?.data;
};

// TODO: Remove type definition when nimby common entity is updated
export type TCoursesSyllabusesRecentlyViewedResponse = {
	availableUntil?: number;
} & (AvailableCoursesReponse | AvailableSyllabusesResponse);
export type TFetchLmsCoursesSyllabusesRecentlyViewedParams = Partial<PaginationOptions>;
export const fetchLmsCoursesSyllabusesRecentlyViewed = async (
	params?: TFetchLmsCoursesSyllabusesRecentlyViewedParams,
): Promise<PaginationResult<TCoursesSyllabusesRecentlyViewedResponse[]> | undefined> => {
	const url = queryString.stringifyUrl({
		url: `${apiURL}/lms/courses-syllabuses/recently-viewed`,
		query: params as UrlObject['query'],
	});

	const res = await API.getJSON<IAPIResponse<PaginationResult<TCoursesSyllabusesRecentlyViewedResponse[]>>>(url);

	return res?.data;
};

export interface IEnrollLmsCourseSyllabus {
	courseID?: string;
	syllabusID?: string;
}
export const enrollLmsCourseSyllabus = async (args: IEnrollLmsCourseSyllabus) => {
	const url = `${apiURL}/lms/courses-syllabuses/enroll`;

	const token = await API.getFirebaseToken();
	await API.post(url, token, args);
};

export interface IFetchLmsMyCoursesSyllabusesParams extends Partial<PaginationOptions> {
	sortFields?: 'title' | 'publishedDate';
	sortDirections?: 'asc' | 'desc';
}
export const fetchLmsMyCoursesSyllabuses = async (
	params?: IFetchLmsMyCoursesSyllabusesParams,
): Promise<PaginationResult<MyCourseAndSyllabusResponse[]> | undefined> => {
	const url = queryString.stringifyUrl({
		url: `${apiURL}/lms/courses-syllabuses`,
		query: params as UrlObject['query'],
	});

	const res = await API.getJSON<IAPIResponse<PaginationResult<MyCourseAndSyllabusResponse[]>>>(url);

	return res?.data;
};

export const fetchLmsIndicator = async (): Promise<IDashboardLessonStatusStore | undefined> => {
	try {
		let url = `${apiURL}/lms/dashboard/status`;

		const res = await API.getJSON(url);

		return res?.data as IDashboardLessonStatusStore;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchLmsInstructorsList = async (): Promise<Record<string, any>[]> => {
	try {
		let url = `${apiURL}/lms/users/compact?role=instructor`;

		const res = await API.getJSON(url);

		return res?.data;
	} catch (error) {
		Monitoring.logError(error as Error);
		return [];
	}
};

export const fetchLmsCourseList = async (): Promise<Record<string, any>[]> => {
	try {
		let url = `${apiURL}/lms/syllabuses/all`;

		const res = await API.getJSON(url);

		return res?.data;
	} catch (error) {
		Monitoring.logError(error as Error);
		return [];
	}
};

export const fetchLmsLessonList = async (): Promise<Record<string, any>[]> => {
	try {
		let url = `${apiURL}/lms/courses/all`;

		const res = await API.getJSON(url);

		return res?.data;
	} catch (error) {
		Monitoring.logError(error as Error);
		return [];
	}
};
