import { PaginationResult } from '@nimbly-technologies/nimbly-common';
import { FILE_REPO_API_URL } from 'config/baseURL';
import API from 'helpers/api';
import { UserUsageDetail } from 'reducers/repositoryUserUsage/type';

export const getData = async (url: string): Promise<any> => {
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw new Error(json.message);
	}
	return json.data;
};

export const fetchRepositoryUserUsage = async (queryParams: string): Promise<PaginationResult<UserUsageDetail>> => {
	const url = `${FILE_REPO_API_URL}/user/all?${queryParams}`;
	return await getData(url);
};
