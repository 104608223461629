import { action, createAsyncAction } from 'typesafe-actions';
import { LmsUserGroupI } from './type';
import * as types from './userGroup.actionTypes';

export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });
export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });
export const createUserGroup = createAsyncAction(
	types.CREATE_USER_GROUP_REQUEST,
	types.CREATE_USER_GROUP_SUCCESS,
	types.CREATE_USER_GROUP_FAILURE,
)<{ data: LmsUserGroupI }, undefined, { error: string }>();
export const updateUserGroup = createAsyncAction(
	types.UPDATE_USER_GROUP_REQUEST,
	types.UPDATE_USER_GROUP_SUCCESS,
	types.UPDATE_USER_GROUP_FAILURE,
)<{ groupID: string; data: LmsUserGroupI }, undefined, { error: string; errorData?: string[] | null }>();
export const setSelectedEditLmsUser = (data: any) => action(types.SELECTED_EDIT_LMS_USER_GROUP, { data });
export const setSelectedEditLmsUsers = (data: any) => action(types.SELECTED_EDIT_LMS_USERS_GROUP, { data });
