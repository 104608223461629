import { createAsyncAction } from 'typesafe-actions';
import * as types from './siteCompact.actionTypes';
import { SiteCompact } from 'nimbly-common';
import { SiteCompactPaginateData } from './siteCompactPaginate.reducer';

export const siteCompactAction = createAsyncAction(
	types.FETCH_COMPACT_SITE_REQUEST,
	types.FETCH_COMPACT_SITE_SUCCESS,
	types.FETCH_COMPACT_SITE_FAILURE,
)<{ withDepartmentAccess?: boolean }, { data: SiteCompact[]; message: string }, { message: string }>();

export const siteCompactPaginateAction = createAsyncAction(
	types.FETCH_COMPACT_SITE_PAGINATE_REQUEST,
	types.FETCH_COMPACT_SITE_PAGINATE_SUCCESS,
	types.FETCH_COMPACT_SITE_PAGINATE_FAILURE,
)<
	{ page: number; limit: number; search?: string },
	{
		data: SiteCompactPaginateData;
		message: string;
	},
	{ message: string }
>();
