import { FetchCompletionCardsPayload, FetchCompletionCardsResponse } from '../actionType';
import { apiURL } from 'config/baseURL';
import { getDummyCompletionCards } from 'utils/dashboardDummy/overview/dummyCompletionCards';
import API from 'helpers/api';

export const fetchCompletionCards = async (
	payload: FetchCompletionCardsPayload,
): Promise<FetchCompletionCardsResponse> => {
	const { isDummy, ...query } = payload;

	// HANDLE DUMMY DATA
	if (isDummy) {
		const dummyCompletionCards = getDummyCompletionCards(payload);
		return {
			message: 'SUCCESS',
			data: dummyCompletionCards,
		};
	}

	try {
		const token = await API.getFirebaseToken();
		// eslint-disable-next-line no-undef
		const options: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: token,
			},
			body: JSON.stringify(query),
		};
		const urlOverview = `${apiURL}/statistics/sites/completion-overview`;

		const responseOverview = await fetch(urlOverview, options);
		// THROW IF RESPONSE NOT OK
		if (responseOverview.status !== 200) {
			throw new Error('');
		}

		const completionResult: FetchCompletionCardsResponse = await responseOverview.json();
		return completionResult;
	} catch {
		// ERROR
		const errorResult: FetchCompletionCardsResponse = {
			message: 'FAILED',
		};
		return errorResult;
	}
};
