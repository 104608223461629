import { all, call, put, takeEvery } from 'typed-redux-saga';
import * as actions from '../../reducers/skuCategory/skuCategory.action';
import { createSkuCategory, fetchSkuCategories } from '../../services/sku/sku.service';

function* fetchSkuCategoriesSaga() {
	try {
		const categories: string[] = yield* call(fetchSkuCategories);
		yield put(actions.fetchSkuCategories.success(categories));
	} catch {
		yield put(actions.fetchSkuCategories.failure('Failed to get categories'));
	}
}

function* createAndRefreshSkuCategoriesSaga(action: ReturnType<typeof actions.createAndRefreshSkuCategory.request>) {
	try {
		yield* call(createSkuCategory, action.payload);
		const categories: string[] = yield* call(fetchSkuCategories);
		yield put(actions.createAndRefreshSkuCategory.success(categories));
	} catch {
		yield put(actions.createAndRefreshSkuCategory.failure('Failed to create category'));
	}
}

export default function* skuCategorySaga() {
	yield all([
		takeEvery(actions.createAndRefreshSkuCategory.request.toString(), createAndRefreshSkuCategoriesSaga),
		takeEvery(actions.fetchSkuCategories.request.toString(), fetchSkuCategoriesSaga),
	]);
}
