import { takeLatest, put, call, select } from 'redux-saga/effects';
import moment from 'moment';
import { getFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';

import { getToken } from 'reducers/api';
import * as types from 'reducers/product/product.actionTypes';
import * as actions from 'reducers/product/product.action';

import { uploadImage } from '../../utils/file';
import { fmcgURL } from 'config/baseURL';
import { RootState } from 'store/rootReducers';
import { ProductState } from 'reducers/product/type';
const apiURL = fmcgURL;

type Action = {
  type: string;
  payload: any;
  meta: string | undefined;
};

export function* fetchPaginateProducts() {
  try {
    const getState = (state: RootState) => state;
    const state: RootState = yield select(getState);
    const firebaseToken = yield getToken();
    const productsState: ProductState = state.product;

    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };
    let sortDirections: string = 'asc';

    switch (productsState.sortBy) {
      case 'company':
        sortDirections = productsState.company;
        break;
      case 'name':
        sortDirections = productsState.name;
        break;
      case 'category':
        sortDirections = productsState.category;
        break;
      case 'variant':
        sortDirections = productsState.variant;
        break;
      case 'priceFrom':
        sortDirections = productsState.priceFrom;
        break;
      case 'priceTo':
        sortDirections = productsState.priceTo;
        break;
      default:
        break;
    }

    const query: { [key: string]: string | number } = {
      search: productsState.filterQuery || '',
      page: productsState.pageIndex,
      sortFields: productsState.sortBy,
      sortDirections,
      limit: 15
    };

    const queryStr = Object.keys(query).length
      ? Object.keys(query)
          .map((key: string) => {
            return `${key}=${query[key]}`;
          })
          .join('&')
      : '';
    const fetchSitesURL = `${apiURL}/competitor/products/paginate?${queryStr}`;
    const request = () => fetch(fetchSitesURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(
        actions.fetchPaginationProductAsync.success({
          data: responseData.data.docs,
          total: responseData.data.totalDocs
        })
      );
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchPaginationProductAsync.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchPaginationProductAsync.failure({ error: 'Failed to Fetch Sites' }));
    return null;
  }
}

export function* fetchAllProduct() {
  try {
    yield put(actions.setLoading(true));
    const firebaseToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchAllProductUrl = `${apiURL}/competitor/products`;
    const request = () => fetch(fetchAllProductUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData: types.FETCH_ProductsResponse = yield response.json();

      yield put(actions.fetchProduct.success(responseData.data));
      return responseData.data;
    } else {
      const responseData: types.FETCH_ProductsResponse = yield response.json();
      yield put(actions.fetchProduct.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchProduct.failure({ error: 'Failed to Fetch Product List' }));
    return null;
  }
}

function* createProduct(action: Action) {
  try {
    const payload: types.POST_ProductsPayload = { ...action.payload };
    const firebase = getFirebase();
    const createProductUrl = `${apiURL}/competitor/products`;

    if (payload.imgFile) {
      const baseRef = `/competitorAnalysis/product`;
      const imageRef = `${baseRef}/${payload.imgFile.name}-${moment().format('YYYY-MM-DD-X')}`;

      const photoURL = yield uploadImage(firebase, imageRef, payload.imgFile);
      payload.product.image = photoURL;
    }
    const firebaseToken = yield getToken();
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: firebaseToken
      },
      body: JSON.stringify(payload.product)
    };

    const request = () => fetch(createProductUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData: types.POST_ProductsResponse = yield response.json();
      yield put(actions.createProduct.success(responseData.data.name));
      toast.success(`Success create product ${responseData.data.name}`);
    } else {
      const responseData: types.POST_ProductsResponse = yield response.json();
      yield put(actions.createProduct.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.createProduct.failure({ error: 'Failed to Create Product' }));
    return null;
  }
}

function* createProductBulk(action: Action) {
  try {
    const payload: types.POST_ProductsBulkPayload = action.payload;
    let success: string[] = [];
    let error: string[] = [];
    const firebaseToken = yield getToken();

    const companyID = payload.companyID;
    for (let i = 0; i < payload.products.length; i++) {
      const productPayload = { ...payload.products[i], companyCode: companyID };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: firebaseToken
        },
        body: JSON.stringify(productPayload)
      };

      const createProductUrl = `${apiURL}/competitor/products`;

      const request = () => fetch(createProductUrl, options);
      const response = yield call(request);

      if (response && response.status === 200) {
        const responseData: types.POST_ProductsResponse = yield response.json();
        yield put(actions.createProduct.success(responseData.data.name));
        success.push(responseData.data.name);
      } else {
        const responseData = yield response.json();
        error.push(responseData.message);
      }
    }
    yield put(actions.createProductBulk.success(`${success.length}/${payload.products.length}`));
    toast.success(`Success create ${payload.products.length} product`);

    if (success.length !== payload.products.length) {
      yield put(actions.createProduct.failure({ error: error.join(' ') }));
      yield put(actions.createProductBulk.failure(error));
      return null;
    }
  } catch (e) {
    yield put(actions.createProduct.failure({ error: 'Failed to Create Product' }));
    return null;
  }
}

export function* fetchProductDetail(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const firebaseToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const productID: string = action.payload;
    const fetchProductDetail = `${apiURL}/competitor/products/${productID}`;
    const request = () => fetch(fetchProductDetail, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData: types.FETCH_ProductDetailResponse = yield response.json();
      yield put(actions.fetchProductDetail.success(responseData.data));
      return responseData;
    } else {
      const responseData: types.FETCH_ProductDetailResponse = yield response.json();
      yield put(actions.fetchProductDetail.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchProductDetail.failure({ error: 'Failed to Fetch Product Detail' }));
    return null;
  }
}

function* updateProduct(action: Action) {
  try {
    const payload: types.PUT_ProductsPayload = { ...action.payload };
    const firebase = getFirebase();
    const firebaseToken = yield getToken();

    if (payload.imgFile) {
      const baseRef = `/competitorAnalysis/product`;
      const imageRef = `${baseRef}/${payload.imgFile.name}-${moment().format('YYYY-MM-DD-X')}`;

      const photoURL = yield uploadImage(firebase, imageRef, payload.imgFile);
      payload.product.image = photoURL;
    }
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: firebaseToken
      },
      body: JSON.stringify(payload.product)
    };

    const updateProductUrl = `${apiURL}/competitor/products/${payload.product.code}`;

    const request = () => fetch(updateProductUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseJson: types.PUT_ProductsResponse = yield response.json();
      yield put(actions.updateProduct.success(responseJson.data.name));
      toast.success('Success update product');
    } else {
      const responseJson: types.PUT_ProductsResponse = yield response.json();
      yield put(actions.updateProduct.failure({ error: responseJson.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.updateProduct.failure({ error: 'Failed to Update Product' }));
    return null;
  }
}

export function* fetchAllCategory() {
  try {
    const firebaseToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchAllCategoryUrl = `${apiURL}/competitor/categories`;
    const request = () => fetch(fetchAllCategoryUrl, options);
    const response = yield call(request);

    if (response && response?.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchCategory.success({ data: responseData.data }));
      return responseData.data;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchCategory.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchCategory.success({ data: [] }));
    yield put(actions.fetchCategory.failure({ error: 'Failed to Fetch Category List' }));
    return null;
  }
}

export function* fetchAllTargetMarket() {
  try {
    const firebaseToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchAllCategoryUrl = `${apiURL}/competitor/target-market`;
    const request = () => fetch(fetchAllCategoryUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchTargetMarket.success({ data: responseData.data }));
      return responseData.data;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchTargetMarket.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchTargetMarket.failure({ error: 'Failed to Fetch Category List' }));
    return null;
  }
}

export default function* productSaga() {
  yield takeLatest(types.FETCH_PRODUCTS_REQUEST, fetchAllProduct);
  yield takeLatest(types.CREATE_PRODUCTS_REQUEST, createProduct);
  yield takeLatest(types.FETCH_PRODUCT_DETAIL_REQUEST, fetchProductDetail);
  yield takeLatest(types.UPDATE_PRODUCT_REQUEST, updateProduct);
  yield takeLatest(types.CREATE_PRODUCTS_BULK_REQUEST, createProductBulk);
  yield takeLatest(types.FETCH_PRODUCT_CATEGORY_REQUEST, fetchAllCategory);
  yield takeLatest(types.FETCH_PRODUCT_TARGET_MARKET_REQUEST, fetchAllTargetMarket);
  yield takeLatest(types.FETCH_PRODUCTS_PAGINATION_REQUEST, fetchPaginateProducts);
}
