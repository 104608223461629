import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './lmsSettings.action';
import { LMSSettingsState } from './type';
import * as types from './lmsSettings.actionTypes';

const initialState: LMSSettingsState = {
	isLoading: false,
	data: null,
	message: '',
};

export type lmsSettingAction = ActionType<typeof actions.lmsSettingsAction>;
export const lmsSettingsReducer = createReducer<LMSSettingsState, lmsSettingAction>(initialState)
	.handleAction(actions.lmsSettingsAction.request, (state) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(actions.lmsSettingsAction.success, (state, action) => ({
		...state,
		data: action.payload.data,
		message: action.payload.message,
		isLoading: false,
	}))
	.handleAction(actions.lmsSettingsAction.failure, (state, action) => ({
		...state,
		message: action.payload.message,
		isLoading: false,
	}))
	.handleAction(types.SET_DOWNLOAD_CONTENT as any, (state, action) => ({
		...state,
		data: state?.data
			? {
					...state?.data,
					allowDownloadContent: action.payload.allow,
			  }
			: null,
	}))
	.handleAction(types.SET_SHOW_QUIZ_ANSWER as any, (state, action) => ({
		...state,
		data: state?.data
			? {
					...state?.data,
					showCorrectQuizAnswer: action.payload.show,
			  }
			: null,
	}));
