import { ToggleBlockSitePayload, ToggleBlockSiteResponse } from 'reducers/admin/manage/sites/types';
import { getToken } from 'reducers/api';
import { appengineURL } from 'config/baseURL';
import Monitoring from '../../../../../utils/monitoring/Monitoring';

const blockBody = {
  block: {
    disabled: true
  },
  unblock: {
    disabled: false
  }
};

export const blockSite = async ({ siteID, action }: ToggleBlockSitePayload): Promise<ToggleBlockSiteResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify(blockBody[action])
    };
    const url = `${appengineURL}/sites/${siteID}`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const blockSiteResult: ToggleBlockSiteResponse = await res.json();
    return blockSiteResult;
  } catch (e) {
    Monitoring.logEvent('blockSite', e);
    const errorResult: ToggleBlockSiteResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
