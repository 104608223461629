import { createReducer, ActionType } from 'typesafe-actions';
import { MissedMakeupOverviewState, MissedMakeupReportListState } from './types';
import { fetchMissedMakeupOverviewAsync, fetchMissedMakeupListAsync } from './action';

const missedMakeupActions = {
	fetchMissedMakeupOverviewAsync,
	fetchMissedMakeupListAsync,
};

export type MissedMakeupAction = ActionType<typeof missedMakeupActions>;
export type MissedMakeupState = MissedMakeupOverviewState & MissedMakeupReportListState;

const initialState: MissedMakeupState = {
	/** Missed Makeup Report Overview State */
	missedMakeupOverview: {
		data: {
			missed: {
				startDate: '',
				endDate: '',
				num: 0,
				percentage: 0,
			},
			makeup: {
				startDate: '',
				endDate: '',
				num: 0,
				percentage: 0,
			},
			ontime: {
				startDate: '',
				endDate: '',
				num: 0,
				percentage: 0,
			},
		},
		isLoading: false,
		error: '',
	},
	/** Missed Makeup Report List State */
	missedMakeupReportList: {
		data: {
			docs: [],
			limit: 15,
			page: 1,
			totalDocs: 0,
			totalPage: 1,
		},
		isLoading: false,
		error: '',
	},
};

export const missedMakeupReducer = createReducer<MissedMakeupState, MissedMakeupAction>(initialState)
	.handleAction(fetchMissedMakeupOverviewAsync.request, (state) => ({
		...state,
		missedMakeupOverview: {
			...state.missedMakeupOverview,
			isLoading: true,
			error: '',
		},
	}))
	.handleAction(fetchMissedMakeupOverviewAsync.failure, (state, action) => ({
		...state,
		missedMakeupOverview: {
			...state.missedMakeupOverview,
			error: action.payload,
			isLoading: false,
		},
	}))
	.handleAction(fetchMissedMakeupOverviewAsync.success, (state, action) => ({
		...state,
		missedMakeupOverview: {
			...state.missedMakeupOverview,
			data: action.payload,
			isLoading: false,
		},
	}))
	.handleAction(fetchMissedMakeupListAsync.request, (state) => ({
		...state,
		missedMakeupReportList: {
			...state.missedMakeupReportList,
			isLoading: true,
			error: '',
		},
	}))
	.handleAction(fetchMissedMakeupListAsync.failure, (state, action) => ({
		...state,
		missedMakeupReportList: {
			...state.missedMakeupReportList,
			error: action.payload,
			isLoading: false,
		},
	}))
	.handleAction(fetchMissedMakeupListAsync.success, (state, action) => ({
		...state,
		missedMakeupReportList: {
			...state.missedMakeupReportList,
			data: action.payload,
			isLoading: false,
		},
	}));
