import { ActionType, createReducer } from 'typesafe-actions';
import { LmsCoursesState, LmsCoursesFetchState } from './type.d';
import * as actions from './enrollCourseUserGroup.action';
import * as types from './enrollCourseUserGroup.actionTypes';

export type CourseEnrollAction = ActionType<typeof actions>;

export const courseInitialState: LmsCoursesState = {
	pageIndex: 1,
	limit: 15,
	search: '',
	fetchState: LmsCoursesFetchState.SUCCESS,
	sortBy: '',
	sortDirection: 'asc',
	courseList: [],
	totalCourse: 0,
	isShowModal: false,
	isLoading: false,
	isSuccess: false,
};

const getCourseListReducer = createReducer<LmsCoursesState, CourseEnrollAction>(courseInitialState)
	.handleType(types.GET_COURSE_LIST_REQUEST, (state) => ({
		...state,
		limit: courseInitialState.limit,
		fetchState: LmsCoursesFetchState.LOADING,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleType(types.GET_COURSE_LIST_SUCCESS, (state, action) => ({
		...state,
		courseList: action.payload.data.docs,
		fetchState: LmsCoursesFetchState.SUCCESS,
		isLoading: false,
		totalCourse: action.payload.data.totalDocs,
		isSuccess: true,
	}))
	.handleType(types.GET_COURSE_LIST_FAILURE, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleType(types.SET_COURSE_LIST_PAGE_INDEX, (state, action) => ({
		...state,
		pageIndex: action.payload.pageIndex,
		fetchState: LmsCoursesFetchState.LOADING,
		isLoading: true,
		isSuccess: false,
	}))
	.handleType(types.SET_COURSE_LIST_SEARCH_QUERY, (state, action) => ({
		...state,
		search: action.payload.search,
		fetchState: LmsCoursesFetchState.LOADING,
		isLoading: true,
		isSuccess: false,
	}))
	.handleType(types.SET_COURSE_LIST_SORT, (state, action) => ({
		...state,
		sortBy: action.payload.sortBy,
		sortDirection: action.payload.sortDirection,
		fetchState: LmsCoursesFetchState.LOADING,
		isLoading: true,
		isSuccess: false,
	}))

	.handleType(types.SET_SHOW_MODAL, (state, action) => ({
		...state,
		...courseInitialState,
		isShowModal: action.payload.value,
	}))

	.handleType(types.UPDATE_USERGROUP_ENROLLCOURSE_REQUEST, (state, action) => {
		return {
			...state,
			isLoading: true,
		};
	})
	.handleType(types.UPDATE_USERGROUP_ENROLLCOURSE_SUCCESS, (state, action) => {
		return {
			...state,
			isSuccess: true,
		};
	})
	.handleType(types.UPDATE_USERGROUP_ENROLLCOURSE_FAILURE, (state, action) => {
		return {
			...state,
			isLoading: false,
			error: action.payload.error,
		};
	})
	.handleType(types.RESET_COURSE_FILTER, (state) => {
		return {
			...state,
			...courseInitialState,
		};
	});

export { getCourseListReducer };
