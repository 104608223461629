export enum SiteActivityHistoryFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface SiteActivityHistoryResponse {
	userName: string;
	timestamp: Date;
}

export interface SiteActivityHistory {
	userName: string;
	date: string;
	time: string;
}

export interface SiteActivityHistoryState {
	error: string;
	fetchState: SiteActivityHistoryFetchState;
	siteActivityHistory: SiteActivityHistoryResponse[];
}
