import styled from 'styled-components/macro';

export const ProgressBoardContainer = styled.div`
  background-color: #574fcf;
  height: 67px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 992px) {
    height: 56px;
    padding: 10px;
  }
`;

export const Container = styled.div<{ done?: boolean }>`
  @media (max-width: 992px) {
    display: none;
  }
  display: flex;
  height: 100px;
  padding: 0.5rem;
  cursor: default;
  position: relative;
  justify-content: center;
  width: 160px;
  .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      color: white;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      font-style: normal;
    }
    > .connector {
      width: calc(100% + 10px);
      height: 2px;
      background: ${props => (props.done ? '#56C768' : 'white')};
      top: 20px;
      left: calc(30% + 25px);
      position: absolute;
      z-index: 1;
    }
  }
`;

export const Circle = styled.div<{ done?: boolean; current?: boolean }>`
  width: 18px;
  height: 18px;

  display: flex;
  margin: 0.2rem;
  border-radius: 50%;
  background: ${props => (props.done ? '#56C768' : props.current ? '#F6BB42' : 'white')};
  justify-content: center;
  align-items: center;
  z-index: 2;
  span {
    color: white;
  }
  > .checkmark {
    font-family: arial;
    -ms-transform: scaleX(-1) rotate(-35deg); /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-35deg);
    color: white;
    font-size: 9px;
  }
  @media (max-width: 992px) {
    width: 10.8px;
    height: 10.8px;

    display: flex;
    margin: 0.2rem;
    border-radius: 50%;
    background: ${props => (props.done ? '#56C768' : props.current ? '#F6BB42' : 'white')};
    justify-content: center;
    align-items: center;
    z-index: 2;
    span {
      color: white;
    }
  }
`;

export const MobileContainer = styled.div<{ done?: boolean }>`
  @media (min-width: 992px) {
    display: none;
  }
  display: flex;
  height: 100px;
  padding: 0.5rem;
  cursor: default;
  position: relative;
  justify-content: center;
  width: 145px;
  .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      color: white;
      font-weight: 600;
      font-size: 10px;
      line-height: 9px;
      text-align: center;
      font-style: normal;
    }
    > .connector {
      width: calc(100% + 7px);
      height: 2px;
      background: ${props => (props.done ? '#56C768' : 'white')};
      top: calc(15%);
      left: calc(46%);
      position: absolute;
      z-index: 1;
    }
`;
