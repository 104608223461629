import { CoursePaginateResponse, PaginationResult } from '@nimbly-technologies/nimbly-common';
import { CourseBulkErrorResponse, CourseState } from 'reducers/courses/type.d';
import { fetchCourses, editCourses, editCourse } from 'services/lms/courses/courses.service';
import { RootState } from 'store/rootReducers';
import { call, put, select } from 'typed-redux-saga';
import * as actions from '../../../reducers/courses/courses.action';
import * as types from '../../../reducers/courses/courses.actionTypes';
import queryString from 'query-string';
import { takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { toast } from 'react-toastify';
import { fetchSyllabuses } from 'reducers/syllabuses/syllabuses.action';
import {
	setSelectedCourseRows,
	setSelectedCourses,
	toggleBulkEditErrorModal,
} from 'reducers/lms/lmsCsManagementPage/lmsCsManagementPage.action';
import { LMSUserRole } from '@nimbly-technologies/nimbly-common/lib/enumerators';
export function* fetchCoursesSaga(action: ReturnType<typeof actions.fetchCourses.request>) {
	try {
		if (action.payload.status) {
			yield put(
				actions.setCoursesFilters({
					status: [action.payload.status],
					...(action.payload.accessType && { accessType: action.payload.accessType }),
				}),
			);
		}

		const { page, limit, sortField, sortDirection, search, filters }: CourseState = yield select(
			(state: RootState) => state.courses,
		);
		const { auth } = yield select((state: RootState) => state.firebase);
		const { lmsRole } = yield select((state: RootState) => state.userAccess);

		const newFilters = {
			...filters,
			startDate: filters.startDate ? moment(filters.startDate).valueOf() : undefined,
			endDate: filters.endDate ? moment(filters.endDate).endOf('day').valueOf() : undefined,
			expiryStartDate: filters.expiryStartDate ? moment(filters.expiryStartDate).valueOf() : undefined,
			expiryEndDate: filters.expiryEndDate ? moment(filters.expiryEndDate).endOf('day').valueOf() : undefined,
			status: action.payload.status ?? filters.status,
			userIDs: lmsRole === LMSUserRole.INSTRUCTOR ? [auth.uid] : filters.userIDs,
		};

		const query: Record<string, any> = {
			page,
			limit,
			sortFields: sortField,
			sortDirections: sortDirection,
			search,
			...newFilters,
		};

		const queryStr = queryString.stringify(query);
		const { docs, ...result }: PaginationResult<CoursePaginateResponse> = yield* call(fetchCourses, queryStr);

		yield put(actions.fetchCourses.success({ courses: docs, ...result }));
	} catch (e) {
		yield put(actions.fetchCourses.failure({ error: 'Failed to fetch courses' }));
		return null;
	}
}

export function* editCoursesSaga(action: ReturnType<typeof actions.editCourses.request>) {
	try {
		yield* call(editCourses, action.payload.editedCourses);
		yield put(actions.editCourses.success());
		toast.success(action.payload.successMessage);

		yield put(actions.fetchCourses.request({}));
		yield put(setSelectedCourses([]));
		yield put(setSelectedCourseRows([]));
		yield put(fetchSyllabuses.request());
	} catch (e) {
		yield put(toggleBulkEditErrorModal(true));
		yield put(actions.editCourses.failure({ error: e as CourseBulkErrorResponse[] }));
		return null;
	}
}

export function* editCourseSaga(action: ReturnType<typeof actions.editCourse.request>) {
	try {
		yield* call(editCourse, action.payload.courseID, action.payload.editedCourse);
		yield put(actions.editCourse.success());
		toast.success(action.payload.successMessage);

		yield put(actions.fetchCourses.request({}));
	} catch (e) {
		toast.error(e as string);
		yield put(actions.editCourse.failure({ error: e as string }));
		return null;
	}
}

export default function* coursesSaga() {
	yield takeLatest(types.FETCH_COURSES_REQUEST, fetchCoursesSaga);
	yield takeLatest(types.SET_COURSES_PAGE_INDEX, fetchCoursesSaga);
	yield takeLatest(types.SET_COURSES_FILTER_QUERY, fetchCoursesSaga);
	yield takeLatest(types.RESET_COURSES_FILTER_QUERY, fetchCoursesSaga);
	yield takeLatest(types.SET_COURSES_SEARCH_QUERY, fetchCoursesSaga);
	yield takeLatest(types.SET_COURSES_SORT, fetchCoursesSaga);
	yield takeLatest(types.EDIT_COURSES_REQUEST, editCoursesSaga);
	yield takeLatest(types.EDIT_COURSE_REQUEST, editCourseSaga);
}
