import { ActionType, createReducer } from 'typesafe-actions';
import { LMSUserState } from './type';
import * as actions from './user.action';
import * as types from './user.actionTypes';
import { LMSUserRole } from '@nimbly-technologies/nimbly-common/lib/enumerators/lmsUserRole';

export type CreateUserAction = ActionType<typeof actions>;

export const userInitialState: LMSUserState = {
	userName: '',
	email: '',
	userGroups: [],
	lmsRole: LMSUserRole.LEARNER,
	language: 'in',
	isSuccess: false,
	isLoading: false,
};

const createUserReducer = createReducer<LMSUserState, CreateUserAction>(userInitialState)
	.handleAction(actions.createUser.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.createUser.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.createUser.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
		isSuccess: false,
	}))
	.handleAction(actions.updateUser.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.updateUser.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
		isSuccess: false,
		errorData: action.payload.errorData || null,
	}))
	.handleAction(actions.updateUser.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.deleteUser.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.deleteUser.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.deleteUser.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
		isSuccess: false,
		errorData: action.payload.errorData || null,
	}))
	.handleAction(types.SELECTED_EDIT_LMS_USER as any, (state, action) => ({
		...state,
		selectedEditLmsUser: action.payload.data,
	}))
	.handleAction(types.SELECTED_EDIT_LMS_USERS as any, (state, action) => ({
		...state,
		selectedEditLmsUsers: action.payload.data,
	}))
	.handleAction(types.SET_SHOW_MODAL as any, (state, action) => ({
		...state,
		isShowModal: action.payload.value,
	}))
	.handleAction(types.SET_SUCCESS as any, (state, action) => {
		return {
			...state,
			isSuccess: action.payload.value,
		};
	});

export { createUserReducer };
