import { createReducer } from 'typesafe-actions';
import { OverviewState } from './types';
import { OverviewAction } from './action';
import { fetchOverviewDailyChartAsync } from './dailyChart/action';
import { fetchIssuesOverviewCardsAsync } from './issuesOverviewCards/action';
import { fetchIssuesResolvedChartAsync } from './issuesResolvedChart/action';
import { fetchIssuesCategoriesChartAsync } from './issuesCategoriesChart/action';
import { fetchUserNPSAsync, upsertUserNPSAsync, fetchAllUserNPSAsync } from './userNPS/action';
import { fetchLiveOrganizationAsync } from './liveOrganization/action';
import { fetchMapStatisticsAsync } from './mapStatistics/action';

const initialState: any = {
	/**
	 * Daily Chart State
	 */
	dailyChart_loading: false,
	dailyChart_data: [],
	dailyChart_error: '',
	/**
	 * Issues Overview Cards State
	 */
	issuesOverviewCards_loading: false,
	issuesOverviewCards_data: {
		issuesCompleted: 0,
		prevIssuesCompleted: 0,
		issuesCompletedAvgTime: 0,
		prevIssuesCompletedAvgTime: 0,
		issuesCompletionRate: 'N/A',
		prevIssuesCompletionRate: 'N/A',
		issuesCreated: 0,
		prevIssuesCreated: 0,
		issuesDue: 0,
		prevIssuesDue: 0,
		issuesOverdue: 0,
		prevIssuesOverdue: 0,
		// Currently not in use
		issuesResolved: 0,
		prevIssuesResolved: 0,
		issuesResolvedAvgTime: 0,
		prevIssuesResolvedAvgTime: 0,
	},
	issuesOverviewCards_error: '',
	/**
	 * Issues Resolved Chart State
	 */
	issuesResolvedChart_loading: false,
	issuesResolvedChart_data: {
		daily: [],
		overview: {
			growthRate: 0,
			isGrowthValid: false,
			issuesCompleted: 0,
			issuesDue: 0,
			prevIssuesCompleted: 0,
			prevIssuesDue: 0,
		},
	},
	issuesResolvedChart_error: '',
	/**
	 * Issues Resolved Chart State
	 */
	issuesCategoriesChart_loading: false,
	issuesCategoriesChart_data: [],
	issuesCategoriesChart_error: '',
	/**
	 * User NPS State
	 */
	allUserNPS_loading: false,
	allUserNPS_data: [],
	allUserNPS_error: '',
	userNPS_loading: false,
	userNPS_data: {
		isEligible: false,
		reason: '',
		userID: '',
		displayName: '',
		organization: '',
		closeCount: 0,
		lastClosedDate: '',
		submittedDates: [],
	},
	userNPS_error: '',
	/**
	 * Live Organization State
	 */
	liveOrganization_loading: false,
	liveOrganization_data: [],
	liveOrganization_error: '',
	/**
	 * Map Statistics State
	 */
	mapStatistics: {
		isLoading: false,
		data: {
			meta: {
				minLatitude: 0,
				maxLatitude: 0,
				minLongitude: 0,
				maxLongitude: 0,
				zoomFactor: 0,
			},
			statistics: [],
		},
		error: null,
	},
};

export const overviewReducer = createReducer<OverviewState, OverviewAction>(initialState)
	.handleAction(fetchOverviewDailyChartAsync.request, (state) => ({
		...state,
		dailyChart_loading: true,
	}))
	.handleAction(fetchOverviewDailyChartAsync.success, (state, action) => ({
		...state,
		dailyChart_loading: false,
		dailyChart_data: action.payload,
		dailyChart_error: '',
	}))
	.handleAction(fetchOverviewDailyChartAsync.failure, (state, action) => ({
		...state,
		dailyChart_loading: false,
		dailyChart_error: action.payload,
	}))
	.handleAction(fetchIssuesOverviewCardsAsync.request, (state) => ({
		...state,
		issuesOverviewCards_loading: true,
	}))
	.handleAction(fetchIssuesOverviewCardsAsync.success, (state, action) => ({
		...state,
		issuesOverviewCards_loading: false,
		issuesOverviewCards_data: action.payload,
		issuesOverviewCards_error: '',
	}))
	.handleAction(fetchIssuesOverviewCardsAsync.failure, (state, action) => ({
		...state,
		issuesOverviewCards_loading: false,
		issuesOverviewCards_error: action.payload,
	}))
	.handleAction(fetchIssuesResolvedChartAsync.request, (state) => ({
		...state,
		issuesResolvedChart_loading: true,
	}))
	.handleAction(fetchIssuesResolvedChartAsync.success, (state, action) => ({
		...state,
		issuesResolvedChart_loading: false,
		issuesResolvedChart_data: action.payload,
		issuesResolvedChart_error: '',
	}))
	.handleAction(fetchIssuesResolvedChartAsync.failure, (state, action) => ({
		...state,
		issuesResolvedChart_loading: false,
		issuesResolvedChart_error: action.payload,
	}))
	.handleAction(fetchIssuesCategoriesChartAsync.request, (state) => ({
		...state,
		issuesCategoriesChart_loading: true,
	}))
	.handleAction(fetchIssuesCategoriesChartAsync.success, (state, action) => ({
		...state,
		issuesCategoriesChart_loading: false,
		issuesCategoriesChart_data: action.payload,
		issuesCategoriesChart_error: '',
	}))
	.handleAction(fetchIssuesCategoriesChartAsync.failure, (state, action) => ({
		...state,
		issuesCategoriesChart_loading: false,
		issuesCategoriesChart_error: action.payload,
	}))
	.handleAction(fetchAllUserNPSAsync.request, (state) => ({
		...state,
		allUserNPS_loading: true,
	}))
	.handleAction(fetchAllUserNPSAsync.success, (state, action) => ({
		...state,
		allUserNPS_loading: false,
		allUserNPS_data: action.payload,
		allUserNPS_error: '',
	}))
	.handleAction(fetchAllUserNPSAsync.failure, (state, action) => ({
		...state,
		allUserNPS_loading: false,
		allUserNPS_error: action.payload,
	}))
	.handleAction(fetchUserNPSAsync.request, (state) => ({
		...state,
		userNPS_loading: true,
	}))
	.handleAction(fetchUserNPSAsync.success, (state, action) => ({
		...state,
		userNPS_loading: false,
		userNPS_data: action.payload,
		userNPS_error: '',
	}))
	.handleAction(fetchUserNPSAsync.failure, (state, action) => ({
		...state,
		userNPS_loading: false,
		userNPS_error: action.payload,
	}))
	.handleAction(upsertUserNPSAsync.request, (state) => ({
		...state,
		userNPS_loading: true,
	}))
	.handleAction(upsertUserNPSAsync.success, (state, action) => ({
		...state,
		userNPS_loading: false,
		userNPS_data: action.payload,
		userNPS_error: '',
	}))
	.handleAction(upsertUserNPSAsync.failure, (state, action) => ({
		...state,
		userNPS_loading: false,
		userNPS_error: action.payload,
	}))
	.handleAction(fetchLiveOrganizationAsync.request, (state) => ({
		...state,
		liveOrganization_loading: true,
	}))
	.handleAction(fetchLiveOrganizationAsync.success, (state, action) => ({
		...state,
		liveOrganization_loading: false,
		liveOrganization_data: action.payload,
		liveOrganization_error: '',
	}))
	.handleAction(fetchLiveOrganizationAsync.failure, (state, action) => ({
		...state,
		liveOrganization_loading: false,
		liveOrganization_error: action.payload,
	}))
	.handleAction(fetchMapStatisticsAsync.request, (state) => ({
		...state,
		mapStatistics: {
			...state.mapStatistics,
			isLoading: true,
		},
	}))
	.handleAction(fetchMapStatisticsAsync.success, (state, action) => ({
		...state,
		mapStatistics: {
			isLoading: false,
			data: action.payload.data,
			error: null,
		},
	}))
	.handleAction(fetchMapStatisticsAsync.failure, (state, action) => ({
		...state,
		mapStatistics: {
			...state.mapStatistics,
			isLoading: false,
			error: action.payload.error,
		},
	}));
