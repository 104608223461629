import { call, put, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';

import { RootState } from 'store/rootReducers';
import { FeatureAccessState } from 'reducers/featureAccess/type';

import * as types from './actionType';
import * as actions from './action';

import { isAccessGranted } from './helpers/setUserAccessPermissions';
import API from 'helpers/api';
import { apiURL } from 'config/baseURL';
import { fetchFeatures } from 'sagas/featureAccess/featureAccess.actionSaga';
import { enums } from 'nimbly-common';
import { AccessState } from './reducer';

function selectFeatureState(state: RootState) {
	return state.featureAccess;
}

function selectLastAccessFetch(state: RootState, path: string) {
	return state.routeAccess[path] || null;
}

function selectLmsRoleAccess(state: RootState, path: string): boolean | undefined {
	return _.get(state.userAccess?.lmsRoleAccess || {}, path);
}

function selectOrganizationLmsType(state: RootState): string | undefined {
	return state?.organization?.organization?.lmsType;
}

function* getAccess({ payload }: ReturnType<typeof actions.setAccessPath.request>) {
	const { path, feature, resource, lmsRoleAccessType } = payload;

	const pathState: null | AccessState = yield select((state) => selectLastAccessFetch(state, path));
	if (pathState?.isAccessible && pathState.timestamp && moment().diff(pathState.timestamp, 'minutes') < 5) {
		// if it was fetch in less than 5 mins do nothing
		return;
	}

	// route does not have access param set to granted
	if (!feature && !resource && !lmsRoleAccessType) {
		yield put(actions.setAccessPath.success({ path, isAccessible: true }));

		return;
	}

	const featureState: FeatureAccessState = yield select(selectFeatureState);
	const organizationLmsTypeState: string = yield select(selectOrganizationLmsType);

	const authToken: string = yield call(API.getFirebaseToken);

	let hasFeatureAccess = !feature;
	let hasRoleAccess = !resource;
	let hasLmsRoleAccess = !lmsRoleAccessType;

	if (!lmsRoleAccessType && organizationLmsTypeState === 'standalone') {
		yield put(
			actions.setAccessPath.success({
				path,
				isAccessible: false,
			}),
		);
		return;
	}

	if (feature) {
		if (featureState.isLoaded) {
			hasFeatureAccess = !!featureState.features[feature];
		} else {
			const options = {
				method: 'GET',
				headers: {
					Authorization: authToken,
				},
			};

			const additional = featureState.currentOrg === '' ? '/user-auth' : `/${featureState.currentOrg}`;
			const fetchFeatureAccessURL = `${apiURL}/organizations/features${additional}`;

			try {
				const response = yield call(fetchFeatures, fetchFeatureAccessURL, options);
				if (response === null) {
					yield put(actions.setAccessPath.failure({ path: path, error: 'Failed to Fetch Feature Access' }));

					return;
				}

				hasFeatureAccess = !!response.features[feature];
			} catch (error) {
				yield put(actions.setAccessPath.failure({ path: path, error: 'Failed to Fetch Feature Access' }));

				return;
			}
		}
	}

	if (resource) {
		try {
			hasRoleAccess = yield call(isAccessGranted, resource, enums.Permission.View);
		} catch (error) {
			yield put(actions.setAccessPath.failure({ path: path, error: 'Failed to Fetch Feature Access' }));

			return;
		}
	}

	if (lmsRoleAccessType) {
		const lmsRoleAccessState = yield select((state) => selectLmsRoleAccess(state, lmsRoleAccessType));
		hasLmsRoleAccess = !!lmsRoleAccessState;
	}

	yield put(
		actions.setAccessPath.success({
			path,
			isAccessible: hasFeatureAccess && hasRoleAccess && hasLmsRoleAccess,
		}),
	);
}

export default function* routeSaga() {
	yield takeEvery(types.SET_ACCESS_PATH_REQUEST, getAccess);
}
