import { createAsyncAction, action } from 'typesafe-actions';

import * as types from './company.actionTypes';
import { CompetitorPromotion, Company, CompetitorCompanySummary, CompetitorProduct } from 'nimbly-common';

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });
export const setSort = (sortType: 'name' | 'numOfProducts' | 'numOfPromotions', sortOrder: 'asc' | 'desc') =>
  action(types.SET_SORT, { sortType, sortOrder });
// export const updateCompanyDetail = (data: any) => action(types.UPDATE_COMPANY_DETAIL, { data });

export const fetchCompany = createAsyncAction(
  types.FETCH_COMPANIES_REQUEST,
  types.FETCH_COMPANIES_SUCCESS,
  types.FETCH_COMPANIES_FAILURE
)<undefined, CompetitorCompanySummary[], { error: string }>();

export const createCompany = createAsyncAction(
  types.CREATE_COMPANIES_REQUEST,
  types.CREATE_COMPANIES_SUCCESS,
  types.CREATE_COMPANIES_FAILURE
)<types.POST_CompaniesPayload, string, { error: string }>();

export const createCompanyBulk = createAsyncAction(
  types.CREATE_COMPANIES_BULK_REQUEST,
  types.CREATE_COMPANIES_BULK_SUCCESS,
  types.CREATE_COMPANIES_BULK_FAILURE
)<types.POST_BulkCompaniesPayload, undefined, { error: string }>();

export const fetchCompanyDetail = createAsyncAction(
  types.FETCH_COMPANY_DETAIL_REQUEST,
  types.FETCH_COMPANY_DETAIL_SUCCESS,
  types.FETCH_COMPANY_DETAIL_FAILURE
)<any, Company, { error: string }>();

export const updateCompany = createAsyncAction(
  types.UPDATE_COMPANY_REQUEST,
  types.UPDATE_COMPANY_SUCCESS,
  types.UPDATE_COMPANY_FAILURE
)<types.PUT_CompaniesPayload, string, { error: string }>();

export const deleteCompany = createAsyncAction(
  types.DELETE_COMPANY_REQUEST,
  types.DELETE_COMPANY_SUCCESS,
  types.DELETE_COMPANY_FAILURE
)<any, any, { error: string }>();

export const fetchCompanyProducts = createAsyncAction(
  types.FETCH_COMPANY_PRODUCTS_REQUEST,
  types.FETCH_COMPANY_PRODUCTS_SUCCESS,
  types.FETCH_COMPANY_PRODUCTS_FAILURE
)<types.FETCH_CompanyProductsPayload, CompetitorProduct[], string>();

export const fetchCompanyPromotions = createAsyncAction(
  types.FETCH_COMPANY_PROMOTIONS_REQUEST,
  types.FETCH_COMPANY_PROMOTIONS_SUCCESS,
  types.FETCH_COMPANY_PROMOTIONS_FAILURE
)<types.FETCH_CompanyPromotionsPayload, CompetitorPromotion[], string>();
