import {
  FetchRankByCompletionPayload,
  FetchRankByCompletionResponse
} from 'reducers/dashboard/sites/rankByCompletion/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyRankByCompletion } from 'utils/dashboardDummy/sites/dummyRankByCompletion';

export const fetchRankByCompletion = async (
  payload: FetchRankByCompletionPayload
): Promise<FetchRankByCompletionResponse | undefined> => {
  const { isDummy, ...query } = payload;

  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyRankByCompletion = getDummyRankByCompletion(payload);
    return {
      message: 'SUCCESS',
      data: dummyRankByCompletion
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/sites/completion-rank?${serializedQueries}`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const rankByCompletionListResult: FetchRankByCompletionResponse = await res.json();
    return rankByCompletionListResult;
  } catch {
    // ERROR
    const errorResult: FetchRankByCompletionResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
