import { createAsyncAction } from 'typesafe-actions';
import {
  FETCH_ALL_ORG_REQUEST,
  FETCH_ALL_ORG_SUCCESS,
  FETCH_ALL_ORG_FAILURE,
  AllOrganization,
  CREATE_MASTER_PASSWORD_REQUEST,
  CREATE_MASTER_PASSWORD_SUCCESS,
  CREATE_MASTER_PASSWORD_FAILURE,
  CreateMasterPasswordPayload
} from './types';

export const fetchAllOrgAsync = createAsyncAction(FETCH_ALL_ORG_REQUEST, FETCH_ALL_ORG_SUCCESS, FETCH_ALL_ORG_FAILURE)<
  undefined,
  AllOrganization,
  string
>();

export const createOrgMasterPasswordAsync = createAsyncAction(
  CREATE_MASTER_PASSWORD_REQUEST,
  CREATE_MASTER_PASSWORD_SUCCESS,
  CREATE_MASTER_PASSWORD_FAILURE
)<CreateMasterPasswordPayload, undefined, string>();
