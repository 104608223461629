const SET_DUMMY_STATE = '@account/SET_DUMMY_STATE';

export const setDummyState = uid => {
  const dummyUsers = {
    // list all dummy accounts here
    '40aWkEHLVTPmVwMNdfconatt8RH3': true,
    QiEbDPUfFkNrIMu9Zn6LSl5VpCr1: true
  };
  return { type: SET_DUMMY_STATE, isDummy: !!dummyUsers[uid] };
};

const initialState = {
  isDummy: false
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DUMMY_STATE:
      return {
        ...state,
        isDummy: action.isDummy
      };
    default:
      return state;
  }
}
