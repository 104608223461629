import { LearnerActivity, PatchLearnerActivityRequest } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { IAPIResponse } from 'types/api';

import { INotificationAPIBase, INotificationStore } from 'reducers/lms/notification/notification.type';
export interface FetchLmsLearnerActivityByIdArgs {
	learnerActivityId?: string;
	courseOrSyllabusId?: string;
}

export const fetchLmsLearnerActivityById = async ({
	learnerActivityId,
	courseOrSyllabusId,
}: FetchLmsLearnerActivityByIdArgs): Promise<LearnerActivity | undefined> => {
	let id = learnerActivityId;

	if (!id && courseOrSyllabusId) {
		const url = `${apiURL}/lms/learner-activities/activity-id/${courseOrSyllabusId}`;
		const token = await API.getFirebaseToken();
		const res = await API.getJSON<IAPIResponse<string>>(url, token);

		id = res?.data;
		if (!id) throw new Error('Fialed to get learner activityID');
	}

	const url = `${apiURL}/lms/learner-activities/${id}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON<IAPIResponse<LearnerActivity>>(url, token);
	return res?.data;
};

export const updateLmsLearnerActivity = async (
	learnerActivityId: string,
	body: Partial<PatchLearnerActivityRequest>,
): Promise<LearnerActivity | undefined> => {
	const url = `${apiURL}/lms/learner-activities/${learnerActivityId}`;
	const res = await API.patchJSON<IAPIResponse<LearnerActivity>>(url, body);
	return res?.data;
};

export const fetchLmsNotification = async (): Promise<INotificationStore[] | undefined> => {
	const url = `${apiURL}/lms/users/notification-history`;
	const res = await API.getJSON(url);

	return res?.data as INotificationStore[];
};
