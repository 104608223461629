import { action, createAsyncAction } from 'typesafe-actions';
import { GetUserListSuccessResponse, GetUserRequestInterface, SortDirection, UserListFilters } from './type.d';
import * as types from './userList.actionTypes';

export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });
export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });
export const setUserListFilters = (filters: Partial<UserListFilters>) =>
	action(types.SET_USER_LIST_FILTER_QUERY, { filters });
export const resetUserListFilters = () => action(types.RESET_USER_LIST_FILTER_QUERY);
export const setUserListPageIndex = (page: number) => action(types.SET_USER_LIST_PAGE_INDEX, { page });
export const setUserListSearchQuery = (search: string) => action(types.SET_USER_LIST_SEARCH_QUERY, { search });
export const setUserListSort = (sortBy: string, sortDirection: SortDirection) =>
	action(types.SET_USER_LIST_SORT, { sortBy, sortDirection });

export const resetUserListFilter = () => action(types.RESET_USER_FILTER, {});
export const getUserList = createAsyncAction(
	types.GET_USER_LIST_REQUEST,
	types.GET_USER_LIST_SUCCESS,
	types.GET_USER_LIST_FAILURE,
)<GetUserRequestInterface, GetUserListSuccessResponse, { error: string }>();
