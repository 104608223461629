import { createAsyncAction } from 'typesafe-actions';
import {
  OverviewFlagCards,
  FETCH_CARD_FLAG_REQUEST,
  FETCH_CARD_FLAG_SUCCESS,
  FETCH_CARD_FLAG_FAILURE,
  FETCH_CARD_FLAGV1_REQUEST,
  FETCH_CARD_FLAGV1_SUCCESS,
  FETCH_CARD_FLAGV1_FAILURE,
  FetchFlagsOverviewPayload
} from './actionType';

export const fetchCardFlagAsync = createAsyncAction(
  FETCH_CARD_FLAG_REQUEST,
  FETCH_CARD_FLAG_SUCCESS,
  FETCH_CARD_FLAG_FAILURE
)<FetchFlagsOverviewPayload, OverviewFlagCards, string>();

export const fetchCardFlagV1Async = createAsyncAction(
  FETCH_CARD_FLAGV1_REQUEST,
  FETCH_CARD_FLAGV1_SUCCESS,
  FETCH_CARD_FLAGV1_FAILURE
)<FetchFlagsOverviewPayload, OverviewFlagCards, string>();