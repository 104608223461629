import update from 'immutability-helper';
import { SiteScheduleEvent } from '@nimbly-technologies/nimbly-common';

import { ActionType } from 'typesafe-actions';
import * as types from './scheduleTemplate.actionTypes';
import * as actions from './scheduleTemplate.action';
import { ScheduleTemplate } from 'types/common';

export type NamedScheduleTemplate = { key: string; value: SiteScheduleEvent; title: string };

export interface ImportTemplate {
	schedules: ScheduleTemplate[];
	name: string;
	key: string;
}

type ScheduleTemplateModal = {
	saveTemplate: boolean;
	importTemplate: boolean;
};

export type ScheduleTemplateState = {
	modal: ScheduleTemplateModal;
	scheduleList: NamedScheduleTemplate[];
	templateList: ImportTemplate[];
	isLoading: boolean;
	isReady: boolean;
};

export type ScheduleTemplateAction = ActionType<typeof actions>;

const initialState: ScheduleTemplateState = {
	scheduleList: [],
	templateList: [],
	modal: {
		saveTemplate: false,
		importTemplate: false,
	},
	isLoading: false,
	isReady: false,
};

const scheduleTemplateReducer = (state = initialState, action: ScheduleTemplateAction): ScheduleTemplateState => {
	switch (action.type) {
		case types.SET_LOADING_STATE:
			return update(state, {
				isLoading: { $set: action.payload.bool },
			});
		case types.SET_READY_STATE:
			return update(state, {
				isReady: { $set: action.payload.bool },
			});
		case types.SHOW_SAVE_TEMPLATE_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					saveTemplate: true,
				},
			};
		case types.SHOW_IMPORT_TEMPLATE_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					importTemplate: true,
				},
			};
		case types.DISMISS_SAVE_TEMPLATE_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					saveTemplate: false,
				},
			};
		case types.DISMISS_IMPORT_TEMPLATE_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					importTemplate: false,
				},
			};

		case types.SET_QUESTIONNAIRE_TEMPLATE:
			return {
				...state,
				scheduleList: action.payload.questionnaireList,
			};
		case types.SET_IMPORT_TEMPLATE_LIST:
			return update(state, {
				templateList: { $set: action.payload.templates },
				isLoading: { $set: false },
				isReady: { $set: true },
			});
		case types.REMOVE_TEMPLATE:
			return update(state, {
				templateList: { $splice: [[action.payload.index, 1]] },
			});

		case types.RESET_SCHEDULE_TEMPLATE_STATE:
			return initialState;
		default:
			return state;
	}
};

export default scheduleTemplateReducer;
