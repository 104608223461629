import { ActionType, createReducer } from 'typesafe-actions';
import { SiteHierarchyFetchState, SiteHierarchyState } from './type.d';
import update from 'immutability-helper';
import * as actions from './siteHierarchy.action';
import * as types from './siteHierarchy.actionTypes';

export type SiteHierarchyAction = ActionType<typeof actions>;

export const initialState: SiteHierarchyState = {
	error: '',
	fetchState: SiteHierarchyFetchState.SUCCESS,
	siteHierarchy: [],
};

export const siteHierarchyReducer = createReducer<SiteHierarchyState, SiteHierarchyAction>(initialState)
	.handleType(types.FETCH_SITE_HIERARCHY_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: SiteHierarchyFetchState.LOADING },
			siteHierarchy: { $set: initialState.siteHierarchy },
		}),
	)
	.handleType(types.FETCH_SITE_HIERARCHY_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: SiteHierarchyFetchState.SUCCESS },
			siteHierarchy: { $set: action.payload.siteHierarchy },
		}),
	)
	.handleType(types.FETCH_SITE_HIERARCHY_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: SiteHierarchyFetchState.ERROR },
			siteHierarchy: { $set: initialState.siteHierarchy },
		}),
	);
