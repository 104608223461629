import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './departmentGroup.action';
import * as types from './departmentGroup.actionTypes';
import { DepartmentGroupState } from '../../components/dashboard/AnalyticsController/AnalyticsController.Types';

const initialState: DepartmentGroupState = {
  errorList: null,
  isLoadingList: false,
  isLoadingDetail: false,
  filterQuery: '',
  listActiveTab: 'active',
  departmentGroup: null,
  departmentGroupById: null,
  currentlyActiveIndex: -1,
  groupDepartmentData: null,
  departmentData: null,
  fetchLoading: false,
  errorDetail: null,
  editingDetail: false,
  selectedDepartmentGroupID: '',
  prevSelectedDepartmentGroupID: ''
};

const departmentGroupReducer = createReducer<DepartmentGroupState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getDepartmentGroup.request, state => ({
    ...state,
    errorList: null,
    isLoadingList: true
  }))
  .handleAction(actions.getDepartmentGroup.success, (state, action) => ({
    ...state,
    errorList: null,
    isLoadingList: false,
    departmentGroup: action.payload.data
  }))
  .handleAction(actions.getDepartmentGroup.failure, (state, action) => ({
    ...state,
    errorList: action.payload.error,
    isLoadingList: false
  }))
  .handleAction(actions.getDepartmentGroupById.request, state => ({
    ...state,
    errorDetail: null,
    isLoadingDetail: true,
    departmentGroupById: null,
    editingDetail: true
  }))
  .handleAction(actions.getDepartmentGroupById.success, (state, action) => ({
    ...state,
    errorDetail: null,
    isLoadingDetail: false,
    departmentGroupById: action.payload.data,
    selectedDepartmentGroupID: action.payload.data?.ID || '',
    currentlyActiveIndex:
      state.departmentGroup && action.payload.data?.ID
        ? state.departmentGroup.findIndex(d => d.ID === action.payload.data?.ID)
        : -1
  }))
  .handleAction(actions.getDepartmentGroupById.failure, (state, action) => ({
    ...state,
    errorDetail: action.payload.error,
    isLoadingDetail: false
  }))
  .handleAction(actions.deleteDepartmentGroup.request, (state, action) => ({
    ...state,
    errorDetail: null,
    isLoadingDetail: true
  }))
  .handleAction(actions.deleteDepartmentGroup.success, (state, action) => ({
    ...state,
    departmentGroup: action.payload.newDeptGroup,
    departmentGroupById: action.payload.selectedDepartment,
    isLoadingDetail: false,
    listActiveTab: state.listActiveTab === 'active' ? 'disabled' : 'active'
  }))
  .handleAction(actions.deleteDepartmentGroup.failure, (state, action) => ({
    ...state,
    errorDetail: action.payload.error,
    isLoadingList: false,
    isLoadingDetail: false
  }))
  .handleAction(actions.createDepartmentGroup.request, state => ({
    ...state,
    errorList: null,
    errorDetail: null,
    isLoadingList: true,
    isLoadingDetail: true
  }))
  .handleAction(actions.createDepartmentGroup.success, (state, action) => ({
    ...state,
    errorList: null,
    errorDetail: null,
    isLoadingList: false,
    isLoadingDetail: false,
    departmentGroup: action.payload.newData
  }))
  .handleAction(actions.createDepartmentGroup.failure, (state, action) => ({
    ...state,
    errorList: action.payload.error,
    errorDetail: null,
    isLoadingList: false,
    isLoadingDetail: false
  }))
  .handleAction(actions.updateDepartmentGroup.request, (state, action) => ({
    ...state,
    errorDetail: null,
    isLoadingDetail: true,
    currentlyActiveIndex:
      state.departmentGroup && action.payload.data?.ID
        ? state.departmentGroup.findIndex(d => d.ID === action.payload.data?.ID)
        : -1
  }))
  .handleAction(actions.updateDepartmentGroup.success, (state, action) => ({
    ...state,
    errorDetail: null,
    isLoadingDetail: false,
    departmentGroup: action.payload.newDeptGroup
  }))
  .handleAction(actions.updateDepartmentGroup.failure, (state, action) => ({
    ...state,
    errorDetail: action.payload.error,
    isLoadingDetail: false
  }))
  .handleAction(types.SET_DEPT_GROUP_FILTER as any, (state, action) => ({
    ...state,
    filterQuery: action.payload.text
  }))
  .handleAction(types.CLEAR_DEPT_GROUP_FILTER as any, state => ({
    ...state,
    filterQuery: ''
  }))
  .handleAction(types.SET_DEPT_GROUP_TAB as any, (state, action) => ({
    ...state,
    listActiveTab: action.payload,
    selectedDepartmentGroupID: state.prevSelectedDepartmentGroupID || '',
    prevSelectedDepartmentGroupID: state.departmentGroupById?.ID || ''
  }))
  .handleAction(types.SET_EDITING_DETAIL as any, (state, action) => ({
    ...state,
    editingDetail: action.payload.editing
  }));

export { departmentGroupReducer, initialState };
