import * as actions from './superadmin.action';
import * as types from './superadmin.actionTypes';

import { Organization } from '@nimbly-technologies/nimbly-common';
import { ActionType } from 'typesafe-actions';

export type SuperadminActions = ActionType<typeof actions>;

export interface ExtendedOrganization extends Organization {
	allowedSites?: number;
	allowedReports?: number;
	allowedPlatformUsers?: number;
	allowedLMSUsers?: number;
	allowedFileRepoStorage?: number;
}
export interface SuperadminReducerType {
	selectedOrganization: ExtendedOrganization | null;
	updatedOrganization: ExtendedOrganization | null;
}

const initialState: SuperadminReducerType = {
	selectedOrganization: null,
	updatedOrganization: null,
};

export function superadminReducer(state = initialState, action: SuperadminActions) {
	switch (action.type) {
		case types.SELECT_ORGANIZATION:
			return {
				...state,
				selectedOrganization: action.payload.organization,
				updatedOrganization: action.payload.organization,
			};
		case types.UPDATE_ORGANIZATION:
			return {
				...state,
				updatedOrganization: action.payload.organization,
			};
		default:
			return state;
	}
}
