/**
 * @param {string} text - Text to be modified
 * @param {boolean} [all = false] - Option to modify all words
 *
 * @return {string}
 */
export function capsFirstLetter(text, all = false) {
	if (text === null || text === undefined) {
		return '';
	}

	if (typeof text !== 'string') {
		console.warn('text param is not string, returning string value of text');
		return text.toString();
	}

	if (!all) {
		return text[0].toUpperCase() + text.slice(1);
	}
	const words = text.split(' ');

	const newWords = [];
	words.forEach((word, i) => {
		const prev = words[i - 1] ? words[i - 1] : null;
		if (prev !== null && prev === '' && word === '') {
			return false;
		}
		if (word) {
			newWords.push(word[0].toUpperCase() + word.slice(1));
		} else {
			newWords.push(word);
		}
	});
	return newWords.join(' ');
}

/**
 * Create random string default to length 8
 * @param {number} [len=8] - Desired string number default to 8
 *
 * @returns {string}
 */
export function randomString(len = 10) {
	const str = `QWERTYUIOPASDFGHJKLZXCVBNM1234567890`;
	let pass = '';

	for (let i = 0; i < len; i++) {
		const randomIndex = Math.floor(Math.random() * str.length);
		pass += str[randomIndex];
	}
	return pass;
}

export function isReservedWord(text) {
	return text === 'new' || text === 'edit';
}

/**
 * Appends a string digit with thousands separators
 * @param {text}
 *
 * @returns {string}
 */
export function numberWithCommas(text) {
	const parts = text.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return parts.join('.');
}

/**
 * Appends a string digit with prefix zeroes (0)
 * @param {n} - Number
 * @param {width} - Desired with of number string
 *
 * @returns {string}
 */
export function padZeroes(n, width) {
	return String(n).padStart(width, '0');
}

/**
 * Capitalize the first character of the text
 * @param text Target string
 */
export function capitalizeText(text) {
	return text[0].toUpperCase() + text.slice(1);
}

/**
 * Converts an object of key value pair to query string
 * value must be of a non-null
 * @param {*} obj key-value pair
 */
export function toQueryString(obj) {
	return Object.entries(obj)
		.filter((entry) => !!entry[1] || !isNaN(entry[1]))
		.map((entry) => entry.join('='))
		.join('&');
}

/**
 * parses and shortens number with its abbreviated unit
 * @param {Number} num number to be parsed as string
 * @param {Partial<import("react-i18next").UseTranslationResponse, 't'>} t useTranslation
 *
 * @description currently only holds up to number in trillions
 */
export function abbreviateNum(num, t) {
	// standard international numberd unit
	const SI_SYMBOL = [
		'',
		t('label.unit.thousand'),
		t('label.unit.million'),
		t('label.unit.billion'),
		t('label.unit.trillion'),
	];

	// the 'base' number of zeroes per '000
	const numSeparator = 3;
	const base = Math.floor(Math.log10(num)) | 0;

	// currently is limited to 4 (based on SI_SYMBOL array)
	const unit = Math.min(Math.floor(base / numSeparator), 4);
	const suffix = SI_SYMBOL[unit];

	// add extra pad for single digit number
	if (base === 0) {
		return `0${num}`;
	}

	// return the number as is for number below thousand
	if (base <= 2) {
		return num.toString();
	}

	const scale = Math.pow(10, unit * numSeparator);
	const scaled = num / scale;

	return `${scaled.toFixed(1)} ${suffix}`;
}
