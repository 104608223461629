const NAVIGATE_TAB = '@screen/NAVIGATE_TAB';
const SET_TABLE_SORT = '@screen/SET_TABLE_SORT';

export const navigateTab = (screen, tab) => ({ type: NAVIGATE_TAB, screen, tab });

export const setTableSort = (tab, category, direction) => ({
  type: SET_TABLE_SORT,
  tab,
  category,
  direction
});

const initialState = {
  dashboard: {
    viewTab: 'overview',
    tabs: ['overview', 'sites', 'issues', 'auditors', 'inventory', 'stocks', 'calculation', 'auditorDetails'],
    icons: ['fas fa-home', 'fas fa-map', 'fas fa-file-alt', 'fas fa-user-tie', 'fas fa-warehouse', 'fas fa-cubes'],
    auditors: {
      sortBy: 'completion',
      name: 'desc',
      supervisor: 'desc',
      missed: 'desc',
      done: 'asc',
      scheduled: 'asc',
      completion: 'desc',
      change: 'asc',
      madeup: 'desc',
      pending: 'desc',
      adhoc: 'desc',
      score: 'desc'
    },
    inventory: {
      sortBy: 'number',
      number: 'asc',
      category: 'asc',
      count: 'asc'
    },
    sites: {
      sortBy: 'score',
      site: 'asc',
      city: 'asc',
      supervisor: 'asc',
      auditor: 'asc',
      score: 'desc',
      totalScheduled: 'desc',
      reportDone: 'desc',
      reportPending: 'desc',
      reportMissed: 'desc',
      reportCompletion: 'desc'
    },
    issues: {
      sortBy: 'flags',
      questionnaire: 'asc',
      category: 'asc',
      flags: 'desc'
    }
  },
  admin: {
    viewTab: 'sites',
    tabs: [
      'departments',
      'questionnaires',
      'auditors',
      'sites',
      'salestarget',
      'promotions',
      'broadcast',
      'inventory',
      'purchaseorder',
      'calculation'
    ],
    icons: [
      'fas fa-building',
      'fas fa-file-alt',
      'fas fa-user-tie',
      'fas fa-map',
      'fas fa-dollar-sign',
      'fas fa-percent',
      'fas fa-bullhorn',
      'fas fa-warehouse',
      'fas fa-dolly'
    ]
  },
  superadmin: {
    viewTab: 'organizations',
    tabs: ['organizations'],
    icons: ['fas fa-sitemap']
  }
};

export function screenReducer(state = initialState, action) {
  switch (action.type) {
    case NAVIGATE_TAB:
      return {
        ...state,
        [action.screen]: {
          ...state[action.screen],
          viewTab: action.tab
        }
      };
    case SET_TABLE_SORT:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [action.tab]: {
            ...state.dashboard[action.tab],
            sortBy: action.category,
            [action.category]: action.direction
          }
        }
      };
    default:
      return state;
  }
}
