import {
  CreateMasterPasswordPayload,
  CreateMasterPasswordResponse
} from 'reducers/superadmin/clientLogin/masterPassword/types';
import { getToken } from 'reducers/api';
import { apiURL } from '../../../../config/baseURL';

export const createOrgMasterPassword = async (
  payload: CreateMasterPasswordPayload
): Promise<CreateMasterPasswordResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify(payload)
    };
    const url = `${apiURL}/auth/master-password`;

    const res = await fetch(url, options);
    const createOrgMasterPasswordResult: CreateMasterPasswordResponse = await res.json();

    if (res.status !== 200) {
      throw new Error(createOrgMasterPasswordResult.message);
    }

    return createOrgMasterPasswordResult;
  } catch (error) {
    const errorResult: CreateMasterPasswordResponse = {
      message: 'FAILED',
      error: error.message
    };
    return errorResult;
  }
};
