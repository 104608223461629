export enum SiteOffDaysErrorEnum {
	INVALID_DATA = 'invalid_data',
}

const siteOffDaysErrors: { [errorCode: number]: SiteOffDaysErrorEnum } = {
	400: SiteOffDaysErrorEnum.INVALID_DATA,
};

export class SiteOffDaysApiError extends Error {
	public static async getError(response: Response): Promise<SiteOffDaysApiError | Error | null> {
		const error = siteOffDaysErrors[response.status];
		const json = await response.clone().json();

		if (error) {
			return new SiteOffDaysApiError(error, json?.message);
		}

		if (response.status >= 200 && response.status < 400) {
			return null;
		}

		return new Error(json?.message);
	}

	private _errorEnum: SiteOffDaysErrorEnum;

	public constructor(error: SiteOffDaysErrorEnum, message?: string) {
		super(message);
		this._errorEnum = error;
	}

	public get errorEnum() {
		return this._errorEnum;
	}
}
