import { createAsyncAction } from 'typesafe-actions';
import * as types from './userDept.actionTypes';
import { userDeptI } from './type';
export const userDeptAction = createAsyncAction(
	types.FETCH_USER_DEPT_REQUEST,
	types.FETCH_USER_DEPT_SUCCESS,
	types.FETCH_USER_DEPT_FAILURE,
)<
	{
		withDisabled?: boolean;
		departmentIDs?: string[];
	},
	{ data: userDeptI[]; message: string },
	{ message: string }
>();
