import * as actions from './IssueTrackerSettings.action';
import * as types from './IssueTrackerSettings.actionTypes';
import { call, put } from 'typed-redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { getIssueTrackerSetting, getDefaultAutoDueDate } from 'components/settings/utils/issueTrackerSettingsUtils';

export function* fetchIssueTrackerSettings(action: ReturnType<typeof actions.fetchIssueTrackerSettings.request>) {
	try {
		const issueTrackerSettings = yield* call(getIssueTrackerSetting, action.payload.organizationId);
		const defaultAutoDueDate = yield* call(getDefaultAutoDueDate);

		yield put(actions.fetchIssueTrackerSettings.success({ issueTrackerSettings, defaultAutoDueDate }));
	} catch (e) {
		yield put(actions.fetchIssueTrackerSettings.failure({ error: 'Failed to fetch issue tracker settings' }));
	}
}

export default function* issueTrackerSettingsSaga() {
	yield takeLatest(types.FETCH_ISSUE_TRACKER_SETTINGS_REQUEST, fetchIssueTrackerSettings);
}
