import { createReducer, ActionType } from 'typesafe-actions';
import update from 'immutability-helper';

import * as actions from './action';

export interface AccessState {
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly isAccessible: boolean;
  readonly error: string;
  /**
   * fetch timestamp
   */
  readonly timestamp?: number;
}

export type RouteAccessState = {
  [path: string]: AccessState;
};

export type RouteAccessAction = ActionType<typeof actions>;

const initialState: RouteAccessState = {};

export const routeReducer = createReducer<RouteAccessState, RouteAccessAction>(initialState)
  .handleAction(actions.setAccessPath.request, (state, action) =>
    update(state, {
      [action.payload.path]: {
        $set: {
          isAccessible: false,
          isLoaded: false,
          isLoading: true,
          error: ''
        }
      }
    })
  )
  .handleAction(actions.setAccessPath.success, (state, action) =>
    update(state, {
      [action.payload.path]: {
        $merge: {
          isAccessible: action.payload.isAccessible,
          isLoaded: true,
          isLoading: false,
          timestamp: Date.now()
        }
      }
    })
  )
  .handleAction(actions.setAccessPath.failure, (state, action) =>
    update(state, {
      [action.payload.path]: {
        $merge: {
          isLoaded: true,
          isLoading: false,
          error: action.payload.error
        }
      }
    })
  )
  .handleAction(actions.resetPath, (state, action) =>
    update(state, {
      [action.payload.path]: {
        $set: {
          isAccessible: false,
          isLoaded: false,
          isLoading: false,
          error: ''
        }
      }
    })
  )
  .handleAction(actions.reset, () => ({}));
