import {
  FetchRequestDemoListResponse,
  FetchRequestDemoListPayload
} from 'reducers/superadmin/reqForDemo/requestDemoList/types';
import { getToken } from 'reducers/api';
import { apiURL } from 'config/baseURL';

export const fetchRequestDemoList = async (
  payload: FetchRequestDemoListPayload
): Promise<FetchRequestDemoListResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };

    const url = `${apiURL}/miscellaneous/demo-requests?startDate=${payload.startDate}&endDate=${payload.endDate}`;
    const res = await fetch(url, options);

    let requestDemoListResult: FetchRequestDemoListResponse = await res.json();

    requestDemoListResult.message = 'SUCCESS';
    return requestDemoListResult;
  } catch {
    const errorResult: FetchRequestDemoListResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
