import { apiURL } from 'config/baseURL';
import { getToken } from './index';
import { OrganizationUsersResponse } from './users.types';
import Monitoring from '../../../utils/monitoring/Monitoring';

export const getUsersByOrganization = async (organizationID: string): Promise<OrganizationUsersResponse> => {
  if (!organizationID) {
    throw new Error('No organizationID is being requested');
  }

  const token = await getToken();
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: token
    }
  };

  const url = `${apiURL}/users/organizations/${organizationID}`;

  try {
    const response = await fetch(url, options);

    if (response.status !== 200) {
      throw new Error(`Failed to fetch: ${url}`);
    }

    const result = (await response.json()) as OrganizationUsersResponse;

    if (result.message === 'FAILED') {
      throw new Error(`Error occurred to fetch: ${url}`);
    }

    return { data: result.data, message: 'SUCCESS', error: null };
  } catch (e) {
    Monitoring.logEvent('getUsersByOrganization', e);
    return { data: null, message: 'FAILED', error: e };
  }
};
