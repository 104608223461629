import API from 'helpers/api';
import { apiURL } from 'config/baseURL';
import { store } from 'store/configStore';
import { toast } from 'react-toastify';
import { HeatmapIssueFlagsI, LastSelectedWidgetI } from 'reducers/dashboardRevamp/executive/executive.types';
import moment from 'moment';
import { queryStringify } from 'utils/router';
import { arrayToMap } from 'nimbly-common/lib/functions';

export const getIsAllSelected = () => {
	const { sites, departments, users: usersFilter, questionnaires } = store.getState().executiveDashboard.filters;
	const users = store.getState().users.users;
	const siteOptions = store.getState().siteCompact?.data;
	const departmentOptions = store.getState().departments.departments;
	const questionnaireOptions = store.getState().questionnaireByProps.data;
	const siteMap = arrayToMap(siteOptions, 'siteID');
	const questionnairesMap = arrayToMap(questionnaireOptions, 'questionnaireIndexID');

	const isAllSites = sites.length === Object.keys(siteMap)?.length;
	const isAllDepartments = departments.length === Object.keys(departmentOptions || []).length;
	const isAllUsers = usersFilter.length === Object.keys(users || []).length;
	const isAllQuestionnaires = questionnaires.length === Object.keys(questionnairesMap || []).length;
	return {
		isAllSites,
		isAllDepartments,
		isAllUsers,
		isAllQuestionnaires,
	};
};

export const fetchHeatmapIssueFlags = async (): Promise<HeatmapIssueFlagsI | undefined> => {
	const { filters } = store.getState().executiveDashboard;
	const overallCategories = store.getState().admin.manage.questionCategories_data;
	const { isAllSites, isAllDepartments, isAllUsers, isAllQuestionnaires } = getIsAllSelected();

	let categories: string[] = [];

	categories = overallCategories
		.filter((cat) => cat?._id && filters?.categories?.includes(cat?._id))
		?.map((cat) => cat?.label);

	const isAllCategories = categories.length === overallCategories.length;

	const query = {
		metric: 'heatmap',
		viewBy: 'issueRedFlag',
		startDate: moment(filters?.startDate)?.format('YYYY-MM-DD') || '',
		endDate: moment(filters?.endDate)?.format('YYYY-MM-DD') || '',
		siteIDs: (!isAllSites && filters?.sites) || [],
		departmentIDs: (!isAllDepartments && filters?.departments) || [],
		userIDs: (!isAllUsers && filters?.users) || [],
		questionnaireIDs: (!isAllQuestionnaires && filters?.questionnaires) || [],
		categories: (!isAllCategories && categories) || [],
	};
	const apiRoute = `issue`;
	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(query),
		};

		const url = `${apiURL}/statistics/dashboard/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData?.data as HeatmapIssueFlagsI;
	} catch (error) {
		toast.error(error);
	}
};

export const fetchLastUpdatedWidget = async (): Promise<LastSelectedWidgetI | undefined> => {
	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const url = `${apiURL}/statistics/dashboard/metrics`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData?.data as LastSelectedWidgetI;
	} catch (error) {
		throw new Error(`Error ${error}`);
	}
};

export const UpdateLastUpdatedWidget = async (payload: LastSelectedWidgetI) => {
	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		};

		const url = `${apiURL}/statistics/dashboard/upsertMetrics`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
	} catch (error) {
		throw new Error(`Error ${error}`);
	}
};
