export const SET_DATA_TYPE = '@lms/SET_DATA_TYPE';
export const SET_VIEW_TYPE = '@lms/SET_VIEW_TYPE';
export const SET_SELECTED_COURSES = '@lms/SET_SELECTED_COURSES';
export const SET_CONFIRMATION_MODAL_CONFIG = '@lms/SET_CONFIRMATION_MODAL_CONFIG';

export const SET_SELECTED_COURSE_ROWS = '@lms/SET_SELECTED_COURSE_ROWS';
export const TOGGLE_CONFIRMATION_MODAL = '@lms/TOGGLE_CONFIRMATION_MODAL';
export const TOGGLE_BULK_EDIT_MODAL = '@lms/TOGGLE_BULK_EDIT_MODAL';
export const TOGGLE_BULK_EDIT_ERROR_MODAL = '@lms/TOGGLE_BULK_EDIT_ERROR_MODAL';

export const RESET_PAGE_STATE = '@lms/RESET_PAGE_STATE';

export const SET_SELECTED_SYLLABUS = '@lms/SET_SELECTED_SYLLABUS';

export const SET_SELECTED_SYLLABUS_ROWS = '@lms/SET_SELECTED_SYLLABUS_ROWS';
