import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesOverviewStat,
  FETCH_OVERVIEW_STAT_REQUEST,
  FETCH_OVERVIEW_STAT_SUCCESS,
  FETCH_OVERVIEW_STAT_FAILURE,
  FetchOverviewStatPayload
} from './types';

export const fetchOverviewStatAsync = createAsyncAction(
  FETCH_OVERVIEW_STAT_REQUEST,
  FETCH_OVERVIEW_STAT_SUCCESS,
  FETCH_OVERVIEW_STAT_FAILURE
)<FetchOverviewStatPayload, IssuesOverviewStat, string>();
