import { Single_SKUScheduleList } from '@nimbly-technologies/nimbly-common';
import { ActionType } from 'typesafe-actions';
import * as actions from '../stockAuditScheduler/stockAuditScheduler.action';
import { BaseSelectOptionType } from '../../components/global/Select/utils/optionsMapper';

export interface StockAuditSchedulerState {
	fetchState: StockAuditFetchState;

	schedules: Single_SKUScheduleList[];
	lastUpdatedAt: number;

	searchQuery: string;
	pageIndex: number;
	limit: number;
	totalSchedules: number;

	isMobileFiltersVisible: boolean;
	filters: StockAuditSchedulerFilters;

	sortBy: string;
	isAscending: boolean;

	error: string;
}

export enum StockAuditFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export type StockAuditSchedulerAction = ActionType<typeof actions>;

export interface StockAuditSchedulerFilters {
	types: BaseSelectOptionType[];
	sites: BaseSelectOptionType[];
	status: BaseSelectOptionType[];
	frequencies: BaseSelectOptionType[];
}
