export enum APIResponseMessageEnum {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
}

const APIResponse = {
	CODE: {
		SUCCESS: 200,
		BAD_REQUEST: 400,
		UNAUTHORIZED: 401,
		FORBIDDEN: 403,
		NOT_FOUND: 404,
		INTERNAL_SERVER_ERROR: 500,
		BAD_GATEWAY: 502,
	},
	API: {
		SUCCESS: APIResponseMessageEnum.SUCCESS,
		FAILED: APIResponseMessageEnum.FAILED,
	},
};

export { APIResponse };
