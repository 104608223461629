import {
  DashboardOverviewPayload,
  IssuesResolvedChartResponse
} from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesResolvedChart = IssuesResolvedChartResponse['data'];

export interface IssuesResolvedChartState {
  readonly issuesResolvedChart_loading: boolean;
  readonly issuesResolvedChart_data: IssuesResolvedChart;
  readonly issuesResolvedChart_error: string;
}

export const FETCH_ISSUES_RESOLVED_CHART_REQUEST = '@@dashboard/overview/FETCH_ISSUES_RESOLVED_CHART_REQUEST';
export const FETCH_ISSUES_RESOLVED_CHART_SUCCESS = '@@dashboard/overview/FETCH_ISSUES_RESOLVED_CHART_SUCCESS';
export const FETCH_ISSUES_RESOLVED_CHART_FAILURE = '@@dashboard/overview/FETCH_ISSUES_RESOLVED_CHART_FAILURE';

export type FetchIssuesResolvedChartPayload = DashboardOverviewPayload;
export type FetchIssuesResolvedChartResponse = IssuesResolvedChartResponse | ErrorResponse;
