import { ActionType, createReducer } from 'typesafe-actions';
import * as types from './lmsCourse.actionTypes';
import * as actions from './lmsCourse.action';
import { ILmsCourseStore } from './lmsCourse.d';

const initialState: ILmsCourseStore = {
	fetchById: {
		isLoading: false,
		data: null,
		errorMessage: '',
	},
	selectedFile: null,
	chaptersLearnerActivityMap: null,
	filesWithoutChapterLearnerActivityMap: null,
	fileLearnerActivityFlatMap: null,
	isCompletedModalVisible: false,
	isCourseRetaken: false,
	isMobileTopicsModalOpen: false,
	lessonSequence: [],
	quizLearnerActivityMap: null,
	autoSelectLastLesson: true,
};

export type LmsCourseAction = ActionType<typeof actions>;
export const lmsCourseReducer = createReducer<ILmsCourseStore, LmsCourseAction>(initialState)
	.handleAction(actions.fetchLmsCourseByIdAsync.request, (state) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsCourseByIdAsync.success, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: false,
			// @ts-ignore TODO: Update types when nimbly common updated
			data: action.payload,
			errorMessage: '',
		},
	}))
	.handleAction(actions.fetchLmsCourseByIdAsync.failure, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: false,
			errorMessage: action.payload,
		},
	}))
	.handleAction((types.SET_LMS_SELECTED_FILE as unknown) as typeof actions.setLmsSelectedFile, (state, action) => ({
		...state,
		selectedFile: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_BY_ID as any, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			data: action.payload,
		},
	}))
	.handleAction(types.SET_LMS_COURSE_CHAPTERS_LEARNER_ACTIVITY_MAP as any, (state, action) => ({
		...state,
		chaptersLearnerActivityMap: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_FILES_WITHOUT_CHAPTER_LEARNER_ACTIVITY_MAP as any, (state, action) => ({
		...state,
		filesWithoutChapterLearnerActivityMap: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_FILE_LEARNER_ACTIVITY_FLAT_MAP as any, (state, action) => ({
		...state,
		fileLearnerActivityFlatMap: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_IS_COMPLETED_MODAL_VISIBLE as any, (state, action) => ({
		...state,
		isCompletedModalVisible: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_RETAKEN as any, (state, action) => ({
		...state,
		isCourseRetaken: action.payload,
	}))
	.handleAction(types.SET_LMS_MOBILE_TOPICS_MODAL as any, (state, action) => ({
		...state,
		isMobileTopicsModalOpen: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_LESSON_SEQUENCE as any, (state, action) => ({
		...state,
		lessonSequence: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_QUIZ_LEARNER_ACTIVITY_MAP as any, (state, action) => ({
		...state,
		quizLearnerActivityMap: action.payload,
	}))
	.handleAction(types.SET_LMS_COURSE_AUTOSELECT_LAST_LESSON as any, (state, action) => ({
		...state,
		autoSelectLastLesson: action.payload,
	}));
