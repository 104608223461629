import { toast } from 'react-toastify';
import { RootState } from 'store/rootReducers';
import { call, put, select } from 'typed-redux-saga';
import * as actions from './siteScheduleActivity.action';
import * as types from './siteScheduleActivity.actionTypes';
import { SiteScheduleActivityState } from './type.d';
import queryString from 'query-string';
import { takeLatest } from 'redux-saga/effects';
import { fetchSiteScheduleActivity } from 'services/siteScheduleActivity/siteScheduleActivity.service';

export function* fetchSiteScheduleActivitySaga() {
	const siteScheduleActivityState = (state: RootState) => state.siteScheduleActivity;
	const state: SiteScheduleActivityState = yield select(siteScheduleActivityState);

	try {
		const query = {
			page: state.page,
			limit: state.limit,
			sortFields: state.sortFields,
			sortDirections: state.sortDirections,
			siteIDs: state.siteID,
			...state.filters,
		};
		const queryStr = queryString.stringify(query);

		// @ts-ignore
		const { docs, ...result } = yield* call(fetchSiteScheduleActivity, queryStr);

		// @ts-ignore
		yield put(actions.fetchSiteScheduleActivity.success({ siteScheduleActivity: docs, ...result }));
	} catch (e) {
		toast.error((e as Error).message);
		yield put(actions.fetchSiteScheduleActivity.failure({ error: 'Failed to fetch attachments' }));
	}
}

export default function* siteScheduleActivitySaga() {
	yield takeLatest(types.FETCH_SITE_SCHEDULE_ACTIVITY_REQUEST, fetchSiteScheduleActivitySaga);
	yield takeLatest(types.SET_SITE_SCHEDULE_ACTIVITY_PAGE_INDEX, fetchSiteScheduleActivitySaga);
	yield takeLatest(types.SET_SITE_SCHEDULE_ACTIVITY_SORT, fetchSiteScheduleActivitySaga);
	yield takeLatest(types.SET_SITE_SCHEDULE_ACTIVITY_FILTERS, fetchSiteScheduleActivitySaga);
	yield takeLatest(types.RESET_SITE_SCHEDULE_ACTIVITY_FILTERS, fetchSiteScheduleActivitySaga);
}
