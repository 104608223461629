import { CourseDetailResponse } from '@nimbly-technologies/nimbly-common';
import * as actions from './lmsCourse.action';
import * as types from './lmsCourse.actionTypes';
import { fetchLmsCourseById } from 'services/lms/lms.service';
import { call, put, takeLatest } from 'typed-redux-saga';

function* fetchLmsCourseByIdSaga(action: ReturnType<typeof actions.fetchLmsCourseByIdAsync.request>): Generator {
	try {
		const query = () => fetchLmsCourseById(action.payload);
		const course: CourseDetailResponse = (yield call(query) as unknown) as CourseDetailResponse;

		yield put(actions.fetchLmsCourseByIdAsync.success(course));
	} catch {
		yield put(actions.fetchLmsCourseByIdAsync.failure('Failed to get categories'));
	}
}

export default function* lmsCourseSaga() {
	yield takeLatest(types.FETCH_LMS_COURSE_BYID_REQUEST, fetchLmsCourseByIdSaga);
}
