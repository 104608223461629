import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from 'reducers/userRoles/userRoles.action';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';

function* fetchUserRolesCompact({ payload }: ReturnType<typeof actions.userRolesAction.request>) {
	const authToken = yield API.getFirebaseToken();

	const URL = `${apiURL}/user-roles/compact?withLMS=${payload?.withLMS || false}`;
	const response = yield call(API.get, URL, authToken);
	const { data, message } = yield response.json();
	if (response && response.status === 200) {
		yield put(actions.userRolesAction.success({ data, message }));
	} else {
		yield put(actions.userRolesAction.failure({ message }));
	}
}

export default function* userRolesCompactSaga() {
	yield takeLatest(actions.userRolesAction.request, fetchUserRolesCompact);
}
