import { UserCompactResponse } from '@nimbly-technologies/nimbly-common/src/models/api/lms/response';
import { apiURL } from '../../config/baseURL';
import API from '../../helpers/api';

export const fetchLmsUsersCompact = async (searchParams: string): Promise<UserCompactResponse[]> => {
	const url = `${apiURL}/lms/users/compact?${searchParams}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};
