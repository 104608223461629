import { createAsyncAction, action } from 'typesafe-actions';

import * as types from './promotions.actionType';
import { PromotionMongo, Promotion } from 'nimbly-common';

type PromotionUpdatePayload = {
  endAt: string;
  updatedAt: string;
  promotionID: string | null;
};

type PromotionCreatePayload = {
  cost: number;
  cover: (string | File)[];
  departments: string[];
  endAt: string;
  sites: string[];
  message: string;
  recipientType: string;
  startAt: string;
  title: string;
  status: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  timezone: string | undefined;
};

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });
export const setSort = (sortType: string, sortOrder?: string) => action(types.SET_SORT, { sortType, sortOrder });
export const setPageIndex = (value: number) => action(types.SET_PAGE_INDEX, { value });
export const setFilterQuery = (text: string) => action(types.SET_FILTER_QUERY, { text });
export const setSelectedTab = (tab: string) => action(types.SET_SELECTED_TAB, { tab });

export const fetchPromotions = createAsyncAction(
  types.FETCH_PROMOTIONS_REQUEST,
  types.FETCH_PROMOTIONS_SUCCESS,
  types.FETCH_PROMOTIONS_FAILURE
)<undefined, { data: { mapped: { [key: string]: Promotion }; raw: Promotion[] } }, { error: string }>();

type PaginationPayload = {
  limit: number;
};

export const fetchPaginatePromotions = createAsyncAction(
  types.FETCH_PAGINATE_PROMOTIONS_REQUEST,
  types.FETCH_PAGINATE_PROMOTIONS_SUCCESS,
  types.FETCH_PAGINATE_PROMOTIONS_FAILURE
)<PaginationPayload, { data: { value: Promotion; key: string }[] | null; total: number }, { error: string }>();

export const createPromotion = createAsyncAction(
  types.CREATE_PROMOTIONS_REQUEST,
  types.CREATE_PROMOTIONS_SUCCESS,
  types.CREATE_PROMOTIONS_FAILURE
)<PromotionCreatePayload, { data: Promotion }, { error: string }>();

export const fetchPromotionDetail = createAsyncAction(
  types.FETCH_PROMOTIONS_DETAIL_REQUEST,
  types.FETCH_PROMOTIONS_DETAIL_SUCCESS,
  types.FETCH_PROMOTIONS_DETAIL_FAILURE
)<string, { data: PromotionMongo | null }, { error: string }>();

export const updatePromotion = createAsyncAction(
  types.UPDATE_PROMOTIONS_REQUEST,
  types.UPDATE_PROMOTIONS_SUCCESS,
  types.UPDATE_PROMOTIONS_FAILURE
)<Partial<PromotionUpdatePayload>, { data: { [key: string]: PromotionMongo } }, { error: string }>();
