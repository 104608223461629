export const SET_FILTER_QUERY = '@dashboardRevamp/reportInsights/SET_FILTER_QUERY';

export const FETCH_COMPLETE_REPORT_INSIGHT_DETAIL_REQUEST =
	'@dashboardRevamp/reportInsights/FETCH_COMPLETE_REPORT_INSIGHT_DETAIL_REQUEST';
export const FETCH_COMPLETE_REPORT_INSIGHT_DETAIL_SUCCESS =
	'@dashboardRevamp/reportInsights/FETCH_COMPLETE_REPORT_INSIGHT_DETAIL_SUCCESS';
export const FETCH_COMPLETE_REPORT_INSIGHT_DETAIL_FAILURE =
	'@dashboardRevamp/reportInsights/FETCH_COMPLETE_REPORT_INSIGHT_DETAIL_FAILURE';

export const FETCH_REPORT_STATUS_LIST_REQUEST = '@dashboardRevamp/reportInsights/FETCH_REPORT_STATUS_LIST_REQUEST';
export const FETCH_REPORT_STATUS_LIST_SUCCESS = '@dashboardRevamp/reportInsights/FETCH_REPORT_STATUS_LIST_SUCCESS';
export const FETCH_REPORT_STATUS_LIST_FAILURE = '@dashboardRevamp/reportInsights/FETCH_REPORT_STATUS_LIST_FAILURE';

export const FETCH_REPORT_TYPE_LIST_REQUEST = '@dashboardRevamp/reportInsights/FETCH_REPORT_TYPE_LIST_REQUEST';
export const FETCH_REPORT_TYPE_LIST_SUCCESS = '@dashboardRevamp/reportInsights/FETCH_REPORT_TYPE_LIST_SUCCESS';
export const FETCH_REPORT_TYPE_LIST_FAILURE = '@dashboardRevamp/reportInsights/FETCH_REPORT_TYPE_LIST_FAILURE';

export const FETCH_REPORT_TABLE_COUNT_DATA_LIST_REQUEST =
	'@dashboardRevamp/reportInsights/FETCH_REPORT_TABLE_COUNT_DATA_LIST_REQUEST';
export const FETCH_REPORT_TABLE_COUNT_DATA_LIST_SUCCESS =
	'@dashboardRevamp/reportInsights/FETCH_REPORT_TABLE_COUNT_DATA_LIST_SUCCESS';
export const FETCH_REPORT_TABLE_COUNT_DATA_LIST_FAILURE =
	'@dashboardRevamp/reportInsights/FETCH_REPORT_TABLE_COUNT_DATA_LIST_FAILURE';

export const FETCH_REPORT_TABLE_RCR_DATA_LIST_REQUEST =
	'@dashboardRevamp/reportInsights/FETCH_REPORT_TABLE_RCR_DATA_LIST_REQUEST';
export const FETCH_REPORT_TABLE_RCR_DATA_LIST_SUCCESS =
	'@dashboardRevamp/reportInsights/FETCH_REPORT_TABLE_RCR_DATA_LIST_SUCCESS';
export const FETCH_REPORT_TABLE_RCR_DATA_LIST_FAILURE =
	'@dashboardRevamp/reportInsights/FETCH_REPORT_TABLE_RCR_DATA_LIST_FAILURE';

export const CLEAR_FILTERS = '@dashboardRevamp/reportInsights/CLEAR_FILTERS';

export const SET_FILTERS = '@dashboardRevamp/reportInsights/SET_FILTERS';
export const SET_GROUP_BY = '@dashboardRevamp/reportInsights/SET_GROUP_BY';
export const SET_DRILL_BY = '@dashboardRevamp/reportInsights/SET_DRILL_BY';
export const SET_REPORT_STATUS_BASIC_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_STATUS_BASIC_DATA';
export const SET_REPORT_STATUS_IS_LOADING = '@dashboardRevamp/reportInsights/SET_REPORT_STATUS_IS_LOADING';
export const SET_REPORT_STATUS_DRILLED_DATA_IS_LOADING =
	'@dashboardRevamp/reportInsights/SET_REPORT_STATUS_DRILLED_DATA_IS_LOADING';
export const SET_REPORT_STATUS_GROUP_BY_DRILL_BY =
	'@dashboardRevamp/reportInsights/SET_REPORT_STATUS_GROUP_BY_DRILL_BY';
export const SET_REPORT_STATUS_DRILLED_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_STATUS_DRILLED_DATA';

export const SET_REPORT_TYPE_BASIC_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_TYPE_BASIC_DATA';
export const SET_REPORT_TYPE_IS_LOADING = '@dashboardRevamp/reportInsights/SET_REPORT_TYPE_IS_LOADING';
export const SET_REPORT_TYPE_DRILLED_DATA_IS_LOADING =
	'@dashboardRevamp/reportInsights/SET_REPORT_TYPE_DRILLED_DATA_IS_LOADING';
export const SET_REPORT_TYPE_GROUP_BY_DRILL_BY = '@dashboardRevamp/reportInsights/SET_REPORT_TYPE_GROUP_BY_DRILL_BY';
export const SET_REPORT_TYPE_DRILLED_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_TYPE_DRILLED_DATA';

export const SET_REPORT_TABLE_COUNT_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_COUNT_DATA';
export const SET_REPORT_TABLE_COUNT_GROUP_BY = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_COUNT_GROUP_BY';
export const SET_REPORT_TABLE_COUNT_ROW_LIMIT = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_COUNT_ROW_LIMIT';
export const SET_REPORT_TABLE_COUNT_CURRENT_PAGE =
	'@dashboardRevamp/reportInsights/SET_REPORT_TABLE_COUNT_CURRENT_PAGE';
export const SET_REPORT_TABLE_RCR_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_RCR_DATA';
export const SET_REPORT_TABLE_RCR_GROUP_BY = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_RCR_GROUP_BY';
export const SET_REPORT_TABLE_RCR_ROW_LIMIT = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_RCR_ROW_LIMIT';
export const SET_REPORT_TABLE_RCR_CURRENT_PAGE = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_RCR_CURRENT_PAGE';
export const SET_REPORT_TABLE_IS_LOADING = '@dashboardRevamp/reportInsights/SET_REPORT_TABLE_IS_LOADING';

export const SET_REPORT_SUMMARY_IS_LOADING = '@dashboardRevamp/reportInsights/SET_REPORT_SUMMARY_IS_LOADING';
export const SET_REPORT_SUMMARY_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_SUMMARY_DATA';

export const SET_REPORT_DOWNLOAD_GROUP_BY_FIELD_NAMES =
	'@dashboardRevamp/reportInsights/SET_REPORT_DOWNLOAD_GROUP_BY_FIELD_NAMES';
export const SET_REPORT_DOWNLOAD_VIEW_DATA = '@dashboardRevamp/reportInsights/SET_REPORT_DOWNLOAD_VIEW_DATA';
export const SET_REPORT_DOWNLOAD_VIEW_IS_LOADING =
	'@dashboardRevamp/reportInsights/SET_REPORT_DOWNLOAD_VIEW_IS_LOADING';
