export const SET_ACTIVE_REPOSITORY_TAB = '@repository/SET_ACTIVE_REPOSITORY_TAB';
export const SET_REPOSITORY_SEARCH_QUERY = '@repository/SET_REPOSITORY_SEARCH_QUERY';
export const SET_REPOSITORY_FILTERS = '@repository/SET_REPOSITORY_FILTERS';
export const RESET_REPOSITORY_FILTERS = '@repository/RESET_REPOSITORY_FILTERS';
export const SET_REPOSITORY_GROUP_BY = '@repository/SET_REPOSITORY_GROUP_BY';
export const SET_REPOSITORY_PAGE_INDEX = '@repository/SET_REPOSITORY_PAGE_INDEX';
export const SET_REPOSITORY_GRID_SIZE = '@repository/SET_REPOSITORY_GRID_SIZE';
export const SET_REPOSITORY_VIEW_TYPE = '@repository/SET_REPOSITORY_VIEW_TYPE';
export const SET_REPOSITORY_SORT_BY = '@repository/SET_REPOSITORY_SORT_BY';
export const SET_REPOSITORY_SORT_TYPE = '@repository/SET_REPOSITORY_SORT_TYPE';
export const SET_REPOSITORY_FOLDER_HIERARCHY = '@repository/SET_REPOSITORY_FOLDER_HIERARCHY';
export const SET_REPOSITORY_FOLDERS = '@repository/SET_REPOSITORY_FOLDERS';
export const SET_REPOSITORY_FILES = '@repository/SET_REPOSITORY_FILES';
export const SET_REPOSITORY_FILE_SECTIONS = '@repository/SET_REPOSITORY_FILE_SECTIONS';

export const SET_SELECTED_REPOSITORY_FILE = '@repository/SET_SELECTED_REPOSITORY_FILE';
export const SET_SELECTED_REPOSITORY_FILE_IDS = '@repository/SET_SELECTED_REPOSITORY_FILE_IDS';
export const SET_TOTAL_SELECTED_REPOSITORY_FILES = '@repository/SET_TOTAL_SELECTED_REPOSITORY_FILES';
export const SET_TOTAL_SELECTED_REPOSITORY_FILES_SIZE = '@repository/SET_TOTAL_SELECTED_REPOSITORY_FILES_SIZE';
export const SET_REPOSITORY_UPLOAD_STATE = '@repository/SET_REPOSITORY_UPLOAD_STATE';

export const SET_SELECTED_REPOSITORY_FOLDER = '@repository/SET_SELECTED_REPOSITORY_FOLDER';
export const SET_SELECTED_REPOSITORY_FOLDER_IDS = '@repository/SET_SELECTED_REPOSITORY_FOLDER_IDS';

export const SET_SELECTED_REPOSITORY_DATES = '@repository/SET_SELECTED_REPOSITORY_DATES';

export const TOGGLE_REPOSITORY_FILTER = '@repository/TOGGLE_REPOSITORY_FILTER';
export const TOGGLE_REPOSITORY_SELECT_MODE = '@repository/TOGGLE_REPOSITORY_SELECT_MODE';
export const TOGGLE_REPOSITORY_BULK_OPERATION = '@repository/TOGGLE_REPOSITORY_BULK_OPERATION';

export const TOGGLE_FILE_PREVIEW_MODAL = '@repository/TOGGLE_FILE_PREVIEW_MODAL';
export const TOGGLE_NEW_FOLDER_MODAL = '@repository/TOGGLE_NEW_FOLDER_MODAL';
export const TOGGLE_RENAME_FOLDER_MODAL = '@repository/TOGGLE_RENAME_FOLDER_MODAL';
export const TOGGLE_DELETE_FOREVER_MODAL = '@repository/TOGGLE_DELETE_FOREVER_MODAL';
export const TOGGLE_EMPTY_TRASH_MODAL = '@repository/TOGGLE_EMPTY_TRASH_MODAL';
export const TOGGLE_RESTORE_MODAL = '@repository/TOGGLE_RESTORE_MODAL';
export const TOGGLE_SHARE_MODAL = '@repository/TOGGLE_SHARE_MODAL';
export const TOGGLE_MOVE_TO_FOLDER_MODAL = '@repository/TOGGLE_MOVE_TO_FOLDER_MODAL';
export const TOGGLE_STAR_MODAL = '@repository/TOGGLE_STAR_MODAL';
export const TOGGLE_TRASH_MODAL = '@repository/TOGGLE_TRASH_MODAL';
export const TOGGLE_UPLOAD_FILE_MODAL = '@repository/TOGGLE_UPLOAD_FILE_MODAL';
export const TOGGLE_DOWNLOAD_INITIALIZATION_MODAL = '@repository/TOGGLE_DOWNLOAD_INITIALIZATION_MODAL';
export const TOGGLE_DOWNLOAD_ERROR_MODAL = '@repository/TOGGLE_DOWNLOAD_ERROR_MODAL';
export const SET_DOWNLOAD_PROGRESS_MODAL_CONFIG = '@repository/SET_DOWNLOAD_PROGRESS_MODAL_CONFIG';

export const FETCH_REPOSITORY_REQUEST = '@repository/FETCH_REPOSITORY_REQUEST';
export const FETCH_REPOSITORY_SUCCESS = '@repository/FETCH_REPOSITORY_SUCCESS';
export const FETCH_REPOSITORY_FAILED = '@repository/FETCH_REPOSITORY_FAILED';

export const SEARCH_REPOSITORY_REQUEST = '@repository/SEARCH_REPOSITORY_REQUEST';
export const SEARCH_REPOSITORY_SUCCESS = '@repository/SEARCH_REPOSITORY_SUCCESS';
export const SEARCH_REPOSITORY_FAILED = '@repository/SEARCH_REPOSITORY_FAILED';
