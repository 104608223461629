export const FETCH_CAMPAIGN_LIST_REQUEST = 'customerFeedback/FETCH_CAMPAIGN_LIST_REQUEST';
export const FETCH_CAMPAIGN_LIST_SUCCESS = 'customerFeedback/FETCH_CAMPAIGN_LIST_SUCCESS';
export const FETCH_CAMPAIGN_LIST_FAIL = 'customerFeedback/FETCH_CAMPAIGN_LIST_FAIL';

export const POST_QUESTIONNAIRE_REQUEST = 'customerFeedback/POST_QUESTIONNAIRE_REQUEST';
export const POST_QUESTIONNAIRE_SUCCESS = 'customerFeedback/POST_QUESTIONNAIRE_SUCCESS';
export const POST_QUESTIONNAIRE_FAIL = 'customerFeedback/POST_QUESTIONNAIRE_FAIL';

export const GET_ONE_QUESTIONNAIRE_REQUEST = 'customerFeedback/GET_ONE_QUESTIONNAIRE_REQUEST';
export const GET_ONE_QUESTIONNAIRE_SUCCESS = 'customerFeedback/GET_ONE_QUESTIONNAIRE_SUCCESS';
export const GET_ONE_QUESTIONNAIRE_FAIL = 'customerFeedback/GET_ONE_QUESTIONNAIRE_FAIL';

export const PUT_QUESTIONNAIRE_REQUEST = 'customerFeedback/PUT_QUESTIONNAIRE_REQUEST';
export const PUT_QUESTIONNAIRE_SUCCESS = 'customerFeedback/PUT_QUESTIONNAIRE_SUCCESS';
export const PUT_QUESTIONNAIRE_FAIL = 'customerFeedback/PUT_QUESTIONNAIRE_FAIL';

export const FETCH_CAMPAIGN_REPORT_REQUEST = 'customerFeedback/FETCH_CAMPAIGN_REPORT_REQUEST';
export const FETCH_CAMPAIGN_REPORT_SUCCESS = 'customerFeedback/FETCH_CAMPAIGN_REPORT_SUCCESS';
export const FETCH_CAMPAIGN_REPORT_FAIL = 'customerFeedback/FETCH_CAMPAIGN_REPORT_FAIL';

export const FETCH_CAMPAIGN_QR_CODES_REQUEST = 'customerFeedback/FETCH_CAMPAIGN_QR_CODES_REQUEST';
export const FETCH_CAMPAIGN_QR_CODES_SUCCESS = 'customerFeedback/FETCH_CAMPAIGN_QR_CODES_SUCCESS';
export const FETCH_CAMPAIGN_QR_CODES_FAIL = 'customerFeedback/FETCH_CAMPAIGN_QR_CODES_FAIL';

export const SET_CAMPAIGN_STATUS_REQUEST = 'customerFeedback/SET_CAMPAIGN_STATUS_REQUEST';
export const SET_CAMPAIGN_STATUS_SUCCESS = 'customerFeedback/SET_CAMPAIGN_STATUS_SUCCESS';
export const SET_CAMPAIGN_STATUS_FAIL = 'customerFeedback/SET_CAMPAIGN_STATUS_FAIL';

export const GET_CAMPAIGN_TEMPLATE_REQUEST = 'customerFeedback/GET_CAMPAIGN_TEMPLATE_REQUEST';
export const GET_CAMPAIGN_TEMPLATE_SUCCESS = 'customerFeedback/GET_CAMPAIGN_TEMPLATE_SUCCESS';
export const GET_CAMPAIGN_TEMPLATE_FAILED = 'customerFeedback/GET_CAMPAIGN_TEMPLATE_FAILED';
