import { fetchQuizAssociationOptions } from 'services/lms/quizzes/quizzes.service';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import * as actions from '../../../../reducers/lms/quiz/quizAssociationOptions/quizAssociationOptions.action';

export function* fetchQuizAssociationOptionsSaga() {
	try {
		const quizAssociationOptions = yield* call(fetchQuizAssociationOptions);
		yield put(actions.fetchQuizAssociationOptions.success({ quizAssociationOptions }));
	} catch (e) {
		yield put(actions.fetchQuizAssociationOptions.failure({ error: 'Failed to fetch quiz association options' }));
		return null;
	}
}

export default function* quizAssociationOptionsSaga() {
	yield all([takeEvery(actions.fetchQuizAssociationOptions.request.toString(), fetchQuizAssociationOptionsSaga)]);
}
