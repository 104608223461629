import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './formulas.action';

export function* fetchFormulas(action: ReturnType<typeof actions.fetchFormulaAsync.request>) {
	const { page = 1, limit = 16, sortFields = '', sortDirections = '' } = action.payload;

	try {
		yield put(actions.setLoading(true));
		const token: string = yield call(API.getFirebaseToken);

		const query: { [key: string]: string } = {
			page: String(page),
			limit: String(limit),
			sortFields,
			sortDirections,
		};

		const queryString = new URLSearchParams(query);
		const url = `${apiURL}/questionnaires/formulas?${queryString}`;
		const response: any = yield call(API.get, url, token);

		if (response && response.status === 200) {
			const result: any = yield response.json();
			yield put(
				actions.fetchFormulaAsync.success({
					paginatedFormulas: result.data.docs,
					totalItem: result.data.totalDocs,
				}),
			);
			return;
		}

		yield put(actions.fetchFormulaAsync.failure({ error: response.message }));
		yield put(actions.setLoading(false));
	} catch (err) {
		yield put(actions.fetchFormulaAsync.success({ paginatedFormulas: [], totalItem: 0 }));
		yield put(actions.fetchFormulaAsync.failure({ error: '' }));
		yield put(actions.setLoading(false));
	}
}

export default function* formulasSaga() {
	yield takeLatest(actions.fetchFormulaAsync.request, fetchFormulas);
}
