export const FETCH_LMS_DASHBOARD_INDICATOR_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_INDICATOR_REQUEST';
export const FETCH_LMS_DASHBOARD_INDICATOR_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_INDICATOR_SUCCESS';
export const FETCH_LMS_DASHBOARD_INDICATOR_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_INDICATOR_FAILURE';

export const FETCH_LMS_DASHBOARD_COMPLETIONSTATS_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_COMPLETIONSTATS_REQUEST';
export const FETCH_LMS_DASHBOARD_COMPLETIONSTATS_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_COMPLETIONSTATS_SUCCESS';
export const FETCH_LMS_DASHBOARD_COMPLETIONSTATS_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_COMPLETIONSTATS_FAILURE';

export const FETCH_LMS_DASHBOARD_PASSFAILRATIO_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_PASSFAILRATIO_REQUEST';
export const FETCH_LMS_DASHBOARD_PASSFAILRATIO_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_PASSFAILRATIO_SUCCESS';
export const FETCH_LMS_DASHBOARD_PASSFAILRATIO_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_PASSFAILRATIO_FAILURE';

export const FETCH_LMS_DASHBOARD_LESSONPROGRESS_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_LESSONPROGRESS_REQUEST';
export const FETCH_LMS_DASHBOARD_LESSONPROGRESS_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_LESSONPROGRESS_SUCCESS';
export const FETCH_LMS_DASHBOARD_LESSONPROGRESS_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_LESSONPROGRESS_FAILURE';

export const FETCH_LMS_DASHBOARD_LESSONSTATUS_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_LESSONSTATUS_REQUEST';
export const FETCH_LMS_DASHBOARD_LESSONSTATUS_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_LESSONSTATUS_SUCCESS';
export const FETCH_LMS_DASHBOARD_LESSONSTATUS_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_LESSONSTATUS_FAILURE';

export const SET_LMS_DASHBOARD_LESSONSTATUS_DATE = '@dashboard/SET_LMS_DASHBOARD_LESSONSTATUS_DATE';

export const FETCH_LMS_DASHBOARD_SUMMARY_TABLE_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_SUMMARY_TABLE_REQUEST';
export const FETCH_LMS_DASHBOARD_SUMMARY_TABLE_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_SUMMARY_TABLE_SUCCESS';
export const FETCH_LMS_DASHBOARD_SUMMARY_TABLE_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_SUMMARY_TABLE_FAILURE';

export const SET_LMS_DASHBOARD_SUMMARY_TABLE_PAGE = '@dashboard/SET_LMS_DASHBOARD_SUMMARY_TABLE_PAGE';
export const SET_LMS_DASHBOARD_SUMMARY_TABLE_SORT = '@dashboard/SET_LMS_DASHBOARD_SUMMARY_TABLE_SORT';
export const SET_LMS_DASHBOARD_SUMMARY_TABLE_STATUS = '@dashboard/SET_LMS_DASHBOARD_SUMMARY_TABLE_STATUS';
export const SET_LMS_DASHBOARD_SUMMARY_TABLE_TYPE = '@dashboard/SET_LMS_DASHBOARD_SUMMARY_TABLE_TYPE';

export const FETCH_LMS_DASHBOARD_QUIZINSIGHT_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_QUIZINSIGHT_REQUEST';
export const FETCH_LMS_DASHBOARD_QUIZINSIGHT_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_QUIZINSIGHT_SUCCESS';
export const FETCH_LMS_DASHBOARD_QUIZINSIGHT_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_QUIZINSIGHT_FAILURE';

export const FETCH_LMS_DASHBOARD_ENTITY_STATUS_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_ENTITY_STATUS_REQUEST';
export const FETCH_LMS_DASHBOARD_ENTITY_STATUS_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_ENTITY_STATUS_SUCCESS';
export const FETCH_LMS_DASHBOARD_ENTITY_STATUS_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_ENTITY_STATUS_FAILURE';

export const FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_REQUEST';
export const FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_SUCCESS';
export const FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_FAILURE';

export const FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_REQUEST =
	'@dashboard/FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_REQUEST';
export const FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_SUCCESS =
	'@dashboard/FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_SUCCESS';
export const FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_FAILURE =
	'@dashboard/FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_FAILURE';

export const FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_REQUEST';
export const FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_SUCCESS';
export const FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_FAILURE';

export const FETCH_LMS_DASHBOARD_TIME_SPENT_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_TIME_SPENT_REQUEST';
export const FETCH_LMS_DASHBOARD_TIME_SPENT_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_TIME_SPENT_SUCCESS';
export const FETCH_LMS_DASHBOARD_TIME_SPENT_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_TIME_SPENT_FAILURE';

export const FETCH_LMS_DASHBOARD_TRENDS_REQUEST = '@dashboard/FETCH_LMS_DASHBOARD_TRENDS_REQUEST';
export const FETCH_LMS_DASHBOARD_TRENDS_SUCCESS = '@dashboard/FETCH_LMS_DASHBOARD_TRENDS_SUCCESS';
export const FETCH_LMS_DASHBOARD_TRENDS_FAILURE = '@dashboard/FETCH_LMS_DASHBOARD_TRENDS_FAILURE';

export const FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_REQUEST =
	'@dashboard/FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_REQUEST';
export const FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_SUCCESS =
	'@dashboard/FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_SUCCESS';
export const FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_FAILURE =
	'@dashboard/FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_FAILURE';

export const FETCH_LMS_ENTITY_DETAILS_REQUEST = '@dashboard/FETCH_LMS_ENTITY_DETAILS_REQUEST';
export const FETCH_LMS_ENTITY_DETAILS_SUCCESS = '@dashboard/FETCH_LMS_ENTITY_DETAILS_SUCCESS';
export const FETCH_LMS_ENTITY_DETAILS_FAILURE = '@dashboard/FETCH_LMS_ENTITY_DETAILS_FAILURE';
export const SET_LMS_ENTITY_DETAILS_PAGE = '@dashboard/SET_LMS_ENTITY_DETAILS_PAGE';

export const FETCH_LMS_ENROLLMENT_STATS_REQUEST = '@dashboard/FETCH_LMS_ENROLLMENT_STATS_REQUEST';
export const FETCH_LMS_ENROLLMENT_STATS_SUCCESS = '@dashboard/FETCH_LMS_ENROLLMENT_STATS_SUCCESS';
export const FETCH_LMS_ENROLLMENT_STATS_FAILURE = '@dashboard/FETCH_LMS_ENROLLMENT_STATS_FAILURE';

export const FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_REQUEST = '@dashboard/FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_REQUEST';
export const FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_SUCCESS = '@dashboard/FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_SUCCESS';
export const FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_FAILURE = '@dashboard/FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_FAILURE';

export const SET_LMS_DASHBOARD_FILTERS = '@dashboard/SET_LMS_DASHBOARD_FILTERS';
