import { all, call, put, takeLatest } from 'typed-redux-saga';
import * as actions from '../../reducers/site/siteOffDays/siteOffDays.action';
import { fetchSiteOffDays, upsertSiteOffDays } from 'services/siteOffDays/siteOffDays.service';
import { SiteOffdayMongo } from '@nimbly-technologies/nimbly-common';
import Monitoring from 'utils/monitoring/Monitoring';

export function* fetchSiteOffDaysSaga({ payload }: ReturnType<typeof actions.fetchSiteOffDays.request>) {
	try {
		const { siteID } = payload;

		const siteOffDays: SiteOffdayMongo = yield* call(fetchSiteOffDays, siteID);

		yield put(actions.fetchSiteOffDays.success({ data: siteOffDays || new SiteOffdayMongo({}) }));
	} catch (e) {
		Monitoring.logEvent('fetchSiteOffDaysSaga', e);
		yield put(actions.fetchSiteOffDays.failure({ error: e.message || 'Failed to fetch site off days' }));
		return null;
	}
}

export function* upsertSiteOffDaysSaga({ payload }: ReturnType<typeof actions.upsertSiteOffDays.request>) {
	try {
		const { siteID, updatedSiteOffDays } = payload;
		yield* call(upsertSiteOffDays, siteID, updatedSiteOffDays);
		yield put(actions.upsertSiteOffDays.success({ data: new SiteOffdayMongo(updatedSiteOffDays) }));
	} catch (e) {
		Monitoring.logEvent('upsertSiteOffDaysSaga', e);
		yield put(actions.upsertSiteOffDays.failure({ error: e.message || 'Failed to update site off days' }));
	}
}
export default function* siteOffDaysSaga() {
	yield all([
		takeLatest(actions.fetchSiteOffDays.request.toString(), fetchSiteOffDaysSaga),
		takeLatest(actions.upsertSiteOffDays.request.toString(), upsertSiteOffDaysSaga),
	]);
}
