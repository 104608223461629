import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './siteCompact.action';
import { SiteCompactState } from './type';

const initialState: SiteCompactState = {
  isLoading: false,
  data: [],
  message: ''
};

export type SiteCompactAction = ActionType<typeof actions.siteCompactAction>;
export const siteCompactReducer = createReducer<SiteCompactState, SiteCompactAction>(initialState)
  .handleAction(actions.siteCompactAction.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(actions.siteCompactAction.success, (state, action) => ({
    ...state,
    data: action.payload.data,
    message: action.payload.message,
    isLoading: false
  }))
  .handleAction(actions.siteCompactAction.failure, (state, action) => ({
    ...state,
    message: action.payload.message,
    isLoading: false
  }));
