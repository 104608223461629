export const SET_LOADING_STATE = '@scheduleTemplate/SET_LOADING_STATE';
export const SET_READY_STATE = '@scheduleTemplate/SET_READY_STATE';
export const RESET_SCHEDULE_TEMPLATE_STATE = '@scheduleTemplate/RESET_SCHEDULE_TEMPLATE_STATE';

export const SHOW_SAVE_TEMPLATE_MODAL = '@scheduleTemplate/SHOW_SAVE_TEMPLATE_MODAL';
export const SHOW_IMPORT_TEMPLATE_MODAL = '@scheduleTemplate/SHOW_IMPORT_TEMPLATE_MODAL';
export const DISMISS_SAVE_TEMPLATE_MODAL = '@scheduleTemplate/DISMISS_SAVE_TEMPLATE_MODAL';
export const DISMISS_IMPORT_TEMPLATE_MODAL = '@scheduleTemplate/DISMISS_IMPORT_TEMPLATE_MODAL';

export const SET_QUESTIONNAIRE_TEMPLATE = '@scheduleTemplate/SET_QUESTIONNAIRE_TEMPLATE';
export const SET_IMPORT_TEMPLATE_LIST = '@scheduleTemplate/SET_IMPORT_TEMPLATE_LIST';

export const REMOVE_TEMPLATE = '@scheduleTemplate/REMOVE_TEMPLATE';
