import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './siteGroup.action';
import { siteGroupCompactState } from './type';

const initialState: siteGroupCompactState = {
	isLoading: false,
	data: [],
	message: '',
};

export type siteGroupCompactAction = ActionType<typeof actions.siteGroupAction>;
export const siteGroupCompactReducer = createReducer<siteGroupCompactState, siteGroupCompactAction>(initialState)
	.handleAction(actions.siteGroupAction.request, (state) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(actions.siteGroupAction.success, (state, action) => ({
		...state,
		data: action.payload.data,
		message: action.payload.message,
		isLoading: false,
	}))
	.handleAction(actions.siteGroupAction.failure, (state, action) => ({
		...state,
		message: action.payload.message,
		isLoading: false,
	}));
