import { VerifyAuthCodePayload, VerifyAuthCodeResponse } from 'reducers/superadmin/clientLogin/loginAccess/types';
import { getToken } from 'reducers/api';
import { apiURL } from '../../../../config/baseURL';

export const verifyAuthCode = async (payload: VerifyAuthCodePayload): Promise<VerifyAuthCodeResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify(payload)
    };
    const url = `${apiURL}/auth/confirm-code`;

    const res = await fetch(url, options);
    const verifyAuthCodeResult: VerifyAuthCodeResponse = await res.json();

    if (res.status !== 200) {
      throw new Error(verifyAuthCodeResult.message);
    }

    return verifyAuthCodeResult;
  } catch (error) {
    const errorResult: VerifyAuthCodeResponse = {
      message: 'FAILED',
      error: error.message
    };
    return errorResult;
  }
};
