import { SingleCourse } from '../courses/type';
export enum LmsCoursesFetchState {
	LOADING,
	SUCCESS,
	FAILURE,
}
export interface LmsCouseList {
	message: string;
	data: LmsCourseAPIData;
}

export interface LmsCourseAPIData {
	docs: SingleCourse[];
	totalDocs: number;
	limit: number;
	page: number;
	totalPages: number;
}

export interface UpdateUserGroupEnrollCourse {
	groupID: string;
	courseIDs: string[];
}
export interface LmsCoursesState {
	fetchState: LmsCoursesFetchState;
	courseList: SingleCourse[];
	errorMessage?: string;
	pageIndex: number;
	limit: number;
	search: string;
	sortBy: string;
	sortDirection: SortDirection;
	totalCourse: number;
	isShowModal: boolean;
	isLoading: boolean;
	isSuccess: boolean;
}

export type SortDirection = 'asc' | 'desc';
