import { createAsyncAction } from 'typesafe-actions';
import {
  UserNPS,
  FETCH_ALL_USER_NPS_REQUEST,
  FETCH_ALL_USER_NPS_SUCCESS,
  FETCH_ALL_USER_NPS_FAILURE,
  FETCH_USER_NPS_REQUEST,
  FETCH_USER_NPS_SUCCESS,
  FETCH_USER_NPS_FAILURE,
  FetchUserNPSPayload,
  UPSERT_USER_NPS_REQUEST,
  UPSERT_USER_NPS_SUCCESS,
  UPSERT_USER_NPS_FAILURE,
  UpsertUserNPSPayload
} from './types';

export const fetchAllUserNPSAsync = createAsyncAction(
  FETCH_ALL_USER_NPS_REQUEST,
  FETCH_ALL_USER_NPS_SUCCESS,
  FETCH_ALL_USER_NPS_FAILURE
)<undefined, UserNPS[], string>();

export const fetchUserNPSAsync = createAsyncAction(
  FETCH_USER_NPS_REQUEST,
  FETCH_USER_NPS_SUCCESS,
  FETCH_USER_NPS_FAILURE
)<FetchUserNPSPayload, UserNPS, string>();

export const upsertUserNPSAsync = createAsyncAction(
  UPSERT_USER_NPS_REQUEST,
  UPSERT_USER_NPS_SUCCESS,
  UPSERT_USER_NPS_FAILURE
)<undefined, UserNPS, string>();
