import { ColumnType } from 'components/dashboardRevamp/ReportPreviewModal/ReportPreviewUtils';
import moment from 'moment';

export enum TrendsType {
	Course = 'course',
	Lesson = 'lesson',
}

export enum LmsTab {
	Course = 'course',
	Lesson = 'lesson',
	Quiz = 'quiz',
	Learner = 'learner',
	Instructor = 'instructor',
}

export enum UserRole {
	Admin = 'admin',
	Instructor = 'instructor',
	Learner = 'learner',
}

export enum DrillBy {
	LearnerName = 'learner',
	CourseName = 'course',
}

export const DrillByOptions = [
	{
		label: 'Learner',
		value: DrillBy.LearnerName,
	},
	{
		label: 'Course Name',
		value: DrillBy.CourseName,
	},
];

export enum Status {
	Completed = 'completed',
	NotStarted = 'not_started',
	InProgress = 'in_progress',
	All = '',
}

export enum LessonStatus {
	Active = 'active',
	Inactive = 'inactive',
	Draft = 'draft',
}

export const StatusOptions = [
	{ label: 'Completed', value: Status.Completed },
	{ label: 'Not Started', value: Status.NotStarted },
	{ label: 'In Progress', value: Status.InProgress },
];

export interface ChartLabels {
	widget: string;
	yLabel: string;
	xLabel: string;
}

export const defaultChartLabels: ChartLabels = {
	widget: '',
	yLabel: '',
	xLabel: '',
};

interface BaseColumnsConfig {
	headerLabel: string;
	fieldName: string;
	widthClassName?: string;
	type?: ColumnType;
}

export type ColumnsConfig = BaseColumnsConfig & {
	subColumns?: BaseColumnsConfig[];
};

export interface DateType {
	startDate: string | undefined | null;
	endDate: string | undefined | null;
}

export function hasAccessToDashboard(userRole: UserRole) {
	return [UserRole.Admin, UserRole.Instructor, UserRole.Learner].includes(userRole);
}

export const tableHeaderClass =
	'aa-px-2 aa-py-3 aa-border aa-border-color-table-border aa-bg-color-table-bg aa-font-semibold';
export const tableCellClass = 'aa-p-2 aa-border aa-border-color-table-border';

export const DEFAULT_START_DATE = moment().subtract(7, 'days').format('YYYY-MM-DD');
export const DEFAULT_END_DATE = moment().format('YYYY-MM-DD');
