/** Filter query information */
export const SET_GROUP_BY = '@dashboardRevamp/operational/SET_GROUP_BY';
export const SET_PERIOD = '@dashboardRevamp/operational/SET_PERIOD';
export const SET_FILTERS = '@dashboardRevamp/operational/SET_FILTERS';
export const SET_FILTER_QUERY = '@dashboardRevamp/operational/SET_FILTER_QUERY';

export const FETCH_COMPLETE_REPORT_DETAIL_REQUEST = '@dashboardRevamp/operational/FETCH_COMPLETE_REPORT_DETAIL_REQUEST';
export const FETCH_COMPLETE_REPORT_DETAIL_SUCCESS = '@dashboardRevamp/operational/FETCH_COMPLETE_REPORT_DETAIL_SUCCESS';
export const FETCH_COMPLETE_REPORT_DETAIL_FAILURE = '@dashboardRevamp/operational/FETCH_COMPLETE_REPORT_DETAIL_FAILURE';

export const FETCH_ISSUE_OVERVIEW_DETAIL_REQUEST = '@dashboardRevamp/operational/FETCH_ISSUE_OVERVIEW_DETAIL_REQUEST';
export const FETCH_ISSUE_OVERVIEW_DETAIL_SUCCESS = '@dashboardRevamp/operational/FETCH_ISSUE_OVERVIEW_DETAIL_SUCCESS';
export const FETCH_ISSUE_OVERVIEW_DETAIL_FAILURE = '@dashboardRevamp/operational/FETCH_ISSUE_OVERVIEW_DETAIL_FAILURE';

export const FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_REQUEST =
	'@dashboardRevamp/operational/FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_REQUEST';
export const FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_SUCCESS =
	'@dashboardRevamp/operational/FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_SUCCESS';
export const FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_FAILURE =
	'@dashboardRevamp/operational/FETCH_LAST_MONTH_ISSUE_OVERVIEW_DETAIL_FAILURE';

export const FETCH_TOP_ISSUES_LIST_REQUEST = '@dashboardRevamp/operational/FETCH_TOP_ISSUES_LIST_REQUEST';
export const FETCH_TOP_ISSUES_LIST_SUCCESS = '@dashboardRevamp/operational/FETCH_TOP_ISSUES_LIST_SUCCESS';
export const FETCH_TOP_ISSUES_LIST_FAILURE = '@dashboardRevamp/operational/FETCH_TOP_ISSUES_LIST_FAILURE';

export const FETCH_TOP_REPORTS_LIST_REQUEST = '@dashboardRevamp/operational/FETCH_TOP_REPORTS_LIST_REQUEST';
export const FETCH_TOP_REPORTS_LIST_SUCCESS = '@dashboardRevamp/operational/FETCH_TOP_REPORTS_LIST_SUCCESS';
export const FETCH_TOP_REPORTS_LIST_FAILURE = '@dashboardRevamp/operational/FETCH_TOP_REPORTS_LIST_FAILURE';

export const FETCH_ISSUE_CHART_REQUEST = '@dashboardRevamp/operational/FETCH_ISSUE_CHART_REQUEST';
export const FETCH_ISSUE_CHART_SUCCESS = '@dashboardRevamp/operational/FETCH_ISSUE_CHART_SUCCESS';
export const FETCH_ISSUE_CHART_FAILURE = '@dashboardRevamp/operational/FETCH_ISSUE_CHART_FAILURE';

export const FETCH_REPORT_CHART_REQUEST = '@dashboardRevamp/operational/FETCH_REPORT_CHART_REQUEST';
export const FETCH_REPORT_CHART_SUCCESS = '@dashboardRevamp/operational/FETCH_REPORT_CHART_SUCCESS';
export const FETCH_REPORT_CHART_FAILURE = '@dashboardRevamp/operational/FETCH_REPORT_CHART_FAILURE';

export const CLEAR_FILTERS = '@dashboardRevamp/operational/CLEAR_FILTERS';
export const SET_TOP_CHART_DATA = '@dashboardRevamp/operational/SET_TOP_CHART_DATA';
export const SET_TOP_CHART_IS_LOADING = '@dashboardRevamp/operational/SET_TOP_CHART_IS_LOADING';

export const SET_TRENDS_CHART_IS_LOADING = '@dashboardRevamp/operational/SET_TRENDS_CHART_IS_LOADING';
export const SET_TRENDS_CHART_ISSUE_DATA = '@dashboardRevamp/operational/SET_TRENDS_CHART_ISSUE_DATA';
export const SET_TRENDS_CHART_REPORT_DATA = '@dashboardRevamp/operational/SET_TRENDS_CHART_REPORT_DATA';

export const SET_REPORT_DOWNLOAD_VIEW_IS_LOADING = '@dashboardRevamp/operational/SET_REPORT_DOWNLOAD_VIEW_IS_LOADING';
export const SET_REPORT_DOWNLOAD_VIEW_GROUP_BY_FIELD_NAMES =
	'@dashboardRevamp/operational/SET_REPORT_DOWNLOAD_VIEW_GROUP_BY_FIELD_NAMES';
export const SET_REPORT_DOWNLOAD_VIEW_PERIOD = '@dashboardRevamp/operational/SET_REPORT_DOWNLOAD_VIEW_PERIOD';
export const SET_REPORT_DOWNLOAD_VIEW_DATA = '@dashboardRevamp/operational/SET_REPORT_DOWNLOAD_VIEW_DATA';
export const SET_REPORT_DOWNLOAD_VIEW_TENDS_DATA = '@dashboardRevamp/operational/SET_REPORT_DOWNLOAD_VIEW_TENDS_DATA';
export const SET_REPORT_DOWNLOAD_VIEW_TRENDS_FIELD_NAMES =
	'@dashboardRevamp/operational/SET_REPORT_DOWNLOAD_VIEW_TRENDS_FIELD_NAMES';
