import { createAsyncAction, action } from 'typesafe-actions';
import * as types from './tutorial.actionTypes';
import { enums } from 'nimbly-common';
import { CumulativeProgress } from './type.d';

export type FetchTutorialResponse = {
  progress: Partial<
    {
      [k in enums.OrganizationOnboardingProgress]: boolean;
    }
  >;
  isFinished?: boolean;
  currentProgress?: string;
  cumulativeProgress?: Partial<
    {
      [k in CumulativeProgress]: boolean;
    }
  >;
  isOrganizationOnboarding: boolean;
};

export const fetchTutorialAsync = createAsyncAction(
  types.FETCH_TUTORIAL_REQUEST,
  types.FETCH_TUTORIAL_SUCCESS,
  types.FETCH_TUTORIAL_FAILURE
)<undefined, FetchTutorialResponse, { error: string }>();

export const updateTutorialAsync = createAsyncAction(
  types.UPDATE_TUTORIAL_REQUEST,
  types.UPDATE_TUTORIAL_SUCCESS,
  types.UPDATE_TUTORIAL_FAILURE
)<undefined, any, { error: string }>();

export const setToFinish = () => action(types.SET_TO_FINISH);
export const updateProgress = (
  previous: Partial<enums.OrganizationOnboardingProgress>,
  next: Partial<enums.OrganizationOnboardingProgress>
) => action(types.UPDATE_PROGRESS, { previous, next });
export const updateCumulative = (value: Partial<CumulativeProgress>) =>
  action(types.UPDATE_CUMULATIVE_PROGRESS, { value });
