import React, { useState, useEffect } from 'react';

import styled from 'styled-components/macro';
import { Overlay, Modal } from 'styles/General';
import { Button } from 'styles/Button';
import { useTranslation } from 'react-i18next';
import WarningIcon from '../../assets/icon/warning.svg';

const AdBlockBlocker = () => {
  const [blockerDetected, setBlockerDetected] = useState(false);
  const [modalActive, setModalActive] = useState(true);

  useEffect(() => {
    init();
  });

  /**
   * How the ad blocker detection system works:
   * 1. System attempts to inject dummy script to
   *    the head containting the word `ad`
   * 2. Any ad blocker will detect this script
   *    (because it contains to word `ad`) and will
   *    prevent the script from running
   * 3. When the script did not load (onerror), it
   *    means the ad blocker is active and block
   *    dialog will be shown
   * 4. Else, the system will just ignore it.
   *
   * As for the function use `var = function() { }`
   * instead of `var = () => { }`, the second one
   * will cause fatal error in run time.
   *
   */
  const init = () => {
    if (!modalActive) return;

    const head = document.getElementsByTagName('head')[0];
    const tempDummy = document.getElementById('ad-init');
    const dummy = document.createElement('script');

    // <script id="ad-init" async="false"
    // type="application/javascript" src="./adframe.js"
    // ></script>
    //
    // DO NOT remove the script from (app/public/index.html)
    // as it can cause MIME error
    //
    // This function remove the script that has been registered
    // in index.html before injecting it with new dummy script.
    // The injection is needed so the onLoad and onError function
    // can be injected to the dummy script.
    //
    if (tempDummy) {
      try {
        head.removeChild(tempDummy);
      } catch (e) {}
    }

    // Create new dummy script
    dummy.id = 'dummy-ad';
    dummy.type = 'application/javascript';
    dummy.src = '/adframe.js';
    dummy.async = false;
    dummy.onload = function () {
      setBlockerDetected(false);
      setModalActive(false);
    };
    dummy.onerror = function () {
      // TODO: current error handler is too general (does not catch fetch failure or request timeout)
      // need to find better solution
      // setBlockerDetected(true);
      // setModalActive(true);
    };

    // Embed dummy script into head
    head.appendChild(dummy);
  };

  const handleIgnore = () => {
    setModalActive(false);
  };

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const { t } = useTranslation();

  return (
    <div id="dummy-ad">
      {blockerDetected && modalActive ? (
        <AdBlockContainer>
          <Overlay isActive={modalActive}>
            <ModalBlocker isActive={modalActive}>
              <ModalHeader>
                <ModalIcon>
                  <img src={WarningIcon} alt="warning" />
                </ModalIcon>
                <ModalTitle>
                  <h1>{t('modal.adBlocker.title')}</h1>
                </ModalTitle>
              </ModalHeader>
              <ModalContent>
                <p>{t('modal.adBlocker.description')}</p>
              </ModalContent>

              <ModalFooter>
                <ButtonBlank onClick={handleIgnore}>{t('modal.adBlocker.button.ignore')}</ButtonBlank>
                <Button onClick={handleRefreshPage}>{t('modal.adBlocker.button.refresh')}</Button>
              </ModalFooter>
            </ModalBlocker>
          </Overlay>
        </AdBlockContainer>
      ) : null}

      <AdBlockWarning shown={blockerDetected && !modalActive}>
        <AdBlockWarningContainer>
          <AdBlockWarningContainerIcon>
            <img src={WarningIcon} alt="warning" />
          </AdBlockWarningContainerIcon>
          <AdBlockWarningContainerContent>
            <h1>{t('modal.adBlocker.footer.title')}</h1>
            <p>{t('modal.adBlocker.footer.content')}</p>
          </AdBlockWarningContainerContent>
          <RefreshButton onClick={handleRefreshPage}>{t('modal.adBlocker.button.refresh')}</RefreshButton>
        </AdBlockWarningContainer>
      </AdBlockWarning>
    </div>
  );
};

export default AdBlockBlocker;

const RefreshButton = styled(Button)`
  border: 1px solid #fff;
  padding-left: 40px;
  padding-right: 40px;
  background: none;

  &:hover {
    background: #fff;
    color: var(--color-primary);
    border: 1px solid #fff;
  }
`;

const ButtonBlank = styled(Button)`
  background: none;
  border: none;
  color: #000;

  &:hover {
    background: none;
    border: none;
    color: var(--color-primary);
  }
`;

const ModalBlocker = styled(Modal)`
  padding: 40px;
  width: 100%;

  @media (min-width: 992px) {
    width: 450px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: top;
`;

const ModalIcon = styled.div`
  padding-left: 0px;
  padding-right: 10px;
`;

const ModalTitle = styled.div`
  padding-left: 10px;

  & h1 {
    font-size: 24px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    margin-top: -5px;
  }
`;

const ModalContent = styled.div`
  margin-top: 20px;

  & p {
    margin: 0px;
  }
`;

const AdBlockContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  & > * {
    margin: 4px;
    width: 150px;

    @media (max-width: 500px) {
      width: auto;
    }
  }
`;

const AdBlockWarning = styled.div`
  max-height: ${props => (props.shown ? '500px' : '0px')};
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  line-height: 150%;
  color: #fff;
  overflow: hidden;
  position: fixed;
  transition: 0.5s all;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: hidden;
  justify-content: center;
  @media (max-width: 992px) {
    bottom: 60px;
  }
`;

const AdBlockWarningContainer = styled.div`
  padding: 20px;
  width: 900px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const AdBlockWarningContainerIcon = styled.div`
  padding-left: 0px;
  padding-right: 15px;
  width: 75px;
  min-width: 75px;

  & > img {
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 50px;
    margin-bottom: 20px;
  }
`;

const AdBlockWarningContainerContent = styled.div`
  margin-top: -5px;
  padding-right: 20px;

  & h1 {
    font-size: 16px;
  }

  & p {
    font-size: 14px;
  }

  & > * {
    color: #fff;
    margin: 0px;
    line-height: 125%;
  }

  @media (max-width: 992px) {
    margin-bottom: 20px;
  }
`;
