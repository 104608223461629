import { action, createAsyncAction } from 'typesafe-actions';

import * as types from './sites.actionTypes';
import { SortType, OrderedSite } from './sites.reducer';

import { Sites, SiteExtend, TabOptions } from './type';

// Reducer for sites management
export const clearSitesFilterQuery = () => action(types.CLEAR_FILTER_QUERY);

export const dismissScheduleModal = () => action(types.DISMISS_SCHEDULE_MODAL);

export const dismissBulkModal = () => action(types.DISMISS_BULK_MODAL);

export const dismissBulkScheduleModal = () => action(types.DISMISS_BULK_SCHEDULE);

export const dismissBulkScheduleEditModal = () => action(types.DISMISS_BULK_SCHEDULE_EDIT);

export const dismissConfirmModal = () => action(types.DISMISS_CONFIRM_BLOCK_MODAL);

export const getPopulatedSites = (limit: number) => action(types.GET_POPULATED_SITES, { limit });

export const setSitesFilterQuery = (text: string) => action(types.SET_FILTER_QUERY, { text });

export const setSortSites = (newType: SortType, newOrder = null) => action(types.SET_SORT, { newType, newOrder });

export const setColorSort = () => action(types.SET_COLOR);

export const setSitesScrollPosition = (newScroll: number) => action(types.SET_SCROLL, { newScroll });

export const setSiteTab = (tab: string) => action(types.SET_SITE_TAB, { tab });
export const setSiteListTab = (tab: TabOptions) => action(types.SET_SITE_LIST_TAB, { tab });

export const showScheduleModal = (key: string) => action(types.SHOW_SCHEDULE_MODAL, { key });

export const showBulkModal = () => action(types.SHOW_BULK_MODAL);

export const showBulkScheduleModal = () => action(types.SHOW_BULK_SCHEDULE);

export const showBulkScheduleEditModal = () => action(types.SHOW_BULK_SCHEDULE_EDIT);

export const setIsLoading = (value: boolean) => action(types.SET_IS_LOADING, { value });

export const setSites = (sites: OrderedSite[]) => action(types.SET_SITES, { sites });

export const setPageIndex = (value: number) => action(types.SET_PAGE_INDEX, { value });

export const setProcessedSites = (sites: OrderedSite[]) => action(types.SET_PROCESSED_SITES, { sites });

export const showConfirmModal = (site: any) => action(types.SHOW_CONFIRM_BLOCK_MODAL, site);

export const setIsConfirmationLoading = (value: boolean) => action(types.SET_IS_CONFIRMATION_LOADING, { value });

export const setIsSitesLoaded = (value: boolean) => action(types.SET_IS_SITES_LOADED, { value });

export const setError = (value: string) => action(types.SET_ERROR, { value });

export const resetSite = () => action(types.RESET_SITE);

export const setTotalSites = (value: number) => action(types.SET_TOTAL_SITES, { value });

export const setTotalActive = (value: number) => action(types.SET_TOTAL_ACTIVE, { value });

export const setTotalDisabled = (value: number) => action(types.SET_TOTAL_DISABLED, { value });

export const resetSiteSettings = () => action(types.RESET_SITE_SETTINGS);

export const fetchSites = createAsyncAction(
	types.FETCH_SITES_REQUEST,
	types.FETCH_SITES_SUCCESS,
	types.FETCH_SITES_FAILURE,
)<undefined, { data: Sites | null; ordered: OrderedSite[] }, { error: string }>();

export const fetchPaginateSites = createAsyncAction(
	types.FETCH_PAGINATES_SITES_REQUEST,
	types.FETCH_PAGINATES_SITES_SUCCESS,
	types.FETCH_PAGINATES_SITES_FAILURE,
)<undefined, { data: Sites | null }, { error: string }>();

export const fetchSiteById = createAsyncAction(
	types.FETCH_SITE_BY_ID_REQUEST,
	types.FETCH_SITE_BY_ID_SUCCESS,
	types.FETCH_SITE_BY_ID_FAILURE,
)<{ siteKey: string }, { data: SiteExtend | null }, { error: string }>();

export const fetchSiteDepartments = createAsyncAction(
	types.FETCH_SITE_DEPARTMENTS_REQUEST,
	types.FETCH_SITE_DEPARTMENTS_SUCCESS,
	types.FETCH_SITE_DEPARTMENTS_FAILURE,
)<{ siteKey: string }, { data: string[] }, { error: string }>();

export const updateSiteDepartments = createAsyncAction(
	types.UPDATE_SITE_DEPARTMENTS_REQUEST,
	types.UPDATE_SITE_DEPARTMENTS_SUCCESS,
	types.UPDATE_SITE_DEPARTMENTS_FAILURE,
)<{ siteKey: string; data: string[] }, undefined, { error: string }>();
