import { enums } from 'nimbly-common';
import { call, put, takeLatest, select } from 'redux-saga/effects';

// utils
import { apiURL } from 'config/baseURL';
import { getToken } from 'reducers/api';

// action-reducers
import * as actions from 'reducers/featureAccess/featureAccess.action';

// types
import * as types from 'reducers/featureAccess/featureAccess.actionTypes';
import { RootState } from 'store/rootReducers';
import { FeatureAccessState } from '../../reducers/featureAccess/type';

export async function fetchFeatures(url: string, options: any) {
	const response = await fetch(url, options);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const features: { [K in enums.FeaturesType]: boolean } = {} as { [K in enums.FeaturesType]: boolean };

	if (response && response.status === 200) {
		const responseData = await response.json();
		const resultFeatures = Object.keys(responseData.data.features) as enums.FeaturesType[];
		const resultPackage: enums.Package = responseData.data.package;
		resultFeatures.forEach((f: enums.FeaturesType) => {
			features[f] = responseData.data.features[f];
		});

		return { features, packageType: resultPackage };
	}

	return null;
}

function* fetchFeatureAccess() {
	try {
		const authToken = yield getToken();
		const getState = (state: RootState) => state;
		const state: RootState = yield select(getState);

		const featState: FeatureAccessState = state.featureAccess;
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};
		const additional = featState.currentOrg === '' ? '/user-auth' : `/${featState.currentOrg}`;
		const fetchFeatureAccessURL = `${apiURL}/organizations/features${additional}`;

		// @ts-ignore
		const response = yield call(fetchFeatures, fetchFeatureAccessURL, options);
		if (response !== null) {
			const features: FeatureAccessState['features'] = { ...featState.features };
			(Object.keys(features) as Array<keyof typeof features>).forEach((key) => {
				features[key] = response.features[key] || false;
			});
			featState.packageType = response.packageType;
			yield put(actions.fetchFeatureAccess.success({ data: features, packageType: response.packageType }));
		} else {
			yield put(actions.fetchFeatureAccess.failure({ error: 'Failed to Fetch Feature Aceess' }));
		}

		return;
	} catch (e) {
		yield put(actions.fetchFeatureAccess.failure({ error: 'Failed to Fetch Feature Aceess' }));
	}
}

export default function* featureAccessSaga() {
	yield takeLatest(types.FETCH_FEATURE_ACCESS_REQUEST, fetchFeatureAccess);
}
