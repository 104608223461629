const SET_API_KEY = 'gmaps/SET_API_KEY';

// Reducer for auditor management
export const setGoogleMapsKey = key => {
  return { type: SET_API_KEY, key };
};

const initialState = {
  apiKey: null
};

export function gmapsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_API_KEY:
      return {
        ...state,
        apiKey: action.key
      };
    default:
      return state;
  }
}
