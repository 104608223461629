import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './healthDashboard.action';
import { IHealthDashboardStore, IOverallHealthBase, IIssueRateBase, ISuggestionBase } from './healthDashboard.type';
import moment from 'moment';
import * as types from './healthDashboard.actionTypes';

const overallHealthInitialState: IOverallHealthBase = {
	data: null,
	isLoading: false,
};
const issueRateInitialState: IIssueRateBase = {
	data: null,
	isLoading: false,
};
const suggestionInitialState: ISuggestionBase = {
	data: null,
	isLoading: false,
};

const initialState: IHealthDashboardStore = {
	startDate: moment().subtract(1, 'month'),
	endDate: moment(),
	overallHealth: overallHealthInitialState,
	issueResolution: issueRateInitialState,
	issueCompletion: issueRateInitialState,
	issueFlag: issueRateInitialState,
	resolutionSuggestion: suggestionInitialState,
	completionSuggestion: suggestionInitialState,
	flagSuggestion: suggestionInitialState,
};

export type HealthDashboardAction = ActionType<typeof actions>;

export const healthDashboardReducer = createReducer<IHealthDashboardStore, HealthDashboardAction>(initialState)
	.handleAction(actions.fetchHealthDashboardResolutionSuggestionAsync.request, (state) => ({
		...state,
		resolutionSuggestion: {
			...state.resolutionSuggestion,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchHealthDashboardResolutionSuggestionAsync.success, (state, action) => {
		return {
			...state,
			resolutionSuggestion: {
				...state.resolutionSuggestion,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchHealthDashboardResolutionSuggestionAsync.failure, (state, action) => ({
		...state,
		resolutionSuggestion: suggestionInitialState,
	}))

	.handleAction(actions.fetchHealthDashboardCompletionSuggestionAsync.request, (state) => ({
		...state,
		completionSuggestion: {
			...state.completionSuggestion,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchHealthDashboardCompletionSuggestionAsync.success, (state, action) => {
		return {
			...state,
			completionSuggestion: {
				...state.completionSuggestion,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchHealthDashboardCompletionSuggestionAsync.failure, (state, action) => ({
		...state,
		flagSuggestion: suggestionInitialState,
	}))

	.handleAction(actions.fetchHealthDashboardFlagRateSuggestionAsync.request, (state) => ({
		...state,
		flagSuggestion: {
			...state.flagSuggestion,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchHealthDashboardFlagRateSuggestionAsync.success, (state, action) => {
		return {
			...state,
			flagSuggestion: {
				...state.flagSuggestion,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchHealthDashboardFlagRateSuggestionAsync.failure, (state, action) => ({
		...state,
		flagSuggestion: suggestionInitialState,
	}))

	.handleAction(actions.fetchHealthDashboardResolutionRateAsync.request, (state) => ({
		...state,
		issueResolution: {
			...state.issueResolution,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchHealthDashboardResolutionRateAsync.success, (state, action) => {
		return {
			...state,
			issueResolution: {
				...state.issueResolution,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchHealthDashboardResolutionRateAsync.failure, (state, action) => ({
		...state,
		issueResolution: issueRateInitialState,
	}))

	.handleAction(actions.fetchHealthDashboardCompletionRateAsync.request, (state) => ({
		...state,
		issueCompletion: {
			...state.issueCompletion,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchHealthDashboardCompletionRateAsync.success, (state, action) => {
		return {
			...state,
			issueCompletion: {
				...state.issueCompletion,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchHealthDashboardCompletionRateAsync.failure, (state, action) => ({
		...state,
		issueCompletion: issueRateInitialState,
	}))
	.handleAction(actions.fetchHealthDashboardFlagRateAsync.request, (state) => ({
		...state,
		issueFlag: {
			...state.issueFlag,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchHealthDashboardFlagRateAsync.success, (state, action) => {
		return {
			...state,
			issueFlag: {
				...state.issueFlag,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchHealthDashboardFlagRateAsync.failure, (state, action) => ({
		...state,
		issueFlag: issueRateInitialState,
	}))

	.handleAction(types.SET_HEALTH_DASHBOARD_FILTER_DATE as any, (state, action) => {
		return {
			...state,
			startDate: action.payload.startDate,
			endDate: action.payload.endDate,
		};
	});
