export const SET_SHOW_MODAL = 'userManagement/SET_SHOW_MODAL';
export const SET_SUCCESS = 'userManagement/SET_SUCCESS';

export const CREATE_USER_REQUEST = 'userManagement/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'userManagement/CREATE_USER_LOADING';
export const CREATE_USER_FAILURE = 'userManagement/CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'userManagement/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'userManagement/UPDATE_USER_LOADING';
export const UPDATE_USER_FAILURE = 'userManagement/UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'userManagement/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'userManagement/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'userManagement/DELETE_USER_FAILURE';

export const SELECTED_EDIT_LMS_USER = 'userManagement/SELECTED_EDIT_LMS_USER';
export const SELECTED_EDIT_LMS_USERS = 'userManagement/SELECTED_EDIT_LMS_USERS';
