import { call, put, takeLatest } from 'typed-redux-saga';
import * as types from '../../reducers/skuUom/skuUom.actionTypes';
import * as actions from '../../reducers/skuUom/skuUom.action';
import { UOM } from '@nimbly-technologies/nimbly-common';
import { fetchSkuUoms } from '../../services/sku/sku.service';

function* fetchSkuUomsSaga(): Generator {
	try {
		const uoms: UOM[] = yield* call(fetchSkuUoms);
		yield put(actions.fetchSkuUoms.success(uoms));
	} catch {
		yield put(actions.fetchSkuUoms.failure('Failed to get categories'));
	}
}

export default function* skuUomSaga() {
	yield takeLatest(types.FETCH_SKU_UOMS_REQUEST, fetchSkuUomsSaga);
}
