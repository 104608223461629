import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import { User } from 'nimbly-common';

import * as types from './auditors.actionTypes';
import * as actions from './auditors.actions';

export type AuditorsActions = ActionType<typeof actions>;
export interface AuditorsState {
	modalVisible: boolean;
	downloadModalVisible: boolean;
	isDownloading: boolean;
	selectedUserKey: null | string;
	selectedUserDeleteKey: null | string;
	selectedUserDownloadKeys: { [userID: string]: boolean };
	filterQuery: string;
	sortBy: string;
	displayName: 'asc' | 'desc';
	role: 'asc' | 'desc';
	email: 'asc' | 'desc';
	status: 'asc' | 'desc';
	scrollPosition: number;
	bulkModal: boolean;
	superadminModal: boolean;
	deleteModalVisible: boolean;
	selectedTab: string;
	pageIndex: number;
}

const initialState: AuditorsState = {
	modalVisible: false,
	downloadModalVisible: false,
	isDownloading: false,
	selectedUserKey: null,
	selectedUserDeleteKey: null,
	selectedUserDownloadKeys: {},
	filterQuery: '',
	sortBy: 'displayName',
	displayName: 'asc',
	role: 'asc',
	email: 'asc',
	status: 'asc',
	scrollPosition: 0,
	bulkModal: false,
	superadminModal: false,
	deleteModalVisible: false,
	selectedTab: 'published',
	pageIndex: 1,
};

export const auditorsReducer = createReducer<AuditorsState, AuditorsActions>(initialState)
	.handleType(types.SHOW_SUPERADMIN_MODAL_BULK, (state) =>
		update(state, {
			superadminModal: { $set: true },
		}),
	)
	.handleType(types.DISMISS_SUPERADMIN_MODAL_BULK, (state) =>
		update(state, {
			superadminModal: { $set: false },
		}),
	)
	.handleType(types.SHOW_MODAL_BULK, (state) =>
		update(state, {
			bulkModal: { $set: true },
		}),
	)
	.handleType(types.DISMISS_MODAL_BULK, (state) =>
		update(state, {
			bulkModal: { $set: false },
		}),
	)
	.handleType(types.SHOW_MODAL, (state, action) =>
		update(state, {
			modalVisible: { $set: true },
			selectedUserKey: { $set: action.payload.uid },
		}),
	)
	.handleType(types.DISMISS_MODAL, (state) =>
		update(state, {
			modalVisible: { $set: false },
			selectedUserKey: { $set: null },
		}),
	)
	.handleType(types.SET_FILTER_QUERY, (state, action) =>
		update(state, {
			filterQuery: { $set: action.payload.text },
			pageIndex: { $set: initialState.pageIndex },
		}),
	)
	.handleType(types.CLEAR_FILTER_QUERY, (state) =>
		update(state, {
			filterQuery: { $set: '' },
		}),
	)
	.handleType(types.SET_SORT, (state, action) =>
		update(state, {
			sortBy: { $set: action.payload.newType },
			[action.payload.newType]: { $set: action.payload.newOrder },
		}),
	)
	.handleType(types.SET_SCROLL, (state, action) =>
		update(state, {
			scrollPosition: { $set: action.payload.newScroll },
		}),
	)
	.handleType(types.SHOW_MODAL_DELETE, (state, action) =>
		update(state, {
			selectedUserDeleteKey: { $set: action.payload.auditor },
			deleteModalVisible: { $set: true },
		}),
	)
	.handleType(types.DISMISS_MODAL_DELETE, (state) =>
		update(state, {
			selectedUserDeleteKey: { $set: null },
			deleteModalVisible: { $set: false },
		}),
	)
	.handleType(types.SHOW_MODAL_DOWNLOAD, (state) =>
		update(state, {
			downloadModalVisible: { $set: true },
		}),
	)
	.handleType(types.DISMISS_MODAL_DOWNLOAD, (state) =>
		update(state, {
			downloadModalVisible: { $set: false },
		}),
	)
	.handleType(types.DOWNLOAD_LOADING, (state, action) =>
		update(state, {
			isDownloading: { $set: action.payload.isDownloading },
		}),
	)
	.handleType(types.SET_AUDITOR_DOWNLOAD, (state, action) =>
		update(state, {
			selectedUserDownloadKeys: { $set: action.payload.auditorIDs },
		}),
	)
	.handleType(types.SET_AUDITOR_TAB, (state, action) =>
		update(state, {
			selectedTab: { $set: action.payload.tab },
		}),
	)
	.handleType(types.SET_PAGE_INDEX, (state, action) =>
		update(state, {
			pageIndex: { $set: action.payload.index },
		}),
	)
	.handleType(types.RESET_PAGINATE_FILTER, (state) =>
		update(state, {
			pageIndex: { $set: initialState.pageIndex },
			filterQuery: { $set: initialState.filterQuery },
			displayName: { $set: 'asc' },
			role: { $set: 'asc' },
			email: { $set: 'asc' },
		}),
	);
