import { getIdToken } from 'hooks/useFirebaseAuth';
import Monitoring from '../utils/monitoring/Monitoring';
import fetchBuilder from 'fetch-retry';

/**
 * Represents an API helper class for making HTTP requests.
 */
export default class API {
	private static fetchRetry = fetchBuilder(fetch, { retryCount: 5, retryDelay: 1000 });

	/**
	 * Retrieves the Firebase token.
	 * @returns A promise that resolves to the Firebase token.
	 */
	public static async getFirebaseToken(): Promise<string> {
		const token = await getIdToken();

		return token || '';
	}

	/**
	 * Sends a GET request to the specified URL.
	 * @param url - The URL to send the request to.
	 * @param token - The authorization token. It can be undefined if the token is not available.
	 * @returns A promise that resolves to the response of the GET request.
	 */
	public static get(url: string, token: string) {
		return fetch(url, {
			method: 'GET',
			headers: {
				authorization: token,
			},
		});
	}

	/**
	 * Sends a GET request to the specified URL with retry.
	 * @param url - The URL to send the request to.
	 * @param token - The authorization token. It can be undefined if the token is not available.
	 * @returns A promise that resolves to the response of the GET request.
	 */
	public static getRetry(url: string, token: string) {
		const options = {
			method: 'GET',
			headers: {
				authorization: token,
			},
		};
		return API.fetchRetry(url, options);
	}

	/**
	 * Sends a POST request to the specified URL.
	 * @param url - The URL to send the request to.
	 * @param token - The authorization token. It can be undefined if the token is not available.
	 * @param data - The data to send in the request body.
	 * @returns A promise that resolves to the response of the POST request.
	 * @throws An error if the request fails.
	 */
	public static async post(url: string, token: string | undefined, data: any) {
		const authToken = token || (await API.getFirebaseToken());

		const res = await fetch(url, {
			method: 'POST',
			headers: {
				authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		if (res.status >= 200 && res.status < 400) {
			return res;
		}

		const json = await res.json();

		throw new Error(json?.data || json?.message || 'Failed to post data');
	}

	/**
	 * Sends a POST request to the specified URL and parses the response as JSON.
	 * @param url The URL to send the request to.
	 * @param token Optional authorization token. If not provided, a Firebase token will be automatically used.
	 * @param data The payload for the POST request.
	 * @returns {Promise<T>} A promise that resolves to the parsed JSON response.
	 * @throws {Error} Throws an error if the request fails or the response cannot be parsed as JSON.
	 */
	public static async postJSON<T>(url: string, data: any, token?: string): Promise<T> {
		const res = await API.post(url, token, data);
		return res.json();
	}

	/**
	 * Sends a POST request to the specified URL with retry logic.
	 * @param {string} url - The URL to send the request to.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @param {any} data - The data to send in the request body.
	 * @returns {Promise<Response>} The response from the server.
	 */
	public static async postRetry(url: string, token: string | undefined, data: any) {
		const authToken = token || (await API.getFirebaseToken());

		const options = {
			method: 'POST',
			headers: {
				authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		};
		return API.fetchRetry(url, options);
	}

	/**
	 * Sends a POST request to the specified URL with a file in the body.
	 * @param {string} url - The URL to send the request to.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @param {any} file - The file to send in the request body.
	 * @returns {Promise<Response>} The response from the server.
	 */
	public static async postFile(url: string, token: string | undefined, file: any) {
		const authToken = token || (await API.getFirebaseToken());

		return fetch(url, {
			method: 'POST',
			headers: {
				authorization: authToken,
			},
			body: file,
		});
	}

	/**
	 * Sends a PUT request to the specified URL with a file in the body.
	 * @param {string} url - The URL to send the request to.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @param {any} file - The file to send in the request body.
	 * @returns {Promise<Response>} The response from the server.
	 */
	public static async putFile(url: string, token: string | undefined, file: any) {
		const authToken = token || (await API.getFirebaseToken());

		return fetch(url, {
			method: 'PUT',
			headers: {
				authorization: authToken,
			},
			body: file,
		});
	}

	/**
	 * Sends a PUT request to the specified URL.
	 * @param {string} url - The URL to send the request to.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @param {any} data - The data to send in the request body.
	 * @param {Object} ctx - Optional context object to include in the headers.
	 * @returns {Promise<Response>} The response from the server.
	 */
	// eslint-disable-next-line max-params
	public static async put(url: string, token: string | undefined, data: any, ctx?: { internal?: string }) {
		const authToken = token || (await API.getFirebaseToken());

		let headers = {
			authorization: authToken,
			'Content-Type': 'application/json',
		};

		if (ctx) {
			headers = Object.assign(headers, ctx);
		}

		return fetch(url, {
			method: 'PUT',
			headers,
			body: JSON.stringify(data),
		});
	}

	/**
	 * Sends a PUT request to the specified URL and parses the response as JSON.
	 * @param url The URL to send the request to.
	 * @param token Optional authorization token. If not provided, a Firebase token will be automatically used.
	 * @param data The payload for the PUT request.
	 * @param ctx Optional context to include in the request headers.
	 * @returns {Promise<T>} A promise that resolves to the parsed JSON response.
	 * @throws {Error} Throws an error if the request fails or the response cannot be parsed as JSON.
	 */
	public static async putJSON<T>(url: string, data: any, ctx?: { internal?: string }, token?: string): Promise<T> {
		const res = await API.put(url, token, data, ctx);
		return res.json();
	}

	/**
	 * Sends a GET request to the specified URL with query parameters and parses the response as JSON.
	 * @param {string} url - The URL to send the request to.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @param {Record<string, string | string[]>} queryParams - The query parameters to append to the URL.
	 * @returns {Promise<R | null>} The parsed JSON response, or null if the response is not JSON.
	 * @throws {Error} Throws an error if the request fails or the response cannot be parsed as JSON.
	 */
	public static async getJSON<R extends { [key: string]: any }>(
		url: string,
		token?: string,
		queryParams?: Record<string, undefined | boolean | string | string[]>,
	): Promise<R | null> {
		const queryString = queryParams ? API.toQueryString(queryParams) : null;
		const fullUrl = queryString ? `${url}?${queryString}` : url;
		const authorization = token ?? (await this.getFirebaseToken());

		const res = await fetch(fullUrl, {
			headers: {
				authorization,
				'Content-Type': 'application/json',
			},
		});

		const contentType = res.headers.get('Content-Type');

		if (!contentType?.includes('application/json')) {
			return null;
		}

		const json: R = await res.json();

		if (res.status >= 200 && res.status < 400) {
			return json;
		}

		throw new Error(json?.message || 'Failed to retrieve data');
	}

	/**
	 * Sends a GET request to the specified URL with cancellation support and parses the response as JSON.
	 * @param {string} url - The URL to send the request to.
	 * @param {string} token - The authorization token.
	 * @param {any} signal - The AbortSignal to use for cancellation.
	 * @returns {Promise<R | null>} The parsed JSON response, or null if the response is not JSON or the request was cancelled.
	 */
	public static async getJSONCancellation<R extends { [key: string]: any }>(
		url: string,
		token: string,
		signal: any,
	): Promise<R | null> {
		try {
			const res = await fetch(url, {
				headers: {
					authorization: token,
					'Content-Type': 'application/json',
				},
				signal,
			});

			const contentType = res.headers.get('Content-Type');

			if (!contentType?.includes('application/json')) {
				return null;
			}

			const json: R = await res.json();

			if (res.status >= 200 && res.status < 400) {
				return json;
			}

			throw new Error(json?.message || 'Failed to retrieve data');
		} catch (ex) {
			Monitoring.logEvent('getJSONCancellation', ex);
			if ((ex as Error).name === 'AbortError') {
				return null; // Continuation logic has already been skipped, so return normally
			}

			throw ex;
		}
	}

	/**
	 * Sends a DELETE request to the specified URL.
	 * @param {string} url - The URL to send the request to.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @param {any} data - Optional data to send in the request body.
	 * @returns {Promise<Response>} The response from the server.
	 */
	public static async delete(url: string, token: string | undefined, data?: any) {
		const authToken = token || (await API.getFirebaseToken());

		return fetch(url, {
			method: 'DELETE',
			headers: {
				authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
	}

	/**
	 * Sends a DELETE request to the specified URL and parses the response as JSON.
	 * @param url The URL to send the request to.
	 * @param token Optional authorization token. If not provided, a Firebase token will be automatically used.
	 * @param data Optional payload for the DELETE request.
	 * @returns {Promise<T>} A promise that resolves to the parsed JSON response.
	 * @throws {Error} Throws an error if the request fails or the response cannot be parsed as JSON.
	 */
	public static async deleteJSON<T>(url: string, data?: any, token?: string): Promise<T> {
		const authToken = token || (await API.getFirebaseToken());
		const res = await fetch(url, {
			method: 'DELETE',
			headers: {
				authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: data ? JSON.stringify(data) : undefined,
		});

		if (res.status >= 200 && res.status < 400) {
			return res.json();
		}

		const json = await res.json();
		throw new Error(json?.message || 'Failed to delete data');
	}

	/**
	 * Sends a PATCH request to the specified URL.
	 * @param {string} url - The URL to send the request to.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @param {any} data - The data to send in the request body.
	 * @returns {Promise<Response>} The response from the server.
	 */
	public static async patch(url: string, token: string | undefined, data: any) {
		const authToken = token || (await API.getFirebaseToken());

		return fetch(url, {
			method: 'PATCH',
			headers: {
				authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
	}

	/**
	 * Sends a PATCH request to the specified URL and parses the response as JSON.
	 * @param {string} url - The URL to send the request to.
	 * @param {any} data - The data to send in the request body.
	 * @param {string | undefined} token - The authorization token. If not provided, a Firebase token will be used.
	 * @returns {Promise<R | null>} The parsed JSON response, or null if the response is not JSON.
	 * @throws {Error} Throws an error if the response status is not in the range 200-399 or if the response is not JSON.
	 */
	public static async patchJSON<R extends { [key: string]: any }>(
		url: string,
		data: any,
		token?: string,
	): Promise<R | null> {
		const authorization = token ?? (await this.getFirebaseToken());

		const res = await this.patch(url, authorization, data);

		const contentType = res.headers.get('Content-Type');

		if (!contentType?.includes('application/json')) {
			return null;
		}

		const json: R = await res.json();

		if (res.status >= 200 && res.status < 400) {
			return json;
		}

		throw new Error(json?.message || 'Failed to patch data');
	}

	/**
	 * Constructs a query string from a parameters object.
	 * @param params An object containing the query parameters as key-value pairs.
	 * @returns {string} A query string.
	 */
	private static toQueryString(params: Record<string, undefined | boolean | string | string[]>): string | null {
		// filter out undefined values
		const queryParams = Object.keys(params).reduce<Record<string, boolean | string | string[]>>((acc, key) => {
			if (params[key] !== undefined) {
				acc[key] = params[key] as boolean | string | string[];
			}
			return acc;
		}, {});

		if (Object.keys(queryParams).length === 0) {
			return null;
		}

		return Object.keys(queryParams)
			.map((key) => {
				const value = queryParams[key];
				if (Array.isArray(value)) {
					return value.map((val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
				}
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			})
			.join('&');
	}
}
