import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import * as actions from './lmsCsManagementPage.action';
import * as types from './lmsCsManagementPage.actionTypes';
import { lmsCsManagementPageState } from './type.d';
import { LmsDataType, LmsViewType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';

export type CoursesAction = ActionType<typeof actions>;

export const initialState: lmsCsManagementPageState = {
	dataType: LmsDataType.SYLLABUS,
	viewType: LmsViewType.TILE,
	selectedCourses: [],
	selectedCourseRows: [],

	selectedSyllabus: [],
	selectedSyllabusRow: [],

	confirmationModalConfig: {
		isVisible: false,
		title: '',
		content: '',
		confirmButtonText: '',
		cancelButtonText: '',
		onCancel: () => {},
		onConfirm: () => {},
	},
	isConfirmationModalOpen: false,
	isBulkEditModalOpen: false,
	isBulkEditErrorModalOpen: false,
};

export const lmsCsManagementPageReducer = createReducer<lmsCsManagementPageState, CoursesAction>(initialState)
	.handleType(types.SET_DATA_TYPE, (state, action) =>
		update(state, {
			dataType: { $set: action.payload.dataType },
		}),
	)
	.handleType(types.SET_VIEW_TYPE, (state, action) =>
		update(state, {
			viewType: { $set: action.payload.viewType },
		}),
	)
	.handleType(types.SET_SELECTED_COURSES, (state, action) =>
		update(state, {
			selectedCourses: { $set: action.payload.selectedCourses },
		}),
	)
	.handleType(types.SET_SELECTED_COURSE_ROWS, (state, action) =>
		update(state, {
			selectedCourseRows: { $set: action.payload.selectedCourseRows },
		}),
	)
	.handleType(types.SET_CONFIRMATION_MODAL_CONFIG, (state, action) =>
		update(state, {
			confirmationModalConfig: {
				$set: {
					...state.confirmationModalConfig,
					...action.payload.modalConfig,
				},
			},
		}),
	)
	.handleType(types.TOGGLE_BULK_EDIT_MODAL, (state, action) =>
		update(state, {
			isBulkEditModalOpen: { $set: action.payload.isOpen },
		}),
	)
	.handleType(types.TOGGLE_BULK_EDIT_ERROR_MODAL, (state, action) =>
		update(state, {
			isBulkEditErrorModalOpen: { $set: action.payload.isOpen },
		}),
	)
	.handleType(types.TOGGLE_CONFIRMATION_MODAL, (state, action) =>
		update(state, {
			isConfirmationModalOpen: { $set: action.payload.isOpen },
		}),
	)
	.handleType(types.RESET_PAGE_STATE, (state) =>
		update(state, {
			dataType: { $set: initialState.dataType },
			viewType: { $set: initialState.viewType },
			isBulkEditModalOpen: { $set: initialState.isBulkEditModalOpen },
			isConfirmationModalOpen: { $set: initialState.isConfirmationModalOpen },
		}),
	)
	.handleType(types.SET_SELECTED_SYLLABUS, (state, action) =>
		update(state, {
			selectedSyllabus: { $set: action.payload.selectedSyllabus },
		}),
	)
	.handleType(types.SET_SELECTED_SYLLABUS_ROWS, (state, action) =>
		update(state, {
			selectedSyllabusRow: { $set: action.payload.selectedSyllabusRow },
		}),
	);
