import moment from 'moment-timezone';

// For manual organization schedules, returns the next due date for individual site as a string
function siteNextDueDate(orgSchedule, siteSchedule, reportIndex) {
  // Subtract 4 hours from the now comparison, effectively allowing reports for day x to be done till x + 1 @ 4:00am
  const now = moment.parseZone().subtract(4, 'hours');
  const end = currentPeriodEndDate(orgSchedule);
  const keys = siteSchedule
    ? Object.keys(siteSchedule).filter(
        key =>
          key !== 'assignedAuditor' && moment(key).isSameOrAfter(now, 'day') && moment(key).isSameOrBefore(end, 'day')
      )
    : [];
  keys.sort();
  let output = null;
  let k = 0;
  while (!output && k < keys.length) {
    const key = keys[k];
    if (!reportIndex || !reportIndex[key] || reportIndex[key].status === 'draft') {
      output = key;
    } else {
      k += 1;
    }
  }
  return output;
}

// For manual organization schedules, returns array of dates if site has an undone report within period
// If true, it means that the undone report can be 'made up', or done now but with a red mark
function siteUndoneReportDates(orgSchedule, siteSchedule, reportIndex) {
  const start = currentPeriodStartDate(orgSchedule).subtract(4, 'hours');
  const now = moment.parseZone();
  const keys = siteSchedule
    ? Object.keys(siteSchedule).filter(
        key =>
          key !== 'assignedAuditor' &&
          moment(key).isSameOrAfter(start, 'day') &&
          moment(key).isBefore(now, 'day') &&
          (!reportIndex || !reportIndex[key])
      )
    : [];
  keys.sort();
  return keys;
}

// Returns the current period's start date as a moment object
function currentPeriodStartDate(orgSchedule) {
  const now = moment.parseZone();
  let start;
  if (orgSchedule && orgSchedule.periodStart) {
    switch (orgSchedule.periodUnit) {
      case 'week':
        start =
          now.isoWeekday() >= orgSchedule.periodStart
            ? now.startOf('isoWeek')
            : now.subtract(1, 'week').startOf('isoWeek');
        return start.isoWeekday(orgSchedule.periodStart);
      case 'month':
        start =
          now.date() >= orgSchedule.periodStart ? now.startOf('month') : now.subtract(1, 'month').startOf('month');
        return start.date(orgSchedule.periodStart);
      case 'year':
        start = now.date() >= orgSchedule.periodStart ? now.startOf('year') : now.subtract(1, 'year').startOf('year');
        return start.date(orgSchedule.periodStart);
      default:
        return null;
    }
  }
  return null;
}

// Returns the current period's start date as a moment object
function currentPrevPeriodStartDate(orgSchedule) {
  const now = moment.parseZone();
  let start;
  if (orgSchedule && orgSchedule.periodStart) {
    switch (orgSchedule.periodUnit) {
      case 'week':
        start =
          now.isoWeekday() >= orgSchedule.periodStart
            ? now.subtract(1, 'week').startOf('isoWeek')
            : now.subtract(2, 'week').startOf('isoWeek');
        return start.isoWeekday(orgSchedule.periodStart);
      case 'month':
        start =
          now.date() >= orgSchedule.periodStart
            ? now.subtract(1, 'month').startOf('month')
            : now.subtract(2, 'month').startOf('month');
        return start.date(orgSchedule.periodStart);
      case 'year':
        start =
          now.date() >= orgSchedule.periodStart
            ? now.subtract(1, 'year').startOf('year')
            : now.subtract(2, 'year').startOf('year');
        return start.date(orgSchedule.periodStart);
      default:
        return null;
    }
  }
  return null;
}

// Returns the current period's end date as a moment object
function currentPeriodEndDate(orgSchedule) {
  const start = currentPeriodStartDate(orgSchedule);
  if (orgSchedule) {
    switch (orgSchedule.periodUnit) {
      case 'week':
        return start.add(7, 'days').subtract(1, 'ms');
      case 'month':
        return start.add(start.daysInMonth(), 'days').subtract(1, 'ms');
      case 'year':
        return start.add(1, 'year').subtract(1, 'ms');
      default:
        return null;
    }
  }
  return null;
}

// Returns the previous period's end date as a moment object
function previousPeriodEndDate(orgSchedule) {
  const start = currentPeriodStartDate(orgSchedule);
  return start ? start.subtract(1, 'ms') : null;
}

function reportsDueThisPeriod(orgSchedule, siteSchedule, site) {
  const startDate = currentPeriodStartDate(orgSchedule).format('YYYY-MM-DD');
  const endDate = currentPeriodEndDate(orgSchedule).format('YYYY-MM-DD');
  if (orgSchedule.type === 'manual') {
    return siteSchedule
      ? Object.keys(siteSchedule).filter(date => date !== 'assignedAuditor' && date >= startDate && date <= endDate)
          .length
      : 0;
  }
  return 0;
}

function upcomingReportDate(orgSchedule, siteSchedule) {
  const endDate = currentPeriodEndDate(orgSchedule).format('YYYY-MM-DD');
  const upcomingDates = siteSchedule
    ? Object.keys(siteSchedule).filter(date => date !== 'assignedAuditor' && date >= endDate)
    : [];
  return upcomingDates.length > 0 ? upcomingDates[0] : 0;
}

// Takes a report index and Schedule
// Returns number of completed reports in the period (0 means fully completed)
function reportsCompletedInPeriod(orgSchedule, reportIndex) {
  if (!reportIndex) {
    return 0;
  }
  // Filter the reports by completed
  const filteredReportIndex = Object.assign({}, reportIndex);
  Object.keys(filteredReportIndex).forEach(reportKey => {
    if (filteredReportIndex[reportKey].status !== 'complete') {
      delete filteredReportIndex[reportKey];
    }
  });
  // Get the current period's dates
  const periodStartDate = currentPeriodStartDate(orgSchedule);
  const periodEndDate = currentPeriodEndDate(orgSchedule);
  // Count number of completed reports in the period
  let count = 0;
  if (periodStartDate) {
    const periodStartDateString = periodStartDate.toISOString(true).slice(0, 10);
    const periodEndDateString = periodEndDate.toISOString(true).slice(0, 10);
    Object.keys(filteredReportIndex).forEach(reportKey => {
      if (reportKey >= periodStartDateString && reportKey <= periodEndDateString) {
        count += 1;
      }
    });
  }
  return count;
}

// Returns array of available string dates (in auditorStat) within its selected period
function getFilteredReportDates(auditorStat, startDate, endDate) {
  const periodStartDate = startDate.startOf('day');
  const periodEndDate = endDate.endOf('day');
  const reportDates = auditorStat ? Object.keys(auditorStat) : [];

  return reportDates.filter(date => {
    const parsedDate = moment.parseZone(date);
    return parsedDate.isSameOrAfter(periodStartDate) && parsedDate.isSameOrBefore(periodEndDate);
  });
}

export {
  siteNextDueDate,
  siteUndoneReportDates,
  currentPeriodStartDate,
  currentPeriodEndDate,
  previousPeriodEndDate,
  reportsDueThisPeriod,
  reportsCompletedInPeriod,
  getFilteredReportDates,
  currentPrevPeriodStartDate,
  upcomingReportDate
};
