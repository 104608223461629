import { FindSKUPerCategory, SKUCompact } from '@nimbly-technologies/nimbly-common';
import { createAsyncAction } from 'typesafe-actions';
import * as types from './skusCompact.actionTypes';

export const fetchSkusCompact = createAsyncAction(
	types.FETCH_SKUS_COMPACT_REQUEST,
	types.FETCH_SKUS_COMPACT_SUCCESS,
	types.FETCH_SKUS_COMPACT_FAILED,
)<
	{ siteID: string },
	{ skusCompactWithCategory: FindSKUPerCategory; skusCompact: SKUCompact[] | [] },
	{ error: string }
>();
