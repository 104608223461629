import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './syllabuses.action';
import { ILmsSyllabusesStore } from './syllabuses.type';

const initialFetchState = {
	data: null,
	errorMessage: '',
	isLoading: false,
};

const initialState: ILmsSyllabusesStore = {
	fetchById: initialFetchState,
};

export type LmsSyllabusesAction = ActionType<typeof actions>;

export const lmsSyllabusesReducer = createReducer<ILmsSyllabusesStore, LmsSyllabusesAction>(initialState)
	.handleAction(actions.fetchLmsSyllabusesByIdAsync.request, (state) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchLmsSyllabusesByIdAsync.success, (state, action) => {
		return {
			...state,
			fetchById: {
				...state.fetchById,
				isLoading: false,
				data: action.payload,
			},
		};
	})
	.handleAction(actions.fetchLmsSyllabusesByIdAsync.failure, (state, action) => ({
		...state,
		fetchById: {
			...state.fetchById,
			isLoading: false,
			errorMessage: action.payload,
		},
	}));
