import { takeLatest, put, call, select } from 'redux-saga/effects';

import { apiURL, appengineURL } from 'config/baseURL';
import { User } from 'nimbly-common';
import { UserRoles } from 'reducers/users/type';
import { getToken } from 'reducers/api';
import * as types from 'reducers/users/users.actionType';
import * as actions from 'reducers/users/users.action';
import { RootState } from 'store/rootReducers';
import { setToSession, checkExpiredStorageItem } from 'helpers/sessionStorageHelper';
import { queryStringify } from 'utils/router';
import API from 'helpers/api';

const USERS = 'USERS';
interface Action {
	type: string;
	payload: any;
	meta: string | undefined;
}

export function* fetchUsers() {
	try {
		yield put(actions.setLoading(true));
		const authToken = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const users = checkExpiredStorageItem<any>(USERS);
		let response;
		if (!users) {
			const fetchUsersURL = `${apiURL}/users`;
			const request = () => fetch(fetchUsersURL, options);
			response = yield call(request);

			if (response && response.status === 200) {
				const responseData = yield response.json();
				setToSession(responseData, USERS);
				const mappingData: { [key: string]: User } = {};

				responseData.data.forEach((user: User) => {
					const userKey: string = user.userID;
					if (!mappingData.hasOwnProperty(userKey)) {
						mappingData[userKey] = user;
					}
				});

				yield put(actions.fetchUsers.success({ data: mappingData }));
				return mappingData;
			} else {
				const responseData = yield response.json();
				yield put(actions.fetchUsers.failure({ error: responseData.message }));
				return null;
			}
		} else {
			const mappingData: { [key: string]: User } = {};
			const responseData = users;
			responseData.data.forEach((user: User) => {
				const userKey: string = user.userID;
				if (!mappingData.hasOwnProperty(userKey)) {
					mappingData[userKey] = user;
				}
			});

			yield put(actions.fetchUsers.success({ data: mappingData }));
			return mappingData;
		}
	} catch (e) {
		yield put(actions.fetchUsers.success({ data: null }));
		yield put(actions.fetchUsers.failure({ error: 'Failed to Fetch Users' }));
		return null;
	}
}

export function* fetchPaginateUsers(action: Action) {
	try {
		const authToken = yield getToken();
		const getState = (state: RootState) => state;
		const state: RootState = yield select(getState);

		const auditorState = state.auditors;

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};
		let sortDirections = 'asc';
		if (auditorState.sortBy === 'displayName') {
			sortDirections = auditorState.displayName;
		} else if (auditorState.sortBy === 'email') {
			sortDirections = auditorState.email;
		} else if (auditorState.sortBy === 'role') {
			sortDirections = auditorState.role;
		} else if (auditorState.sortBy === 'status') {
			sortDirections = auditorState.status;
		}

		const query: { [key: string]: string | number } = {
			search: auditorState.filterQuery || '',
			page: auditorState.pageIndex,
			sortFields: auditorState.sortBy,
			sortDirections,
			limit: action.payload.limit,
		};

		if (action.payload.withLMSUsers !== undefined) {
			query.withLMSUsers = action.payload.withLMSUsers;
		}

		const queryStr = Object.keys(query).length
			? Object.keys(query)
					.map((key: string) => {
						return `${key}=${query[key]}`;
					})
					.join('&')
			: '';
		let userStatus = '&status=active&status=fresh';
		if (auditorState.selectedTab === 'disabled') {
			userStatus = '&status=disabled';
		}

		const fetchUsersURL = `${apiURL}/users/paginate?${queryStr}${userStatus}`;
		const request = () => fetch(fetchUsersURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			const mappingData: { [key: string]: User } = {};

			responseData.data.docs.forEach((user: User) => {
				const userKey: string = user.userID;
				if (!mappingData.hasOwnProperty(userKey)) {
					mappingData[userKey] = user;
				}
			});
			yield put(actions.fetchPaginateUsers.success({ data: mappingData, total: responseData.data.totalDocs }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchPaginateUsers.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchPaginateUsers.success({ data: null, total: null }));
		yield put(actions.fetchPaginateUsers.failure({ error: 'Failed to Fetch Users' }));
		return null;
	}
}

export function* fetchUserRoles() {
	try {
		yield put(actions.setLoading(true));
		const authToken = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchUsersURL = `${apiURL}/user-roles/all`;
		const request = () => fetch(fetchUsersURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			const userRoles: UserRoles = {};

			responseData.data.forEach((el: any) => {
				userRoles[el.level] = { role: el.role, label: el.label };
			});

			yield put(actions.fetchUserRole.success({ data: userRoles }));
			return userRoles;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchUserRole.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchUserRole.failure({ error: 'Failed to Fetch User Roles' }));
		return null;
	}
}

export function* fetchSiteOwners() {
	try {
		yield put(actions.setLoading(true));
		const token = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: token,
			},
		};

		const fetchUsersURL = `${appengineURL}/sites/site-owner`;
		const request = () => fetch(fetchUsersURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const result = yield response.json();

			yield put(actions.fetchUserRole.success({ data: result.data }));
			return null;
		} else {
			const result = yield response.json();
			yield put(actions.fetchUserRole.failure({ error: result.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchUserRole.failure({ error: 'Failed to Fetch Site Owners' }));
		return null;
	}
}

export function* fetchUserSchedule() {
	try {
		const token = yield getToken();
		const getState = (state: RootState) => state;
		const state: RootState = yield select(getState);
		const auditorState = state.auditors;
		const options = {
			method: 'GET',
			headers: {
				Authorization: token,
			},
		};
		const id = auditorState.selectedUserDeleteKey;
		const url = `${apiURL}/schedules/users/${id}`;
		const request = () => fetch(url, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const result = yield response.json();
			yield put(actions.fetchUserSchedule.success({ data: result.data }));
			return null;
		} else {
			const result = yield response.json();
			yield put(actions.fetchUserSchedule.failure({ error: result.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchUserSchedule.failure({ error: 'Failed to Fetch User Schedule' }));
	}
}

export function* fetchUserConfig() {
	try {
		const token = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: token,
			},
		};

		const url = `${apiURL}/users/config`;
		const request = () => fetch(url, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const result = yield response.json();
			yield put(actions.fetchUserConfig.success({ data: result.data }));
			return null;
		} else {
			const result = yield response.json();
			yield put(actions.fetchUserRole.failure({ error: result.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchUserConfig.failure({ error: 'Failed to Fetch User Configs' }));
	}
}

export function* fetchUsersDataVisibility() {
	try {
		yield put(actions.setLoading(true));
		const authToken = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchUsersURL = `${apiURL}/users?withDataVisibility=false`;
		const request = () => fetch(fetchUsersURL, options);
		let response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			const mappingData: { [key: string]: User } = {};

			responseData.data.forEach((user: User) => {
				const userKey: string = user.userID;
				if (!mappingData.hasOwnProperty(userKey)) {
					mappingData[userKey] = user;
				}
			});

			yield put(actions.fetchUsersDataVisibility.success({ data: mappingData }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchUsersDataVisibility.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchUsersDataVisibility.success({ data: null }));
		yield put(actions.fetchUsersDataVisibility.failure({ error: 'Failed to Fetch Users' }));
		return null;
	}
}

export function* fetchBaseRoleUsers() {
	try {
		yield put(actions.setLoading(true));
		const authToken = yield getToken();
		let data = {
			roles: ['auditor', 'supervisor', 'admin', 'account_holder', 'superadmin'],
			projection: ['displayName', 'userID', 'role'],
		};

		const fetchUsersURL = `${apiURL}/users/baseRole`;

		let response = yield call(API.post, fetchUsersURL, authToken, data);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			const mappingData: { [key: string]: User } = {};

			responseData.data.forEach((user: User) => {
				const userKey: string = user.userID;
				if (!mappingData.hasOwnProperty(userKey)) {
					mappingData[userKey] = user;
				}
			});

			yield put(actions.fetchBaseRoleUsers.success({ data: mappingData }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchBaseRoleUsers.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchBaseRoleUsers.success({ data: null }));
		yield put(actions.fetchBaseRoleUsers.failure({ error: 'Failed to Fetch Users' }));
		return null;
	}
}

export default function* usersSaga() {
	yield takeLatest(types.FETCH_USERS_REQUEST, fetchUsers);
	yield takeLatest(types.FETCH_USER_ROLE_REQUEST, fetchUserRoles);
	yield takeLatest(types.FETCH_USER_OWNER_REQUEST, fetchSiteOwners);
	yield takeLatest(types.FETCH_USER_CONFIG_REQUEST, fetchUserConfig);
	yield takeLatest(types.FETCH_USER_SCHEDULE_REQUEST, fetchUserSchedule);
	yield takeLatest(types.FETCH_PAGINATE_USERS_REQUEST, fetchPaginateUsers);
	yield takeLatest(types.FETCH_USERS_DATA_VISIBILITY_REQUEST, fetchUsersDataVisibility);
	yield takeLatest(types.FETCH_BASE_ROLE_REQUEST, fetchBaseRoleUsers);
}
