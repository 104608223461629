import { action, createAsyncAction } from 'typesafe-actions';
import { LMSUserRequest } from '@nimbly-technologies/nimbly-common';
import * as types from './user.actionTypes';
import { BaseStatus } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });
export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });
export const createUser = createAsyncAction(
	types.CREATE_USER_REQUEST,
	types.CREATE_USER_SUCCESS,
	types.CREATE_USER_FAILURE,
)<{ data: LMSUserRequest }, undefined, { error: string }>();
export const updateUser = createAsyncAction(
	types.UPDATE_USER_REQUEST,
	types.UPDATE_USER_SUCCESS,
	types.UPDATE_USER_FAILURE,
)<
	{ userID: string; data: Partial<LMSUserRequest & { lmsStatus: BaseStatus }> },
	undefined,
	{ error: string; errorData?: string[] | null }
>();
export const deleteUser = createAsyncAction(
	types.DELETE_USER_REQUEST,
	types.DELETE_USER_SUCCESS,
	types.DELETE_USER_FAILURE,
)<{ userID: string }, undefined, { error: string; errorData?: string[] | null }>();
export const setSelectedEditLmsUser = (data: any) => action(types.SELECTED_EDIT_LMS_USER, { data });
export const setSelectedEditLmsUsers = (data: any) => action(types.SELECTED_EDIT_LMS_USERS, { data });
