import {
  FetchAuditorsDailyChartPayload,
  FetchAuditorsDailyChartResponse
} from 'reducers/dashboard/auditors/dailyChart/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyAuditorsDailyChart } from 'utils/dashboardDummy/auditors/dummyAuditorsDailyChart';

export const fetchDailyChart = async (
  payload: FetchAuditorsDailyChartPayload
): Promise<FetchAuditorsDailyChartResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyAuditorsDailyChart = getDummyAuditorsDailyChart(payload);
    return {
      message: 'SUCCESS',
      data: dummyAuditorsDailyChart
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/users/daily?${serializedQueries}`;

    const res = await fetch(url, options);

    if (res.status !== 200) {
      throw new Error('');
    }

    const auditorsListResult: FetchAuditorsDailyChartResponse = await res.json();
    return auditorsListResult;
  } catch {
    const errorResult: FetchAuditorsDailyChartResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
