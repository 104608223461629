import { createAsyncAction } from 'typesafe-actions';
import * as types from './siteGroup.actionTypes';
import { siteGroupI } from './type';
export const siteGroupAction = createAsyncAction(
	types.FETCH_SITE_GROUP_REQUEST,
	types.FETCH_SITE_GROUP_SUCCESS,
	types.FETCH_SITE_GROUP_FAILURE,
)<
	{
		withDisabled?: boolean;
		minimal?: boolean;
	},
	{ data: siteGroupI[]; message: string },
	{ message: string }
>();
