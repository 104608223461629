import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import * as Common from 'nimbly-common';

import { RestructuredResource } from '../typings';

export type Resource = { resource: string; permission: Common.enums.Permission[] };
export type UserPermission = { role: string; resources: Resource[] };
export type UserPermissions = UserPermission[];

/**
 * Edit permissions based on backend data
 */
export const updateResourcePermissions = (data: RestructuredResource, userPermissions: UserPermissions) => {
  let paths: string[][] = [];
  const cloneData = cloneDeep(data);

  userPermissions.forEach((perRole: any, index) => {
    (perRole.resources || []).forEach((el: any) => {
      let path: string[] = [];
      path = el.resource.split(':');
      path.push('permissions');
      el.permission.forEach((permission: Common.enums.Permission) => {
        paths.push([...path, permission, perRole.role]);
      });
    });
  });

  /**
   * change permission based on role
   */
  paths.forEach((path: any) => {
    const checkWithTemplate = get(cloneData, path.join('.'));
    if (checkWithTemplate !== undefined) {
      set(cloneData, path.join('.'), true);
    }
  });

  return cloneData;
};
