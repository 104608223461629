import { combineReducers } from 'redux';

import siteLowStock from './siteLowStock/reducer';
import trends from './trends/reducer';
import { overviewReducer as overview } from './overview/reducer';
import { overviewCardReducer as overviewCard } from './overview/overviewCardReducer';
import { sitesReducer as sites } from './sites/reducer';
import { issuesReducer as issues } from './issues/reducer';
import { auditorsReducer as auditors } from './auditors/reducer';

export default combineReducers({
  siteLowStock,
  trends,
  overview,
  overviewCard,
  sites,
  issues,
  auditors
});
