/**
 * Comparator function to compare if the current
 * list of members matched with the new list of
 * members
 *
 **/
export const compareMembersEquality = (a: { [uid: string]: string }, b: { [uid: string]: string }) => {
  var aProps: string[] = Object.getOwnPropertyNames(a);
  var bProps: string[] = Object.getOwnPropertyNames(b);

  /** If length does not match, then the object is already different */
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    /** If values of same property are not equal, objects are not equivalent */
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  /** Else, the objects are equal */
  return true;
};
