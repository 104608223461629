import {
	Course,
	CoursePaginateResponse,
	CourseStatus,
	FindSKUPerCategory,
	SKUCompact,
} from '@nimbly-technologies/nimbly-common';
import { LMSAccessType } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export enum CourseFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

interface CourseFilters {
	userIDs: string[];
	startDate: string;
	endDate: string;
	expiryStartDate: string;
	expiryEndDate: string;
	status: CourseStatus[];
	accessType?: LMSAccessType;
}

export enum LmsCourseSortField {
	NAME = 'name',
	PUBLISHED_DATE = 'publishedDate',
	CREATED_BY = 'createdBy',
	PARTICIPANTS = 'participants',
	EXPIRATION_DATE = 'expirationDate',
	STATUS = 'status',
}

export type CoursesSortFields = 'name' | 'publishedDate' | 'createdBy' | 'participants' | 'expirationDate' | 'status';

export interface CourseBulkErrorResponse {
	courseID: string;
	reason: string;
}

export interface CourseState {
	error: any;
	bulkError: CourseBulkErrorResponse[];
	fetchState: CourseFetchState;
	courses: CoursePaginateResponse[];

	totalPages: number;
	totalDocs: number;
	page: number;
	limit: number;

	sortField: CoursesSortFields;
	sortDirection: 'asc' | 'desc';
	search: string;
	filters: CourseFilters;
}
