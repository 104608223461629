import { put } from 'typed-redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import * as actions from './adminDashboard.action';
import {
	fetchTotalSites,
	fetchTotalUsers,
	fetchTotalLoginDevices,
	fetchAvgLoginInfo,
	fetchUsersCvU,
	fetchSitesCvU,
	fetchAvgVTotalReports,
	fetchMAU,
	fetchStorageInfo,
} from 'services/adminDashboard/adminDashboard.service';
import {
	TotalSitesInfo,
	TotalUsersInfo,
	TotalLoginDevicesInfo,
	AvgLoginInfo,
	UsersCvUInfo,
	SitesCvUInfo,
	AvgVTotalReportsInfo,
	MAUInfo,
	StorageInfo,
} from './adminDashboardStore';

function* fetchTotalActiveSitesSaga(action: ReturnType<typeof actions.fetchTotalActiveSitesAsync.request>): Generator {
	try {
		const res = (yield call(fetchTotalSites) as unknown) as TotalSitesInfo;
		yield put(actions.fetchTotalActiveSitesAsync.success(res));
	} catch {
		yield put(actions.fetchTotalActiveSitesAsync.failure('Failed to get total active sites'));
	}
}

function* fetchTotalActiveUsersSaga(action: ReturnType<typeof actions.fetchTotalActiveUsersAsync.request>): Generator {
	try {
		const res = (yield call(fetchTotalUsers) as unknown) as TotalUsersInfo;
		yield put(actions.fetchTotalActiveUsersAsync.success(res));
	} catch {
		yield put(actions.fetchTotalActiveUsersAsync.failure('Failed to get total active users'));
	}
}

function* fetchTotalLoginDevicesAsync(
	action: ReturnType<typeof actions.fetchTotalLoginDevicesAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchTotalLoginDevices) as unknown) as TotalLoginDevicesInfo;
		yield put(actions.fetchTotalLoginDevicesAsync.success(res));
	} catch {
		yield put(actions.fetchTotalLoginDevicesAsync.failure('Failed to get total login devices'));
	}
}

function* fetchAvgLoginInfoAsync(action: ReturnType<typeof actions.fetchAvgLoginInfoAsync.request>): Generator {
	try {
		const res = (yield call(fetchAvgLoginInfo) as unknown) as AvgLoginInfo;
		yield put(actions.fetchAvgLoginInfoAsync.success(res));
	} catch {
		yield put(actions.fetchAvgLoginInfoAsync.failure('Failed to get avg login info'));
	}
}

function* fetchUsersCvUInfoSaga(action: ReturnType<typeof actions.fetchUsersCvUAsync.request>): Generator {
	try {
		const res = (yield call(fetchUsersCvU) as unknown) as UsersCvUInfo[] | string;
		yield put(actions.fetchUsersCvUAsync.success(res));
	} catch {
		yield put(actions.fetchUsersCvUAsync.failure('Failed to get users '));
	}
}

function* fetchSitesCvUInfoSaga(action: ReturnType<typeof actions.fetchSitesCvUAsync.request>): Generator {
	try {
		const res = (yield call(fetchSitesCvU) as unknown) as SitesCvUInfo[] | string;
		yield put(actions.fetchSitesCvUAsync.success(res));
	} catch {
		yield put(actions.fetchSitesCvUAsync.failure('Failed to get sites '));
	}
}

function* fetchAvgVTotalReportsInfoSaga(
	action: ReturnType<typeof actions.fetchAvgVTotalReportsAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchAvgVTotalReports) as unknown) as AvgVTotalReportsInfo[] | string;
		yield put(actions.fetchAvgVTotalReportsAsync.success(res));
	} catch {
		yield put(actions.fetchAvgVTotalReportsAsync.failure('Failed to get users '));
	}
}

function* fetchMAUInfoSaga(action: ReturnType<typeof actions.fetchMAUAsync.request>): Generator {
	try {
		const res = (yield call(fetchMAU) as unknown) as MAUInfo | string;
		yield put(actions.fetchMAUAsync.success(res));
	} catch {
		yield put(actions.fetchMAUAsync.failure('Failed to get MAU '));
	}
}

function* fetchStorageInfoSaga(action: ReturnType<typeof actions.fetchStorageAsync.request>): Generator {
	try {
		const res = (yield call(fetchStorageInfo) as unknown) as StorageInfo;
		yield put(actions.fetchStorageAsync.success(res));
	} catch {
		yield put(actions.fetchStorageAsync.failure('Failed to get storage details '));
	}
}

export default function* adminDashboardSaga() {
	yield takeEvery(actions.fetchTotalActiveSitesAsync.request, fetchTotalActiveSitesSaga);
	yield takeEvery(actions.fetchTotalActiveUsersAsync.request, fetchTotalActiveUsersSaga);
	yield takeEvery(actions.fetchTotalLoginDevicesAsync.request, fetchTotalLoginDevicesAsync);
	yield takeEvery(actions.fetchAvgLoginInfoAsync.request, fetchAvgLoginInfoAsync);
	yield takeEvery(actions.fetchUsersCvUAsync.request, fetchUsersCvUInfoSaga);
	yield takeEvery(actions.fetchSitesCvUAsync.request, fetchSitesCvUInfoSaga);
	yield takeEvery(actions.fetchAvgVTotalReportsAsync.request, fetchAvgVTotalReportsInfoSaga);
	yield takeEvery(actions.fetchMAUAsync.request, fetchMAUInfoSaga);
	yield takeEvery(actions.fetchStorageAsync.request, fetchStorageInfoSaga);
}
