import { action } from 'typesafe-actions';
import * as types from './userEnrollment.actionTypes';

export const setEnrollmentStatus = (value?: string | null) => {
	const localStorageValue = localStorage.getItem('enrollmentStatus');
	if (!localStorageValue && value) {
		localStorage.setItem('enrollmentStatus', value);
		return action(types.SET_ENROLLMENT_STATUS, { value });
	} else if (localStorageValue && !value) {
		return action(types.SET_ENROLLMENT_STATUS, { value: localStorageValue });
	} else {
		return action(types.SET_ENROLLMENT_STATUS, { value: '' });
	}
};
export const setAuthenticatingStatus = (value: boolean) => action(types.SET_AUTHENTICATING_STATUS, { value });
