import { takeLatest, put, call, select } from 'redux-saga/effects';

import { apiURL } from 'config/baseURL';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/tutorial/tutorial.action';
import * as types from 'reducers/tutorial/tutorial.actionTypes';
import { RootState } from 'store/rootReducers';
import { TutorialState } from 'reducers/tutorial/type';

export function* fetchTutorial() {
  try {
    const firebaseToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: firebaseToken
      }
    };

    const fetchTutorialUrl = `${apiURL}/organizations/onboardings`;
    const request = () => fetch(fetchTutorialUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      const { data } = responseData;
      if (data.isOrganizationOnboarding) {
        const { organization } = data;
        yield put(
          actions.fetchTutorialAsync.success({
            currentProgress: organization.currentProgress,
            cumulativeProgress: organization.cumulativeProgress,
            progress: organization.progress,
            isOrganizationOnboarding: true,
            isFinished: organization.isFinished
          })
        );
      } else {
        const { user } = data;
        yield put(
          actions.fetchTutorialAsync.success({
            progress: user.progress,
            isOrganizationOnboarding: false
          })
        );
      }

      return responseData.data;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchTutorialAsync.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchTutorialAsync.failure({ error: 'Failed to Fetch Tutorial' }));
    return null;
  }
}

export function* updateTutorial() {
  try {
    const firebaseToken = yield getToken();
    const getState = (state: RootState) => state;
    const state: RootState = yield select(getState);
    let newTutorial = {};
    const tutorialState: TutorialState = state.tutorial;
    if (tutorialState.isOrganizationOnboarding) {
      newTutorial = {
        user: { progress: tutorialState.progress },
        organization: {
          progress: tutorialState.progress,
          currentProgress: tutorialState.currentProgress,
          cumulativeProgress: tutorialState.cumulativeProgress,
          isFinished: tutorialState.isFinished
        }
      };
    } else {
      newTutorial = {
        user: { progress: tutorialState.progress }
      };
    }

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: firebaseToken
      },
      body: JSON.stringify(newTutorial)
    };

    const fetchTutorialUrl = `${apiURL}/organizations/onboardings`;
    const request = () => fetch(fetchTutorialUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.updateTutorialAsync.success(responseData.data));
      return responseData.data;
    } else {
      const responseData = yield response.json();
      yield put(actions.updateTutorialAsync.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.updateTutorialAsync.failure({ error: 'Failed to update Tutorial' }));
    return null;
  }
}
export default function* tutorialSaga() {
  yield takeLatest(types.FETCH_TUTORIAL_REQUEST, fetchTutorial);
  yield takeLatest(types.UPDATE_TUTORIAL_REQUEST, updateTutorial);
}
