import cloneDeep from 'lodash/cloneDeep';

import { RestructuredResource, RoleLabel } from '../typings';

export const revertData = (resource: RestructuredResource, userRoles: RoleLabel[]) => {
  const clonedResource: any = cloneDeep(resource);
  const revertedData: any = {};

  Object.keys(clonedResource).forEach((tab: string) => {
    Object.keys(clonedResource[tab]).forEach((feature: string) => {
      Object.keys(clonedResource[tab][feature]).forEach((access: string) => {
        Object.keys(clonedResource[tab][feature][access]).forEach(item => {
          if (item === 'permissions') {
            Object.keys(clonedResource[tab][feature][access][item]).forEach((permission: string) => {
              Object.keys(clonedResource[tab][feature][access][item][permission]).forEach(role => {
                if (!revertedData[role]) {
                  revertedData[role] = {};
                  revertedData[role] = { resources: {} };
                }

                const resource = [tab, feature, access].join(':');

                if (!revertedData[role].resources[resource]) {
                  revertedData[role].resources[resource] = {};
                }
                revertedData[role].resources[resource][permission] =
                  clonedResource[tab][feature][access][item][permission][role];
              });
            });
          }
        });
      });
    });
  });

  const revertedArr: any = [];

  Object.keys(revertedData).forEach(role => {
    let tempData: any = { role: role, resources: [], label: '' };
    let userRole = userRoles.find(userRole => userRole.value === role);

    if (userRole) {
      Object.assign(tempData, { label: userRole.label });
    }

    Object.keys(revertedData[role].resources).forEach(resource => {
      let tempResource: any = { resource: null, permission: [] };
      let tempArrPermissions: any = [];
      Object.keys(revertedData[role].resources[resource]).forEach(permission => {
        if (
          permission &&
          revertedData[role].resources[resource] &&
          revertedData[role].resources[resource][permission]
        ) {
          tempArrPermissions.push(permission);
        }
      });
      tempResource.resource = resource;
      tempResource.permission = tempArrPermissions;
      tempData.resources.push(tempResource);
    });
    revertedArr.push(tempData);
  });

  return revertedArr;
};
