import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './company.action';
import * as types from './company.actionTypes';
import { CompanyState } from './type';

export type CompanyActions = ActionType<typeof actions>;

const default_sortDirections: CompanyState['sortDirections'] = {
  name: 'asc',
  numOfProducts: 'asc',
  numOfPromotions: 'asc'
};
const initialState: CompanyState = {
  companyList: [],
  company: null,
  isLoading: false,
  isLoadingUpdate: false,
  error: null,
  sortDirections: default_sortDirections,
  sortBy: 'name',
  updatedName: '',
  companyProductList: {
    isLoading: false,
    data: {
      companyName: '',
      products: []
    },
    error: ''
  },
  companyPromotionList: {
    isLoading: false,
    data: {
      companyName: '',
      promotions: []
    },
    error: ''
  }
};

export const companyReducer = createReducer<CompanyState, CompanyActions>(initialState)
  .handleAction(actions.fetchCompany.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchCompany.success, (state, action) => ({
    ...state,
    companyList: action.payload,
    isLoading: false
  }))
  .handleAction(actions.fetchCompany.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.createCompany.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.createCompany.success, (state, action) => ({
    ...state,
    updatedName: action.payload,
    isLoading: false
  }))
  .handleAction(actions.createCompany.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.fetchCompanyDetail.request, state => {
    return {
      ...state,
      company: null,
      error: null,
      isLoading: true
    };
  })
  .handleAction(actions.fetchCompanyDetail.success, (state, action) => ({
    ...state,
    company: action.payload,
    isLoading: false
  }))
  .handleAction(actions.fetchCompanyDetail.failure, (state, action) => {
    return {
      ...state,
      company: null,
      error: action.payload.error,
      isLoading: false
    };
  })
  .handleAction(actions.createCompanyBulk.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.createCompanyBulk.success, state => ({
    ...state,
    isLoading: false,
    error: ''
  }))
  .handleAction(actions.createCompanyBulk.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  }))
  .handleAction(actions.updateCompany.request, state => ({
    ...state,
    error: null,
    isLoadingUpdate: true
  }))
  .handleAction(actions.updateCompany.success, (state, action) => ({
    ...state,
    updatedName: action.payload,
    isLoadingUpdate: false
  }))
  .handleAction(actions.updateCompany.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoadingUpdate: false
  }))
  .handleAction(actions.fetchCompanyProducts.request, (state, action) => ({
    ...state,
    companyProductList: {
      ...state.companyProductList,
      isLoading: true,
      data: {
        companyName: action.payload.companyName,
        products: []
      },
      error: ''
    }
  }))
  .handleAction(actions.fetchCompanyProducts.success, (state, action) => ({
    ...state,
    companyProductList: {
      ...state.companyProductList,
      isLoading: false,
      data: {
        ...state.companyProductList.data,
        products: action.payload
      }
    }
    // isLoading: false
  }))
  .handleAction(actions.fetchCompanyProducts.failure, (state, action) => ({
    ...state,
    companyProductList: {
      ...state.companyProductList,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(actions.fetchCompanyPromotions.request as any, (state, action) => ({
    ...state,
    companyPromotionList: {
      ...state.companyPromotionList,
      isLoading: true,
      data: {
        companyName: action.payload.companyName,
        promotions: []
      },
      error: ''
    }
  }))
  .handleAction(actions.fetchCompanyPromotions.success as any, (state, action) => ({
    ...state,
    companyPromotionList: {
      ...state.companyPromotionList,
      isLoading: false,
      data: {
        ...state.companyPromotionList.data,
        promotions: action.payload
      }
    }
    // isLoading: false
  }))
  .handleAction(actions.fetchCompanyPromotions.failure as any, (state, action) => ({
    ...state,
    companyPromotionList: {
      ...state.companyPromotionList,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(types.SET_LOADING as any, (state, action) => {
    return { ...state, isLoading: action.payload.value };
  })
  .handleAction(types.SET_SORT as any, (state, action) => {
    if (state.sortBy !== action.payload.sortType) {
      return {
        ...state,
        sortBy: action.payload.sortType,
        sortDirections: default_sortDirections
      };
    }
    return {
      ...state,
      sortDirections: {
        ...state.sortDirections,
        [action.payload.sortType]: action.payload.sortOrder
      }
    };
  });

export default companyReducer;
