import { FunctionReturn } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/lms/userManagement/createUser/user.action';
import * as types from 'reducers/lms/userManagement/createUser/user.actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';

function* createUser(action: ReturnType<typeof actions.createUser.request>) {
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const body = action.payload.data;

		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const createUserURL = `${apiURL}/lms/users`;
		const request = () => fetch(createUserURL, options);
		// @ts-ignore
		const response = yield call(request);

		if (response && response.status === 200) {
			yield put(actions.createUser.success());
			toast.success(i18n.t('message.lms.userManagement.user.create.success'));
		} else {
			// @ts-ignore
			const CreateUserResponseData = yield response.json();
			const message = CreateUserResponseData.data;
			toast.error(message);
			if (message === 'No access to user' || message === 'Failed to Fetch Create User API') {
				//
			}
			yield put(actions.createUser.failure({ error: CreateUserResponseData.data }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.user.create.failed'));
		yield put(actions.createUser.failure({ error: 'Failed to Create User' }));
	}
}

function* updateUser(action: ReturnType<typeof actions.updateUser.request>) {
	try {
		const userID = action.payload.userID;
		const body = action.payload.data;
		// @ts-ignore
		const authToken = yield getToken();
		const options = {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const updateUser = `${apiURL}/lms/users/${userID}`;
		const request = () => fetch(updateUser, options);
		const response: Response = yield (call(request) as unknown) as Response;

		if (response && response.status === 200) {
			yield put(actions.updateUser.success());
			toast.success(i18n.t('message.lms.userManagement.user.update.success'));
		} else if (response && response.status === 409) {
			yield put(actions.updateUser.failure({ error: i18n.t('message.lms.userManagement.user.update.userExist') }));
			toast.error(i18n.t('message.lms.userManagement.user.update.userExist'));
		} else {
			const UpdateUserResponseData: FunctionReturn<Array<any> | string> = yield response.json();
			if (UpdateUserResponseData.data && Array.isArray(UpdateUserResponseData.data)) {
				yield put(actions.setShowModal(true));
				yield put(
					actions.updateUser.failure({ error: UpdateUserResponseData.message, errorData: UpdateUserResponseData.data }),
				);
				return;
			}

			const errorMessage = `${UpdateUserResponseData.message}: ${i18n.t(
				'message.lms.userManagement.user.update.failed',
			)} ${UpdateUserResponseData.data}`;
			yield put(actions.updateUser.failure({ error: errorMessage }));
			toast.error(errorMessage);
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.user.update.failed'));
		yield put(actions.updateUser.failure({ error: 'Failed to update User' }));
	}
}

function* deleteUser(action: ReturnType<typeof actions.deleteUser.request>) {
	try {
		const userID = action.payload.userID;
		// @ts-ignore
		const authToken = yield getToken();
		const options = {
			method: 'DELETE',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const updateUser = `${apiURL}/lms/users/${userID}`;
		const request = () => fetch(updateUser, options);
		// @ts-ignore
		const response = yield call(request);
		if (response && response.status === 200) {
			yield put(actions.updateUser.success());
			toast.success(i18n.t('message.lms.userManagement.user.delete.success'));
		} else if (response && response.status === 409) {
			yield put(actions.updateUser.failure({ error: i18n.t('message.lms.userManagement.user.delete.userExist') }));
			toast.error(i18n.t('message.lms.userManagement.user.delete.userExist'));
		} else {
			// @ts-ignore
			const DeleteUserResponseData = yield response.json();
			if (DeleteUserResponseData.data && Array.isArray(DeleteUserResponseData.data)) {
				yield put(actions.setShowModal(true));
				yield put(
					actions.updateUser.failure({ error: DeleteUserResponseData.message, errorData: DeleteUserResponseData.data }),
				);
				return;
			}
			toast.error(DeleteUserResponseData.data);
			yield put(actions.updateUser.failure({ error: DeleteUserResponseData.message }));
		}
	} catch (e) {
		toast.error(i18n.t('message.lms.userManagement.user.delete.failed'));
		yield put(actions.updateUser.failure({ error: 'Failed to delete User' }));
	}
}

export default function* createUserSaga() {
	yield takeLatest(types.UPDATE_USER_REQUEST, updateUser);
	yield takeLatest(types.CREATE_USER_REQUEST, createUser);
	yield takeLatest(types.DELETE_USER_REQUEST, deleteUser);
}
