import { ActionType, createReducer, Reducer } from 'typesafe-actions';
import update from 'immutability-helper';
import * as actions from './quizManagement.action';
import * as types from './quizManagement.actionTypes';
import { quizManagementState } from './type.d';
import { LmsDataType, LmsViewType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';

export type QuizAction = ActionType<typeof actions>;

export const initialState: quizManagementState = {
	dataType: LmsDataType.QUIZ,
	viewType: LmsViewType.TILE,
	isSelectMode: false,
	selectedQuizzes: [],
	selectedQuizRows: [],

	confirmationModalConfig: {
		isVisible: false,
		title: '',
		content: '',
		confirmButtonText: '',
		cancelButtonText: '',
		onCancel: () => {},
		onConfirm: () => {},
	},
	isConfirmationModalOpen: false,
	isBulkEditModalOpen: false,
	isBulkEditErrorModalOpen: false,
};

export const quizManagementReducer: Reducer<quizManagementState, QuizAction> = createReducer<
	quizManagementState,
	QuizAction
>(initialState)
	.handleType(types.SET_VIEW_TYPE, (state, action) =>
		update(state, {
			viewType: { $set: action.payload.viewType },
		}),
	)
	.handleType(types.SET_SELECTED_QUIZZES, (state, action) =>
		update(state, {
			selectedQuizzes: { $set: action.payload.selectedQuizzes },
		}),
	)
	.handleType(types.SET_SELECTED_QUIZ_ROWS, (state, action) =>
		update(state, {
			selectedQuizRows: { $set: action.payload.selectedQuizRows },
		}),
	)
	.handleType(types.SET_CONFIRMATION_MODAL_CONFIG, (state, action) =>
		update(state, {
			confirmationModalConfig: {
				$set: {
					...state.confirmationModalConfig,
					...action.payload.modalConfig,
				},
			},
		}),
	)
	.handleType(types.TOGGLE_BULK_EDIT_MODAL, (state, action) =>
		update(state, {
			isBulkEditModalOpen: { $set: action.payload.isOpen },
		}),
	)
	.handleType(types.TOGGLE_BULK_EDIT_ERROR_MODAL, (state, action) =>
		update(state, {
			isBulkEditErrorModalOpen: { $set: action.payload.isOpen },
		}),
	)
	.handleType(types.TOGGLE_CONFIRMATION_MODAL, (state, action) =>
		update(state, {
			isConfirmationModalOpen: { $set: action.payload.isOpen },
		}),
	)
	.handleType(types.TOGGLE_IS_SELECT_MODE, (state, action) =>
		update(state, {
			isSelectMode: { $set: action.payload.isSelectMode },
			selectedQuizzes: { $set: initialState.selectedQuizzes },
			selectedQuizRows: { $set: initialState.selectedQuizRows },
		}),
	)
	.handleType(types.RESET_PAGE_STATE, (state) =>
		update(state, {
			dataType: { $set: initialState.dataType },
			viewType: { $set: initialState.viewType },
			isBulkEditModalOpen: { $set: initialState.isBulkEditModalOpen },
			isConfirmationModalOpen: { $set: initialState.isConfirmationModalOpen },
		}),
	);
