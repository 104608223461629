import { CreateAuditorRequest, UpdateAuditorRequest } from 'nimbly-common';
import { createAsyncAction, createAction } from 'typesafe-actions';

import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_USER_STATE,
  UpsertedSiteID
} from './types';

export const createUserAsync = createAsyncAction(CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE)<
  CreateAuditorRequest,
  UpsertedSiteID,
  string
>();

export const updateUserAsync = createAsyncAction(UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE)<
  UpdateAuditorRequest,
  UpsertedSiteID,
  string
>();

export const clearUserState = createAction(CLEAR_USER_STATE)<undefined>();
