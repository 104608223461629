/**
 *
 *      Send an email, if correct, send password reset link
 *      to targeted email address
 *
 *      @param email user email address
 *      @param firebase firebase object retrieved from props.firebase
 *
 */
async function authenticatePasswordReset(email: string, firebase: any) {
  if (!email) {
    return {
      status: 'field_not_complete',
      errorMsg: 'One or more fields are missing.',
      errorField: null
    };
  } else {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return {
          status: 'ok',
          errorMsg: null,
          errorField: null
        };
      })
      .catch((error: any) => {
        if (error.code === 'auth/user-not-found') {
          return {
            status: 'user_not_found',
            errorMsg: 'Email address was not found. Please try again.',
            errorField: 'forgotPass'
          };
        } else if (error.code === 'auth/invalid-email') {
          return {
            status: 'user_not_found',
            errorMsg: 'Email address is badly formatted. Please try again.',
            errorField: 'forgotPass'
          };
        } else {
          return {
            status: 'unknown_error',
            errorMsg: 'Unknown error',
            errorField: null
          };
        }
      });
  }
}

export default authenticatePasswordReset;
