import { Single_SKUList } from '@nimbly-technologies/nimbly-common';
import { SelectedFilters } from 'components/skuManagement/components/SkuFilter';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './skus.actionTypes';
import { SortDirection, ALL_SKU_LIST } from './type';

export const setSkuFilters = (filters: SelectedFilters) => action(types.SET_SKU_FILTER_QUERY, { filters });
export const setSkuPageIndex = (pageIndex: number) => action(types.SET_SKU_PAGE_INDEX, { pageIndex });
export const setSkuSearchQuery = (searchQuery: string) => action(types.SET_SKU_SEARCH_QUERY, { searchQuery });
export const setSkuSort = (sortBy: string, sortDirection: SortDirection) =>
	action(types.SET_SKU_SORT, { sortBy, sortDirection });

export const fetchSkusAync = createAsyncAction(
	types.FETCH_SKUS_REQUEST,
	types.FETCH_SKUS_SUCCESS,
	types.FETCH_SKUS_FAILED,
)<undefined, { skus: Single_SKUList[] | []; totalSkus: number; lastUpdatedAt: number }, { error: string }>();

export const fetchAllSkusAsync = createAsyncAction(
	types.FETCH_ALL_SKUS_REQUEST,
	types.FETCH_ALL_SKUS_SUCCESS,
	types.FETCH_ALL_SKUS_FAILED,
)<undefined, { skusAll: ALL_SKU_LIST[] | [] }, { error: string }>();
