import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './formulas.actionTypes';

export const setPage = (index: number) => action(types.SET_PAGE, { index });

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const fetchFormulaAsync = createAsyncAction(
	types.FETCH_PAGINATE_FORMULAS_REQUEST,
	types.FETCH_PAGINATE_FORMULAS_SUCCESS,
	types.FETCH_PAGINATE_FORMULAS_FAILURE,
)<
	{ sortFields: string; sortDirections: string; page: number; limit?: number },
	{ paginatedFormulas: []; totalItem: number | 0 },
	{ error: string }
>();
