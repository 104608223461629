export const SET_PAGE_INDEX_REPORT = '@reports/SET_PAGE_INDEX_REPORT';
export const SET_REPORTS = '@reports/SET_REPORTS';
export const SET_FILTERED_REPORTS = '@reports/SET_FILTERED_REPORTS';
export const SET_IS_LOADING = '@reports/SET_IS_LOADING';
export const SET_IS_FETCHING = '@reports/SET_IS_FETCHING';
export const SET_SORT_TYPE = '@reports/SET_SORT_TYPE';
export const SET_COLOR_CHANGE = '@reports/SET_COLOR_CHANGE';
export const SET_REPORT_USER_FILTERS = '@reports/SET_REPORT_USER_FILTERS';
export const SET_REPORT_SITES_FILTERS = '@reports/SET_REPORT_SITES_FILTERS';
export const SET_REPORT_QUESTIONNAIRE_FILTERS = '@reports/SET_REPORT_QUESTIONNAIRE_FILTERS';
export const SET_REPORT_TYPE_FILTER = '@reports/SET_REPORT_TYPE_FILTER';
export const SET_ISSUE_IDS = '@reports/SET_ISSUE_IDS';
export const CLEAR_ISSUE_IDS = '@reports/CLEAR_ISSUE_IDS';

export const SET_SELECT_SCHEDULE_KEY = '@reports/SET_SELECT_SCHEDULE_KEY';
export const SET_SELECT_SKU_KEY = '@reports/SET_SELECT_SKU_KEY';

export const SET_PERIOD_TYPE = '@reports/SET_PERIOD_TYPE';
export const SET_CUSTOM_DATES = '@reports/SET_CUSTOM_DATES';
export const SET_DATES = '@reports/SET_DATES';
export const SET_PREVIOUS_DATES = '@reports/SET_PREVIOUS_DATES';
export const RESET_REPORTS_FILTER_DATES = '@reports/RESET_REPORTS_FILTER_DATES';

export const FETCH_REPORTS_REQUEST = '@reports/FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = '@reports/FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = '@reports/FETCH_REPORTS_FAILURE';
