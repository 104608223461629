import {
	CoursePaginateResponse,
	PaginationResult,
	BulkUpdateCourseRequest,
	Course,
} from '@nimbly-technologies/nimbly-common';
import { apiURL } from '../../../config/baseURL';
import API from '../../../helpers/api';

export const fetchCourses = async (searchParams: string): Promise<PaginationResult<CoursePaginateResponse>> => {
	const url = `${apiURL}/lms/courses?${searchParams}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};

export const editCourse = async (courseID: string, updatedData: Partial<Course>): Promise<any> => {
	const url = `${apiURL}/lms/courses/${courseID}`;
	const token = await API.getFirebaseToken();
	const response = await API.put(url, token, updatedData);
	const json = await response.json();

	if (response.status === 200) {
		return json.data;
	} else {
		throw json.data;
	}
};

export const editCourses = async (updatedData: BulkUpdateCourseRequest[]): Promise<any> => {
	const url = `${apiURL}/lms/courses/bulk-edit`;
	const token = await API.getFirebaseToken();
	const response = await API.post(url, token, updatedData);
	const json = await response.json();

	if (response.status === 200) {
		return json.data;
	} else {
		throw json.data;
	}
};
