import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './basicDashboard.action';
import { BasicDashboardReducerState } from './basicDashboardStore';
export type BasicDashboardAction = ActionType<typeof actions>;

export const initialState: BasicDashboardReducerState = {
	isSiteSummaryLoading: false,
	isAssigneeSummaryLoading: false,
	isDepartmentSummaryLoading: false,
};

export const basicDashboardReducer = createReducer<BasicDashboardReducerState, BasicDashboardAction>(initialState)
	.handleAction(actions.setSiteSummaryIsLoading, (state, action) => ({
		...state,
		isSiteSummaryLoading: action.payload.isLoading,
	}))
	.handleAction(actions.setAssigneeSummaryIsLoading, (state, action) => ({
		...state,
		isAssigneeSummaryLoading: action.payload.isLoading,
	}))
	.handleAction(actions.setDeptartmentSummaryIsLoading, (state, action) => ({
		...state,
		isDepartmentSummaryLoading: action.payload.isLoading,
	}));
