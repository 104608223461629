import { FetchLiveOrganizationResponse } from 'reducers/dashboard/overview/liveOrganization/types';
import { apiURL } from '../../../../config/baseURL';
import API from 'helpers/api';

type FetchLiveOrganizationData = {
  message: 'SUCCESS';
  data: {
    _id: string;
    organizationIDs: string[];
  };
};

export const fetchLiveOrganization = async (): Promise<FetchLiveOrganizationResponse> => {
  try {
    const token = await API.getFirebaseToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const url = `${apiURL}/users/liveOrganization`;

    const res = await fetch(url, options);

    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const liveOrganizationResponse: FetchLiveOrganizationData = await res.json();
    return {
      message: 'SUCCESS',
      data: liveOrganizationResponse.data.organizationIDs
    };
  } catch {
    const errorResult: FetchLiveOrganizationResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
