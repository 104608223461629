import {
  IssuesOverviewCards,
  FetchIssuesOverviewCardsPayload,
  FetchIssuesOverviewCardsResponse
} from 'reducers/dashboard/overview/issuesOverviewCards/types';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from '../../../../config/baseURL';
import { getDummyIssuesOverviewStat } from 'utils/dashboardDummy/issues/dummyIssuesOverviewStat';
import API from 'helpers/api';

type IssuesOverviewCardsData = {
  message?: 'Invalid date Query';
  issuesData: IssuesOverviewCards;
};

export const fetchIssuesOverviewCards = async (
  payload: FetchIssuesOverviewCardsPayload
): Promise<FetchIssuesOverviewCardsResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyIssuesOverviewCards = getDummyIssuesOverviewStat(payload);
    return {
      message: 'SUCCESS',
      data: dummyIssuesOverviewCards
    };
  }

  try {
    const token = await API.getFirebaseToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/issues?${serializedQueries}`;

    const res = await fetch(url, options);

    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const issuesOverviewCardsResult: IssuesOverviewCardsData = await res.json();
    return {
      message: 'SUCCESS',
      data: issuesOverviewCardsResult.issuesData
    };
  } catch {
    const errorResult: FetchIssuesOverviewCardsResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
