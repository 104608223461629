import { createAsyncAction } from 'typesafe-actions';
import {
  SingleUserChart,
  FETCH_SINGLE_USER_CHART_REQUEST,
  FETCH_SINGLE_USER_CHART_SUCCESS,
  FETCH_SINGLE_USER_CHART_FAILURE,
  FetchSingleUserChartPayload
} from './types';

export const fetchSingleUserChartAsync = createAsyncAction(
  FETCH_SINGLE_USER_CHART_REQUEST,
  FETCH_SINGLE_USER_CHART_SUCCESS,
  FETCH_SINGLE_USER_CHART_FAILURE
)<FetchSingleUserChartPayload, SingleUserChart, string>();
