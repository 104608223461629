import { call, takeEvery } from 'redux-saga/effects';

import { put } from 'typed-redux-saga';
import * as actions from './executive.action';
import {
	fetchDepartmentRatio,
	fetchIrrRatio,
	fetchRcrRatio,
	fetchVocRatio,
} from 'services/dashboardRevamp/executiveDashboard/sidebar.service';

import { HeatmapIssueFlagsI, IrrRatioI, RcrRatioI, VocRationI } from './executive.types';
import { fetchHeatmapIssueFlags } from 'services/dashboardRevamp/executiveDashboard/header.service';

function* fetchDepartmentRatioSaga(action: ReturnType<typeof actions.fetchDepartmentRatioAsync.request>): Generator {
	try {
		const res = (yield call(fetchDepartmentRatio) as unknown) as string;
		yield put(actions.fetchDepartmentRatioAsync.success(res));
	} catch {
		yield put(actions.fetchDepartmentRatioAsync.failure('Failed to get Report details'));
	}
}

function* fetchIrrRatioSaga(action: ReturnType<typeof actions.fetchIrrRatioAsync.request>): Generator {
	try {
		const res = (yield call(fetchIrrRatio) as unknown) as IrrRatioI;
		yield put(actions.fetchIrrRatioAsync.success(res));
	} catch {
		yield put(actions.fetchIrrRatioAsync.failure('Failed to get Report details'));
	}
}

function* fetchRcrRatioSaga(action: ReturnType<typeof actions.fetchRcrRatioAsync.request>): Generator {
	try {
		const res = (yield call(fetchRcrRatio) as unknown) as RcrRatioI;
		yield put(actions.fetchRcrRatioAsync.success(res));
	} catch {
		yield put(actions.fetchRcrRatioAsync.failure('Failed to get Report details'));
	}
}

function* fetchVocRatioSaga(action: ReturnType<typeof actions.fetchVocRatioAsync.request>): Generator {
	try {
		const res = (yield call(fetchVocRatio) as unknown) as VocRationI;
		yield put(actions.fetchVocRatioAsync.success(res));
	} catch {
		yield put(actions.fetchVocRatioAsync.failure('Failed to get Report details'));
	}
}

function* fetchHeatmapIssueFlagsSaga(
	action: ReturnType<typeof actions.fetchHeatmapIssueFlagsAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchHeatmapIssueFlags) as unknown) as HeatmapIssueFlagsI;
		yield put(actions.fetchHeatmapIssueFlagsAsync.success(res));
	} catch {
		yield put(actions.fetchHeatmapIssueFlagsAsync.failure('Failed to get heatmap issue flag details'));
	}
}

export default function* executiveDashboardSaga() {
	yield takeEvery(actions.fetchDepartmentRatioAsync.request, fetchDepartmentRatioSaga);
	yield takeEvery(actions.fetchIrrRatioAsync.request, fetchIrrRatioSaga);
	yield takeEvery(actions.fetchRcrRatioAsync.request, fetchRcrRatioSaga);
	yield takeEvery(actions.fetchVocRatioAsync.request, fetchVocRatioSaga);
	yield takeEvery(actions.fetchHeatmapIssueFlagsAsync.request, fetchHeatmapIssueFlagsSaga);
}
