import React, { useEffect } from 'react';

import { InputLogin } from './Auth.styles';
import { AuthFormProps } from './AuthForm';

import ReactGA from 'react-ga';

export type AuthFormForgotPasswordProps = Omit<
	AuthFormProps,
	| 'loginCredentials'
	| 'isLoading'
	| 'modeForgot'
	| 'handleSubmitForm'
	| 'handleChangeEmail'
	| 'handleChangePassword'
	| 'handleToggleForgotPass'
	| 'validationMessage'
	| 'isFederated'
	| 'isRequestAccess'
	| 'handleRequestAccess'
	| 'isCheckingEmail'
	| 'isEmailValid'
	| 'isRequestSuccess'
	| 'isRequestFailed'
	| 'isMultipleRequest'
	| 'isAuthenticating'
	| 'handleDismissRequestSuccess'
>;

export const AuthFormForgotPassword = (props: AuthFormForgotPasswordProps) => {
	useEffect(() => {
		ReactGA.event({
			category: 'Home',
			action: 'Landing - Forgot Password',
		});
	});

	return (
		/** Forgot password form (will only be shown when modeForgot is true) */
		<div className="email-forgot-pass">
			<InputLogin
				name="email-forgot"
				placeholder="Email Address"
				hasError={props.siteFlashMessage.hasError.forgotPass}
				value={props.forgotPasswordData.email}
				validation
				required
				type="email"
				onChange={props.handleChangeForgotPass}
				className="fade-in"
				id="input_email_forgot_pwd"
			/>
		</div>
	);
};

export default AuthFormForgotPassword;
