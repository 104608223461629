import { createReducer } from 'typesafe-actions';
import { AuditorsState } from './types';
import { AuditorsAction } from './action';
import { fetchAuditorsDailyChartAsync } from './dailyChart/action';
import { fetchAuditorsListAsync, downloadAuditorsListAsync } from './auditorsList/action';
import { fetchManyUsersChartAsync } from './manyUsersChart/action';
import { fetchSingleUserChartAsync } from './singleUserChart/action';

const initialState: AuditorsState = {
  /**
   * Auditors List State
   */
  auditorsList_loading: false,
  auditorsList_data: [],
  auditorsList_error: '',
  auditorsList_dl_loading: false,
  auditorsList_dl_url: '',
  auditorsList_dl_error: '',
  /**
   * Auditors Daily Chart State
   */
  dailyChart_loading: false,
  dailyChart_data: {
    overview: {
      currentCompleted: 0,
      previousCompleted: 0,
      currentDuration: 0,
      previousDuration: 0,
      currentScheduled: 0,
      previousScheduled: 0
    },
    daily: []
  },
  dailyChart_error: '',
  /**
   * Many Users Chart State
   */
  manyUsersChart_loading: false,
  manyUsersChart_data: {
    overview: {
      currentCompleted: 0,
      previousCompleted: 0,
      currentDuration: 0,
      previousDuration: 0,
      currentScheduled: 0,
      previousScheduled: 0
    },
    daily: []
  },
  manyUsersChart_error: '',
  /**
   * Single User Chart State
   */
  singleUserChart_loading: false,
  singleUserChart_data: {
    overview: {
      currentCompleted: 0,
      previousCompleted: 0,
      currentDuration: 0,
      previousDuration: 0,
      currentScheduled: 0,
      previousScheduled: 0
    },
    daily: []
  },
  singleUserChart_error: ''
};

export const auditorsReducer = createReducer<AuditorsState, AuditorsAction>(initialState)
  .handleAction(fetchAuditorsDailyChartAsync.request, state => ({
    ...state,
    dailyChart_loading: true
  }))
  .handleAction(fetchAuditorsDailyChartAsync.success, (state, action) => ({
    ...state,
    dailyChart_loading: false,
    dailyChart_data: action.payload,
    dailyChart_error: ''
  }))
  .handleAction(fetchAuditorsDailyChartAsync.failure, (state, action) => ({
    ...state,
    dailyChart_loading: false,
    dailyChart_error: action.payload
  }))
  .handleAction(fetchAuditorsListAsync.request, state => ({
    ...state,
    auditorsList_loading: true
  }))
  .handleAction(fetchAuditorsListAsync.success, (state, action) => ({
    ...state,
    auditorsList_loading: false,
    auditorsList_data: action.payload,
    auditorsList_error: ''
  }))
  .handleAction(fetchAuditorsListAsync.failure, (state, action) => ({
    ...state,
    auditorsList_loading: false,
    auditorsList_error: action.payload
  }))
  .handleAction(fetchManyUsersChartAsync.request, state => ({
    ...state,
    manyUsersChart_loading: true
  }))
  .handleAction(fetchManyUsersChartAsync.success, (state, action) => ({
    ...state,
    manyUsersChart_loading: false,
    manyUsersChart_data: action.payload,
    manyUsersChart_error: ''
  }))
  .handleAction(fetchManyUsersChartAsync.failure, (state, action) => ({
    ...state,
    manyUsersChart_loading: false,
    manyUsersChart_error: action.payload
  }))
  .handleAction(fetchSingleUserChartAsync.request, state => ({
    ...state,
    singleUserChart_loading: true
  }))
  .handleAction(fetchSingleUserChartAsync.success, (state, action) => ({
    ...state,
    singleUserChart_loading: false,
    singleUserChart_data: action.payload,
    singleUserChart_error: ''
  }))
  .handleAction(fetchSingleUserChartAsync.failure, (state, action) => ({
    ...state,
    singleUserChart_loading: false,
    singleUserChart_error: action.payload
  }))
  .handleAction(downloadAuditorsListAsync.request, state => ({
    ...state,
    auditorsList_dl_loading: true
  }))
  .handleAction(downloadAuditorsListAsync.success, (state, action) => ({
    ...state,
    auditorsList_dl_loading: false,
    auditorsList_dl_url: action.payload,
    auditorsList_dl_error: ''
  }))
  .handleAction(downloadAuditorsListAsync.failure, (state, action) => ({
    ...state,
    auditorsList_dl_loading: false,
    auditorsList_dl_url: '',
    auditorsList_dl_error: action.payload
  }));
