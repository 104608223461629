import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './questionnaireIndex.action';
import { QuestionnaireIndexState } from './type';

export type QuestionnairesAction = ActionType<typeof actions>;

const initialState: QuestionnaireIndexState = {
  questionnaireIndex: null,
  questionnaireProfileOnly: null,
  isLoading: false,
  error: null
};

export const questionnaireIndexReducer = createReducer<QuestionnaireIndexState, QuestionnairesAction>(initialState)
  .handleAction(actions.fetchQuestionnaireIndex.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchQuestionnaireIndex.success, (state, action) => ({
    ...state,
    questionnaireIndex: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchQuestionnaireProfileOnly.success, (state, action) => ({
    ...state,
    questionnaireProfileOnly: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchQuestionnaireIndex.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }));

export default questionnaireIndexReducer;
