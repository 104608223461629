import {
  FetchQuestionnaireStatPayload,
  FetchQuestionnaireStatResponse,
  IssuesQuestionnaireStat
} from 'reducers/dashboard/issues/questionnaireStat/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyIssuesQuestionnaireStat } from '../../../../utils/dashboardDummy/issues/dummyIssuesQuestionnaireStat';

type QuestionnaireStatData = {
  message?: 'Invalid date Query';
  issuesData: IssuesQuestionnaireStat;
};

export const fetchQuestionnaireStat = async (
  payload: FetchQuestionnaireStatPayload
): Promise<FetchQuestionnaireStatResponse> => {
  const { isDummy, ...query } = payload;

  if (isDummy) {
    const dummyIssuesQuestionnaireStat = getDummyIssuesQuestionnaireStat(payload);
    return {
      message: 'SUCCESS',
      data: dummyIssuesQuestionnaireStat
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/issues/questionnaires?${serializedQueries}`;

    const res = await fetch(url, options);

    if (res.status !== 200) {
      throw new Error('');
    }

    const questionnaireStatResult: QuestionnaireStatData = await res.json();

    return {
      message: 'SUCCESS',
      data: questionnaireStatResult.issuesData
    };
  } catch {
    const errorResult: FetchQuestionnaireStatResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
