import { DashboardOverviewPayload, DailyChartResponse } from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type OverviewDailyChart = DailyChartResponse['data'];

export interface OverviewDailyChartState {
  readonly dailyChart_loading: boolean;
  readonly dailyChart_data: OverviewDailyChart;
  readonly dailyChart_error: string;
}

export const FETCH_OVERVIEW_DAILY_CHART_REQUEST = '@@dashboard/overview/FETCH_DAILY_CHART_REQUEST';
export const FETCH_OVERVIEW_DAILY_CHART_SUCCESS = '@@dashboard/overview/FETCH_DAILY_CHART_SUCCESS';
export const FETCH_OVERVIEW_DAILY_CHART_FAILURE = '@@dashboard/overview/FETCH_DAILY_CHART_FAILURE';

export type FetchDailyChartPayload = DashboardOverviewPayload;
export type FetchDailyChartResponse = DailyChartResponse | ErrorResponse;
