import { put } from 'typed-redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import * as actions from './operational.action';
import {
	fetchReportsDetail,
	fetchIssueOverviewDetail,
	fetchLastMonthIssueOverviewDetail,
	fetchIssueChartData,
	fetchReportChartData,
} from 'services/dashboardRevamp/reports/operationalKPI.service';
import { GetCompleteReportDetail } from '@nimbly-technologies/nimbly-common';
import {
	IssueChartData,
	IssueOverviewDetail,
	LastMonthIssueOverviewDetail,
	IssueResolutionData,
	ReportChartData,
	ReportCompletionData,
} from './operationalStore';

function* fetchCompleteReportDetailSaga(
	action: ReturnType<typeof actions.fetchCompleteReportDetailAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchReportsDetail) as unknown) as GetCompleteReportDetail;
		yield put(actions.fetchCompleteReportDetailAsync.success(res));
	} catch {
		yield put(actions.fetchCompleteReportDetailAsync.failure('[ERROR] Failed to get Dashboard Report Details'));
	}
}

function* fetchIssueOverviewDetailAsync(
	action: ReturnType<typeof actions.fetchIssueOverviewDetailAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchIssueOverviewDetail) as unknown) as IssueOverviewDetail;
		yield put(actions.fetchIssueOverviewDetailAsync.success(res));
	} catch {
		yield put(actions.fetchIssueOverviewDetailAsync.failure('[ERROR] Failed to get Dashboard Issue Details'));
	}
}

function* fetchLastMonthIssueOverviewDetailAsync(
	action: ReturnType<typeof actions.fetchLastMonthIssueOverviewDetailAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLastMonthIssueOverviewDetail) as unknown) as LastMonthIssueOverviewDetail;
		yield put(actions.fetchLastMonthIssueOverviewDetailAsync.success(res));
	} catch {
		yield put(actions.fetchLastMonthIssueOverviewDetailAsync.failure('[ERROR] Failed to get Dashboard Issue Details'));
	}
}

function* fetchIssueChartAsync(action: ReturnType<typeof actions.fetchIssueChartAsync.request>): Generator {
	try {
		const res = (yield call(fetchIssueChartData) as unknown) as IssueChartData[];
		yield put(actions.fetchIssueChartAsync.success(res));
	} catch {
		yield put(actions.fetchIssueChartAsync.failure('[ERROR] Failed to get Dashboard Issues List'));
	}
}

function* fetchReportChartAsync(action: ReturnType<typeof actions.fetchReportChartAsync.request>): Generator {
	try {
		const res = (yield call(fetchReportChartData) as unknown) as ReportChartData[];
		yield put(actions.fetchReportChartAsync.success(res));
	} catch {
		yield put(actions.fetchReportChartAsync.failure('[ERROR] Failed to get Dashboard Issues List'));
	}
}

export default function* operationalDashboardSaga() {
	yield takeEvery(actions.fetchCompleteReportDetailAsync.request, fetchCompleteReportDetailSaga);
	yield takeEvery(actions.fetchIssueOverviewDetailAsync.request, fetchIssueOverviewDetailAsync);
	yield takeEvery(actions.fetchLastMonthIssueOverviewDetailAsync.request, fetchLastMonthIssueOverviewDetailAsync);
	yield takeEvery(actions.fetchIssueChartAsync.request, fetchIssueChartAsync);
	yield takeEvery(actions.fetchReportChartAsync.request, fetchReportChartAsync);
}
