import {
	Quiz,
	QuizPaginateResponse,
	QuizState,
	FindSKUPerCategory,
	SKUCompact,
} from '@nimbly-technologies/nimbly-common';
import { DateRange } from 'components/global/CalendarPicker/utils/calendarUtils';

export enum QuizFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface QuizFilters {
	state: string[];
	userIDs: string[];
	publishedDate: DateRange;
	lastModified: DateRange;
	associationStates: string[];
}

export enum LmsQuizSortField {
	TITLE = 'title',
	PUBLISHED_DATE = 'publishedDate',
	CREATED_BY = 'createdBy',
	STATE = 'state',
}

export type QuizzesSortFields = 'title' | 'publishedDate' | 'createdBy' | 'state' | 'lastModified';

export interface QuizBulkErrorResponse {
	quizID: string;
	reason: string;
}

export interface QuizState {
	error: any;
	bulkError: QuizBulkErrorResponse[];
	fetchState: QuizFetchState;
	quizzes: QuizPaginateResponse[];

	totalPages: number;
	totalDocs: number;
	page: number;
	limit: number;

	sortField: QuizzesSortFields;
	sortDirection: 'asc' | 'desc';
	search: string;
	filters: QuizFilters;
}
