import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import { statistic } from 'nimbly-common';

import * as actions from './action';

export type CardCompletionAction = ActionType<typeof actions>;

export interface CardCompletionState {
  isLoading: boolean;
  isLoaded: boolean;
  data: null | statistic.SiteScheduleOverview;
  error: string | null;
}

const initialState: CardCompletionState = {
  isLoading: false,
  isLoaded: false,
  data: null,
  error: null
};

export const cardCompletionReducer = createReducer<CardCompletionState, CardCompletionAction>(initialState)
  .handleAction(actions.fetchCardCompletionAsync.request, state =>
    update(state, {
      $merge: {
        isLoading: true,
        isLoaded: false,
        data: null,
        error: null
      }
    })
  )
  .handleAction(actions.fetchCardCompletionAsync.success, (state, action) =>
    update(state, {
      $merge: {
        isLoaded: true,
        isLoading: false,
        error: null,
        data: action.payload
      }
    })
  )
  .handleAction(actions.fetchCardCompletionAsync.failure, (state, action) =>
    update(state, {
      $merge: {
        isLoaded: false,
        isLoading: false,
        error: action.payload,
        data: null
      }
    })
  );
