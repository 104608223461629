export const FETCH_ALL_ORGANIZATIONS_REQUEST = '@activities/FETCH_ALL_ORGANIZATIONS_REQUEST';
export const FETCH_ALL_ORGANIZATIONS_SUCCESS = '@activities/FETCH_ALL_ORGANIZATIONS_SUCCESS';
export const FETCH_ALL_ORGANIZATIONS_FAILURE = '@activities/FETCH_ALL_ORGANIZATIONS_FAILURE';

export const FETCH_ORGANIZATION_USERS_REQUEST = '@activities/FETCH_ORGANIZATION_USERS_REQUEST';
export const FETCH_ORGANIZATION_USERS_SUCCESS = '@activities/FETCH_ORGANIZATION_USERS_SUCCESS';
export const FETCH_ORGANIZATION_USERS_FAILURE = '@activities/FETCH_ORGANIZATION_USERS_FAILURE';

export const FETCH_ORGANIZATION_ACTIVITIES_REQUEST = '@activities/FETCH_ORGANIZATION_ACTIVITIES_REQUEST';
export const FETCH_ORGANIZATION_ACTIVITIES_SUCCESS = '@activities/FETCH_ORGANIZATION_ACTIVITIES_SUCCESS';
export const FETCH_ORGANIZATION_ACTIVITIES_FAILURE = '@activities/FETCH_ORGANIZATION_ACTIVITIES_FAILURE';

export const RESET_ORGANIZATION_ACTIVITIES = '@activities/RESET_ORGANIZATION_ACTIVITIES';

export const SELECT_ORGANIZATION = '@activities/SELECT_ORGANIZATION';
export const SELECT_USER = '@activities/SELECT_USER';
export const SELECT_ACTIVITY_TYPE = '@activities/SELECT_ACTIVITY_TYPE';
export const SELECT_PERIOD_TYPE = '@activities/SELECT_PERIOD_TYPE';

export const SET_DATES = '@activities/SET_DATES';
export const SET_PREVIOUS_DATES = '@activities/SET_PREVIOUS_DATES';
export const SET_CUSTOM_DATES = '@activities/SET_CUSTOM_DATES';

export const SET_PAGE_INDEX = '@activities/SET_PAGE_INDEX';
export const SET_TOTAL_PAGES = '@activities/SET_TOTAL_DOCS';
export const SET_TOTAL_DOCS = '@activities/SET_TOTAL_PAGES';
