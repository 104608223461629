import { createAsyncAction } from 'typesafe-actions';
import { internalStatistic } from '@nimbly-technologies/nimbly-common';

import {
	FETCH_MISSED_MAKEUP_OVERVIEW_REQUEST,
	FETCH_MISSED_MAKEUP_OVERVIEW_SUCCESS,
	FETCH_MISSED_MAKEUP_OVERVIEW_FAILURE,
	FETCH_MISSED_MAKEUP_LIST_REQUEST,
	FETCH_MISSED_MAKEUP_LIST_SUCCESS,
	FETCH_MISSED_MAKEUP_LIST_FAILURE,
	FetchMissedMakeupQuery,
	FetchMissedMakeupReportListQuery,
} from './types';

export const fetchMissedMakeupOverviewAsync = createAsyncAction(
	FETCH_MISSED_MAKEUP_OVERVIEW_REQUEST,
	FETCH_MISSED_MAKEUP_OVERVIEW_SUCCESS,
	FETCH_MISSED_MAKEUP_OVERVIEW_FAILURE,
)<FetchMissedMakeupQuery, internalStatistic.ReportSummaryOverview, string>();

export const fetchMissedMakeupListAsync = createAsyncAction(
	FETCH_MISSED_MAKEUP_LIST_REQUEST,
	FETCH_MISSED_MAKEUP_LIST_SUCCESS,
	FETCH_MISSED_MAKEUP_LIST_FAILURE,
)<FetchMissedMakeupReportListQuery, internalStatistic.ReportSummaryList, string>();
