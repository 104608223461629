import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './users.action';
import { UsersState } from './type';

export type UsersAction = ActionType<typeof actions>;

const initialState: UsersState = {
	users: null,
	userRoles: {},
	userConfig: [],
	siteOwners: [],
	isLoading: false,
	error: null,
	userSchedule: null,
	isLoadingSchedule: false,
	paginateUsers: null,
	totalUsers: null,
	isLoadingPaginate: false,
	usersDataVisibility: null,
	baseRoleUsers: null,
};

export const usersReducer = createReducer<UsersState, UsersAction>(initialState)
	.handleAction(actions.fetchUsers.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchUsers.success, (state, action) => ({
		...state,
		users: action.payload.data,
		isLoading: false,
	}))
	.handleAction(actions.fetchUsers.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchUserConfig.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchUserConfig.success, (state, action) => ({
		...state,
		userConfig: action.payload.data,
		isLoading: false,
	}))
	.handleAction(actions.fetchUserConfig.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.setUserConfig, (state, action) => ({
		...state,
		userConfig: action.payload.data,
	}))
	.handleAction(actions.fetchUserRole.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchUserRole.success, (state, action) => ({
		...state,
		userRoles: action.payload.data,
		isLoading: false,
	}))
	.handleAction(actions.fetchUserRole.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchUserSchedule.request, (state) => ({
		...state,
		error: null,
		isLoadingSchedule: true,
	}))
	.handleAction(actions.fetchUserSchedule.success, (state, action) => ({
		...state,
		userSchedule: action.payload.data.length,
		isLoadingSchedule: false,
	}))
	.handleAction(actions.fetchUserSchedule.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingSchedule: false,
	}))
	.handleAction(actions.fetchPaginateUsers.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isLoadingPaginate: true,
	}))
	.handleAction(actions.fetchPaginateUsers.success, (state, action) => ({
		...state,
		paginateUsers: action.payload.data,
		totalUsers: action.payload.total,
		isLoading: false,
		isLoadingPaginate: false,
	}))
	.handleAction(actions.fetchPaginateUsers.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
		isLoadingPaginate: false,
	}))
	.handleAction(actions.fetchSiteOwners.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchSiteOwners.success, (state, action) => ({
		...state,
		sitOwners: action.payload.data,
		isLoading: false,
	}))
	.handleAction(actions.fetchSiteOwners.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchUsersDataVisibility.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchUsersDataVisibility.success, (state, action) => ({
		...state,
		usersDataVisibility: action.payload.data,
		isLoading: false,
	}))
	.handleAction(actions.fetchUsersDataVisibility.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.fetchBaseRoleUsers.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchBaseRoleUsers.success, (state, action) => ({
		...state,
		baseRoleUsers: action.payload.data,
		isLoading: false,
	}))
	.handleAction(actions.fetchBaseRoleUsers.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}));

export default usersReducer;
