const serializeQuery = (obj: any) => {
	let query = [];
	for (let attr in obj)
		if (obj.hasOwnProperty(attr)) {
			if (obj[attr] !== '_all_') {
				query.push(encodeURIComponent(attr) + '=' + obj[attr]);
			}
		}
	return query.join('&');
};

export default serializeQuery;
