import { toast } from 'react-toastify';
import { call, put, select } from 'typed-redux-saga';
import * as actions from './repositoryFolderList.action';
import * as types from './repositoryFolderList.actionTypes';
import { takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/rootReducers';
import { RepositoryFolderListState } from './type';
import queryString from 'query-string';
import { fetchRepositoryFolderList } from 'services/repository/repository.service';

export function* fetchRepositoryFolderListaga() {
	const repositoryFolderListState = (state: RootState) => state.repositoryFolderList;
	const state: RepositoryFolderListState = yield select(repositoryFolderListState);

	try {
		const query = {
			sortBy: state.sortBy,
			sortType: state.sortType,
		};
		const queryStr = queryString.stringify(query);
		const result = yield* call(fetchRepositoryFolderList);
		yield put(actions.fetchRepositoryFolderList.success({ data: result }));
	} catch (e) {
		toast.error((e as Error).message);
		yield put(actions.fetchRepositoryFolderList.failure({ error: 'Failed to fetch attachments' }));
	}
}

export default function* repositoryFolderListSaga() {
	yield takeLatest(types.FETCH_REPOSITORY_FOLDER_LIST_REQUEST, fetchRepositoryFolderListaga);
	yield takeLatest(types.SET_REPOSITORY_FOLDER_LIST_SORT_BY, fetchRepositoryFolderListaga);
	yield takeLatest(types.SET_REPOSITORY_FOLDER_LIST_SORT_TYPE, fetchRepositoryFolderListaga);
}
