import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesFlagsChart,
  FETCH_ISSUES_FLAGS_CHART_REQUEST,
  FETCH_ISSUES_FLAGS_CHART_SUCCESS,
  FETCH_ISSUES_FLAGS_CHART_FAILURE,
  FetchFlagsChartPayload
} from './types';

export const fetchIssuesFlagsChartAsync = createAsyncAction(
  FETCH_ISSUES_FLAGS_CHART_REQUEST,
  FETCH_ISSUES_FLAGS_CHART_SUCCESS,
  FETCH_ISSUES_FLAGS_CHART_FAILURE
)<FetchFlagsChartPayload, IssuesFlagsChart, string>();
