import {
	NotificationConfig,
	NotificationSettings,
	UnsubscribeNotificationSettings as UnsubscribeNotificationSettingsNimbly,
} from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import { IAPIResponse } from 'types/api';
import API from '../../../helpers/api';

export type UnsubscribeNotificationSettings = Omit<UnsubscribeNotificationSettingsNimbly, 'createdAt' | 'updatedAt'>;
export type NotificationConfigApp = Record<string, Omit<NotificationConfig, 'lastExecutionTime'>>;

export type CreateSettingsNotificationPayload = FetchSettingsNotificationPayload;
export const createSettingsNotification = async (
	payload: CreateSettingsNotificationPayload,
): Promise<IAPIResponse<NotificationSettings>> => {
	const { organizationID: organizationId } = payload;

	const url = getNotificationSettingsUrl(payload);

	const token = await API.getFirebaseToken();
	const res = await API.post(url, token, {
		organizationID: organizationId,
		disabled: false,
	});
	const data = await res.json();

	return data;
};

export interface UpdateSettingsNotificationPayload extends FetchSettingsNotificationPayload {
	configs: NotificationConfigApp;
}
export const updateSettingsNotification = async (
	payload: UpdateSettingsNotificationPayload,
): Promise<IAPIResponse<NotificationSettings>> => {
	const url = getNotificationSettingsUrl(payload);

	const token = await API.getFirebaseToken();
	const res = await API.put(url, token, payload);
	const data = await res.json();

	return data;
};

interface FetchSettingsNotificationPayload {
	organizationID: string;
}
export const fetchSettingsNotification = async (
	payload: FetchSettingsNotificationPayload,
): Promise<IAPIResponse<NotificationSettings>> => {
	const url = getNotificationSettingsUrl(payload);

	const data = await API.getJSON<IAPIResponse<NotificationSettings>>(url);

	if (!data) {
		throw new Error('Failed to fetch notification settings');
	}

	return data;
};

const getNotificationSettingsUrl = (arg: FetchSettingsNotificationPayload) => {
	const { organizationID: organizationId } = arg;

	return `${apiURL}/organizations/${organizationId}/notification-settings`;
};

export interface FetchSettingsNotificationUnsubscribePayload {
	organizationID: string;
}
export type FetchSettingsNotificationUnsubscribeResponse = IAPIResponse<UnsubscribeNotificationSettings[]>;
export const fetchSettingsNotificationUnsubscribe = async (
	payload: FetchSettingsNotificationUnsubscribePayload,
): Promise<FetchSettingsNotificationUnsubscribeResponse> => {
	const url = getNotificationSettingsUnsubscribeUrl(payload);

	const data = await API.getJSON<FetchSettingsNotificationUnsubscribeResponse>(`${url}`);

	if (!data) {
		const createRes = await createSettingsNotificationUnsubscribe({
			...payload,
			settings: [],
		});
		if (createRes) return createRes;

		throw new Error('Failed to fetch notification settings');
	}

	return data;
};

export interface CreateSettingsNotificationUnsubscribePayload extends FetchSettingsNotificationUnsubscribePayload {
	settings: UnsubscribeNotificationSettings[];
}
export type CreateSettingsNotificationUnsubscribeResponse = FetchSettingsNotificationUnsubscribeResponse;
export const createSettingsNotificationUnsubscribe = async (
	payload: CreateSettingsNotificationUnsubscribePayload,
): Promise<CreateSettingsNotificationUnsubscribeResponse> => {
	const { settings } = payload;

	const url = getNotificationSettingsUnsubscribeUrl(payload);

	const token = await API.getFirebaseToken();
	const res = await API.post(url, token, settings);
	const data = await res.json();

	return data;
};

export interface UpdateSettingsNotificationUnsubscribePayload extends FetchSettingsNotificationUnsubscribePayload {
	settings: UnsubscribeNotificationSettings[];
}
export type UpdateSettingsNotificationUnsubscribeResponse = FetchSettingsNotificationUnsubscribeResponse;
export const updateSettingsNotificationUnsubscribe = async (
	payload: UpdateSettingsNotificationUnsubscribePayload,
): Promise<UpdateSettingsNotificationUnsubscribeResponse> => {
	const { settings } = payload;

	const url = getNotificationSettingsUnsubscribeUrl(payload);

	const token = await API.getFirebaseToken();
	const res = await API.put(url, token, settings);
	const data = await res.json();

	return data;
};

const getNotificationSettingsUnsubscribeUrl = (arg: FetchSettingsNotificationUnsubscribePayload) => {
	const { organizationID: organizationId } = arg;

	return `${apiURL}/organizations/${organizationId}/unsubscribe-notification-settings`;
};
