export type SortDirection = 'asc' | 'desc';

export enum SKU_FETCH_STATE {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface ALL_SKU_LIST {
	_id: string;
	skuID: string;
	name: string;
}
