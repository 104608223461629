export const SET_LOADING = '@questionnaireIndex/SET_LOADING';

export const FETCH_QUESTIONNAIRE_INDEXES_REQUEST = '@questionnaireIndex/FETCH_QUESTIONNAIRE_INDEXES_REQUEST';
export const FETCH_QUESTIONNAIRE_INDEXES_SUCCESS = '@questionnaireIndex/FETCH_QUESTIONNAIRE_INDEXES_LOADING';
export const FETCH_QUESTIONNAIRE_INDEXES_FAILURE = '@questionnaireIndex/FETCH_QUESTIONNAIRE_INDEXES_FAILURE';

export const FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_REQUEST =
  '@questionnaireIndex/FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_REQUEST';
export const FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_SUCCESS =
  '@questionnaireIndex/FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_SUCCESS';
export const FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_FAILURE =
  '@questionnaireIndex/FETCH_QUESTIONNAIRE_INDEXES_PROFILE_ONLY_FAILURE';
