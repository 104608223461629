import { takeLatest, put, call, all, select, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getToken } from 'reducers/api';
import * as types from 'reducers/promotions/promotions.actionType';
import * as actions from 'reducers/promotions/promotions.action';
import { promotionState } from 'reducers/promotions/type';
import { apiURL } from 'config/baseURL';
import { RootState } from 'store/rootReducers';

type Action = {
  type: string;
  payload: any;
  meta: string | undefined;
};

export function* fetchAllPromotions() {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchAllPromotionsUrl = `${apiURL}/promotions/all/`;
    const request = () => fetch(fetchAllPromotionsUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      const mappingData: { [key: string]: any } = {};

      responseData.data.forEach((promo: any) => {
        const promotionId: string = promo.promotionID;
        if (!mappingData.hasOwnProperty(promotionId)) {
          mappingData[promotionId] = promo;
        }
      });

      yield put(
        actions.fetchPromotions.success({
          data: {
            mapped: responseData.data ? mappingData : [],
            raw: responseData.data !== null ? responseData.data : []
          }
        })
      );
      return mappingData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchPromotions.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchPromotions.failure({ error: 'Failed to Fetch Promotion List' }));
    return null;
  }
}

function* createPromotion(action: Action) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const createProductUrl = `${apiURL}/promotions/`;
    if (payload.cover && payload.cover.length > 0) {
      const uploadTasks = payload.cover.map((cover: any) => {
        if (typeof cover === 'string') {
          return Promise.resolve(cover);
        } else {
          const newCover = new FormData();
          newCover.append('promotionPhoto[0]', cover);
          const photoOptions = {
            method: 'POST',
            headers: {
              Authorization: authToken
            },
            body: newCover
          };
          return fetch(`${createProductUrl}upload`, photoOptions);
        }
      });
      const photoURLs = yield all(uploadTasks);
      if (typeof payload.cover[0] === 'string') {
        payload.coverURL = photoURLs[0];
        payload.cover = '';
      } else {
        if (photoURLs[0] && photoURLs[0].status === 200) {
          const responseData = yield photoURLs[0].json();
          const photoData = responseData.data;
          payload.cover = photoData.promotionPhoto[0].photoPath;
          payload.coverURL = photoData.promotionPhoto[0].photoURL;
        } else {
          const responseData = yield photoURLs[0].json();
          yield fork(toast.error, responseData.message);
          yield put(actions.createPromotion.failure({ error: responseData.message }));
          return null;
        }
      }
    } else {
      payload.cover = '';
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(payload)
    };

    const request = () => fetch(createProductUrl, options);
    const response = yield call(request);

    if (response && response?.status === 200) {
      const responseData = yield response.json();

      yield put(actions.createPromotion.success({ data: responseData.data }));
      yield fork(toast.success, 'Success create Promotion');
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.createPromotion.failure({ error: responseData.message }));
      yield fork(toast.error, responseData.message);
      return null;
    }
  } catch (e) {
    yield fork(toast.error, 'Failed to Create Promotion');
    yield put(actions.createPromotion.failure({ error: 'Failed to Create Promotion' }));
    return null;
  }
}

export function* fetchPromotionDetail(action: Action) {
  try {
    yield put(actions.setLoading(true));
    const authToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchPromotionDetail = `${apiURL}/promotions/${action.payload}`;
    const request = () => fetch(fetchPromotionDetail, options);
    const response = yield call(request);

    if (response && response?.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchPromotionDetail.success({ data: responseData.data }));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchPromotionDetail.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchPromotionDetail.success({ data: null }));
    yield put(actions.fetchPromotionDetail.failure({ error: 'Failed to Fetch Product Detail' }));
    return null;
  }
}

function* updatePromotion(action: Action) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(payload)
    };

    const updatePromotionUrl = `${apiURL}/promotions/${payload.promotionID}`;

    const request = () => fetch(updatePromotionUrl, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      yield put(actions.updatePromotion.success({ data: { [payload.id]: payload } }));
      yield call(toast.success, 'Success update Promotion');
    } else {
      const responseData = yield response.json();
      yield call(toast.success, responseData.message);
      yield put(actions.updatePromotion.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.updatePromotion.failure({ error: 'Failed to Update Promotion' }));
    yield call(toast.success, 'Failed to Update Promotion');
    return null;
  }
}

export function* fetchPaginatePromotions(action: Action) {
  try {
    const authToken = yield getToken();
    const getState = (state: RootState) => state;
    const state: RootState = yield select(getState);
    const payload = { ...action.payload };
    const promotionState: promotionState = state.promotions;

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };
    let sortDirections: string = 'asc';

    switch (promotionState.sortBy) {
      case 'title':
        sortDirections = promotionState.title;
        break;
      case 'sales':
        sortDirections = promotionState.sales;
        break;
      case 'message':
        sortDirections = promotionState.message;
        break;
      case 'target':
        sortDirections = promotionState.target;
        break;
      case 'start':
        sortDirections = promotionState.start;
        break;
      case 'end':
        sortDirections = promotionState.end;
        break;
      case 'cost':
        sortDirections = promotionState.cost;
        break;
      default:
        break;
    }

    const query: { [key: string]: string | number } = {
      search: promotionState.filterQuery || '',
      page: promotionState.pageIndex,
      sortFields: promotionState.sortBy,
      sortDirections,
      limit: payload.limit,
      tab: promotionState.selectedTab
    };

    const queryStr = Object.keys(query).length
      ? Object.keys(query)
          .map((key: string) => {
            return `${key}=${query[key]}`;
          })
          .join('&')
      : '';

    const fetchPromotionURL = `${apiURL}/promotions/?${queryStr}`;
    const request = () => fetch(fetchPromotionURL, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      const mappingData: { key: string; value: any }[] = [];

      responseData.data.docs.forEach((promo: any) => {
        const promotionKey: string = promo.promotionID;
        mappingData.push({ value: promo, key: promotionKey });
      });

      yield put(actions.fetchPaginatePromotions.success({ data: mappingData, total: responseData.data.totalDocs }));
      return mappingData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchPaginatePromotions.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchPaginatePromotions.success({ data: null, total: 0 }));
    yield put(actions.fetchPaginatePromotions.failure({ error: 'Failed to Fetch Sites' }));
    return null;
  }
}

export default function* promotionSaga() {
  yield takeLatest(types.FETCH_PROMOTIONS_REQUEST, fetchAllPromotions);
  yield takeLatest(types.CREATE_PROMOTIONS_REQUEST, createPromotion);
  yield takeLatest(types.FETCH_PROMOTIONS_DETAIL_REQUEST, fetchPromotionDetail);
  yield takeLatest(types.UPDATE_PROMOTIONS_REQUEST, updatePromotion);
  yield takeLatest(types.FETCH_PAGINATE_PROMOTIONS_REQUEST, fetchPaginatePromotions);
}
