import { ActionType, createReducer } from 'typesafe-actions';
import { RepositoryFilters, RepositoryState, RepositoryTabEnum, SearchAttachmentResult } from './type.d';
import * as actions from './repository.action';
import { LmsViewType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';
import { RepositoryListEnum, RepositorySortByOptionsEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export type SkusCompactAction = ActionType<typeof actions>;

export const initialRepositoryFilters: RepositoryFilters = {
	qFileType: [],
	qUser: [],
	qLocation: '',
	qStartDate: null,
	qEndDate: null,
};

export const initialSearchValues: SearchAttachmentResult = {
	questionMatches: [],
	questionMatchCount: 0,
	categoryMatches: [],
	categoryMatchCount: 0,
	issueMatches: [],
	issueMatchCount: 0,
};

export const initialDownloadProgressModalConfig = {
	isVisible: false,
	count: 0,
	total: 0,
};

export const initialState: RepositoryState = {
	error: '',
	isLoading: false,
	isLoadingNextPage: false,
	isLoadingSearch: false,
	isSelectMode: false,
	isBulkOperation: false,
	activeTab: RepositoryTabEnum.MY_FILES,
	gridSize: 6,
	viewType: LmsViewType.TILE,
	sortBy: RepositorySortByOptionsEnum.LAST_MODIFIED,
	sortType: 'desc',

	page: 1,
	limit: 6 * 4,
	totalPages: 0,
	totalDocs: 0,
	canLoadMore: false,

	folders: [],
	files: [],
	fileSections: {},

	selectedFile: null,
	selectedFileIds: new Set(),
	totalSelectedItems: 0,
	totalSelectedFilesSize: 0,

	selectedFolder: undefined,
	selectedFolderIds: new Set(),

	selectedDates: new Set(),
	folderHierarchy: [],

	searchQuery: '',
	searchData: initialSearchValues,
	filters: initialRepositoryFilters,
	list: RepositoryListEnum.MY_FILES,
	groupByCount: undefined,

	isFilterOpen: false,
	isFilePreviewModalVisible: false,
	isNewFolderModalVisible: false,
	isTrashModalVisible: false,
	isRenameModalVisible: false,
	isDeleteForeverModalVisible: false,
	isEmptyTrashModalVisible: false,
	isRestoreModalVisible: false,
	isShareModalVisible: false,
	isMovetoFolderModalVisible: false,
	isStarModalVisible: false,
	isUploadFileModalVisible: false,
	isDownloadErrorModalVisible: false,
	isDownloadInitializationModalVisible: false,
	downloadProgressModalConfig: initialDownloadProgressModalConfig,
};

export const repositoryReducer = createReducer<RepositoryState, SkusCompactAction>(initialState)
	.handleAction(actions.setActiveRepositoryTab, (state, action) => ({
		...initialState,
		activeTab: action.payload,
		list: state.list,
		searchQuery: state.searchQuery,
		selectedFolder: state.selectedFolder,
	}))
	.handleAction(actions.setRepositorySearchQuery, (state, action) => ({
		...state,
		searchQuery: action.payload,
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.setRepositoryFilters, (state, action) => ({
		...state,
		isLoading: true,
		filters: { ...state.filters, ...action.payload },
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.resetRepositoryFilters, (state) => ({
		...state,
		isLoading: true,
		filters: initialState.filters,
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.setRepositoryGroupBy, (state, action) => ({
		...state,
		list: action.payload,
		isSelectMode: initialState.isSelectMode,
		selectedFileIds: initialState.selectedFileIds,
		selectedFolderIds: initialState.selectedFolderIds,
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.setRepositoryPageIndex, (state, action) => ({
		...state,
		page: action.payload,
	}))
	.handleAction(actions.setRepositoryGridSize, (state, action) => ({
		...state,
		gridSize: action.payload,
		limit: action.payload * 4,
	}))
	.handleAction(actions.setRepositoryViewType, (state, action) => ({
		...state,
		viewType: action.payload,
	}))
	.handleAction(actions.setRepositorySortBy, (state, action) => ({
		...state,
		sortBy: action.payload,
	}))
	.handleAction(actions.setRepositorySortType, (state, action) => ({
		...state,
		sortType: action.payload,
	}))
	.handleAction(actions.setRepositoryFolders, (state, action) => ({
		...state,
		folders: action.payload,
	}))
	.handleAction(actions.setRepositoryFiles, (state, action) => ({
		...state,
		files: action.payload,
	}))
	.handleAction(actions.setRepositoryFileSections, (state, action) => ({
		...state,
		fileSections: action.payload,
	}))

	// DATA SELECTION
	.handleAction(actions.setSelectedRepositoryFile, (state, action) => ({
		...state,
		selectedFile: action.payload,
	}))
	.handleAction(actions.setSelectedRepositoryFileIds, (state, action) => ({
		...state,
		selectedFileIds: action.payload,
	}))
	.handleAction(actions.setTotalSelectedRepositoryFiles, (state, action) => ({
		...state,
		totalSelectedItems: action.payload,
	}))
	.handleAction(actions.setTotalSelectedFilesSize, (state, action) => ({
		...state,
		totalSelectedFilesSize: action.payload,
	}))

	.handleAction(actions.setSelectedRepositoryFolder, (state, action) => ({
		...state,
		selectedFolder: action.payload,
	}))
	.handleAction(actions.setSelectedRepositoryFolderIds, (state, action) => ({
		...state,
		selectedFolderIds: action.payload,
	}))

	.handleAction(actions.setSelectedRepositoryDates, (state, action) => ({
		...state,
		selectedDates: action.payload,
	}))
	.handleAction(actions.setRepositoryFolderHierarchy, (state, action) => ({
		...state,
		folderHierarchy: action.payload,
	}))

	// MODAL CONTROL
	.handleAction(actions.toggleRepositoryFilter, (state, action) => ({
		...state,
		isFilterOpen: action.payload,
	}))
	.handleAction(actions.toggleRepositorySelectMode, (state, action) => ({
		...state,
		isSelectMode: action.payload,
		selectedFileIds: initialState.selectedFileIds,
		selectedFolderIds: initialState.selectedFolderIds,
		totalSelectedItems: initialState.totalSelectedItems,
		totalSelectedFilesSize: initialState.totalSelectedFilesSize,
		selectedDates: initialState.selectedDates,
	}))
	.handleAction(actions.toggleRepositoryBulkOperation, (state, action) => ({
		...state,
		isBulkOperation: action.payload,
	}))
	.handleAction(actions.toggleRepositoryFilePreviewModal, (state, action) => ({
		...state,
		isFilePreviewModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryNewFolderModal, (state, action) => ({
		...state,
		isNewFolderModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryTrashModal, (state, action) => ({
		...state,
		isTrashModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryRenameFolderModal, (state, action) => ({
		...state,
		isRenameModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryDeleteForeverModal, (state, action) => ({
		...state,
		isDeleteForeverModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryEmptyTrashModal, (state, action) => ({
		...state,
		isEmptyTrashModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryRestoreModal, (state, action) => ({
		...state,
		isRestoreModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryShareModal, (state, action) => ({
		...state,
		isShareModalVisible: action.payload,
	}))
	.handleAction(actions.toggleMoveToFolderModal, (state, action) => ({
		...state,
		isMovetoFolderModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryStarModal, (state, action) => ({
		...state,
		isStarModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryUploadFileModal, (state, action) => ({
		...state,
		isUploadFileModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryDownloadErrorModal, (state, action) => ({
		...state,
		isDownloadErrorModalVisible: action.payload,
	}))
	.handleAction(actions.toggleRepositoryDownloadInitializationModal, (state, action) => ({
		...state,
		isDownloadInitializationModalVisible: action.payload,
	}))
	.handleAction(actions.setRepositoryDownloadProgressModalConfig, (state, action) => ({
		...state,
		downloadProgressModalConfig: action.payload,
	}))

	// DATA FETCH
	.handleAction(actions.fetchRepository.request, (state, action) => ({
		...state,
		error: initialState.error,
		isLoading: action.payload.loadMore ? false : true,
		isLoadingNextPage: action.payload.loadMore ? true : false,
		page: action.payload.loadMore ? Number(state.page) + 1 : initialState.page,
	}))
	.handleAction(actions.fetchRepository.success, (state, action) => ({
		...state,
		...action.payload,
		error: initialState.error,
		isLoading: false,
		canLoadMore: (action.payload?.page || 0) < (action.payload.totalPages || 0),
	}))
	.handleAction(actions.fetchRepository.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.searchRepository.request, (state) => ({
		...state,
		error: initialState.error,
		isLoadingSearch: true,
	}))
	.handleAction(actions.searchRepository.success, (state, action) => ({
		...state,
		searchData: action.payload,
		isLoadingSearch: false,
	}))
	.handleAction(actions.searchRepository.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingSearch: false,
	}));
