import { TEMP_UPDATE_PRODUCT } from './type';
import { CompetitorProduct, Product } from 'nimbly-common';

export const SET_LOADING = '@product/SET_LOADING';
export const SET_SORT = '@product/SET_SORT';
export const SET_FILTER_QUERY = '@product/SET_FILTER_QUERY';
export const SET_PAGE_INDEX = '@product/SET_PAGE_INDEX';

export const FETCH_PRODUCTS_REQUEST = '@product/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = '@product/FETCH_PRODUCTS_LOADING';
export const FETCH_PRODUCTS_FAILURE = '@product/FETCH_PRODUCTS_FAILURE';

export type FETCH_ProductsResponse = {
  message: string;
  error: string;
  data: CompetitorProduct[];
};

export const CREATE_PRODUCTS_REQUEST = '@product/CREATE_PRODUCTS_REQUEST';
export const CREATE_PRODUCTS_SUCCESS = '@product/CREATE_PRODUCTS_SUCCESS';
export const CREATE_PRODUCTS_FAILURE = '@product/CREATE_PRODUCTS_FAILURE';

export type POST_ProductsPayload = {
  product: Partial<TEMP_UPDATE_PRODUCT>;
  imgFile: File | undefined;
};
export type POST_ProductsResponse = {
  message: string;
  error: string;
  data: Product;
};

export const CREATE_PRODUCTS_BULK_REQUEST = '@product/CREATE_PRODUCTS_BULK_REQUEST';
export const CREATE_PRODUCTS_BULK_SUCCESS = '@product/CREATE_PRODUCTS_BULK_SUCCESS';
export const CREATE_PRODUCTS_BULK_FAILURE = '@product/CREATE_PRODUCTS_BULK_FAILURE';

export type POST_ProductsBulkPayload = {
  companyID: string;
  products: Partial<TEMP_UPDATE_PRODUCT>[];
};

export const FETCH_PRODUCT_DETAIL_REQUEST = '@product/FETCH_PRODUCT_DETAIL_REQUEST';
export const FETCH_PRODUCT_DETAIL_SUCCESS = '@product/FETCH_PRODUCT_DETAIL_SUCCESS';
export const FETCH_PRODUCT_DETAIL_FAILURE = '@product/FETCH_PRODUCT_DETAIL_FAILURE';

export type FETCH_ProductDetailResponse = {
  message: string;
  error: string;
  data: Product;
};

export const UPDATE_PRODUCT_REQUEST = '@product/UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = '@product/UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = '@product/UPDATE_PRODUCT_FAILURE';

export type PUT_ProductsPayload = {
  product: Partial<TEMP_UPDATE_PRODUCT>;
  imgFile: File | undefined;
};
export type PUT_ProductsResponse = {
  message: string;
  error: string;
  data: Product;
};

export const DELETE_PRODUCT_REQUEST = '@product/DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = '@product/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = '@product/DELETE_PRODUCT_FAILURE';

export const FETCH_PRODUCT_CATEGORY_REQUEST = '@product/FETCH_PRODUCT_CATEGORY_REQUEST';
export const FETCH_PRODUCT_CATEGORY_SUCCESS = '@product/FETCH_PRODUCT_CATEGORY_LOADING';
export const FETCH_PRODUCT_CATEGORY_FAILURE = '@product/FETCH_PRODUCT_CATEGORY_FAILURE';

export const FETCH_PRODUCT_TARGET_MARKET_REQUEST = '@product/FETCH_PRODUCT_TARGET_MARKET_REQUEST';
export const FETCH_PRODUCT_TARGET_MARKET_SUCCESS = '@product/FETCH_PRODUCT_TARGET_MARKET_SUCCESS';
export const FETCH_PRODUCT_TARGET_MARKET_FAILURE = '@product/FETCH_PRODUCT_TARGET_MARKET_FAILURE';

export const FETCH_PRODUCTS_PAGINATION_REQUEST = '@product/FETCH_PRODUCTS_PAGINATION_REQUEST';
export const FETCH_PRODUCTS_PAGINATION_SUCCESS = '@product/FETCH_PRODUCTS_PAGINATION_SUCCESS';
export const FETCH_PRODUCTS_PAGINATION_FAILURE = '@product/FETCH_PRODUCTS_PAGINATION_FAILURE';
export const FLUSH_PRODUCT_STATE = '@product/FLUSH_PRODUCT_STATE';
