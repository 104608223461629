import { LMSUserResponse } from '@nimbly-technologies/nimbly-common';
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchLmsUserById } from 'services/lms/lmsUserManagement.service';
import * as actions from './lmsUserManagementUserById.action';

function* fetchLmsUserByIdSaga(action: ReturnType<typeof actions.fetchLmsUserByIdAsync.request>): Generator {
	try {
		const activity = (yield call(fetchLmsUserById, action.payload.userID) as unknown) as LMSUserResponse;

		yield put(actions.fetchLmsUserByIdAsync.success(activity));
	} catch {
		yield put(actions.fetchLmsUserByIdAsync.failure('Failed to get categories'));
	}
}

export default function* lmsUserManagementUserSaga() {
	yield takeEvery(actions.fetchLmsUserByIdAsync.request, fetchLmsUserByIdSaga);
}
