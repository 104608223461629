import { SelectedFilters } from 'components/lms/userManagement/UserListTableHeader';
import { action, createAsyncAction } from 'typesafe-actions';
import { SortDirection, UserGroupPopulatedResponse } from './type';
import * as types from './userGroupList.actionTypes';
import { PaginationResult } from '@nimbly-technologies/nimbly-common';

export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });
export const setSelectedUserGroupCount = (value: number) => action(types.SET_SELECTED_USER_GROUP_COUNT, { value });
export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });
export const setUserGroupListFilters = (filters: SelectedFilters) =>
	action(types.SET_USER_GROUP_LIST_FILTER_QUERY, { filters });
export const setUserGroupListPageIndex = (pageIndex: number) =>
	action(types.SET_USER_GROUP_LIST_PAGE_INDEX, { pageIndex });
export const setUserGroupListSearchQuery = (search: string) =>
	action(types.SET_USER_GROUP_LIST_SEARCH_QUERY, { search });
export const setUserGroupListSort = (sortBy: string, sortDirection: SortDirection) =>
	action(types.SET_USER_GROUP_LIST_SORT, { sortBy, sortDirection });
export const resetUserGroupListFilter = () => action(types.RESET_USER_GROUP_FILTER, {});
export const getUserGroupList = createAsyncAction(
	types.GET_USER_GROUP_LIST_REQUEST,
	types.GET_USER_GROUP_LIST_SUCCESS,
	types.GET_USER_GROUP_LIST_FAILURE,
)<{ limit?: number }, PaginationResult<UserGroupPopulatedResponse>, { error: string }>();
