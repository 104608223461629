export const SET_COURSES_AND_SYLLABUSES_AVAILABLE_SEARCH = '@coursesSyllabuses/SET_AVAILABLE_SEARCH';
export const SET_COURSES_AND_SYLLABUSES_AVAILABLE_SORT = '@coursesSyllabuses/SET_AVAILABLE_SORT';
export const SET_COURSES_AND_SYLLABUSES_AVAILABLE_FILTERS = '@coursesSyllabuses/SET_AVAILABLE_FILTERS';
export const RESET_COURSES_AND_SYLLABUSES_AVAILABLE_FILTERS = '@coursesSyllabuses/RESET_AVAILABLE_FILTERS';

export const FETCH_LMS_COURSES_SYLLABUSES_AVAILABLE_REQUEST = '@coursesSyllabuses/FETCH_AVAILABLE_REQUEST';
export const FETCH_LMS_COURSES_SYLLABUSES_AVAILABLE_SUCCESS = '@coursesSyllabuses/FETCH_AVAILABLE_SUCCESS';
export const FETCH_LMS_COURSES_SYLLABUSES_AVAILABLE_FAILURE = '@coursesSyllabuses/FETCH_AVAILABLE_FAILURE';

export const FETCH_LMS_COURSES_SYLLABUSES_IN_PROGRESS_REQUEST = '@coursesSyllabuses/FETCH_IN_PROGRESS_REQUEST';
export const FETCH_LMS_COURSES_SYLLABUSES_IN_PROGRESS_SUCCESS = '@coursesSyllabuses/FETCH_IN_PROGRESS_SUCCESS';
export const FETCH_LMS_COURSES_SYLLABUSES_IN_PROGRESS_FAILURE = '@coursesSyllabuses/FETCH_IN_PROGRESS_FAILURE';

export const FETCH_LMS_COURSES_SYLLABUSES_RECENTLY_VIEWED_REQUEST = '@coursesSyllabuses/FETCH_RECENTLY_VIEWED_REQUEST';
export const FETCH_LMS_COURSES_SYLLABUSES_RECENTLY_VIEWED_SUCCESS = '@coursesSyllabuses/FETCH_RECENTLY_VIEWED_SUCCESS';
export const FETCH_LMS_COURSES_SYLLABUSES_RECENTLY_VIEWED_FAILURE = '@coursesSyllabuses/FETCH_RECENTLY_VIEWED_FAILURE';

export const ENROLL_LMS_COURSES_SYLLABUSES_REQUEST = '@coursesSyllabuses/ENROLL_REQUEST';
export const ENROLL_LMS_COURSES_SYLLABUSES_SUCCESS = '@coursesSyllabuses/ENROLL_SUCCESS';
export const ENROLL_LMS_COURSES_SYLLABUSES_FAILURE = '@coursesSyllabuses/ENROLL_FAILURE';

export const FETCH_LMS_MY_COURSES_SYLLABUSES_REQUEST = '@coursesSyllabuses/FETCH_LMS_MY_COURSES_SYLLABUSES_REQUEST';
export const FETCH_LMS_MY_COURSES_SYLLABUSES_SUCCESS = '@coursesSyllabuses/FETCH_LMS_MY_COURSES_SYLLABUSES_SUCCESS';
export const FETCH_LMS_MY_COURSES_SYLLABUSES_FAILURE = '@coursesSyllabuses/FETCH_LMS_MY_COURSES_SYLLABUSES_FAILURE';
export const SET_MY_COURSES_AND_SYLLABUSES_SEARCH = '@coursesSyllabuses/SET_MY_COURSES_AND_SYLLABUSES_SEARCH';

export const FETCH_LMS_INDICATOR_REQUEST = '@dashboard/FETCH_LMS_INDICATOR_REQUEST';
export const FETCH_LMS_INDICATOR_SUCCESS = '@dashboard/FETCH_LMS_INDICATOR_SUCCESS';
export const FETCH_LMS_INDICATOR_FAILURE = '@dashboard/FETCH_LMS_INDICATOR_FAILURE';

export const SET_MY_COURSES_AND_SYLLABUSES_TAB = '@coursesSyllabuses/SET_MY_COURSES_AND_SYLLABUSES_TAB';
export const SET_LMS_LEARNER_COURSEID = 'coursesSyllabuses/SET_LMS_LEARNER_COURSEID';
