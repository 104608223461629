import {
	DateCustomV2,
	Department,
	ErrSchedule,
	Questionnaire,
	ScheduleActionResponse,
	Site,
	SiteScheduleIndex,
} from '@nimbly-technologies/nimbly-common';
import i18n from 'i18n';
import moment from 'moment-timezone';
import { getFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import { all, call, delay, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { apiURL } from 'config/baseURL';
import { DEFAULT_TIMEZONE } from 'constants/Constants';
import API from 'helpers/api';
import * as actions from 'reducers/site/siteSchedule/siteSchedule.action';
import { QuestionnaireScheduleError, UpdatedSchedule } from 'reducers/site/siteSchedule/siteSchedule.reducer';
import ScheduleAPI from 'services/schedule.api';
import SiteAPI from 'services/site.api';
import { RootState } from 'store/rootReducers';
import Monitoring from 'utils/monitoring/Monitoring';
import legacySaveMultiSiteSchedule from './utils/legacySaveMultiSiteSchedule';

// SELECTORS
const selectProfile = (state: RootState) => state.firebase.profile;
const departmentsSelector = (state: RootState) => state.departments.departments;
const selectDepartmentIndexes = (state: RootState) => state.departments.departmentsIndex;
const selectSite = (state: RootState) => state.site.siteDetails.value;
const selectSchedules = (state: RootState) => state.site.siteSchedule.siteScheduleIndexes || [];
const questionnairesSelector = (state: RootState) => state.questionnaire.data;
const selectAuth = (state: RootState) => state.firebase.auth;
const selectFilter = (state: RootState) => state.site.siteSchedule.filter;
const selectOrgSettings = (state: RootState) => state.organization.organization;

function* fetchSiteSchedules({ payload }: ReturnType<typeof actions.fetchSiteSchedules.request>) {
	const token: string = yield call(API.getFirebaseToken);
	const { siteID, status = 'active' } = payload;
	const site: Site = yield select(selectSite);

	try {
		if (token === '' && siteID === '') {
			yield call(toast.error, i18n.t('error.common.invalidRequest'));
			yield put(actions.fetchSiteSchedules.failure({ error: 'invalid request' }));
			return;
		}

		const url = `${apiURL}/schedules/sites/${siteID}?status=${status}`;
		const response: Response = yield call(API.get, url, token);
		const contentType = response.headers.get('content-type');
		if (!contentType || !contentType.includes('application/json')) {
			if (process.env.NODE_ENV !== 'production') {
				console.warn(`Response return with status ${response.status}`);
			}
			yield put(actions.fetchSiteSchedules.failure({ error: 'internal' }));
			return;
		}

		let json: { message: ErrSchedule; data?: SiteScheduleIndex[] } = yield call(response.json.bind(response));

		if (response.status >= 400) {
			const message: ErrSchedule = json.message;
			yield put(actions.fetchSiteSchedules.failure({ error: message }));

			return;
		}

		const today = moment().format('YYYY-MM-DD');

		const myConvertedDate = (myDate: Date, timezone: any) => {
			const dateTimeString = moment.tz(myDate, timezone).format('YYYY-MM-DD HH:mm').toString();
			const [dateString, timeString] = dateTimeString.split(' ');
			const [year, month, day] = dateString.split('-');
			const [hours, minutes] = timeString.split(':');

			const newDate = new Date(
				parseInt(year, 10),
				parseInt(month, 10) - 1,
				parseInt(day, 10),
				parseInt(hours, 10),
				parseInt(minutes, 10),
			);

			return newDate;
		};

		const convertCustomDates = (customDates: DateCustomV2[], timezone: any) => {
			let convertedCustomDates: DateCustomV2[] = [];
			for (const customDate of customDates) {
				if (customDate?.startDate && customDate?.endDate) {
					convertedCustomDates.push({
						customID: customDate?.customID,
						startDate: myConvertedDate(customDate.startDate, timezone),
						endDate: myConvertedDate(customDate.endDate, timezone),
					});
				} else {
					convertedCustomDates.push(customDate);
				}
			}

			return convertedCustomDates || [];
		};

		// convert back any date field
		const schedules: SiteScheduleIndex[] = (json.data as SiteScheduleIndex[])?.map((sch) => ({
			...sch,
			firstScheduleStart: sch.firstScheduleStart ? myConvertedDate(sch.firstScheduleStart, sch.timezone) : null,
			firstScheduleEnd: sch.firstScheduleEnd ? myConvertedDate(sch.firstScheduleEnd, sch.timezone) : null,
			datesCustomV2: sch.datesCustomV2 ? convertCustomDates(sch.datesCustomV2, sch.timezone) : [],
			startAt: sch.startAt ? new Date(sch.startAt) : null,
			endAt: sch.endAt ? new Date(sch.endAt) : null,
			createdAt: new Date(sch.createdAt),
			updatedAt: new Date(sch.updatedAt),
		}));

		yield put(actions.fetchSiteSchedules.success({ schedules: schedules || [] }));

		if (site.isMultiSite) {
			const teamScheduleIndex = schedules.findIndex((sch) => !sch.questionnaireIndexID);
			if (teamScheduleIndex >= 0) {
				yield put(actions.selectSchedule({ indexOrigin: teamScheduleIndex, schedule: schedules[teamScheduleIndex] }));
			}
		}
	} catch (error) {
		yield call(toast.error, i18n.t('error.siteSchedulePage.fetchError'));

		yield put(actions.fetchSiteSchedules.failure({ error: error.message }));
	}
}

/**
 * add new single audit schedule
 */
function* addNewSchedule({ payload }: ReturnType<typeof actions.addNewSchedule.request>) {
	const departmentIndexes: ReturnType<typeof selectDepartmentIndexes> = yield select(selectDepartmentIndexes);
	const site: ReturnType<typeof selectSite> = yield select(selectSite);
	const user: ReturnType<typeof selectProfile> = yield select(selectProfile);
	const auth: ReturnType<typeof selectAuth> = yield select(selectAuth);
	const orgSetting: ReturnType<typeof selectOrgSettings> = yield select(selectOrgSettings);

	const { siteID, departmentID, questionnaireIndexID } = payload;
	const departmentIndex = departmentIndexes?.[departmentID] || null;

	// if (!departmentIndex) {
	// 	yield put(actions.addNewSchedule.failure({ error: ErrSchedule.NO_DEPARTMENT }));
	// 	return;
	// }

	if (!site) {
		yield put(actions.addNewSchedule.failure({ error: ErrSchedule.INVALID_SITE }));
		return;
	}

	// add auditors from site's team which belongs to assigned schedule department
	const usersSet = new Set<string>();
	if (departmentIndex?.users) {
		for (const { uid } of departmentIndex.users || {}) {
			usersSet.add(uid);
		}
	}

	const team: string[] = site.team || [];
	const filteredTeam = team.filter((uid) => usersSet.has(uid)) || [];

	// if (filteredTeam.length === 0) {
	// 	const message = i18n.t('error.siteSchedulePage.department.noUser');
	// 	yield call(toast.warn, message);
	// }

	const now = new Date();

	const schedule = new SiteScheduleIndex({
		siteID,
		departmentID: departmentID || '',
		questionnaireIndexID,
		questionnaires: [questionnaireIndexID],
		auditors: filteredTeam || [],
		organizationID: user.organization,
		createdBy: auth.uid,
		updatedBy: auth.uid,
		createdAt: now,
		updatedAt: now,
		timezone: site.timezone || '',
		// @ts-expect-error other initialization handled by BE
		scheduleActivePeriod: orgSetting?.useScheduleActivePeriod
			? {
					periodLength: orgSetting.scheduleActivePeriodLength,
					periodUnit: orgSetting.scheduleActivePeriodUnit,
			  }
			: null,
	});

	yield put(actions.addNewSchedule.success(schedule));
}

function constructErrorMessage(questionnaireTitle: string, departmentName: string): string {
	let str = '';

	if (questionnaireTitle) {
		str += `${i18n.t('label.questionnaire')}: ${questionnaireTitle},\n`;
	}

	if (departmentName) {
		str += `${i18n.t('label.department')}: ${departmentName} `;
	}

	return str;
}

function handleUploadError(
	responses: ScheduleActionResponse[],
	departments: { [id: string]: Department },
	questionnaires: { [id: string]: Questionnaire },
) {
	const allErrors = (responses || []).reduce((acc, res, i) => {
		if (res.errors.length === 0) {
			return acc;
		}

		return acc.concat({
			indexOrigin: i,
			errors: res.errors,
			schedule: res.schedules[0],
		});
	}, [] as Array<{ indexOrigin: number; schedule: SiteScheduleIndex; errors: ErrSchedule[] }>);

	const errorState: { [index: number]: QuestionnaireScheduleError } = {};
	for (const err of allErrors) {
		const schedule = err.schedule;
		if (!schedule) {
			continue;
		}

		const department = departments?.[schedule.departmentID];
		const questionnaire = questionnaires?.[schedule.questionnaireIndexID];

		const errorSchedule: QuestionnaireScheduleError = {};

		for (const errorStr of err.errors) {
			// TODO: Handle other error enum,
			const baseMessage = `${constructErrorMessage(questionnaire?.title, department?.name)} - `;
			switch (errorStr) {
				case ErrSchedule.NO_ASSIGNED_AUDITOR:
					errorSchedule.assignedAuditor = true;
					toast.error(baseMessage + i18n.t('error.siteSchedulePage.validation.noUser'));
					break;

				case ErrSchedule.MISMATCH_DEPARTMENT_USER:
					errorSchedule.assignedAuditor = true;
					toast.error(baseMessage + i18n.t('error.siteSchedulePage.department.noUser'));
					break;

				default:
					toast.error(baseMessage + (errorStr || i18n.t('error.siteSchedulePage.general')));
					break;
			}

			errorState[err.indexOrigin] = errorSchedule;
		}
	}

	return { errors: allErrors, errorState };
}

function* uploadSiteSchedules({ payload }: ReturnType<typeof actions.uploadSiteSchedules.request>) {
	const { schedules, siteID } = payload;

	const filter: ReturnType<typeof selectFilter> = yield select(selectFilter);

	const url = `${apiURL}/schedules/sites/${siteID}/v2`;
	const departments: { [key: string]: Department } = yield select(departmentsSelector);
	const questionnaires: { [key: string]: Questionnaire } = yield select(questionnairesSelector);
	const site: ReturnType<typeof selectSite> = yield select(selectSite);

	const myConvertedDate = (myDate: Date, timezone: any) => {
		const dateTimeString = moment(myDate).format('YYYY-MM-DD HH:mm').toString();
		const [dateString, timeString] = dateTimeString.split(' ');
		const [year, month, day] = dateString.split('-');
		const [hours, minutes] = timeString.split(':');
		const compiledDateString = `${parseInt(year, 10)}-${parseInt(month, 10)}-${parseInt(day, 10)} ${parseInt(
			hours,
			10,
		)}:${parseInt(minutes, 10)}`;
		return moment.tz(compiledDateString, 'YYYY-M-D H:m', timezone).toDate();
	};

	const sanitizedSchedules = schedules.map((schedule) => {
		const timezone = schedule.timezone || site?.timezone || DEFAULT_TIMEZONE;
		return {
			...schedule,
			datesCustomV2: (schedule.datesCustomV2 || []).map((dateCustomV2) => {
				return {
					...dateCustomV2,
					startDate: dateCustomV2.startDate ? myConvertedDate(dateCustomV2.startDate, timezone) : null,
					endDate: dateCustomV2.endDate ? myConvertedDate(dateCustomV2.endDate, timezone) : null,
				};
			}),
		};
	});

	try {
		const token: string = yield call(API.getFirebaseToken);

		const response: Response = yield call(API.put, url, token, { schedules: sanitizedSchedules });

		const contentType = response.headers.get('content-type');

		// #region response no JSON
		if (!contentType || !contentType.includes('application/json') || response.status >= 500) {
			if (process.env.NODE_ENV !== 'production') {
				console.warn(`Response return no JSON with status ${response.status}`);
			}

			yield call(toast.error, i18n.t('error.common.internal'));
			yield put(actions.setQuestionnaireScheduleErrors({}));
			yield put(actions.uploadSiteSchedules.failure({ errors: [] }));

			return;
		}
		// #endregion response no JSON

		const { data }: { data: ScheduleActionResponse[] } = yield call(response.json.bind(response));

		// #region response status >= 400
		if (response.status >= 400) {
			const allErrors = (data || []).reduce((acc, res, i) => {
				if (res.errors.length === 0) {
					return acc;
				}

				return acc.concat({
					indexOrigin: i,
					errors: res.errors,
					schedule: res.schedules[0],
				});
			}, [] as Array<{ indexOrigin: number; schedule: SiteScheduleIndex; errors: ErrSchedule[] }>);

			const errorState: { [index: number]: QuestionnaireScheduleError } = {};
			for (const err of allErrors) {
				const schedule = err.schedule;
				if (!schedule) {
					continue;
				}

				const department = departments?.[schedule.departmentID];
				const questionnaire = questionnaires?.[schedule.questionnaireIndexID];

				const errorSchedule: QuestionnaireScheduleError = {};

				for (const errorStr of err.errors) {
					// TODO: Handle other error enum,
					const baseMessage = `${constructErrorMessage(questionnaire?.title, department?.name)} - `;
					switch (errorStr) {
						case ErrSchedule.NO_ASSIGNED_AUDITOR:
							errorSchedule.assignedAuditor = true;
							yield call(toast.error, baseMessage + i18n.t('error.siteSchedulePage.validation.noUser'));
							break;

						case ErrSchedule.MISMATCH_DEPARTMENT_USER:
							errorSchedule.assignedAuditor = true;
							yield call(toast.error, baseMessage + i18n.t('error.siteSchedulePage.department.noUser'));
							break;

						default:
							yield call(toast.error, baseMessage + (errorStr || i18n.t('error.siteSchedulePage.general')));
							break;
					}

					errorState[err.indexOrigin] = errorSchedule;
				}
			}

			yield put(actions.setQuestionnaireScheduleErrors(errorState));
			const errorMessages = allErrors.reduce((acc, err) => acc.concat(err.errors), [] as ErrSchedule[]);
			yield put(actions.uploadSiteSchedules.failure({ errors: errorMessages }));

			// re-fetch schedules
			yield put(actions.fetchSiteSchedules.request({ siteID, status: filter.status }));

			return;
		}
		// #endregion response status >= 400

		// #region success

		yield put(actions.setHasChanges(false));

		// payload to be used for zapier hook
		const updatedSchedules: UpdatedSchedule[] = data.map((res) => {
			const syncSchedule = {
				newID: res.newID,
				originID: res.originID,
			};

			if (res.schedules?.length > 0) {
				const currentSyncSchedule = res.schedules[0];
				Object.assign(syncSchedule, {
					schedule: currentSyncSchedule,
				});
			}

			return syncSchedule;
		});

		try {
			const profile: ReturnType<typeof selectProfile> = yield select(selectProfile);
			const auth: ReturnType<typeof selectAuth> = yield select(selectAuth);
			const firebase = getFirebase();
			const siteScheduleHistoryRef = `siteScheduleHistory/${profile.organization}/${siteID}`;

			yield firebase.database!().ref(siteScheduleHistoryRef).push({
				updatedBy: auth.uid,
				updatedAt: moment().toISOString(),
			});
		} catch (error) {}

		const successMessage = i18n.t('addOn.site.updateSaved');
		yield delay(3000);
		yield call(actions.fetchSiteSchedules.request, { siteID, status: filter.status });
		yield call(toast.success, successMessage);

		yield put(actions.uploadSiteSchedules.success({ updatedSchedules }));

		yield put(actions.fetchSiteSchedules.request({ siteID, status: filter.status }));
		// #endregion success
		return;
	} catch (error) {
		yield call(toast.error, i18n.t('error.siteSchedulePage.general'));
		yield put(actions.uploadSiteSchedules.failure({ errors: [error.message] }));
	}
}

function* uploadTeamSiteSchedules({ payload }: ReturnType<typeof actions.uploadTeamSiteSchedules.request>) {
	const { schedules, siteID } = payload;

	const departments: { [key: string]: Department } = yield select(departmentsSelector);
	const questionnaires: { [key: string]: Questionnaire } = yield select(questionnairesSelector);
	const site: Site = yield select((state: RootState) => state.site.siteDetails.value);
	const profile: { organization: string } = yield select((state: RootState) => state.firebase.profile);

	// update site value
	site.siteID = payload.siteID;

	let errorMsg = '';

	try {
		yield call(SiteAPI.updateSite, site);
	} catch (err) {
		Monitoring.logEvent('uploadTeamSiteSchedules.updateSite', err);
		errorMsg = i18n.t('error.siteSchedulePage.general');
		yield call(toast.error, errorMsg);
		yield put(actions.uploadTeamSiteSchedules.failure({ errors: errorMsg }));
		return;
	}

	let uploadResponse: { data: ScheduleActionResponse[]; success: boolean } = { data: [], success: false };
	try {
		uploadResponse = yield call(ScheduleAPI.uploadSiteSchedules, siteID, schedules);
	} catch (error) {
		Monitoring.logEvent('uploadTeamSiteSchedules.uploadSiteSchedules', error);
		errorMsg = i18n.t('error.siteSchedulePage.general');
		yield call(toast.error, errorMsg);
		yield put(actions.uploadTeamSiteSchedules.failure({ errors: errorMsg }));
		return;
	}

	if (!uploadResponse.success) {
		const { errorState, errors } = handleUploadError(uploadResponse.data, departments || {}, questionnaires || {});

		yield put(actions.setQuestionnaireScheduleErrors(errorState));
		yield put(actions.uploadTeamSiteSchedules.failure({ errors: errors[0]?.errors[0] || '' }));

		return;
	}

	yield put(actions.setHasChanges(false));

	// payload to be used for zapier hook
	const updatedSchedules: UpdatedSchedule[] = uploadResponse.data.map((res) => {
		const syncSchedule = {
			newID: res.newID,
			originID: res.originID,
		};

		if (res.schedules?.length > 0) {
			const currentSyncSchedule = res.schedules[0];
			Object.assign(syncSchedule, {
				schedule: currentSyncSchedule,
			});
		}

		return syncSchedule;
	});

	try {
		const profile: ReturnType<typeof selectProfile> = yield select(selectProfile);
		const auth: ReturnType<typeof selectAuth> = yield select(selectAuth);
		const firebase = getFirebase();
		const siteScheduleHistoryRef = `siteScheduleHistory/${profile.organization}/${siteID}`;

		yield firebase.database!().ref(siteScheduleHistoryRef).push({
			updatedBy: auth.uid,
			updatedAt: moment().toISOString(),
		});
	} catch (error) {}

	// TODO: Deprecate this try catch block
	try {
		const teamSchedules = schedules.filter((sch) => !sch.questionnaireIndexID && !sch.disabled);
		for (const sch of teamSchedules) {
			if (sch) {
				yield call(legacySaveMultiSiteSchedule, { site, schedule: sch, organizationID: profile.organization });
			}
		}
	} catch (error) {
		Monitoring.logEvent('uploadTeamSiteSchedules.legacySaveMultiSiteSchedule', error);
	}

	const successMessage = i18n.t('addOn.site.updateSaved');

	const filter: ReturnType<typeof selectFilter> = yield select(selectFilter);

	yield call(actions.fetchSiteSchedules.request, { siteID, status: filter.status });
	yield call(toast.success, successMessage);

	yield put(actions.uploadTeamSiteSchedules.success());
	yield put(actions.fetchSiteSchedules.request({ siteID, status: filter.status }));
	// #endregion success
	return;
}

function* syncSiteSchedulesCalendar({ payload }: ReturnType<typeof actions.syncSiteSchedulesCalendar.request>) {
	const { updatedSchedules } = payload;
	const url = `${apiURL}/schedules/hooks/subscription`;

	try {
		const token: string = yield call(API.getFirebaseToken);
		const syncSchedules = updatedSchedules.filter(({ schedule }) => schedule!.withCalendarSync);

		// directly finish process when no schedules needed to be synched
		if (syncSchedules.length === 0) {
			yield put(actions.syncSiteSchedulesCalendar.success({}));
		}

		const syncCalendarPromises = syncSchedules.map(({ originID, newID, schedule }) => {
			// TODO: hook trigger to delete existing google calendar
			// if (schedule.disabled) {
			//  return call(API.delete, deleteURL, token, schedule);
			// }
			// TODO: hook trigger to update existing google calendar
			// if (originID !== newID) {
			//   return call(API.update, updateURL, token, schedule);
			// }

			// hook trigger to create new google calendar
			return call(API.post, url, token, schedule);
		});

		try {
			// currently not set to listen to errors
			yield all(syncCalendarPromises);
			yield put(actions.syncSiteSchedulesCalendar.success({}));
		} catch (error) {
			console.error(error);
			yield put(actions.syncSiteSchedulesCalendar.failure({ error: error }));
		}
	} catch (err) {
		console.error('Failed to sync calendar', err);
		yield put(actions.syncSiteSchedulesCalendar.failure({ error: err }));
	}
}

function* importSchedules({ payload }: ReturnType<typeof actions.importSchedules.request>) {
	const existingSchedules: SiteScheduleIndex[] = yield select(selectSchedules);
	const departmentIndexes: ReturnType<typeof selectDepartmentIndexes> = yield select(selectDepartmentIndexes);
	const auth: ReturnType<typeof selectAuth> = yield select(selectAuth);

	const { schedules, activeDepartment, siteID } = payload;

	const m = moment();
	const today = m.format('YYYY-MM-DD');
	const date = m.toDate();

	// #region disable active schedule of selectedDepartment
	const endScheduleUpdates: Partial<SiteScheduleIndex> = {
		endDate: today,
		disabled: true,

		updatedBy: auth.uid,
		updatedAt: date,
		endAt: date,
	};
	const processedExistingSchedules = existingSchedules.map((sch) => {
		if (sch.siteID === siteID) {
			return { ...sch, ...endScheduleUpdates };
		}
		return { ...sch };
	});
	// #endregion

	const validQuestionnaire = departmentIndexes?.[activeDepartment]?.questionnaires
		?.filter((q) => !q.disabled)
		.map((q) => q.id);
	// #region filter only available questionnaire
	const importedSchedules = schedules
		// all questionnaire exist
		?.filter((sch) => sch.questionnaires.every((qID) => !!validQuestionnaire?.includes(qID)));
	// #endregion filter only available questionnaire

	const newSchedules = processedExistingSchedules.concat(schedules);
	yield put(actions.importSchedules.success({ schedules: newSchedules }));
}

export default function* siteSchedulesSaga() {
	yield takeLatest(actions.fetchSiteSchedules.request, fetchSiteSchedules);
	yield takeEvery(actions.addNewSchedule.request, addNewSchedule);
	yield takeLeading(actions.uploadSiteSchedules.request, uploadSiteSchedules);
	yield takeLeading(actions.uploadTeamSiteSchedules.request, uploadTeamSiteSchedules);
	yield takeLeading(actions.syncSiteSchedulesCalendar.request, syncSiteSchedulesCalendar);
	yield takeLeading(actions.importSchedules.request, importSchedules);
}
