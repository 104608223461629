// NOTE: Copy Chage Syllabus -> Course, Course -> Lesson
export enum LmsDataType {
	COURSE = 'course',
	SYLLABUS = 'syllabus',
	QUIZ = 'quiz',
}

export enum LmsViewType {
	TILE = 'tile',
	LIST = 'list',
}
