import { takeLatest } from 'redux-saga/effects';
import * as types from '../../reducers/stockAuditScheduler/stockAuditScheduler.actionTypes';
import { call, put, select } from 'typed-redux-saga';
import { getToken } from '../../reducers/api';
import { RootState } from '../../store/rootReducers';
import { StockAuditSchedulerState } from '../../reducers/stockAuditScheduler/type';
import { apiURL } from '../../config/baseURL';
import * as actions from '../../reducers/stockAuditScheduler/stockAuditScheduler.action';

export default function* stockAuditSchedulerSaga() {
	yield takeLatest(types.FETCH_SCHEDULES_REQUEST, fetchStockAuditSchedules);
	yield takeLatest(types.SET_SCHEDULE_PAGE_INDEX, fetchStockAuditSchedules);
	yield takeLatest(types.SET_SCHEDULE_SEARCH_QUERY, fetchStockAuditSchedules);
	yield takeLatest(types.SET_SCHEDULE_FILTER_QUERY, fetchStockAuditSchedules);
	yield takeLatest(types.SET_SCHEDULE_SORT, fetchStockAuditSchedules);
}

export function* fetchStockAuditSchedules({ payload }: ReturnType<typeof actions.fetchSchedulesAsync.request>) {
	try {
		const currentState: StockAuditSchedulerState = yield* select((state: RootState) => state.stockAuditScheduler);

		const authToken = yield* call(getToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const convertFiltersToArray = (type: string, filters: any[]) =>
			filters.reduce((prev: Array<string[]>, filter) => [...prev, [type, filter.value]], []);

		const filterQuery = [
			...convertFiltersToArray('scheduleTypes', currentState.filters.types),
			...convertFiltersToArray('siteIDs', currentState.filters.sites),
			...convertFiltersToArray('status', currentState.filters.status),
			...convertFiltersToArray('frequencies', currentState.filters.frequencies),
		];

		const query: Record<string, string> = {
			withDisabled: String(payload.withDisabled || false),
			page: String(currentState.pageIndex),
			limit: String(currentState.limit),
			sortFields: currentState.sortBy,
			sortDirections: currentState.isAscending ? 'asc' : 'desc',
			search: currentState.searchQuery,
		};

		const queryParams = new URLSearchParams([...Object.entries(query), ...filterQuery]);
		const fetchSchedulesURL = `${apiURL}/skus/schedules/search?${queryParams}`;
		const response = yield* call(fetch, fetchSchedulesURL, options);
		const responseJson = yield response.json();

		if (response?.status !== 200) {
			yield put(actions.fetchSchedulesAsync.failure({ error: responseJson.message }));
			return;
		}

		const data = responseJson.data;
		yield put(
			actions.fetchSchedulesAsync.success({
				schedules: data.docs,
				totalSchedules: data.totalDocs || 0,
				lastUpdatedAt: data.lastUpdatedAt,
			}),
		);
	} catch {
		yield put(actions.fetchSchedulesAsync.failure({ error: 'Failed to Fetch Schedules' }));
	}
}
