import styled, { css } from 'styled-components/macro';
import { ActiveStyle, DecorationStyle, DimensionStyle, DirectionStyle, SizeStyle, ThemeStyle } from './types';

/**
 * @deprecated Please use FilledButton
 */
export const Button = styled.button<SizeStyle & DirectionStyle & DimensionStyle & ThemeStyle & DecorationStyle>`
	box-sizing: border-box;

	height: ${(props) => (props.small ? '20px' : props.medium ? '30px' : '35px')};
	max-height: ${(props) => (props.small ? '20px' : props.medium ? '30px' : '35px')};
	font-size: ${(props) => (props.small ? '0.625rem' : props.medium ? '0.75rem' : '0.875rem')};
	font-weight: ${(props) => (props.bold ? '700' : 'normal')};

	line-height: ${(props) => (props.small ? '10px' : props.medium ? '18px' : '22px')};

	border-radius: 1.5rem;
	padding: 0 ${(props) => (props.small ? '10px' : props.medium ? '12px' : '1rem')};

	border: 2px solid #5049b2;
	background: ${(props) => (props.inversed ? 'transparent' : '#5049B2')};
	color: ${(props) => (props.inversed ? '#5049B2' : '#ffffff')};

	transition: 100ms all ease-in-out;

	cursor: pointer;

	${(props) =>
		props.width &&
		css`
			width: ${props.width};
		`}
	:hover {
		border: 2px solid #867ff8;
		background: ${(props) => (props.inversed ? '#fff' : '#867FF8')};
		color: ${(props) => (props.inversed ? '#3BD070' : '#ffffff')};
	}

	:disabled {
		cursor: not-allowed;
		background: #efeeed !important;
		color: #bcbccb;
		border: 2px solid #bcbccb;
	}
`;
/**
 * @deprecated Please use LinkButton
 */
export const ButtonLink = styled.button<SizeStyle & DirectionStyle & DimensionStyle & ThemeStyle>`
	padding: 0;
	border-radius: 1.5rem;
	box-sizing: border-box;
	background: transparent;
	border: 1px solid transparent;

	height: ${(props) => (props.small ? '20px' : props.medium ? '25px' : '35px')};
	max-height: ${(props) => (props.small ? '20px' : props.medium ? '25px' : '35px')};
	line-height: ${(props) => (props.small ? '10px' : props.medium ? '18px' : '22px')};

	display: flex;
	align-items: center;

	cursor: pointer;

	& > span {
		color: #5049b2;
		transition: 100ms all ease-in-out;
		font-size: ${(props) => (props.small ? '0.625rem' : props.medium ? '0.75rem' : '0.875rem')};
	}

	& > img {
		margin-right: 5px;
	}

	&:hover {
		& > span {
			color: #3bd070;
		}
	}

	&:disabled {
		cursor: not-allowed;

		& > span {
			color: #efeeed !important;
		}
	}
`;

export const ButtonWithIcon = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 1px solid ${(props) => (props.inversed ? '#11998E' : 'transparent')};

	transition: 100ms all ease-in-out;

	:hover {
		border: 1px solid ${(props) => (props.inversed ? '#3BD070' : 'transparent')};

		img {
			${(props) =>
				props.inversed ? 'filter: invert(0.32) sepia(1) saturate(4) hue-rotate(72deg) brightness(1);' : null};
			transition: 100ms all ease-in-out;
		}
	}

	span {
		color: inherit;
		background: inherit;
	}

	> :nth-child(2) {
		padding-left: 0.5rem;
		margin: 0;
	}
`;

export const ButtonWithIconNew = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 0;

	transition: 100ms all ease-in-out;

	span {
		color: ${(props) => (props.custom ? '#5049b2' : '#ffffff')};
	}

	&:hover {
		border: 0;

		img {
			transition: 100ms all ease-in-out;
		}
	}

	> :nth-child(2) {
		padding-left: 0.5rem;
		margin: 0;
	}
`;

export const ButtonDanger = styled(Button)`
	border: 1px solid #ed5565;
	background: ${(props) => (props.inversed ? 'transparent' : '#ED5565')};
	color: ${(props) => (props.inversed ? '#ED5565' : '#ffffff')};

	:hover {
		border: 1px solid #d14150;
		background: ${(props) => (props.inversed ? 'transparent' : '#d14150')};
		color: ${(props) => (props.inversed ? '#d14150' : '#ffffff')};
	}

	:disabled,
	:hover :disabled {
		border: 1px solid transparent;
		color: #bcbccb;
	}
`;

export const ButtonGray = styled(Button)`
	border: 1px solid #c4c4c4;
	background: ${(props) => (props.inversed ? '#ffffff' : '#C4C4C4')};
	color: ${(props) => (props.inversed ? '#C4C4C4' : '#ffffff')};

	:hover {
		border: 1px solid #b5b5b5;
		background: ${(props) => (props.inversed ? '#ffffff' : '#B5B5B5')};
		color: ${(props) => (props.inversed ? '#B5B5B5' : '#ffffff')};
	}

	:disabled {
		border: 1px solid transparent;
	}
`;

export const ButtonFilter = styled(Button)`
	border: ${(props) => (props.custom ? '0px' : '1px solid #574fcf')};
	background: ${(props) => (props.inversed ? '#C4C4C4' : 'transparent')};
	color: ${(props) => (props.inversed ? '#C4C4C4' : '#5049B2')};
	transition: 0.5s;

	:hover {
		border: 1px solid #ffffff;
		background: #ffffff;
		color: ${(props) => (props.inversed ? '#B5B5B5' : '#ffffff')};
		font-weight: bold;
	}

	:disabled {
		border: 1px solid transparent;
	}
`;

export const Label = styled.label<SizeStyle & DirectionStyle & ActiveStyle>`
	height: ${(props) => (props.small ? '20px' : props.medium ? '25px' : '35px')};
	font-size: ${(props) => (props.small ? '0.625rem' : props.medium ? '0.75rem' : '0.875rem')};

	border-radius: 1.5rem;
	padding: 0 1rem;

	border: 1px solid #11998e;
	background: ${(props) => (props.disabled ? '#efeeed !important' : props.inversed ? '#ffffff' : '#11998E')};
	color: ${(props) => (props.disabled ? '#bcbccb' : props.inversed ? '#11998E' : '#ffffff')};

	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	transition: 100ms all ease-in-out;

	:hover {
		border: 1px solid #3bd070;
		background: ${(props) => (props.inversed ? '#ffffff' : '#3BD070')};
		color: ${(props) => (props.inversed ? '#3BD070' : '#ffffff')};

		img {
			${(props) =>
				props.inversed ? 'filter: invert(0.32) sepia(1) saturate(4) hue-rotate(72deg) brightness(1);' : null};
			transition: 100ms all ease-in-out;
		}
	}
`;

export const LabelWithIcon = styled(Label)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	span {
		color: inherit;
		background: inherit;
	}

	> :nth-child(2) {
		padding-left: 0.5rem;
		margin: 0;
	}
`;

export const TertiaryButton = styled.button<SizeStyle & DecorationStyle & ThemeStyle>`
	height: 30px;
	padding: 0 1rem;
	max-height: ${(props) => (props.small ? '20px' : props.medium ? '25px' : '35px')};
	background: transparent;
	text-decoration: ${(props) => (props.underlined ? 'underline' : props.small ? 'none' : 'underline')};
	font-weight: ${(props) => (props.bold ? '700' : 'normal')};

	color: #5049b2;
	font-size: ${(props) => (props.small ? '0.625rem' : '0.75rem')};
	cursor: pointer;
	border-radius: 25px;

	:hover {
		color: #11998e;
	}

	:disabled {
		cursor: not-allowed;
		background: #efeeed !important;
		color: #bcbccb;
	}
`;

export const StyledLink = styled.a<SizeStyle>`
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 5px;
	height: 30px;
	background: transparent;
	padding: 0 0.5rem;

	color: #11998e;
	font-size: ${(props) => (props.small ? '0.625rem' : '0.75rem')};
	text-decoration: ${(props) => (props.small ? 'none' : 'underline')};

	cursor: pointer;
	transition: 100ms all ease-in-out;

	:hover {
		background: #f2f5f6;
	}

	:disabled {
		cursor: not-allowed;
		background: #efeeed !important;
		color: #bcbccb;
	}
`;

export const FilledButton = styled.button<SizeStyle & ThemeStyle & { customWidth?: string }>`
	${(props) => getFilledButtonSizeStyle(props)}
	${(props) => getFilledButtonThemeStyle(props)}

	width: ${(props) => props.customWidth || 'auto'};

  font-weight: 600;
	border-radius: 45px;
	border-width: 2px;
	border-style: solid;
	color: #ffffff;
	transition: 100ms all ease-in-out;
	cursor: pointer;

	:disabled {
		cursor: not-allowed;
		background: #efeeed;
		color: #c4c4c4;
		border-color: #efeeed;
	}
`;

const getFilledButtonSizeStyle = (props: SizeStyle) => {
	switch (true) {
		case props.large:
			return FilledButtonLargeStyles;
		case props.medium:
		default:
			return FilledButtonMediumStyles;
	}
};

const FilledButtonLargeStyles = css`
	font-size: 1rem;
	line-height: 1.6875rem;
	padding: 6px 16px 7px 16px;
`;

const FilledButtonMediumStyles = css`
	font-size: 0.875rem;
	line-height: 1.1875rem;
	padding: 4px 14px 5px 14px;
`;

const getFilledButtonThemeStyle = (props: ThemeStyle) => {
	switch (true) {
		case props.danger:
			return FilledButtonDangerStyles;
		case props.inverse:
			return FilledButtonInverseStyles;
		case props.primary:
		default:
			return FilledButtonPrimaryStyles;
	}
};

const FilledButtonPrimaryStyles = css`
	background: #574fcf;
	border-color: #574fcf;

	:hover,
	:focus {
		border-color: #7e78ea;
		background: #7e78ea;
	}
`;

const FilledButtonInverseStyles = css`
	color: #574fcf !important;
	border-color: #574fcf !important;
	background: #ffffff;

	:hover,
	:focus {
		color: #7e78ea !important;
		border-color: #7e78ea !important;
		background: #ffffff;
	}
`;

const FilledButtonDangerStyles = css`
	background: #ff354a;
	border-color: #ff354a;

	:hover,
	:focus {
		border-color: #ff667b;
		background: #ff667b;
	}
`;

export const OutlineButton = styled(FilledButton)`
	border: 2px solid #574fcf;
	background: transparent;
	color: #574fcf;

	&:hover,
	&:focus {
		border: 2px solid #867ff8;
		background: transparent;
		color: #867ff8;
	}

	:disabled {
		cursor: not-allowed;
		color: #c4c4c4;
		background: transparent;
		border: 2px solid #c4c4c4;
	}
`;

const getLinkButtonThemeStyle = (props: ThemeStyle) => {
	switch (true) {
		case props.danger:
			return LinkButtonDangerStyles;
		case props.secondary:
			return LinkButtonSecondaryStyles;
		case props.tertiary:
			return LinkButtonSecondaryStyles;
		case props.primary:
		default:
			return LinkButtonPrimaryStyles;
	}
};

const LinkButtonPrimaryStyles = css`
	color: #574fcf;

	:hover,
	:focus {
		color: #7e78ea;
	}
`;

const LinkButtonSecondaryStyles = css`
	color: #25282b !important;

	:hover,
	:focus {
		color: #535353 !important;
	}
`;

const LinkButtonTertiaryStyles = css`
	color: #25282b !important;

	:hover,
	:focus {
		color: #535353 !important;
	}
`;

const LinkButtonDangerStyles = css`
	color: #ff354a;

	:hover,
	:focus {
		color: #ff667b;
	}
`;

export const LinkButton = styled.button<SizeStyle & ThemeStyle>`
	display: inline-flex;
	justify-content: center;
	align-items: center;

	${(props) => getLinkButtonThemeStyle(props)};
	font-size: ${(props) => getLinkButtonFontSize(props)};
	line-height: ${(props) => getLinkButtonLineHeight(props)};
	padding: ${(props) => getLinkButtonSizePadding(props)};
	font-weight: 600;
	gap: 4px;

	&:disabled {
		cursor: not-allowed;
		color: #c4c4c4;
	}
`;

export const LinkButtonWithIcon = styled(LinkButton)<SizeStyle & ThemeStyle & { selected?: boolean }>`
	padding: 3.5px 9px 3.5px 4px;
	margin: 0;
	background-color: ${({ selected }) => (selected ? '#EAE9F9' : null)};
	border-radius: 22px;
	${(props) => getLinkButtonThemeStyle(props)};

	&:not([disabled]):hover,
	&:not([disabled]):focus {
		img.icon {
			filter: brightness(0) saturate(100%) invert(45%) sepia(86%) saturate(530%) hue-rotate(205deg) brightness(103%)
				contrast(94%);
		}
	}

	.icon {
		height: 25px;
		width: 25px;
	}

	img.icon {
		filter: brightness(0) saturate(100%) invert(29%) sepia(69%) saturate(1345%) hue-rotate(221deg) brightness(97%)
			contrast(95%);
	}

	&:disabled {
		cursor: not-allowed;
		color: #c4c4c4 !important;
	}
`;

const getLinkButtonSizePadding = (props: SizeStyle) => {
	switch (true) {
		case props.small:
			return '3px 4.5px';
		case props.large:
			return '6px 12px';
		case props.medium:
		default:
			return '6px 16px 7px 16px';
	}
};

const getLinkButtonFontSize = (props: SizeStyle) => {
	switch (true) {
		case props.small:
			return '0.75rem';
		case props.large:
			return '1rem';
		case props.xl:
			return '1.5rem';
		case props.medium:
		default:
			return '0.875rem';
	}
};

const getLinkButtonLineHeight = (props: SizeStyle) => {
	switch (true) {
		case props.small:
			return '1.125rem';
		case props.large:
			return '1.6875rem';
		case props.medium:
		default:
			return '1.1875rem';
	}
};
