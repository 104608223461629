import { action, createAsyncAction } from 'typesafe-actions';
import * as Common from 'nimbly-common';
import { Moment } from 'moment';

// utils
import * as types from './actionType';

// types
import { OrganizationActivityQuery } from 'constants/api/v1/types';
import { Option } from 'types/app';

/**
 * action reducer to sets in its UI dropdown
 * @param organizationKey
 */
export const setOrganization = (organizationKey: Option | null) =>
  action(types.SELECT_ORGANIZATION, { organizationKey });

/**
 * action reducer to set user in its UI dropdown
 * @param userKey
 */
export const setUserKey = (userKey: Option | null) => action(types.SELECT_USER, { userKey });

/**
 * action reducer to set activity type in its UI dropdown
 * @param activityType
 */
export const setActivityType = (activityType: Option | null) => action(types.SELECT_ACTIVITY_TYPE, { activityType });

/**
 * action reducer to set period type in its UI dropdown
 * @param periodType
 */
export const setPeriodType = (periodType: Common.enums.PeriodTypes) => action(types.SELECT_PERIOD_TYPE, { periodType });

/**
 * action to
 * @param dates { startDate: Moment | null, endDate: Moment | null }
 */
export const setDates = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) =>
  action(types.SET_DATES, { startDate, endDate });

/**
 * action to
 * @param dates { startDate: Moment | null, endDate: Moment | null }
 */
export const setPreviousDates = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) =>
  action(types.SET_PREVIOUS_DATES, { startDate, endDate });

/**
 * action to
 * @param dates { startDate: Moment | null, endDate: Moment | null }
 */
export const setCustomDates = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) =>
  action(types.SET_CUSTOM_DATES, { startDate, endDate });

/**
 * manages pageIndexing for pagination
 * @param total
 */
export const setTotalPages = (total: number | null) => action(types.SET_TOTAL_PAGES, { total });

/**
 * manages pageIndexing for pagination
 * @param total
 */
export const setTotalDocs = (total: number | null) => action(types.SET_TOTAL_DOCS, { total });

/**
 * passes in empty array to `activities.data` in redux state
 */
export const resetActivities = () => action(types.RESET_ORGANIZATION_ACTIVITIES);

/**
 * manages pageIndexing for pagination
 * @param pageIndex
 */
export const setPageIndex = (pageIndex: number) => action(types.SET_PAGE_INDEX, { pageIndex });

/**
 * initial api fetch to populate organization options
 */
export const fetchAllOrganizationAsync = createAsyncAction(
  types.FETCH_ALL_ORGANIZATIONS_REQUEST,
  types.FETCH_ALL_ORGANIZATIONS_SUCCESS,
  types.FETCH_ALL_ORGANIZATIONS_FAILURE
)<undefined, { options: Option[] }, { error: string }>();

/**
 * initial api fetch to populate user options once an organization is selected
 */
export const fetchOrganizationUsersAsync = createAsyncAction(
  types.FETCH_ORGANIZATION_USERS_REQUEST,
  types.FETCH_ORGANIZATION_USERS_SUCCESS,
  types.FETCH_ORGANIZATION_USERS_FAILURE
)<{ organizationID: string }, { options: Option[]; organizationID: string }, { error: string }>();

/**
 * api fetch to populate table once organization is selected
 */
export const fetchOrganizationActivitiesAsync = createAsyncAction(
  types.FETCH_ORGANIZATION_ACTIVITIES_REQUEST,
  types.FETCH_ORGANIZATION_ACTIVITIES_SUCCESS,
  types.FETCH_ORGANIZATION_ACTIVITIES_FAILURE
)<{ organizationID: string } & OrganizationActivityQuery, { data: Common.PopulatedActivity[][] }, { error: string }>();
