import { SingleUserChartResponse, DashboardAuditorsPayload } from 'reducers/dashboard/fetch-types/auditors-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type SingleUserChart = SingleUserChartResponse['data'];

export interface SingleUsersChartState {
  readonly singleUserChart_loading: boolean;
  readonly singleUserChart_data: SingleUserChart;
  readonly singleUserChart_error: string;
}

export const FETCH_SINGLE_USER_CHART_REQUEST = '@@dashboard/auditors/FETCH_SINGLE_USER_CHART_REQUEST';
export const FETCH_SINGLE_USER_CHART_SUCCESS = '@@dashboard/auditors/FETCH_SINGLE_USER_CHART_SUCCESS';
export const FETCH_SINGLE_USER_CHART_FAILURE = '@@dashboard/auditors/FETCH_SINGLE_USER_CHART_FAILURE';

export type FetchSingleUserChartPayload = DashboardAuditorsPayload & {
  userID: string;
};
export type FetchSingleUserChartResponse = SingleUserChartResponse | ErrorResponse;
