import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './formulas.action';
import { Formulas } from './type.d';
export type QuestionnaireAction = ActionType<typeof actions>;

const initialState: Formulas = {
	error: null,
	paginatedFormulas: [],
	totalItem: 0,
	isLoading: false,
};

const formulasReducer = createReducer<any, QuestionnaireAction>(initialState)
	.handleAction(actions.fetchFormulaAsync.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
	}))
	.handleAction(actions.fetchFormulaAsync.success, (state, action) => ({
		...state,
		error: null,
		isLoading: false,
		paginatedFormulas: action.payload.paginatedFormulas,
		totalItem: action.payload.totalItem,
	}))
	.handleAction(actions.fetchFormulaAsync.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}));

export { formulasReducer };
