import styled from 'styled-components/macro';

export const ChipItem = styled.div`
	margin-right: 8px;
	margin-bottom: 6px;
	padding: 4px 8px;
	display: flex;
	flex-direction: row;

	color: #574fcf;
	border: 1px solid #574fcf;
	border-radius: 2px;

	.label {
		font-size: 0.75rem;
		line-height: 1.125rem;
		font-weight: 400;
		color: inherit;
	}
`;

export const ChipCloseButton = styled.button`
	padding: 0 0 0 6px;
	cursor: pointer;

	img {
		height: 10px;
		width: 10px;
		filter: brightness(0) saturate(100%) invert(32%) sepia(23%) saturate(5614%) hue-rotate(230deg) brightness(85%)
			contrast(89%);
	}

	&:hover,
	&:focus {
		img {
			filter: brightness(0) saturate(100%) invert(75%) sepia(89%) saturate(4783%) hue-rotate(214deg) brightness(92%)
				contrast(99%);
		}
	}
`;

export const ChipListContainer = styled.div<{ hasError?: boolean }>`
	height: 160px;
	overflow-y: auto;
	padding: 8px 0 2px 8px;

	background-color: #fcfcfb;
	border: 2px solid ${({ hasError }) => (hasError ? '#FF354A' : '#DFE1E6')};
	border-radius: 3px;
`;

export const ChipList = styled.div<{ disabled?: boolean }>`
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: start;
	flex-wrap: wrap;

	${ChipItem} {
		cursor: pointer;
		pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
		color: ${({ disabled }) => (disabled ? '#8F8F8F' : '#574fcf')};
		border-color: ${({ disabled }) => (disabled ? '#8F8F8F' : '#574fcf')};

		&:focus,
		&:hover,
		&:focus-within {
			border-color: #7e78ea;
			color: #7e78ea;
		}
	}

	${ChipCloseButton} {
		img {
			height: 10px;
			width: 10px;
			filter: ${({ disabled }) =>
				disabled
					? 'brightness(0) saturate(100%) invert(48%) sepia(96%) saturate(0%) hue-rotate(180deg) brightness(98%) contrast(93%)'
					: 'brightness(0) saturate(100%) invert(32%) sepia(23%) saturate(5614%) hue-rotate(230deg) brightness(85%)\n      contrast(89%)'};
		}

		&:hover,
		&:focus {
			img {
				filter: brightness(0) saturate(100%) invert(75%) sepia(89%) saturate(4783%) hue-rotate(214deg) brightness(92%)
					contrast(99%);
			}
		}
	}
`;
