import { BaseSelectOptionType } from 'components/global/Select/utils/optionsMapper';
import { DateRange } from '../../global/CalendarPicker/utils/calendarUtils';
import { SelectedFilters } from 'components/lms/userManagement/UserListTableHeader';
import { Features, RoleResources } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export enum USERS_FETCH_STATE {
	LOADING,
	SUCCESS,
	ERROR,
}

export type SortDirection = 'asc' | 'desc';

export interface UserListI {
	userName: string;
	email: string;
	userGroups: string[];
	lmsRole: string;
}

export interface UserListFilters {
	courses: string[];
	roles: string[];
	status: string[];
	verificationDate: DateRange;
	lastLoginDate: DateRange;
}

export interface UserListState {
	page: number;
	limit: number;
	search: string;
	sortBy:
		| 'userName'
		| 'lmsRole'
		| 'lmsStatus'
		| 'verificationDate'
		| 'lastLoginDate'
		| 'enrolledCourses'
		| 'userGroups';
	sortDirection: SortDirection;
	fetchState: USERS_FETCH_STATE;
	userList: SingleUser[];
	totalUsers: number;
	filters: UserListFilters;
}

export interface GetUserRequestInterface {
	page?: number;
	limit?: number;
	searchQuery?: string;
	roles?: string[];
}

export interface GetUserListSuccessResponse {
	data: SingleUser[];
	totalDocs?: number;
	filters?: Partial<UserListFilters>;
}

export interface SingleUser {
	_id: string;
	userID: string;
	__v?: number;
	lastLoginDate: number;
	lmsRole: string;
	lmsStatus: string;
	userGroupIDs: string[];
	verificationDate: number;
	populated: Populated;
}

export interface Populated {
	user: User;
	userGroups: UserGroup[];
	groupCount: number;
	courses: any[];
	syllabus: any[];
	allEnrolledCourses: any[];
	allEnrolledCourseCount: number;
}

export interface User {
	userID: string;
	clearedTutorial?: boolean;
	displayName: string;
	email: string;
	language: string;
	role: string;
	status: string;
	uuid: string;
	__v?: number;
}

export interface UserGroup {
	groupID: string;
	__v: number;
	description: string;
	groupName: string;
	status: string;
}
