import {
	CreateRepositoryItemRequest,
	GetRepositoryItemsResponse,
	PaginatedRepositoryItems,
	UpdateRepositoryItemRequest,
} from '@nimbly-technologies/nimbly-common';
import { FILE_REPO_API_URL } from 'config/baseURL';
import API from 'helpers/api';
import { SearchAttachmentResult } from 'reducers/repository/type.d';
import { RepositoryFolderList } from 'reducers/repositoryFolderList/type.d';

export const getData = async (url: string): Promise<any> => {
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw new Error(json.data);
	}
	return json.data;
};

export const fetchRepository = async (queryParams: string): Promise<PaginatedRepositoryItems> => {
	const url = `${FILE_REPO_API_URL}?${queryParams}`;
	return await getData(url);
};

export const fetchRepositoryFolderList = async (): Promise<RepositoryFolderList[]> => {
	const url = `${FILE_REPO_API_URL}/folders`;
	return await getData(url);
};

export const searchRepository = async (queryParams: string): Promise<SearchAttachmentResult> => {
	const url = `${FILE_REPO_API_URL}/search?${queryParams}`;
	return await getData(url);
};

export const createRepositoryItem = async (
	payload: CreateRepositoryItemRequest,
): Promise<GetRepositoryItemsResponse[]> => {
	// eslint-disable-next-line no-useless-catch
	try {
		const token = await API.getFirebaseToken();
		const url = `${FILE_REPO_API_URL}`;
		const response = await API.post(url, token, payload);

		const json = await response.json();

		return json.data;
	} catch (e) {
		throw e;
	}
};

export const uploadRepositoryFile = async (signedUrl: string, file: File) => {
	const response = await fetch(signedUrl, {
		method: 'PUT',
		body: file,
		headers: {
			'Content-Type': file.type,
		},
	});
};

export const getRepositorySignedUrl = async (queryParams: string): Promise<Record<string, string>> => {
	const token = await API.getFirebaseToken();

	const url = `${FILE_REPO_API_URL}/signedUploadURL?${queryParams}`;
	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw new Error(json.message);
	}

	return json.data;
};

export const updateRepositoryItem = async (payload: UpdateRepositoryItemRequest) => {
	const token = await API.getFirebaseToken();
	const url = `${FILE_REPO_API_URL}`;
	const response = await API.put(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}

	return json.data;
};

export const downloadRepositoryItem = async (payload: { entityIDs: string[] }) => {
	const token = await API.getFirebaseToken();
	const url = `${FILE_REPO_API_URL}/download`;
	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw new Error(json.message);
	}

	return json.data;
};

export const fetchRepositoryStorageDetails = async (
	queryParams: string,
): Promise<{ usedStorageGB: number; displayName: string }[]> => {
	const url = `${FILE_REPO_API_URL}/user/all?${queryParams}`;
	return await getData(url);
};

export const createRepositoryUser = async (): Promise<any> => {
	const token = await API.getFirebaseToken();
	const url = `${FILE_REPO_API_URL}/user`;

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			authorization: token,
		},
	});

	if (response.status !== 200) {
		const json = await response.json();
		throw new Error(json.message);
	}
};
