import styled, { keyframes } from 'styled-components/macro';

export const AdminPage = styled.div`
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
	overflow-y: unset;

	display: flex;
	flex-direction: column;

	@media (max-width: 991px) {
		min-width: 100%;
		overflow-y: unset;
	}

	@media (min-width: 992px) {
		position: relative;
		height: 100vh;
		transition: all 300ms;
		will-change: left, width;
	}
`;

export const PageHeader = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 54px;
	background-color: #fff;

	padding: 0.625rem 0.625rem;

	.title {
		font-size: 1.25rem;
		line-height: 1.875rem;
		font-weight: bold;

		margin-bottom: 0.125rem;

		color: #fff;
	}

	.subtitle {
		font-style: normal;
		font-weight: normal;
		font-size: 0.75rem;
		line-height: 18px;

		color: #ffffff;
	}

	@media (max-width: 600px) {
		.subtitle {
			display: none;
		}
	}

	@media (min-width: 992px) {
		margin-top: 0;
		padding-left: 16px;
		height: 177px;

		z-index: 1;
		box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
	}
`;

const fadeIn = keyframes` 
  from {
    top: 40px;
    opacity: 0
  }
  
  to {
    top: 0;
    opacity: 1
  }
`;

interface PageWrapperProps {
	customStyles?: string;
}

export const PageWrapper = styled.div<PageWrapperProps>`
	position: relative;
	width: 100%;
	max-height: 100vh;
	padding: calc(0.625rem + 54px) 0.625rem calc(0.625rem + 60px) 0.625rem;
	animation: 150ms ${fadeIn} ease-out;
	overflow-y: scroll;

	@media (min-width: 992px) {
		padding: 1.25rem;
		background: #fff;
	}

	${(props) => props.customStyles ?? ''};
`;

export const AnalyticsPageWrapper = styled(PageWrapper)`
	background: #fafafa;
	padding-top: 0.625rem;
`;

export const Overlay = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
`;

export const Modal = styled.div`
	max-width: 90%;
	background: #efeeed;
	box-shadow: 0 5px 10px rgba(51, 145, 137, 0.4);
	border-radius: 10px;

	padding: 1.25rem;

	@media (min-width: 992px) {
		width: 23rem;
	}
`;

export const EmptyContainer = styled.div`
	margin: auto;
	margin-top: 40px;
	margin-bottom: 40px;
	width: calc(100% - 20px);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 60%;
		max-width: 250px;
	}

	.title {
		margin-top: 14px;

		font-weight: 600;
		font-size: 16px;
		line-height: 26px;
		text-align: center;
		color: #4d4f5c;
	}

	.subtitle {
		margin-top: 18px;
		width: 100%;

		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 18px;
		text-align: center;
		color: #4d4f5c;
	}

	@media (min-width: 992px) {
		margins: 90px auto;
		width: 60%;

		img {
			width: unset;
		}

		.title {
			font-size: 24px;
			line-height: 36px;
		}

		.subtitle {
			margin-top: 24px;
			width: 60%;
			font-size: 15px;
			line-height: 22px;
		}

		> button {
			margin-top: 12px;
		}
	}
`;
