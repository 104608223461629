import i18n from 'i18n';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { upsertQuestionnaireAsync } from 'reducers/admin/manage/questionnaires/action';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiURL } from '../../config/baseURL';
import { getToken } from '../../reducers/api';
import * as actions from '../../reducers/customerFeedback/customerFeedback.action';
import * as QuestionnaireDetailAction from '../../reducers/questionnaireDetail/questionnaireDetail.action';
import * as types from '../../reducers/customerFeedback/customerFeedback.actionTypes';

export function* fetchCampaignList() {
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const campaignListUrl = `${apiURL}/customer-feedback/campaign`;
		const request = () => fetch(campaignListUrl, options);
		// @ts-ignore
		const response = yield call(request);
		// @ts-ignore
		const responseData = yield response.json();

		if (response?.status === 200) {
			yield put(actions.fetchCampaignList.success({ data: responseData.data }));
		} else {
			yield put(actions.fetchCampaignList.failure({ error: responseData.message }));
		}
	} catch (error) {
		yield put(actions.fetchCampaignList.failure({ error: 'Failed to retrieve list of campaign' }));
	}
}

export function* fetchCampaignReport({ payload }: ReturnType<typeof actions.fetchCampaignReport.request>) {
	// @ts-ignore
	const authToken = yield getToken();
	const { questionnaireId, startDate, endDate, sites, dl } = payload;

	const options = {
		method: 'GET',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
	};
	const query = queryString.stringify(
		{
			startDate,
			endDate,
			dl,
			sites,
		},
		{ arrayFormat: 'bracket' },
	);
	const fetchCampaignReportUrl = `${apiURL}/customer-feedback/trends/${questionnaireId}?` + query;
	const request = () => fetch(fetchCampaignReportUrl, options);
	// @ts-ignore
	const response = yield call(request);
	const { data, message } = yield response.json();

	if (response.status === 200) {
		yield put(actions.fetchCampaignReport.success({ data, message }));
	} else {
		yield put(actions.fetchCampaignReport.failure({ message }));
	}
}

export function* setCampaignStatus({ payload }: ReturnType<typeof actions.setCampaignStatus.request>) {
	const { status, questionnaireID } = payload;
	try {
		// @ts-ignore
		const authToken = yield getToken();
		const options = {
			method: 'PUT',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ disabled: status }),
		};
		const campaignStatusURL = `${apiURL}/customer-feedback/campaign/status/${questionnaireID}`;
		const request = () => fetch(campaignStatusURL, options);
		// @ts-ignore
		const response = yield call(request);
		// @ts-ignore
		const responseData = yield response.json();

		if (response && response.status === 200) {
			toast.success(i18n.t('message.customerFeedback.toast.success'));
			yield put(
				actions.setCampaignStatus.success({ data: { status: status, questionnaireID }, message: responseData.message }),
			);
		} else {
			yield put(actions.setCampaignStatus.failure({ error: responseData.message }));
			toast.error(i18n.t('message.customerFeedback.toast.fail'));
		}
	} catch (error) {
		yield put(actions.setCampaignStatus.failure({ error: 'Failed to update campaign status' }));
		toast.error(i18n.t('message.customerFeedback.toast.fail'));
	}
}

function* postQuestionnaires(action: ReturnType<typeof actions.postQuestionnaires.request>): Generator {
	try {
		const authToken: any = yield getToken();

		// eslint-disable-next-line no-undef
		const options: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(action.payload),
		};

		const postQuestionnairesFeedback = `${apiURL}/customer-feedback/campaign`;
		const request = () => fetch(postQuestionnairesFeedback, options);
		const response = yield call(request);
		// @ts-ignore
		const { data, message } = yield response.json();

		// @ts-ignore
		if (response.status === 200) {
			yield put(upsertQuestionnaireAsync.success(data));
			toast.success('Questionnaire successfully added');
		} else {
			toast.error('Failed to add questionnaire: ' + message);
			yield put(actions.postQuestionnaires.failure('CUSTOMER FEEDBACK - UPSERT QUESTIONNAIRE FAILED'));
		}
	} catch (e) {
		yield put(actions.postQuestionnaires.failure('MANAGE - UPSERT QUESTIONNAIRE FAILED'));
	} finally {
		yield put(QuestionnaireDetailAction.setIsBusy(false));
	}
}

function* updateQuestionnaires(action: ReturnType<typeof actions.updateQuestionnaires.request>): Generator {
	try {
		const authToken: any = yield getToken();

		const options = {
			method: 'PUT',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(action.payload),
		};

		const updateQuestionnaireFeedback = `${apiURL}/customer-feedback/campaign/${action.payload.questionnaireIndexID}`;
		const request = () => fetch(updateQuestionnaireFeedback, options);
		const res = yield call(request);
		// @ts-ignore
		const { data, message } = yield res.json();

		// @ts-ignore
		if (res.status === 200) {
			toast.success('Questionnaire successfully updated');
			yield put(actions.postQuestionnaires.success(data));
		} else {
			toast.error('Failed to update questionnaire: ' + message);
			yield put(actions.postQuestionnaires.failure('CUSTOMER FEEDBACK - UPDATE QUESTIONNAIRE FAILED'));
		}
	} catch (e) {
		yield put(actions.postQuestionnaires.failure('MANAGE - UPDATE QUESTIONNAIRE FAILED'));
	} finally {
		yield put(QuestionnaireDetailAction.setIsBusy(false));
	}
}

function* getCampaignTemplate() {
	try {
		// @ts-ignore
		const authorizationToken = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: authorizationToken,
			},
		};

		const campaignTemplateURL = `${apiURL}/customer-feedback/campaign/bulk-upload`;
		const req = () => fetch(campaignTemplateURL, options);
		// @ts-ignore
		const res = yield call(req);
		// @ts-ignore
		const responseData = yield res.json();

		if (res?.status === 200) {
			yield put(actions.getCampaignTemplate.success({ data: responseData.data }));
		} else {
			yield put(actions.getCampaignTemplate.failure({ error: responseData.message }));
		}
	} catch (error) {
		yield put(actions.getCampaignTemplate.failure({ error: 'Failed to retrieve template' }));
	}
}

export default function* customerFeedbackSaga() {
	yield takeLatest(types.FETCH_CAMPAIGN_LIST_REQUEST, fetchCampaignList);
	yield takeLatest(types.FETCH_CAMPAIGN_REPORT_REQUEST, fetchCampaignReport);
	yield takeLatest(types.POST_QUESTIONNAIRE_REQUEST, postQuestionnaires);
	yield takeLatest(types.PUT_QUESTIONNAIRE_REQUEST, updateQuestionnaires);
	yield takeLatest(types.SET_CAMPAIGN_STATUS_REQUEST, setCampaignStatus);
	yield takeLatest(types.GET_CAMPAIGN_TEMPLATE_REQUEST, getCampaignTemplate);
}
