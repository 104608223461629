import { createAsyncAction, action } from 'typesafe-actions';
import * as types from './siteOffDays.actionTypes';
import { SiteOffdayMongo } from '@nimbly-technologies/nimbly-common';

export const fetchSiteOffDays = createAsyncAction(
	types.FETCH_SITE_OFF_DAYS_REQUEST,
	types.FETCH_SITE_OFF_DAYS_SUCCESS,
	types.FETCH_SITE_OFF_DAYS_FAILED,
)<{ siteID: string }, { data: SiteOffdayMongo }, { error: string }>();

export const upsertSiteOffDays = createAsyncAction(
	types.UPSERT_SITE_OFF_DAYS_REQUEST,
	types.UPSERT_SITE_OFF_DAYS_SUCCESS,
	types.UPSERT_SITE_OFF_DAYS_FAILED,
)<{ siteID: string; updatedSiteOffDays: Partial<SiteOffdayMongo> }, { data: SiteOffdayMongo }, { error: string }>();

export const resetSiteOffDaysCallState = () => action(types.RESET_SITE_OFF_DAYS_CALL_STATE);
