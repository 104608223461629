import { CourseStatus } from '@nimbly-technologies/nimbly-common';
import { toast } from 'react-toastify';

export const getSuccessToastMessage = (status: CourseStatus | undefined) => {
	const successMsg =
		status === CourseStatus.DRAFT
			? 'You have successfully saved a lesson as draft'
			: 'You have successfully published a lesson.';
	toast.success(successMsg);
};
