export const SET_SHOW_MODAL = 'userGroupManagement/SET_SHOW_MODAL';
export const SET_SUCCESS = 'userGroupManagement/SET_SUCCESS';

export const CREATE_USER_GROUP_REQUEST = 'userGroupManagement/CREATE_USER_GROUP_REQUEST';
export const CREATE_USER_GROUP_SUCCESS = 'userGroupManagement/CREATE_USER_GROUP_LOADING';
export const CREATE_USER_GROUP_FAILURE = 'userGroupManagement/CREATE_USER_GROUP_FAILURE';

export const UPDATE_USER_GROUP_REQUEST = 'userGroupManagement/UPDATE_USER_GROUP_REQUEST';
export const UPDATE_USER_GROUP_SUCCESS = 'userGroupManagement/UPDATE_USER_GROUP_LOADING';
export const UPDATE_USER_GROUP_FAILURE = 'userGroupManagement/UPDATE_USER_GROUP_FAILURE';

export const SELECTED_EDIT_LMS_USER_GROUP = 'userGroupManagement/SELECTED_EDIT_LMS_USER_GROUP';
export const SELECTED_EDIT_LMS_USERS_GROUP = 'userGroupManagement/SELECTED_EDIT_LMS_USERS_GROUP';
