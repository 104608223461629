import { SiteCompact } from 'nimbly-common';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './siteCompact.action';

export interface SiteCompactPaginateData {
  docs: SiteCompact[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
}

export interface SiteCompactPaginateState {
  isLoading: boolean;
  data: SiteCompactPaginateData;
  message: string;
}

const initialState: SiteCompactPaginateState = {
  isLoading: false,
  data: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0
  },
  message: ''
};

export type SiteCompactPaginateAction = ActionType<typeof actions.siteCompactPaginateAction>;
export const siteCompactPaginateReducer = createReducer<SiteCompactPaginateState, SiteCompactPaginateAction>(
  initialState
)
  .handleAction(actions.siteCompactPaginateAction.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(actions.siteCompactPaginateAction.success, (state, action) => ({
    ...state,
    data: action.payload.data,
    message: action.payload.message,
    isLoading: false
  }))
  .handleAction(actions.siteCompactPaginateAction.failure, (state, action) => ({
    ...state,
    message: action.payload.message,
    isLoading: false
  }));
