import React, { useEffect, useState } from 'react';
import { TabConfig } from './MobileTabs.types';
import { CollapsibleSubTabs, CollapsibleTab, SubTab, Tab } from './MobileTabs.style';
import DropdownIcon from '../../../../assets/icon/chevron-dark.svg';

export interface CollapsibleMobileTabProps {
	tabConfig: TabConfig;
}

const CollapsibleMobileTab = ({ tabConfig }: CollapsibleMobileTabProps) => {
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		setIsExpanded(tabConfig.isActive);
	}, [tabConfig.isActive]);

	const render = () => {
		const visibleSubTabs = tabConfig.subTabs!.filter((item) => item.isVisible);
		const visibleSubTabsCount = visibleSubTabs.length;

		if (visibleSubTabsCount <= 0) {
			return null;
		}

		if (visibleSubTabsCount === 1) {
			const visibleTabConfig = visibleSubTabs[0];
			return (
				<Tab key={visibleTabConfig.key} onClick={visibleTabConfig.onClick} isActive={visibleTabConfig.isActive}>
					{visibleTabConfig.icon}
					<span>{visibleTabConfig.title}</span>
				</Tab>
			);
		}

		return (
			<>
				<CollapsibleTab key={tabConfig.key} isActive={isExpanded} onClick={handleOnCollapsibleTabClick}>
					{tabConfig.icon}
					<span>{tabConfig.title}</span>
					<span className="dropdown">
						<img src={DropdownIcon} alt="dropdown" />
					</span>
				</CollapsibleTab>
				<CollapsibleSubTabs isCollapsed={!isExpanded}>
					{tabConfig.subTabs!.map((subTabConfig) => {
						return subTabConfig.isVisible ? (
							<SubTab key={subTabConfig.key} isActive={subTabConfig.isActive} onClick={subTabConfig.onClick}>
								{subTabConfig.icon}
								<span>{subTabConfig.title}</span>
							</SubTab>
						) : null;
					})}
				</CollapsibleSubTabs>
			</>
		);
	};

	const handleOnCollapsibleTabClick = () => {
		setIsExpanded(!isExpanded);
		tabConfig.onClick?.();
	};

	return render();
};

export default CollapsibleMobileTab;
