import { UserCompactResponse } from '@nimbly-technologies/nimbly-common/src/models/api/lms/response';
import { createAsyncAction } from 'typesafe-actions';
import * as types from './lmsUsersCompact.actionTypes';

export const fetchLmsUsersCompact = createAsyncAction(
	types.FETCH_USERS_COMPACT_REQUEST,
	types.FETCH_USERS_COMPACT_SUCCESS,
	types.FETCH_USERS_COMPACT_FAILED,
)<
	{
		withDisabled?: boolean;
		roles?: string[];
	},
	{
		lmsUsersCompact: UserCompactResponse[];
	},
	{
		error: string;
	}
>();
