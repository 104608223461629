import { DashboardSitesPayload, FlagsChartResponse } from 'reducers/dashboard/fetch-types/sites-fetch-types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type SitesFlagsChart = FlagsChartResponse['data'];

export interface SitesFlagsChartState {
  readonly sitesFlagsChart_loading: boolean;
  readonly sitesFlagsChart_data: SitesFlagsChart;
  readonly sitesFlagsChart_error: string;
}

export const FETCH_SITES_FLAGS_CHART_REQUEST = '@@dashboard/sites/FETCH_FLAGS_CHART_REQUEST';
export const FETCH_SITES_FLAGS_CHART_SUCCESS = '@@dashboard/sites/FETCH_FLAGS_CHART_SUCCESS';
export const FETCH_SITES_FLAGS_CHART_FAILURE = '@@dashboard/sites/FETCH_FLAGS_CHART_FAILURE';

export type FetchFlagsChartPayload = DashboardSitesPayload;
export type FetchFlagsChartResponse = FlagsChartResponse | ErrorResponse;
