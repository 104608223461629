import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './bulkEdit.action';
import { BulkEditUserI } from './type';
import * as types from './bulkEdit.actionTypes';

export type BulkUpdateUserAction = ActionType<typeof actions>;

export const initialState: BulkEditUserI = {
	data: [{ userID: '', userGroupIDs: [], lmsStatus: '' }],
	isSuccess: false,
	isLoading: false,
};

const bulkUpdateUser = createReducer<BulkEditUserI, BulkUpdateUserAction>(initialState)
	.handleAction(actions.bulkUpdateUser.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.bulkUpdateUser.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.bulkUpdateUser.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
		isSuccess: false,
	}))
	.handleAction(types.SET_SHOW_MODAL as any, (state, action) => ({
		...state,
		isShowModal: action.payload.value,
	}))
	.handleAction(types.SET_SUCCESS as any, (state, action) => {
		return {
			...state,
			isSuccess: action.payload.value,
		};
	});

export { bulkUpdateUser };
