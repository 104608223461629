export interface ColumnsConfig {
	configurationLabel: string;
	columnLabel?: string;
	isSelected: boolean;
	isDisabled?: boolean;
	isHidden?: boolean;
	headerClassName?: string;
	rowClassName?: string;
	type?: ColumnType;
	fieldName?: string;
	queryParamFieldName?: string;
	parentFieldName?: string;
	subColumns?: {
		fieldName: string;
		columnLabel: string;
		headerClassName?: string;
		rowClassName?: string;
		type?: ColumnType;
		configurationLabel?: string;
		isSelected?: boolean;
	}[];
}

export enum ColumnType {
	Text = 'Text',
	Number = 'Number',
	Amount = 'Amount',
	Percentage = 'Percentage',
	Time = 'Time',
	ArrayOfText = 'ArrayOfText',
}

export const getColumnTypeClassName = (type?: ColumnType) => {
	switch (type) {
		case ColumnType.Amount:
			return 'aa-text-right';
		case ColumnType.Number:
		case ColumnType.Percentage:
			return 'aa-text-center';
		default:
			return 'aa-text-left';
	}
};

export enum FieldName { // currently only Issue Count fieldnames
	total = 'total',
	redCount = 'redCount',
	yellowCount = 'yellowCount',
	resolvedCount = 'resolvedCount',
	openIssueCount = 'openIssueCount',
}

export enum LinkTabs {
	IssueCount = 'openIssueCount',
	Issues = 'Issues',
}
