import { action, createAsyncAction } from 'typesafe-actions';
import { Moment } from 'moment';
import * as types from './siteReports.actionTypes';
import * as Common from 'nimbly-common';

import { SiteReportSortKeys, SiteReportSummary, SkuReportSummary } from './siteReport.types';
import { getDatesByPeriod } from 'utils/dates';

export const setIsLoading = (status: boolean) => action(types.SET_IS_LOADING, { status });

export const setIsFetching = (status: boolean) => action(types.SET_IS_FETCHING, { status });

export const setSiteReports = (reports: SiteReportSummary[], showWarning?: boolean) =>
	action(types.SET_REPORTS, { reports, showWarning });

export const setPageIndexReport = (value: number) => action(types.SET_PAGE_INDEX_REPORT, { value });

export const setSortType = (sortBy: SiteReportSortKeys) => action(types.SET_SORT_TYPE, { sortBy });

export const setColorChange = () => action(types.SET_COLOR_CHANGE);

export const setFilteredType = (type: string) => action(types.SET_REPORT_TYPE_FILTER, { type });

export const setFilteredQuestionnaires = (questionnaires: string[]) =>
	action(types.SET_REPORT_QUESTIONNAIRE_FILTERS, { questionnaires });

export const setIssueIDs = (issues: string[]) => action(types.SET_ISSUE_IDS, { issues });

export const clearIssueIDs = () => action(types.CLEAR_ISSUE_IDS, {});

export const setFilteredUsers = (users: string[]) => action(types.SET_REPORT_USER_FILTERS, { users });

export const setSiteFilteredReports = (reports: SiteReportSummary[]) => action(types.SET_FILTERED_REPORTS, { reports });

export const setPeriodType = (periodType: Common.enums.PeriodTypes | null) =>
	action(types.SET_PERIOD_TYPE, { periodType });

export const resetFilterDates = () => action(types.RESET_SITE_REPORT_FILTER_DATES);

export const setDates = (
	periodType: Common.enums.PeriodTypes | null,
	orgSchedule: Common.OrganizationSchedule,
	date: { customStart: Moment; customEnd: Moment } | null = null,
) => {
	const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date);
	return action(types.SET_DATES, {
		startDate: startDate,
		endDate: endDate,
	});
};

export const setCustomDates = (startDate: Moment | null, endDate: Moment | null) =>
	action(types.SET_CUSTOM_DATES, { startDate, endDate });

export const setPreviousDates = (
	periodType: Common.enums.PeriodTypes | null,
	orgSchedule: Common.OrganizationSchedule,
	date: { customStart: Moment; customEnd: Moment } | null = null,
) => {
	const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date, true);
	return action(types.SET_PREVIOUS_DATES, {
		prevStartDate: startDate,
		prevEndDate: endDate,
	});
};

// TODO: move this to nimbly-common
export interface QuerySiteReport {
	siteID: string;
	startDate: Moment | null;
	endDate: Moment | null;
	type: string;
	questionnaires: string[];
	departments: string[];
	auditors: string[];
	pageIndex: number;
	sortBy: string;
}

export const fetchSiteReports = createAsyncAction(
	types.FETCH_SITE_REPORTS_REQUEST,
	types.FETCH_SITE_REPORTS_SUCCESS,
	types.FETCH_SITE_REPORTS_FAILURE,
)<
	QuerySiteReport,
	{
		skuReports: SkuReportSummary[];
		reports: SiteReportSummary[];
		total: number;
	},
	{ error: string }
>();

export const siteReportActions = {
	setIsLoading,
	setSiteReports,
	setPageIndexReport,
	setSortType,
	setColorChange,
	setFilteredType,
	setFilteredQuestionnaires,
	setFilteredUsers,
	setSiteFilteredReports,
	setPeriodType,
	setDates,
	setCustomDates,
	setPreviousDates,
	fetchSiteReports,
	setIssueIDs,
};
