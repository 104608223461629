export const uploadImage = async (firebase, ref, file) => {
  try {
    const uploadTask = await firebase
      .storage()
      .ref(ref)
      .put(file);

    const url = await uploadTask.ref.getDownloadURL();
    return url;
  } catch (error) {
    throw error;
  }
};
