import update from 'immutability-helper';

import { PurchaseOrder } from 'nimbly-common';

import * as types from './actionType';

const initialState = {
  ...new PurchaseOrder('', {}, [], '', '', 'pending', '', [], '', '', '', '', '')
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ALL:
      return {
        ...action.payload.po
      };
    case types.SET_PO_NUMBER:
      return {
        ...state,
        number: action.payload.number
      };
    case types.ADD_PO_ITEM:
      return update(state, {
        items: {
          $merge: { [action.payload.skuKey]: true }
        },
        itemDetails: {
          $set: action.payload.updatedItems
        },
        totalPrice: { $set: action.payload.totalPrice }
      });
    case types.REMOVE_PO_ITEM:
      return update(state, {
        items: {
          $unset: [action.payload.skuKey]
        },
        itemDetails: {
          $set: action.payload.updatedItems
        },
        totalPrice: { $set: action.payload.totalPrice }
      });
    case types.UPDATE_PO_ITEM:
      return update(state, {
        itemDetails: {
          $set: action.payload.updatedItems
        },
        totalPrice: {
          $set: action.payload.totalPrice
        }
      });
    case types.SET_PO_VENDOR:
      return {
        ...state,
        vendorKey: action.payload.vendorKey
      };
    case types.SET_PO_SITE:
      return {
        ...state,
        siteKey: action.payload.siteKey
      };
    case types.SET_PO_EMAIL_TARGET:
      return update(state, {
        emailTargets: {
          [action.payload.index]: {
            $set: action.payload.email
          }
        }
      });
    case types.ADD_PO_EMAIL_TARGET:
      return update(state, {
        emailTargets: {
          $push: ['']
        }
      });
    case types.SET_PO_NOTE:
      return {
        ...state,
        note: action.payload.note
      };
    case types.CLEAR_PO:
      return {
        ...initialState
      };
    case types.SET_PO_ESTIMATED_DELIVERY:
      return {
        ...state,
        estimatedDelivery: action.payload.date
      };
    case types.SET_PO_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    default:
      return state;
  }
};
