import React from 'react';
import styled from 'styled-components';
import SuccessIcon from 'assets/icon/green-checklist-full.svg';
import RemoveIcon from 'assets/icon/remove-icon.svg';
import FailedIcon from 'assets/icon/red-notif.svg';

interface AuthFederatedMessageProps {
	isRequestSuccess: boolean;
	isRequestFailed: boolean;
	isMultipleRequest: boolean;
	handleDismissRequestSuccess: () => void;
}

const AuthFederatedMessage = (props: AuthFederatedMessageProps) => {
	const isMultipleRequest = props.isRequestFailed && props.isMultipleRequest;
	const isFailedRequest = props.isRequestFailed && !props.isMultipleRequest;

	const renderNotification = () => {
		let notification = '';
		if (props.isRequestSuccess) {
			notification = 'Your request has been sent to your Account Holder';
		}
		if (isMultipleRequest) {
			notification = 'Request access for this domain has already been submitted';
		}
		if (isFailedRequest) {
			notification = 'Please try again later';
		}

		return notification;
	};

	return (
		<Container isShow={props.isRequestSuccess || props.isRequestFailed} isFailed={props.isRequestFailed}>
			<Section>
				<TitleSection>
					<IconContainer src={props.isRequestSuccess ? SuccessIcon : FailedIcon} />
					{props.isRequestSuccess ? 'Access Requested!' : 'Request Access Failed'}
					<IconContainer src={RemoveIcon} onClick={props.handleDismissRequestSuccess} />
				</TitleSection>
				{renderNotification()}
			</Section>
		</Container>
	);
};

export default AuthFederatedMessage;

const Container = styled.div<{ isShow: boolean; isFailed: boolean }>`
	margin-top: 10px;
	width: 300px;
	height: 121px;
	background: ${(props) => (props.isFailed ? '#ffd9d9' : '#e6faed')};
	opacity: ${(props) => (props.isShow ? 1 : 0)};
	transition: opacity 0.4s linear;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
	padding: 16px 18px;
`;

const Section = styled.div`
	display: grid;
	grid-gap: 15px;
	grid-template-rows: 40% 60%;
`;

const TitleSection = styled.div`
	display: grid;
	grid-template-columns: 10% 70% 10%;
	grid-gap: 5px;
	font-weight: 600;
`;

const IconContainer = styled.img`
	width: 18px;
	height: 18px;
`;
