/* eslint-disable complexity */
import { createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './issueInsights.actionTypes';
import { GroupByEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import {
	IssueCountData,
	IssueInsightsData,
	IssueInsightsFilters,
	IssueInsightsRequest,
	IssueStatusData,
	IssueTableList,
} from './issueInsightsStore';

// TODO: Add actions for drills & download reports.
export const setGroupBy = createAction(types.SET_GROUP_BY, (groupBy: GroupByEnum | string) => ({ groupBy }))();
export const setLimit = createAction(types.SET_LIMIT, (limit: number) => ({ limit }))();
export const setFilters = createAction(types.SET_FILTERS, (filters: Partial<IssueInsightsFilters>) => ({ filters }))();
export const setIssueStatus = createAction(types.SET_ISSUE_STATUS, (status: string) => ({ status }))();
export const setPage = createAction(types.SET_PAGE, (page: number) => ({ page }))();
export const setIssueInsightChartDetailFilters = createAction(
	types.SET_ISSUE_INSIGHT_CHART_DETAIL_FILTERS,
	(filters: IssueInsightsRequest) => ({ filters }),
)();
export const clearFilters = createAction(types.CLEAR_FILTERS, () => ({}))();

export const fetchIssueInsightDetailAsync = createAsyncAction(
	types.FETCH_ISSUE_INSIGHT_DETAIL_REQUEST,
	types.FETCH_ISSUE_INSIGHT_DETAIL_SUCCESS,
	types.FETCH_ISSUE_INSIGHT_DETAIL_FAILURE,
)<undefined, IssueInsightsData, string>();

export const fetchIssueInsightListAsync = createAsyncAction(
	types.FETCH_ISSUE_INSIGHT_LIST_REQUEST,
	types.FETCH_ISSUE_INSIGHT_LIST_SUCCESS,
	types.FETCH_ISSUE_INSIGHT_LIST_FAILURE,
)<IssueInsightsRequest, IssueCountData[], string>();

export const fetchIssueChartAsync = createAsyncAction(
	types.FETCH_ISSUE_CHART_REQUEST,
	types.FETCH_ISSUE_CHART_SUCCESS,
	types.FETCH_ISSUE_CHART_FAILURE,
)<IssueInsightsRequest, IssueStatusData[], string>();

export const fetchIssueChartDetailsAsync = createAsyncAction(
	types.FETCH_ISSUE_CHART_DETAILS_REQUEST,
	types.FETCH_ISSUE_CHART_DETAILS_SUCCESS,
	types.FETCH_ISSUE_CHART_DETAILS_FAILURE,
)<undefined, IssueStatusData[], string>();

export const fetchIssueTableDataAsync = createAsyncAction(
	types.FETCH_ISSUE_TABLE_DATA_REQUEST,
	types.FETCH_ISSUE_TABLE_DATA_SUCCESS,
	types.FETCH_ISSUE_TABLE_DATA_FAILURE,
)<undefined, IssueTableList, string>();
