import { getStringValue } from 'helpers/commons';
import styled from 'styled-components/macro';

interface Props {
	customStyle?: string;
}

const Container = styled.div<Props>`
	height: 100%;

	display: flex;
	flex-direction: row;

	${(props) => getStringValue(props?.customStyle)};
`;

export { Container };
