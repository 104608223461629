import { FederatedAuthentication } from '@nimbly-technologies/nimbly-common';
import API from 'helpers/api';
import { apiURL } from 'config/baseURL';
import { auth } from 'store/configStore';

const generateErrorNotification = (errorCode: string) => {
	// @ts-ignore
	if (errorCode === 'auth/wrong-password') {
		return {
			status: 'wrong_password',
			errorMsg: 'Invalid credentials. Please try again',
			errorField: 'password',
		};
	}
	// @ts-ignore
	if (errorCode === 'auth/too-many-requests') {
		return {
			status: 'too_many_requests',
			errorMsg: 'Too many login errors. Please try again later.',
			errorField: '',
		};
	} else {
		return {
			status: 'unknown_error',
			errorMsg: 'Unknown error',
			errorField: '',
		};
	}
};

export const authenticateFederatedCredentials = (tenant: FederatedAuthentication, firebase: any): Promise<any> => {
	const newAuth = auth();
	newAuth.tenantId = tenant.tenantID;
	const provider = new firebase.auth.OAuthProvider(tenant.loginProviderID);

	return newAuth
		.signInWithPopup(provider)
		.then((result: any) => {
			if (result) return API.getFirebaseToken();
		})
		.then((res: any) => {
			return res;
		})
		.catch((error: any) => {
			return {
				pendingCred: error.credential,
				errorCode: error.code
			};
		});
};

export const linkAccount = async (email:string, password: string, pendingCredential: any) : Promise<any> => {
	const authentication = auth(); 
	try {
		if(!password){
			return {
				status: 'field_not_complete',
				errorMsg: 'One or more fields are missing.',
				errorField: '',
			}
		}
		const signInMethods = await authentication.fetchSignInMethodsForEmail(email);
		if(signInMethods[0] === 'password'){
			const res = await authentication.signInWithEmailAndPassword(email, password);
			if(res) {
				await res.user?.linkWithCredential(pendingCredential);
				return {
					status: 'ok',
					errorMsg: '',
					errorField: ''
				}
			}

		}
	} catch (error) {
		return generateErrorNotification(error?.code)
	}
}

export const authenticateMultiTenant = async (
	tenant: FederatedAuthentication,
	email: string,
	password: string,
): Promise<any> => {
	const multiTenantAuth = auth();
	multiTenantAuth.tenantId = tenant?.tenantID;

	try {
		if (!password) {
			return {
				status: 'field_not_complete',
				errorMsg: 'One or more fields are missing.',
				errorField: '',
			};
		}
		const result = await multiTenantAuth.signInWithEmailAndPassword(email, password);
		if (result)
			return {
				status: 'ok',
				errorMsg: '',
				errorField: '',
			};
	} catch (error) {
		return generateErrorNotification(error?.code);
	}
};

export const findTenantByEmailDomain = async (emailDomain: string, email: string) => {
	try {
		const url = `${apiURL}/auth/federated/domain`;

		const bodySend = {
			domain: emailDomain,
			email: email,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(bodySend),
		};
		const fetchData = await fetch(url, options);
		if (fetchData.status !== 200) return false;

		const result = await fetchData.json();
		return result.data;
	} catch (error) {
		return error;
	}
};

export const checkUserEnrollment = async (idToken: string, tenantID: string) => {
	try {
		const url = `${apiURL}/auth/federated/verify-enrollment`;
		const bodySend = {
			token: idToken,
			tenantID,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(bodySend),
		};

		const fetching = await fetch(url, options);
		if (fetching.status !== 200) {
			const res = await fetching.json();
			return res;
		}

		const result = await fetching.json();
		return result;
	} catch (error) {
		return error;
	}
};

export const requestAccessEnrollment = async (token: string, tenant: FederatedAuthentication) => {
	try {
		const url = `${apiURL}/auth/user-enrollment`;
		const body = {
			token: token,
			tenant: tenant,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const requesting = await fetch(url, options);
		if (requesting.status !== 200) return false;

		const response = await requesting.json();
		return response.data;
	} catch (error) {
		return error;
	}
};
