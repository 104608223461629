import { ActionType, createReducer } from 'typesafe-actions';
import { GalleryFilters, GalleryGroupByType, GalleryState, GalleryTabEnum, SearchAttachmentResult } from './type.d';
import * as actions from './gallery.action';

export type SkusCompactAction = ActionType<typeof actions>;

export const initialGalleryFilters: GalleryFilters = {
	startDate: null,
	endDate: null,
	source: [],
	type: [],
	qnrs: [],
	auditors: [],
	siteID: [],
	deptIDs: [],
};

export const initialSearchValues: SearchAttachmentResult = {
	questionMatches: [],
	questionMatchCount: 0,
	categoryMatches: [],
	categoryMatchCount: 0,
	issueMatches: [],
	issueMatchCount: 0,
};

export const initialDownloadProgressModalConfig = {
	isVisible: false,
	count: 0,
	total: 0,
};

export const initialState: GalleryState = {
	error: '',
	isLoading: false,
	isLoadingNextPage: false,
	isLoadingSearch: false,
	isSelectMode: false,
	activeTab: GalleryTabEnum.LIBRARY,
	gridSize: 6,

	page: 1,
	limit: 6 * 4,
	totalPages: 0,
	totalDocs: 0,
	canLoadMore: false,

	attachments: [],
	sections: {},

	selectedAttachment: null,
	selectedAttachmentIds: new Set(),
	totalSelectedAttachments: 0,
	totalSelectedAttachmentsSize: 0,

	selectedAlbum: undefined,
	selectedAlbumIds: new Set(),

	selectedDates: new Set(),

	searchQuery: '',
	searchData: initialSearchValues,
	filters: initialGalleryFilters,
	groupBy: GalleryGroupByType.ALL,
	groupByCount: undefined,

	isFilterOpen: false,
	isDownloadErrorModalVisible: false,
	isDownloadInitializationModalVisible: false,
	downloadProgressModalConfig: initialDownloadProgressModalConfig,
};

export const galleryReducer = createReducer<GalleryState, SkusCompactAction>(initialState)
	.handleAction(actions.setActiveGalleryTab, (state, action) => ({
		...initialState,
		activeTab: action.payload,
		groupBy: state.groupBy,
		searchQuery: state.searchQuery,
		selectedAlbum: state.selectedAlbum,
	}))
	.handleAction(actions.setGallerySearchQuery, (state, action) => ({
		...state,
		searchQuery: action.payload,
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.setGalleryFilters, (state, action) => ({
		...state,
		isLoading: true,
		filters: { ...state.filters, ...action.payload },
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.resetGalleryFilters, (state) => ({
		...state,
		isLoading: true,
		filters: initialState.filters,
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.setGalleryGroupBy, (state, action) => ({
		...state,
		groupBy: action.payload,
		isSelectMode: initialState.isSelectMode,
		selectedAttachmentIds: initialState.selectedAttachmentIds,
		selectedAlbumIds: initialState.selectedAlbumIds,
		page: initialState.page,
		totalPages: initialState.totalPages,
		totalDocs: initialState.totalDocs,
		canLoadMore: initialState.canLoadMore,
	}))
	.handleAction(actions.setGalleryPageIndex, (state, action) => ({
		...state,
		page: action.payload,
	}))
	.handleAction(actions.setGalleryGridSize, (state, action) => ({
		...state,
		gridSize: action.payload,
		limit: action.payload * 4,
	}))

	// DATA SELECTION
	.handleAction(actions.setSelectedGalleryAttachment, (state, action) => ({
		...state,
		selectedAttachment: action.payload,
	}))
	.handleAction(actions.setSelectedGalleryAttachmentIds, (state, action) => ({
		...state,
		selectedAttachmentIds: action.payload,
	}))
	.handleAction(actions.setTotalSelectedGalleryAttachments, (state, action) => ({
		...state,
		totalSelectedAttachments: action.payload,
	}))
	.handleAction(actions.setTotalSelectedAttachmentsSize, (state, action) => ({
		...state,
		totalSelectedAttachmentsSize: action.payload,
	}))

	.handleAction(actions.setSelectedGalleryAlbum, (state, action) => ({
		...state,
		selectedAlbum: action.payload,
	}))
	.handleAction(actions.setSelectedGalleryAlbumIds, (state, action) => ({
		...state,
		selectedAlbumIds: action.payload,
	}))

	.handleAction(actions.setSelectedGalleryDates, (state, action) => ({
		...state,
		selectedDates: action.payload,
	}))

	// MODAL CONTROL
	.handleAction(actions.toggleGalleryFilter, (state, action) => ({
		...state,
		isFilterOpen: action.payload,
	}))
	.handleAction(actions.toggleGallerySelectMode, (state, action) => ({
		...state,
		isSelectMode: action.payload,
		selectedAttachmentIds: initialState.selectedAttachmentIds,
		selectedAlbumIds: initialState.selectedAlbumIds,
		totalSelectedAttachments: initialState.totalSelectedAttachments,
		totalSelectedAttachmentsSize: initialState.totalSelectedAttachmentsSize,
		selectedDates: initialState.selectedDates,
	}))
	.handleAction(actions.toggleGalleryDownloadErrorModal, (state, action) => ({
		...state,
		isDownloadErrorModalVisible: action.payload,
	}))
	.handleAction(actions.toggleGalleryDownloadInitializationModal, (state, action) => ({
		...state,
		isDownloadInitializationModalVisible: action.payload,
	}))
	.handleAction(actions.setGalleryDownloadProgressModalConfig, (state, action) => ({
		...state,
		downloadProgressModalConfig: action.payload,
	}))

	// DATA FETCH
	.handleAction(actions.fetchAttachments.request, (state, action) => ({
		...state,
		error: initialState.error,
		isLoading: action.payload.loadMore ? false : true,
		isLoadingNextPage: action.payload.loadMore ? true : false,
		page: action.payload.loadMore ? state.page + 1 : initialState.page,
	}))
	.handleAction(actions.fetchAttachments.success, (state, action) => ({
		...state,
		...action.payload,
		error: initialState.error,
		isLoading: false,
		canLoadMore: action.payload.page < action.payload.totalPages,
	}))
	.handleAction(actions.fetchAttachments.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(actions.searchAttachments.request, (state) => ({
		...state,
		error: initialState.error,
		isLoadingSearch: true,
	}))
	.handleAction(actions.searchAttachments.success, (state, action) => ({
		...state,
		searchData: action.payload,
		isLoadingSearch: false,
	}))
	.handleAction(actions.searchAttachments.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingSearch: false,
	}));
