import { MyCourseAndSyllabusResponse } from '@nimbly-technologies/nimbly-common';
import { SortDirection } from 'reducers/lms/userManagement/userList/type';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './myCourseAndSyllabuses.actionTypes';
import { MyCoursesAndSyllabusesFilters, MyCoursesAndSyllabusesSortFields } from './type';

export const setMyCoursesAndSyllabusesPageIndex = (page: number) =>
	action(types.SET_MY_COURSES_AND_SYLLABUSES_PAGE_INDEX, { page });

export const setMyCoursesAndSyllabusesSearchQuery = (searchQuery: string) =>
	action(types.SET_MY_COURSES_AND_SYLLABUSES_SEARCH_QUERY, { searchQuery });

export const setMyCoursesAndSyllabusesSort = (
	sortField: MyCoursesAndSyllabusesSortFields,
	sortDirection: SortDirection,
) => action(types.SET_MY_COURSES_AND_SYLLABUSES_SORT, { sortField, sortDirection });

export const setMyCoursesAndSyllabusesFilters = (filters: Partial<MyCoursesAndSyllabusesFilters>) =>
	action(types.SET_MY_COURSES_AND_SYLLABUSES_FILTER_QUERY, { filters });

export const resetMyCoursesAndSyllabusesFilters = () => action(types.RESET_MY_COURSES_AND_SYLLABUSES_FILTER_QUERY);

export const fetchMyCoursesAndSyllabuses = createAsyncAction(
	types.FETCH_MY_COURSES_AND_SYLLABUSES_REQUEST,
	types.FETCH_MY_COURSES_AND_SYLLABUSES_SUCCESS,
	types.FETCH_MY_COURSES_AND_SYLLABUSES_FAILED,
)<
	undefined,
	{
		myCoursesAndSyllabuses: MyCourseAndSyllabusResponse[];
		totalDocs?: number;
		page?: number;
		totalPages?: number;
	},
	{
		error: string;
	}
>();
