import {
  IssuesOverviewCardsResponse,
  DashboardOverviewPayload
} from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesOverviewCards = IssuesOverviewCardsResponse['data'];

export interface IssuesOverviewCardsState {
  readonly issuesOverviewCards_loading: boolean;
  readonly issuesOverviewCards_data: IssuesOverviewCards;
  readonly issuesOverviewCards_error: string;
}

export const FETCH_ISSUES_OVERVIEW_CARDS_REQUEST = '@@dashboard/overview/FETCH_ISSUES_OVERVIEW_CARDS_REQUEST';
export const FETCH_ISSUES_OVERVIEW_CARDS_SUCCESS = '@@dashboard/overview/FETCH_ISSUES_OVERVIEW_CARDS_SUCCESS';
export const FETCH_ISSUES_OVERVIEW_CARDS_FAILURE = '@@dashboard/overview/FETCH_ISSUES_OVERVIEW_CARDS_FAILURE';

export type FetchIssuesOverviewCardsPayload = DashboardOverviewPayload;
export type FetchIssuesOverviewCardsResponse = IssuesOverviewCardsResponse | ErrorResponse;
