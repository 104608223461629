/* eslint-disable complexity */
import { put } from 'typed-redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import * as actions from './issueInsights.action';
import { IssueCountData, IssueInsightsData, IssueStatusData, IssueTableList } from './issueInsightsStore';
import {
	fetchIssueInsightDetails,
	fetchIssueInsightList,
	fetchIssueInsightChartDetails,
	fetchIssueInsightTableList,
} from 'services/dashboardRevamp/issueInsights/issueInsights.service';

function* fetchIssueInsightDetailSaga(
	action: ReturnType<typeof actions.fetchIssueInsightDetailAsync.request>,
): Generator {
	try {
		const res: unknown = yield call(fetchIssueInsightDetails);
		yield put(actions.fetchIssueInsightDetailAsync.success(res as IssueInsightsData));
	} catch {
		yield put(actions.fetchIssueInsightDetailAsync.failure('[ERROR] Failed to get Issue Insight Dashboard Details'));
	}
}

function* fetchIssueInsightListSaga(action: ReturnType<typeof actions.fetchIssueInsightListAsync.request>): Generator {
	try {
		const res: unknown = yield call(fetchIssueInsightList);
		yield put(actions.fetchIssueInsightListAsync.success(res as IssueCountData[]));
	} catch {
		yield put(actions.fetchIssueInsightListAsync.failure('[ERROR] Failed to get Issue Insight List'));
	}
}

function* fetchIssueChartSaga(action: ReturnType<typeof actions.fetchIssueChartAsync.request>): Generator {
	try {
		const res: unknown = yield call(fetchIssueInsightList);
		yield put(actions.fetchIssueChartAsync.success(res as IssueStatusData[]));
	} catch {
		yield put(actions.fetchIssueChartAsync.failure('[ERROR] Failed to get Issue Chart'));
	}
}

function* fetchIssueChartDetailSaga(action: ReturnType<typeof actions.fetchIssueChartDetailsAsync.request>): Generator {
	try {
		const res: unknown = yield call(fetchIssueInsightChartDetails);
		yield put(actions.fetchIssueChartDetailsAsync.success(res as IssueStatusData[]));
	} catch {
		yield put(actions.fetchIssueChartDetailsAsync.failure('[ERROR] Failed to get Issue Chart'));
	}
}

function* fetchIssueTableDataSaga(action: ReturnType<typeof actions.fetchIssueTableDataAsync.request>): Generator {
	try {
		const res: unknown = yield call(fetchIssueInsightTableList);
		yield put(actions.fetchIssueTableDataAsync.success(res as IssueTableList));
	} catch {
		yield put(actions.fetchIssueTableDataAsync.failure('[ERROR] Failed to get Issue Table List'));
	}
}

export default function* issueInsightsDashboardSaga() {
	yield takeEvery(actions.fetchIssueInsightDetailAsync.request, fetchIssueInsightDetailSaga);
	yield takeEvery(actions.fetchIssueInsightListAsync.request, fetchIssueInsightListSaga);
	yield takeEvery(actions.fetchIssueChartAsync.request, fetchIssueChartSaga);
	yield takeEvery(actions.fetchIssueChartDetailsAsync.request, fetchIssueChartDetailSaga);
	yield takeEvery(actions.fetchIssueTableDataAsync.request, fetchIssueTableDataSaga);
}
