import { ActionType, createReducer } from 'typesafe-actions';
import { UserGroupListState, USER_GROUPS_FETCH_STATE } from './type.d';
import * as actions from './userGroupList.action';
import * as types from './userGroupList.actionTypes';

export type CreateUserGroupAction = ActionType<typeof actions>;

export const userGroupInitialState: UserGroupListState = {
	pageIndex: 1,
	limit: 15,
	search: '',
	totalUserGroups: 0,
	userGroupList: [],
	fetchState: USER_GROUPS_FETCH_STATE.SUCCESS,
	sortBy: 'groupName',
	sortDirection: 'asc',
	selectedUserGroupCount: 0,
	isLoading: false,
	isSuccess: false,
};

const getUserGroupListReducer = createReducer<UserGroupListState, CreateUserGroupAction>(userGroupInitialState)
	.handleType(types.GET_USER_GROUP_LIST_REQUEST, (state) => ({
		...state,
		limit: userGroupInitialState.limit,
		fetchState: USER_GROUPS_FETCH_STATE.LOADING,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleType(types.GET_USER_GROUP_LIST_SUCCESS, (state, action) => ({
		...state,
		userGroupList: action.payload.docs,
		fetchState: USER_GROUPS_FETCH_STATE.SUCCESS,
		isLoading: false,
		totalUserGroups: action.payload.totalDocs || 0,
		isSuccess: true,
	}))
	.handleType(types.GET_USER_GROUP_LIST_FAILURE, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleType(types.SET_USER_GROUP_LIST_PAGE_INDEX, (state, action) => ({
		...state,
		pageIndex: action.payload.pageIndex,
		fetchState: USER_GROUPS_FETCH_STATE.LOADING,
	}))
	.handleType(types.SET_USER_GROUP_LIST_SEARCH_QUERY, (state, action) => ({
		...state,
		search: action.payload.search,
		fetchState: USER_GROUPS_FETCH_STATE.LOADING,
		isLoading: true,
	}))
	.handleType(types.SET_USER_GROUP_LIST_SORT, (state, action) => ({
		...state,
		sortBy: action.payload.sortBy,
		sortDirection: action.payload.sortDirection,
		fetchState: USER_GROUPS_FETCH_STATE.LOADING,
	}))
	.handleType(types.SET_USER_GROUP_LIST_FILTER_QUERY, (state, action) => ({
		...state,
		filters: action.payload.filters,
		fetchState: USER_GROUPS_FETCH_STATE.LOADING,
	}))
	.handleType(types.SET_SHOW_MODAL, (state, action) => ({
		...state,
		isShowModal: action.payload.value,
	}))
	.handleType(types.SET_SELECTED_USER_GROUP_COUNT, (state, action) => ({
		...state,
		selectedUserGroupCount: action.payload.value,
	}))
	.handleType(types.SET_SUCCESS, (state, action) => {
		return {
			...state,
			isSuccess: action.payload.value,
		};
	})
	.handleType(types.RESET_USER_GROUP_FILTER, (state) => {
		return {
			...state,
			...userGroupInitialState,
		};
	});

export { getUserGroupListReducer };
