import React, { Component } from 'react';
import styled from 'styled-components/macro';

import './SortingArrow.css';
import ChevronVerticalIcon from 'assets/icon/sidebar/sort-icon-vertical.svg';

export default class SortingArrow extends Component {
	// eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
	render() {
		const { active, direction } = this.props;

		return (
			<Wrapper>
				<Arrow src={ChevronVerticalIcon} active={active && direction === 'asc'} direction="asc" />
				<Arrow src={ChevronVerticalIcon} active={active && direction === 'desc'} direction="desc" />
			</Wrapper>
		);
	}
}

const Wrapper = styled.span`
	display: inline-flex;
	flex-direction: column;
	padding-right: 0.5rem;
	position: relative;
	cursor: pointer;
`;

const Arrow = styled.img`
	position: relative;
	margin-top: 2px;
	transform: ${(props) => (props.direction === 'desc' ? 'rotate(180deg)' : 'rotate(0)')};
	opacity: ${(props) => (props.active ? 1 : 0.3)};
`;
