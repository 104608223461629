import {
  FetchSiteIssueStatListPayload,
  FetchSiteIssueStatListResponse,
  SitesIssueList
} from 'reducers/dashboard/sites/issueList/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getSitesIssueList } from 'utils/dashboardDummy/sites/dummySitesIssueList';

type IssueListData = {
  message?: 'Invalid date Query';
  issuesData: SitesIssueList;
};

export const fetchIssueList = async (
  payload: FetchSiteIssueStatListPayload
): Promise<FetchSiteIssueStatListResponse> => {
  const { isDummy, ...query } = payload;

  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummySitesIssueList = getSitesIssueList(payload);
    return {
      message: 'SUCCESS',
      data: dummySitesIssueList
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/issues/sites?${serializedQueries}`;

    const res = await fetch(url, options);
    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const issueListResult: IssueListData = await res.json();
    return {
      message: 'SUCCESS',
      data: issueListResult.issuesData
    };
  } catch {
    // ERROR
    const errorResult: FetchSiteIssueStatListResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
