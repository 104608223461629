import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './siteUser.action';
import { siteUserCompactState } from './type';

const initialState: siteUserCompactState = {
	isLoading: false,
	data: [],
	message: '',
};

export type siteUserCompactAction = ActionType<typeof actions.siteUserAction>;
export const siteUserCompactReducer = createReducer<siteUserCompactState, siteUserCompactAction>(initialState)
	.handleAction(actions.siteUserAction.request, (state) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(actions.siteUserAction.success, (state, action) => ({
		...state,
		data: action.payload.data,
		message: action.payload.message,
		isLoading: false,
	}))
	.handleAction(actions.siteUserAction.failure, (state, action) => ({
		...state,
		message: action.payload.message,
		isLoading: false,
	}));
