export const CLEAR_FILTER_QUERY = '@sites/CLEAR_FILTER_QUERY';
export const DISMISS_BULK_MODAL = '@sites/DISMISS_BULK_MODAL';
export const DISMISS_BULK_SCHEDULE = '@sites/DISMISS_BULK_SCHEDULE';
export const DISMISS_BULK_SCHEDULE_EDIT = '@sites/DISMISS_BULK_SCHEDULE_EDIT';
export const DISMISS_CONFIRM_BLOCK_MODAL = '@sites/DISMISS_CONFIRM_BLOCK_MODAL';
export const DISMISS_SCHEDULE_MODAL = '@sites/DISMISS_SCHEDULE_MODAL';
export const GET_POPULATED_SITES = '@sites/GET_POPULATED_SITES';

export const SET_COLOR = '@sites/SET_SITES_COLOR';
export const SET_TOTAL_SITES = '@sites/SET_TOTAL_SITES';
export const SET_TOTAL_ACTIVE = '@sites/SET_TOTAL_ACTIVE';
export const SET_TOTAL_DISABLED = '@sites/SET_TOTAL_DISABLED';
export const SET_ERROR = '@sites/SET_ERROR';
export const SET_FILTER_QUERY = '@sites/SET_FILTER_QUERY';
export const SET_IS_CONFIRMATION_LOADING = '@sites/SET_IS_CONFIRMATION_LOADING';
export const SET_IS_LOADING = '@sites/SET_IS_LOADING';
export const SET_PAGE_INDEX = '@sites/SET_PAGE_INDEX';
export const SET_PROCESSED_SITES = '@sites/SET_PROCESSED_SITES';
export const SET_SCROLL = '@sites/SET_SCROLL';
export const SET_SITES = '@sites/SET_SITES';
export const SET_SITES_PER_PAGE = '@sites/SET_SITES_PER_PAGE';
export const SET_SITE_TAB = '@sites/SET_TAB';
export const SET_SITE_LIST_TAB = '@sites/SET_LIST_TAB';
export const SET_SORT = '@sites/SET_SITES_SORT';
export const SET_IS_SITES_LOADED = '@sites/SET_IS_SITES_LOADED';
export const SHOW_BULK_MODAL = '@sites/SHOW_BULK_MODAL';
export const SHOW_BULK_SCHEDULE = '@sites/SHOW_BULK_SCHEDULE';
export const SHOW_BULK_SCHEDULE_EDIT = '@sites/SHOW_BULK_SCHEDULE_EDIT';
export const SHOW_CONFIRM_BLOCK_MODAL = '@sites/SHOW_CONFIRM_BLOCK_MODAL';
export const SHOW_SCHEDULE_MODAL = '@sites/SHOW_SCHEDULE_MODAL';
export const RESET_SITE = '@sites/RESET_SITE';
export const RESET_SITE_SETTINGS = '@sites/RESET_SITE_SETTINGS';

export const FETCH_SITES_REQUEST = '@sites/FETCH_SITES_REQUEST';
export const FETCH_SITES_SUCCESS = '@sites/FETCH_SITES_SUCCESS';
export const FETCH_SITES_FAILURE = '@sites/FETCH_SITES_FAILURE';

export const FETCH_SITE_BY_ID_REQUEST = '@sites/FETCH_SITE_BY_ID_REQUEST';
export const FETCH_SITE_BY_ID_SUCCESS = '@sites/FETCH_SITE_BY_ID_SUCCESS';
export const FETCH_SITE_BY_ID_FAILURE = '@sites/FETCH_SITE_BY_ID_FAILURE';

export const FETCH_SITE_DEPARTMENTS_REQUEST = '@sites/FETCH_SITE_DEPARTMENTS_REQUEST';
export const FETCH_SITE_DEPARTMENTS_SUCCESS = '@sites/FETCH_SITE_DEPARTMENTS_SUCCESS';
export const FETCH_SITE_DEPARTMENTS_FAILURE = '@sites/FETCH_SITE_DEPARTMENTS_FAILURE';

export const UPDATE_SITE_DEPARTMENTS_REQUEST = '@sites/UPDATE_SITE_DEPARTMENTS_REQUEST';
export const UPDATE_SITE_DEPARTMENTS_SUCCESS = '@sites/UPDATE_SITE_DEPARTMENTS_SUCCESS';
export const UPDATE_SITE_DEPARTMENTS_FAILURE = '@sites/UPDATE_SITE_DEPARTMENTS_FAILURE';

export const FETCH_PAGINATES_SITES_REQUEST = '@sites/FETCH_PAGINATES_SITES_REQUEST';
export const FETCH_PAGINATES_SITES_SUCCESS = '@sites/FETCH_PAGINATES_SITES_SUCCESS';
export const FETCH_PAGINATES_SITES_FAILURE = '@sites/FETCH_PAGINATES_SITES_FAILURE';
