import { apiURL } from 'config/baseURL';

const postCheckIsSandbox = async (email: string) => {
	try {
		const options: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		};
		const url = `${apiURL}/auth/sandbox/is-sandbox-organization`;
		await fetch(url, options);
	} catch (error) {
		// intended due to browser logging
		console.error(error);
	}
};

export default postCheckIsSandbox;
