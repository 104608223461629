import React from 'react';
import { Container, Tab } from './MobileTabs.style';

import { TabConfig } from './MobileTabs.types';
import CollapsibleMobileTab from './CollapsibleMobileTab';

export interface MobileTabsProps {
	tabConfigs: TabConfig[];
}

const MobileTabs = (props: MobileTabsProps) => {
	const render = () => {
		return (
			<Container>
				{props.tabConfigs.map((tabConfig) => {
					if (tabConfig.subTabs) {
						return <CollapsibleMobileTab key={tabConfig.key} tabConfig={tabConfig} />;
					} else {
						return renderTab(tabConfig);
					}
				})}
			</Container>
		);
	};

	const renderTab = (tabConfig: TabConfig) => {
		return tabConfig.isVisible ? (
			<Tab key={tabConfig.key} onClick={tabConfig.onClick} isActive={tabConfig.isActive}>
				{tabConfig.icon}
				<span>{tabConfig.title}</span>
			</Tab>
		) : null;
	};

	return render();
};

export default MobileTabs;
