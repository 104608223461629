import styled, { keyframes } from 'styled-components/macro';
import { FilledButton } from 'styles/Button';

interface ErrorMessageProps {
	mode: any;
}

interface InputProps {
	hasError?: boolean;
	value?: any;
	name?: string;
	placeholder?: string;
	validation?: boolean;
	type?: string;
	required?: boolean;
}

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const InputLogin = styled.input<InputProps>`
	background: #fff;
	border-radius: 5px;
	width: 297px;
	height: 40px;
	border: ${(props: InputProps) =>
		!props.value ? `1px solid #c4c4c4` : !props.hasError ? `#34CA65 solid 1px` : '#ed5565 solid 1px'};
	padding: 0px 25px;
`;

const InputLoginPassword = styled.input<InputProps>`
	background: #fff;
	border-radius: 5px;
	width: 297px;
	height: 40px;
	border: ${(props: InputProps) =>
		!props.value ? `1px solid #c4c4c4` : !props.hasError ? `#34CA65 1px solid` : '#ed5565 solid 1px'};
	padding: 0px 25px;

	disabled: {
		background: #efeeed;
	}
`;

const ButtonLogin = styled(FilledButton)<{ isRequestAccess: boolean; isFederated: boolean }>`
	display: ${(props) => (props.isRequestAccess ? 'none' : 'auto')};
	margin-top: ${(props) => (props.isFederated ? '85px' : '32px')};
	padding: 0.375rem 2.75rem;
`;

const ButtonRequestAccess = styled.button<{ isRequestAccess: boolean }>`
	display: ${(props) => (props.isRequestAccess ? 'auto' : 'none')};
	border: 1px solid #574fcf;
	background: transparent;
	color: #574fcf;
	transition: 0.5s;
	font-size: 14px;
	font-weight: 600;
	width: 145px;
	border-radius: 30px;
	height: 32px;
	margin-top: 30px;

	:hover {
		background: #574fcf;
		color: #ffffff;
		font-weight: 'bold';
	}

	:disabled {
		background: #efeeed;
		color: #c4c4c4;
		border: 0;
	}
`;

const Container = styled.div<ErrorMessageProps>`
	background: #fff;
	width: 100%;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	.logo {
		display: none;
		@media (min-width: 992px) {
			display: block;
		}
	}

	.fade-in {
		animation: ${fadeIn} 1s linear;
	}

	.email-forgot-pass {
		margin-bottom: 20px;
	}

	.error-message {
		margin-top: 10px;
		margin-bottom: ${(props: ErrorMessageProps) => (props.mode === true ? '20px' : '0px')};
		span {
			font-size: 10px;
			font-style: italic;
			line-height: 12px;
			color: #ed5565;
			height: 13px;
		}
	}

	.success-message {
		margin-top: 10px;
		margin-bottom: ${(props: ErrorMessageProps) => (props.mode === true ? '20px' : '0px')};
		span {
			font-size: 10px;
			font-style: italic;
			line-height: 12px;
			color: #34ca65;
			height: 13px;
		}
	}

	.greeting {
		margin-top: 220px;
		font-weight: bold;
		font-size: 96px;
		line-height: 52px;
		letter-spacing: 0.01em;
		color: #34ca65;

		@media (min-width: 321px) {
			margin-top: 50px;
		}
	}

	.login {
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		line-height: 52px;
		color: #34ca65;
		margin-top: 40px;

		span {
			color: #34ca65;
		}
	}

	.description {
		width: 276px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.05em;
		margin-top: 21px;
	}

	.input-login {
		margin-top: 32px;
		input:first-child {
			margin-bottom: 10px;
		}
	}

	.version {
		font-weight: 100;
		font-size: 8px;
		line-height: 18px;
		letter-spacing: 0.05em;
		position: absolute;
		bottom: 0;

		span {
			color: #cacccd;
		}
	}

	.container-forgot-pass {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		cursor: pointer;
		height: 15px;

		> .forgot-password {
			font-weight: bold;
			font-size: 10px;
			line-height: 15px;
			text-align: right;
			color: #574fcf;
		}
	}
`;

export { fadeIn, InputLogin, InputLoginPassword, ButtonLogin, Container, ButtonRequestAccess };
