import { createAsyncAction, deprecated } from 'typesafe-actions';

import {
  CREATE_SITE_REQUEST,
  CREATE_SITE_SUCCESS,
  CREATE_SITE_FAILURE,
  CreateSitePayload,
  UPDATE_SITE_REQUEST,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAILURE,
  UpdateSitePayload,
  UpsertedSiteID,
  TOGGLE_BLOCK_SITE_REQUEST,
  TOGGLE_BLOCK_SITE_SUCCESS,
  TOGGLE_BLOCK_SITE_FAILURE,
  CHECK_SITE_SCHEDULE_COUNT_REQUEST,
  CHECK_SITE_SCHEDULE_COUNT_SUCCESS,
  CHECK_SITE_SCHEDULE_COUNT_FAILURE,
  CheckSiteScheduleCountPayload,
  CheckedSiteSchedules,
  CLEAR_SITE_STATE,
  ToggleBlockSitePayload,
  BlockedSiteID,
  CLEAR_TOGGLE_BLOCK_SITE_STATE
} from './types';

const { createStandardAction } = deprecated;

export const createSiteAsync = createAsyncAction(CREATE_SITE_REQUEST, CREATE_SITE_SUCCESS, CREATE_SITE_FAILURE)<
  CreateSitePayload,
  UpsertedSiteID,
  string
>();

export const updateSiteAsync = createAsyncAction(UPDATE_SITE_REQUEST, UPDATE_SITE_SUCCESS, UPDATE_SITE_FAILURE)<
  UpdateSitePayload,
  UpsertedSiteID,
  string
>();
export const checkSiteScheduleCountAsync = createAsyncAction(
  CHECK_SITE_SCHEDULE_COUNT_REQUEST,
  CHECK_SITE_SCHEDULE_COUNT_SUCCESS,
  CHECK_SITE_SCHEDULE_COUNT_FAILURE
)<CheckSiteScheduleCountPayload, CheckedSiteSchedules, string>();
export const toggleBlockSiteAsync = createAsyncAction(
  TOGGLE_BLOCK_SITE_REQUEST,
  TOGGLE_BLOCK_SITE_SUCCESS,
  TOGGLE_BLOCK_SITE_FAILURE
)<ToggleBlockSitePayload, BlockedSiteID, string>();

export const clearSiteState = createStandardAction(CLEAR_SITE_STATE)<undefined>();
export const clearToggleBlockSiteState = createStandardAction(CLEAR_TOGGLE_BLOCK_SITE_STATE)<undefined>();
