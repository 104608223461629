import { takeLatest, put, call, all, select, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import { SalesTargetState } from 'reducers/salesTarget/type';
import { getToken } from 'reducers/api';
import * as types from 'reducers/salesTarget/salesTarget.actionTypes';
import * as actions from 'reducers/salesTarget/salesTarget.action';
import * as Common from 'nimbly-common';
import i18n from 'i18n';
import { apiURL } from 'config/baseURL';
import { RootState } from 'store/rootReducers';

// SELECTORS
const salesTargetRoot = (state: RootState) => state.salestarget;

export function* fetchPaginateSalesTarget(action: ReturnType<typeof actions.fetchPaginateSalestargetAsync.request>) {
  try {
    const authToken = yield getToken();
    const salesTargetState: SalesTargetState = yield select(salesTargetRoot);

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };
    let sortDirections: string = 'asc';

    switch (salesTargetState.sortBy) {
      case 'name':
        sortDirections = salesTargetState.name;
        break;
      case 'primaryDepartment':
        sortDirections = salesTargetState.primaryDepartment;
        break;
      case 'auditor':
        sortDirections = salesTargetState.auditor;
        break;
      case 'sales':
        sortDirections = salesTargetState.sales;
        break;
      case 'salesTarget':
        sortDirections = salesTargetState.salesTarget;
        break;
      case 'progress':
        sortDirections = salesTargetState.progress;
        break;
      default:
        break;
    }
    const month = salesTargetState.period.month;
    const year = salesTargetState.period.year;
    const startDate = moment().year(year).month(month).date(1);
    const endDate = moment().year(year).month(month).date(1);

    const query: { [key: string]: string | number } = {
      search: salesTargetState.filterQuery || '',
      page: salesTargetState.pageIndex,
      sortFields: salesTargetState.sortBy,
      sortDirections,
      limit: 15,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.endOf('month').format('YYYY-MM-DD')
      //   tab: salesTargetState.selectedTab
    };

    const queryStr = Object.keys(query).length
      ? Object.keys(query)
          .map((key: string) => {
            return `${key}=${query[key]}`;
          })
          .join('&')
      : '';

    const url = `${apiURL}/sales/?${queryStr}`;
    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      yield put(
        actions.fetchPaginateSalestargetAsync.success({
          data: responseData.data.docs,
          total: responseData.data.totalDocs
        })
      );
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchPaginateSalestargetAsync.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchPaginateSalestargetAsync.success({ data: null, total: 0 }));
    yield put(
      actions.fetchPaginateSalestargetAsync.failure({
        error: i18n.t('message.salesPage.error.general.fetchError')
      })
    );
    return null;
  }
}

// Sales

export function* fetchSalesDetail(action: ReturnType<typeof actions.fetchSalesBySiteIdAsync.request>) {
  try {
    const authToken = yield getToken();
    const salesTargetState: SalesTargetState = yield select(salesTargetRoot);
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };
    const month = salesTargetState.period.month;
    const year = salesTargetState.period.year;
    const startDate = moment().year(year).month(month).date(1);
    const endDate = moment().year(year).month(month).date(1);
    const dateFormat = 'YYYY-MM-DD';
    const query: { [key: string]: string | number } = {
      startDate: startDate.format(dateFormat),
      endDate: endDate.endOf('month').format(dateFormat)
      //   tab: salesTargetState.selectedTab
    };

    const queryStr = Object.keys(query).length
      ? Object.keys(query)
          .map((key: string) => {
            return `${key}=${query[key]}`;
          })
          .join('&')
      : '';

    const url = `${apiURL}/sales/${action.payload}?${queryStr}`;
    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      const mappingData: { [key: string]: number } = {};

      responseData.data.forEach((salesData: Common.Sales) => {
        mappingData[salesData.date] = salesData.sales;
      });
      yield put(actions.fetchSalesBySiteIdAsync.success({ data: mappingData }));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchSalesBySiteIdAsync.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchSalesBySiteIdAsync.success({ data: {} }));
    yield put(
      actions.fetchSalesBySiteIdAsync.failure({
        error: i18n.t('message.salesPage.error.sales.fetchError')
      })
    );
    return null;
  }
}

function* createSales(action: ReturnType<typeof actions.createSalesBySiteIdAsync.request>) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const url = `${apiURL}/sales/${payload.key}`;
    const sendPayload = {
      sales: payload.sales,
      date: payload.date
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(sendPayload)
    };

    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      yield put(actions.createSalesBySiteIdAsync.success({ data: 'Success', payload }));
      yield fork(toast.success, i18n.t('message.salesPage.success.sales.create'));
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.createSalesBySiteIdAsync.failure({ error: responseData.message }));
      yield fork(toast.error, responseData.message);
      return null;
    }
  } catch (e) {
    yield fork(toast.error, i18n.t('message.salesPage.error.sales.create'));
    yield put(actions.createSalesBySiteIdAsync.failure({ error: i18n.t('message.salesPage.error.sales.create') }));
    return null;
  }
}

function* updateSales(action: ReturnType<typeof actions.updateSalesBySiteIdAsync.request>) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const url = `${apiURL}/sales/${payload.key}/${payload.date}`;
    const sendPayload = {
      sales: payload.sales
    };
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(sendPayload)
    };

    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      yield put(actions.updateSalesBySiteIdAsync.success({ data: responseData.data }));
      yield fork(toast.success, i18n.t('message.salesPage.success.sales.create'));
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.updateSalesBySiteIdAsync.failure({ error: responseData.message }));
      yield fork(toast.error, responseData.message);
      return null;
    }
  } catch (e) {
    yield fork(toast.error, i18n.t('message.salesPage.error.sales.update'));
    yield put(actions.updateSalesBySiteIdAsync.failure({ error: i18n.t('message.salesPage.error.sales.update') }));
    return null;
  }
}

function* createSalesBulk(action: ReturnType<typeof actions.createSalesBulkAsync.request>) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const url = `${apiURL}/sales/bulk/${payload.period}`;
    let newBulkData = new FormData();
    newBulkData.append('sales', payload.file);
    const options = {
      method: 'POST',
      headers: {
        Authorization: authToken
      },
      body: newBulkData
    };

    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      yield put(actions.createSalesBulkAsync.success({ data: 'success' }));
      yield fork(toast.success, i18n.t('message.salesPage.success.sales.bulk'));
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.createSalesBulkAsync.failure({ error: responseData.data }));
      yield fork(toast.error, responseData.message);
      return null;
    }
  } catch (e) {
    yield fork(toast.error, i18n.t('message.salesPage.error.sales.bulk'));
    yield put(actions.createSalesBulkAsync.failure({ error: [i18n.t('message.salesPage.error.sales.bulk')] }));
    return null;
  }
}

export function* fetchSalesTargetDetail(action: ReturnType<typeof actions.fetchSalesTargetBySiteIdAsync.request>) {
  try {
    const authToken = yield getToken();
    const salesTargetState: SalesTargetState = yield select(salesTargetRoot);
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };
    const month = salesTargetState.period.month;
    const year = salesTargetState.period.year;
    const startDate = moment().year(year).month(month).date(1);
    const endDate = moment().year(year).month(month).date(1);
    const query: { [key: string]: string | number } = {
      startDate: startDate.format('YYYY-DD-MM'),
      endDate: endDate.endOf('month').format('YYYY-DD-MM')
      //   tab: salesTargetState.selectedTab
    };

    const queryStr = Object.keys(query).length
      ? Object.keys(query)
          .map((key: string) => {
            return `${key}=${query[key]}`;
          })
          .join('&')
      : '';

    const url = `${apiURL}/sales/target/${action.payload}?${queryStr}`;
    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();
      const mappingData: { [key: string]: number } = {};

      responseData.data.forEach((salesTarget: Common.SalesTarget) => {
        mappingData[salesTarget.date] = salesTarget.target;
      });
      yield put(actions.fetchSalesTargetBySiteIdAsync.success({ data: mappingData }));
      return responseData;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchSalesTargetBySiteIdAsync.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    yield put(actions.fetchSalesTargetBySiteIdAsync.success({ data: {} }));
    yield put(
      actions.fetchSalesTargetBySiteIdAsync.failure({
        error: i18n.t('message.salesPage.error.salesTarget.fetchError')
      })
    );
    return null;
  }
}

function* createSalesTarget(action: ReturnType<typeof actions.createSalesTargetBySiteIdAsync.request>) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const url = `${apiURL}/sales/target/${payload.key}`;
    const sendPayload = {
      target: payload.target,
      date: payload.date
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(sendPayload)
    };
    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      yield put(actions.createSalesTargetBySiteIdAsync.success({ data: 'Success', payload }));
      yield fork(toast.success, i18n.t('message.salesPage.success.salesTarget.create'));
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.createSalesTargetBySiteIdAsync.failure({ error: responseData.message }));
      yield fork(toast.error, responseData.message);
      return null;
    }
  } catch (e) {
    yield fork(toast.error, i18n.t('message.salesPage.error.salesTarget.create'));
    yield put(
      actions.createSalesTargetBySiteIdAsync.failure({
        error: i18n.t('message.salesPage.error.salesTarget.create')
      })
    );
    return null;
  }
}

function* updateSalesTarget(action: ReturnType<typeof actions.updateSalesTargetBySiteIdAsync.request>) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const url = `${apiURL}/sales/target/${payload.key}/${payload.date}`;
    const sendPayload = {
      target: payload.target
    };
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(sendPayload)
    };

    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      yield put(actions.updateSalesTargetBySiteIdAsync.success({ data: responseData.data }));
      yield fork(toast.success, i18n.t('message.salesPage.success.salesTarget.update'));
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.updateSalesTargetBySiteIdAsync.failure({ error: responseData.message }));
      yield fork(toast.error, responseData.message);
      return null;
    }
  } catch (e) {
    yield fork(toast.error, i18n.t('message.salesPage.error.salesTarget.update'));
    yield put(
      actions.updateSalesTargetBySiteIdAsync.failure({ error: i18n.t('message.salesPage.error.salesTarget.update') })
    );
    return null;
  }
}

function* createSalesTargetBulk(action: ReturnType<typeof actions.createSalesTargetBulkAsync.request>) {
  try {
    const authToken = yield getToken();
    const payload = { ...action.payload };
    const url = `${apiURL}/sales/target/bulk/${payload.period}`;
    let newBulkData = new FormData();
    newBulkData.append('sales', payload.file);
    const options = {
      method: 'POST',
      headers: {
        Authorization: authToken
      },
      body: newBulkData
    };

    const request = () => fetch(url, options);
    const response = yield call(request);

    if (response && response.status === 200) {
      const responseData = yield response.json();

      yield put(actions.createSalesTargetBulkAsync.success({ data: 'success' }));
      yield fork(toast.success, i18n.t('message.salesPage.success.salesTarget.bulk'));
      return null;
    } else {
      const responseData = yield response.json();
      yield put(actions.createSalesTargetBulkAsync.failure({ error: responseData.message }));
      yield fork(toast.error, responseData.message);
      return null;
    }
  } catch (e) {
    yield fork(toast.error, i18n.t('message.salesPage.error.salesTarget.bulk'));
    yield put(
      actions.createSalesTargetBulkAsync.failure({ error: i18n.t('message.salesPage.error.salesTarget.bulk') })
    );
    return null;
  }
}

export default function* salesTargetSaga() {
  // All
  yield takeLatest(types.FETCH_PAGINATE_SALESTARGET_REQUEST, fetchPaginateSalesTarget);
  // Sales
  yield takeLatest(types.FETCH_SALES_BY_ID_REQUEST, fetchSalesDetail);
  yield takeLatest(types.CREATE_SALES_BY_ID_REQUEST, createSales);
  yield takeLatest(types.UPDATE_SALES_BY_ID_REQUEST, updateSales);
  yield takeLatest(types.CREATE_SALES_BULK_REQUEST, createSalesBulk);
  // Sales Target
  yield takeLatest(types.FETCH_SALESTARGET_BY_ID_REQUEST, fetchSalesTargetDetail);
  yield takeLatest(types.CREATE_SALESTARGET_BY_ID_REQUEST, createSalesTarget);
  yield takeLatest(types.UPDATE_SALESTARGET_BY_ID_REQUEST, updateSalesTarget);
  yield takeLatest(types.CREATE_SALESTARGET_BULK_REQUEST, createSalesTargetBulk);
}
