import { GetScheduleActivityPaginate } from '@nimbly-technologies/nimbly-common';
import { SortDirection } from 'utils/sort';

export enum SiteScheduleActivitySortField {
	timestamp = 'timestamp',
	questionnaire = 'questionnaire',
	user = 'user',
	oldConfiguration = 'oldConfiguration',
	newConfiguration = 'newConfiguration',
	operation = 'operation',
	source = 'source',
}

export interface SiteScheduleActivityFilters {
	userIDs: string[];
	questionnaireIndexIDs: string[];
	operations: string[];
	sources: string[];
	startDate: string | null;
	endDate: string | null;
}

export interface SiteScheduleActivityState {
	error: string;
	isLoading: boolean;
	page: number;
	limit: number;
	totalPages: number;
	totalDocs: number;
	siteID: string;

	siteScheduleActivity: GetScheduleActivityPaginate[];
	sortFields: SiteScheduleActivitySortField;
	sortDirections: SortDirection;
	filters: SiteScheduleActivityFilters;
}
