import { FILE_REPO_API_URL } from 'config/baseURL';
import API from 'helpers/api';
import { RepositoryOrganizationUsageResponse } from 'reducers/repositoryOrganizationUsage/type.d';

export const getData = async (url: string): Promise<any> => {
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw new Error(json.message);
	}
	return json.data;
};

export const fetchRepositoryOrganizationUsage = async (
	organizationID: string,
): Promise<RepositoryOrganizationUsageResponse> => {
	const url = `${FILE_REPO_API_URL}/tracker/${organizationID}`;
	return await getData(url);
};
