import { createAsyncAction } from 'typesafe-actions';
import {
  IssuesOverviewCards,
  FETCH_ISSUES_OVERVIEW_CARDS_REQUEST,
  FETCH_ISSUES_OVERVIEW_CARDS_SUCCESS,
  FETCH_ISSUES_OVERVIEW_CARDS_FAILURE,
  FetchIssuesOverviewCardsPayload
} from './types';

export const fetchIssuesOverviewCardsAsync = createAsyncAction(
  FETCH_ISSUES_OVERVIEW_CARDS_REQUEST,
  FETCH_ISSUES_OVERVIEW_CARDS_SUCCESS,
  FETCH_ISSUES_OVERVIEW_CARDS_FAILURE
)<FetchIssuesOverviewCardsPayload, IssuesOverviewCards, string>();
