export const SET_PAGE_INDEX_REPORT = '@siteReports/SET_PAGE_INDEX_REPORT';
export const SET_REPORTS = '@siteReports/SET_REPORTS';
export const SET_FILTERED_REPORTS = '@siteReports/SET_FILTERED_REPORTS';
export const SET_IS_LOADING = '@siteReports/SET_IS_LOADING';
export const SET_IS_FETCHING = '@siteReports/SET_IS_FETCHING';
export const SET_SORT_TYPE = '@siteReports/SET_SORT_TYPE';
export const SET_COLOR_CHANGE = '@siteReports/SET_COLOR_CHANGE';
export const SET_REPORT_USER_FILTERS = '@siteReports/SET_REPORT_USER_FILTERS';
export const SET_REPORT_QUESTIONNAIRE_FILTERS = '@siteReports/SET_REPORT_QUESTIONNAIRE_FILTERS';
export const SET_REPORT_TYPE_FILTER = '@siteReports/SET_REPORT_TYPE_FILTER';
export const SET_ISSUE_IDS = '@siteReports/SET_ISSUE_IDS';
export const CLEAR_ISSUE_IDS = '@siteReports/CLEAR_ISSUE_IDS';

export const SET_PERIOD_TYPE = '@siteReports/SET_PERIOD_TYPE';
export const SET_CUSTOM_DATES = '@siteReports/SET_CUSTOM_DATES';
export const SET_DATES = '@siteReports/SET_DATES';
export const SET_PREVIOUS_DATES = '@siteReports/SET_PREVIOUS_DATES';
export const RESET_SITE_REPORT_FILTER_DATES = '@siteReports/RESET_SITE_REPORT_FILTER_DATES';

export const FETCH_SITE_REPORTS_REQUEST = '@siteReports/FETCH_SITE_REPORTS_REQUEST';
export const FETCH_SITE_REPORTS_SUCCESS = '@siteReports/FETCH_SITE_REPORTS_SUCCESS';
export const FETCH_SITE_REPORTS_FAILURE = '@siteReports/FETCH_SITE_REPORTS_FAILURE';
