export const SET_LOADING_STATE = '@siteSchedule/SET_LOADING_STATE';

// Single Site Scheduling
export const SET_INITIAL_SCHEDULES = '@siteSchedule/SET_INITIAL_SCHEDULES';
export const SET_SINGLE_AUDIT_SCHEDULE_LIST = '@siteSchedule/SET_SINGLE_AUDIT_SCHEDULE_LIST';

export const SET_TEAM_AUDIT_SCHEDULE = '@siteSchedule/SET_TEAM_AUDIT_SCHEDULE';
export const ADD_TEAM_AUDIT_QUESTIONNAIRE = '@siteSchedule/ADD_TEAM_AUDIT_QUESTIONNAIRE';

export const SET_SELECTED_QUESTIONNAIRE_SCHEDULE = '@siteSchedule/SET_SELECTED_QUESTIONNAIRE_SCHEDULE';
export const REMOVE_SELECTED_QUESTIONNAIRE_SCHEDULE = '@siteSchedule/REMOVE_SELECTED_QUESTIONNAIRE_SCHEDULE';

export const ADD_QUESTIONNAIRE_SCHEDULE = '@siteSchedule/ADD_QUESTIONNAIRE_SCHEDULE';
export const SPLICE_SELECTED_SCHEDULE = '@siteSchedule/SPLICE_SELECTED_SCHEDULE';
export const TOGGLE_QUESTIONNAIRE_SCHEDULE_DATE = '@siteSchedule/TOGGLE_QUESTIONNAIRE_SCHEDULE_DATE';
export const SET_QUESTIONNAIRE_SCHEDULE_TYPE = '@siteSchedule/SET_QUESTIONNAIRE_SCHEDULE_TYPE';
export const SET_QUESTIONNAIRE_SCHEDULE_FREQUENCY = '@siteSchedule/SET_QUESTIONNAIRE_SCHEDULE_FREQUENCY';
export const ADD_QUESTIONNAIRE_SCHEDULE_WEEKDAY = '@siteSchedule/ADD_QUESTIONNAIRE_SCHEDULE_WEEKDAY';
export const REMOVE_QUESTIONNAIRE_SCHEDULE_WEEKDAY = '@siteSchedule/REMOVE_QUESTIONNAIRE_SCHEDULE_WEEKDAY';
export const ADD_QUESTIONNAIRE_SCHEDULE_MONTH_DATE = '@siteSchedule/ADD_QUESTIONNAIRE_SCHEDULE_MONTH_DATE';
export const REMOVE_QUESTIONNAIRE_SCHEDULE_MONTH_DATE = '@siteSchedule/REMOVE_QUESTIONNAIRE_SCHEDULE_MONTH_DATE';
export const ADD_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET = '@siteSchedule/ADD_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET';
export const UPDATE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET = '@siteSchedule/UPDATE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET';
export const REMOVE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET = '@siteSchedule/REMOVE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET';
export const SET_QUESTIONNAIRE_SCHEDULE_SIGNATURES = '@siteSchedule/SET_QUESTIONNAIRE_SCHEDULE_SIGNATURES';
export const SET_QUESTIONNAIRE_DEADLINE_AUDITOR = '@siteSchedule/SET_QUESTIONNAIRE_DEADLINE_AUDITOR';
export const SET_QUESTIONNAIRE_DEADLINE_TIME = '@siteSchedule/SET_QUESTIONNAIRE_DEADLINE_TIME';
export const SET_QUESTIONNAIRE_DEADLINE_REMINDER_TIME = '@siteSchedule/SET_QUESTIONNAIRE_DEADLINE_REMINDER_TIME';
export const SET_QUESTIONNAIRE_DEADLINE_STATUS = '@siteSchedule/SET_QUESTIONNAIRE_DEADLINE_STATUS';
export const SET_QUESTIONNAIRE_SCHEDULES_ERRORS = '@siteSchedule/SET_QUESTIONNAIRE_SCHEDULES_ERRORS';
export const SET_QUESTIONNAIRE_SELFIE_SIGNATURE = '@siteSchedule/SET_QUESTIONNAIRE_SELFIE_SIGNATURE';
export const SET_QUESTIONNAIRE_SELFIE_SIGNATURE_TITLE = '@siteSchedule/SET_QUESTIONNAIRE_SELFIE_SIGNATURE_TITLE';
export const SET_QUESTIONNAIRE_SCHEDULE_START_DATE = '@siteSchedule/SET_QUESTIONNAIRE_SCHEDULE_START_DATE';
export const SET_QUESTIONNAIRE_SCHEDULE_END_DATE = '@siteSchedule/SET_QUESTIONNAIRE_SCHEDULE_END_DATE';

export const UPDATE_QUESTIONNAIRE_SCHEDULE_VALIDATION = '@siteSchedule/UPDATE_QUESTIONNAIRE_SCHEDULE_VALIDATION';
export const REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION = '@siteSchedule/REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION';
export const REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION_PROPERTY =
	'@siteSchedule/REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION_PROPERTY';

export const CLEAR_SITE_SCHEDULE_STATE = '@siteSchedule/CLEAR_SITE_SCHEDULE_STATE';

export const SET_HAS_CHANGES = '@siteDetails/SET_HAS_CHANGES';

// After Schedule improvement
export const RESET = '@siteSchedule/RESET';

export const SET_ACTIVE_DEPARTMENT = '@siteSchedule/SET_ACTIVE_DEPARTMENT';

export const SELECT_SCHEDULE = '@siteSchedule/SELECT_SCHEDULE';
export const REMOVE_SELECTED_SCHEDULE = '@siteSchedule/REMOVE_SELECTED_SCHEDULE';
export const UPDATE_SELECTED_SCHEDULE_STATE = '@siteSchedule/UPDATE_SELECTED_SCHEDULE_STATE';
export const PUSH_NEW_SCHEDULE = '@siteSchedule/PUSH_NEW_SCHEDULE';

export const SET_SELECTED_SCHEDULE_TYPE = '@siteSchedule/SET_SELECTED_SCHEDULE_TYPE';

export const SET_SELECTED_SCHEDULE_REPEAT_TYPE = '@siteSchedule/SET_SELECTED_SCHEDULE_REPEAT_TYPE';

export const SET_SELECTED_SCHEDULE_DAYS_OF_WEEK = '@siteSchedule/SET_SELECTED_SCHEDULE_DAYS_OF_WEEK';
export const SET_SELECTED_SCHEDULE_DATES_OF_MONTH = '@siteSchedule/SET_SELECTED_SCHEDULE_DATES_OF_MONTH';
export const SET_SELECTED_SCHEDULE_DATES_CUSTOM = '@siteSchedule/SET_SELECTED_SCHEDULE_DATES_CUSTOM';
export const SET_SELECTED_SCHEDULE_HAS_DEADLINE = '@siteSchedule/SET_SELECTED_SCHEDULE_HAS_DEADLINE';
export const SET_SELECTED_SCHEDULE_ASSIGNED_AUDITOR = '@siteSchedule/SET_SELECTED_SCHEDULE_ASSIGNED_AUDITOR';
export const SET_SELECTED_SCHEDULE_ISSUE_OWNER = '@siteSchedule/SET_SELECTED_SCHEDULE_ISSUE_OWNER';
export const SET_SELECTED_SCHEDULE_START_TIME = '@siteSchedule/SET_SELECTED_SCHEDULE_START_TIME';
export const SET_SELECTED_SCHEDULE_END_TIME = '@siteSchedule/SET_SELECTED_SCHEDULE_END_TIME';
export const SET_SELECTED_SCHEDULE_REMINDER_TIME = '@siteSchedule/SET_SELECTED_SCHEDULE_REMINDER_TIME';
export const SET_SELECTED_SCHEDULE_START_DATE = '@siteSchedule/SET_SELECTED_SCHEDULE_START_DATE';
export const SET_SELECTED_SCHEDULE_END_DATE = '@siteSchedule/SET_SELECTED_SCHEDULE_END_DATE';
export const SET_SELECTED_SCHEDULE_SIGNATURES = '@siteSchedule/SET_SELECTED_SCHEDULE_SIGNATURES';
export const SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE = '@siteSchedule/SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE';
export const SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE_TITLE =
	'@siteSchedule/SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE_TITLE';

export const TOGGLE_SELECTED_SCHEDULE_SYNCHRONIZE_CALENDAR =
	'@siteSchedule/TOGGLE_SELECTED_SCHEDULE_SYNCHRONIZE_CALENDAR';

export const SET_SELECTED_SCHEDULE_EMAIL_TARGET = '@siteSchedule/SET_SELECTED_SCHEDULE_EMAIL_TARGET';
export const ADD_SELECTED_SCHEDULE_EMAIL_TARGET = '@siteSchedule/ADD_SELECTED_SCHEDULE_EMAIL_TARGET';
export const REMOVE_SELECTED_SCHEDULE_EMAIL_TARGET = '@siteSchedule/REMOVE_SELECTED_SCHEDULE_EMAIL_TARGET';

// export const SET_SELECTED_SCHEDULE_EMAIL = '@siteSchedule/SET_SELECTED_SCHEDULE_EMAIL';

export const ADD_NEW_SCHEDULE_REQUEST = '@siteSchedule/ADD_NEW_SCHEDULE_REQUEST';
export const ADD_NEW_SCHEDULE_SUCCESS = '@siteSchedule/ADD_NEW_SCHEDULE_SUCCESS';
export const ADD_NEW_SCHEDULE_FAILURE = '@siteSchedule/ADD_NEW_SCHEDULE_FAILURE';

// Saga types
export const FETCH_SITE_SCHEDULES_REQUEST = '@siteSchedule/FETCH_SITE_SCHEDULES_REQUEST';
export const FETCH_SITE_SCHEDULES_SUCCESS = '@siteSchedule/FETCH_SITE_SCHEDULES_SUCCESS';
export const FETCH_SITE_SCHEDULES_FAILURE = '@siteSchedule/FETCH_SITE_SCHEDULES_FAILURE';

export const UPLOAD_SITE_SCHEDULES_REQUEST = '@siteSchedule/UPLOAD_SITE_SCHEDULES_REQUEST';
export const UPLOAD_SITE_SCHEDULES_SUCCESS = '@siteSchedule/UPLOAD_SITE_SCHEDULES_SUCCESS';
export const UPLOAD_SITE_SCHEDULES_FAILURE = '@siteSchedule/UPLOAD_SITE_SCHEDULES_FAILURE';

export const UPLOAD_TEAM_SITE_SCHEDULES_REQUEST = '@siteSchedule/UPLOAD_TEAM_SITE_SCHEDULES_REQUEST';
export const UPLOAD_TEAM_SITE_SCHEDULES_SUCCESS = '@siteSchedule/UPLOAD_TEAM_SITE_SCHEDULES_SUCCESS';
export const UPLOAD_TEAM_SITE_SCHEDULES_FAILURE = '@siteSchedule/UPLOAD_TEAM_SITE_SCHEDULES_FAILURE';

export const SYNC_SITE_SCHEDULES_CALENDAR_REQUEST = '@siteSchedule/SYNC_SITE_SCHEDULES_CALENDAR_REQUEST';
export const SYNC_SITE_SCHEDULES_CALENDAR_SUCCESS = '@siteSchedule/SYNC_SITE_SCHEDULES_CALENDAR_SUCCESS';
export const SYNC_SITE_SCHEDULES_CALENDAR_FAILURE = '@siteSchedule/SYNC_SITE_SCHEDULES_CALENDAR_FAILURE';

export const IMPORT_SCHEDULES_REQUEST = '@siteSchedule/IMPORT_SCHEDULES_REQUEST';
export const IMPORT_SCHEDULES_SUCCESS = '@siteSchedule/IMPORT_SCHEDULES_SUCCESS';
export const IMPORT_SCHEDULES_FAILURE = '@siteSchedule/IMPORT_SCHEDULES_FAILURE';

export const SET_FIRST_SCHEDULE_START_DATE = '@siteSchedule/SET_FIRST_SCHEDULE_START_DATE';
export const SET_FIRST_SCHEDULE_END_DATE = '@siteSchedule/SET_FIRST_SCHEDULE_END_DATE';
export const SET_REPEATING_TYPE = '@siteSchedule/SET_REPEATING_TYPE';
export const SET_SCHEDULE_START_AT = '@siteSchedule/SET_SCHEDULE_START_AT';

export const SET_OCCURENCE_NUMBER = '@siteSchedule/SET_OCCURENCE_NUMBER';
export const SET_REPEATING_END_DATE = '@siteSchedule/SET_REPEATING_END_DATE';
export const SET_SELECTED_SCHEDULE_EMAIL = '@siteSchedule/SET_SELECTED_SCHEDULE_EMAIL';

export const SET_UPDATE_ALL_END_TIME = '@siteSchedule/SET_UPDATE_ALL_END_TIME';
export const SET_ALLOW_ADHOC_REPORT = '@siteSchedule/SET_ALLOW_ADHOC_REPORT';
export const SET_ALLOW_RESTRICT_TIME = '@siteSchedule/SET_ALLOW_RESTRICT_TIME';
export const SET_SELECTED_SCHEDULE_REMINDER_PERIOD = '@siteSchedule/SET_SELECTED_SCHEDULE_REMINDER_PERIOD';

export const SET_ALLOW_CUSTOM_ADHOC_ONLY = '@siteSchedule/SET_ALLOW_CUSTOM_ADHOC_ONLY';

export const SET_CUSTOMV2_ADD_ELEMENT = '@siteSchedule/SET_CUSTOMV2_ADD_ELEMENT';
export const SET_CUSTOMV2_DELETE_ELEMENT = '@siteSchedule/SET_CUSTOMV2_DELETE_ELEMENT';
export const SET_CUSTOMV2_START_DATE = '@siteSchedule/SET_CUSTOMV2_START_DATE';
export const SET_CUSTOMV2_END_DATE = '@siteSchedule/SET_CUSTOMV2_END_DATE';

export const SET_ALLOW_ENFORCE_CHECKIN = '@siteSchedule/SET_ALLOW_ENFORCE_CHECKIN';
export const SET_ALLOW_ENFORCE_CHECKOUT = '@siteSchedule/SET_ALLOW_ENFORCE_CHECKOUT';

export const SET_CUSTOMV2_ADD_DATE = '@siteSchedule/SET_CUSTOMV2_ADD_DATE';

export const SET_FILTER_STATUS = '@siteSchedule/SET_FILTER_STATUS';

export const TOGGLE_ALLOW_ACTIVE_SCHEDULE_PERIOD = '@siteSchedule/TOGGLE_ALLOW_ACTIVE_SCHEDULE_PERIOD';
export const SET_ACTIVE_SCHEDULE_PERIOD_UNIT = '@siteSchedule/SET_ACTIVE_SCHEDULE_PERIOD_UNIT';
export const SET_ACTIVE_SCHEDULE_PERIOD_LENGTH = '@siteSchedule/SET_ACTIVE_SCHEDULE_PERIOD_LENGTH';

export const SET_SELECTED_SCHEDULE_DEPARTMENT = '@siteSchedule/SET_SELECTED_SCHEDULE_DEPARTMENT';

export const SET_SCHEDULE_SUPERVISOR = '@siteSchedule/SET_SCHEDULE_SUPERVISOR';
