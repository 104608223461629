import {
	AvailableCourseAndSyllabusResponse,
	Course,
	InProgressCourseAndSyllabusResponse,
	PaginationResult,
	MyCourseAndSyllabusResponse,
} from '@nimbly-technologies/nimbly-common';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	enrollLmsCourseSyllabus,
	fetchLmsCoursesSyllabusesAvailable,
	fetchLmsCoursesSyllabusesInProgress,
	fetchLmsCoursesSyllabusesRecentlyViewed,
	IFetchLmsCoursesSyllabusesAvailableParams,
	IFetchLmsCoursesSyllabusesInProgressParams,
	TCoursesSyllabusesRecentlyViewedResponse,
	TFetchLmsCoursesSyllabusesRecentlyViewedParams,
	IFetchLmsMyCoursesSyllabusesParams,
	fetchLmsMyCoursesSyllabuses,
	fetchLmsIndicator,
} from 'services/lms/lms.service';
import { RootState } from 'store/rootReducers';
import { fetchLmsCourseByIdAsync, setLmsSelectedFile } from '../course/lmsCourse.action';
import { fetchLmsLearnerActivityByIdAsync } from '../learnerActivity/lmsLearnerActivity.action';
import * as actions from './coursesSyllabuses.action';
import * as types from './coursesSyllabuses.actionTypes';
import {
	ILmsCoursesSyllabusesStore,
	TAvailableCoursesAndSyllabuses,
	TInProgressCoursesAndSyllabuses,
	TRecentlyViewedCoursesAndSyllabuses,
	TMyCourseAndSyllabusResponse,
} from './coursesSyllabuses.type';
import { fetchLmsCoursesSyllabuses } from './utils/fetchLmsCoursesSyllabuses';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { IDashboardLessonStatusStore } from '../lmsDashboard/lmsDashboard.type';
const lmsCoursesSyllabusesSelector = (state: RootState) => state.lmsCoursesSyllabuses;

function* fetchLmsCoursesSyllabusesAvailableSaga(
	action: ReturnType<typeof actions.fetchLmsCoursesSyllabusesAvailableAsync.request>,
): Generator {
	try {
		const state = (yield select(lmsCoursesSyllabusesSelector) as unknown) as ILmsCoursesSyllabusesStore;
		const { data: prevData, query: prevQuery, search, sortFields, filters } = state.fetchAvailable;

		const res = (yield fetchLmsCoursesSyllabuses<
			IFetchLmsCoursesSyllabusesAvailableParams,
			TAvailableCoursesAndSyllabuses,
			AvailableCourseAndSyllabusResponse
		>({
			loadMore: action.payload?.loadMore,
			nextPage: action.payload?.page,
			extraQuery: { search, sortFields, ...filters },
			fetchData: fetchLmsCoursesSyllabusesAvailable,
			prevData: prevData ?? [],
			prevQuery,
			currentQuery: action.payload,
		}) as unknown) as PaginationResult<AvailableCourseAndSyllabusResponse>;

		yield put(actions.fetchLmsCoursesSyllabusesAvailableAsync.success(res));
	} catch {
		yield put(actions.fetchLmsCoursesSyllabusesAvailableAsync.failure('Failed to get available courses & syllabuses'));
	}
}

function* fetchLmsCoursesSyllabusesInProgressSaga(
	action: ReturnType<typeof actions.fetchLmsCoursesSyllabusesInProgressAsync.request>,
): Generator {
	try {
		const state = (yield select(lmsCoursesSyllabusesSelector) as unknown) as ILmsCoursesSyllabusesStore;
		const { data: prevData, query: prevQuery } = state.fetchInProgress;

		const res = (yield fetchLmsCoursesSyllabuses<
			IFetchLmsCoursesSyllabusesInProgressParams,
			TInProgressCoursesAndSyllabuses,
			InProgressCourseAndSyllabusResponse
		>({
			loadMore: action.payload?.loadMore,
			fetchData: fetchLmsCoursesSyllabusesInProgress,
			prevData: prevData ?? [],
			prevQuery,
			currentQuery: action.payload,
		}) as unknown) as PaginationResult<InProgressCourseAndSyllabusResponse>;

		yield put(actions.fetchLmsCoursesSyllabusesInProgressAsync.success(res));
	} catch {
		yield put(
			actions.fetchLmsCoursesSyllabusesInProgressAsync.failure('Failed to get in proggress courses & syllabuses'),
		);
	}
}

function* fetchLmsCoursesSyllabusesRecentlyViewedSaga(
	action: ReturnType<typeof actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.request>,
): Generator {
	try {
		const state = (yield select(lmsCoursesSyllabusesSelector) as unknown) as ILmsCoursesSyllabusesStore;
		const { data: prevData, query: prevQuery } = state.fetchRecentlyViewed;

		const res = (yield fetchLmsCoursesSyllabuses<
			TFetchLmsCoursesSyllabusesRecentlyViewedParams,
			TRecentlyViewedCoursesAndSyllabuses,
			TCoursesSyllabusesRecentlyViewedResponse
		>({
			loadMore: action.payload?.loadMore,
			fetchData: fetchLmsCoursesSyllabusesRecentlyViewed,
			prevData: prevData ?? [],
			prevQuery,
			currentQuery: action.payload,
		}) as unknown) as PaginationResult<TCoursesSyllabusesRecentlyViewedResponse>;

		yield put(actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.success(res));
	} catch {
		yield put(
			actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.failure(
				'Failed to get recently viewed courses & syllabuses',
			),
		);
	}
}

function* enrollLmsCoursesSyllabusesSaga(
	action: ReturnType<typeof actions.enrollLmsCoursesSyllabusesAsync.request>,
): Generator {
	const { courseID, syllabusID } = action.payload;
	const courseOrSyllabusID = courseID ?? syllabusID ?? '';

	try {
		const query = () => enrollLmsCourseSyllabus(action.payload);
		yield call(query);

		const selectedCourse = (yield select((state: RootState) => state.lmsLearnerCourse.fetchById.data) as unknown) as
			| Course
			| undefined;
		if (selectedCourse?.courseID) {
			yield put(fetchLmsCourseByIdAsync.request(selectedCourse.courseID));
			yield put(
				fetchLmsLearnerActivityByIdAsync.request({
					courseOrSyllabusId: courseOrSyllabusID,
				}),
			);
			yield put(setLmsSelectedFile(selectedCourse.chapters[0].files[0]));
		}

		yield put(actions.fetchLmsCoursesSyllabusesAvailableAsync.request({}));
		yield put(actions.fetchLmsCoursesSyllabusesInProgressAsync.request({}));
		yield put(actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.request({}));

		yield put(
			actions.enrollLmsCoursesSyllabusesAsync.success({
				courseOrSyllabusID,
			}),
		);
		toast.success(i18n.t('message.lms.courseAndSyllabus.enroll.success'));
	} catch {
		yield put(
			actions.enrollLmsCoursesSyllabusesAsync.failure({
				courseOrSyllabusID,
				errorMessage: 'Failed to enroll courses or syllabuses',
			}),
		);
	}
}

function* fetchLmsMyCoursesSyllabusesSaga(
	action: ReturnType<typeof actions.fetchLmsMyCoursesSyllabusesAsync.request>,
): Generator {
	try {
		const state = (yield select(lmsCoursesSyllabusesSelector) as unknown) as ILmsCoursesSyllabusesStore;
		const {
			data: prevData,
			query: prevQuery,
			search,
			sortFields,
			filters,
			sortDirections,
		} = state.fetchMyCoursesSyllabuses;

		const res = (yield fetchLmsCoursesSyllabuses<
			IFetchLmsMyCoursesSyllabusesParams,
			TMyCourseAndSyllabusResponse,
			MyCourseAndSyllabusResponse
		>({
			loadMore: action.payload?.loadMore,
			nextPage: action.payload?.page,
			extraQuery: { search, sortFields, sortDirections },
			fetchData: fetchLmsMyCoursesSyllabuses,
			prevData: prevData ?? [],
			prevQuery,
			currentQuery: action.payload,
		}) as unknown) as PaginationResult<MyCourseAndSyllabusResponse>;

		yield put(actions.fetchLmsMyCoursesSyllabusesAsync.success(res));
	} catch {
		yield put(actions.fetchLmsMyCoursesSyllabusesAsync.failure('Failed to get my courses & lessons'));
	}
}

function* fetchLmsIndicatorSaga(action: ReturnType<typeof actions.fetchLmsIndicatorAsync.request>): Generator {
	try {
		const res = (yield call(fetchLmsIndicator) as unknown) as IDashboardLessonStatusStore;

		yield put(actions.fetchLmsIndicatorAsync.success(res));
	} catch {
		yield put(actions.fetchLmsIndicatorAsync.failure('Failed to get dashboard details'));
	}
}

export default function* lmsCoursesSyllabusesSaga() {
	yield takeLatest(actions.fetchLmsCoursesSyllabusesAvailableAsync.request, fetchLmsCoursesSyllabusesAvailableSaga);
	yield takeLatest(types.SET_COURSES_AND_SYLLABUSES_AVAILABLE_SEARCH, fetchLmsCoursesSyllabusesAvailableSaga);
	yield takeLatest(types.SET_COURSES_AND_SYLLABUSES_AVAILABLE_SORT, fetchLmsCoursesSyllabusesAvailableSaga);
	yield takeLatest(types.SET_COURSES_AND_SYLLABUSES_AVAILABLE_FILTERS, fetchLmsCoursesSyllabusesAvailableSaga);
	yield takeLatest(types.RESET_COURSES_AND_SYLLABUSES_AVAILABLE_FILTERS, fetchLmsCoursesSyllabusesAvailableSaga);
	yield takeEvery(actions.fetchLmsCoursesSyllabusesAvailableAsync.request, fetchLmsCoursesSyllabusesAvailableSaga);
	yield takeEvery(actions.fetchLmsCoursesSyllabusesInProgressAsync.request, fetchLmsCoursesSyllabusesInProgressSaga);
	yield takeEvery(
		actions.fetchLmsCoursesSyllabusesRecentlyViewedAsync.request,
		fetchLmsCoursesSyllabusesRecentlyViewedSaga,
	);
	yield takeLatest(actions.fetchLmsCoursesSyllabusesInProgressAsync.request, fetchLmsCoursesSyllabusesInProgressSaga);
	yield takeLatest(actions.enrollLmsCoursesSyllabusesAsync.request, enrollLmsCoursesSyllabusesSaga);
	yield takeLatest(actions.fetchLmsMyCoursesSyllabusesAsync.request, fetchLmsMyCoursesSyllabusesSaga);
	yield takeLatest(types.SET_MY_COURSES_AND_SYLLABUSES_SEARCH, fetchLmsMyCoursesSyllabusesSaga);
	yield takeEvery(actions.fetchLmsMyCoursesSyllabusesAsync.request, fetchLmsMyCoursesSyllabusesSaga);
	yield takeEvery(actions.fetchLmsIndicatorAsync.request, fetchLmsIndicatorSaga);
}
