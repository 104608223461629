// utilities
import API from 'helpers/api';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { takeLatest, put, call, delay, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import i18n from 'i18n';

// constants and data types
import { apiURL } from 'config/baseURL';
import { RootState } from 'store/rootReducers';
import { APIResponse } from 'constants/ResponseCollection';
import { DepartmentGroup } from 'nimbly-common';

// related redux
import * as types from 'reducers/departmentGroup/departmentGroup.actionTypes';
import * as actions from 'reducers/departmentGroup/departmentGroup.action';

// SELECTORS
const departmentGroupSelector = (state: RootState) => state.departmentGroup.departmentGroup;
const currentlyActiveIndexSelector = (state: RootState) => state.departmentGroup.currentlyActiveIndex;

function* createDepartmentGroup(action: ReturnType<typeof actions.createDepartmentGroup.request>) {
  try {
    const departmentGroup: DepartmentGroup[] = yield select(departmentGroupSelector) || [];
    const departmentGroupList: DepartmentGroup[] = cloneDeep(departmentGroup);
    const body = action.payload.data;

    const authToken = yield API.getFirebaseToken();
    const url = `${apiURL}/departments/group`;
    const response = yield call(API.post, url, authToken, body);
    const responseData = yield call(response.json.bind(response));

    if (response && response.status === APIResponse.CODE.SUCCESS) {
      const groupDetail: DepartmentGroup = responseData.data;
      groupDetail.ID = responseData.data.ID;
      departmentGroupList.push(groupDetail);
      yield put(actions.createDepartmentGroup.success({ newData: departmentGroupList }));
      toast.success(i18n.t('message.departmentGroupPage.create.success'));
      yield delay(1000);
      yield put(push('/admin/departmentsgroup/' + groupDetail.ID));
    } else {
      yield put(actions.createDepartmentGroup.failure({ error: response.message }));
      toast.error(i18n.t('message.departmentGroupPage.create.failed'));
    }
  } catch (e) {
    toast.error(i18n.t('message.departmentGroupPage.create.failed'));
    yield put(actions.createDepartmentGroup.failure({ error: 'Failed to create Department Group' }));
  }
}

function* updateDepartmentGroup(action: ReturnType<typeof actions.updateDepartmentGroup.request>) {
  try {
    const departmentGroup: DepartmentGroup[] = yield select(departmentGroupSelector) || [];
    const departmentGroupList: DepartmentGroup[] = cloneDeep(departmentGroup);
    let currentlySelected: number = yield select(currentlyActiveIndexSelector);
    const authToken = yield API.getFirebaseToken();
    const groupDeptID = action.payload.groupDeptID;
    const body = action.payload.data;
    body.status = 'active';

    if (currentlySelected < 0) {
      currentlySelected = departmentGroupList.findIndex(d => d.ID === groupDeptID);
    }

    const url = `${apiURL}/departments/group/${groupDeptID}`;
    const response = yield call(API.post, url, authToken, body);

    if (response && response.status === APIResponse.CODE.SUCCESS) {
      if (departmentGroupList) {
        departmentGroupList[currentlySelected] = { ...departmentGroupList[currentlySelected], ...body };
      }
      yield put(actions.updateDepartmentGroup.success({ newDeptGroup: departmentGroupList }));
      toast.success(i18n.t('message.departmentGroupPage.update.success'));
    } else {
      const responseData = yield response.json();
      yield put(actions.updateDepartmentGroup.failure({ error: responseData.message }));
      toast.error(i18n.t('message.departmentGroupPage.update.failed'));
    }
  } catch (e) {
    toast.error(i18n.t('message.departmentGroupPage.update.failed'));
    yield put(actions.updateDepartmentGroup.failure({ error: 'Failed to update Department Group' }));
  }
}

export default function* departmentGroupEditorSaga() {
  yield takeLatest(types.CREATE_DEPARTMENT_GROUP_REQUEST, createDepartmentGroup);
  yield takeLatest(types.UPDATE_DEPARTMENT_GROUP_REQUEST, updateDepartmentGroup);
}
