export const SHOW_MODAL = 'auditors/SHOW_MODAL';
export const DISMISS_MODAL = 'auditors/DISMISS_MODAL';
export const SET_FILTER_QUERY = 'auditors/SET_FILTER_QUERY';
export const CLEAR_FILTER_QUERY = 'auditors/CLEAR_FILTER_QUERY';
export const SET_SORT = 'auditors/SET_AUDITOR_SORT';
export const SET_SCROLL = 'auditors/SET_AUDITOR_SCROLL';
export const SHOW_MODAL_BULK = 'auditors/SHOW_MODAL_BULK';
export const DISMISS_MODAL_BULK = 'auditors/DISMISS_MODAL_BULK';
export const SHOW_SUPERADMIN_MODAL_BULK = 'SHOW_SUPERADMIN_MODAL_BULK';
export const DISMISS_SUPERADMIN_MODAL_BULK = 'DISMISS_SUPERADMIN_MODAL_BULK';
export const SHOW_MODAL_DELETE = 'auditors/SHOW_MODAL_DELETE';
export const DISMISS_MODAL_DELETE = 'auditors/DISMISS_MODAL_DELETE';
export const SHOW_MODAL_DOWNLOAD = 'auditors/SHOW_MODAL_DOWNLOAD';
export const DISMISS_MODAL_DOWNLOAD = 'auditors/DISMISS_MODAL_DOWNLOAD';
export const DOWNLOAD_LOADING = 'auditors/DOWNLOAD_LOADING';
export const SET_AUDITOR_TAB = 'auditors/SET_AUDITOR_TAB';
export const SET_PAGE_INDEX = 'auditors/SET_PAGE_INDEX';
export const SET_AUDITOR_DOWNLOAD = 'auditors/SET_AUDITOR_DOWNLOAD';
export const RESET_PAGINATE_FILTER = 'auditors/RESET_PAGINATE_FILTER';
