import { ActionType, createReducer } from 'typesafe-actions';
import { ExecutiveStore } from './executive.types';
import * as actions from './executive.action';
import * as types from './executive.actionTypes';
import { DashboardTabs } from 'pages/dashboardRevamp/dashboardUtils';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
const initialAPIData = {
	isLoading: false,
	isSuccess: false,
	data: null,
};

const initialState: ExecutiveStore = {
	type: DashboardTabs.Issues,
	showMap: false,
	showFilters: false,
	filters: {
		startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		sites: [],
		departments: [],
		users: [],
		questionnaires: [],
		categories: [],
		roles: [],
	},
	departmentRatio: initialAPIData,
	irrRatio: initialAPIData,
	rcrRatio: initialAPIData,
	vocRatio: initialAPIData,
	heatmapIssueFlags: initialAPIData,
	searchTrigger: '',
	lastUpdated: moment().format('HH:mm'),
	lastSelectedWidget: {
		issuesConfig: null,
		reportsConfig: null,
	},
};

export type ExecutiveAction = ActionType<typeof actions>;

export const executiveDashboardReducer = createReducer<ExecutiveStore, ExecutiveAction>(initialState)
	.handleAction(actions.fetchDepartmentRatioAsync.request, (state) => ({
		...state,
		departmentRatio: {
			data: null,
			isLoading: true,
			isSuccess: true,
		},
	}))
	.handleAction(actions.fetchDepartmentRatioAsync.success, (state, action) => ({
		...state,
		departmentRatio: {
			data: action.payload || '',
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchDepartmentRatioAsync.failure, (state, action) => ({
		...state,
		...state,
		departmentRatio: {
			data: null,
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchIrrRatioAsync.request, (state) => ({
		...state,
		irrRatio: {
			data: null,
			isLoading: true,
			isSuccess: true,
		},
	}))
	.handleAction(actions.fetchIrrRatioAsync.success, (state, action) => ({
		...state,
		irrRatio: {
			data: action.payload,
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchIrrRatioAsync.failure, (state, action) => ({
		...state,
		...state,
		irrRatio: {
			data: null,
			isLoading: false,
			isSuccess: false,
		},
	}))

	.handleAction(actions.fetchRcrRatioAsync.request, (state) => ({
		...state,
		rcrRatio: {
			data: null,
			isLoading: true,
			isSuccess: true,
		},
	}))
	.handleAction(actions.fetchRcrRatioAsync.success, (state, action) => ({
		...state,
		rcrRatio: {
			data: action.payload,
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchRcrRatioAsync.failure, (state, action) => ({
		...state,
		...state,
		rcrRatio: {
			data: null,
			isLoading: false,
			isSuccess: false,
		},
	}))

	.handleAction(actions.fetchVocRatioAsync.request, (state) => ({
		...state,
		vocRatio: {
			data: null,
			isLoading: true,
			isSuccess: true,
		},
	}))
	.handleAction(actions.fetchVocRatioAsync.success, (state, action) => ({
		...state,
		vocRatio: {
			data: action.payload,
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchVocRatioAsync.failure, (state, action) => ({
		...state,
		...state,
		vocRatio: {
			data: null,
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchHeatmapIssueFlagsAsync.request, (state) => ({
		...state,
		heatmapIssueFlags: {
			data: null,
			isLoading: true,
			isSuccess: true,
		},
	}))
	.handleAction(actions.fetchHeatmapIssueFlagsAsync.success, (state, action) => ({
		...state,
		heatmapIssueFlags: {
			data: action.payload,
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchHeatmapIssueFlagsAsync.failure, (state, action) => ({
		...state,
		...state,
		heatmapIssueFlags: {
			data: null,
			isLoading: false,
			isSuccess: false,
		},
	}))
	.handleAction(types.SET_DASHBOARD_TYPE as any, (state, action) => {
		return {
			...state,
			type: action.payload,
		};
	})
	.handleAction(types.SET_SHOW_MAP as any, (state, action) => {
		return {
			...state,
			showMap: action.payload,
		};
	})
	.handleAction(types.SET_SHOW_FILTERS as any, (state, action) => {
		return {
			...state,
			showFilters: action.payload,
		};
	})
	.handleAction(types.SET_MULTI_SELECT as any, (state, action) => {
		return {
			...state,
			filters: {
				...state.filters,
				[action.payload.key]: action.payload.value,
			},
		};
	})
	.handleAction(types.SET_FILTER_DATE as any, (state, action) => {
		return {
			...state,
			filters: {
				...state.filters,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			},
		};
	})
	.handleAction(types.SET_FILTERS as any, (state, action) => {
		return {
			...state,
			filters: action.payload.filters,
		};
	})
	.handleAction(types.SET_SEARCH_TRIGGER as any, (state) => {
		return {
			...state,
			searchTrigger: uuidv4(),
		};
	})
	.handleAction(types.SET_RESET_FILTER as any, (state) => {
		return {
			...state,
			filters: initialState.filters,
			searchTrigger: uuidv4(),
		};
	})

	.handleAction(types.SET_LAST_UPDATE as any, (state) => {
		return {
			...state,
			lastUpdated: moment().format('HH:mm'),
		};
	})
	.handleAction(types.SET_LAST_SELECTED_WIDGET as any, (state, action) => {
		return {
			...state,
			lastSelectedWidget: action.payload,
		};
	});
