import React from 'react';

import logoURL from '../assets/img/icon-nimbly.png';
import LoadingDots from '../components/global/LoadingDots/LoadingDots';

// Loading placeholder page
// Used mostly to allow Firebase to load authentication

const LoadingPage = () => {
  return (
    <div
      className="page-root"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img src={logoURL} className="page-logo" alt="Nimbly Logo" draggable="false" />
      <LoadingDots />
    </div>
  );
};

export default LoadingPage;
