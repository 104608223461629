import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './skuCategory.action';
import { SkuCategoryDataState, SkuCategoryState } from './type.d';

const initialState: SkuCategoryState = {
	dataState: SkuCategoryDataState.LOADING,
	skuCategories: [],
};

export type SkuCategoryAction = ActionType<typeof actions>;
export const skuCategoryReducer = createReducer<SkuCategoryState, SkuCategoryAction>(initialState)
	.handleAction(actions.fetchSkuCategories.request, () => ({
		dataState: SkuCategoryDataState.LOADING,
		skuCategories: [],
		errorMessage: '',
	}))
	.handleAction(actions.fetchSkuCategories.success, (state, action) => ({
		dataState: SkuCategoryDataState.SUCCESS,
		skuCategories: action.payload,
		errorMessage: '',
	}))
	.handleAction(actions.fetchSkuCategories.failure, (state, action) => ({
		dataState: SkuCategoryDataState.FAILURE,
		skuCategories: [],
		errorMessage: action.payload,
	}))
	.handleAction(actions.createAndRefreshSkuCategory.request, (state) => ({
		...state,
		dataState: SkuCategoryDataState.LOADING,
		errorMessage: '',
	}))
	.handleAction(actions.createAndRefreshSkuCategory.success, (state, action) => ({
		...state,
		dataState: SkuCategoryDataState.SUCCESS,
		skuCategories: action.payload,
		errorMessage: '',
	}))
	.handleAction(actions.createAndRefreshSkuCategory.failure, (state, action) => ({
		...state,
		dataState: SkuCategoryDataState.FAILURE,
		errorMessage: action.payload,
	}));
