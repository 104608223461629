import { ActionType, createReducer } from 'typesafe-actions';

import { AddUserI } from './type';
import * as actions from './addUser.action';
import * as types from './addUser.actionTypes';

export type AddUserAction = ActionType<typeof actions>;

export const userInitialState: AddUserI = {
	data: [{ lmsRole: '', userID: '' }],
	isSuccess: false,
	isLoading: false,
};

const addUserReducer = createReducer<AddUserI, AddUserAction>(userInitialState)
	.handleAction(actions.addUser.request, (state) => ({
		...state,
		error: null,
		isLoading: true,
		isSuccess: false,
	}))
	.handleAction(actions.addUser.success, (state) => ({
		...state,
		isLoading: false,
		isSuccess: true,
	}))
	.handleAction(actions.addUser.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoading: false,
	}))
	.handleAction(types.SET_SHOW_MODAL as any, (state, action) => ({
		...state,
		isShowModal: action.payload.value,
	}))
	.handleAction(types.SET_SUCCESS as any, (state, action) => {
		return {
			...state,
			isSuccess: action.payload.value,
		};
	});

export { addUserReducer };
