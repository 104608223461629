const prefix = '@dashboard/trends';

export const START_REQUESTING_CHART = `${prefix}/START_REQUESTING_CHART`;
export const LOAD_CHART = `${prefix}/LOAD_CHART`;
export const FINISH_REQUESTING_CHART = `${prefix}/FINISH_REQUESTING_CHART`;
export const IS_DATA_EMPTY = `${prefix}/IS_DATA_EMPTY`;
export const SET_QUESTIONNAIRE = `${prefix}/SET_QUESTIONNAIRE`;
export const SET_QUESTION_CATEGORY = `${prefix}/SET_QUESTION_CATEGORY`;
export const SET_QUESTION_TYPE = `${prefix}/SET_QUESTION_TYPE`;

export const FETCH_TRENDS_CHART_REQUEST = `${prefix}/FETCH_TRENDS_CHART_REQUEST`;
export const FETCH_TRENDS_CHART_SUCCESS = `${prefix}/FETCH_TRENDS_CHART_SUCCESS`;
export const FETCH_TRENDS_CHART_FAILURE = `${prefix}/FETCH_TRENDS_CHART_FAILURE`;
