import { FindSKUPerCategory, SKUCompact } from '@nimbly-technologies/nimbly-common';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import * as actions from '../../reducers/skusCompact/skusCompact.action';
import { fetchSkusCompact } from '../../services/sku/sku.service';

export function* fetchSkusCompactSaga({ payload }: ReturnType<typeof actions.fetchSkusCompact.request>) {
	try {
		const query: Record<string, string> = { siteID: payload.siteID };
		const queryStr = new URLSearchParams(query);

		const skusCompactWithCategory: FindSKUPerCategory = yield* call(fetchSkusCompact, queryStr);
		const skusCompact: SKUCompact[] = skusCompactWithCategory.reduce((prevObj: any[], data) => {
			return [...prevObj, ...data.skus];
		}, []);

		yield put(actions.fetchSkusCompact.success({ skusCompactWithCategory, skusCompact }));
	} catch (e) {
		yield put(actions.fetchSkusCompact.failure({ error: 'Failed to fetch sku compact' }));
		return null;
	}
}

export default function* skusCompactSaga() {
	yield all([takeEvery(actions.fetchSkusCompact.request.toString(), fetchSkusCompactSaga)]);
}
