import { DownloadAuditorsListPayload } from 'reducers/dashboard/auditors/auditorsList/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';

export type DownloadAuditorsListResponse = {
  message: 'SUCCESS';
  url: string;
};
type ErrorResponse = {
  message: 'FAILED';
};

export const downloadAuditorsList = async (
  query: DownloadAuditorsListPayload
): Promise<DownloadAuditorsListResponse | ErrorResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/users/rank?${serializedQueries}`;
    const res = await fetch(url, options);

    if (res.status !== 200) {
      throw new Error('');
    }
    const blob = await res.blob();
    const blobURL = window.URL.createObjectURL(blob);

    const downloadURLResult: DownloadAuditorsListResponse = {
      message: 'SUCCESS',
      url: blobURL
    };
    return downloadURLResult;
  } catch {
    const errorResult: ErrorResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
