import { CoursePaginateResponse, MyCourseAndSyllabusResponse } from '@nimbly-technologies/nimbly-common';
import { LmsDataType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';

export enum FetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

interface MyCoursesAndSyllabusesFilters {
	type: string[];
	status: string[];
}

export enum LmsMyCoursesAndSyllabusesSortField {
	PUBLISHED_DATE = 'publishedDate',
	TITLE = 'title',
}

export type MyCoursesAndSyllabusesSortFields = 'publishedDate' | 'title';

export interface MyCoursesAndSyllabusesState {
	error: string;
	fetchState: FetchState;
	myCoursesAndSyllabuses: MyCourseAndSyllabusResponse[];

	totalPages: number;
	totalDocs: number;
	page: number;
	limit: number;

	sortField: MyCoursesAndSyllabusesSortFields;
	sortDirection: 'asc' | 'desc';
	search: string;
	filters: MyCoursesAndSyllabusesFilters;
}
