import update from 'immutability-helper';
import moment from 'moment-timezone';
import * as Common from '@nimbly-technologies/nimbly-common';
import { ActionType, Reducer } from 'typesafe-actions';

import * as types from './siteSchedule.actionTypes';
import * as actions from './siteSchedule.action';
import { DataValue } from 'types/myTypes';
import { getTodayMinutes } from 'utils/getTodayMinute';
export interface UpdatedSchedule {
	newID: string;
	originID: string;
	schedule?: ExtendedSiteScheduleIndex;
}

export type SiteScheduleAction = ActionType<typeof actions>;

export interface QuestionnaireScheduleState {
	key: string;
	value: Common.QuestionnaireSchedule;
}

export interface QuestionnaireScheduleError {
	email?: { [index: number]: boolean };
	assignedAuditor?: boolean;
	startTime?: boolean;
	endTime?: boolean;
	datesOfMonth?: boolean;
	daysOfWeek?: boolean;
}

export interface NamedScheduleTemplate {
	key: string;
	value: Common.SiteScheduleEvent;
	title: string;
}

export type QuestionnaireScheduleProps = keyof QuestionnaireScheduleError;

export interface ExtendedSiteScheduleIndex extends Common.SiteScheduleIndex {
	supervisor?: string;
}

export interface SelectedSchedule {
	schedule: ExtendedSiteScheduleIndex;
	/**
	 * array index in siteScheduleIndexes state
	 */
	indexOrigin: number;
}

export interface SiteScheduleState {
	/** updating schedule indicator */
	isLoading: boolean;

	// single report state
	isInitialQuestionnaireScheduleSet: boolean;
	initialQuestionnaireSchedules: DataValue<Common.SiteScheduleEvent>[];

	singleAuditScheduleList: DataValue<Common.SiteScheduleEvent>[];
	// TODO: Deprecate this state, use `selectedSchedule` instead
	/** team audit only allow 1 schedule active at a time */
	teamAuditSchedule: null | DataValue<Common.SiteScheduleEvent>;

	selectedQuestionnaireSchedule: null | (DataValue<Common.SiteScheduleEvent> & { index: number });

	questionnaireSchedulesErrors: { [index: number]: QuestionnaireScheduleError };

	hasChanges: boolean;

	activeDepartment: string;
	siteScheduleIndexes: ExtendedSiteScheduleIndex[];
	selectedSchedule: null | SelectedSchedule;
	isUploading: boolean;
	error: Common.ErrSchedule | string;
	isSuccess: boolean;
	filter: {
		status: 'active' | 'inactive';
	};
}

const initialState: SiteScheduleState = {
	isLoading: false,

	// single report state
	isInitialQuestionnaireScheduleSet: false,
	initialQuestionnaireSchedules: [],

	singleAuditScheduleList: [],
	teamAuditSchedule: null,

	selectedQuestionnaireSchedule: null,

	questionnaireSchedulesErrors: {},

	hasChanges: false,

	activeDepartment: '',
	siteScheduleIndexes: [],
	selectedSchedule: null,
	isUploading: false,
	isSuccess: false,
	error: '',
	filter: {
		status: 'active',
	},
};

// eslint-disable-next-line complexity
const siteScheduleReducer: Reducer<SiteScheduleState, SiteScheduleAction> = (
	state = initialState,
	action,
): SiteScheduleState => {
	switch (action.type) {
		case types.SET_LOADING_STATE:
			return update(state, {
				isLoading: { $set: action.payload.bool },
			});
		case types.SET_INITIAL_SCHEDULES:
			return update(state, {
				isInitialQuestionnaireScheduleSet: { $set: true },
				initialQuestionnaireSchedules: { $set: action.payload.allSchedules },
				singleAuditScheduleList: { $set: action.payload.singleAuditSchedules },
				teamAuditSchedule: { $set: action.payload.teamAuditSchedule },
			});
		case types.SET_SINGLE_AUDIT_SCHEDULE_LIST:
			return update(state, {
				singleAuditScheduleList: { $set: action.payload.list },
			});

		// START Team audit config
		case types.SET_TEAM_AUDIT_SCHEDULE:
			return update(state, {
				teamAuditSchedule: { $set: action.payload.schedule },
			});

		case types.SET_QUESTIONNAIRE_SELFIE_SIGNATURE: {
			if (
				state.singleAuditScheduleList[action.payload.index].value.selfieSignatures &&
				state.singleAuditScheduleList[action.payload.index].value.selfieSignatures.length > 0
			) {
				return update(state, {
					singleAuditScheduleList: {
						[action.payload.index]: {
							value: {
								selfieSignatures: { $set: [] },
							},
						},
					},
				});
			} else {
				return update(state, {
					singleAuditScheduleList: {
						[action.payload.index]: {
							value: {
								selfieSignatures: { $set: [{ title: '', path: '' }] },
							},
						},
					},
				});
			}
		}

		case types.SET_QUESTIONNAIRE_SELFIE_SIGNATURE_TITLE:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							selfieSignatures: {
								[action.payload.titleIndex]: {
									title: { $set: action.payload.value },
								},
							},
						},
					},
				},
			});

		case types.TOGGLE_SELECTED_SCHEDULE_SYNCHRONIZE_CALENDAR:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						withCalendarSync: {
							$apply: (bool: boolean | undefined) => !bool,
						},
					},
				},
				selectedSchedule: {
					schedule: {
						withCalendarSync: {
							$apply: (bool: boolean | undefined) => !bool,
						},
					},
				},
			});

		case types.ADD_TEAM_AUDIT_QUESTIONNAIRE:
			return update(state, {
				teamAuditSchedule: (schedule) =>
					update(schedule!, {
						value: {
							questionnaires: {
								$apply: (prev: string[]) =>
									prev
										? Array.from(new Set([...prev, action.payload.questionnaireIndexKey]))
										: [action.payload.questionnaireIndexKey],
							},
						},
					}),
			});
		// END Team audit config

		case types.SET_SELECTED_QUESTIONNAIRE_SCHEDULE:
			return update(state, {
				selectedQuestionnaireSchedule: {
					$set: {
						value: { ...action.payload.schedule.value },
						key: action.payload.schedule.key,
						index: action.payload.index,
					},
				},
			});
		case types.REMOVE_SELECTED_QUESTIONNAIRE_SCHEDULE:
			return update(state, {
				selectedQuestionnaireSchedule: { $set: null },
			});
		case types.ADD_QUESTIONNAIRE_SCHEDULE:
			return update(state, {
				singleAuditScheduleList: { $push: [action.payload.schedule] },
			});

		case types.TOGGLE_QUESTIONNAIRE_SCHEDULE_DATE:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							datesCustom: (sch) =>
								update(sch || {}, {
									$apply: (v: { [date: string]: boolean }) => {
										const newSchedule = { ...v };
										if (newSchedule[action.payload.date]) {
											delete newSchedule[action.payload.date];
										} else {
											newSchedule[action.payload.date] = true;
										}

										return { ...newSchedule };
									},
								}),
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						datesCustom: (sch) =>
							update(sch || {}, {
								$apply: (v: { [date: string]: boolean }) => {
									const newSchedule = { ...v };
									if (newSchedule[action.payload.date]) {
										delete newSchedule[action.payload.date];
									} else {
										newSchedule[action.payload.date] = true;
									}

									return { ...newSchedule };
								},
							}),
					},
				},
			});

		case types.ADD_QUESTIONNAIRE_SCHEDULE_WEEKDAY:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							daysOfWeek: (weekday) =>
								update(weekday || [], {
									$push: [action.payload.day],
								}),
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						daysOfWeek: (weekday) =>
							update(weekday || [], {
								$push: [action.payload.day],
							}),
					},
				},
			});
		case types.REMOVE_QUESTIONNAIRE_SCHEDULE_WEEKDAY:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							daysOfWeek: { $splice: [[action.payload.dayIndex, 1]] },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						daysOfWeek: { $splice: [[action.payload.dayIndex, 1]] },
					},
				},
			});
		case types.ADD_QUESTIONNAIRE_SCHEDULE_MONTH_DATE:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							datesOfMonth: (weekday) =>
								update(weekday || [], {
									$push: [action.payload.date],
								}),
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						datesOfMonth: (weekday) =>
							update(weekday || [], {
								$push: [action.payload.date],
							}),
					},
				},
			});
		case types.REMOVE_QUESTIONNAIRE_SCHEDULE_MONTH_DATE:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							datesOfMonth: { $splice: [[action.payload.dateIndex, 1]] },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						datesOfMonth: { $splice: [[action.payload.dateIndex, 1]] },
					},
				},
			});
		case types.ADD_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							emailTargets: (emails) =>
								update(emails || [], {
									$push: [''],
								}),
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						emailTargets: (emails) =>
							update(emails || [], {
								$push: [''],
							}),
					},
				},
			});
		case types.UPDATE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							emailTargets: (emails) =>
								update(emails || [], {
									$splice: [[action.payload.emailIndex, 1, action.payload.text]],
								}),
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						emailTargets: (emails) =>
							update(emails || [], {
								$splice: [[action.payload.emailIndex, 1, action.payload.text]],
							}),
					},
				},
			});
		case types.REMOVE_QUESTIONNAIRE_SCHEDULE_EMAIL_TARGET:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							emailTargets: { $splice: [[action.payload.emailIndex, 1]] },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						emailTargets: { $splice: [[action.payload.emailIndex, 1]] },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_SCHEDULE_START_DATE:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							startDate: { $set: action.payload.date },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						startDate: { $set: action.payload.date },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_SCHEDULE_END_DATE:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							endDate: { $set: action.payload.date },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						endDate: { $set: action.payload.date },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_SCHEDULE_SIGNATURES:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							signatures: { $set: action.payload.value },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						signatures: { $set: action.payload.value },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_DEADLINE_TIME:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							[action.payload.name]: { $set: action.payload.value },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						[action.payload.name]: { $set: action.payload.value },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_DEADLINE_REMINDER_TIME:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							reminderTime: { $set: action.payload.value },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						reminderTime: { $set: action.payload.value },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_DEADLINE_STATUS:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							hasDeadline: { $set: action.payload.bool },
							startTime: { $apply: (num) => (action.payload.bool ? num : null) },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						hasDeadline: { $set: action.payload.bool },
						startTime: { $apply: (num) => (action.payload.bool ? num : null) },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_DEADLINE_AUDITOR:
			return update(state, {
				singleAuditScheduleList: {
					[action.payload.index]: {
						value: {
							assignedAuditor: { $set: action.payload.userKey },
							auditors: { $set: action.payload.team },
						},
					},
				},
				selectedQuestionnaireSchedule: {
					value: {
						assignedAuditor: { $set: action.payload.userKey },
						auditors: { $set: action.payload.team },
					},
				},
			});
		case types.SET_QUESTIONNAIRE_SCHEDULES_ERRORS:
			return update(state, {
				questionnaireSchedulesErrors: {
					$set: action.payload.errorMessages,
				},
			});
		case types.UPDATE_QUESTIONNAIRE_SCHEDULE_VALIDATION:
			return update(state, {
				questionnaireSchedulesErrors: (s) =>
					update(s || {}, {
						[action.payload.index]: (s) =>
							update(s || {}, {
								[action.payload.property]: { $set: action.payload.value },
							}),
					}),
			});
		case types.REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION:
			return update(state, {
				questionnaireSchedulesErrors: {
					$unset: [action.payload.index],
				},
			});

		case types.REMOVE_QUESTIONNAIRE_SCHEDULE_VALIDATION_PROPERTY:
			return update(state, {
				questionnaireSchedulesErrors: {
					[action.payload.index]: (errorProps) =>
						update(errorProps || {}, {
							$unset: [action.payload.property],
						}),
				},
			});
		case types.CLEAR_SITE_SCHEDULE_STATE:
			return initialState;
		case types.SET_HAS_CHANGES:
			return update(state, {
				hasChanges: { $set: action.payload.hasChanges },
			});

		// BELLOW After Improve

		case types.SET_ACTIVE_DEPARTMENT:
			return update(state, {
				activeDepartment: { $set: action.payload.departmentID },
			});

		case types.SELECT_SCHEDULE:
			return update(state, {
				selectedSchedule: {
					$set: action.payload,
				},
			});

		case types.REMOVE_SELECTED_SCHEDULE:
			return update(state, {
				selectedSchedule: { $set: null },
			});

		case types.UPDATE_SELECTED_SCHEDULE_STATE:
			return update(state, {
				selectedSchedule: { $set: action.payload },
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: { $set: action.payload.schedule },
				},
			});

		case types.PUSH_NEW_SCHEDULE:
			return update(state, {
				siteScheduleIndexes: { $push: [action.payload.schedule] },
				selectedSchedule: {
					$apply: (prev) => {
						if (!action.payload.select) {
							return prev;
						}

						const prevLen = state.siteScheduleIndexes.length;

						return {
							indexOrigin: prevLen,
							schedule: action.payload.schedule,
						};
					},
				},
			});

		case types.SPLICE_SELECTED_SCHEDULE: {
			const m = moment();
			return update(state, {
				// splice item from list
				siteScheduleIndexes: {
					$apply: (schedules: ExtendedSiteScheduleIndex[]) => {
						const copy = schedules.slice();
						const selected = copy[action.payload.index];
						// is new
						if (selected.scheduleID === '') {
							copy.splice(action.payload.index, 1);

							return copy;
						}

						copy[action.payload.index] = {
							...selected,
							disabled: true,
							endDate: m.format('YYYY-MM-DD'),
							endAt: m.toDate(),
						};

						return copy;
					},
				},
				// remove selected if index match
				selectedSchedule: {
					$apply: (prev) => {
						if (!prev || prev.indexOrigin === action.payload.index) return null;
						return prev;
					},
				},
				// remove error validation
				questionnaireSchedulesErrors: {
					$unset: [action.payload.index],
				},
			});
		}
		case types.SET_SELECTED_SCHEDULE_TYPE:
			return update(state, {
				selectedSchedule: {
					schedule: {
						type: { $set: action.payload.type },
						isRecurring: { $set: action.payload.type !== Common.enums.ScheduleTypes.CUSTOM },
						firstScheduleStart: { $set: null },
						firstScheduleEnd: { $set: null },
						repeatingType: { $set: Common.enums.RepeatingTypes.INDEFINITE },
						occurenceNumber: { $set: null },
						repeatingEndDate: { $set: null },
						datesCustomV2: { $set: [] },
						datesCustom: { $set: {} },
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						type: { $set: action.payload.type },
						isRecurring: { $set: action.payload.type !== Common.enums.ScheduleTypes.CUSTOM },
						firstScheduleStart: { $set: null },
						firstScheduleEnd: { $set: null },
						repeatingType: { $set: Common.enums.RepeatingTypes.INDEFINITE },
						occurenceNumber: { $set: null },
						repeatingEndDate: { $set: null },
						datesCustomV2: { $set: [] },
						datesCustom: { $set: {} },
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_REPEAT_TYPE:
			return update(state, {
				selectedSchedule: {
					schedule: {
						type: { $set: action.payload.type },
						isRecurring: { $set: action.payload.type !== Common.enums.ScheduleTypes.CUSTOM },
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						type: { $set: action.payload.type },
						isRecurring: { $set: action.payload.type !== Common.enums.ScheduleTypes.CUSTOM },
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_DAYS_OF_WEEK:
			return update(state, {
				selectedSchedule: {
					schedule: {
						daysOfWeek: { $set: action.payload.daysOfWeek },
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						daysOfWeek: { $set: action.payload.daysOfWeek },
					},
				},
				questionnaireSchedulesErrors: (errors) =>
					update(errors || {}, {
						[action.payload.indexOrigin]: (properties) =>
							update(properties || {}, {
								daysOfWeek: { $set: false },
							}),
					}),
			});

		case types.SET_SELECTED_SCHEDULE_DATES_OF_MONTH:
			return update(state, {
				selectedSchedule: {
					schedule: {
						datesOfMonth: { $set: action.payload.datesOfMonth },
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						datesOfMonth: { $set: action.payload.datesOfMonth },
					},
				},
				questionnaireSchedulesErrors: (errors) =>
					update(errors || {}, {
						[action.payload.indexOrigin]: (properties) =>
							update(properties || {}, {
								datesOfMonth: { $set: false },
							}),
					}),
			});

		case types.SET_SELECTED_SCHEDULE_DATES_CUSTOM:
			return update(state, {
				selectedSchedule: {
					schedule: {
						datesCustom: { $set: action.payload.datesCustom },
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						datesCustom: { $set: action.payload.datesCustom },
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_HAS_DEADLINE:
			return update(state, {
				selectedSchedule: {
					schedule: {
						hasDeadline: { $set: action.payload.bool },
						startTime: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return null;
							},
						},
						endTime: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return null;
							},
						},
						assignedAuditor: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return '';
							},
						},
						reminderTime: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return null;
							},
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						hasDeadline: { $set: action.payload.bool },
						startTime: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return null;
							},
						},
						endTime: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return null;
							},
						},
						assignedAuditor: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return '';
							},
						},
						reminderTime: {
							$apply: (prev) => {
								if (action.payload.bool) {
									return prev;
								}
								return null;
							},
						},
					},
				},
				questionnaireSchedulesErrors: (errors) =>
					update(errors || {}, {
						[action.payload.indexOrigin]: (properties) =>
							update(properties || {}, {
								$apply: (errorProps) => {
									return {
										...errorProps,
										assignedAuditor: !action.payload.bool ? false : !!errorProps.assignedAuditor,
										startTime: !action.payload.bool ? false : !!errorProps.startTime,
										endTime: !action.payload.bool ? false : !!errorProps.endTime,
									};
								},
							}),
					}),
			});

		case types.SET_SELECTED_SCHEDULE_ASSIGNED_AUDITOR:
			return update(state, {
				selectedSchedule: {
					schedule: {
						assignedAuditor: {
							$set: action.payload.uid || '',
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						assignedAuditor: {
							$set: action.payload.uid || '',
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_ISSUE_OWNER:
			return update(state, {
				selectedSchedule: {
					schedule: {
						defaultIssueOwner: {
							$set: action.payload.uid || '',
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						defaultIssueOwner: {
							$set: action.payload.uid || '',
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_START_TIME:
			return update(state, {
				selectedSchedule: {
					schedule: {
						startTime: {
							$set: action.payload.time,
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						startTime: {
							$set: action.payload.time,
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_END_TIME:
			return update(state, {
				selectedSchedule: {
					schedule: {
						endTime: {
							$set: action.payload.time,
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						endTime: {
							$set: action.payload.time,
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_REMINDER_TIME:
			return update(state, {
				selectedSchedule: {
					schedule: {
						reminderTime: {
							$set: action.payload.time,
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						reminderTime: {
							$set: action.payload.time,
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_START_DATE:
			return update(state, {
				selectedSchedule: {
					schedule: {
						startDate: {
							$set: action.payload.date,
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						startDate: {
							$set: action.payload.date,
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_END_DATE:
			return update(state, {
				selectedSchedule: {
					schedule: {
						endDate: {
							$set: action.payload.date,
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						endDate: {
							$set: action.payload.date,
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_SIGNATURES:
			return update(state, {
				selectedSchedule: {
					schedule: {
						signatures: {
							$set: action.payload.count,
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						signatures: {
							$set: action.payload.count,
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE:
			return update(state, {
				selectedSchedule: {
					schedule: {
						selfieSignatures: {
							$apply: (prev: Common.SelfieSignature[]) => {
								if (prev && prev.length > 0) {
									return [];
								}
								return [{ title: '', path: '' }];
							},
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						selfieSignatures: {
							$apply: (prev: Common.SelfieSignature[]) => {
								if (prev && prev.length > 0) {
									return [];
								}
								return [{ title: '', path: '' }];
							},
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_SELFIE_SIGNATURE_TITLE:
			return update(state, {
				selectedSchedule: {
					schedule: {
						selfieSignatures: {
							[action.payload.indexSignature]: {
								title: { $set: action.payload.title },
							},
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						selfieSignatures: {
							[action.payload.indexSignature]: {
								title: { $set: action.payload.title },
							},
						},
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_EMAIL_TARGET:
			return update(state, {
				selectedSchedule: {
					schedule: {
						emailTargets: {
							[action.payload.indexEmail]: {
								$set: action.payload.email,
							},
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						emailTargets: {
							[action.payload.indexEmail]: {
								$set: action.payload.email,
							},
						},
					},
				},
			});

		case types.ADD_SELECTED_SCHEDULE_EMAIL_TARGET:
			return update(state, {
				selectedSchedule: {
					schedule: {
						emailTargets: {
							$push: [''],
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						emailTargets: {
							$push: [''],
						},
					},
				},
			});

		case types.REMOVE_SELECTED_SCHEDULE_EMAIL_TARGET:
			return update(state, {
				selectedSchedule: {
					schedule: {
						emailTargets: {
							$apply: (emails: string[]) => {
								const copy = (emails || []).slice();
								copy.splice(action.payload.indexEmail, 1);

								if (copy.length === 0) {
									return [''];
								}
								return copy;
							},
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						emailTargets: {
							$apply: (emails: string[]) => {
								const copy = (emails || []).slice();
								copy.splice(action.payload.indexEmail, 1);

								if (copy.length === 0) {
									return [''];
								}
								return copy;
							},
						},
					},
				},
			});

		// Saga
		case types.ADD_NEW_SCHEDULE_SUCCESS:
			return update(state, {
				siteScheduleIndexes: {
					$push: [action.payload],
				},
			});

		case types.UPLOAD_SITE_SCHEDULES_REQUEST:
			return update(state, {
				isLoading: { $set: true },
				isUploading: { $set: true },
				isSuccess: { $set: false },
			});

		case types.UPLOAD_SITE_SCHEDULES_SUCCESS:
			return update(state, {
				isLoading: { $set: false },
				isUploading: { $set: false },
				isSuccess: { $set: true },
			});

		case types.UPLOAD_SITE_SCHEDULES_FAILURE:
			return update(state, {
				isLoading: { $set: false },
				isUploading: { $set: false },
			});

		case types.FETCH_SITE_SCHEDULES_REQUEST:
			return update(state, {
				isLoading: { $set: true },
				selectedSchedule: { $set: null },
			});
		case types.FETCH_SITE_SCHEDULES_SUCCESS:
			return update(state, {
				isLoading: { $set: false },
				siteScheduleIndexes: { $set: action.payload.schedules },
				teamAuditSchedule: {
					$apply: (prev) => {
						const teamSch = action.payload.schedules?.find((sch) => !sch.questionnaireIndexID);
						if (!teamSch) {
							return prev;
						}

						return {
							key: teamSch.scheduleID,
							value: {
								...teamSch,
								siteKey: teamSch.siteID,
								departmentKey: teamSch.departmentID,
								questionnaireIndexKey: teamSch.questionnaireIndexID,
								siteOwnerKey: '',
							},
						};
					},
				},
			});
		case types.FETCH_SITE_SCHEDULES_FAILURE:
			return update(state, {
				isLoading: { $set: false },
				error: { $set: action.payload.error },
			});

		// IMPORT SCHEDULES CASES
		case types.IMPORT_SCHEDULES_REQUEST:
			return update(state, {
				isLoading: { $set: true },
				hasChanges: { $set: true },
			});
		case types.IMPORT_SCHEDULES_SUCCESS:
			return update(state, {
				selectedSchedule: { $set: null },
				isLoading: { $set: false },
				siteScheduleIndexes: { $set: action.payload.schedules },
			});

		case types.UPLOAD_TEAM_SITE_SCHEDULES_REQUEST:
			return update(state, {
				isLoading: { $set: true },
				isUploading: { $set: true },
				isSuccess: { $set: false },
			});

		case types.UPLOAD_TEAM_SITE_SCHEDULES_SUCCESS:
			return update(state, {
				isLoading: { $set: false },
				isUploading: { $set: false },
				isSuccess: { $set: true },
			});

		case types.UPLOAD_TEAM_SITE_SCHEDULES_FAILURE:
			return update(state, {
				isLoading: { $set: false },
				isUploading: { $set: false },
				isSuccess: { $set: false },
			});
		case types.SET_FIRST_SCHEDULE_START_DATE:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						firstScheduleStart: { $set: action.payload.date },
						startAt: { $set: action.payload.date },
						startDate: { $set: action.payload.date ? moment(action.payload.date).format('YYYY-MM-DD') : '' },
						startTime: { $set: action.payload.date ? getTodayMinutes(action.payload.date) : null },
					},
				},
			});
		case types.SET_FIRST_SCHEDULE_END_DATE:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						firstScheduleEnd: { $set: action.payload.date },
					},
				},
			});
		case types.SET_REPEATING_TYPE:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						repeatingType: { $set: action.payload.type },
						occurenceNumber: { $set: null },
						endAt: { $set: null },
						repeatingEndDate: { $set: null },
						endDate: { $set: '' },
					},
				},
				selectedSchedule: {
					schedule: {
						repeatingType: { $set: action.payload.type },
						occurenceNumber: { $set: null },
						endAt: { $set: null },
						repeatingEndDate: { $set: null },
						endDate: { $set: '' },
					},
				},
			});
		case types.SET_OCCURENCE_NUMBER:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						occurenceNumber: { $set: action.payload.value },
					},
				},
				selectedSchedule: {
					schedule: {
						occurenceNumber: { $set: action.payload.value },
					},
				},
			});
		case types.SET_REPEATING_END_DATE:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						endAt: { $set: action.payload.date },
						repeatingEndDate: { $set: action.payload.date },
						endDate: { $set: moment(action.payload.date).format('YYYY-MM-DD') },
					},
				},
				selectedSchedule: {
					schedule: {
						endAt: { $set: action.payload.date },
						repeatingEndDate: { $set: action.payload.date },
						endDate: { $set: moment(action.payload.date).format('YYYY-MM-DD') },
					},
				},
			});

		case types.SET_SELECTED_SCHEDULE_EMAIL:
			return update(state, {
				selectedSchedule: {
					schedule: {
						emailTargets: {
							$push: [action.payload.email],
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						emailTargets: {
							$push: [action.payload.email],
						},
					},
				},
			});
		case types.SET_UPDATE_ALL_END_TIME:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						endDate: { $set: action.payload.date ? moment(action.payload.date).format('YYYY-MM-DD') : '' },
						endAt: { $set: action.payload.date || null },
						repeatingEndDate: { $set: action.payload.date || null },
					},
				},
				selectedSchedule: {
					schedule: {
						endDate: { $set: action.payload.date ? moment(action.payload.date).format('YYYY-MM-DD') : '' },
						endAt: { $set: action.payload.date },
						repeatingEndDate: { $set: action.payload.date || null },
					},
				},
			});
		case types.SET_SCHEDULE_START_AT:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						startAt: { $set: action.payload.date },
					},
				},
				selectedSchedule: {
					schedule: {
						startAt: { $set: action.payload.date },
					},
				},
			});
		case types.SET_ALLOW_ADHOC_REPORT:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						allowAdhoc: { $set: action.payload.bool },
					},
				},
				selectedSchedule: {
					schedule: {
						allowAdhoc: { $set: action.payload.bool },
					},
				},
			});
		case types.SET_ALLOW_RESTRICT_TIME:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						hasStrictTime: { $set: action.payload.bool },
					},
				},
				selectedSchedule: {
					schedule: {
						hasStrictTime: { $set: action.payload.bool },
					},
				},
			});
		case types.SET_ALLOW_ENFORCE_CHECKIN:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						enforceCheckIn: { $set: action.payload.bool },
					},
				},
				selectedSchedule: {
					schedule: {
						enforceCheckIn: { $set: action.payload.bool },
					},
				},
			});
		case types.SET_ALLOW_ENFORCE_CHECKOUT:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						enforceCheckOut: { $set: action.payload.bool },
					},
				},
				selectedSchedule: {
					schedule: {
						enforceCheckOut: { $set: action.payload.bool },
					},
				},
			});
		case types.SET_SELECTED_SCHEDULE_REMINDER_PERIOD:
			return update(state, {
				selectedSchedule: {
					schedule: {
						reminderPeriod: {
							$set: action.payload.type,
						},
					},
				},
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						reminderPeriod: {
							$set: action.payload.type,
						},
					},
				},
			});
		case types.SET_ALLOW_CUSTOM_ADHOC_ONLY:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						isAdhocOnly: { $set: action.payload.bool },

						datesCustomV2: { $set: [] },
						datesCustom: { $set: {} },

						assignedAuditor: { $set: '' },
						allowAdhoc: { $set: false },
						hasStrictTime: { $set: false },
						enforceCheckIn: { $set: false },
						enforceCheckOut: { $set: false },
						reminderTime: { $set: null },
						reminderPeriod: { $set: Common.enums.ReminderPeriod.MINUTES },

						signatures: { $set: 0 },
						selfieSignatures: { $set: [] },

						emailTargets: { $set: [] },
					},
				},
				selectedSchedule: {
					schedule: {
						isAdhocOnly: { $set: action.payload.bool },

						datesCustomV2: { $set: [] },
						datesCustom: { $set: {} },

						assignedAuditor: { $set: '' },
						allowAdhoc: { $set: false },
						hasStrictTime: { $set: false },
						enforceCheckIn: { $set: false },
						enforceCheckOut: { $set: false },
						reminderTime: { $set: null },
						reminderPeriod: { $set: Common.enums.ReminderPeriod.MINUTES },

						signatures: { $set: 0 },
						selfieSignatures: { $set: [] },

						emailTargets: { $set: [] },
					},
				},
			});
		case types.SET_CUSTOMV2_ADD_ELEMENT:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						datesCustomV2: { $push: [{ customID: '', startDate: null, endDate: null }] },
					},
				},
				selectedSchedule: {
					schedule: {
						datesCustomV2: { $push: [{ customID: '', startDate: null, endDate: null }] },
					},
				},
			});
		case types.SET_CUSTOMV2_DELETE_ELEMENT:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						datesCustomV2: { $splice: [[action.payload.customIndex, 1]] },
					},
				},
				selectedSchedule: {
					schedule: {
						datesCustomV2: { $splice: [[action.payload.customIndex, 1]] },
					},
				},
			});
		case types.SET_CUSTOMV2_START_DATE:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						datesCustomV2: {
							[action.payload.customIndex]: {
								startDate: { $set: action.payload.date },
							},
						},
					},
				},
				selectedSchedule: {
					schedule: {
						datesCustomV2: {
							[action.payload.customIndex]: {
								startDate: { $set: action.payload.date },
							},
						},
					},
				},
			});
		case types.SET_CUSTOMV2_END_DATE:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						datesCustomV2: {
							[action.payload.customIndex]: {
								endDate: { $set: action.payload.date },
							},
						},
					},
				},
				selectedSchedule: {
					schedule: {
						datesCustomV2: {
							[action.payload.customIndex]: {
								endDate: { $set: action.payload.date },
							},
						},
					},
				},
			});

		case types.SET_CUSTOMV2_ADD_DATE:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						datesCustomV2: {
							$push: [{ customID: '', startDate: action.payload.startDate, endDate: action.payload.endDate }],
						},
					},
				},
				selectedSchedule: {
					schedule: {
						datesCustomV2: {
							$push: [{ customID: '', startDate: action.payload.startDate, endDate: action.payload.endDate }],
						},
					},
				},
			});

		case types.SET_FILTER_STATUS:
			return update(state, {
				filter: {
					status: { $set: action.payload.status },
				},
			});

		case types.TOGGLE_ALLOW_ACTIVE_SCHEDULE_PERIOD: {
			const nextActivePeriod = state.selectedSchedule?.schedule.scheduleActivePeriod ? null : {};

			const nextEnforceCheckIn = nextActivePeriod ? false : state.selectedSchedule?.schedule.enforceCheckIn;
			const nextEnforceCheckOut = nextActivePeriod ? false : state.selectedSchedule?.schedule.enforceCheckOut;

			return update(state, {
				// @ts-expect-error object initialization handled by BE
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						scheduleActivePeriod: { $set: nextActivePeriod },
						enforceCheckIn: { $set: nextEnforceCheckIn },
						enforceCheckOut: { $set: nextEnforceCheckOut },
					},
				},
				selectedSchedule: {
					schedule: {
						// @ts-expect-error object initialization handled by BE
						scheduleActivePeriod: { $set: nextActivePeriod },
						enforceCheckIn: { $set: nextEnforceCheckIn },
						enforceCheckOut: { $set: nextEnforceCheckOut },
					},
				},
			});
		}

		case types.SET_ACTIVE_SCHEDULE_PERIOD_UNIT: {
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						scheduleActivePeriod: {
							periodUnit: { $set: action.payload.unit },
						},
					},
				},
				selectedSchedule: {
					schedule: {
						scheduleActivePeriod: {
							periodUnit: { $set: action.payload.unit },
						},
					},
				},
			});
		}

		case types.SET_ACTIVE_SCHEDULE_PERIOD_LENGTH: {
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						scheduleActivePeriod: {
							periodLength: { $set: action.payload.length },
						},
					},
				},
				selectedSchedule: {
					schedule: {
						scheduleActivePeriod: {
							periodLength: { $set: action.payload.length },
						},
					},
				},
			});
		}
		case types.SET_SELECTED_SCHEDULE_DEPARTMENT:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						departmentID: { $set: action.payload.dept },
					},
				},
				selectedSchedule: {
					schedule: {
						departmentID: { $set: action.payload.dept },
					},
				},
			});
		case types.SET_SCHEDULE_SUPERVISOR:
			return update(state, {
				siteScheduleIndexes: {
					[action.payload.indexOrigin]: {
						supervisor: { $set: action.payload.userID },
					},
				},
				selectedSchedule: {
					schedule: {
						supervisor: { $set: action.payload.userID },
					},
				},
			});
		case types.RESET:
			return initialState;
		default:
			return state;
	}
};

export default siteScheduleReducer;
