import { UpsertUserNPSPayload, UpsertUserNPSResponse } from 'reducers/dashboard/overview/userNPS/types';
import { apiURL } from '../../../../config/baseURL';
import API from 'helpers/api';

export const upsertUserNPS = async (userNPS: UpsertUserNPSPayload): Promise<UpsertUserNPSResponse> => {
  try {
    const token = await API.getFirebaseToken();
    const options: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify(userNPS)
    };
    const url = `${apiURL}/users/userNPS/close`;

    const res = await fetch(url, options);

    if (res.status !== 200) {
      throw new Error('');
    }

    const userNPSResult: UpsertUserNPSResponse = await res.json();
    return userNPSResult;
  } catch {
    const errorResult: UpsertUserNPSResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
