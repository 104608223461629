import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './executive.actionTypes';
import { DashboardTabs } from 'pages/dashboardRevamp/dashboardUtils';
import moment from 'moment';
import {
	HeatmapIssueFlagsI,
	IrrRatioI,
	RcrRatioI,
	VocRationI,
	LastSelectedWidgetI,
	ExecutiveFiltersI,
} from './executive.types';

export const setDashboardType = (type: DashboardTabs) => action(types.SET_DASHBOARD_TYPE, type);
export const setShowMap = (type: boolean) => action(types.SET_SHOW_MAP, type);
export const setShowFilters = (type: boolean) => action(types.SET_SHOW_FILTERS, type);

export const setSearchTrigger = () => action(types.SET_SEARCH_TRIGGER);
export const setResetFilter = () => action(types.SET_RESET_FILTER);

export const setFilterDates = (startDate: moment.Moment | null, endDate: moment.Moment | null) =>
	action(types.SET_FILTER_DATE, { startDate, endDate });

export const setFilters = (filters: Partial<ExecutiveFiltersI>) => action(types.SET_FILTERS, { filters });

export const setMultiSelect = (key: string, value: string[]) => action(types.SET_MULTI_SELECT, { key, value });

export const setLastSelectedWidget = (value: LastSelectedWidgetI) => action(types.SET_LAST_SELECTED_WIDGET, value);

export const setLastUpdate = () => action(types.SET_LAST_UPDATE);

export const fetchDepartmentRatioAsync = createAsyncAction(
	types.FETCH_DEPARTMENT_RATIO_REQUEST,
	types.FETCH_DEPARTMENT_RATIO_SUCCESS,
	types.FETCH_DEPARTMENT_RATIO_FAILURE,
)<undefined, string, string>();

export const fetchIrrRatioAsync = createAsyncAction(
	types.FETCH_IRR_RATIO_REQUEST,
	types.FETCH_IRR_RATIO_SUCCESS,
	types.FETCH_IRR_RATIO_FAILURE,
)<undefined, IrrRatioI, string>();

export const fetchRcrRatioAsync = createAsyncAction(
	types.FETCH_RCR_RATIO_REQUEST,
	types.FETCH_RCR_RATIO_SUCCESS,
	types.FETCH_RCR_RATIO_FAILURE,
)<undefined, RcrRatioI, string>();

export const fetchVocRatioAsync = createAsyncAction(
	types.FETCH_VOC_RATIO_REQUEST,
	types.FETCH_VOC_RATIO_SUCCESS,
	types.FETCH_VOC_RATIO_FAILURE,
)<undefined, VocRationI, string>();

export const fetchHeatmapIssueFlagsAsync = createAsyncAction(
	types.FETCH_HEATMAP_ISSUE_FLAGS_REQUEST,
	types.FETCH_HEATMAP_ISSUE_FLAGS_SUCCESS,
	types.FETCH_HEATMAP_ISSUE_FLAGS_FAILURE,
)<undefined, HeatmapIssueFlagsI, string>();
