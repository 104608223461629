import { createAsyncAction } from 'typesafe-actions';
import * as types from './siteUser.actionTypes';
import { SiteUserMinimalI } from './type';
export const siteUserAction = createAsyncAction(
	types.FETCH_SITE_USER_REQUEST,
	types.FETCH_SITE_USER_SUCCESS,
	types.FETCH_SITE_USER_FAILURE,
)<
	{
		withDisabled?: boolean;
		userIDs?: string[];
		siteGroupIDs?: string[];
	},
	{ data: SiteUserMinimalI[]; message: string },
	{ message: string }
>();
