import { Quiz, QuizResult } from '@nimbly-technologies/nimbly-common';
import {
	QueryLmsCourseQuizByIdResponse,
	RetryLmsCourseQuizArgs,
	RetryLmsCourseQuizResponse,
	StartLmsCourseQuizArgs,
	SubmitLmsCourseQuizSubmissionArgs,
	SubmitLmsCourseQuizSubmissionResponse,
	UpdateLmsCourseQuizSubmissionDraft,
} from 'services/lms/lmsCourseQuiz.service';
import { action, createAction, createAsyncAction } from 'typesafe-actions';
import { LmsCourseQuizStep } from './lmsCourseQuiz';
import {
	QUERY_LMS_COURSE_QUIZ_BY_ID_FAILURE,
	QUERY_LMS_COURSE_QUIZ_BY_ID_REQUEST,
	QUERY_LMS_COURSE_QUIZ_BY_ID_SUCCESS,
	QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_FAILURE,
	QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_REQUEST,
	QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_SUCCESS,
	RETRY_LMS_COURSE_QUIZ_FAILURE,
	RETRY_LMS_COURSE_QUIZ_REQUEST,
	RETRY_LMS_COURSE_QUIZ_SUCCESS,
	SET_LMS_COURSE_QUIZ_CURRENT_QUESTION_INDEX,
	SET_LMS_COURSE_QUIZ_SELECTED_ID as SET_LMS_COURSE_QUIZ_SELECTED_QUIZ_ID,
	SET_LMS_COURSE_QUIZ_STEP,
	SET_LMS_COURSE_QUIZ_TIMEOUT_MODAL_VISIBLE,
	SET_LMS_COURSE_QUIZ_SUMMARY_QUESTIONS_MOBILE_VISIBLE,
	START_LMS_COURSE_QUIZ_FAILURE,
	START_LMS_COURSE_QUIZ_REQUEST,
	START_LMS_COURSE_QUIZ_SUCCESS,
	SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_FAILURE,
	SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_REQUEST,
	SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_SUCCESS,
	UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_FAILURE,
	UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_REQUEST,
	UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_SUCCESS,
} from './lmsCourseQuiz.actionTypes';

const setSelectedQuizId = (quizId: string | null) => action(SET_LMS_COURSE_QUIZ_SELECTED_QUIZ_ID, quizId);
const setCurrentQuestionIndex = createAction(SET_LMS_COURSE_QUIZ_CURRENT_QUESTION_INDEX)<number>();
const setQuizStep = createAction(SET_LMS_COURSE_QUIZ_STEP)<LmsCourseQuizStep>();
const setTimeoutModalVisible = createAction(SET_LMS_COURSE_QUIZ_TIMEOUT_MODAL_VISIBLE)<boolean>();
const setIsSummarQuestionsMobileVisible = createAction(SET_LMS_COURSE_QUIZ_SUMMARY_QUESTIONS_MOBILE_VISIBLE)<boolean>();

const queryLmsCourseQuizByIdAsync = createAsyncAction(
	QUERY_LMS_COURSE_QUIZ_BY_ID_REQUEST,
	QUERY_LMS_COURSE_QUIZ_BY_ID_SUCCESS,
	QUERY_LMS_COURSE_QUIZ_BY_ID_FAILURE,
)<{ quizId: string }, Quiz, { error: string }>();

const startLmsCourseQuizAsync = createAsyncAction(
	START_LMS_COURSE_QUIZ_REQUEST,
	START_LMS_COURSE_QUIZ_SUCCESS,
	START_LMS_COURSE_QUIZ_FAILURE,
)<StartLmsCourseQuizArgs & { onSuccess?: () => void }, string, { error: string }>();

const queryLmsCourseQuizSubmissionDraftBySubmitIdAsync = createAsyncAction(
	QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_REQUEST,
	QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_SUCCESS,
	QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_FAILURE,
)<{ submitId: string }, QueryLmsCourseQuizByIdResponse, { error: string }>();

const updateLmsCourseQuizSubmissionDraftAsync = createAsyncAction(
	UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_REQUEST,
	UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_SUCCESS,
	UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_FAILURE,
)<UpdateLmsCourseQuizSubmissionDraft, QuizResult, { error: string }>();

const submitLmsCourseQuizSubmissionAsync = createAsyncAction(
	SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_REQUEST,
	SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_SUCCESS,
	SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_FAILURE,
)<
	SubmitLmsCourseQuizSubmissionArgs & { onSuccess?: () => void },
	SubmitLmsCourseQuizSubmissionResponse,
	{ error: string }
>();

const retryLmsCourseQuizAsync = createAsyncAction(
	RETRY_LMS_COURSE_QUIZ_REQUEST,
	RETRY_LMS_COURSE_QUIZ_SUCCESS,
	RETRY_LMS_COURSE_QUIZ_FAILURE,
)<RetryLmsCourseQuizArgs & { onSuccess?: () => void }, RetryLmsCourseQuizResponse, { error: string }>();

export const lmsCourseQuizActions = {
	setSelectedQuizId: setSelectedQuizId,
	setCurrentQuestionIndex: setCurrentQuestionIndex,
	setStep: setQuizStep,
	setTimeoutModalVisible: setTimeoutModalVisible,
	setIsSummarQuestionsMobileVisible: setIsSummarQuestionsMobileVisible,

	queryCourseById: queryLmsCourseQuizByIdAsync,
	startQuiz: startLmsCourseQuizAsync,
	retryQuiz: retryLmsCourseQuizAsync,
	querySubmissionDraftBySubmitId: queryLmsCourseQuizSubmissionDraftBySubmitIdAsync,
	updateSubmissionDraft: updateLmsCourseQuizSubmissionDraftAsync,
	submitSubmission: submitLmsCourseQuizSubmissionAsync,
};

export default lmsCourseQuizActions;
