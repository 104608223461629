import update from 'immutability-helper';

import * as types from './actionTypes';

/**
 * @typedef {import('../../../utils/classes').Vendor & {isNew: boolean}} VendorState
 * @type {VendorState | null}
 */
const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_VENDOR:
      return {
        ...action.payload.vendor
      };
    case types.SET_NEW_VENDOR:
      return {
        ...action.payload.vendor,
        isNew: true
      };
    case types.SET_EXISTING_VENDOR:
      return {
        ...action.payload.vendor,
        isNew: false
      };
    case types.SET_VENDOR_NAME:
      return {
        ...state,
        name: action.payload.name,
        nameLowercase: action.payload.name.toLowerCase()
      };

    case types.SET_VENDOR_ADDRESS:
      return {
        ...state,
        address: action.payload.address
      };

    case types.ADD_VENDOR_ITEM:
      return update(state, {
        items: {
          [action.payload.skuKey]: {
            $set: {
              price: action.payload.price,
              disabled: false
            }
          }
        }
      });
    case types.UPDATE_VENDOR_ITEM:
      return update(state, {
        items: {
          [action.payload.skuKey]: {
            $set: action.payload.item
          }
        }
      });
    case types.UPDATE_VENDOR_ITEM_PRICE:
      return update(state, {
        items: {
          [action.payload.skuKey]: {
            $set: {
              price: action.payload.price,
              disabled: false
            }
          }
        }
      });
    case types.CLEAR_VENDOR:
      return null;
    default:
      return state;
  }
};
