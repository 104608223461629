import { apiURL } from 'config/baseURL';
import {
	IssueApproval,
	IssueApprovalForApprover,
	IssueApproverList,
} from 'core/domain/entities/issue/issueApprovalEntity';
import { IIssueApprovalRepository } from 'core/domain/iRepositories/issue/iIssueApprovalRepository';
import { APIResponse } from 'core/domain/models/api';
import {
	AddApproverParams,
	ApproveRequestParams,
	CancelRequestParams,
	CreateApprovalRequestParams,
	CreateApprovalResponse,
	GetApproverHistoryParams,
	GetApproversListParams,
	RemoveApproverParams,
} from 'core/domain/models/issue/issueApprovalModel';
import API from 'helpers/api';
import { toast } from 'react-toastify';

class IssueApprovalRepository implements IIssueApprovalRepository {
	createApprovalRequest = async ({
		issueId,
		approvalType,
		approvers,
		optionalNote,
		optionalAttachment,
	}: CreateApprovalRequestParams): Promise<CreateApprovalResponse | undefined> => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/v2/create-request`;
		const payload = {
			issueID: issueId,
			approvalType,
			approvers,
			comments: optionalNote,
			attachments: optionalAttachment ?? [],
		};

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		});

		const responseJson = await response.json();

		return response.status === 200
			? responseJson
			: {
					message: 'FAILED',
			  };
	};

	getApprovalData = async (issueId: string): Promise<IssueApproval | undefined> => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/v2/${issueId}?overview=true`;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		});

		if (response.status !== 200) {
			toast.error(`Error Status : ${response.status}`);
			return undefined;
		}

		const responseData = await response.json();
		return responseData?.data;
	};

	approveRequest = async ({ requestId, issueId, status, comments, departmentID }: ApproveRequestParams) => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/v2/approve-request/${requestId}`;
		const payload = {
			issueID: issueId,
			status,
			comments,
			...(departmentID && { departmentID }),
		};

		const response = await fetch(url, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		});
		const responseData = (await response.json()) as ReturnType<IIssueApprovalRepository['approveRequest']>;

		if (response.status !== 200) {
			toast.error(`Failed to post review. Error: ${response}`);
			return responseData;
		} else {
			return responseData;
		}
	};

	cancelApprovalRequest = async ({ issueId }: CancelRequestParams): Promise<string> => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/cancel-request/${issueId}`;

		const response = await fetch(url, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		});

		return response.status === 200 ? 'SUCCESS' : 'FAILED';
	};

	getApproverHistory = async ({
		issueId,
		id,
		type,
	}: GetApproverHistoryParams): Promise<IssueApprovalForApprover | undefined> => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/v2/${issueId}?overview=false&${type}=${id}`;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		});

		if (response.status !== 200) {
			toast.error(`Error Status : ${response.status}`);
			return undefined;
		}

		const responseData = (await response.json()) as APIResponse<IssueApprovalForApprover>;
		return responseData?.data;
	};

	getApproversList = async ({ requesterId }: GetApproversListParams): Promise<IssueApproverList | undefined> => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/approvers-list?requesterID=${requesterId}`;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		});

		if (response.status !== 200) {
			toast.error(`Error Status : ${response.status}`);
			return undefined;
		}

		const responseData = await response.json();
		return responseData || undefined;
	};

	addApprover = async ({ issueId, userIds }: AddApproverParams): Promise<string> => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/v2/add-approver/${issueId}`;
		const payload = { userID: userIds };

		const response = await fetch(url, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		});

		return response.status === 200 ? 'SUCCESS' : 'FAILED';
	};

	removeApprover = async ({ issueId, userId }: RemoveApproverParams): Promise<string> => {
		const authToken = await API.getFirebaseToken();
		const url = `${apiURL}/issues/issueApproval/v2/remove-approver/${issueId}`;
		const payload = { userID: [userId] };

		const response = await fetch(url, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		});

		if (response.status !== 200) {
			toast.error(`Failed to remove approver. Error Status : ${response.status}`);
		}

		return response.status === 200 ? 'SUCCESS' : 'FAILED';
	};
}

const issueApprovalRepository = new IssueApprovalRepository();

export { issueApprovalRepository };
