import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './product.action';
import * as types from './product.actionTypes';
import { ProductState } from './type';

export type ProductAction = ActionType<typeof actions>;

const initialState: ProductState = {
  productList: [],
  product: null,
  isLoading: false,
  isUpdateLoading: false,
  error: null,
  categoryList: [],
  company: 'asc',
  name: 'asc',
  category: 'asc',
  variant: 'asc',
  priceFrom: 'asc',
  priceTo: 'asc',
  filterQuery: '',
  sortBy: 'name',
  isLoadingCategory: false,
  errorCategory: null,
  isLoadingTargetMarket: false,
  targetMarketList: [],
  errorTargetMarket: null,
  updatedName: '',
  bulkSuccessRatio: '0/0',
  bulkErrorCount: 0,
  isBulkUploadLoading: false,
  pageIndex: 1,
  totalProduct: 0,
  paginateProducts: [],
  isBulkSuccess: false
};

export const productReducer = createReducer<ProductState, ProductAction>(initialState)
  .handleAction(actions.fetchProduct.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    // flush updated product name state
    updatedName: '',
    isBulkSuccess: false
  }))
  .handleAction(actions.fetchProduct.success, (state, action) => ({
    ...state,
    productList: action.payload,
    isLoading: false
  }))
  .handleAction(actions.fetchProduct.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.fetchPaginationProductAsync.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isBulkSuccess: false
  }))
  .handleAction(actions.fetchPaginationProductAsync.success, (state, action) => ({
    ...state,
    paginateProducts: action.payload.data,
    totalProduct: action.payload.total,
    isLoading: false
  }))
  .handleAction(actions.fetchPaginationProductAsync.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.createProduct.request, state => ({
    ...state,
    error: null,
    isUpdateLoading: true,
    isBulkSuccess: false
  }))
  .handleAction(actions.createProduct.success, (state, action) => ({
    ...state,
    updatedName: action.payload,
    isUpdateLoading: false
  }))
  .handleAction(actions.createProduct.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isUpdateLoading: false
  }))
  .handleAction(actions.fetchProductDetail.request, state => ({
    ...state,
    product: null,
    error: null,
    isLoading: true,
    isBulkSuccess: false
  }))
  .handleAction(actions.fetchProductDetail.success, (state, action) => ({
    ...state,
    product: action.payload,
    isLoading: false
  }))
  .handleAction(actions.fetchProductDetail.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.updateProduct.request, state => ({
    ...state,
    error: null,
    isUpdateLoading: true
  }))
  .handleAction(actions.updateProduct.success, (state, action) => ({
    ...state,
    updatedName: action.payload,
    isUpdateLoading: false
  }))
  .handleAction(actions.updateProduct.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isUpdateLoading: false
  }))
  .handleAction(actions.createProductBulk.request, state => ({
    ...state,
    bulkSuccessRatio: '0/0',
    bulkErrorCount: 0,
    isBulkUploadLoading: true
  }))
  .handleAction(actions.createProductBulk.success, (state, action) => ({
    ...state,
    bulkSuccessRatio: action.payload,
    bulkErrorCount: 0,
    isBulkUploadLoading: false,
    isBulkSuccess: true
  }))
  .handleAction(actions.createProductBulk.failure, (state, action) => ({
    ...state,
    bulkErrorCount: action.payload.length,
    isBulkUploadLoading: false
  }))
  .handleAction(actions.flushProductState, state => ({
    ...state,
    updatedName: '',
    bulkSuccessRatio: '0/0',
    bulkErrorCount: 0
  }))
  .handleAction(actions.fetchCategory.request, state => ({
    ...state,
    error: null,
    isBulkSuccess: false
  }))
  .handleAction(actions.fetchCategory.success, (state, action) => ({
    ...state,
    categoryList: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchCategory.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.fetchTargetMarket.request, state => ({
    ...state,
    error: null,
    isBulkSuccess: false
  }))
  .handleAction(actions.fetchTargetMarket.success, (state, action) => ({
    ...state,
    targetMarketList: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchTargetMarket.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(types.SET_LOADING as any, (state, action) => {
    return { ...state, isLoading: action.payload.value };
  })
  .handleAction(types.SET_SORT as any, (state, action) => {
    if (state.sortBy !== action.payload.sortType) {
      return {
        ...state,
        sortBy: action.payload.sortType,
        company: 'asc',
        productName: 'asc',
        category: 'asc',
        variant: 'asc',
        priceFrom: 'asc',
        priceTo: 'asc'
      };
    }
    return {
      ...state,
      [action.payload.sortType]: action.payload.sortOrder
    };
  })
  .handleAction(types.SET_FILTER_QUERY as any, (state, action) => {
    return {
      ...state,
      filterQuery: action.payload.text
    };
  })
  .handleAction(types.SET_PAGE_INDEX as any, (state, action) => {
    return {
      ...state,
      pageIndex: action.payload.value
    };
  });

export default productReducer;
