import { createAsyncAction } from 'typesafe-actions';
import {
  SitesFlagsChart,
  FETCH_SITES_FLAGS_CHART_REQUEST,
  FETCH_SITES_FLAGS_CHART_SUCCESS,
  FETCH_SITES_FLAGS_CHART_FAILURE,
  FetchFlagsChartPayload
} from './types';

export const fetchSitesFlagsChartAsync = createAsyncAction(
  FETCH_SITES_FLAGS_CHART_REQUEST,
  FETCH_SITES_FLAGS_CHART_SUCCESS,
  FETCH_SITES_FLAGS_CHART_FAILURE
)<FetchFlagsChartPayload, SitesFlagsChart, string>();
