import { statistic } from 'nimbly-common';

import { AuditorsRankResponse, DashboardAuditorsPayload } from 'reducers/dashboard/fetch-types/auditors-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type AuditorStatistic = statistic.UserRankStatistic;
export type AuditorsAuditorsList = AuditorsRankResponse['data'];

export interface AuditorsListState {
  readonly auditorsList_loading: boolean;
  readonly auditorsList_data: AuditorsAuditorsList;
  readonly auditorsList_error: string;
  readonly auditorsList_dl_loading: boolean;
  readonly auditorsList_dl_url: string;
  readonly auditorsList_dl_error: string;
}
type DownloadPayload = {
  dl: 1;
};

export const FETCH_AUDITORS_LIST_REQUEST = '@@dashboard/auditors/FETCH_AUDITORS_LIST_REQUEST';
export const FETCH_AUDITORS_LIST_SUCCESS = '@@dashboard/auditors/FETCH_AUDITORS_LIST_SUCCESS';
export const FETCH_AUDITORS_LIST_FAILURE = '@@dashboard/auditors/FETCH_AUDITORS_LIST_FAILURE';

export type FetchAuditorsListPayload = DashboardAuditorsPayload & {
  questionnaire?: string;
  users?: string;
};
export type FetchAuditorsListResponse = AuditorsRankResponse | ErrorResponse;

export const DOWNLOAD_AUDITORS_LIST_REQUEST = '@@dashboard/auditors/DOWNLOAD_AUDITORS_LIST_REQUEST';
export const DOWNLOAD_AUDITORS_LIST_SUCCESS = '@@dashboard/auditors/DOWNLOAD_AUDITORS_LIST_SUCCESS';
export const DOWNLOAD_AUDITORS_LIST_FAILURE = '@@dashboard/auditors/DOWNLOAD_AUDITORS_LIST_FAILURE';

export type DownloadAuditorsListPayload = DashboardAuditorsPayload & {
  questionnaire?: string;
  users?: string;
} & DownloadPayload;
