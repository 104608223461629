import { createAsyncAction, deprecated } from 'typesafe-actions';
import * as types from './repositoryUserUsage.actionTypes';
import { RepositoryUserUsageSortBy, UserUsageDetail } from './type';
import { SortDirection } from 'reducers/skuManagement/type';
import { PaginationResult } from '@nimbly-technologies/nimbly-common';

const { createStandardAction } = deprecated;

export const setRepositoryUserUsagePageIndex = createStandardAction(types.SET_REPOSITORY_USER_USAGE_PAGE_INDEX)<
	number
>();
export const setRepositoryUserUsageSortBy = createStandardAction(types.SET_REPOSITORY_USER_USAGE_SORT_BY)<
	RepositoryUserUsageSortBy
>();
export const setRepositoryUserUsageSortType = createStandardAction(types.SET_REPOSITORY_USER_USAGE_SORT_TYPE)<
	SortDirection
>();

export const fetchRepositoryUserUsage = createAsyncAction(
	types.FETCH_REPOSITORY_USER_USAGE_REQUEST,
	types.FETCH_REPOSITORY_USER_USAGE_SUCCESS,
	types.FETCH_REPOSITORY_USER_USAGE_FAILED,
)<undefined, PaginationResult<UserUsageDetail>, { error: string }>();
