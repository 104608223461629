export const FETCH_LMS_COURSE_BYID_REQUEST = '@lms/ourse/FETCH_LMS_COURSE_BYID_REQUEST';
export const FETCH_LMS_COURSE_BYID_SUCCESS = '@lms/course/FETCH_LMS_COURSE_BYID_SUCCESS';
export const FETCH_LMS_COURSE_BYID_FAILURE = '@lms/course/FETCH_LMS_COURSE_BYID_FAILURE';

export const SET_LMS_SELECTED_FILE = '@lms/course/SET_LMS_SELECTED_FILE';
export const SET_LMS_COURSE_BY_ID = '@lms/course/SET_LMS_COURSE_BY_ID';
export const SET_LMS_COURSE_IS_COMPLETED_MODAL_VISIBLE = '@lms/course/SET_LMS_COURSE_IS_COMPLETED_MODAL_VISIBLE';
export const SET_LMS_COURSE_RETAKEN = '@lms/course/SET_LMS_COURSE_RETAKEN';
export const SET_LMS_COURSE_LESSON_SEQUENCE = '@lms/course/SET_LMS_COURSE_LESSON_SEQUENCE';

export const SET_LMS_COURSE_CHAPTERS_LEARNER_ACTIVITY_MAP = '@lms/course/SET_LMS_COURSE_CHAPTERS_LEARNER_ACTIVITY_MAP';
export const SET_LMS_COURSE_FILES_WITHOUT_CHAPTER_LEARNER_ACTIVITY_MAP =
	'@lms/course/SET_LMS_COURSE_FILES_WITHOUT_CHAPTER_LEARNER_ACTIVITY_MAP';
export const SET_LMS_COURSE_FILE_LEARNER_ACTIVITY_FLAT_MAP =
	'@lms/course/SET_LMS_COURSE_FILE_LEARNER_ACTIVITY_FLAT_MAP';

export const SET_LMS_MOBILE_TOPICS_MODAL = '@lms/course/SET_LMS_MOBILE_TOPICS_MODAL';
export const SET_LMS_COURSE_QUIZ_LEARNER_ACTIVITY_MAP = '@lms/course/SET_LMS_COURSE_QUIZ_LEARNER_ACTIVITY_MAP';

export const SET_LMS_COURSE_AUTOSELECT_LAST_LESSON = '@lms/course/SET_LMS_COURSE_AUTOSELECT_LAST_LESSON';
