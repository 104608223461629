import { FetchUserNPSPayload, FetchUserNPSResponse } from 'reducers/dashboard/overview/userNPS/types';
import { apiURL } from '../../../../config/baseURL';
import API from 'helpers/api';

export const fetchUserNPS = async (userID: FetchUserNPSPayload): Promise<FetchUserNPSResponse> => {
  try {
    const token = await API.getFirebaseToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const url = `${apiURL}/users/userNPS/eligible`;

    const res = await fetch(url, options);

    if (res.status !== 200) {
      throw new Error('');
    }

    const userNPSResult: FetchUserNPSResponse = await res.json();
    return userNPSResult;
  } catch {
    const errorResult: FetchUserNPSResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
