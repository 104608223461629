import { LearnerActivity } from '@nimbly-technologies/nimbly-common';
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchLmsLearnerActivityById, updateLmsLearnerActivity } from 'services/lms/lmsLearnerActivity.service';
import * as actions from './lmsLearnerActivity.action';

function* fetchLmsLearnerActivityByIdSaga(
	action: ReturnType<typeof actions.fetchLmsLearnerActivityByIdAsync.request>,
): Generator {
	try {
		const activity: LearnerActivity = (yield call(
			fetchLmsLearnerActivityById,
			action.payload,
		) as unknown) as LearnerActivity;

		if (!activity) throw new Error('Failed to get learnerActivity');

		yield put(actions.fetchLmsLearnerActivityByIdAsync.success(activity));
	} catch {
		yield put(actions.fetchLmsLearnerActivityByIdAsync.failure('Failed to get learnerActivity'));
	}
}

function* updateLmsLearnerActivitySaga(
	action: ReturnType<typeof actions.updateLmsLearnerActivityAsync.request>,
): Generator {
	try {
		const { learnerActivityID, ...body } = action.payload;

		const query = () => updateLmsLearnerActivity(learnerActivityID, body);
		const activity: LearnerActivity = (yield call(query) as unknown) as LearnerActivity;

		yield put(actions.fetchLmsLearnerActivityByIdAsync.request({ learnerActivityId: learnerActivityID }));
		yield put(actions.updateLmsLearnerActivityAsync.success(activity));
	} catch {
		yield put(actions.updateLmsLearnerActivityAsync.failure('Failed to update learner activity'));
	}
}

export default function* lmsLearnerActivitySaga() {
	yield takeEvery(actions.fetchLmsLearnerActivityByIdAsync.request, fetchLmsLearnerActivityByIdSaga);
	yield takeEvery(actions.updateLmsLearnerActivityAsync.request, updateLmsLearnerActivitySaga);
}
