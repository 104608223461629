import { ActionType } from 'typesafe-actions';

import * as types from './reportInsights.actionTypes';
import * as actions from './reportInsights.action';
import moment from 'moment';
import { ReportInsightsReducerState } from './reportInsightsStore';
import {
	DEFAULT_PAGE_NUMBER,
	DEFAULT_ROW_LIMIT,
	DEFAULT_VIEW_BY,
	REPORT_DOWNLOAD_VIEW_INIT_FIELDS,
	REPORT_SUMMARY_INITIAL_DATA,
} from 'pages/dashboardRevamp/dashboardUtils';
import { GroupByEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export type ReportInsightsReducerAction = ActionType<typeof actions>;

export interface ReportInsightsFilters {
	startDate: string;
	endDate: string;
	siteIDs: string[];
	departmentIDs: string[];
	userIDs: string[];
	questionnaireIDs: string[];
	categories: string[];
	reportStatus: string[];
}

export const DEFAULT_FILTERS: ReportInsightsFilters = {
	startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
	endDate: moment().format('YYYY-MM-DD'),
	siteIDs: [],
	departmentIDs: [],
	userIDs: [],
	questionnaireIDs: [],
	categories: [],
	reportStatus: [],
};

export const initialState: ReportInsightsReducerState = {
	isLoading: false,
	filters: DEFAULT_FILTERS,

	isReportTableCountDataLoading: false,
	isReportTableRCRDataLoading: false,

	reportTableCountData: [],
	reportTableCountGroupBy: DEFAULT_VIEW_BY,
	reportTableCountRowLimit: DEFAULT_ROW_LIMIT,
	reportTableCountCurrentPage: DEFAULT_PAGE_NUMBER,
	reportTableRCRData: [],
	reportTableRCRGroupBy: DEFAULT_VIEW_BY,
	reportTableRCRRowLimit: DEFAULT_ROW_LIMIT,
	reportTableRCRCurrentPage: DEFAULT_PAGE_NUMBER,
	reportTableIsLoading: false,

	reportStatusIsLoading: false,
	reportStatusDrilledDataLoading: false,
	reportStatusGroupBy: null,
	reportStatusDrillBy: null,
	reportStatusData: [],
	reportStatusDrilledData: [],

	reportTypeIsLoading: false,
	reportTypeDrilledDataLoading: false,
	reportTypeGroupBy: null,
	reportTypeDrillBy: null,
	reportTypeData: [],
	reportTypeDrilledData: [],

	reportSummaryIsLoading: false,
	reportSummaryData: REPORT_SUMMARY_INITIAL_DATA,

	reportDownloadIsLoading: false,
	reportDownloadPeriod: null,
	reportDownloadData: [],
	reportDownloadGroupBy: GroupByEnum.SITE,
	reportDownloadFieldNames: REPORT_DOWNLOAD_VIEW_INIT_FIELDS,
};

export type ReportInsightsAction = ActionType<typeof actions>;

// eslint-disable-next-line complexity
export function reportInsightsReducer(
	state = initialState,
	action: { type: string; payload: any },
): ReportInsightsReducerState {
	switch (action.type) {
		case types.SET_FILTERS: {
			const updatedFilters = action.payload ? { ...action.payload } : {};
			return {
				...state,
				filters: { ...state.filters, ...updatedFilters },
			};
		}

		case types.SET_REPORT_STATUS_IS_LOADING: {
			return {
				...state,
				reportStatusIsLoading: action.payload ?? false,
			};
		}

		case types.SET_REPORT_STATUS_DRILLED_DATA_IS_LOADING: {
			return {
				...state,
				reportStatusDrilledDataLoading: action.payload ?? false,
			};
		}

		case types.SET_REPORT_STATUS_BASIC_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportStatusData: data,
				reportStatusIsLoading: false,
			};
		}
		case types.SET_REPORT_STATUS_DRILLED_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportStatusDrilledData: data,
				reportStatusIsLoading: false,
			};
		}
		case types.SET_REPORT_STATUS_GROUP_BY_DRILL_BY: {
			const { drillBy, groupBy } = action.payload;
			return {
				...state,
				reportStatusGroupBy: groupBy,
				reportStatusDrillBy: drillBy,
			};
		}

		case types.SET_REPORT_TYPE_IS_LOADING: {
			return {
				...state,
				reportTypeIsLoading: action.payload ?? false,
			};
		}

		case types.SET_REPORT_TYPE_DRILLED_DATA_IS_LOADING: {
			return {
				...state,
				reportTypeDrilledDataLoading: action.payload ?? false,
			};
		}

		case types.SET_REPORT_TYPE_BASIC_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportTypeData: data,
				reportTypeIsLoading: false,
			};
		}
		case types.SET_REPORT_TYPE_DRILLED_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportTypeDrilledData: data,
				reportTypeIsLoading: false,
			};
		}
		case types.SET_REPORT_TYPE_GROUP_BY_DRILL_BY: {
			const { drillBy, groupBy } = action.payload;
			return {
				...state,
				reportTypeGroupBy: groupBy,
				reportTypeDrillBy: drillBy,
			};
		}

		case types.SET_REPORT_TABLE_IS_LOADING: {
			return {
				...state,
				reportTableIsLoading: action.payload ?? false,
			};
		}

		case types.SET_REPORT_TABLE_COUNT_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportTableCountData: data,
				reportTableIsLoading: false,
			};
		}

		case types.SET_REPORT_TABLE_COUNT_GROUP_BY: {
			return {
				...state,
				reportTableCountGroupBy: action.payload,
			};
		}

		case types.SET_REPORT_TABLE_COUNT_ROW_LIMIT: {
			return {
				...state,
				reportTableCountRowLimit: action.payload,
			};
		}

		case types.SET_REPORT_TABLE_RCR_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportTableRCRData: data,
				reportTableIsLoading: false,
			};
		}

		case types.SET_REPORT_TABLE_COUNT_CURRENT_PAGE: {
			return {
				...state,
				reportTableCountCurrentPage: action.payload,
			};
		}

		case types.SET_REPORT_TABLE_RCR_CURRENT_PAGE: {
			return {
				...state,
				reportTableRCRCurrentPage: action.payload,
			};
		}

		case types.SET_REPORT_TABLE_RCR_GROUP_BY: {
			return {
				...state,
				reportTableRCRGroupBy: action.payload,
			};
		}

		case types.SET_REPORT_TABLE_RCR_ROW_LIMIT: {
			return {
				...state,
				reportTableRCRRowLimit: action.payload,
			};
		}

		case types.SET_REPORT_SUMMARY_IS_LOADING: {
			return {
				...state,
				reportSummaryIsLoading: action.payload ?? false,
			};
		}

		case types.SET_REPORT_SUMMARY_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportSummaryData: data,
				reportSummaryIsLoading: false,
			};
		}

		case types.SET_REPORT_DOWNLOAD_VIEW_IS_LOADING: {
			return {
				...state,
				reportDownloadIsLoading: action.payload ?? false,
			};
		}

		case types.SET_REPORT_DOWNLOAD_GROUP_BY_FIELD_NAMES: {
			return {
				...state,
				reportDownloadGroupBy: action.payload.groupBy,
				reportDownloadFieldNames: action.payload.fieldNames,
			};
		}

		case types.SET_REPORT_DOWNLOAD_VIEW_DATA: {
			const { data = [] } = action.payload;
			return {
				...state,
				reportDownloadData: data,
				reportDownloadIsLoading: false,
			};
		}

		default: {
			return state;
		}
	}
}
