import { action, createAsyncAction, deprecated } from 'typesafe-actions';
import * as types from './lmsDashboard.actionTypes';
import {
	IDashboardIndicatorStore,
	IDashboardCompletionStore,
	IDashboardPassFailRatioStore,
	IDashboardLessonProgressStore,
	IDashboardLessonStatusStore,
	IDashboardSummaryStore,
	IDashboardQuizInSightStore,
	EntityStatusStore,
	OverallQuizInsights,
	adminOverallInsight,
	instructorOverallInsight,
	learnerOverallInsight,
	IDashboardtimeSpent,
	LmsDashboardTrends,
	LmsDashboardTopBottomEntities,
	LmsEntityDetail,
	LmsEntityDetailResponse,
	LmsEntityEnrollmentStats,
	LmsEntityStatusWiseSplit,
	IDashboardFilters,
} from './lmsDashboard.type';
const { createAction } = deprecated;

export const setCustomDates = (startDate: number | null, endDate: number | null) =>
	action(types.SET_LMS_DASHBOARD_LESSONSTATUS_DATE, { startDate, endDate });

export const setSummaryPage = (page: number) => action(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_PAGE, { page });
export const setEntityDetailPage = (page: number) => action(types.SET_LMS_ENTITY_DETAILS_PAGE, { page });

export const setSortSummary = (sortField: string, sortDirection: string) =>
	action(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_SORT, { sortField, sortDirection });

export const setSummaryStatus = (status: string) => action(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_STATUS, { status });
export const setSummaryType = (type: string) => action(types.SET_LMS_DASHBOARD_SUMMARY_TABLE_TYPE, { type });

export const setDashboardFilters = (filters: Partial<IDashboardFilters>) =>
	action(types.SET_LMS_DASHBOARD_FILTERS, { filters });

export const fetchLmsDashboardIndicatorAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_INDICATOR_REQUEST,
	types.FETCH_LMS_DASHBOARD_INDICATOR_SUCCESS,
	types.FETCH_LMS_DASHBOARD_INDICATOR_FAILURE,
)<undefined, IDashboardIndicatorStore, string>();

export const fetchLmsDashboardCompletionStatsAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_COMPLETIONSTATS_REQUEST,
	types.FETCH_LMS_DASHBOARD_COMPLETIONSTATS_SUCCESS,
	types.FETCH_LMS_DASHBOARD_COMPLETIONSTATS_FAILURE,
)<undefined, IDashboardCompletionStore, string>();

export const fetchLmsDashboardPassFailRatioAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_PASSFAILRATIO_REQUEST,
	types.FETCH_LMS_DASHBOARD_PASSFAILRATIO_SUCCESS,
	types.FETCH_LMS_DASHBOARD_PASSFAILRATIO_FAILURE,
)<undefined, IDashboardPassFailRatioStore[], string>();

export const fetchLmsDashboardLessonProgressAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_LESSONPROGRESS_REQUEST,
	types.FETCH_LMS_DASHBOARD_LESSONPROGRESS_SUCCESS,
	types.FETCH_LMS_DASHBOARD_LESSONPROGRESS_FAILURE,
)<undefined, IDashboardLessonProgressStore[], string>();

export const fetchLmsDashboardLessonStatusAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_LESSONSTATUS_REQUEST,
	types.FETCH_LMS_DASHBOARD_LESSONSTATUS_SUCCESS,
	types.FETCH_LMS_DASHBOARD_LESSONSTATUS_FAILURE,
)<undefined, IDashboardLessonStatusStore, string>();

export const fetchLmsDashboardSummaryAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_SUMMARY_TABLE_REQUEST,
	types.FETCH_LMS_DASHBOARD_SUMMARY_TABLE_SUCCESS,
	types.FETCH_LMS_DASHBOARD_SUMMARY_TABLE_FAILURE,
)<string | undefined, IDashboardSummaryStore, string>();

export const fetchLmsDashboardQuizInsightAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_QUIZINSIGHT_REQUEST,
	types.FETCH_LMS_DASHBOARD_QUIZINSIGHT_SUCCESS,
	types.FETCH_LMS_DASHBOARD_QUIZINSIGHT_FAILURE,
)<undefined, IDashboardQuizInSightStore[], string>();

export const fetchLmsDashboardEntityStatusAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_ENTITY_STATUS_REQUEST,
	types.FETCH_LMS_DASHBOARD_ENTITY_STATUS_SUCCESS,
	types.FETCH_LMS_DASHBOARD_ENTITY_STATUS_FAILURE,
)<string, EntityStatusStore, string>();

export const fetchLmsDashboardQuizInsightV2Async = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_REQUEST,
	types.FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_SUCCESS,
	types.FETCH_LMS_DASHBOARD_QUIZINSIGHTV2_FAILURE,
)<string, IDashboardQuizInSightStore[], string>();

export const fetchLmsDashboardOverallQuizInsightAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_REQUEST,
	types.FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_SUCCESS,
	types.FETCH_LMS_DASHBOARD_OVERALL_QUIZINSIGHT_FAILURE,
)<string, OverallQuizInsights, string>();

export const fetchLmsDashboardOverviewAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_REQUEST,
	types.FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_SUCCESS,
	types.FETCH_LMS_DASHBOARD_OVERALL_INSIGHT_FAILURE,
)<string, adminOverallInsight | instructorOverallInsight | learnerOverallInsight, string>();

export const fetchLmsDashboardTimeSpentAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_TIME_SPENT_REQUEST,
	types.FETCH_LMS_DASHBOARD_TIME_SPENT_FAILURE,
	types.FETCH_LMS_DASHBOARD_TIME_SPENT_SUCCESS,
)<string, IDashboardtimeSpent[], string>();

export const fetchLmsDashboardTrendsAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_TRENDS_REQUEST,
	types.FETCH_LMS_DASHBOARD_TRENDS_SUCCESS,
	types.FETCH_LMS_DASHBOARD_TRENDS_FAILURE,
)<{ role: string; period: string; entity: string }, LmsDashboardTrends[], string>();

export const fetchLmsDashboardTopBottomEntitiesAsync = createAsyncAction(
	types.FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_REQUEST,
	types.FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_SUCCESS,
	types.FETCH_LMS_DASHBOARD_TOP_BOTTOM_ENTITES_FAILURE,
)<{ role: string; entity: string; status: string; sortDirection: string }, LmsDashboardTopBottomEntities[], string>();

export const fetchLmsEntityDetailsAsync = createAsyncAction(
	types.FETCH_LMS_ENTITY_DETAILS_REQUEST,
	types.FETCH_LMS_ENTITY_DETAILS_SUCCESS,
	types.FETCH_LMS_ENTITY_DETAILS_FAILURE,
)<string, LmsEntityDetailResponse, string>();

export const fetchLmsEnrollmentStatsAsync = createAsyncAction(
	types.FETCH_LMS_ENROLLMENT_STATS_REQUEST,
	types.FETCH_LMS_ENROLLMENT_STATS_SUCCESS,
	types.FETCH_LMS_ENROLLMENT_STATS_FAILURE,
)<string, LmsEntityEnrollmentStats, string>();

export const fetchLmsEntityStatusWiseSplitAsync = createAsyncAction(
	types.FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_REQUEST,
	types.FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_SUCCESS,
	types.FETCH_LMS_ENTITY_STATUS_WISE_SPLIT_FAILURE,
)<string, LmsEntityStatusWiseSplit, string>();
