import { GetRepositoryItemsResponse, PaginationResult } from '@nimbly-technologies/nimbly-common';
import { createAsyncAction, deprecated } from 'typesafe-actions';
import * as types from './repository.actionTypes';
import {
	FolderHierarchyDetail,
	RepositoryFileSection,
	RepositoryFilters,
	RepositoryProgressModalConfig,
	RepositoryTabEnum,
	SearchAttachmentResult,
	SelectedRepositoryItem,
} from './type';
import { LmsViewType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';
import { SortDirection } from 'reducers/skuManagement/type';
import { RepositoryListEnum, RepositorySortByOptionsEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';

const { createStandardAction } = deprecated;

/**
 * @activeTab selected active tab
 */
export const setActiveRepositoryTab = createStandardAction(types.SET_ACTIVE_REPOSITORY_TAB)<RepositoryTabEnum>();
/**
 * @searchQuery search query from user input
 */
export const setRepositorySearchQuery = createStandardAction(types.SET_REPOSITORY_SEARCH_QUERY)<string>();
/**
 * @filters object of repository filters
 */
export const setRepositoryFilters = createStandardAction(types.SET_REPOSITORY_FILTERS)<Partial<RepositoryFilters>>();
export const resetRepositoryFilters = createStandardAction(types.RESET_REPOSITORY_FILTERS)<undefined>();
/**
 * @groupBy group type
 */
export const setRepositoryGroupBy = createStandardAction(types.SET_REPOSITORY_GROUP_BY)<RepositoryListEnum>();
/**
 * @pageIndex page index for pagination
 */
export const setRepositoryPageIndex = createStandardAction(types.SET_REPOSITORY_PAGE_INDEX)<number>();
/**
 * @gridSize size for grid size
 */
export const setRepositoryGridSize = createStandardAction(types.SET_REPOSITORY_GRID_SIZE)<number>();
export const setRepositoryViewType = createStandardAction(types.SET_REPOSITORY_VIEW_TYPE)<LmsViewType>();
export const setRepositorySortBy = createStandardAction(types.SET_REPOSITORY_SORT_BY)<RepositorySortByOptionsEnum>();
export const setRepositorySortType = createStandardAction(types.SET_REPOSITORY_SORT_TYPE)<SortDirection>();
export const setRepositoryFolderHierarchy = createStandardAction(types.SET_REPOSITORY_FOLDER_HIERARCHY)<
	FolderHierarchyDetail[]
>();
export const setRepositoryFolders = createStandardAction(types.SET_REPOSITORY_FOLDERS)<GetRepositoryItemsResponse[]>();
export const setRepositoryFiles = createStandardAction(types.SET_REPOSITORY_FILES)<GetRepositoryItemsResponse[]>();
export const setRepositoryFileSections = createStandardAction(types.SET_REPOSITORY_FILE_SECTIONS)<
	RepositoryFileSection
>();

export const setSelectedRepositoryFile = createStandardAction(
	types.SET_SELECTED_REPOSITORY_FILE,
)<SelectedRepositoryItem | null>();
export const setSelectedRepositoryFileIds = createStandardAction(types.SET_SELECTED_REPOSITORY_FILE_IDS)<Set<string>>();
export const setTotalSelectedRepositoryFiles = createStandardAction(types.SET_TOTAL_SELECTED_REPOSITORY_FILES)<
	number
>();
export const setTotalSelectedFilesSize = createStandardAction(types.SET_TOTAL_SELECTED_REPOSITORY_FILES_SIZE)<number>();

export const setSelectedRepositoryFolder = createStandardAction(types.SET_SELECTED_REPOSITORY_FOLDER)<
	GetRepositoryItemsResponse | undefined
>();
export const setSelectedRepositoryFolderIds = createStandardAction(types.SET_SELECTED_REPOSITORY_FOLDER_IDS)<
	Set<string>
>();

/**
 * @selectedDates selected dates
 */
export const setSelectedRepositoryDates = createStandardAction(types.SET_SELECTED_REPOSITORY_DATES)<Set<string>>();

export const toggleRepositoryFilter = createStandardAction(types.TOGGLE_REPOSITORY_FILTER)<boolean>();
export const toggleRepositorySelectMode = createStandardAction(types.TOGGLE_REPOSITORY_SELECT_MODE)<boolean>();
export const toggleRepositoryBulkOperation = createStandardAction(types.TOGGLE_REPOSITORY_BULK_OPERATION)<boolean>();

export const toggleRepositoryFilePreviewModal = createStandardAction(types.TOGGLE_FILE_PREVIEW_MODAL)<boolean>();
export const toggleRepositoryNewFolderModal = createStandardAction(types.TOGGLE_NEW_FOLDER_MODAL)<boolean>();
export const toggleRepositoryRenameFolderModal = createStandardAction(types.TOGGLE_RENAME_FOLDER_MODAL)<boolean>();
export const toggleRepositoryDeleteForeverModal = createStandardAction(types.TOGGLE_DELETE_FOREVER_MODAL)<boolean>();
export const toggleRepositoryEmptyTrashModal = createStandardAction(types.TOGGLE_EMPTY_TRASH_MODAL)<boolean>();
export const toggleRepositoryRestoreModal = createStandardAction(types.TOGGLE_RESTORE_MODAL)<boolean>();
export const toggleRepositoryShareModal = createStandardAction(types.TOGGLE_SHARE_MODAL)<boolean>();
export const toggleMoveToFolderModal = createStandardAction(types.TOGGLE_MOVE_TO_FOLDER_MODAL)<boolean>();
export const toggleRepositoryStarModal = createStandardAction(types.TOGGLE_STAR_MODAL)<boolean>();
export const toggleRepositoryTrashModal = createStandardAction(types.TOGGLE_TRASH_MODAL)<boolean>();
export const toggleRepositoryUploadFileModal = createStandardAction(types.TOGGLE_UPLOAD_FILE_MODAL)<boolean>();
export const toggleRepositoryDownloadErrorModal = createStandardAction(types.TOGGLE_DOWNLOAD_ERROR_MODAL)<boolean>();
export const toggleRepositoryDownloadInitializationModal = createStandardAction(
	types.TOGGLE_DOWNLOAD_INITIALIZATION_MODAL,
)<boolean>();
export const setRepositoryDownloadProgressModalConfig = createStandardAction(types.SET_DOWNLOAD_PROGRESS_MODAL_CONFIG)<
	RepositoryProgressModalConfig
>();

export const fetchRepository = createAsyncAction(
	types.FETCH_REPOSITORY_REQUEST,
	types.FETCH_REPOSITORY_SUCCESS,
	types.FETCH_REPOSITORY_FAILED,
)<
	{ loadMore?: boolean },
	PaginationResult<GetRepositoryItemsResponse> & {
		folders: GetRepositoryItemsResponse[];
	},
	{ error: string }
>();

export const searchRepository = createAsyncAction(
	types.SEARCH_REPOSITORY_REQUEST,
	types.SEARCH_REPOSITORY_SUCCESS,
	types.SEARCH_REPOSITORY_FAILED,
)<undefined, SearchAttachmentResult, { error: string }>();
