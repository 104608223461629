import {
  IssuesOverviewStat,
  FetchOverviewStatPayload,
  FetchOverviewStatResponse
} from 'reducers/dashboard/issues/overviewStat/types';
import { getToken } from 'reducers/api';
import serializeQuery from '../../../../helpers/serializeQuery';
import { apiURL } from 'config/baseURL';
import { getDummyIssuesOverviewStat } from 'utils/dashboardDummy/issues/dummyIssuesOverviewStat';

type OverviewStatData = {
  message?: 'Invalid date Query';
  issuesData: IssuesOverviewStat;
};

export const fetchOverviewStat = async (payload: FetchOverviewStatPayload): Promise<FetchOverviewStatResponse> => {
  const { isDummy, ...query } = payload;
  // HANDLE DUMMY DATA
  if (isDummy) {
    const dummyOverviewStat = getDummyIssuesOverviewStat(payload);
    return {
      message: 'SUCCESS',
      data: dummyOverviewStat
    };
  }

  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const serializedQueries = serializeQuery(query);
    const url = `${apiURL}/statistics/issues?${serializedQueries}`;

    const res = await fetch(url, options);

    if (res.status !== 200) {
      throw new Error('');
    }

    const overviewStatResult: OverviewStatData = await res.json();
    return {
      message: 'SUCCESS',
      data: overviewStatResult.issuesData
    };
  } catch {
    const errorResult: FetchOverviewStatResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
