import React from 'react';

import { AuthHeadline } from './AuthHeadline';
import { AuthForm } from './AuthForm';
import { SitePropsFlashMessage, SitePropsForgotPasswordData, SitePropsLoginData } from './Auth';
import { version } from '../../../../package.json';

export interface AuthContainerProps {
	/** Login form data: { email, password } */
	loginCredentials: SitePropsLoginData;

	/** Forgot password form data: { email } */
	forgotPasswordData: SitePropsForgotPasswordData;

	/** If yes, disable form function and show loading animation */
	isLoading: boolean;

	/** If yes, show forgot password page. Otherwise, show login page instead */
	modeForgot: boolean;

	/** Contain information about message that can be displayed on screen */
	siteFlashMessage: SitePropsFlashMessage;

	/** Executed when form submitted */
	handleSubmitForm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => void;

	/** Executed when user change the email address field on login form to check for errors */
	handleChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;

	/** Executed when user change the password field on login form to check for errors */
	handleChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;

	/** Executed when user change the email address field on forgot password page to check for errors */
	handleChangeForgotPass: (e: React.ChangeEvent<HTMLInputElement>) => void;

	/** Executed when user clicked forgot my password / return to login. When called, toggle between those two pages */
	handleToggleForgotPass: () => void;

	/** Federated Login Props */
	isFederated: boolean | null | undefined;
	isRequestAccess: boolean;
	isCheckingEmail: boolean;
	isEmailValid: boolean;
	isRequestSuccess: boolean;
	isRequestFailed: boolean;
	isAuthenticating: boolean;
	isMultipleRequest: boolean;
	validationMessage: string;
	handleRequestAccess: () => void;
	handleDismissRequestSuccess: () => void;
}

export const AuthContainer = (props: AuthContainerProps) => {
	return (
		<div>
			<AuthHeadline modeForgot={props.modeForgot} />
			<AuthForm
				isAuthenticating={props.isAuthenticating}
				isRequestAccess={props.isRequestAccess}
				isFederated={props.isFederated}
				isCheckingEmail={props.isCheckingEmail}
				isEmailValid={props.isEmailValid}
				isRequestSuccess={props.isRequestSuccess}
				isRequestFailed={props.isRequestFailed}
				isMultipleRequest={props.isMultipleRequest}
				loginCredentials={props.loginCredentials}
				forgotPasswordData={props.forgotPasswordData}
				isLoading={props.isLoading}
				modeForgot={props.modeForgot}
				siteFlashMessage={props.siteFlashMessage}
				validationMessage={props.validationMessage}
				handleSubmitForm={props.handleSubmitForm}
				handleChangeEmail={props.handleChangeEmail}
				handleChangePassword={props.handleChangePassword}
				handleChangeForgotPass={props.handleChangeForgotPass}
				handleToggleForgotPass={props.handleToggleForgotPass}
				handleRequestAccess={props.handleRequestAccess}
				handleDismissRequestSuccess={props.handleDismissRequestSuccess}
			/>
			<div className="version">
				<span>ver {version}</span>
			</div>
		</div>
	);
};

export default AuthContainer;
