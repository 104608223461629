import { ActionType, createReducer } from 'typesafe-actions';
import { CourseState, CourseFetchState } from './type.d';
import update from 'immutability-helper';
import * as actions from './courses.action';
import * as types from './courses.actionTypes';

export type CoursesAction = ActionType<typeof actions>;

export const initialState: CourseState = {
	error: '',
	bulkError: [],
	fetchState: CourseFetchState.SUCCESS,
	courses: [],

	totalPages: 0,
	totalDocs: 0,

	page: 1,
	limit: 15,
	sortField: 'publishedDate',
	sortDirection: 'desc',
	search: '',

	filters: {
		userIDs: [],
		startDate: '',
		endDate: '',
		expiryStartDate: '',
		expiryEndDate: '',
		status: [],
	},
};

export const coursesReducer = createReducer<CourseState, CoursesAction>(initialState)
	.handleType(types.SET_COURSES_PAGE_INDEX, (state, action) =>
		update(state, {
			fetchState: { $set: CourseFetchState.LOADING },
			page: { $set: action.payload.page },
		}),
	)
	.handleType(types.SET_COURSES_SEARCH_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: CourseFetchState.LOADING },
			page: { $set: initialState.page },
			search: { $set: action.payload.searchQuery },
		}),
	)
	.handleType(types.SET_COURSES_SORT, (state, action) =>
		update(state, {
			fetchState: { $set: CourseFetchState.LOADING },
			page: { $set: 1 },
			sortField: { $set: action.payload.sortField },
			sortDirection: { $set: action.payload.sortDirection },
		}),
	)
	.handleType(types.SET_COURSES_FILTER_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: CourseFetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: { ...state.filters, ...action.payload.filters } },
		}),
	)
	.handleType(types.RESET_COURSES_FILTER_QUERY, (state) =>
		update(state, {
			fetchState: { $set: CourseFetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_COURSES_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: CourseFetchState.LOADING },
			courses: { $set: initialState.courses },
			page: { $set: initialState.page },
			sortField: { $set: initialState.sortField },
			sortDirection: { $set: initialState.sortDirection },
			search: { $set: initialState.search },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_COURSES_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: CourseFetchState.SUCCESS },
			courses: { $set: action.payload.courses },
			totalDocs: { $set: action.payload.totalDocs || 0 },
			page: { $set: action.payload.page || 0 },
			totalPages: { $set: action.payload.totalPages || 0 },
		}),
	)
	.handleType(types.FETCH_COURSES_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: CourseFetchState.ERROR },
			courses: { $set: initialState.courses },
		}),
	)
	.handleType(types.EDIT_COURSES_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: CourseFetchState.LOADING },
			bulkError: { $set: initialState.error },
		}),
	)
	.handleType(types.EDIT_COURSES_SUCCESS, (state) =>
		update(state, {
			fetchState: { $set: CourseFetchState.SUCCESS },
		}),
	)
	.handleType(types.EDIT_COURSES_FAILED, (state, action) =>
		update(state, {
			bulkError: { $set: action.payload.error },
			fetchState: { $set: CourseFetchState.ERROR },
		}),
	)
	.handleType(types.EDIT_COURSE_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: CourseFetchState.LOADING },
		}),
	)
	.handleType(types.EDIT_COURSE_SUCCESS, (state) =>
		update(state, {
			fetchState: { $set: CourseFetchState.SUCCESS },
		}),
	)
	.handleType(types.EDIT_COURSE_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: CourseFetchState.ERROR },
		}),
	);
