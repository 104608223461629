import {
	AttachmentGalleryResponse,
	FilterAttachmentsResult,
	GetRepositoryItemsResponse,
	IssueType,
	PaginatedRepositoryItems,
	mimeTypes,
} from '@nimbly-technologies/nimbly-common';
import { RepositoryListEnum, RepositorySortByOptionsEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import { AttachmentDetails } from 'components/gallery/component/AttachmentThumbnailCard';
import { LmsViewType } from 'components/lms/coursesAndSyllabus/LmsCsManagement.types';
import { AttachmentState, AttachmentUploadState } from 'hooks/useUploadFileAttachment';
import { SortDirection } from 'reducers/skuManagement/type';
import { AttachmentResult } from 'services/gallery/gallery.service';

export enum RepositoryTabEnum {
	MY_FILES = 'myFiles',
	SHARED_WITH_ME = 'sharedWithMe',
	RECENT = 'recent',
	STARRED = 'starred',
	TRASH = 'trash',
	SEARCH = 'search',
	DASHBOARD = 'dashboard',
	FOLDER = 'folder',
}

export enum RepositoryFileType {
	IMAGE = 'image',
	VIDEO = 'video',
	DOCUMENT = 'document',
}

export enum RepositoryFilterEnum {
	FILE_TYPE = 'qFileType',
	SHARED_BY = 'qUser',
	LOCATION = 'qLocation',
	START_DATE = 'qStartDate',
	END_DATE = 'qEndDate',
}

export interface RepositoryFilters {
	qFileType: string[];
	qUser: string[];
	qLocation: string;
	qStartDate: string | null; // YYYY-MM-DD
	qEndDate: string | null; // YYYY-MM-DD
}

export const repositoryFilterArray = [RepositoryFilterEnum.FILE_TYPE, RepositoryFilterEnum.SHARED_BY];

export interface RepositoryItem {
	id: string;
	name: string;
	type: string;
	owner: string;
	sharedDate?: Date | string;
	lastModifiedDate?: Date | string;
	lastModified: {
		date: Date;
		user: string;
	};
	fileSize: string;
}

export type RepositorySections = Record<string, RepositoryItem[]>;

export interface SearchAttachmentResult {
	questionMatches: AttachmentGalleryResponse[];
	questionMatchCount: number;
	categoryMatches: AttachmentGalleryResponse[];
	categoryMatchCount: number;
	issueMatchCount: number;
	issueMatches: AttachmentGalleryResponse[];
}

export interface RepositoryProgressModalConfig {
	isVisible: boolean;
	count: number;
	total: number;
}

export enum RepositoryItemEnum {
	FILE = 'file',
	FOLDER = 'folder',
}

export interface RepositoryQueryOptions extends RepositoryFilters {
	search: string;
	parentID: string;
	limit: number;
	page: number;
}

export interface FileSection {
	date: string; // yyyy-mm-dd
	entities: GetRepositoryItemsResponse[];
}

export interface RepositoryFileSection {
	[date: string]: GetRepositoryItemsResponse[];
}

export interface FolderHierarchyDetail {
	id: string;
	name: string;
}

export interface FolderHierarchy {
	[index: number]: FolderHierarchyDetail;
}

export type SelectedRepositoryItem = GetRepositoryItemsResponse & {
	index: number;
	sectionKey?: string;
};

export interface RepositoryState {
	error: string;
	isLoading: boolean;
	isLoadingNextPage: boolean;
	isLoadingSearch: boolean;
	activeTab: RepositoryTabEnum;
	gridSize: number;
	viewType: LmsViewType;
	sortBy: RepositorySortByOptionsEnum;
	sortType: SortDirection;

	page: number;
	limit: number;
	totalPages: number;
	totalDocs: number;
	canLoadMore: boolean;

	folders: GetRepositoryItemsResponse[];
	files: GetRepositoryItemsResponse[];
	fileSections: RepositoryFileSection;
	selectedFile: SelectedRepositoryItem | null;
	selectedFileIds: Set<string>;
	totalSelectedItems: number;
	totalSelectedFilesSize: number;
	selectedFolder: GetRepositoryItemsResponse | undefined;
	selectedFolderIds: Set<string>;
	selectedDates: Set<string>;
	folderHierarchy: FolderHierarchyDetail[];

	searchQuery: string;
	searchData: SearchAttachmentResult;
	filters: RepositoryFilters;
	list: RepositoryListEnum;
	groupByCount?: [
		{
			question?: string;
			category?: string;
			noOfAttachments: number;
		},
	];

	isFilterOpen: boolean;
	isSelectMode: boolean;
	isBulkOperation: boolean;

	isFilePreviewModalVisible: boolean;
	isDeleteForeverModalVisible: boolean;
	isEmptyTrashModalVisible: boolean;
	isRestoreModalVisible: boolean;
	isNewFolderModalVisible: boolean;
	isTrashModalVisible: boolean;
	isRenameModalVisible: boolean;
	isShareModalVisible: boolean;
	isMovetoFolderModalVisible: boolean;
	isStarModalVisible: boolean;
	isUploadFileModalVisible: boolean;
	isDownloadErrorModalVisible: boolean;
	isDownloadInitializationModalVisible: boolean;
	downloadProgressModalConfig: RepositoryProgressModalConfig;
}

export type FetchAttachmentsSuccessPayload = Omit<FilterAttachmentsResult, 'results'> & {
	attachments: AttachmentGalleryResponse[];
	sections: Record<string, AttachmentGalleryResponse[]>;
};
