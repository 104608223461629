import { CompletionCardsResponse, DashboardOverviewPayload } from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type OverviewCompletionCards = CompletionCardsResponse['data'];

export interface CompletionCardsState {
  readonly completionCards_loading: boolean;
  readonly completionCards_data: OverviewCompletionCards;
  readonly completionCards_error: string;
}

export const FETCH_CARD_COMPLETION_REQUEST = '@@dashboard/overview/FETCH_CARD_COMPLETION_REQUEST';
export const FETCH_CARD_COMPLETION_SUCCESS = '@@dashboard/overview/FETCH_CARD_COMPLETION_SUCCESS';
export const FETCH_CARD_COMPLETION_FAILURE = '@@dashboard/overview/FETCH_CARD_COMPLETION_FAILURE';

export type FetchCompletionCardsPayload = DashboardOverviewPayload;
export type FetchCompletionCardsResponse = CompletionCardsResponse | ErrorResponse;
