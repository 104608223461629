import { call, put, takeLatest } from 'redux-saga/effects';
import { getToken } from '../../reducers/api';
import { apiURL } from '../../config/baseURL';
import * as types from './integration.actionTypes';
import * as actions from './integration.action';

function* retrieveIntegrationToken() {
  try {
    const authToken = yield getToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };
    const retrieveIntegrationToken = `${apiURL}/auth/access-token`;
    const request = () => fetch(retrieveIntegrationToken, options);
    const response = yield call(request);
    const responseData = yield response.json();

    if (response && response.status === 200) {
      yield put(actions.retrieveIntegrationToken.success({ token: responseData.data }));
    } else {
      yield put(actions.retrieveIntegrationToken.failure({ error: responseData.message }));
    }
  } catch (e) {
    yield put(actions.retrieveIntegrationToken.failure({ error: e.message }));
  }
}

function* createIntegrationToken(action: ReturnType<typeof actions.createIntegrationToken.request>) {
  try {
    const authToken = yield getToken();
    const options = {
      method: 'POST',
      headers: {
        Authorization: authToken
      },
      body: JSON.stringify({
        organizationID: action.payload.organizationId,
        userID: action.payload.userId
      })
    };
    const createIntegrationToken = `${apiURL}/auth/generate-token`;
    const request = () => fetch(createIntegrationToken, options);
    const response = yield call(request);
    const responseData = yield response.json();

    if (response && response.status === 200) {
      yield put(actions.createIntegrationToken.success({ token: responseData.data }));
    } else {
      yield put(actions.createIntegrationToken.failure({ error: responseData.message }));
    }
  } catch (e) {
    yield put(actions.createIntegrationToken.failure({ error: e.message }));
  }
}

export default function* integrationSaga() {
  yield takeLatest(types.RETRIEVE_INTEGRATION_TOKEN_REQUEST, retrieveIntegrationToken);
  yield takeLatest(types.CREATE_INTEGRATION_TOKEN_REQUEST, createIntegrationToken);
}
