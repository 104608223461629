import { RankByCompletionResponse, DashboardSitesPayload } from 'reducers/dashboard/fetch-types/sites-fetch-types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type RankByCompletion = RankByCompletionResponse['data'];

export interface RankByCompletionState {
  readonly reportList_loading: boolean;
  readonly reportList_data: RankByCompletion;
  readonly reportList_error: string;
  readonly reportList_dl_loading: boolean;
  readonly reportList_dl_url: string;
  readonly reportList_dl_error: string;
}
type DownloadPayload = {
  dl: 1;
};

export const FETCH_RANK_BY_COMPLETION_REQUEST = '@@dashboard/sites/FETCH_RANK_BY_COMPLETION_REQUEST';
export const FETCH_RANK_BY_COMPLETION_SUCCESS = '@@dashboard/sites/FETCH_RANK_BY_COMPLETION_SUCCESS';
export const FETCH_RANK_BY_COMPLETION_FAILURE = '@@dashboard/sites/FETCH_RANK_BY_COMPLETION_FAILURE';

export type FetchRankByCompletionPayload = DashboardSitesPayload;
export type FetchRankByCompletionResponse = RankByCompletionResponse | ErrorResponse;

export const DOWNLOAD_RANK_BY_COMPLETION_REQUEST = '@@dashboard/sites/DOWNLOAD_RANK_BY_COMPLETION_REQUEST';
export const DOWNLOAD_RANK_BY_COMPLETION_SUCCESS = '@@dashboard/sites/DOWNLOAD_RANK_BY_COMPLETION_SUCCESS';
export const DOWNLOAD_RANK_BY_COMPLETION_FAILURE = '@@dashboard/sites/DOWNLOAD_RANK_BY_COMPLETION_FAILURE';

export type DownloadRankByCompletionPayload = DashboardSitesPayload & DownloadPayload;
