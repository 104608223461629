import { all, call, put, takeLatest } from 'typed-redux-saga';
import * as actions from '../../reducers/site/siteActivityHistory/siteActivityHistory.action';
import { apiURL } from '../../config/baseURL';
import { getToken } from 'reducers/api';
import Monitoring from 'utils/monitoring/Monitoring';

// Static Query
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 20;
const DEFAULT_SORT_FIELDS = 'timestamp';
const DEFAULT_SORT_DIRECTIONS = 'desc';

export function* fetchSiteActivityHistorySaga({
	payload,
}: ReturnType<typeof actions.fetchSiteActivityHistory.request>) {
	try {
		const authToken = yield* call(getToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const { siteID } = payload;

		const query: Record<string, string> = {
			page: String(DEFAULT_PAGE),
			limit: String(DEFAULT_LIMIT),
			sortFields: DEFAULT_SORT_FIELDS,
			sortDirections: DEFAULT_SORT_DIRECTIONS,
		};

		const queryStr = new URLSearchParams(query);

		const fetchSiteActivityHistoryUrl = `${apiURL}/activities/sites/history/${siteID}?${queryStr}`;
		const response = yield* call(fetch, fetchSiteActivityHistoryUrl, options);
		const responseJson = yield response.json();

		if (response?.status !== 200) {
			yield put(actions.fetchSiteActivityHistory.failure({ error: responseJson.message }));
			return;
		}

		const siteActivityHistory = responseJson.data;
		yield put(actions.fetchSiteActivityHistory.success({ data: siteActivityHistory?.docs || [] }));
	} catch (e) {
		Monitoring.logEvent('fetchSiteActivityHistorySaga', e);
		yield put(actions.fetchSiteActivityHistory.failure({ error: 'Failed to fetch site activity history' }));
		return null;
	}
}

export default function* siteActivityHistorySaga() {
	yield all([takeLatest(actions.fetchSiteActivityHistory.request.toString(), fetchSiteActivityHistorySaga)]);
}
