export const base64toBlob = (base64Data: string, contentType: string) => {
	// eslint-disable-next-line no-param-reassign
	contentType = contentType || '';
	const sliceSize = 1024;
	const byteCharacters = atob(base64Data);
	const bytesLength = byteCharacters.length;
	const slicesCount = Math.ceil(bytesLength / sliceSize);
	const byteArrays = new Array(slicesCount);

	for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		const begin = sliceIndex * sliceSize;
		const end = Math.min(begin + sliceSize, bytesLength);

		const bytes = new Array(end - begin);
		for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, { type: contentType });
};

export const getStringValue = (actualValue: any, defaultValue = '') => actualValue || defaultValue;
export const getValue = (actualValue: any, defaultValue: any = '') => actualValue || defaultValue;
export const getPluralForm = (count = 0, singularForm = '', pluralForm = '') => {
	const pluralLabel = pluralForm ? pluralForm : `${singularForm}s`;

	return count > 0 ? pluralLabel : singularForm;
};

export const isWholeNumber = (value?: number) => {
	if (typeof value !== 'number') {
		return false;
	}

	const tempValue = Number(value);
	return tempValue === Math.floor(tempValue);
};

export const getDisplayDecimalNumber = (value?: number, digits = 2) => {
	if (typeof value !== 'number') {
		return 0;
	}

	return isWholeNumber(value) ? value.toFixed() : value.toFixed(digits);
};

export const getDisplayNumber = (value?: number, defaultValue = '-') => {
	if (typeof value !== 'number') {
		return defaultValue;
	}

	return value >= 1000 ? `${getDisplayDecimalNumber(value / 1000)}K` : value;
};

export const hideBodyElementScrollbar = () => {
	const body = document.querySelector('body');

	if (body) {
		body.style.overflow = 'hidden';
	}
};
