import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchTrendsAnalyticsAsync } from 'reducers/dashboard/trends/action';

// reducers
import * as types from 'reducers/dashboard/trends/actionType';

// utils
import * as APIService from 'constants/api/v1';
import { toQueryString } from 'utils/string';

// types
import { TrendsAnalyticsResponse } from 'constants/api/v1/types';

export function* fetchTrendsChartSaga(action: ReturnType<typeof fetchTrendsAnalyticsAsync.request>): Generator {
  try {
    const { questionType } = action.payload.params;
    const query = toQueryString({ ...action.payload.query });

    const { data, error } = (yield call(
      APIService.getTrendsAnalytics,
      questionType,
      query
    ) as unknown) as TrendsAnalyticsResponse;

    if (error) {
      throw new Error(error);
    }

    const aggregatedData: { [key: string]: any[] } = {};

    for (const [key, val] of Object.entries(data.adhoc)) {
      if (!aggregatedData[key]) {
        aggregatedData[key] = val;
        continue;
      }

      if (val.length > 0) {
        aggregatedData[key].push(...val);
      }
    }

    for (const [key, val] of Object.entries(data.scheduled)) {
      if (!aggregatedData[key]) {
        aggregatedData[key] = val;
        continue;
      }

      if (val.length > 0) {
        aggregatedData[key].push(...val);
      }
    }

    yield put(fetchTrendsAnalyticsAsync.success({ data: aggregatedData, isEmpty: data.isEmpty }));
  } catch (e) {
    yield put(fetchTrendsAnalyticsAsync.failure({ error: e }));
  }
}

export default function* trendsAnalyticsSaga() {
  yield takeLatest(types.FETCH_TRENDS_CHART_REQUEST, fetchTrendsChartSaga);
}
