export const SET_QUIZZES_PAGE_INDEX = '@lms/quizzes/SET_QUIZZES_PAGE_INDEX';
export const SET_QUIZZES_SORT = '@lms/quizzes/SET_QUIZZES_SORT_FIELD';
export const SET_QUIZZES_SEARCH_QUERY = '@lms/quizzes/SET_QUIZZES_SEARCH_QUERY';
export const SET_QUIZZES_FILTER_QUERY = '@lms/quizzes/SET_QUIZZES_FILTER_QUERY';
export const RESET_QUIZZES_FILTER_QUERY = '@lms/quizzes/RESET_QUIZZES_FILTER_QUERY';

export const FETCH_QUIZZES_REQUEST = '@lms/quizzes/FETCH_QUIZZES_REQUEST';
export const FETCH_QUIZZES_SUCCESS = '@lms/quizzes/FETCH_QUIZZES_SUCCESS';
export const FETCH_QUIZZES_FAILED = '@lms/quizzes/FETCH_QUIZZES_FAILED';

export const EDIT_QUIZZES_REQUEST = '@lms/quizzes/EDIT_QUIZZES_REQUEST';
export const EDIT_QUIZZES_SUCCESS = '@lms/quizzes/EDIT_QUIZZES_SUCCESS';
export const EDIT_QUIZZES_FAILED = '@lms/quizzes/EDIT_QUIZZES_FAILED';

export const EDIT_QUIZ_REQUEST = '@lms/quizzes/EDIT_QUIZ_REQUEST';
export const EDIT_QUIZ_SUCCESS = '@lms/quizzes/EDIT_QUIZ_SUCCESS';
export const EDIT_QUIZ_FAILED = '@lms/quizzes/EDIT_QUIZ_FAILED';

export const PATCH_QUIZ_REQUEST = '@lms/quizzes/PATCH_QUIZ_REQUEST';
export const PATCH_QUIZ_SUCCESS = '@lms/quizzes/PATCH_QUIZ_SUCCESS';
export const PATCH_QUIZ_FAILED = '@lms/quizzes/PATCH_QUIZ_FAILED';

export const DELETE_QUIZ_REQUEST = '@lms/quizzes/DELETE_QUIZ_REQUEST';
export const DELETE_QUIZ_SUCCESS = '@lms/quizzes/DELETE_QUIZ_SUCCESS';
export const DELETE_QUIZ_FAILED = '@lms/quizzes/DELETE_QUIZ_FAILED';
