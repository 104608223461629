export const SET_LOADING = '@promotion/SET_LOADING';
export const SET_SORT = '@promotion/SET_SORT';

export const FETCH_PROMOTIONS_REQUEST = '@promotion/FETCH_PROMOTIONS_REQUEST';
export const FETCH_PROMOTIONS_SUCCESS = '@promotion/FETCH_PROMOTIONS_LOADING';
export const FETCH_PROMOTIONS_FAILURE = '@promotion/FETCH_PROMOTIONS_FAILURE';

export const CREATE_PROMOTION_REQUEST = '@promotion/CREATE_PROMOTION_REQUEST';
export const CREATE_PROMOTION_SUCCESS = '@promotion/CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAILURE = '@promotion/CREATE_PROMOTION_FAILURE';

export const CREATE_PROMOTIONS_BULK_REQUEST = '@promotion/CREATE_PROMOTIONS_BULK_REQUEST';
export const CREATE_PROMOTIONS_BULK_SUCCESS = '@promotion/CREATE_PROMOTIONS_BULK_SUCCESS';
export const CREATE_PROMOTIONS_BULK_FAILURE = '@promotion/CREATE_PROMOTIONS_BULK_FAILURE';

export const FETCH_PROMOTION_DETAIL_REQUEST = '@promotion/FETCH_PROMOTION_DETAIL_REQUEST';
export const FETCH_PROMOTION_DETAIL_SUCCESS = '@promotion/FETCH_PROMOTION_DETAIL_SUCCESS';
export const FETCH_PROMOTION_DETAIL_FAILURE = '@promotion/FETCH_PROMOTION_DETAIL_FAILURE';

export const UPDATE_PROMOTION_REQUEST = '@promotion/UPDATE_PROMOTION_REQUEST';
export const UPDATE_PROMOTION_SUCCESS = '@promotion/UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_FAILURE = '@promotion/UPDATE_PROMOTION_FAILURE';

export const DELETE_PROMOTION_REQUEST = '@promotion/DELETE_PROMOTION_REQUEST';
export const DELETE_PROMOTION_SUCCESS = '@promotion/DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_FAILURE = '@promotion/DELETE_PROMOTION_FAILURE';
