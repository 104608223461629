import { OrganizationStatus, Organization } from 'nimbly-common';

export const getGeoPrecisionList = () => {
	return [
		{ value: 'high', label: 'Small (default)' },
		{ value: 'medium', label: 'Medium' },
		{ value: 'low', label: 'Large' },
		{ value: 'none', label: 'Disabled' },
	];
};

export const getPhotoSourceList = () => {
	return [
		{ value: false, label: 'Camera only' },
		{ value: true, label: 'Camera and Gallery' },
	];
};

export const getReportFormatList = () => {
	return [
		{ value: 'pdf', label: 'PDF (default)' },
		{ value: 'xlsx', label: 'Excel Spreadsheet' },
	];
};

export const getPeriodUnit = () => {
	return [
		{ value: 'week', label: 'Week' },
		{ value: 'month', label: 'Month' },
		// { value: 'quarter', label: 'Quarter' },
		{ value: 'year', label: 'Year' },
	];
};

export const getHighResOption = () => {
	return [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	];
};

export const isActiveOrganization = (status: OrganizationStatus = OrganizationStatus.LIVE) => {
	return ![OrganizationStatus.FROZEN, OrganizationStatus.CANCELLED, OrganizationStatus.CHURNED].includes(status);
};
