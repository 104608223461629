import React, { useState } from 'react';
import { DateRangePicker, FocusedInputShape, DateRangePickerShape } from 'react-dates';
import { Moment } from 'moment';
import { DateRangePickerContainer } from './CalendarPicker.styles';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

export interface CalendarDateRangePickerOwnProps {
	startDate: Moment | null;
	endDate: Moment | null;
	numberOfMonths?: number;
	hasError?: boolean;
	isDisabled?: boolean;
	hasRange?: boolean;
	displayFormat?: string;
	onClose?: () => void;
}

type CalendarDateRangePickerProps = CalendarDateRangePickerOwnProps &
	Pick<DateRangePickerShape, 'startDatePlaceholderText' | 'endDatePlaceholderText' | 'onDatesChange'>;

const CalendarDateRangePicker = ({
	displayFormat = 'DD MMMM YYYY',
	hasRange = false,
	...props
}: CalendarDateRangePickerProps) => {
	const [datepickerFocus, setDatePickerFocus] = useState<FocusedInputShape | null>(null);

	const render = () => {
		return (
			<DateRangePickerContainer hasError={props.hasError}>
				<DateRangePicker
					startDateId="date_range_start_date"
					endDateId="date_range_end_date"
					firstDayOfWeek={1}
					numberOfMonths={props.numberOfMonths}
					hideKeyboardShortcutsPanel
					startDate={props.startDate}
					endDate={props.endDate}
					onDatesChange={(dates) => {
						props.onDatesChange(dates);
					}}
					focusedInput={datepickerFocus}
					onFocusChange={(focusState) => setDatePickerFocus(focusState)}
					onClose={() => props.onClose?.()}
					disabled={props.isDisabled}
					noBorder
					weekDayFormat="ddd"
					horizontalMargin={0}
					startDatePlaceholderText={props.startDatePlaceholderText}
					endDatePlaceholderText={props.endDatePlaceholderText}
					displayFormat={() => displayFormat}
					isOutsideRange={() => hasRange}
					customArrowIcon="to"
					minimumNights={0}
					navPrev={
						<span className="dropdown-icon dropdown-icon__previous">
							<MdChevronLeft size={25} />
						</span>
					}
					navNext={
						<span className="dropdown-icon dropdown-icon__next">
							<MdChevronRight size={25} />
						</span>
					}
					renderDayContents={(date: any) => {
						return <div className="custom-day ">{date.format('D')}</div>;
					}}
				/>
			</DateRangePickerContainer>
		);
	};

	return render();
};

export default CalendarDateRangePicker;
