import {
  UserNPSResponse,
  GET_UserNPSPayload,
  UPSERT_UserNPSPayload,
  AllUserNPSResponse
} from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type UserNPS = UserNPSResponse['data'];

export interface UserNPSState {
  readonly userNPS_loading: boolean;
  readonly userNPS_data: UserNPS;
  readonly userNPS_error: string;
  readonly allUserNPS_loading: boolean;
  readonly allUserNPS_data: UserNPS[];
  readonly allUserNPS_error: string;
}

export const FETCH_ALL_USER_NPS_REQUEST = '@@dashboard/overview/FETCH_ALL_USER_NPS_REQUEST';
export const FETCH_ALL_USER_NPS_SUCCESS = '@@dashboard/overview/FETCH_ALL_USER_NPS_SUCCESS';
export const FETCH_ALL_USER_NPS_FAILURE = '@@dashboard/overview/FETCH_ALL_USER_NPS_FAILURE';

export type FetchAllUserNPSResponse = AllUserNPSResponse | ErrorResponse;

export const FETCH_USER_NPS_REQUEST = '@@dashboard/overview/FETCH_USER_NPS_REQUEST';
export const FETCH_USER_NPS_SUCCESS = '@@dashboard/overview/FETCH_USER_NPS_SUCCESS';
export const FETCH_USER_NPS_FAILURE = '@@dashboard/overview/FETCH_USER_NPS_FAILURE';

export type FetchUserNPSPayload = GET_UserNPSPayload;
export type FetchUserNPSResponse = UserNPSResponse | ErrorResponse;

export const UPSERT_USER_NPS_REQUEST = '@@dashboard/overview/UPSERT_USER_NPS_REQUEST';
export const UPSERT_USER_NPS_SUCCESS = '@@dashboard/overview/UPSERT_USER_NPS_SUCCESS';
export const UPSERT_USER_NPS_FAILURE = '@@dashboard/overview/UPSERT_USER_NPS_FAILURE';

export type UpsertUserNPSPayload = UPSERT_UserNPSPayload;
export type UpsertUserNPSResponse = UserNPSResponse | ErrorResponse;
