import styled from 'styled-components';

const MOBILE_SIDEBAR_PADDING = '30px';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 16px;
	width: 100%;
`;

export const Tab = styled.div<{ isActive: boolean }>`
	margin-left: -${MOBILE_SIDEBAR_PADDING};
	width: calc(100% + ${MOBILE_SIDEBAR_PADDING} + ${MOBILE_SIDEBAR_PADDING});
	padding-left: ${MOBILE_SIDEBAR_PADDING};
	padding-right: ${MOBILE_SIDEBAR_PADDING};

	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;

	background: ${(props) => (props.isActive ? '#E6FAED' : 'transparent')};

	transition: 150ms all ease-in-out;

	cursor: pointer;

	img {
		margin-right: 4px;
		filter: ${(props) => (props.isActive ? 'none' : 'grayscale(100%)')};
	}

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 27px;
		color: #45978e;
		padding: 8px;
	}
`;

export const CollapsibleTab = styled(Tab)`
	background: ${(props) => (props.isActive ? '#E6FAED' : 'transparent')};

	.dropdown {
		position: absolute;
		right: 20px;

		img {
			transform: rotate(90deg);
		}
	}
`;

export const CollapsibleSubTabs = styled.div<{ isCollapsed: boolean }>`
	display: ${(props) => (props.isCollapsed ? 'none' : 'flex')};
	flex-direction: column;
	justify-content: start;
	align-items: start;
	gap: 12px;

	transition: 100ms all ease-in-out;
`;

export const SubTab = styled(Tab)`
	margin-left: -${MOBILE_SIDEBAR_PADDING};
	width: calc(100% + ${MOBILE_SIDEBAR_PADDING} + ${MOBILE_SIDEBAR_PADDING});
	padding-left: ${MOBILE_SIDEBAR_PADDING};
	padding-right: ${MOBILE_SIDEBAR_PADDING};

	display: flex;
	flex-direction: row;
	align-items: center;

	transition: 150ms all ease-in-out;
	cursor: pointer;
	background-color: transparent;

	img {
		margin-left: 32px;
		margin-right: 11px;
		filter: ${(props) => (props.isActive ? 'none' : 'grayscale(100%)')};
	}

	span {
		font-weight: 400;
	}

	:hover {
		background-color: transparent;
	}
`;
