import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  LOGIN_AS_CLIENT_REQUEST,
  LOGIN_AS_CLIENT_SUCCESS,
  LOGIN_AS_CLIENT_FAILURE,
  LoginAsClientPayload,
  CLEAR_LOGIN_AS_CLIENT_STATE,
  VERIFY_AUTH_CODE_REQUEST,
  VERIFY_AUTH_CODE_SUCCESS,
  VERIFY_AUTH_CODE_FAILURE,
  VerifyAuthCodePayload,
  ClientTokenInfo,
  CLEAR_VERIFY_AUTH_CODE_STATE
} from './types';

export const loginAsClientAsync = createAsyncAction(
  LOGIN_AS_CLIENT_REQUEST,
  LOGIN_AS_CLIENT_SUCCESS,
  LOGIN_AS_CLIENT_FAILURE
)<LoginAsClientPayload, undefined, string>();

export const clearLoginAsClientState = createAction(CLEAR_LOGIN_AS_CLIENT_STATE)<undefined>();

export const verifyAuthCodeAsync = createAsyncAction(
  VERIFY_AUTH_CODE_REQUEST,
  VERIFY_AUTH_CODE_SUCCESS,
  VERIFY_AUTH_CODE_FAILURE
)<VerifyAuthCodePayload, ClientTokenInfo, string>();

export const clearVerifyAuthCodeState = createAction(CLEAR_VERIFY_AUTH_CODE_STATE)<undefined>();
