import styled, { css } from 'styled-components/macro';

interface ContainerProps {
	isCollapsed: boolean;
	isLocked: boolean;
}

interface MenuItemProps {
	isActive?: boolean;
}

interface CollapsibleItemProps {
	isActive: boolean;
	isCollapsed: boolean;
	itemCount: number;
}

const lockedContainer = css<ContainerProps>`
	position: relative;
`;

const floatingContainer = css<ContainerProps>`
	position: absolute;
	left: ${(props) => (props.isCollapsed ? '90px' : '-70px')};
`;

const Container = styled.div<ContainerProps>`
	${(props) => (props.isLocked ? lockedContainer : floatingContainer)};
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 160px;
	background-color: #e6faed;
	transition: all 300ms;
	z-index: 5;

	border-right: 2px solid rgb(0, 0, 0, 0.1);

	@media (max-width: 991px) {
		display: none;
	}
`;

const Header = styled.div`
	height: 75px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	padding: 0 12px;
	border-bottom: 1px solid #fff;
	text-overflow: ellipsis;

	span {
		color: #574fcf;
		font-size: 14px;
	}
	.display-name {
		margin-top: 4px;
		font-size: 16px;
		font-weight: 600;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
`;

const MenuItem = styled.a<MenuItemProps>`
	text-decoration: none;
	box-sizing: border-box;
	background: none;
	border: none;

	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;

	cursor: pointer;
	width: 100%;
	min-height: 44px;
	padding-left: 16px;

	transition: filter 200ms;
	filter: ${(props) => (props.isActive ? 'grayscale(0)' : 'grayscale(1) opacity(0.25)')};

	img {
		min-width: 17px;
		min-height: auto;
	}

	span {
		max-width: 92px;
		margin-left: 12px;
		font-size: 14px;
		color: #574fcf;
	}

	:hover {
		filter: grayscale(0) opacity(1);
		background-color: ${(props) => (props.isActive ? 'transparent' : '#D4EFDE')};
	}
`;

const CollapsibleMenuItem = styled.div<CollapsibleItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 40px;
    padding-left: 16px;

    transition: all 200ms;
    filter: ${(props) => (props.isActive ? 'grayscale(0)' : 'grayscale(1) opacity(0.25)')};

    span {
      flex: 1;
      max-width: 72px;
      margin-left: 12px;
      margin-right: 12px;
      font-size: 14px;
      color: #574fcf;
    }
  }

  .chevron-container {
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 200ms;
    /* background-color: ${(props) => (props.isCollapsed || props.isActive ? '#d4efde' : '#e6faed')}; */

    img {
      transition: transform 200ms;
      transform: ${(props) => (props.isCollapsed ? 'rotate(180deg)' : 'rotate(0)')};
    }
  }

  .collapsible {
    display: flex;
    flex-direction: column;

    transition: height 400ms ease-out;
    height: ${(props) => (props.isCollapsed ? `${props.itemCount * 40 + 8}px` : '0px')};
    overflow: hidden;

    ${MenuItem} {
      min-height: 40px;
      span {
        margin-left: 0;
        max-width: 108px;
        font-size: 14px;
      }
    }

    & ${MenuItem}:first-child {
      margin-top: 0;
    }
  }

  :hover {
    .header {
      filter: grayscale(0) opacity(1);
      background-color: #d4efde;
    }

    .chevron-container {
     // background-color: #c3e2ce;
    }
  }
`;

const MenuContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 15px;
	overflow: hidden;

	border-top: 1px solid #a0a4a8;
`;

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	width: 100%;
	padding: 15px 16px 16px 0;
`;

const LockButton = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 4px;
	background-color: #fff;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export { Container, Header, MenuContainer, MenuItem, CollapsibleMenuItem, Footer, LockButton };
