import { call, put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_REQUEST_DEMO_LIST_REQUEST,
  FetchRequestDemoListResponse,
  EXPORT_REQUEST_DEMO_LIST_REQUEST,
  ExportRequestDemoListResponse
} from 'reducers/superadmin/reqForDemo/requestDemoList/types';

import {
  fetchRequestForDemoListAsync,
  exportRequestForDemoListAsync
} from 'reducers/superadmin/reqForDemo/requestDemoList/action';

import { fetchRequestDemoList, exportRequestDemoList } from './helpers/reqForDemo';

export function* fetchRequestDemoListSaga(action: ReturnType<typeof fetchRequestForDemoListAsync.request>): Generator {
  try {
    const fetchRes: FetchRequestDemoListResponse = (yield call(fetchRequestDemoList, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(fetchRequestForDemoListAsync.success(fetchRes.data));
    } else {
      yield put(fetchRequestForDemoListAsync.failure('REQ FOR DEMO - FETCH LIST FAILED'));
    }
  } catch (e) {
    yield put(fetchRequestForDemoListAsync.failure('REQ FOR DEMO - FETCH LIST FAILED'));
  }
}

export function* exportRequestDemoListSaga(
  action: ReturnType<typeof exportRequestForDemoListAsync.request>
): Generator {
  try {
    const fetchRes: ExportRequestDemoListResponse = (yield call(exportRequestDemoList, action.payload)) as any;

    if (fetchRes.message === 'SUCCESS') {
      yield put(exportRequestForDemoListAsync.success(fetchRes.url));
    } else {
      yield put(exportRequestForDemoListAsync.failure('REQ FOR DEMO - EXPORT LIST FAILED'));
    }
  } catch (e) {
    yield put(exportRequestForDemoListAsync.failure('REQ FOR DEMO - EXPORT LIST FAILED'));
  }
}

export default function* superadminReqForDemoSagaWatcher() {
  yield takeLatest(FETCH_REQUEST_DEMO_LIST_REQUEST, fetchRequestDemoListSaga);
  yield takeLatest(EXPORT_REQUEST_DEMO_LIST_REQUEST, exportRequestDemoListSaga);
}
