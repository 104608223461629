/**
 *
 *      Retrieve blog data from wordpress.
 *
 *      @param max number of posts retrieved from the API.
 *
 */
function fetchBlogData(max: number) {
	return fetch('https://hellonimbly.com/wp-json/wp/v2/posts', {
		method: 'GET',
	})
		.then((response) => {
			const contentType = response.headers.get('content-type');
			if (contentType?.includes('application/json')) {
				return response.json();
			}

			return {
				msg: 'err',
				data: 'Typedata error',
			};
		})

		.then((json) => {
			return {
				msg: 'ok',
				data: json.splice(0, max),
			};
		})

		.catch((error) => {
			return {
				msg: 'err',
				data: error,
			};
		});
}

export default fetchBlogData;
