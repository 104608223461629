import { FlagsOverviewResponse, DashboardSitesPayload } from 'reducers/dashboard/fetch-types/sites-fetch-types';
import { ErrorResponse, PeriodPayload } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type SitesFlagsOverview = FlagsOverviewResponse['data'];

export interface SitesFlagsOverviewState {
  readonly flagsOverview_loading: boolean;
  readonly flagsOverview_data: SitesFlagsOverview;
  readonly flagsOverview_error: string;
}

export const FETCH_SITES_FLAGS_OVERVIEW_REQUEST = '@@dashboard/sites/FETCH_FLAGS_OVERVIEW_REQUEST';
export const FETCH_SITES_FLAGS_OVERVIEW_SUCCESS = '@@dashboard/sites/FETCH_FLAGS_OVERVIEW_SUCCESS';
export const FETCH_SITES_FLAGS_OVERVIEW_FAILURE = '@@dashboard/sites/FETCH_FLAGS_OVERVIEW_FAILURE';

export type FetchFlagsOverviewPayload = DashboardSitesPayload;

export type FetchFlagsOverviewResponse = FlagsOverviewResponse | ErrorResponse;

export type FetchFlagsOverviewQuery = Pick<
  FetchFlagsOverviewPayload,
  'sites' | 'departments' | 'isDummy' | 'periodType'
> &
  Pick<PeriodPayload, 'startDate' | 'endDate'>;
