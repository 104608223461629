import { FetchAllUserNPSResponse } from 'reducers/dashboard/overview/userNPS/types';
import { apiURL } from '../../../../config/baseURL';
import API from 'helpers/api';

export const fetchAllUserNPS = async (): Promise<FetchAllUserNPSResponse> => {
  try {
    const token = await API.getFirebaseToken();
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    };
    const url = `${apiURL}/users/userNPS`;

    const res = await fetch(url, options);

    // THROW IF RESPONSE NOT OK
    if (res.status !== 200) {
      throw new Error('');
    }

    const allUserNPSResult: FetchAllUserNPSResponse = await res.json();
    return allUserNPSResult;
  } catch {
    const errorResult: FetchAllUserNPSResponse = {
      message: 'FAILED'
    };
    return errorResult;
  }
};
