import React, { useCallback } from 'react';
import { MenuItem } from '../styles/MainSidebar.styles';
import { useLocation, useHistory } from 'react-router';
import { usePopperTooltip } from 'react-popper-tooltip';

function MainMenuItem(props: OwnProps) {
	const { icon, label, path, id } = props;

	const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
		{
			trigger: 'hover',
			placement: 'right-start',
			followCursor: true,
			offset: [-55, 0],
		},
		{
			strategy: 'absolute',
		},
	);

	const location = useLocation();
	const history = useHistory();
	const mainPath = path.split('/')[1];
	const match = props.match ?? location.pathname.split('/')[1] === mainPath;

	const handleNavigation = useCallback((e) => {
		e.preventDefault();
		history.push({ pathname: path });
	}, []);

	return React.useMemo(
		() => (
			<>
				<MenuItem
					ref={setTriggerRef}
					isActive={match}
					href={path}
					onClick={(e) => {
						if (props.isDisabled) {
							e.preventDefault();
							e.stopPropagation();
							return;
						}
						handleNavigation(e);
					}}
					id={id}
					isDisabled={props.isDisabled}
				>
					<img src={icon} alt={`${mainPath}-icon`} />
					{label && <span>{label}</span>}
				</MenuItem>
				{visible && props.isDisabled ? (
					<div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
						Feature unavailable. Please contact our support team for further information.
					</div>
				) : null}
			</>
		),
		[match, label, visible, setTooltipRef, setTriggerRef, getTooltipProps],
	);
}

export default MainMenuItem;

interface OwnProps {
	icon: string;
	label?: string;
	path: string;
	id?: string;
	isDisabled?: boolean;
	match?: boolean;
}
