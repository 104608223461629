import * as Common from 'nimbly-common';

// utils
import { apiURL } from 'config/baseURL';
import { getToken } from './index';
import Monitoring from '../../../utils/monitoring/Monitoring';

// types
import { MapStatisticOverviewResponse, TrendsAnalyticsResponse, MapStatisticOverviewQuery } from './statistics.types';

export const getMapStatisticOverview = async (query: MapStatisticOverviewQuery) => {
	const token = await getToken();
	const options = {
		method: 'POST',
		headers: {
			authorization: token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(query),
	};
	const urlStatistic = `${apiURL}/statistics/sites/flag-overview-rank`;

	try {
		const res = await fetch(urlStatistic, options);

		if (res.status !== 200) {
			throw new Error('Failed to fetch');
		}

		const result = (await res.json()) as MapStatisticOverviewResponse;

		return { data: result.data, error: result.error, message: result.message };
	} catch (e) {
		Monitoring.logEvent('getMapStatisticOverview', e);
		return { data: null, error: true, message: 'FAILED' };
	}
};

export const getTrendsAnalytics = async (questionType: Common.enums.QuestionTypes, query: string) => {
	const token = await getToken();
	const options = {
		method: 'GET',
		headers: {
			authorization: token,
		},
	};

	const url = `${apiURL}/statistics/trends/${questionType}?${query}`;

	try {
		const response = await fetch(url, options);

		if (response.status !== 200) {
			throw new Error('Failed to fetch');
		}

		const result = (await response.json()) as TrendsAnalyticsResponse;
		return { data: result.data, error: result.error, message: result.message };
	} catch (e) {
		Monitoring.logEvent('getTrendsAnalytics', e);
		return { data: null, error: e, message: 'FAILED' };
	}
};
