import { UnsubscribeNotificationSettings } from 'services/settings/settingsNotification/settingsNotification.service';

export function generateChangedSettings(
	unsubscribeSettings: UnsubscribeNotificationSettings[] | null,
	newSettings: UnsubscribeNotificationSettings[],
) {
	const existingSettingsMap = new Map(unsubscribeSettings?.map((setting) => [setting.userID, setting]));

	const newSettingsMap = new Map(newSettings.map((setting) => [setting.userID, setting]));

	const changedSettings: UnsubscribeNotificationSettings[] = [];
	// Detect added or changed settings
	newSettings.forEach((newSetting) => {
		const existing = existingSettingsMap.get(newSetting.userID);

		const emaiUnchanged = existing?.hasUnsubscribedEmail === newSetting.hasUnsubscribedEmail;
		const whatsappUnchanged = existing?.hasUnsubscribedWhatsApp === newSetting.hasUnsubscribedWhatsApp;

		if (emaiUnchanged && whatsappUnchanged) return;

		changedSettings.push(newSetting);
	});

	// Detect removed settings
	existingSettingsMap.forEach((existingSetting) => {
		if (newSettingsMap.has(existingSetting.userID)) return;

		changedSettings.push({
			...existingSetting,
			hasUnsubscribedEmail: false,
			hasUnsubscribedWhatsApp: false,
		});
	});
	return changedSettings;
}
