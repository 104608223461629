/* eslint-disable */
import { apiURL } from 'config/baseURL';
import { FindSKUByQueryResponse } from '@nimbly-technologies/nimbly-common';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/skuManagement/skus.action';
import * as types from 'reducers/skuManagement/skus.actionTypes';
import { SkusState } from 'reducers/skuManagement/skus.reducer';
import { call, put, select } from 'typed-redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/rootReducers';
import { ALL_SKU_LIST } from 'reducers/skuManagement/type.d';

export function* fetchSkus() {
	try {
		const authToken = yield* call(getToken);
		const skuState: SkusState = yield* select((state: RootState) => state.sku);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const convertFiltersToArray = (type: string, filters: any[]) =>
			filters.reduce((prev: Array<string[]>, filter) => [...prev, [type, filter.value]], []);

		const filterQuery = [
			...convertFiltersToArray('categories', skuState.filters.categories),
			...convertFiltersToArray('siteIDs', skuState.filters.sites),
			...convertFiltersToArray('uoms', skuState.filters.uoms),
		];

		const query: Record<string, string> = {
			page: String(skuState.pageIndex),
			limit: String(skuState.limit),
			sortFields: skuState.sortBy,
			sortDirections: skuState.sortDirection,
			search: skuState.searchQuery,
			withDisabled: 'false',
		};

		const queryStr = new URLSearchParams([...Object.entries(query), ...filterQuery]).toString();
		const fetchSkusURL = `${apiURL}/skus/search?${queryStr}`;

		const response = yield* call(fetch, fetchSkusURL, options);

		if (response?.status === 200) {
			const responseJson = yield response.json();

			const responseData: FindSKUByQueryResponse = responseJson.data;

			yield put(
				actions.fetchSkusAync.success({
					skus: responseData.docs,
					totalSkus: responseData.totalDocs || 0,
					lastUpdatedAt: responseData.lastUpdatedAt,
				}),
			);
			return responseData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchSkusAync.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchSkusAync.failure({ error: 'Failed to Fetch Skus' }));
		return null;
	}
}

export function* fetchAllSkus() {
	try {
		const authToken = yield* call(getToken);
		const skuState: SkusState = yield* select((state: RootState) => state.sku);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchSkusURL = `${apiURL}/skus/?props=skuID&props=name&withDisabled=false`;

		const response = yield* call(fetch, fetchSkusURL, options);

		if (response?.status === 200) {
			const responseJson = yield response.json();

			const responseData: ALL_SKU_LIST[] = responseJson.data;

			yield put(
				actions.fetchAllSkusAsync.success({
					skusAll: responseData,
				}),
			);
			return responseData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchAllSkusAsync.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchAllSkusAsync.failure({ error: 'Failed to Fetch Skus' }));
		return null;
	}
}

export default function* skusSaga() {
	yield takeLatest(types.FETCH_SKUS_REQUEST, fetchSkus);
	yield takeLatest(types.FETCH_ALL_SKUS_REQUEST, fetchAllSkus);
	yield takeLatest(types.SET_SKU_PAGE_INDEX, fetchSkus);
	yield takeLatest(types.SET_SKU_FILTER_QUERY, fetchSkus);
	yield takeLatest(types.SET_SKU_SEARCH_QUERY, fetchSkus);
	yield takeLatest(types.SET_SKU_SORT, fetchSkus);
}
