export enum IssueFlagType {
	RED = 'red',
	YELLOW = 'yellow',
}

export enum IssuePriorityType {
	LOW = 'low',
	MEDIUM = 'medium',
	HIGH = 'high',
}

export enum IssueStatusType {
	OPEN = 'open',
	RESOLVED = 'resolved',
	ALL = 'all',
	EXPIRED = 'expired',
	BLOCKED = 'blocked',
	IN_REVIEW = 'in-review',
	IN_PROGRESS = 'in-progress',
}

export enum IssueSecondaryStatusType {
	ON_TIME = 'on-time',
	BEHIND_TIME = 'behind-time',
	OVERDUE = 'overdue',
	DEFAULT = 'default',
	PENDING = 'pending',
}

export enum ReportStatusType {
	COMPLETED = 'completed',
	PENDING = 'pending',
	MISSED = 'missed',
}
