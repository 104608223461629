import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import { statistic } from 'nimbly-common';

import * as actions from './action';

export type CardFlagAction = ActionType<typeof actions>;

export interface CardFlagState {
	isLoading: boolean;
	isLoaded: boolean;
	data: null | {
		scheduled: statistic.QuestionFlagOverview;
		adhoc: statistic.QuestionFlagOverview;
		issue: statistic.QuestionFlagOverview;
	};
	error: string | null;
}

const initialState: CardFlagState = {
	isLoading: false,
	isLoaded: false,
	data: null,
	error: null,
};

export const cardFlagReducer = createReducer<CardFlagState, CardFlagAction>(initialState)
	.handleAction(actions.fetchCardFlagAsync.request, (state) =>
		update(state, {
			$merge: {
				isLoading: true,
				isLoaded: false,
				data: null,
				error: null,
			},
		}),
	)
	.handleAction(actions.fetchCardFlagAsync.success, (state, action) =>
		update(state, {
			$merge: {
				isLoaded: true,
				isLoading: false,
				error: null,
				data: action.payload,
			},
		}),
	)
	.handleAction(actions.fetchCardFlagAsync.failure, (state, action) =>
		update(state, {
			$merge: {
				isLoaded: false,
				isLoading: false,
				error: action.payload,
				data: null,
			},
		}),
	)
	.handleAction(actions.fetchCardFlagV1Async.request, (state) =>
		update(state, {
			$merge: {
				isLoading: true,
				isLoaded: false,
				data: null,
				error: null,
			},
		}),
	)
	.handleAction(actions.fetchCardFlagV1Async.success, (state, action) =>
		update(state, {
			$merge: {
				isLoaded: true,
				isLoading: false,
				error: null,
				data: action.payload,
			},
		}),
	)
	.handleAction(actions.fetchCardFlagV1Async.failure, (state, action) =>
		update(state, {
			$merge: {
				isLoaded: false,
				isLoading: false,
				error: action.payload,
				data: null,
			},
		}),
	);
