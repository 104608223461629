import { Questionnaire } from '@nimbly-technologies/nimbly-common';
import { apiURL } from 'config/baseURL';
import { getToken } from 'reducers/api';
import queryString from 'query-string';

export type QuestionnaireByPropsType = Array<{ [key: string]: any }>;
export type fetchQuestionnaireByPropsPayload = Array<keyof Questionnaire>;
async function fetchQuestionnaireByProps(
	props: fetchQuestionnaireByPropsPayload = [],
): Promise<QuestionnaireByPropsType> {
	let result: QuestionnaireByPropsType = [];
	try {
		const authToken = await getToken();
		const options = {
			method: 'GET',
			headers: { Authorization: authToken },
		};
		const url = `${apiURL}/questionnaires/questionnaireIndexes/minified${
			props.length ? `?props=${props.join('&props=')}` : ''
		}`;
		const response = await fetch(url, options);
		const { data } = await response.json();
		result = data;
	} catch (error) {
		throw new Error(error.message);
	}
	return result;
}

export async function fetchQuestionnairePaginate(query: any) {
	let res: any = [];
	try {
		const authToken = await getToken();
		const options = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		};
		const queryStr = queryString.stringify(query);
		const url = `${apiURL}/questionnaires/questionnaireIndexes/paginate?${queryStr}`;
		const response = await fetch(url, options);
		const { data } = await response.json();
		res = data;
	} catch (error) {
		throw new Error(error.message);
	}
	return res;
}

export default fetchQuestionnaireByProps;
