import React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonLogin, ButtonRequestAccess } from './Auth.styles';
import { AuthFlashMessage } from '../Auth/AuthFlashMessage';
import { AuthFormLogin } from '../Auth/AuthFormLogin';
import { AuthFormForgotPassword } from '../Auth/AuthFormForgotPassword';
import { AuthContainerProps } from './AuthContainer';
import FreezeAccount from './FreezeAccount/FreezeAccount';
import LoadingSpinner from 'components/global/LoadingSpinner/LoadingSpinner';
import AuthFederatedMessage from './AuthFederatedMessage';

export type AuthFormProps = Omit<AuthContainerProps, ''>;

export const AuthForm = (props: AuthFormProps) => {
	const { t } = useTranslation();

	const renderLoginForm = () => {
		return !props.modeForgot ? (
			<AuthFormLogin
				isAuthenticating={props.isAuthenticating}
				isFederated={props.isFederated}
				isEmailValid={props.isEmailValid}
				isCheckingEmail={props.isCheckingEmail}
				isRequestAccess={props.isRequestAccess}
				siteFlashMessage={props.siteFlashMessage}
				loginCredentials={props.loginCredentials}
				handleChangeEmail={props.handleChangeEmail}
				handleChangePassword={props.handleChangePassword}
			/>
		) : (
			<AuthFormForgotPassword
				siteFlashMessage={props.siteFlashMessage}
				forgotPasswordData={props.forgotPasswordData}
				handleChangeForgotPass={props.handleChangeForgotPass}
			/>
		);
	};

	const btnLoginDisabled = !props.isEmailValid || props.isRequestAccess || props.isLoading;
	const btnRequestAccessDisabled = props.isRequestSuccess || props.isLoading || props.isRequestFailed;

	const renderForgotPassword = () => {
		if (!props.isFederated && props.isEmailValid) {
			return (
				<span className="forgot-password" onClick={props.handleToggleForgotPass} id="link_forgot_return">
					{!props.modeForgot ? t('label.loginPage.forgotPassword') : t('label.loginPage.returnToLogin')}
				</span>
			);
		}
	};

	const renderBtnText = () => {
		let btnText = '';
		if (props.isLoading) {
			btnText = 'Loading...';
		}
		if (!props.modeForgot) {
			btnText = 'Log in';
		} else {
			btnText = 'Submit';
		}
		return btnText;
	};

	const renderBtnRequestText = () => {
		let btnReqText = '';
		if (props.isLoading) {
			btnReqText = 'Loading ...';
		} else {
			btnReqText = t('label.loginPage.requestAccess');
		}
		return btnReqText;
	};

	return (
		<div>
			<form onSubmit={props.handleSubmitForm}>
				<div className="input-login">{renderLoginForm()}</div>
				<AuthFlashMessage siteFlashMessage={props.siteFlashMessage} validationMessage={props.validationMessage} />
				{props.isCheckingEmail && <LoadingSpinner small />}
				<div className="container-forgot-pass fade-in">{renderForgotPassword()}</div>

				<ButtonLogin
					isFederated={props.isFederated as boolean}
					isRequestAccess={props.isRequestAccess}
					onClick={props.handleSubmitForm}
					className="fade-in"
					disabled={btnLoginDisabled}
					data-testid="button-submit"
					id={!props.modeForgot ? `btn_login` : 'btn_submit'}
				>
					{renderBtnText()}
				</ButtonLogin>
			</form>
			<ButtonRequestAccess
				onClick={props.handleRequestAccess}
				isRequestAccess={props.isRequestAccess}
				className="fade-in"
				disabled={btnRequestAccessDisabled}
				data-testid="button-submit-request-access"
				id="btn_submit_request_access"
			>
				{renderBtnRequestText()}
			</ButtonRequestAccess>
			<AuthFederatedMessage
				isRequestSuccess={props.isRequestSuccess}
				isRequestFailed={props.isRequestFailed}
				isMultipleRequest={props.isMultipleRequest}
				handleDismissRequestSuccess={props.handleDismissRequestSuccess}
			/>
			{/** Prepare if response login for frozen account is ready */}
			{/* <FreezeAccount /> */}
		</div>
	);
};

export default AuthForm;
