import React, { useEffect, useMemo } from 'react';
import { connect, InferableComponentEnhancerWithProps, ConnectedProps } from 'react-redux';
import { Dispatch, compose } from 'redux';
import { firebaseConnect } from 'react-redux-firebase';
import { useLocation, useHistory } from 'react-router';
import moment from 'moment';
import i18n from 'i18n';
import ReactGA from 'react-ga';

import { Container } from './styles/Sidebar.styles';
import MainSidebar from './MainSidebar';
import SubSidebar from './SubSidebar';

import { RootState } from 'store/rootReducers';
import { sidebarActions, SidebarLockedState } from 'reducers/sidebar';
import { FirebaseConnect } from 'types/app';
import { LangOptions, MainSidebarOptions, SUB_MENU_LIST } from './constants';

import * as siteDetailsAction from 'reducers/site/siteDetails/siteDetails.action';
import * as siteScheduleAction from 'reducers/site/siteSchedule/siteSchedule.action';
import 'moment/locale/pt';
import 'moment/locale/id';
import 'moment/locale/es';
import 'moment/locale/th';

function Sidebar(props: SidebarProps) {
	const {
		sidebar: { isCollapsed, lockedState, subMenuCollapsedStateList },
		setSidebarCollapse,
		setSidebarLock,
		setSidebarSubMenuCollapse,
	} = props;

	const { pathname } = useLocation();
	const history = useHistory();

	const shouldRenderCollapseButton = useMemo(() => {
		const mainPath = pathname.split('/')[1] as MainSidebarOptions;
		if (!SUB_MENU_LIST()[mainPath]) return false;

		return !!SUB_MENU_LIST()[mainPath].length;
	}, [pathname]);

	const isSuperadmin = useMemo(() => props.profile.role === 'superadmin', [props.profile]);

	useEffect(() => {
		if (lockedState === 'temp-unlocked' && shouldRenderCollapseButton) {
			setSidebarCollapse(true);
			setSidebarLock('locked');
		}
	}, [lockedState, shouldRenderCollapseButton]);

	const handleLogout = () => {
		// navigate to /loading
		history.push('/loading');
		localStorage.removeItem('enrollmentStatus');

		let hasChangesSiteDetails = props.hasChangesSiteDetails;
		let hasChangesSiteSchedule = props.hasChangesSiteSchedule;
		if (hasChangesSiteDetails || hasChangesSiteSchedule) {
			if (hasChangesSiteDetails) {
				props.setSiteDetailsChanges(false);
			}

			if (hasChangesSiteSchedule) {
				props.setSiteScheduleChanges(false);
			}

			// eslint-disable-next-line no-restricted-globals
			if (!confirm('You have made changes. Leaving this page will not save those changes.')) {
				if (hasChangesSiteDetails) {
					props.setSiteDetailsChanges(true);
				}

				if (hasChangesSiteSchedule) {
					props.setSiteScheduleChanges(true);
				}

				return;
			}
		}

		// Push to placeholder loading page while we log out
		// Timeout is put so the state change in hasChanges have time to update first.
		// If not, double dialog will appear
		window.onbeforeunload = null;
		setTimeout(() => {
			props.firebase.logout().then(() => {
				// Reload the page to empty the redux state and start from 0
				window.location.reload();
			});
		}, 250);
	};

	const handleLangChange = (lang: LangOptions) => {
		// TODO: change language directly firebase DB
		const userRef = props.firebase.database().ref(`user/${props.auth.uid}`);
		props.firebase.update(userRef, {
			language: lang,
		});
		i18n.changeLanguage(lang);
		moment.locale(lang);

		ReactGA.event({
			category: 'Navigation',
			action: 'Language - Changed to: ' + lang,
		});
	};

	const handleChangeCollapsedState = (state: boolean) => {
		setSidebarCollapse(state);
	};
	const handleChangeLockedState = (state: SidebarLockedState) => {
		setSidebarLock(state);
	};
	const handleSidebarSubMenuCollapse = (subMenuID: string, isCollapsed: boolean) => {
		setSidebarSubMenuCollapse(subMenuID, isCollapsed);
	};

	return (
		<Container customStyle={props.customStyle}>
			<MainSidebar
				isSubSidebarCollapsed={isCollapsed}
				onChangeCollapsedState={handleChangeCollapsedState}
				subSidebarLockedState={lockedState}
				onChangeLockedState={handleChangeLockedState}
				onLogout={handleLogout}
				onLangChange={handleLangChange}
				shouldRenderCollapseButton={shouldRenderCollapseButton}
				isSuperadmin={isSuperadmin}
			/>
			<SubSidebar
				isCollapsed={isCollapsed}
				lockedState={lockedState}
				onChangeLockedState={handleChangeLockedState}
				loggedInAs={props.profile.displayName}
				onChangeSubMenuCollapsed={handleSidebarSubMenuCollapse}
				subMenuCollapsedStateList={subMenuCollapsedStateList}
			/>
		</Container>
	);
}

const mapStateToProps = (state: RootState) => ({
	auth: state.firebase.auth,
	profile: state.firebase.profile,
	sidebar: state.sidebar,
	hasChangesSiteDetails: state.site.siteDetails.hasChanges,
	hasChangesSiteSchedule: state.site.siteSchedule.hasChanges,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
	setSidebarCollapse: (bool: boolean) => dispatch(sidebarActions.setSidebarCollapse(bool)),
	setSidebarLock: (state: SidebarLockedState) => dispatch(sidebarActions.setSidebarLock(state)),
	setSidebarSubMenuCollapse: (subMenuID: string, isCollapsed: boolean) =>
		dispatch(sidebarActions.setSidebarSubMenuCollapse(subMenuID, isCollapsed)),
	setSiteDetailsChanges: (bool: boolean) => dispatch(siteDetailsAction.setHasChanges(bool)),
	setSiteScheduleChanges: (bool: boolean) => dispatch(siteScheduleAction.setHasChanges(bool)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
	customStyle?: string;
}
type PropsFromRedux = ConnectedProps<typeof connector>;
type EnhancedProps = PropsFromRedux & FirebaseConnect;

type SidebarProps = OwnProps & EnhancedProps;

const enhance: InferableComponentEnhancerWithProps<EnhancedProps, OwnProps> = compose(firebaseConnect(), connector);

export default enhance(Sidebar);
