import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import { Single_SKUList } from '@nimbly-technologies/nimbly-common';
import * as actions from './skus.action';
import * as types from './skus.actionTypes';
import { SKU_FETCH_STATE, SortDirection, ALL_SKU_LIST } from './type.d';
import { SelectedFilters } from 'components/skuManagement/components/SkuFilter';

export type SkuAction = ActionType<typeof actions>;

export interface SkusState {
	error: string;
	fetchState: SKU_FETCH_STATE;
	filters: SelectedFilters;
	lastUpdatedAt: number;
	limit: number;
	pageIndex: number;
	searchQuery: string;
	skus: Single_SKUList[];
	sortBy: string;
	sortDirection: SortDirection;
	totalSkus: number;
	skusAll: ALL_SKU_LIST[];
}

export const initialState: SkusState = {
	error: '',
	fetchState: SKU_FETCH_STATE.SUCCESS,
	filters: { categories: [], sites: [], uoms: [] },
	lastUpdatedAt: 0,
	limit: 15,
	pageIndex: 1,
	searchQuery: '',
	skus: [],
	sortBy: 'name',
	sortDirection: 'asc',
	totalSkus: 0,
	skusAll: [],
};

export const skuReducer = createReducer<SkusState, SkuAction>(initialState)
	.handleType(types.SET_SKU_FILTER_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: SKU_FETCH_STATE.LOADING },
			pageIndex: { $set: initialState.pageIndex },
			filters: { $set: action.payload.filters },
		}),
	)
	.handleType(types.SET_SKU_PAGE_INDEX, (state, action) =>
		update(state, {
			fetchState: { $set: SKU_FETCH_STATE.LOADING },
			pageIndex: { $set: action.payload.pageIndex },
		}),
	)
	.handleType(types.SET_SKU_SEARCH_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: SKU_FETCH_STATE.LOADING },
			pageIndex: { $set: initialState.pageIndex },
			searchQuery: { $set: action.payload.searchQuery },
		}),
	)
	.handleType(types.SET_SKU_SORT, (state, action) =>
		update(state, {
			fetchState: { $set: SKU_FETCH_STATE.LOADING },
			pageIndex: { $set: initialState.pageIndex },
			sortBy: { $set: action.payload.sortBy },
			sortDirection: { $set: action.payload.sortDirection },
		}),
	)
	.handleType(types.FETCH_SKUS_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: SKU_FETCH_STATE.LOADING },
			filters: { $set: initialState.filters },
			pageIndex: { $set: initialState.pageIndex },
			searchQuery: { $set: initialState.searchQuery },
			sortBy: { $set: initialState.sortBy },
			sortDirection: { $set: initialState.sortDirection },
		}),
	)
	.handleType(types.FETCH_SKUS_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: SKU_FETCH_STATE.SUCCESS },
			lastUpdatedAt: { $set: action.payload.lastUpdatedAt },
			skus: { $set: action.payload.skus },
			totalSkus: { $set: action.payload.totalSkus },
		}),
	)
	.handleType(types.FETCH_SKUS_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: SKU_FETCH_STATE.ERROR },
			skus: { $set: initialState.skus },
		}),
	)
	.handleType(types.FETCH_ALL_SKUS_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: SKU_FETCH_STATE.LOADING },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_ALL_SKUS_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: SKU_FETCH_STATE.SUCCESS },
			skusAll: { $set: action.payload.skusAll },
		}),
	)
	.handleType(types.FETCH_ALL_SKUS_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: SKU_FETCH_STATE.ERROR },
			skusAll: { $set: initialState.skusAll },
		}),
	);
