import { LiveOrganizationResponse } from 'reducers/dashboard/fetch-types/overview-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type LiveOrganization = LiveOrganizationResponse['data'];

export interface LiveOrganizationState {
  readonly liveOrganization_loading: boolean;
  readonly liveOrganization_data: LiveOrganization;
  readonly liveOrganization_error: string;
}

export const FETCH_LIVE_ORGANIZATION_REQUEST = '@@dashboard/overview/FETCH_LIVE_ORGANIZATION_REQUEST';
export const FETCH_LIVE_ORGANIZATION_SUCCESS = '@@dashboard/overview/FETCH_LIVE_ORGANIZATION_SUCCESS';
export const FETCH_LIVE_ORGANIZATION_FAILURE = '@@dashboard/overview/FETCH_LIVE_ORGANIZATION_FAILURE';

export type FetchLiveOrganizationResponse = LiveOrganizationResponse | ErrorResponse;
