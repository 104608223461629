export const SET_GROUP_BY = '@dashboardRevamp/issueInsights/SET_GROUP_BY';
export const SET_LIMIT = '@dashboardRevamp/issueInsights/SET_LIMIT';

export const SET_FILTERS = '@dashboardRevamp/issueInsights/SET_FILTERS';
export const SET_FILTER_QUERY = '@dashboardRevamp/issueInsights/SET_FILTER_QUERY';

export const SET_ISSUE_INSIGHT_CHART_DETAIL_FILTERS =
	'@dashboardRevamp/issueInsights/SET_ISSUE_INSIGHT_CHART_DETAIL_FILTERS';
export const RESET_ISSUE_INSIGHT_CHART_DETAIL_FILTERS =
	'@dashboardRevamp/issueInsights/RESET_ISSUE_INSIGHT_CHART_DETAIL_FILTERS';

export const SET_ISSUE_STATUS = '@dashboardRevamp/issueInsights/SET_ISSUE_STATUS';

export const SET_PAGE = '@dashboardRevamp/issueInsights/SET_PAGE';

export const FETCH_ISSUE_INSIGHT_DETAIL_REQUEST = '@dashboardRevamp/issueInsights/FETCH_ISSUE_INSIGHT_DETAIL_REQUEST';
export const FETCH_ISSUE_INSIGHT_DETAIL_SUCCESS = '@dashboardRevamp/issueInsights/FETCH_ISSUE_INSIGHT_DETAIL_SUCCESS';
export const FETCH_ISSUE_INSIGHT_DETAIL_FAILURE = '@dashboardRevamp/issueInsights/FETCH_ISSUE_INSIGHT_DETAIL_FAILURE';

export const FETCH_ISSUE_INSIGHT_LIST_REQUEST = '@dashboardRevamp/issueInsights/FETCH_ISSUE_INSIGHT_LIST_REQUEST';
export const FETCH_ISSUE_INSIGHT_LIST_SUCCESS = '@dashboardRevamp/issueInsights/FETCH_ISSUE_INSIGHT_LIST_SUCCESS';
export const FETCH_ISSUE_INSIGHT_LIST_FAILURE = '@dashboardRevamp/issueInsights/FETCH_ISSUE_INSIGHT_LIST_FAILURE';

export const FETCH_ISSUE_CHART_REQUEST = '@dashboardRevamp/issueInsights/FETCH_ISSUE_CHART_REQUEST';
export const FETCH_ISSUE_CHART_SUCCESS = '@dashboardRevamp/issueInsights/FETCH_ISSUE_CHART_SUCCESS';
export const FETCH_ISSUE_CHART_FAILURE = '@dashboardRevamp/issueInsights/FETCH_ISSUE_CHART_FAILURE';

export const FETCH_ISSUE_CHART_DETAILS_REQUEST = '@dashboardRevamp/issueInsights/FETCH_ISSUE_CHART_DETAILS_REQUEST';
export const FETCH_ISSUE_CHART_DETAILS_SUCCESS = '@dashboardRevamp/issueInsights/FETCH_ISSUE_CHART_DETAILS_SUCCESS';
export const FETCH_ISSUE_CHART_DETAILS_FAILURE = '@dashboardRevamp/issueInsights/FETCH_ISSUE_CHART_DETAILS_FAILURE';

export const FETCH_ISSUE_TABLE_DATA_REQUEST = '@dashboardRevamp/issueInsights/FETCH_ISSUE_TABLE_DATA_REQUEST';
export const FETCH_ISSUE_TABLE_DATA_SUCCESS = '@dashboardRevamp/issueInsights/FETCH_ISSUE_TABLE_DATA_SUCCESS';
export const FETCH_ISSUE_TABLE_DATA_FAILURE = '@dashboardRevamp/issueInsights/FETCH_ISSUE_TABLE_DATA_FAILURE';

export const CLEAR_FILTERS = '@dashboardRevamp/issueInsights/CLEAR_FILTERS';
