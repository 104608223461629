import { DashboardIssuesPayload, FlagsOverviewResponse } from 'reducers/dashboard/fetch-types/issues-fetch.types';
import { ErrorResponse } from 'reducers/dashboard/fetch-types/dashboard-fetch.types';

export type IssuesFlagsOverview = FlagsOverviewResponse['data'];

export interface IssuesFlagsOverviewState {
  readonly flagsOverview_loading: boolean;
  readonly flagsOverview_data: IssuesFlagsOverview;
  readonly flagsOverview_error: string;
}

export const FETCH_ISSUES_FLAGS_OVERVIEW_REQUEST = '@@dashboard/issues/FETCH_FLAGS_OVERVIEW_REQUEST';
export const FETCH_ISSUES_FLAGS_OVERVIEW_SUCCESS = '@@dashboard/issues/FETCH_FLAGS_OVERVIEW_SUCCESS';
export const FETCH_ISSUES_FLAGS_OVERVIEW_FAILURE = '@@dashboard/issues/FETCH_FLAGS_OVERVIEW_FAILURE';

export type FetchFlagsOverviewPayload = DashboardIssuesPayload;
export type FetchFlagsOverviewResponse = FlagsOverviewResponse | ErrorResponse;
