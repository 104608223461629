const SHOW_MODAL = 'inventory/SHOW_MODAL';
const DISMISS_MODAL = 'inventory/DISMISS_MODAL';
const SHOW_BULK_MODAL = 'inventory/SHOW_BULK_MODAL';
const DISMISS_BULK_MODAL = 'inventory/DISMISS_BULK_MODAL';
const SET_SORT = 'inventory/SET_SORT';

export const showInventoryModal = (isNew = false, skuKey, skuToEdit) => {
  return { type: SHOW_MODAL, payload: { isNew, skuKey, editingSKU: skuToEdit } };
};

export const dismissInventoryModal = () => {
  return { type: DISMISS_MODAL };
};

export const showInventoryBulkModal = () => {
  return { type: SHOW_BULK_MODAL };
};

export const dismissInventoryBulkModal = () => {
  return { type: DISMISS_BULK_MODAL };
};

export const setSortInventory = (newType, newOrder = null) => {
  return { type: SET_SORT, newType, newOrder };
};

const initialState = {
  modalVisible: false,
  isNew: false,
  sortBy: 'idx',
  idx: 'asc',
  name: 'asc',
  number: 'asc',
  tags: 'asc',
  sites: 'asc',
  modalBulkShown: false,
  skuKey: '',
  editingSKU: null
};

export function inventoryReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalVisible: true,
        ...action.payload
      };
    case DISMISS_MODAL:
      return {
        modalVisible: false
      };
    case SHOW_BULK_MODAL:
      return {
        ...state,
        modalBulkShown: true
      };
    case DISMISS_BULK_MODAL:
      return {
        ...state,
        modalBulkShown: false
      };
    case SET_SORT: {
      if (state.sortBy !== action.newType) {
        return {
          ...state,
          sortBy: action.newType,
          idx: 'asc',
          name: 'asc',
          number: 'asc',
          Tags: 'asc',
          sites: 'asc'
        };
      }
      return {
        ...state,
        [action.newType]: action.newOrder
      };
    }
    default:
      return state;
  }
}
