import { ActionType, createReducer } from 'typesafe-actions';
import { QuizState, QuizFetchState } from './type.d';
import update from 'immutability-helper';
import * as actions from './quizzes.action';
import * as types from './quizzes.actionTypes';

export type QuizzesAction = ActionType<typeof actions>;

export const initialState: QuizState = {
	error: '',
	bulkError: [],
	fetchState: QuizFetchState.SUCCESS,
	quizzes: [],

	totalPages: 0,
	totalDocs: 0,

	page: 1,
	limit: 15,
	sortField: 'publishedDate',
	sortDirection: 'desc',
	search: '',

	filters: {
		userIDs: [],
		publishedDate: { startDate: null, endDate: null },
		lastModified: { startDate: null, endDate: null },
		state: [],
		associationStates: [],
	},
};

export const quizzesReducer = createReducer<QuizState, QuizzesAction>(initialState)
	.handleType(types.SET_QUIZZES_PAGE_INDEX, (state, action) =>
		update(state, {
			fetchState: { $set: QuizFetchState.LOADING },
			page: { $set: action.payload.page },
		}),
	)
	.handleType(types.SET_QUIZZES_SEARCH_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: QuizFetchState.LOADING },
			page: { $set: initialState.page },
			search: { $set: action.payload.searchQuery },
		}),
	)
	.handleType(types.SET_QUIZZES_SORT, (state, action) =>
		update(state, {
			fetchState: { $set: QuizFetchState.LOADING },
			page: { $set: 1 },
			sortField: { $set: action.payload.sortField },
			sortDirection: { $set: action.payload.sortDirection },
		}),
	)
	.handleType(types.SET_QUIZZES_FILTER_QUERY, (state, action) =>
		update(state, {
			fetchState: { $set: QuizFetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: { ...state.filters, ...action.payload.filters } },
		}),
	)
	.handleType(types.RESET_QUIZZES_FILTER_QUERY, (state) =>
		update(state, {
			fetchState: { $set: QuizFetchState.LOADING },
			page: { $set: initialState.page },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_QUIZZES_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: QuizFetchState.LOADING },
			quizzes: { $set: initialState.quizzes },
			page: { $set: initialState.page },
			sortField: { $set: initialState.sortField },
			sortDirection: { $set: initialState.sortDirection },
			search: { $set: initialState.search },
			filters: { $set: initialState.filters },
		}),
	)
	.handleType(types.FETCH_QUIZZES_SUCCESS, (state, action) =>
		update(state, {
			fetchState: { $set: QuizFetchState.SUCCESS },
			quizzes: { $set: action.payload.quizzes },
			totalDocs: { $set: action.payload.totalDocs || 0 },
			page: { $set: action.payload.page || 0 },
			totalPages: { $set: action.payload.totalPages || 0 },
		}),
	)
	.handleType(types.FETCH_QUIZZES_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: QuizFetchState.ERROR },
			quizzes: { $set: initialState.quizzes },
		}),
	)
	.handleType(types.EDIT_QUIZ_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: QuizFetchState.LOADING },
		}),
	)
	.handleType(types.EDIT_QUIZ_SUCCESS, (state) =>
		update(state, {
			fetchState: { $set: QuizFetchState.SUCCESS },
		}),
	)
	.handleType(types.EDIT_QUIZ_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: QuizFetchState.ERROR },
		}),
	)
	.handleType(types.PATCH_QUIZ_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: QuizFetchState.LOADING },
		}),
	)
	.handleType(types.PATCH_QUIZ_SUCCESS, (state) =>
		update(state, {
			fetchState: { $set: QuizFetchState.SUCCESS },
		}),
	)
	.handleType(types.PATCH_QUIZ_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: QuizFetchState.ERROR },
		}),
	)
	.handleType(types.DELETE_QUIZ_REQUEST, (state) =>
		update(state, {
			error: { $set: initialState.error },
			fetchState: { $set: QuizFetchState.LOADING },
		}),
	)
	.handleType(types.DELETE_QUIZ_SUCCESS, (state) =>
		update(state, {
			fetchState: { $set: QuizFetchState.SUCCESS },
		}),
	)
	.handleType(types.DELETE_QUIZ_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			fetchState: { $set: QuizFetchState.ERROR },
		}),
	);
