import { Quiz, QuizPaginateResponse, QuizState } from '@nimbly-technologies/nimbly-common';
import { SortDirection } from 'reducers/lms/userManagement/userList/type';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './quizzes.actionTypes';
import { QuizFilters, QuizzesSortFields } from './type.d';

export const setQuizzesPageIndex = (page: number) => action(types.SET_QUIZZES_PAGE_INDEX, { page });

export const setQuizzesSearchQuery = (searchQuery: string) => action(types.SET_QUIZZES_SEARCH_QUERY, { searchQuery });

export const setQuizzesSort = (sortField: QuizzesSortFields, sortDirection: SortDirection) =>
	action(types.SET_QUIZZES_SORT, { sortField, sortDirection });

export const setQuizzesFilters = (filters: Partial<QuizFilters>) => action(types.SET_QUIZZES_FILTER_QUERY, { filters });

export const resetQuizzesFilters = () => action(types.RESET_QUIZZES_FILTER_QUERY);

export const fetchQuizzes = createAsyncAction(
	types.FETCH_QUIZZES_REQUEST,
	types.FETCH_QUIZZES_SUCCESS,
	types.FETCH_QUIZZES_FAILED,
)<
	{ state?: QuizState },
	{
		quizzes: QuizPaginateResponse[];
		totalDocs?: number;
		page?: number;
		totalPages?: number;
	},
	{
		error: string;
	}
>();

export const editQuiz = createAsyncAction(types.EDIT_QUIZ_REQUEST, types.EDIT_QUIZ_SUCCESS, types.EDIT_QUIZ_FAILED)<
	{
		quizID: string;
		editedQuiz: Partial<Quiz>;
		successMessage?: string;
	},
	undefined,
	{
		error: string;
	}
>();

export const patchQuiz = createAsyncAction(types.PATCH_QUIZ_REQUEST, types.PATCH_QUIZ_SUCCESS, types.PATCH_QUIZ_FAILED)<
	{
		quizID: string;
		patchedQuiz: Partial<Quiz>;
		successMessage?: string;
	},
	undefined,
	{
		error: string;
	}
>();

export const deleteQuiz = createAsyncAction(
	types.DELETE_QUIZ_REQUEST,
	types.DELETE_QUIZ_SUCCESS,
	types.DELETE_QUIZ_FAILED,
)<
	{
		quizID: string;
		successMessage?: string;
	},
	undefined,
	{
		error: string;
	}
>();
