import { createAction } from 'typesafe-actions';
import * as types from './basicDashboard.actionTypes';

export const setSiteSummaryIsLoading = createAction(types.SET_SITE_SUMMARY_IS_LOADING, (isLoading: boolean) => ({
	isLoading,
}))();
export const setAssigneeSummaryIsLoading = createAction(
	types.SET_ASSIGNEE_SUMMARY_IS_LOADING,
	(isLoading: boolean) => ({
		isLoading,
	}),
)();
export const setDeptartmentSummaryIsLoading = createAction(
	types.SET_DEPARTMENT_SUMMARY_IS_LOADING,
	(isLoading: boolean) => ({
		isLoading,
	}),
)();
