export const SET_SHOW_MODAL = 'userManagement/SET_SHOW_MODAL';
export const SET_SUCCESS = 'userManagement/SET_SUCCESS';

export const GET_USER_GROUP_LIST_REQUEST = 'userManagement/GET_USER_GROUP_LIST_REQUEST';
export const GET_USER_GROUP_LIST_SUCCESS = 'userManagement/GET_USER_GROUP_LIST_LOADING';
export const GET_USER_GROUP_LIST_FAILURE = 'userManagement/GET_USER_GROUP_LIST_FAILURE';

export const SET_USER_GROUP_LIST_FILTER_QUERY = 'userManagement/SET_USER_GROUP_LIST_FILTER_QUERY';
export const SET_USER_GROUP_LIST_PAGE_INDEX = 'userManagement/SET_USER_GROUP_LIST_PAGE_INDEX';
export const SET_USER_GROUP_LIST_SEARCH_QUERY = 'userManagement/SET_USER_GROUP_LIST_SEARCH_QUERY';
export const SET_USER_GROUP_LIST_SORT = 'userManagement/SET_USER_GROUP_LIST_SORT';
export const SET_SELECTED_USER_GROUP_COUNT = 'userManagement/SET_SELECTED_USER_GROUP_COUNT';

export const RESET_USER_GROUP_FILTER = 'userManagement/RESET_USER_GROUP_FILTER';
