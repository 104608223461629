import { PaginationResult } from '@nimbly-technologies/nimbly-common';

export const getPaginatedResponseData = <Data>(
	loadMore: boolean | undefined,
	prevData: Data[] | null,
	res: PaginationResult<Data>,
) => {
	const docs: Data[] = loadMore ? [...(prevData ?? []), ...res.docs] : res.docs;
	const data: PaginationResult<Data> = {
		...res,
		docs,
	};

	return data;
};
