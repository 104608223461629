import { apiURL } from '../../config/baseURL';
import { SiteOffdayMongo } from '@nimbly-technologies/nimbly-common';
import { SiteOffDaysApiError } from './siteOffDays.errors';
import API from '../../helpers/api';

export const upsertSiteOffDays = async (siteID: string, payload: Partial<SiteOffdayMongo>): Promise<string> => {
	const url = `${apiURL}/sites/off-days/${siteID}`;
	const token = await API.getFirebaseToken();
	const response = await API.put(url, token, payload);

	const error = await SiteOffDaysApiError.getError(response);
	if (error) throw error;

	const json = await response.json();

	return json.data;
};

export const fetchSiteOffDays = async (siteID: string): Promise<SiteOffdayMongo> => {
	const url = `${apiURL}/sites/off-days/${siteID}`;
	const token = await API.getFirebaseToken();
	const res = await API.getJSON(url, token);
	return res?.data;
};
