import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import NoAccessImage from 'assets/img/no-access.svg';
import Layout from 'components/global/Layout/Layout';
import AdBlockBlocker from 'components/adblockblocker/AdBlockBlocker';
import { AdminPage, PageHeader, PageWrapper } from 'styles/General';
import NoAccessContent from './NoAccessContent';

const NoAccessPage = () => {
	return (
		<Layout>
			<AdBlockBlocker />
			<AdminPage>
				<PageHeader />
				<AdaptiveWrapper>
					<NoAccessContent />
				</AdaptiveWrapper>
			</AdminPage>
		</Layout>
	);
};

const AdaptiveWrapper = styled(PageWrapper)`
	display: flex;
	flex-direction: column;

	@media (min-width: 600px) {
		min-height: calc(100vh - 60px - 26px);
		max-height: calc(100vh - 60px - 26px);
	}
`;

export default NoAccessPage;
