import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './bulkEdit.actionTypes';
import { BulkEditUserI, SingleUser } from './type';

export const setShowModal = (value: boolean) => action(types.SET_SHOW_MODAL, { value });
export const setIsSuccess = (value: boolean) => action(types.SET_SUCCESS, { value });
export const bulkUpdateUser = createAsyncAction(
	types.BULK_UPDATE_USER_REQUEST,
	types.BULK_UPDATE_USER_SUCCESS,
	types.BULK_UPDATE_USER_FAILURE,
)<{ data: SingleUser[] }, undefined, { error: string }>();
