import { GetScheduleActivityPaginate } from '@nimbly-technologies/nimbly-common';
import { apiURLAlt } from 'config/baseURL';
import API from 'helpers/api';

export const getData = async (url: string): Promise<any> => {
	const token = await API.getFirebaseToken();
	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw new Error(json.message);
	}

	return json.data;
};

export const fetchSiteScheduleActivity = async (queryParams: string): Promise<GetScheduleActivityPaginate> => {
	const url = `${apiURLAlt}/schedules/activity?${queryParams}`;
	return await getData(url);
};
