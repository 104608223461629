import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './departmentEditor.action';
import { DepartmentEditorState } from './type';
import * as types from './departmentEditor.actionTypes';

export type DepartmentEditorAction = ActionType<typeof actions>;

export const initialState: DepartmentEditorState = {
  departmentData: null,
  fetchLoading: false,
  error: null,
  errorData: null,

  sites: null,

  isLoading: false,
  isShowModal: false,
  isSuccess: false
};

const departmentEditorReducer = createReducer<DepartmentEditorState, DepartmentEditorAction>(initialState)
  .handleAction(actions.createDepartment.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isSuccess: false
  }))
  .handleAction(actions.createDepartment.success, state => ({
    ...state,
    isLoading: false,
    isSuccess: true
  }))
  .handleAction(actions.createDepartment.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.updateDepartment.request, state => ({
    ...state,
    error: null,
    isLoading: true,
    isSuccess: false
  }))
  .handleAction(actions.updateDepartment.success, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(actions.updateDepartment.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false,
    errorData: action.payload.errorData || null
  }))
  .handleAction(types.SET_SHOW_MODAL as any, (state, action) => ({
    ...state,
    isShowModal: action.payload.value
  }))
  .handleAction(types.SET_SUCCESS as any, (state, action) => {
    return {
      ...state,
      isSuccess: action.payload.value
    };
  });

export { departmentEditorReducer };
