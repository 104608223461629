import { apiURL } from 'config/baseURL';
import { getToken } from './index';
import Monitoring from '../../../utils/monitoring/Monitoring';

export const getActivityByOrganization = async (organizationID: string, query: string) => {
  const token = await getToken();
  const options = {
    method: 'GET',
    headers: {
      authorization: token
    }
  };

  const url = `${apiURL}/activities/superadmin/paginate/${organizationID}?${query}`;

  try {
    const response = await fetch(url, options);

    if (response.status !== 200) {
      throw new Error('Failed to fetch');
    }

    const result = await response.json();

    return { data: result.data, error: result.error, message: result.message };
  } catch (e) {
    Monitoring.logEvent('getActivityByOrganization', e);
    console.info(e);
    return { data: null, error: true, message: 'FAILED' };
  }
};
