import moment from 'moment';
import { createReducer, ActionType, action } from 'typesafe-actions';
import * as actions from './salesTarget.action';
import * as types from './salesTarget.actionTypes';
import { SalesTargetState, SalesPayload } from './type';
import * as Common from 'nimbly-common';

export type SalesTargetAction = ActionType<typeof actions>;
const initialState: SalesTargetState = {
  modalVisible: false,
  modalType: null,
  modalMode: null,
  selectedSiteKey: null,
  period: { month: moment().month(), year: moment().year() },
  filterQuery: '',
  sortBy: 'progress',
  name: 'asc',
  primaryDepartment: 'asc',
  auditor: 'asc',
  sales: 'desc',
  salesTarget: 'desc',
  progress: 'desc',
  scrollPosition: 0,
  salesPagination: [],
  pageIndex: 1,
  paginateLoading: false,
  error: '',
  totalSales: 0,
  salesTargetUpdateLoading: false,
  salesUpdateCreateLoading: false,
  salesDetailLoading: false,
  salesTargetDetailLoading: false,
  salesData: {} as { [key: string]: Common.Sales },
  salesTargetData: {} as { [key: string]: Common.SalesTarget },
  salesSuccess: false,
  bulkSuccess: false,
  bulkLoading: false,
  salesTargetSuccess: false,
  selectedSiteDepartment: null,
  bulkErrorMessage: [],
  bulkError: false
};

export const salestargetReducer = createReducer<SalesTargetState, SalesTargetAction>(initialState)
  .handleAction(types.CREATE_SALES_BY_ID_REQUEST as any, state => ({
    ...state,
    salesUpdateCreateLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false
  }))
  .handleAction(types.CREATE_SALES_BY_ID_SUCCESS as any, (state, action) => {
    const { payload } = action.payload as { payload: SalesPayload };
    const date: string = payload.date;
    let newData: any = [];
    if (payload.type === 'edit') {
      newData = state.salesPagination.slice();
      newData.forEach((el: any) => {
        if (el.siteID === payload.key) {
          if (state.salesData[date]) {
            el.sales = el.sales - state.salesData[payload.date] + payload.sales;
            if (el.sales === 0 || el.salesTarget === 0) {
              el.progress = 0;
            } else {
              el.progress = el.sales / el.salesTarget;
            }
          }
        }
      }); 
      if (state.salesData[payload.date]) {
        state.salesData[payload.date] = payload.sales;
      }
    } else if (payload.type === 'create') {
      newData = state.salesPagination.slice();
      newData.forEach((el: any) => {
        if (el.siteID === payload.key) {
          el.sales += payload.sales;
          if (el.sales === 0 || el.salesTarget === 0) {
            el.progress = 0;
          } else {
            el.progress = el.sales / el.salesTarget;
          }
        }
      });
      state.salesData[payload.date] = payload.sales;
    } else if (payload.type === 'delete') {
      newData = state.salesPagination.slice();
      newData.forEach((el: any) => {
        if (el.siteID === payload.key) {
          if (state.salesData[payload.date]) {
            el.sales = el.sales - state.salesData[payload.date];
            if (el.sales === 0 || el.salesTarget === 0) {
              el.progress = 0;
            } else {
              el.progress = el.sales / el.salesTarget;
            }
          }
        }
      });
      if (state.salesData[payload.date]) {
        delete state.salesData[payload.date];
      }
    }
    return {
      ...state,
      salesUpdateCreateLoading: false,
      salesSuccess: true,
      salesPagination: newData
    };
  })
  .handleAction(types.CREATE_SALES_BY_ID_FAILURE as any, (state, action) => ({
    ...state,
    error: action.payload.error,
    salesUpdateCreateLoading: false
  }))
  .handleAction(types.UPDATE_SALES_BY_ID_REQUEST as any, state => ({
    ...state,
    salesUpdateCreateLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false
  }))
  .handleAction(types.UPDATE_SALES_BY_ID_SUCCESS as any, (state, action) => ({
    ...state,
    salesUpdateCreateLoading: false,
    salesSuccess: true
  }))
  .handleAction(types.UPDATE_SALES_BY_ID_FAILURE as any, (state, action) => ({
    ...state,
    error: action.payload.error,
    salesUpdateCreateLoading: false
  }))
  .handleAction(types.FETCH_SALES_BY_ID_REQUEST as any, state => ({
    ...state,
    salesDetailLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false
  }))
  .handleAction(types.FETCH_SALES_BY_ID_SUCCESS as any, (state, action) => ({
    ...state,
    salesDetailLoading: false,
    salesData: action.payload.data
  }))
  .handleAction(types.FETCH_SALES_BY_ID_FAILURE as any, (state, action) => ({
    ...state,
    error: action.payload.error,
    salesDetailLoading: false
  }))
  .handleAction(types.FETCH_SALESTARGET_BY_ID_REQUEST as any, state => ({
    ...state,
    salesTargetDetailLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false
  }))
  .handleAction(types.FETCH_SALESTARGET_BY_ID_SUCCESS as any, (state, action) => ({
    ...state,
    salesTargetDetailLoading: false,
    salesTargetData: action.payload.data
  }))
  .handleAction(types.FETCH_SALESTARGET_BY_ID_FAILURE as any, (state, action) => ({
    ...state,
    error: action.payload.error,
    salesTargetDetailLoading: false
  }))
  .handleAction(types.CREATE_SALES_BULK_REQUEST as any, state => ({
    ...state,
    bulkLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false,
    bulkErrorMessage: [],
    bulkError: false
  }))
  .handleAction(types.CREATE_SALES_BULK_SUCCESS as any, (state, action) => ({
    ...state,
    bulkLoading: false,
    bulkSuccess: true
  }))
  .handleAction(types.CREATE_SALES_BULK_FAILURE as any, (state, action) => ({
    ...state,
    bulkErrorMessage: action.payload.error,
    bulkLoading: false,
    bulkError: true
  }))
  .handleAction(types.CREATE_SALESTARGET_BULK_REQUEST as any, state => ({
    ...state,
    bulkLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false,
    bulkErrorMessage: [],
    bulkError: false
  }))
  .handleAction(types.CREATE_SALESTARGET_BULK_SUCCESS as any, (state, action) => ({
    ...state,
    bulkLoading: false,
    bulkSuccess: true
  }))
  .handleAction(types.CREATE_SALESTARGET_BULK_FAILURE as any, (state, action) => ({
    ...state,
    bulkErrorMessage: action.payload.error,
    bulkLoading: false,
    bulkError: true
  }))
  .handleAction(types.CREATE_SALESTARGET_BY_ID_REQUEST as any, state => ({
    ...state,
    salesTargetUpdateLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false
  }))
  .handleAction(types.CREATE_SALESTARGET_BY_ID_SUCCESS as any, (state, action) => {
    const { payload } = action.payload;
    const currentPeriod = moment().year(state.period.year).month(state.period.month).format('YYYY-MM');
    if (payload.date === currentPeriod) {
      let newData: any = [];
      newData = state.salesPagination.slice();
      newData.forEach((el: any) => {
        if (el.siteID === payload.key) {
          el.salesTarget = payload.target;
          if (el.sales === 0 || el.salesTarget === 0) {
            el.progress = 0;
          } else {
            el.progress = el.sales / el.salesTarget;
          }
        }
      });
      return {
        ...state,
        salesTargetUpdateLoading: false,
        salesPagination: newData,
        salesTargetSuccess: true
      };
    } else {
      return { ...state, salesTargetUpdateLoading: false, salesTargetSuccess: true };
    }
  })
  .handleAction(types.CREATE_SALESTARGET_BY_ID_FAILURE as any, (state, action) => ({
    ...state,
    error: action.payload.error,
    salesTargetUpdateLoading: false
  }))
  .handleAction(types.FETCH_PAGINATE_SALESTARGET_REQUEST as any, state => ({
    ...state,
    paginateLoading: true,
    salesSuccess: false,
    bulkSuccess: false,
    salesTargetSuccess: false
  }))
  .handleAction(types.FETCH_PAGINATE_SALESTARGET_SUCCESS as any, (state, action) => ({
    ...state,
    paginateLoading: false,
    salesPagination: action.payload.data,
    totalSales: action.payload.total
  }))
  .handleAction(types.FETCH_PAGINATE_SALESTARGET_FAILURE as any, (state, action) => ({
    ...state,
    error: action.payload.error,
    paginateLoading: false
  }))
  .handleAction(types.SHOW_MODAL as any, (state, action) => ({
    ...state,
    modalVisible: true,
    modalType: action.payload.modalType,
    modalMode: action.payload.modalMode,
    selectedSiteKey: action.payload.site,
    selectedSiteDepartment: action.payload.department,
    bulkSuccess: false,
    bulkErrorMessage: [],
    bulkError: false
  }))
  .handleAction(types.DISMISS_MODAL as any, state => ({
    ...state,
    modalVisible: false,
    modalType: null,
    modalMode: null,
    selectedSiteKey: null,
    bulkSuccess: false,
    bulkErrorMessage: [],
    bulkError: false
  }))
  .handleAction(types.SET_PERIOD as any, (state, action) => ({
    ...state,
    period: { month: action.payload.month, year: action.payload.year }
  }))
  .handleAction(types.SET_FILTER_QUERY as any, (state, action) => ({
    ...state,
    filterQuery: action.payload.text
  }))
  .handleAction(types.CLEAR_FILTER_QUERY as any, state => ({
    ...state,
    filterQuery: ''
  }))
  .handleAction(types.SET_SORT as any, (state, action) => {
    if (state.sortBy !== action.payload.newType) {
      return {
        ...state,
        sortBy: action.payload.newType,
        name: 'asc',
        primaryDepartment: 'asc',
        auditor: 'asc',
        sales: 'desc',
        salesTarget: 'desc',
        progress: 'desc'
      };
    }
    return {
      ...state,
      [action.payload.newType]: action.payload.newOrder
    };
  })
  .handleAction(types.SET_SCROLL as any, (state, action) => ({
    ...state,
    scrollPosition: action.payload.newScroll
  }))
  .handleAction(types.SET_PAGE_INDEX as any, (state, action) => ({
    ...state,
    pageIndex: action.payload.value
  }))
  .handleAction(types.RESET_BULK as any, state => ({
    ...state,
    bulkLoading: false,
    bulkSuccess: false,
    bulkErrorMessage: [],
    bulkError: false
  }));
