import { call, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { getToken } from 'reducers/api';
import { apiURL } from 'config/baseURL';

import * as actions from 'reducers/site/siteDetails/siteDetails.action';

export function* fetchSiteDepartments(siteID: ReturnType<typeof actions.fetchSiteDepartments.request>) {
  const transaction = Sentry.startTransaction({ name: 'fetchSiteDepartments' });
  try {
    const authToken = yield getToken();

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const fetchSitesURL = `${apiURL}/departments/site${siteID}`;
    const request = () => fetch(fetchSitesURL, options);
    const response = yield call(request);

    const span = transaction.startChild({
      data: {
        response
      },
      op: 'fetchSiteDepartments',
      description: 'fetch Site Departments'
    });
    span.finish();
    transaction.finish();

    if (response && response?.status === 200) {
      const responseData = yield response.json();
      yield put(actions.fetchSiteDepartments.success({ data: responseData.data }));
      return responseData.data;
    } else {
      const responseData = yield response.json();
      yield put(actions.fetchSiteDepartments.failure({ error: responseData.message }));
      return null;
    }
  } catch (e) {
    transaction.finish();
    yield put(actions.fetchSiteDepartments.failure({ error: 'Failed to fetch site departments' }));
    return null;
  }
}
export default function* siteDetailsSaga() {
  yield takeLatest(actions.fetchSiteDepartments.request, fetchSiteDepartments);
}
