import { FindSKUPerCategory, SKUCompact } from '@nimbly-technologies/nimbly-common';
import { UserCompactResponse } from '@nimbly-technologies/nimbly-common/src/models/api/lms/response';

export enum LmsUsersCompactFetchState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface LmsUsersCompactState {
	error: string;
	fetchState: LmsUsersCompactFetchState;
	lmsUsersCompact: UserCompactResponse[];
}
