import { MyCourseAndSyllabusResponse, PaginationResult } from '@nimbly-technologies/nimbly-common';
import { RootState } from 'store/rootReducers';
import { call, put, select } from 'typed-redux-saga';
import * as actions from '../../../reducers/lms/myCourseAndSyllabuses/myCourseAndSyllabuses.action';
import * as types from '../../../reducers/lms/myCourseAndSyllabuses/myCourseAndSyllabuses.actionTypes';
import queryString from 'query-string';
import { takeLatest } from 'redux-saga/effects';
import { fetchMyCoursesAndSyllabus } from 'services/lms/myCoursesAndSyllabuses/myCoursesAndSyllabuses.service';
import { MyCoursesAndSyllabusesState } from 'reducers/lms/myCourseAndSyllabuses/type';

export function* fetchMyCoursesAndSyllabusesSaga() {
	try {
		const { page, limit, sortField, sortDirection, search, filters }: MyCoursesAndSyllabusesState = yield select(
			(state: RootState) => state.lmsMyCoursesAndSyllabuses,
		);

		const query: Record<string, any> = {
			page,
			limit,
			sortFields: sortField,
			sortDirections: sortDirection,
			search,
			...filters,
		};
		const queryStr = queryString.stringify(query);
		const { docs, ...result }: PaginationResult<MyCourseAndSyllabusResponse> = yield* call(
			fetchMyCoursesAndSyllabus,
			queryStr,
		);

		yield put(actions.fetchMyCoursesAndSyllabuses.success({ myCoursesAndSyllabuses: docs, ...result }));
	} catch (e) {
		yield put(actions.fetchMyCoursesAndSyllabuses.failure({ error: 'Failed to fetch courses' }));
		return null;
	}
}

export default function* myCoursesAndSyllabusesSaga() {
	yield takeLatest(types.FETCH_MY_COURSES_AND_SYLLABUSES_REQUEST, fetchMyCoursesAndSyllabusesSaga);
	yield takeLatest(types.SET_MY_COURSES_AND_SYLLABUSES_PAGE_INDEX, fetchMyCoursesAndSyllabusesSaga);
	yield takeLatest(types.SET_MY_COURSES_AND_SYLLABUSES_FILTER_QUERY, fetchMyCoursesAndSyllabusesSaga);
	yield takeLatest(types.RESET_MY_COURSES_AND_SYLLABUSES_FILTER_QUERY, fetchMyCoursesAndSyllabusesSaga);
	yield takeLatest(types.SET_MY_COURSES_AND_SYLLABUSES_SEARCH_QUERY, fetchMyCoursesAndSyllabusesSaga);
	yield takeLatest(types.SET_MY_COURSES_AND_SYLLABUSES_SORT, fetchMyCoursesAndSyllabusesSaga);
}
