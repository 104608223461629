import { Quiz, QuizResult } from '@nimbly-technologies/nimbly-common';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
	QueryLmsCourseQuizByIdResponse,
	RetryLmsCourseQuizResponse,
	SubmitLmsCourseQuizSubmissionResponse,
	lmsCourseQuizService,
} from 'services/lms/lmsCourseQuiz.service';
import { IAPIResponse } from 'types/api';
import Monitoring from 'utils/monitoring/Monitoring';
import * as types from './lmsCourseQuiz.actionTypes';
import actions from './lmsCourseQuiz.actions';

function* fetchLmsCourseQuizByIdSaga(action: ReturnType<typeof actions.queryCourseById.request>): Generator {
	try {
		const query = () =>
			lmsCourseQuizService.queryCourseQuizById({
				quizId: action.payload.quizId,
			});
		const quiz: IAPIResponse<Quiz> = (yield call(query) as unknown) as IAPIResponse<Quiz>;

		yield put(actions.queryCourseById.success(quiz.data));
	} catch (error) {
		yield put(
			actions.queryCourseById.failure({
				error: 'Error fetching quiz: ' + error,
			}),
		);
	}
}

function* startLmsCourseQuizSaga(action: ReturnType<typeof actions.startQuiz.request>): Generator {
	try {
		const query = () => lmsCourseQuizService.startQuiz(action.payload);
		const quiz: IAPIResponse<string> = (yield call(query) as unknown) as IAPIResponse<string>;

		yield put(actions.startQuiz.success(quiz.data));
		action.payload.onSuccess?.();
	} catch (error) {
		const errorString = 'Error starting quiz: ' + error;

		yield put(
			actions.startQuiz.failure({
				error: errorString,
			}),
		);

		toast.error(errorString);
	}
}

function* fetchLmsCourseQuizSubmissionDraftBySubmitIdSaga(
	action: ReturnType<typeof actions.querySubmissionDraftBySubmitId.request>,
): Generator {
	try {
		const query = () =>
			lmsCourseQuizService.querySubmissionDraftBySubmitId({
				submitId: action.payload.submitId,
			});
		const quizResult: IAPIResponse<QueryLmsCourseQuizByIdResponse> = (yield call(query) as unknown) as IAPIResponse<
			QueryLmsCourseQuizByIdResponse
		>;

		yield put(actions.querySubmissionDraftBySubmitId.success(quizResult.data));
	} catch (error) {
		const errorString = 'Error fetching quiz submission draft: ' + error;

		yield put(
			actions.querySubmissionDraftBySubmitId.failure({
				error: errorString,
			}),
		);

		toast.error(errorString);
		Monitoring.logError(new Error(errorString));
	}
}

function* updateLmsCourseQuizSubmissionDraftSaga(
	action: ReturnType<typeof actions.updateSubmissionDraft.request>,
): Generator {
	try {
		const query = () =>
			lmsCourseQuizService.updateSubmissionDraft({
				elapsedTime: action.payload.elapsedTime,
				questions: action.payload.questions,
				submitId: action.payload.submitId,
			});

		const quizResult: IAPIResponse<QuizResult> = (yield call(query) as unknown) as IAPIResponse<QuizResult>;

		yield put(actions.updateSubmissionDraft.success(quizResult.data));
	} catch (error) {
		const errorString = 'Error updating quiz submission draft: ' + error;

		yield put(
			actions.updateSubmissionDraft.failure({
				error: errorString,
			}),
		);

		toast.error(errorString);
		Monitoring.logError(new Error(errorString));
	}
}

function* submitLmsCourseQuizSubmissionSaga(action: ReturnType<typeof actions.submitSubmission.request>): Generator {
	try {
		const query = () =>
			lmsCourseQuizService.submitSubmission({
				elapsedTime: action.payload.elapsedTime,
				questions: action.payload.questions,
				submitId: action.payload.submitId,
			});

		const quizResult: IAPIResponse<SubmitLmsCourseQuizSubmissionResponse> = (yield call(
			query,
		) as unknown) as IAPIResponse<SubmitLmsCourseQuizSubmissionResponse>;

		yield put(actions.submitSubmission.success(quizResult.data));
		action.payload.onSuccess?.();
	} catch (error) {
		const errorString = 'Error submitting quiz submission: ' + error;

		yield put(
			actions.submitSubmission.failure({
				error: errorString,
			}),
		);

		toast.error(errorString);
		Monitoring.logError(new Error(errorString));
	}
}

function* retryLmsCourseQuizSaga(action: ReturnType<typeof actions.retryQuiz.request>): Generator {
	try {
		const query = () =>
			lmsCourseQuizService.retryQuiz({
				quizId: action.payload.quizId,
				learnerActivityId: action.payload.learnerActivityId,
			});

		const quizResult: RetryLmsCourseQuizResponse = (yield call(query) as unknown) as RetryLmsCourseQuizResponse;

		yield put(
			actions.retryQuiz.success({
				queryByIdResponse: quizResult.queryByIdResponse,
				startMutationResponse: quizResult.startMutationResponse,
			}),
		);
		action.payload.onSuccess?.();
	} catch (error) {
		const errorString = 'Error retrying quiz: ' + error;

		yield put(
			actions.retryQuiz.failure({
				error: errorString,
			}),
		);

		toast.error(errorString);
		Monitoring.logError(new Error(errorString));
	}
}

export default function* lmsCourseQuizSaga() {
	yield takeLatest(types.QUERY_LMS_COURSE_QUIZ_BY_ID_REQUEST, fetchLmsCourseQuizByIdSaga);
	yield takeLatest(types.START_LMS_COURSE_QUIZ_REQUEST, startLmsCourseQuizSaga);
	yield takeLatest(
		types.QUERY_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_BY_SUBMIT_ID_REQUEST,
		fetchLmsCourseQuizSubmissionDraftBySubmitIdSaga,
	);
	yield takeLatest(types.UPDATE_LMS_COURSE_QUIZ_SUBMISSION_DRAFT_REQUEST, updateLmsCourseQuizSubmissionDraftSaga);
	yield takeLatest(types.SUBMIT_LMS_COURSE_QUIZ_SUBMISSION_REQUEST, submitLmsCourseQuizSubmissionSaga);
	yield takeLatest(types.RETRY_LMS_COURSE_QUIZ_REQUEST, retryLmsCourseQuizSaga);
}
