import {
	IDashboardIndicatorStore,
	IDashboardCompletionStore,
	IDashboardPassFailRatioStore,
	IDashboardLessonProgressStore,
	IDashboardLessonStatusStore,
	IDashboardSummaryStore,
	IDashboardQuizInSightStore,
} from './lmsDashboard.type';
import { takeEvery } from 'redux-saga/effects';
import { call, put } from 'typed-redux-saga';
import {
	fetchLmsDashboardIndicator,
	fetchLmsDashboardCompletionStats,
	fetchLmsDashboardPassFailRatio,
	fetchLmsDashboardLessonProgress,
	fetchLmsDashboardLessonStatus,
	fetchLmsDashboardSummary,
	fetchLmsDashboardQuizInsight,
	fetchLmsDashboardEntityStatus,
	fetchLmsDashboardQuizInsightV2,
	fetchLmsDashboardOverallQuizInsight,
	fetchLmsDashboardOverallInsight,
	fetchLmsDashboardTimeSpent,
	fetchLmsDashboardTrends,
	fetchLmsDashboardTopBottomEntites,
	fetchLmsEntityDetails,
	fetchLmsEnrollmentStats,
	fetchLmsEntityStatusWiseSplit,
} from 'services/lms/dashboard/lmsDashboard.service';
import * as actions from './lmsDashboard.action';

function* fetchLmsDashboardIndicatorSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardIndicatorAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLmsDashboardIndicator) as unknown) as IDashboardIndicatorStore;

		yield put(actions.fetchLmsDashboardIndicatorAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardIndicatorAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardCompletionStatsSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardCompletionStatsAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLmsDashboardCompletionStats) as unknown) as IDashboardCompletionStore;

		yield put(actions.fetchLmsDashboardCompletionStatsAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardCompletionStatsAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardPassFailRatioSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardPassFailRatioAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLmsDashboardPassFailRatio) as unknown) as IDashboardPassFailRatioStore[];

		yield put(actions.fetchLmsDashboardPassFailRatioAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardPassFailRatioAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardLessonProgressSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardLessonProgressAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLmsDashboardLessonProgress) as unknown) as IDashboardLessonProgressStore[];

		yield put(actions.fetchLmsDashboardLessonProgressAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardLessonProgressAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardLessonStatusSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardLessonStatusAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLmsDashboardLessonStatus) as unknown) as IDashboardLessonStatusStore;

		yield put(actions.fetchLmsDashboardLessonStatusAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardLessonStatusAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardSummarySaga(
	action: ReturnType<typeof actions.fetchLmsDashboardSummaryAsync.request>,
): Generator {
	try {
		const res = (yield fetchLmsDashboardSummary(action.payload) as unknown) as IDashboardSummaryStore;

		yield put(actions.fetchLmsDashboardSummaryAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardSummaryAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardQuizInsightSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardQuizInsightAsync.request>,
): Generator {
	try {
		const res = (yield call(fetchLmsDashboardQuizInsight) as unknown) as IDashboardQuizInSightStore[];

		yield put(actions.fetchLmsDashboardQuizInsightAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardQuizInsightAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardEntityStatusSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardEntityStatusAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsDashboardEntityStatus, action.payload);

		yield put(actions.fetchLmsDashboardEntityStatusAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardEntityStatusAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardQuizInsightV2Saga(
	action: ReturnType<typeof actions.fetchLmsDashboardQuizInsightV2Async.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsDashboardQuizInsightV2, action.payload);

		yield put(actions.fetchLmsDashboardQuizInsightV2Async.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardQuizInsightV2Async.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardOverallQuizInsightSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardOverallQuizInsightAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsDashboardOverallQuizInsight);

		yield put(actions.fetchLmsDashboardOverallQuizInsightAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardOverallQuizInsightAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardOverallInsightSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardOverviewAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsDashboardOverallInsight, action.payload);

		yield put(actions.fetchLmsDashboardOverviewAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardOverviewAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardTimeSpentSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardTimeSpentAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsDashboardTimeSpent, action.payload);

		yield put(actions.fetchLmsDashboardTimeSpentAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardTimeSpentAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardTrendsSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardTrendsAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsDashboardTrends, action.payload);

		yield put(actions.fetchLmsDashboardTrendsAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardTrendsAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsDashboardTopBottomEntitiesSaga(
	action: ReturnType<typeof actions.fetchLmsDashboardTopBottomEntitiesAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsDashboardTopBottomEntites, action.payload);

		yield put(actions.fetchLmsDashboardTopBottomEntitiesAsync.success(res));
	} catch {
		yield put(actions.fetchLmsDashboardTopBottomEntitiesAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsEntityDetailsSaga(action: ReturnType<typeof actions.fetchLmsEntityDetailsAsync.request>): Generator {
	try {
		const res = yield* call(fetchLmsEntityDetails, action.payload);

		yield put(actions.fetchLmsEntityDetailsAsync.success(res));
	} catch {
		yield put(actions.fetchLmsEntityDetailsAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsEnrollmentStatsSaga(
	action: ReturnType<typeof actions.fetchLmsEnrollmentStatsAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsEnrollmentStats, action.payload);

		yield put(actions.fetchLmsEnrollmentStatsAsync.success(res));
	} catch {
		yield put(actions.fetchLmsEnrollmentStatsAsync.failure('Failed to get dashboard details'));
	}
}

function* fetchLmsEntityStatusWiseSplitSaga(
	action: ReturnType<typeof actions.fetchLmsEntityStatusWiseSplitAsync.request>,
): Generator {
	try {
		const res = yield* call(fetchLmsEntityStatusWiseSplit, action.payload);

		yield put(actions.fetchLmsEntityStatusWiseSplitAsync.success(res));
	} catch {
		yield put(actions.fetchLmsEntityStatusWiseSplitAsync.failure('Failed to get dashboard details'));
	}
}

export default function* lmsDashboardSaga() {
	yield takeEvery(actions.fetchLmsDashboardIndicatorAsync.request, fetchLmsDashboardIndicatorSaga);
	yield takeEvery(actions.fetchLmsDashboardCompletionStatsAsync.request, fetchLmsDashboardCompletionStatsSaga);
	yield takeEvery(actions.fetchLmsDashboardPassFailRatioAsync.request, fetchLmsDashboardPassFailRatioSaga);
	yield takeEvery(actions.fetchLmsDashboardLessonProgressAsync.request, fetchLmsDashboardLessonProgressSaga);
	yield takeEvery(actions.fetchLmsDashboardLessonStatusAsync.request, fetchLmsDashboardLessonStatusSaga);
	yield takeEvery(actions.fetchLmsDashboardSummaryAsync.request, fetchLmsDashboardSummarySaga);
	yield takeEvery(actions.fetchLmsDashboardQuizInsightAsync.request, fetchLmsDashboardQuizInsightSaga);
	yield takeEvery(actions.fetchLmsDashboardEntityStatusAsync.request, fetchLmsDashboardEntityStatusSaga);
	yield takeEvery(actions.fetchLmsDashboardQuizInsightV2Async.request, fetchLmsDashboardQuizInsightV2Saga);
	yield takeEvery(actions.fetchLmsDashboardOverallQuizInsightAsync.request, fetchLmsDashboardOverallQuizInsightSaga);
	yield takeEvery(actions.fetchLmsDashboardOverviewAsync.request, fetchLmsDashboardOverallInsightSaga);
	yield takeEvery(actions.fetchLmsDashboardTimeSpentAsync.request, fetchLmsDashboardTimeSpentSaga);
	yield takeEvery(actions.fetchLmsDashboardTrendsAsync.request, fetchLmsDashboardTrendsSaga);
	yield takeEvery(actions.fetchLmsDashboardTopBottomEntitiesAsync.request, fetchLmsDashboardTopBottomEntitiesSaga);
	yield takeEvery(actions.fetchLmsEntityDetailsAsync.request, fetchLmsEntityDetailsSaga);
	yield takeEvery(actions.fetchLmsEnrollmentStatsAsync.request, fetchLmsEnrollmentStatsSaga);
	yield takeEvery(actions.fetchLmsEntityStatusWiseSplitAsync.request, fetchLmsEntityStatusWiseSplitSaga);
}
