import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './userRoles.action';
import { userRoleCompactState } from './type';

const initialState: userRoleCompactState = {
	isLoading: false,
	data: [],
	message: '',
};

export type userRolesCompactAction = ActionType<typeof actions.userRolesAction>;
export const userRolesCompactReducer = createReducer<userRoleCompactState, userRolesCompactAction>(initialState)
	.handleAction(actions.userRolesAction.request, (state) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(actions.userRolesAction.success, (state, action) => ({
		...state,
		data: action.payload.data,
		message: action.payload.message,
		isLoading: false,
	}))
	.handleAction(actions.userRolesAction.failure, (state, action) => ({
		...state,
		message: action.payload.message,
		isLoading: false,
	}));
