import * as Common from 'nimbly-common';
import { OverviewFlagCards, FetchFlagsOverviewPayload } from 'reducers/dashboard/overview/cardFlag/actionType';
import { getDummyIdxRange, getDummyCustomIdxRange } from '../getDummyIdxRange';

import greenFlags from '../../../assets/dummy/json/flag-green.json';
import yellowFlags from '../../../assets/dummy/json/flag-yellow.json';
import redFlags from '../../../assets/dummy/json/flag-red.json';

const convertToAdhocNum = (num: number, i: number) => (i % 2 === 0 ? Math.floor(num * 2) : Math.ceil(num / 2));
const reduceFunc = (curr: number, acc: number) => curr + acc;
/**
 * Also used for Sites & Issues `FlagsOverview` DUMMY
 */
export const getDummyFlagCards = (payload: FetchFlagsOverviewPayload) => {
	if (payload.periodType === Common.enums.PeriodTypes.PREVIOUS) {
		const dummyFlagCards: OverviewFlagCards = {
			scheduled: {
				currentGreen: 30,
				previousGreen: 20,
				currentRed: 3,
				previousRed: 5,
				currentYellow: 15,
				previousYellow: 8,
				currentTotal: 48,
				previousTotal: 33,
			},
			adhoc: {
				currentGreen: 15,
				previousGreen: 15,
				currentRed: 4,
				previousRed: 3,
				currentYellow: 10,
				previousYellow: 8,
				currentTotal: 29,
				previousTotal: 26,
			},
			issue: {
				currentGreen: 0,
				previousGreen: 0,
				currentRed: 0,
				previousRed: 0,
				currentYellow: 0,
				previousYellow: 0,
				currentTotal: 0,
				previousTotal: 0,
			},
		};
		return dummyFlagCards;
	}

	let diffInDay = 0;
	if (payload.periodType === Common.enums.PeriodTypes.CUSTOM) {
		diffInDay = getDummyCustomIdxRange(payload.startDate, payload.endDate);
	} else {
		diffInDay = getDummyIdxRange(payload.periodType);
	}

	const slicedGreen = greenFlags.slice(0, diffInDay);
	const slicedPrevGreen = greenFlags.slice(-diffInDay);
	const slicedYellow = yellowFlags.slice(0, diffInDay);
	const slicedPrevYellow = yellowFlags.slice(-diffInDay);
	const slicedRed = redFlags.slice(0, diffInDay);
	const slicedPrevRed = redFlags.slice(-diffInDay);

	const slicedGreenAdhoc = slicedGreen.map(convertToAdhocNum);
	const slicedPrevGreenAdhoc = slicedPrevGreen.map(convertToAdhocNum);
	const slicedYellowAdhoc = slicedYellow.map(convertToAdhocNum);
	const slicedPrevYellowAdhoc = slicedPrevYellow.map(convertToAdhocNum);
	const slicedRedAdhoc = slicedRed.map(convertToAdhocNum);
	const slicedPrevRedAdhoc = slicedPrevRed.map(convertToAdhocNum);

	const dummyFlagCards: OverviewFlagCards = {
		scheduled: {
			currentGreen: slicedGreen.reduce(reduceFunc),
			previousGreen: slicedPrevGreen.reduce(reduceFunc),
			currentRed: slicedRed.reduce(reduceFunc),
			previousRed: slicedPrevYellow.reduce(reduceFunc),
			currentYellow: slicedYellow.reduce(reduceFunc),
			previousYellow: slicedPrevRed.reduce(reduceFunc),
			currentTotal: slicedGreen.reduce(reduceFunc) + slicedYellow.reduce(reduceFunc) + slicedRed.reduce(reduceFunc),
			previousTotal:
				slicedPrevGreen.reduce(reduceFunc) + slicedPrevYellow.reduce(reduceFunc) + slicedPrevRed.reduce(reduceFunc),
		},
		adhoc: {
			currentGreen: slicedGreenAdhoc.reduce(reduceFunc),
			previousGreen: slicedPrevGreenAdhoc.reduce(reduceFunc),
			currentRed: slicedRedAdhoc.reduce(reduceFunc),
			previousRed: slicedPrevYellowAdhoc.reduce(reduceFunc),
			currentYellow: slicedYellowAdhoc.reduce(reduceFunc),
			previousYellow: slicedPrevRedAdhoc.reduce(reduceFunc),
			currentTotal:
				slicedGreenAdhoc.reduce(reduceFunc) + slicedYellowAdhoc.reduce(reduceFunc) + slicedRedAdhoc.reduce(reduceFunc),
			previousTotal:
				slicedPrevGreenAdhoc.reduce(reduceFunc) +
				slicedPrevYellowAdhoc.reduce(reduceFunc) +
				slicedPrevRedAdhoc.reduce(reduceFunc),
		},
		issue: {
			currentGreen: 0,
			previousGreen: 0,
			currentRed: 0,
			previousRed: 0,
			currentYellow: 0,
			previousYellow: 0,
			currentTotal: 0,
			previousTotal: 0,
		},
	};

	return dummyFlagCards;
};
