import { LoginAsClientPayload, LoginAsClientResponse } from 'reducers/superadmin/clientLogin/loginAccess/types';
import { getToken } from 'reducers/api';
import { apiURL } from '../../../../config/baseURL';

export const loginAsClient = async (payload: LoginAsClientPayload): Promise<LoginAsClientResponse> => {
  try {
    const token = await getToken();
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify(payload)
    };
    const url = `${apiURL}/auth/login-master-password`;

    const res = await fetch(url, options);
    const loginAsClientResult: LoginAsClientResponse = await res.json();

    if (res.status !== 200) {
      throw new Error(loginAsClientResult.message);
    }

    return loginAsClientResult;
  } catch (error) {
    const errorResult: LoginAsClientResponse = {
      message: 'FAILED',
      error: error.message
    };
    return errorResult;
  }
};
