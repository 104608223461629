import dayjs from 'dayjs';
import { Site, SiteScheduleIndex } from '@nimbly-technologies/nimbly-common';
import merge from 'lodash/merge';
import { db } from 'store/configStore';

/**
 * Legacy function exists for backward compatibility in App team audit
 */
export default async function legacySaveMultiSiteSchedule({
	site,
	schedule,
	organizationID,
}: {
	site: Site;
	schedule: SiteScheduleIndex;
	organizationID: string;
}) {
	const siteScheduleRef = `/schedule/${organizationID}/${site.siteID}`;
	const auditorSiteIndexRef = `/auditorSiteIndex/${organizationID}`;

	const siteSchedules = schedule.datesCustom;

	const now = dayjs();
	const today = now.format('YYYY-MM-DD');

	const newSiteIndex = {
		adhocReports: {},
		siteSchedules: schedule.datesCustom,
		site: site.siteID,
		datetimeUpdated: now.toISOString(),
		disabled: false,
	};
	const auditorSchedulesPromises = site.team.map((uid) => {
		const currentAuditorRef = `${auditorSiteIndexRef}/${uid}/${site.siteID}/`;
		return db.ref(currentAuditorRef).transaction((auditorSiteSchedule: any) => {
			if (!auditorSiteSchedule) {
				return newSiteIndex;
			}
			// eslint-disable-next-line max-nested-callbacks
			Object.keys(auditorSiteSchedule).forEach((date) => {
				if (date >= today) {
					delete auditorSiteSchedule[date];
				}
			});

			const newSchedule = merge(auditorSiteSchedule, newSiteIndex);
			return newSchedule;
		});
	});
	const siteUpdate = db.ref(siteScheduleRef).update(siteSchedules);
	await Promise.all(auditorSchedulesPromises.concat([siteUpdate]));
}
