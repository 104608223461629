import { GetScheduleActivityPaginate } from '@nimbly-technologies/nimbly-common';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './siteScheduleActivity.actionTypes';
import { SiteScheduleActivityFilters, SiteScheduleActivitySortField } from './type';
import { SortDirection } from 'utils/sort';

export const setSiteScheduleActivityPageIndex = (page: number) =>
	action(types.SET_SITE_SCHEDULE_ACTIVITY_PAGE_INDEX, { page });
export const setSiteScheduleActivitySort = (sortBy: SiteScheduleActivitySortField, sortDirection: SortDirection) =>
	action(types.SET_SITE_SCHEDULE_ACTIVITY_SORT, { sortBy, sortDirection });
export const setSiteScheduleActivityFilters = (filters: Partial<SiteScheduleActivityFilters>) =>
	action(types.SET_SITE_SCHEDULE_ACTIVITY_FILTERS, filters);
export const resetSiteScheduleActivityFilters = () => action(types.RESET_SITE_SCHEDULE_ACTIVITY_FILTERS);

export const fetchSiteScheduleActivity = createAsyncAction(
	types.FETCH_SITE_SCHEDULE_ACTIVITY_REQUEST,
	types.FETCH_SITE_SCHEDULE_ACTIVITY_SUCCESS,
	types.FETCH_SITE_SCHEDULE_ACTIVITY_FAILED,
)<
	{ siteID: string },
	{ siteScheduleActivity: GetScheduleActivityPaginate[]; page: number; limit: number; totalDocs: number },
	{ error: string }
>();
